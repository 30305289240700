import React from 'react';
import { PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  active: boolean;
  label: string;
  className?: string;
}

const Validation: React.FC<IProps> = (props: IProps) => {
  const { active, label } = props;
  return (
    <div style={styles.container} className={props.className}>
      <span style={active ? styles.active.circle : styles.inactive.circle} />
      <p style={active ? styles.active.label : styles.inactive.label}> {label} </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex' as 'flex'
  },
  inactive: {
    circle: {
      width: '12px',
      height: '12px',
      borderRadius: '8px',
      background: PALETTE.neutral25,
      marginTop: '5px'
    },
    label: {
      fontFamily: 'MaisonNeueLight',
      fontSize: '14px',
      lineHeight: '24px',
      color: PALETTE.neutral25,
      marginLeft: '16px'
    }
  },
  active: {
    circle: {
      width: '12px',
      height: '12px',
      borderRadius: '8px',
      background: PALETTE.interaction100,
      marginTop: '5px'
    },
    label: {
      fontFamily: 'MaisonNeueLight',
      fontSize: '14px',
      lineHeight: '24px',
      color: PALETTE.interaction100,
      marginLeft: '16px'
    }
  }
};

export default Validation;
