import { Checkbox, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import * as React from 'react';
import styled from '@emotion/styled';
import { useSegmentUser } from '../utils/segmentTracker';
import { newSelections, trackFilters } from '.';

const Header = styled.h5([FONTS.h5], {
  color: PALETTE.neutral88,
  margin: '24px 0'
});

const CheckboxContainer = styled.div({
  marginBottom: '8px'
});

const StatusFilterCheckbox = (props) => {
  const { title, options, selectedStatuses, setFilters } = props;
  const segmentUser = useSegmentUser();
  return (
    <div>
      <Header> {title && title}</Header>
      {options?.map((option, index) => {
        return (
          <CheckboxContainer key={option.value}>
            <Checkbox
              checked={selectedStatuses.includes(option.value)}
              id={`checkbox-status-filter-${index}-${option.value}`}
              css={{ minWidth: 20 }}
              key={`checkbox-status-filter-${index}-${option.value}`}
              name={option.value}
              onCheckedChange={() => {
                trackFilters({ status: option.value }, selectedStatuses, segmentUser);
                setFilters({ statuses: newSelections(option.value, selectedStatuses) });
              }}
              label={option.label_text}
            />
          </CheckboxContainer>
        );
      })}
    </div>
  );
};

export default StatusFilterCheckbox;
