import styled from '@emotion/styled';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { Card as CardBase } from '@sayrhino/rhino-shared-js';

export const Card = styled(CardBase)({
  height: 'auto !important',
  width: '100% !important',
  padding: '30px 48px',
  cursor: 'auto !important',
  margin: '15px auto'
});

export const Header = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderBottom: `1px solid ${PALETTE.neutral25}`,
  paddingBottom: '16px'
});

export const HeaderWithoutBorder = styled(Header)({
  borderBottom: 'none'
});

export const Row = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: '16px'
});

export const Title = styled.h4([FONTS.h4, { color: PALETTE.neutral88, fontSize: '16px' }]);
export const StyledRowLi = styled.li({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${PALETTE.neutral25}`,
  padding: '12px 0',
  fontSize: '16px',
  lineHeight: '28px'
});

export const StyledEditLink = styled.a(FONTS.p2Medium, {
  cursor: 'pointer',
  textDecoration: 'underline',
  color: PALETTE.interaction100,
  lineHeight: '24px',
  marginLeft: '16px'
});

export const StyledTagWrapper = styled.div({ display: 'flex', alignItems: 'center', marginTop: '16px' });
export const StyledTag = styled.div([
  FONTS.p2Medium,
  {
    background: PALETTE.neutral4,
    padding: '4px 8px',
    borderRadius: '4px',
    color: PALETTE.neutral65,
    marginRight: '8px'
  }
]);

export const StyledColLi = styled.div({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
  width: '50%',
  gap: '8px'
});

export const StyledColLiTitle = styled.span({
  fontSize: '20px',
  color: '#6A3BF5',
  fontFamily: 'MaisonNeueExtendedDemi'
})
