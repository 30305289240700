/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Button, FONTS, Loading, PALETTE, Radio, TextInput } from "@sayrhino/rhino-shared-js";

const ContainerDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '15px',
});

const ContentDiv = styled.div({
  display: 'flex',
  backgroundColor: '#F5F6F7',
  border: 'none',
  borderRadius: '10px',
  padding: '20px',
  width: '480px',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const ActionButtonsDiv = styled.div({
  display: 'flex',
  gap: '5px',
  alignItems: 'center'
});

const LeaseStillInEffectBanner = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <ContainerDiv>
      <ContentDiv>
        <p>
          Is the lease still in effect?
        </p>
        <ActionButtonsDiv>
          <Button variant="secondary" onClick={onCancel} id="leaseIsNotInEffectBtn">
            No
          </Button>
          <Button variant="primary" onClick={onConfirm} id="leaseIsInEffectBtn">
            Yes
          </Button>
        </ActionButtonsDiv>
      </ContentDiv>
    </ContainerDiv>
  )
}

export default LeaseStillInEffectBanner;
