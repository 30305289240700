import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { IClaimNotePOST } from 'components/v2/App/interfaces';
import { useMutation, MutationFunction } from 'react-query';

interface IClaimNoteResponse {
  errors?: string[];
}

const createClaimNote: MutationFunction<AxiosResponse<IClaimNoteResponse>, IClaimNotePOST> = async (
  claimNote: IClaimNotePOST
): Promise<AxiosResponse<IClaimNoteResponse>> => {
  const authenticity_token = csrfToken();
  const response: AxiosResponse<IClaimNoteResponse> = await axios.post(
    `/v2/admin/claims/${claimNote.claimsId}/notes`,
    {
      note: {
        description: claimNote.description,
        attachments: claimNote.attachments,
        public: claimNote.public
      }
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useCreateClaimNoteMutation() {
  return useMutation(createClaimNote);
}
