import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
  Alert2Icon,
  ArrowRight,
  Button,
  Checkbox,
  CloseIcon,
  PALETTE,
  DropdownMenu,
  FONTS,
  FONT_FAMILY
} from '@sayrhino/rhino-shared-js';
import Label from 'components/common/formsy/Label';

const contextParaCSS = css({
  color: PALETTE.brand100,
  padding: '12px 0'
});

const contextHeadingCSS = css({
  color: PALETTE.brand100
});

const numberCircleCSS = css({
  border: `1px solid ${PALETTE.brand100}`,
  borderRadius: '50%',
  height: '28px',
  width: '28px',
  display: 'inline-block',
  textAlign: 'center',
  color: PALETTE.brand100,
  marginRight: 14,
  padding: 1
});

const contextLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  margin: '1em 0 1em -48px',
  paddingBottom: 32,
  width: '384px'
});

const closeButtonCSS = css({
  background: PALETTE.neutral4,
  borderRadius: '20px',
  height: '32px',
  width: '32px',
  textAlign: 'center',
  border: 'none'
});

const fieldStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 12,
  borderBottom: `1px solid ${PALETTE.neutral4}`,
  input: [
    FONTS.p1,
    {
      border: 'none',
      width: '75%'
    }
  ],
  label: [
    FONTS.p3,
    {
      color: PALETTE.neutral25,
      textAlign: 'right'
    }
  ]
});

const fieldMargin = css({
  marginLeft: '0.5px',
  marginRight: '0.5px'
});

const formLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutralDark}`,
  margin: '1em 0',
  paddingBottom: 32,
  paddingTop: 16
});

let InfoRailDiv = styled.div({
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'flex-start',
  background: PALETTE.brand4,
  padding: 48,
  borderRight: `1px solid ${PALETTE.neutral12}`,
  width: '384px'
});

let ContextLineMain = styled.hr({
  borderTop: `1px solid ${PALETTE.neutral12}`,
  margin: '60px -98px'
});

let FormHeaderDiv = styled.div({
  borderBottom: `1px solid ${PALETTE.neutral4}`,
  backgroundColor: PALETTE.neutralLight,
  padding: '20px 48px',
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1
});

const DropdownArrowContainer = styled.span({
  borderRadius: '100%',
  width: '24px',
  height: '24px',
  background: '#FFFFFF',
  border: `1px solid ${PALETTE.neutral88}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const IntegrationSetupPageDiv = styled.div({ display: 'flex' });

const InfoRailHeader = styled.h3(FONTS.h3, { color: PALETTE.brand100 });
const InfoRailSubHeader = styled.span(FONTS.p2, contextParaCSS, { paddingBottom: 40 });
const InfoRailStepContainer = styled.div({ marginBottom: 50 });
const InfoRailStepNumber = styled.div(FONTS.p2, numberCircleCSS);
const InfoRailStepText = styled.span(FONTS.h5, contextHeadingCSS);
const IntegrationFormDiv = styled.div({ flex: 1, marginLeft: 384 });

const FormHeaderSpan = styled.span(FONTS.p1, FONT_FAMILY.extendedMedium, { color: PALETTE.neutral65 });
const FormHeaderCloseButton = styled.button(closeButtonCSS);
const StyledFormHeaderCloseIcon = styled(CloseIcon)({ marginTop: '7px', color: PALETTE.neutral55 });

const PMSIntegrationSetupHeader = styled.h3(FONTS.h3, { paddingBottom: '80px' });
const PMSIntegrationSetupSubheader = styled.h4(FONTS.h5, { marginBottom: 8 }, fieldMargin);
const PMSIntegrationSetupDivider = styled.hr({ contextLineCSS });
const IntegrationDetailsHeader = styled.h4(FONTS.h5, {
  marginBottom: 8,
  fieldMargin
});
const PMSIntegrationForm = styled.form({ padding: '40px 96px', marginBottom: 100, maxWidth: '900px' });

const IntegrationDetailsDivider = styled.hr({ formLineCSS });

const CheckboxWrapper = styled.div({ paddingBottom: '24px', paddingTop: '18px' });
const RadioButtonLabelWrapper = styled.div({ paddingTop: '8px', paddingBottom: '12px' });
const RadioButtonLabel = styled(Label)(FONT_FAMILY.extendedMedium);
const RadioButtonSubP = styled.p(FONT_FAMILY.extendedLight, { paddingTop: '16px' });
const RadioButtonErrorP = styled.p(FONT_FAMILY.extendedLight, { paddingTop: '16px', color: PALETTE.alert100 });
const StyledFormCheckbox = styled(Checkbox)({ minWidth: 20 });

const StyledCoverageSelect = styled.select({ border: 'none', appearance: 'none', width: '100%' });
const StyledCoverageSelectDiv = styled.div({
  paddingBottom: 4,
  marginBottom: 84
});
const StyledCoverageSelectContainer = styled.div({
  width: '100%',
  border: 'none',
  borderBottom: `${PALETTE.neutral12} solid 1px`,
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '20px'
});
const StyledCoverageInputDiv = styled.div({
  marginTop: 18,
  marginBottom: 10
});

const StyledAlert2Icon = styled(Alert2Icon)({ verticalAlign: 'middle' });
const StyledDropdownArrow = styled(ArrowRight)({ transform: 'rotate(90deg)', color: PALETTE.neutral55 });
const StyledSubmitButton = styled(Button)({ float: 'right', backgroundColor: PALETTE.neutralDark });

const StyledDropdownMenuTrigger = styled(DropdownMenu.Trigger)({
  width: '100%',
  textAlign: 'left',
  color: '#000000',
  display: 'flex',
  justifyContent: 'space-between'
});
const StyledDropdownMenuItem = styled(DropdownMenu.Item)({ minWidth: '650px' });
const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
});

export const StyledSectionSubheader = styled.p([FONTS.p1Light]);
