import React from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

type ButtonProps = {
  selected: boolean;
};

type ButtonGroupProps = {
  width: number;
};

const ButtonGroup = styled.div<ButtonGroupProps>(
  {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: PALETTE.neutral12,
    borderRadius: '20px',
    border: `1px solid ${PALETTE.neutral12}`,
    margin: '0px !important'
  },
  ({ width }) => ({
    width: `${width}px`
  })
);

const Button = styled.button<ButtonProps>([
  FONTS.p2Medium,
  {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    padding: '0 20px',
    borderRadius: '16px',
    fontSize: '14px',
    lineHeight: '24px',
    cursor: 'pointer',
    margin: '0px !important',
    '&:disabled': {
      cursor: 'not-allowed',
      color: PALETTE.neutral12,
      backgroundColor: PALETTE.neutralLight
    }
  },
  ({ selected }) => ({
    backgroundColor: selected ? PALETTE.neutralLight : 'transparent',
    color: PALETTE.neutralDark,
    border: '1px solid',
    borderColor: selected ? PALETTE.neutral12 : 'transparent'
  })
]);
export type SwitchSelectorProps = {
  active: string;
  list: Array<{ label: string; value: string }>;
  onClick: (active: string) => void;
  id?: string;
  disabled?: boolean;
  width?: number;
};

const SwitchSelector: React.FC<SwitchSelectorProps> = ({ active, disabled, list, onClick, id, width = 500 }) => {
  return (
    <ButtonGroup id={id} width={width} role="tablist">
      {list.map(({ value, label }) => {
        const selected = active === value;
        return (
          <Button
            key={value}
            aria-selected={selected}
            disabled={disabled}
            onClick={() => onClick(value)}
            selected={selected}
            name={`${id}-${value}`}
            type="button"
            role="tab"
          >
            {label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default SwitchSelector;
