import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ActionButton, Loading, PALETTE } from '@sayrhino/rhino-shared-js';
import { useReferrer, urlWithReferrer } from '../utils';
import ToggleControlsButton from '../ToggleControlsButton';
import {
  Title,
  Subtitle,
  Banner,
  ButtonGroup,
  EditButton,
  LoadingWrapper,
  DisplayField,
  InlineNotification,
  NotificationMessage,
  NotificationIcon,
  NotificationWrapper
} from './Styled';
import { ACCOUNT_ROUTES } from '../utils/routeHelpers';
import NavBar from './NavBar';
import usePropertyEntity from './usePropertyEntity';
import PropertyEntityDocumentsSection from './PropertyEntityDocumentsSection';
import usePropertyEntityDocuments from './usePropertyEntityDocuments';
import useUserRole from '../utils/userRole';

const PropertyEntityDetails: React.FC = () => {
  const { propertyEntityId } = useParams();
  const { pathname } = useLocation();
  const rawReferrer = useReferrer();
  const referrer = rawReferrer || ACCOUNT_ROUTES.legalPropertyEntities;
  const { data, isSuccess } = usePropertyEntity(Number(propertyEntityId));
  const documents = usePropertyEntityDocuments(Number(propertyEntityId));
  const daysRemainingToEdit = data?.days_remaining_to_edit;
  const { isPropertyManagerAdmin } = useUserRole();

  const formattedPhone = data?.phone?.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');

  return isSuccess ? (
    <>
      <NavBar referrer={referrer} />
      {!!daysRemainingToEdit && isPropertyManagerAdmin && (
        <NotificationWrapper>
          <InlineNotification variant="highPriority" aria-label="editing notification">
            <NotificationMessage>
              You have {daysRemainingToEdit} day{daysRemainingToEdit > 1 ? 's' : ''} remaining to make changes to Name
              and Address
            </NotificationMessage>
            <NotificationIcon color={PALETTE.alert100} style={{ marginLeft: '8px' }} height="20px" width="20px" />
          </InlineNotification>
        </NotificationWrapper>
      )}
      <Title>{data?.name}</Title>
      {isPropertyManagerAdmin && (
        <EditButton to={urlWithReferrer(`${pathname}/edit`, rawReferrer)}>
          <ActionButton id="EditCliam" variant="noIcon" usage="neutral" disabled={false}>
            Edit
          </ActionButton>
        </EditButton>
      )}

      <Banner>
        <Subtitle>Information</Subtitle>
        <DisplayField>
          <div id="name">{data?.name ?? ''}</div>
          <label htmlFor="name">Name</label>
        </DisplayField>

        <DisplayField>
          <div id="address">{data?.mailing_address ?? ''}</div>
          <label htmlFor="phone">Address</label>
        </DisplayField>

        <DisplayField>
          <div id="phone">{formattedPhone ?? ''}</div>
          <label htmlFor="phone">Phone number</label>
        </DisplayField>

        <DisplayField>
          <div id="email">{data?.email ?? ''}</div>
          <label htmlFor="email">Email</label>{' '}
        </DisplayField>
      </Banner>
      <PropertyEntityDocumentsSection documents={documents?.data} />
    </>
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default PropertyEntityDetails;
