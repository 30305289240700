/** @jsx jsx */
import { CheckIcon, CloseIcon, FONTS } from '@sayrhino/rhino-shared-js';
import React, { useCallback, useEffect, useState, createContext } from 'react';
import { jsx, css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import { TOAST_STATUS } from './use-toast';
interface Props {
  position?: 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left';
  type?: 'success' | 'warning' | 'error' | 'default' | 'info' | 'failed';
  duration?: number;
  children: JSX.Element | JSX.Element[];
}

interface IToast {
  id: number;
  toast: string;
  type: string;
  status: TOAST_STATUS;
}

const initState = {
  addToast: (toast: string, type = 'default', status = TOAST_STATUS.SUCCESS) => {}
};
const ToastContext = createContext(initState);
export const ToastProvider = ToastContext.Provider;
export default ToastContext;

const toastWrapper = css`
  position: fixed;
  z-index: 999;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const bottomLeft = css`
  bottom: 32px;
  left: 32px;
`;
const bottomRight = css`
  bottom: 32px;
  right: 32px;
`;

const topRight = css`
  top: 32px;
  right: 32px;
`;

const topLeft = css`
  top: 32px;
  left: 32px;
`;

const toast = css`
  margin-bottom: 0.5rem;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-radius: 8px;

  i {
    border-radius: 50%;
    padding: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    padding-right: 16px;
    padding-left: 16px;
    color: ${PALETTE.neutralLight};
  }

  button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: ${PALETTE.neutralLight};
    border: none;
    display: flex;
    align-items: center;
  }
`;

const toastPosition = (position) => {
  switch (position) {
    case 'bottom-left':
      return bottomLeft;
    case 'bottom-right':
      return bottomRight;
    case 'top-left':
      return topLeft;
    case 'top-right':
      return topRight;
    default:
      return bottomLeft;
  }
};

const toastIcon = css`
  i {
    background-color: ${PALETTE.neutralLight};
    color: ${PALETTE.neutralDark};
  }
`;

const types = (type) => {
  switch (type) {
    case 'success':
      return css`
        background: ${PALETTE.success100};
        ${toastIcon};
      `;
    case 'warning':
      return css`
        background: ${PALETTE.warning100};
        ${toastIcon};
      `;
    case 'info':
      return css`
        background: ${PALETTE.neutralDark};
        i {
          background-color: ${PALETTE.success100};
          color: ${PALETTE.neutralLight};
          p {
            color: ${PALETTE.neutralLight};
          }
        }
        span {
          font: ${FONTS.p2Light};
        }
      `;
    case 'failed':
      return css`
        background: ${PALETTE.neutralDark};
        i {
          background-color: ${PALETTE.alert100};
          color: ${PALETTE.neutralLight};
          p {
            color: ${PALETTE.neutralLight};
          }
        }
        span {
          font: ${FONTS.p2Light};
        }
      `;
    case 'error':
      return css`
        background: ${PALETTE.alert100};
        ${toastIcon};
      `;
    case 'neutral_warning':
      return css`
        background: ${PALETTE.neutralDark};
        i {
          background-color: ${PALETTE.warning100};
          color: ${PALETTE.warning100};
        }
        i p {
          color: ${PALETTE.neutralLight};
        }
      `;
    default:
      return css`
        background: ${PALETTE.neutralDark};
        i {
          background-color: ${PALETTE.success100};
          color: ${PALETTE.neutralLight};
        }
      `;
  }
};

export const ToastContextProvider = ({ children, position = 'bottom-left', duration = 8000 }: Props) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setInterval(() => setToasts((toasts) => toasts.slice(1)), duration);
      return () => clearInterval(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    (toast, type = 'default', status = TOAST_STATUS.SUCCESS) => {
      const id = Math.random();
      setToasts((toasts) => [...toasts, { id, toast, type, status }]);
    },
    [setToasts]
  );

  const removeToast = (item: IToast) => {
    setToasts((toasts) => toasts.filter((elem: IToast) => elem !== item));
  };

  const variants = {
    leftClose: { y: '100%', opacity: 0 },
    rightClose: { y: '-100%', opacity: 0 }
  };

  return (
    <ToastProvider value={{ addToast }}>
      {children}
      <div
        css={css`
          ${toastWrapper};
          ${toastPosition(position)};
        `}
      >
        <AnimatePresence>
          {toasts.map((item: IToast, index: number) => (
            <motion.div
              css={css`
                ${toast};
                ${toastPosition(position)};
                ${types(item.type)};
              `}
              initial={position.includes('left') ? 'leftClose' : 'rightClose'}
              animate={{ y: 0, opacity: 1 }}
              transition={{ stiffness: 100 }}
              exit={position.includes('left') ? 'leftClose' : 'rightClose'}
              variants={variants}
              key={`toast-${index}`}
            >
              <i>
                {item.status === TOAST_STATUS.SUCCESS && <CheckIcon height={10} width={10} />}
                {item.status === TOAST_STATUS.ERROR && <p>!</p>}
              </i>
              <span>{item.toast}</span>
              <button onClick={() => removeToast(item)}>
                <CloseIcon height={16} width={16} />
              </button>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </ToastProvider>
  );
};
