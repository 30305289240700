import React from 'react';

import './RadioLabel.scss';

const RadioLabel = (props) => {
  const { children, className = '', target } = props;

  return (
    <label htmlFor={target} className={`react-radio-label ${className}`}>
      {children}
    </label>
  );
};

export default RadioLabel;
