import React from 'react';

import DashboardPanelHeader from './DashboardPanelHeader';
import DashboardPanelList from './DashboardPanelList';

/**
 * Provides a container for the summary metrics panels
 * with a title at the top.
 */
const makeSummaryMetricsContainer = (title, fn) => () => (
  <React.Fragment>
    <DashboardPanelHeader>{title}</DashboardPanelHeader>
    <DashboardPanelList>{fn()}</DashboardPanelList>
  </React.Fragment>
);

export default makeSummaryMetricsContainer;
