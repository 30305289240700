import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ButtonHabitat, Checkbox, FONTS, Loading, PALETTE } from '@sayrhino/rhino-shared-js';
import { InlineNotification, LoadingWrapper, StyledContent } from './Styled';
import SFields from './SFields';
import { AxiosError } from 'axios';
import { PROPERTY_OWNER_ROUTES } from '../utils/routeHelpers';
import { useMutation, useQueryClient } from 'react-query';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import NavBar from '../Account/NavBar';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import { useReferrer } from '../utils';
import useYardiFieldSchema, { YARDI_FIELDS_SCHEMA } from 'api/v2/useYardiFieldsSchema';
import updateYardiPolicyPush from 'api/v2/updatePolicyPush';
import { pushAllPolicies } from 'api/v2/pushAllPolicies';
import usePartnerIntegration from 'api/v2/usePartnerIntegration';

const View = styled.div({
  padding: '28px 48px',
  display: 'flex',
  flexDirection: 'column'
});

export const Subtitle = styled.h4([FONTS.h5]);

const SpaceBetween = styled.div({
  display: 'flex',
  marginTop: '40px',
  justifyContent: 'space-between',
  paddingBottom: 8,
  borderBottom: `1px solid ${PALETTE.neutral12}`
});

const Tooltip = styled.div([FONTS.p3Light], {
  position: 'absolute',
  padding: '8px 16px',
  background: PALETTE.neutral88,
  color: PALETTE.neutral4,
  zIndex: 999,
  borderRadius: '4px',
  narginTop: '40px'
});

const Submit = styled(ButtonHabitat)({
  alignSelf: 'flex-end',
  justifySelf: 'flex-end',
  width: '136px',
  alignItems: 'center',
  justifyContent: 'center'
});

const Options = styled.div({
  margin: '24px 0 50px'
});

const SelectWrapper = styled.div({
  width: '100%',
  paddingLeft: '45px',
  margin: '16px 0 32px'
});

enum OptionsType {
  url = 'Policy URL',
  endDate = 'Policy end date',
  amount = 'Deposit amount',
  renewal = 'Renewal Eligibility',
  depositType = 'Deposit type'
}

export type IForm = {
  deposit_type?: string | null;
  policy_url?: string | null;
  coverage_amount?: string | null;
  expiration_date?: string | null;
  renewal_eligibility?: string | null;
};

const YardiPushForm: React.FC = () => {
  const { propertyOwnerId, partnerIntegrationId } = useParams();
  const rawReferrer = useReferrer();
  const { data: schema, isLoading: schemaLoading } = useYardiFieldSchema(partnerIntegrationId);
  const [depositTypeChecked, setDepositTypeChecked] = useState<boolean>(false);
  const [urlChecked, setUrlChecked] = useState<boolean>(false);
  const [amountChecked, setAmountChecked] = useState<boolean>(false);
  const [endDateChecked, setEndDateChecked] = useState<boolean>(false);
  const [renewalEligibilityChecked, setRenewalEligibilityChecked] = useState<boolean>(false);
  const renewalEligibilityEnabled = (window as any).App?.featureFlags?.renewalEligibilityEnabled;
  const [form, setForm] = useState<IForm>({});
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { mutate: updatePolicyPush, isLoading } = useMutation(updateYardiPolicyPush);
  const { mutate: pushPolicies, isLoading: pushLoading } = useMutation(pushAllPolicies);
  const [isValidState, setIsValidState] = useState<boolean>();
  const navigate = useNavigate();
  const [inlineError, setInlineError] = useState<string>();
  const propertyOwnerDetailsPath = generatePath(PROPERTY_OWNER_ROUTES.details, {
    propertyOwnerId
  });
  const { data } = usePartnerIntegration(partnerIntegrationId) ?? {};
  const referrer = rawReferrer || propertyOwnerDetailsPath;
  const [isMouseInside, setIsMouseInside] = useState<boolean>(false);

  useEffect(() => {
    if (schema) {
      setDepositTypeChecked(Boolean(schema?.deposit_type));
      setAmountChecked(Boolean(schema?.coverage_amount));
      setUrlChecked(Boolean(schema?.policy_url));
      setEndDateChecked(Boolean(schema?.expiration_date));
      setRenewalEligibilityChecked(Boolean(schema?.renewal_eligibility));
      setForm(schema);
    }
  }, [schema]);

  const checkIfValid = () => {
    if (!form.deposit_type && !form.coverage_amount && !form.expiration_date && !form.policy_url && !form.renewal_eligibility) {
      setIsValidState(false);
      return;
    }
    setIsValidState(true);
  };

  useEffect(() => {
    checkIfValid();
  }, [form]);

  const handleSubmit = async () => {
    const data = {
      integration: {
        fields_schema: form
      }
    };
    updatePolicyPush(
      { form: data, uuid: partnerIntegrationId },
      {
        onSuccess: (res) => {
          addToast(res?.message, '', TOAST_STATUS.SUCCESS);
          navigate(-1);
          queryClient.invalidateQueries([YARDI_FIELDS_SCHEMA, partnerIntegrationId]);
        },
        onError: (err: AxiosError) => {
          setInlineError(err?.response?.data?.message);
        }
      }
    );
  };

  const repush = () => {
    pushPolicies(propertyOwnerId!, {
      onSuccess: (res) => {
        addToast(res?.message, '', TOAST_STATUS.SUCCESS);
        navigate(-1);
      },
      onError: (err: AxiosError) => {
        setInlineError(err?.response?.data?.message || err?.response?.data?.error);
      }
    });
  };

  const renewalEligibilityContent = () => {
    if (renewalEligibilityEnabled) {
      return (
        <React.Fragment>
          <Checkbox
            checked={renewalEligibilityChecked}
            label={OptionsType.renewal}
            id="renewalEligibility"
            data-cy="renewalEligibility"
            onCheckedChange={(value) => {
              setRenewalEligibilityChecked(value);
              if (!value) {
                setForm((prev) => ({ ...prev, renewal_eligibility: '' }));
              }
            }}
          />
          {renewalEligibilityChecked && (
            <SelectWrapper>
              <SFields
                label="Renewal Eligibility"
                value={form.renewal_eligibility ?? ''}
                handleChange={(option) => setForm((prev) => ({ ...prev, renewal_eligibility: option.value }))}
              />
            </SelectWrapper>
          )}
        </React.Fragment>
      )
    } else {
      return null;
    }
  }

  return !schemaLoading ? (
    <>
      <NavBar referrer={`/${referrer}`} />
      <View>
        <SpaceBetween>
          <Subtitle>Yardi policy push</Subtitle>
          {data?.integration.update_enabled && (
            <ButtonHabitat
              onMouseEnter={() => setIsMouseInside(true)}
              onMouseLeave={() => setIsMouseInside(false)}
              id="Repush"
              variant="interaction"
              size="small"
              usage="tertiary"
              disabled={pushLoading}
              onClick={repush}
            >
              {isMouseInside && (
                <Tooltip className="tooltip">
                  Rhino will attempt to push all existing policies for this partner within a day
                </Tooltip>
              )}
              Repush
            </ButtonHabitat>
          )}
        </SpaceBetween>
        <StyledContent>
          No property management system integration for this property yet — set up your first one now!
        </StyledContent>
        {inlineError && <InlineNotification variant="highPriority">{inlineError}</InlineNotification>}
        <Options>
          <Checkbox
            checked={depositTypeChecked}
            label={OptionsType.depositType}
            id="depositType"
            data-cy="depositTyle"
            onCheckedChange={(value) => {
              setDepositTypeChecked(value);
              if (!value) {
                setForm((prev) => ({ ...prev, deposit_type: '' }));
              }
            }}
          />
          {depositTypeChecked && (
            <SelectWrapper>
              <SFields
                label="Deposit type"
                value={form.deposit_type ?? ''}
                handleChange={(option) => setForm((prev) => ({ ...prev, deposit_type: option.value }))}
              />
            </SelectWrapper>
          )}
          <Checkbox
            checked={urlChecked}
            label={OptionsType.url}
            id="policyUrl"
            data-cy="policyUrl"
            onCheckedChange={(value) => {
              setUrlChecked(value);
              if (!value) {
                setForm((prev) => ({ ...prev, policy_url: '' }));
              }
            }}
          />
          {urlChecked && (
            <SelectWrapper>
              <SFields
                label="Policy URL"
                value={form.policy_url ?? ''}
                handleChange={(option) => setForm((prev) => ({ ...prev, policy_url: option.value }))}
              />
            </SelectWrapper>
          )}
          <Checkbox
            checked={amountChecked}
            label={OptionsType.amount}
            id="coverageAmount"
            data-cy="coverageAmount"
            onCheckedChange={(value) => {
              setAmountChecked(value);
              if (!value) {
                setForm((prev) => ({ ...prev, coverage_amount: '' }));
              }
            }}
          />
          {amountChecked && (
            <SelectWrapper>
              <SFields
                label="Deposit amount"
                value={form.coverage_amount ?? ''}
                handleChange={(option) => setForm((prev) => ({ ...prev, coverage_amount: option.value }))}
              />
            </SelectWrapper>
          )}
          <Checkbox
            checked={endDateChecked}
            label={OptionsType.endDate}
            id="policyEndDate"
            data-cy="policyEndDate"
            onCheckedChange={(value) => {
              setEndDateChecked(value);
              if (!value) {
                setForm((prev) => ({ ...prev, expiration_date: '' }));
              }
            }}
          />
          {endDateChecked && (
            <SelectWrapper>
              <SFields
                label="Policy end date"
                value={form.expiration_date ?? ''}
                handleChange={(option) => setForm((prev) => ({ ...prev, expiration_date: option.value }))}
              />
            </SelectWrapper>
          )}
          { renewalEligibilityContent() }
        </Options>
        <Submit
          id="submit"
          variant="interaction"
          children="Submit"
          onClick={handleSubmit}
          data-cy="yardiPushSubmit"
          disabled={isLoading || !isValidState}
        />
      </View>
    </>
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default YardiPushForm;
