/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
  FONTS,
  PALETTE,
  Checkbox,
  RadioOption,
  Alert2Icon,
  DropdownMenu,
  CaretIcon,
  Select,
  TextInput
} from '@sayrhino/rhino-shared-js';
import { PaymentOption, ACHInfo, CheckingInfo, SavedAccount, Errors } from './claimInterface';
import React, { Fragment } from 'react';
import {
  formLineCSS,
  sectionHeaderCss,
  MediumCSS,
  LightCSS,
  sectionCSS,
  sectionDividerCSS,
  alertRowCSS,
  StyledDisclaimer
} from './Styled';
import AddressOneTextInputOrTypeahead from './AddressOneTextInputOrTypeahead';
import useStates from 'api/v2/useStates';
import useClaimBankAccounts from 'api/v2/useClaimBankAccounts';
import useUserRole, { useUserId } from '../../utils/userRole';
import { FormMode, IStateOption } from '../../interfaces';
import SavePayoutInfo, { ISavePayoutInfoProps } from './SavePayoutInfo';

const StyledDropdownMenuTrigger = styled(DropdownMenu.Trigger)({
  width: '100%',
  textAlign: 'left',
  color: '#000000',
  display: 'flex',
  justifyContent: 'space-between'
});

const StyledDropdownMenuItem = styled(DropdownMenu.Item)({ minWidth: '534px' });
const StyledDropdownNewAccount = styled(StyledDropdownMenuItem)({ fontWeight: 'bold' });
const DropdownArrowContainer = styled.span({
  borderRadius: '100%',
  width: '24px',
  height: '24px',
  background: '#FFFFFF',
  border: `1px solid ${PALETTE.neutral65}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

interface IPaymentMethodProps {
  errors: Errors;
  paymentOption: string;
  handleSelectPaymentOption: (value: string) => void;
  paymentACHInfo: ACHInfo;
  onChangeACHInfo: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  onChangeCheckInfo: (value: string, key: string) => void;
  accountConfirmed: boolean;
  setCheckMailingAddress: (value: CheckingInfo) => void;
  handleAccountConfirmChange: (value: boolean) => void;
  checkMailingAddress: CheckingInfo;
  savedAccount: SavedAccount;
  setSavedAccount: (value: SavedAccount) => void;
  savePayout: ISavePayoutInfoProps;
  mode: FormMode;
  initialPaymentACHInfo: {
    routingNumber: string;
    accountNumber: string;
  };
}

const PayoutMethod: React.FC<IPaymentMethodProps> = ({
  errors,
  paymentOption,
  handleSelectPaymentOption,
  setCheckMailingAddress,
  paymentACHInfo,
  onChangeACHInfo,
  accountConfirmed,
  handleAccountConfirmChange,
  checkMailingAddress,
  onChangeCheckInfo,
  savedAccount,
  setSavedAccount,
  savePayout,
  mode,
  initialPaymentACHInfo
}) => {
  const userId = useUserId();
  const { isPropertyManagerAdmin, isPropertyManager } = useUserRole();
  const makeCall = isPropertyManagerAdmin || isPropertyManager;
  const { data: statesData } = useStates();
  const { data, isSuccess } = useClaimBankAccounts(userId, makeCall) ?? {};
  const showSavedAccounts = mode === FormMode.CREATE && isSuccess && (data?.length ?? []) > 0;
  const NEW_BANK_ACCOUNT = 'New Bank Account...';
  const showBankDetailForm = mode === FormMode.EDIT || !showSavedAccounts || savedAccount.nickname === NEW_BANK_ACCOUNT;
  const shouldShowACHCheckbox =
    mode === FormMode.CREATE ||
    initialPaymentACHInfo.accountNumber !== paymentACHInfo.accountNumber ||
    initialPaymentACHInfo.routingNumber !== paymentACHInfo.routingNumber;

  return (
    <Fragment>
      <hr css={sectionDividerCSS} />
      <div css={sectionCSS}>
        <h5 css={sectionHeaderCss}>Select payout method</h5>
        <hr css={formLineCSS} />
        {errors.paymentSelect && (
          <div css={alertRowCSS}>
            <Alert2Icon width={20} height={20} css={{ marginRight: 20 }} />
            {errors.paymentSelect}
          </div>
        )}
        <div css={paymentOption === PaymentOption.DIRECT_DEPOSIT ? MediumCSS : LightCSS}>
          <RadioOption
            label="Direct deposit"
            id="radio-payment-direct"
            onChange={(e) => handleSelectPaymentOption(e.target.value)}
            value={'Direct deposit'}
            selected={paymentOption}
          />
        </div>
        {paymentOption === PaymentOption.DIRECT_DEPOSIT && (
          <div css={{ paddingLeft: 40, paddingBottom: savedAccount.nickname !== NEW_BANK_ACCOUNT ? 48 : 0 }}>
            <p css={[FONTS.p1, { marginBottom: 24 }]}>
              Please enter your bank account information. You’ll receive a wire transfer once your claim has been
              approved (typically within 48 hours).
            </p>
            {showSavedAccounts && (
              <Fragment>
                {errors.accountConfirm && !showBankDetailForm && (
                  <div css={alertRowCSS}>
                    <Alert2Icon width={20} height={20} css={{ marginRight: 20 }} />
                    {errors.accountConfirm}
                  </div>
                )}

                <DropdownMenu.Root>
                  <StyledDropdownMenuTrigger aria-label="select partner type" value={savedAccount.nickname}>
                    <span>{savedAccount.nickname === '' ? '-' : savedAccount.nickname}</span>
                    <DropdownArrowContainer>
                      <CaretIcon width={10} height={4} />
                    </DropdownArrowContainer>
                  </StyledDropdownMenuTrigger>
                  <DropdownMenu.Content>
                    {data?.map((account) => (
                      <StyledDropdownMenuItem
                        aria-label={account.nickname}
                        onSelect={() => setSavedAccount(account)}
                        key={account.id}
                      >
                        {account.nickname}
                      </StyledDropdownMenuItem>
                    ))}
                    <StyledDropdownNewAccount
                      aria-label={NEW_BANK_ACCOUNT}
                      onSelect={() => setSavedAccount({ id: null, nickname: NEW_BANK_ACCOUNT })}
                    >
                      {NEW_BANK_ACCOUNT}
                    </StyledDropdownNewAccount>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </Fragment>
            )}
            {showBankDetailForm && (
              <Fragment>
                {/* New masked inputs go here */}
                <TextInput
                  id="routing number"
                  label="Routing number associated with bank account"
                  placeholder={'e.g. 012345678'}
                  value={paymentACHInfo.routingNumber}
                  onChange={(e) => onChangeACHInfo(e, 'routingNumber')}
                  subtext={errors?.routingNumber}
                  error={!!errors.routingNumber}
                />
                <TextInput
                  id="account number"
                  label="Account number associated with bank account"
                  placeholder={'e.g. 0123456789012'}
                  value={paymentACHInfo.accountNumber}
                  onChange={(e) => onChangeACHInfo(e, 'accountNumber')}
                  subtext={errors?.accountNumber}
                  error={!!errors.accountNumber}
                />

                {mode === FormMode.CREATE && <SavePayoutInfo {...savePayout} nicknameError={errors.nickname} />}

                {shouldShowACHCheckbox && (
                  <Checkbox
                    id="ACH-verification-checkbox"
                    css={{ minWidth: 20 }}
                    label="By checking this box, you verify that you have reviewed your banking information and that it is correct."
                    checked={accountConfirmed}
                    onCheckedChange={(value) => handleAccountConfirmChange(value)}
                  />
                )}
                <div css={{ height: 26, marginTop: 10 }}>
                  {errors.accountConfirm && (
                    <div css={alertRowCSS}>
                      <Alert2Icon width={20} height={20} css={{ marginRight: 20 }} />
                      {errors.accountConfirm}
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        )}
        <div css={paymentOption === PaymentOption.PHYSICAL_CHECK ? MediumCSS : LightCSS}>
          <RadioOption
            label="Physical check"
            id="radio-payment-physical"
            onChange={(e) => handleSelectPaymentOption(e.target.value)}
            value={'Physical check'}
            selected={paymentOption}
          />
        </div>
        {paymentOption === PaymentOption.PHYSICAL_CHECK && (
          <div>
            <div css={{ paddingLeft: 40, paddingBottom: 20 }}>
              <p css={[FONTS.p1, { marginBottom: 24 }]}>
                Please provide the address where you’d like the check to be mailed.
              </p>
              <AddressOneTextInputOrTypeahead
                id="check mailing address line one"
                label="Street address"
                placeholder={'123 Sesame St'}
                onChangeCheckInfo={onChangeCheckInfo}
                checkMailingAddress={checkMailingAddress}
                subtext={errors?.addressOne}
                error={!!errors.addressOne}
                setCheckMailingAddress={setCheckMailingAddress}
              />
              <TextInput
                id="check mailing address line two"
                label="Suite / Floor / Unit (Optional)"
                placeholder={'P.O. BOX 11'}
                value={checkMailingAddress.addressTwo}
                onChange={(e) => onChangeCheckInfo(e.target.value, 'addressTwo')}
              />
              <TextInput
                id="check mailing address city"
                label="City"
                placeholder={'Springfield'}
                value={checkMailingAddress.city}
                onChange={(e) => onChangeCheckInfo(e.target.value, 'city')}
                subtext={errors?.city}
                error={!!errors.city}
              />
              <Select
                label="State"
                placeholder={checkMailingAddress.state === '' ? '-' : checkMailingAddress.state}
                defaultValue={checkMailingAddress.state}
                onChange={(selection: IStateOption) => {
                  onChangeCheckInfo(selection.value, 'state');
                }}
                options={statesData?.states}
                id="select-payout-state"
                subtext={errors?.state}
                error={!!errors.state}
              />
              <TextInput
                id="check mailing address zip code"
                label="Zip code"
                placeholder={'12345'}
                value={checkMailingAddress.zip}
                onChange={(e) => onChangeCheckInfo(e.target.value, 'zip')}
                subtext={errors?.zip}
                error={!!errors.zip}
              />
            </div>
          </div>
        )}
        <p css={[FONTS.p3]}>
          Any person who knowingly and with intent to defraud any insurance company or other person files an application
          for insurance or statement of claim containing any materially false information concerning any fact material
          thereto or conceals information for the purpose of misleading, commits a fraudulent insurance act, which is a
          crime.
          <br />
          That person shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value
          of the claim for each such violation.
        </p>
      </div>
    </Fragment>
  );
};

export default PayoutMethod;
