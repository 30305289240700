import React from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { CloseIcon, Loading, MaskedInput } from '@sayrhino/rhino-shared-js';
import FormField from '../FormField';
import useOverlay from '../utils/overlay';
import { ACCOUNT_ROUTES } from '../utils/routeHelpers';
import usePropertyEntity from './usePropertyEntity';
import {
  LoadingWrapper,
  PageHeader,
  Banner,
  CloseIconButton,
  FormHeaderTitle,
  FormTitle,
  FormSubtitle,
  NotificationWrapper,
  InlineNotification,
  UpdateButton
} from './Styled';

export const PropertyEntityEditView: React.FC = () => {
  const { propertyEntityId } = useParams();
  const { handleSubmit, formField, errorMessage, data } = usePropertyEntity(Number(propertyEntityId));
  const { name, setName, address, setAddress, phone, setPhone, email, setEmail } = formField;

  return (
    <div>
      <PageHeader>
        <FormHeaderTitle>Edit legal property entity</FormHeaderTitle>
        <CloseIconButton
          to={generatePath(ACCOUNT_ROUTES.legalPropertyEntitiesView, { propertyEntityId })}
          aria-label="view policy details"
        >
          <CloseIcon height={14} width={14} />
        </CloseIconButton>
      </PageHeader>
      {!!errorMessage?.length && (
        <NotificationWrapper>
          {errorMessage.map((message) => (
            <InlineNotification key={message} variant="highPriority" aria-label="form error message">
              {`${message}`.trim()}
            </InlineNotification>
          ))}
        </NotificationWrapper>
      )}
      <Banner>
        <FormTitle>{data?.name}</FormTitle>
        <FormSubtitle>Information</FormSubtitle>
        <form onSubmit={handleSubmit} method="POST">
          {data?.can_edit && (
            <FormField
              input={
                <input
                  name="name"
                  id="name"
                  value={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setName(e.target.value);
                  }}
                />
              }
              label={<label htmlFor="name">Name</label>}
            />
          )}
          {/* TODO SELECT based on type of address of Property or Google address */}
          {data?.can_edit && (
            <FormField
              input={
                <input
                  name="address"
                  id="address"
                  value={address}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAddress(e.target.value);
                  }}
                />
              }
              label={<label htmlFor="address">Address</label>}
            />
          )}
          <FormField
            input={
              <MaskedInput
                mask="(999) 999-9999"
                name="phone"
                id="phone"
                value={phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPhone(e.target.value);
                }}
              />
            }
            label={<label htmlFor="phone">Phone number</label>}
          />
          <FormField
            input={
              <input
                name="email"
                id="email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
              />
            }
            label={<label htmlFor="email">Email</label>}
          />

          <UpdateButton variant="primary" type="submit">
            Update
          </UpdateButton>
        </form>
      </Banner>
    </div>
  );
};

const PropertyEntityEdit = () => {
  const { propertyEntityId } = useParams();
  const { isSuccess } = usePropertyEntity(Number(propertyEntityId));
  const { setShowOverlay } = useOverlay();

  React.useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return isSuccess ? (
    <PropertyEntityEditView />
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default PropertyEntityEdit;
