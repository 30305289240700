import styled from '@emotion/styled';
import { FONTS, PALETTE, Card, Tag } from '@sayrhino/rhino-shared-js';
import React from 'react';
import { StatusCheckIcon } from './StatusCheckIcon';
import { PartnerIntegrationStatusEnum, PartnerValidationStatus, PropertyOwnerClaimClassification } from '../interfaces';
import CardLink from '../CardLink';

type PropertyOwnersCardProps = {
  propertyOwnerName: string;
  integrationType: string;
  propertyOwnerId: number;
  path: string;
  activeIntegration: boolean;
  activePropertyOwner: boolean;
  integrationStatus?: PartnerValidationStatus | null;
  claimClassification?: PropertyOwnerClaimClassification | null;
};

const StyledCard = styled(Card)({
  minHeight: '100px',
  padding: '20px 32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});
const StyledNameDiv = styled.div({ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' });
const StyledIntegrationDiv = styled.div({
  display: 'flex',
  alignItems: 'center'
});

const StyledPropertyOwnerNumber = styled.span([FONTS.p2, { color: PALETTE.neutral88, whiteSpace: 'nowrap' }]);
const StyledPropertyOwnerClassification = styled.span([
  FONTS.p2,
  { color: PALETTE.neutral88, whiteSpace: 'nowrap', padding: '0px 12px 12px 0px' }
]);
const StyledIntegrationTypeDiv = styled.label<{ activeIntegration: boolean; hasValidatedIntegration: boolean }>(
  [{ paddingRight: '24px', borderRight: `1px solid ${PALETTE.neutral12}`, paddingLeft: '8px' }, FONTS.p2],
  ({ hasValidatedIntegration }) => hasValidatedIntegration && [{ color: PALETTE.interaction100 }, FONTS.p2Medium],
  ({ activeIntegration }) => !activeIntegration && { color: PALETTE.neutral55 }
);
const IntegrationTagWrapper = styled.div({ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' });
const StyledTagWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  backgroundColor: PALETTE.neutralLight,
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  border: `1px solid ${PALETTE.neutral12}`,
  borderTop: 'none'
});
const StyledIconDiv = styled.div({ paddingLeft: '24px' });
const Name = styled.label([FONTS.h4, { paddingRight: '20px' }]);
export const PropertyOwnersCard = (props: PropertyOwnersCardProps) => {
  const {
    propertyOwnerName,
    integrationType,
    propertyOwnerId,
    path,
    activeIntegration,
    activePropertyOwner,
    integrationStatus,
    claimClassification
  } = props;
  // If integration is Validated display name in Blue else it will display as black
  const hasValidatedIntegration = integrationStatus === PartnerIntegrationStatusEnum.SUCCESS;
  const cardId = `property_owner_card_${propertyOwnerId}`;
  // label format: NAME is ACTIVE/INACTIVE and has YARDI/REALPAGE/NO integration
  const labelmsg = `${propertyOwnerName} is ${activePropertyOwner ? 'active' : 'inactive'} and has ${
    integrationType ?? 'no'
  } integration.`;

  const statusText = () => {
    if (integrationStatus === PartnerIntegrationStatusEnum.IN_PROGRESS) {
      return 'Validation in progress';
    } else if (
      integrationStatus === PartnerIntegrationStatusEnum.ERROR ||
      integrationStatus === PartnerIntegrationStatusEnum.FAILED
    ) {
      return 'Validation failed';
    } else {
      return integrationType;
    }
  };
  const integrationStatusDisplay = integrationType ? statusText() : 'No integration';

  return (
    <CardLink to={path} id={cardId} aria-label={labelmsg}>
      {claimClassification ? (
        <>
          <StyledCard
            style={{
              height: 'auto',
              width: 'auto',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              borderBottom: 'none',
              marginBottom: '-12px'
            }}
          >
            <StyledNameDiv>
              <StyledPropertyOwnerNumber>{`Id: ${propertyOwnerId}`} </StyledPropertyOwnerNumber>
              <Name>{propertyOwnerName}</Name>
            </StyledNameDiv>
            <IntegrationTagWrapper>
              <StyledIntegrationDiv>
                <StyledIntegrationTypeDiv
                  hasValidatedIntegration={hasValidatedIntegration}
                  activeIntegration={activeIntegration}
                >
                  {integrationStatusDisplay}
                </StyledIntegrationTypeDiv>
                <StyledIconDiv>
                  <StatusCheckIcon active={activePropertyOwner} />
                </StyledIconDiv>
              </StyledIntegrationDiv>
            </IntegrationTagWrapper>
          </StyledCard>

          <StyledTagWrapper>
            <StyledPropertyOwnerClassification id="claim-tag">
              <Tag id="claim classifciation tag">Claims - {claimClassification?.toUpperCase()}</Tag>
            </StyledPropertyOwnerClassification>
          </StyledTagWrapper>
        </>
      ) : (
        <StyledCard
          style={{
            height: 'auto',
            width: 'auto'
          }}
        >
          <StyledNameDiv>
            <StyledPropertyOwnerNumber>{`Id: ${propertyOwnerId}`} </StyledPropertyOwnerNumber>
            <Name>{propertyOwnerName}</Name>
          </StyledNameDiv>

          <StyledIntegrationDiv>
            <StyledIntegrationTypeDiv
              hasValidatedIntegration={hasValidatedIntegration}
              activeIntegration={activeIntegration}
            >
              {integrationStatusDisplay}
            </StyledIntegrationTypeDiv>
            <StyledIconDiv>
              <StatusCheckIcon active={activePropertyOwner} />
            </StyledIconDiv>
          </StyledIntegrationDiv>
        </StyledCard>
      )}
    </CardLink>
  );
};
