/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Checkbox, FONTS, Loading } from '@sayrhino/rhino-shared-js';
import { QueryObserverResult } from 'react-query';
import { LoadingWrapper } from '../Claims/ClaimDetails/ClaimStyles';
import { IFilters, IAdministrators } from '../interfaces';
import { StyledTitleDiv, StyledOptionsDiv, StyledH5, StyledWrapperDiv } from './ClaimFilterStyles';
import { StyledCheckbox, clearButtonCSS, newSelections } from './index';

const ClaimAdminFilterOptions: React.FC<{
  css?: any;
  query: () => QueryObserverResult<IAdministrators>;
  selectedAdmins: string[] | undefined;
  setFilters: (args: Partial<IFilters>) => void;
}> = (props) => {
  const { query, selectedAdmins = [], setFilters, ...rest } = props;
  const { data, isLoading } = query();

  return isLoading ? (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  ) : (
    <div {...rest}>
      <StyledTitleDiv>
        <StyledH5>Claim Analyst Assignment</StyledH5>
        <button
          type="button"
          css={clearButtonCSS({ canClear: Boolean(selectedAdmins.length) })}
          onClick={() => setFilters({ administrators: [] })}
        >
          Clear
        </button>
      </StyledTitleDiv>
      <StyledWrapperDiv>
        <StyledOptionsDiv>
          {data &&
            data.administrators.map((option, index) => {
              return (
                <StyledCheckbox key={`checkbox-admin-wrapper-${index}`}>
                  <Checkbox
                    checked={selectedAdmins.includes(String(option.id))}
                    id={`checkbox-admin-filter-${index}`}
                    key={`checkbox-admin-filter-${index}`}
                    name={String(option.id)}
                    onCheckedChange={() =>
                      setFilters({ administrators: newSelections(String(option.id), selectedAdmins) })
                    }
                    label={option.name_or_email}
                  />
                </StyledCheckbox>
              );
            })}
        </StyledOptionsDiv>
      </StyledWrapperDiv>
    </div>
  );
};

export default ClaimAdminFilterOptions;
