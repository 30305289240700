import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useToast from '../toast/use-toast';
import { ICoverageRulesetPost, ICustomCoverageRuleset } from '../interfaces';
import usePartnerCoverageRules, { COVERAGE_RULES_KEY } from 'api/v2/usePartnerCoverageRules';
import useCoverageRulesForm from './useCoverageRulesForm';
import createBulkCoverageRules from 'api/v2/createUpdateBulkCoverageRules';
import { AxiosError } from 'axios';
import { PROPERTY_COVERAGE_RULES_KEY } from 'api/v2/usePropertyCoverageRules';
import updatePartnerIntegration from 'api/v2/updatePartnerIntegration';

const usePartnerCoverageRulesForm = () => {
  const { partnerIntegrationId } = useParams();
  const { data: coverageRuleSetData } = usePartnerCoverageRules(partnerIntegrationId) ?? {};
  const [partnerRuleSetError, setPartnerRuleSetError] = useState<string | undefined>('');
  const [customRuleSetErrors, setCustomRuleSetErrors] = useState<(string | undefined)[]>([]);
  const partnerCoverageRuleSet = coverageRuleSetData
    ? coverageRuleSetData.filter((ruleset) => ruleset.default === true)[0]
    : undefined;
  const partnerRuleSetId: number | undefined = partnerCoverageRuleSet?.id;
  const { mutate: createBulkCoverageRulesMutation, isLoading: isCoverageRulesLoading } = useMutation(
    createBulkCoverageRules,
    {}
  );

  const { mutate: updatePartnerMutation, isLoading: isUpdatePartnerLoading } = useMutation(
    updatePartnerIntegration,
    {}
  );

  const { addToast } = useToast();
  const navigate = useNavigate();
  const { getPartnerCoverageRules, ...rest } = useCoverageRulesForm(partnerCoverageRuleSet);
  const queryCache = useQueryClient();

  const createManyCoverageRules = (
    partnerId: string,
    customRulesets: ICustomCoverageRuleset[],
    includePartnerRuleset: boolean,
    includeCustomRulesets: boolean,
    integrationType?: string,
    propertyOwnerId?: string,
    inviteResidentStatuses?: Array<string>,
    inviteResidentCreditStatuses?: Array<string>
  ) => {
    const partnerRuleset: ICoverageRulesetPost = {
      type: 'integration_partner',
      coverageable_ids: [partnerId],
      coverage_rules: getPartnerCoverageRules(inviteResidentCreditStatuses),
      default_coverageable_ids: [partnerId],
      name: 'default',
      id: partnerRuleSetId
    };

    const allRulesets: (ICustomCoverageRuleset | ICoverageRulesetPost)[] = [];
    if (includePartnerRuleset) {
      allRulesets.push(partnerRuleset);
    }
    if (customRulesets.length > 0) {
      allRulesets.push(...customRulesets);
    }
    createBulkCoverageRulesMutation(allRulesets, {
      onSuccess: (e) => {
        // add success toast
        addToast('Integration setup submitted');
        // invalidate queries
        queryCache.invalidateQueries([PROPERTY_COVERAGE_RULES_KEY, propertyOwnerId]);
        queryCache.invalidateQueries([COVERAGE_RULES_KEY, partnerIntegrationId]);

        // update integration to denote which coverage rules are active
        if (integrationType) {
          updateCoverageRuleUse(
            includePartnerRuleset,
            includeCustomRulesets,
            partnerId,
            integrationType,
            inviteResidentStatuses,
            inviteResidentCreditStatuses
          );
        }

        // redirect to the previous page
        navigate(-1);
      },
      onError: (e: AxiosError) => {
        const { data } = e?.response ?? {};
        addToast('Something went wrong', 'error');
        const [partnerRuleSetError, ...customRuleSetErrors] = data.map(({ errors }) => {
          const formattedError: string[] = [];
          errors?.forEach(({ coverage_rule_set }) => {
            if (coverage_rule_set) formattedError.push(coverage_rule_set?.join('\n'));
          });
          return formattedError.join('\n');
        });
        setPartnerRuleSetError(partnerRuleSetError);
        setCustomRuleSetErrors(customRuleSetErrors);
      }
    });
  };

  const handleErrorReset = (index) => {
    const updatedError = [...customRuleSetErrors].map((error, idx) => {
      if (index === idx) return undefined;
      return error;
    });
    setCustomRuleSetErrors(updatedError);
  };

  /**
   * Toggles the partner and custom ruleset properties depending on user selection
   * @param enabled_partner_rule_set enable/disable partner rulesets
   * @param enabled_custom_rule_set enable/disable custom rulesets
   * @param uuid partner uuid, needed to update correct partner
   * @param type integration type (realpage/yardi)
   */
  const updateCoverageRuleUse = (
    enabled_partner_rule_set: boolean,
    enabled_custom_rule_set: boolean,
    uuid: string,
    type: string,
    invite_resident_statuses?: Array<string>,
    invite_resident_credit_statuses?: Array<string>
  ) => {
    updatePartnerMutation({
      enabled_partner_rule_set,
      enabled_custom_rule_set,
      uuid,
      type,
      invite_resident_statuses,
      invite_resident_credit_statuses
    });
  };

  return {
    createManyCoverageRules,
    partnerRuleSetError,
    customRuleSetErrors,
    handleErrorReset,
    isCoverageRulesLoading,
    ...rest
  };
};

export default usePartnerCoverageRulesForm;
