import React from 'react';
import styled from '@emotion/styled';
import { FONT_FAMILY, ArrowDown, PALETTE } from '@sayrhino/rhino-shared-js';
import MultiSelect from 'components/v2/select/MultiSelect';
import FormField from '../../../FormField';
import { useFormContext } from 'components/v2/App/utils/propertyMemberAssign';
import { useState } from 'react';
import useDebouncedEffect from 'use-debounced-effect-hook';

export interface Member {
  id: number;
  full_name: string;
  email: string;
  type: string;
}

export interface IProps {
  team: Member[];
  handleChange: (selectedValues: Member[]) => void;
  error?: string | null;
}

const BoldName = styled.span([FONT_FAMILY.extendedDemi]);

const Label = styled.label({ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center', alignItems: 'center' });

export const TeamsMultiSelect: React.FC<IProps> = ({ team, handleChange, error }) => {
  const { members, searchTeamMember } = useFormContext();
  const [search, setSearch] = useState<string>('');

  const onSearchChange = (value: string) => setSearch(value);

  useDebouncedEffect(() => searchTeamMember(search), [search], 300);

  const formatedOptions =
    members?.map((member) => {
      return {
        ...member,
        label: `${member.full_name} ${member.email}`
      };
    }) || [];

  return (
    <FormField
      error={error}
      input={
        <MultiSelect
          id="teamMemberSelect"
          placeholder="Select teams"
          options={formatedOptions}
          value={team}
          labelKey="label"
          valueKey="id"
          inputValue={search}
          onInputChange={onSearchChange}
          onChange={handleChange}
          formatOptionLabel={(option, { context }) => {
            return context === 'menu' ? (
              <>
                <BoldName>{option.full_name || option.email}</BoldName>
                {option.full_name ? `: ${option.email}` : ''}
              </>
            ) : (
              option.full_name || option.email
            );
          }}
          components={{ DropdownIndicator: () => null }}
        />
      }
      label={
        <Label htmlFor="teamMemberSelect">
          Select team member <ArrowDown style={{ color: PALETTE.neutral65 }} />{' '}
        </Label>
      }
    />
  );
};

export default TeamsMultiSelect;
