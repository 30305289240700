import axios from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation } from 'react-query';

type ReqData = string[];

const generateReport = async ({ uuid, data }) => {
  const authenticity_token = csrfToken();
  const response = await axios.post<ReqData>(
    `/v2/integrations/partners/${uuid}/reports`,
    {
      reports: data
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useGenerateReport() {
  return useMutation(generateReport);
}
