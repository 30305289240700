import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { PolicySearchConfig, PolicySearchResults } from 'components/v2/App/interfaces';

export const getPolicies = async (query: string, property_id?: number, config?: PolicySearchConfig) => {
  if (!property_id) {
    return [];
  }
  const { data } = await axios.get<PolicySearchResults>(`/v2/admin/properties/${property_id}/renter_policies`, {
    params: { query: query }
  });
  return data;
};

export function usePolicySearch(
  property_id: number | undefined,
  query: string,
  config?: PolicySearchConfig
): QueryObserverResult<PolicySearchResults> {
  return useQuery<PolicySearchResults>(['policySearch', query, property_id], () => {
    if (!property_id) {
      return [];
    }
    return getPolicies(query, property_id, config);
  });
}
