/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import { NAV_WIDTH } from './utils/constants';

type Props = { width?: string; isFullScreen?: boolean } & JSX.IntrinsicElements['div'];

const modalCSS = css({
  position: 'fixed',
  display: 'flex',
  height: '100vh',
  zIndex: 200,
  background: 'rgba(0, 0, 0, 0.65)'
});

const modalContentCSS = css({
  background: PALETTE.neutralLight,
  margin: '24px 16px'
});

const DrawerModal = (props: Props) => {
  return (
    <div
      css={[
        modalCSS,
        {
          width: props.isFullScreen ? `calc(100vw - ${NAV_WIDTH.expanded})` : '575px',
          top: 0,
          left: props.isFullScreen ? NAV_WIDTH.expanded : 790
        }
      ]}
    >
      <div css={{ width: props.width || '85%', background: PALETTE.neutralLight, margin: 'auto', borderRadius: '8px' }}>
        <div css={modalContentCSS}>{props.children}</div>
      </div>
    </div>
  );
};

export default DrawerModal;
