import styled from '@emotion/styled';
import { PALETTE, Typeahead, FONTS } from '@sayrhino/rhino-shared-js';
import { getUnits } from 'api/v2/usePropertyUnitSearch';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import DomPurify from 'dompurify';
import { RefObject } from './PropertyTypeahead';
import { IPropertyUnitSearchResult } from 'components/v2/App/interfaces';

interface PropertyUnitTypeaheadProps {
  onSelect: (id?: number) => void;
  propertyId?: number;
}

interface IFormattedUnit {
  label: string;
  value: number;
}

const NoUnitsMessage = styled.span({
  color: PALETTE.alert125
});

const EnabledAddressOption = styled.li([FONTS.p2], {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0'
});

const EnabledAddressText = styled.span({
  color: PALETTE.neutral88,
  i: {
    fontWeight: 'bold'
  },
  '@media(max-width:425px)': {
    maxWidth: '250px'
  }
});

const EnabledAddressValueText = styled.p([FONTS.p2], {
  color: PALETTE.neutral88,
  margin: '0',
  fontSize: '16px'
});

export const PropertyUnitInviteTypeahead = forwardRef((props: PropertyUnitTypeaheadProps, ref: Ref<RefObject>) => {
  const { propertyId, onSelect } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [noOptionsMessage, setNoOptionsMessage] = useState<string | object>('');
  const [units, setUnits] = useState<IPropertyUnitSearchResult[]>([]);

  useImperativeHandle(ref, () => {
    return {
      reset
    };
  });

  const reset = () => {
    setSearchTerm('');
  };

  const sanitize = (html) => DomPurify.sanitize(html);

  useEffect(() => {
    let mounted = true;
    (async () => {
      setNoOptionsMessage('Searching...');
      const matchingUnits = await getUnits(searchTerm, propertyId);
      // ensure component is still mounted before updating state to prevent memory leak
      if (mounted) {
        setUnits(matchingUnits);
        setNoOptionsMessage(<NoUnitsMessage>No matches found</NoUnitsMessage>);
      }
    })();

    return () => { mounted = false; } // cleanup function
  }, [searchTerm, propertyId]);

  const boldSearchTerm = (str, substr) => {
    const term = new RegExp('(' + substr + ')', 'ig');
    return str.replace(term, `<i>$1</i>`);
  };

  const formatOptionLabel = ({ value, label }, { context }) => (
    <div>
      {context === 'value' && <EnabledAddressValueText>{label}</EnabledAddressValueText>}
      {context === 'menu' && (
        <EnabledAddressOption>
          <EnabledAddressText dangerouslySetInnerHTML={{ __html: sanitize(boldSearchTerm(label, searchTerm)) }} />
        </EnabledAddressOption>
      )}
    </div>
  );

  const getUnitProperty = (unitName: string) => {
    return units?.find((unit) => unit.address_line_two === unitName);
  };

  const onSelectItem = (formattedUnit: IFormattedUnit) => {
    const selectedUnit = getUnitProperty(formattedUnit.label);
    selectedUnit && onSelect(selectedUnit?.id);
    setSearchTerm(formattedUnit.label);
  };

  const formatUnits = (units) =>
    units?.map((unit) => {
      return { label: unit.address_line_two, value: unit.id };
    });

  return (
    <div title="Lease unit container">
      <Typeahead
        label="Lease unit"
        placeholder=""
        onInputChange={setSearchTerm}
        id="PropertyUnitTypeahead"
        options={units?.length ? formatUnits(units) : []}
        onChange={onSelectItem}
        noOptionsMessage={() => noOptionsMessage}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
});
