import React from 'react';
import { useNavigate } from 'react-router-dom';
import usePropertyOwnerDetails from '../../../../api/v2/usePropertyOwnerDetails';
import useUpdatePropertyOwnerMutation from '../../../../api/v2/updatePropertyOwner';
import { validateRequiredField } from 'components/v2/App/utils/formValidations';
import { useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { PMAPropertyOwnersKey } from 'api/v2/usePMAPropertyOwners';

type RequestError = {
  errors?: string[];
  error?: string;
};
export const usePropertyOwner = (propertyOwnerId: number) => {
  const { data, isSuccess } = usePropertyOwnerDetails(Number(propertyOwnerId)) ?? {};
  const navigate = useNavigate();
  // property owner details fields
  const [name, setName] = React.useState<string>(data?.name || '');
  const [nameError, setNameError] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string[] | undefined>();

  const queryCache = useQueryClient();

  // mutation for react-query to update cache on put request
  const { mutate: updatePropertyOwnerMutation, isLoading } = useUpdatePropertyOwnerMutation();

  const validateForm = () => {
    let isValid = true;
    const nameValidation = validateRequiredField(name, 'Name');
    if (nameValidation) {
      setNameError(nameValidation);
      isValid = false;
    }
    return isValid;
  };

  const resetErrors = () => {
    setNameError(undefined);
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    resetErrors();

    if (validateForm()) {
      updatePropertyOwnerMutation(
        { name, id: propertyOwnerId },
        {
          onSuccess: () => {
            queryCache.removeQueries(['property owner details', Number(propertyOwnerId)], { exact: true });
            queryCache.invalidateQueries([PMAPropertyOwnersKey]);
            navigate(-1);
          },
          onError: (error: AxiosError<RequestError>) => {
            const errorResponses = error?.response?.data?.errors;
            const errorResponse = error?.response?.data?.error;
            if (errorResponse) {
              setErrorMessage([errorResponse]);
            } else if (errorResponses && errorResponses.length) {
              setErrorMessage(errorResponses);
            } else {
              setErrorMessage(['Sorry, something went wrong']);
            }
          }
        }
      );
    }
  };

  return {
    isLoading,
    handleSubmit,
    data,
    isSuccess,
    formField: { name, setName, nameError },
    errorMessage
  };
};

export default usePropertyOwner;
