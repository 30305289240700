/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import InlineStatusBanner from './InlineStatusBanner';
import { Card, FONTS, PALETTE, LegacyTag, PersonCircleIcon } from '@sayrhino/rhino-shared-js';
import { IStatusDetail, ClaimStatus } from './interfaces';
import { IUserRole, useUserRole } from './utils/userRole';
import { initializeDate, stringifyDate, numberToUSDFormatter, getInitialStr, getTagVariant } from './utils';
import { generateMaskedStatus } from './utils/generateMaskedStatus';
import {
  StyledAssigneeInitialsP as StyledAssigneeInitialsPBase,
  StyledIndicatorIcon,
  StyledOtherClaimTag
} from './Claims/ClaimDetails/ClaimStyles';
interface IProps {
  administrator: string;
  amount: number;
  claimNumber: string;
  claimType: string;
  property: {
    address_line_one: string;
  };
  remainingCoverageAmount: number;
  status: string;
  tenantFullName: string;
  reportedAt: string;
  statusDetails?: IStatusDetail[] | undefined;
  ownerClassification: string | undefined;
  styles?: any;
}

const nLineTruncate = (lineNumber: number, flexGrow?: 1 | undefined, wordBreak?: 'break-all' | undefined) =>
  css({
    display: '-webkit-box',
    WebkitLineClamp: lineNumber,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    boxSizing: 'content-box',
    wordBreak,
    flexGrow,
    overflow: 'hidden',
    marginRight: '12px'
  });

const StyledSectionWrapper = styled.div({
  flex: '1',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
});

const StyledDetailInfoSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '4px',
  '&:last-child': {
    marginRight: '0px'
  }
});

const StyledVerticalDivider = styled.div({
  width: '1px',
  background: PALETTE.neutral12,
  margin: '0px 16px',
  height: '50px',
  alignSelf: 'center'
});

const StyledHorizontalDivider = styled.div({
  margin: '8px 0px 10px 0px',
  borderTop: `1px solid ${PALETTE.neutral12}`
});

const StyledCardBanner = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const StyledTenantName = styled.span([
  FONTS.h4,
  {
    marginBottom: '12px',
    ...nLineTruncate(1, 1, 'break-all')
  }
]);

const StyledClaimNumber = styled.span([FONTS.p2, { color: PALETTE.neutral88, whiteSpace: 'nowrap' }]);

const StyledClaimNumAndStatusWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const StyledPersonCircleIcon = styled(PersonCircleIcon)({ color: PALETTE.neutral25, marginRight: '12px' });

const StyledStatusWrapper = styled.div([FONTS.neutral88, { margin: '0.25rem' }]);

const StyledNewStatusTag = styled(LegacyTag)([
  FONTS.h6Medium,
  {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '28px',
    padding: '2px 2px 2px 4px',
    marginLeft: '12px',
    background: PALETTE.neutralLight,
    border: `1px solid ${PALETTE.neutral12}`
  }
]);
const StyledLabelP = styled.p([FONTS.labelMedium, { marginBottom: '4px' }]);

const StyledCardSectionWrapper = styled.div({ display: 'flex', marginTop: '12px' });

const StyledDateP = styled.p([FONTS.p2Medium, { color: PALETTE.neutral88 }]);

const StyledTotalAmountP = styled.p([FONTS.p2Medium, { color: PALETTE.interaction100 }]);

const StyledPropertyAddressP = styled.p([
  FONTS.p2Medium,
  { color: PALETTE.neutral88, marginRight: '12px', ...nLineTruncate(2) }
]);

const StyledClaimCard = styled(Card)({
  height: 'fit-content',
  padding: '20px 32px',
  position: 'relative'
});

const InlineStatusBannerWrapper = styled.div({
  margin: '0 -24px -12px -24px',
  display: 'flex'
});

const StyledAssigneeAndClaimNumWrapper = styled.div({
  display: 'flex',
  alignItems: 'center'
});

const StyledAssigneeInitialsP = styled(StyledAssigneeInitialsPBase)({
  marginRight: '12px',
  marginLeft: '0',
  backgroundColor: PALETTE.neutralDark,
  color: PALETTE.neutralLight,
  fontWeight: 'bold'
});

export const convertClaimUpdateToCardProps = (claimUpdate, userRole: IUserRole) => {
  const attributes = { ...claimUpdate };

  return {
    administrator: attributes.administrator || '',
    amount: attributes.amount_cents / 100,
    claimType: attributes.claim_type,
    property: {
      address_line_one: attributes.property_address_line_one
    },
    remainingCoverageAmount: attributes.remaining_amount_cents / 100,
    status: generateMaskedStatus(attributes.status, userRole),
    tenantFullName: attributes.renter_full_name,
    claimNumber: attributes.claim_number,
    reportedAt: attributes.created_at,
    statusDetails: attributes.status_detail,
    ownerClassification: attributes.property_owner_classification
  };
};

export const ClaimCard = (props: IProps) => {
  const {
    administrator,
    amount,
    claimType,
    property,
    remainingCoverageAmount,
    status,
    tenantFullName,
    claimNumber,
    reportedAt,
    statusDetails,
    ownerClassification,
    ...rest
  } = props;

  const { isAdmin } = useUserRole();

  return (
    <StyledClaimCard style={{ height: 'auto', width: 'auto' }}>
      <StyledCardBanner>
        <StyledTenantName>{tenantFullName}</StyledTenantName>
        {ownerClassification && (
          <StyledOtherClaimTag>{ownerClassification}</StyledOtherClaimTag>
        )}
      </StyledCardBanner>
      <StyledCardSectionWrapper>
        <StyledSectionWrapper>
          <StyledDetailInfoSection>
            <StyledLabelP>Claim amount</StyledLabelP>
            <StyledTotalAmountP>{numberToUSDFormatter(amount)}</StyledTotalAmountP>
          </StyledDetailInfoSection>
          <StyledDetailInfoSection>
            <StyledLabelP>Remaining coverage</StyledLabelP>
            <StyledDateP>{numberToUSDFormatter(remainingCoverageAmount)}</StyledDateP>
          </StyledDetailInfoSection>
        </StyledSectionWrapper>
        <StyledVerticalDivider />
        <StyledSectionWrapper>
          <StyledDetailInfoSection>
            <StyledLabelP>Property</StyledLabelP>
            <StyledPropertyAddressP>{property.address_line_one}</StyledPropertyAddressP>
          </StyledDetailInfoSection>
          <StyledDetailInfoSection style={{ paddingRight: '17px', flexShrink: 0 }}>
            <StyledLabelP>Reported</StyledLabelP>
            <StyledDateP>{stringifyDate(initializeDate(reportedAt))}</StyledDateP>
          </StyledDetailInfoSection>
        </StyledSectionWrapper>
      </StyledCardSectionWrapper>
      <StyledHorizontalDivider />
      <StyledClaimNumAndStatusWrapper>
        <StyledAssigneeAndClaimNumWrapper>
          {isAdmin &&
            (administrator ? (
              <StyledAssigneeInitialsP>{getInitialStr(administrator)}</StyledAssigneeInitialsP>
            ) : (
              <StyledPersonCircleIcon />
            ))}
          <StyledClaimNumber>{`Claim #${claimNumber}`}</StyledClaimNumber>
        </StyledAssigneeAndClaimNumWrapper>
        {status === ClaimStatus.NEW ? (
          <StyledNewStatusTag>
            <StyledStatusWrapper>{status}</StyledStatusWrapper>
            <StyledIndicatorIcon />
          </StyledNewStatusTag>
        ) : (
          <StyledOtherClaimTag variant={getTagVariant(status)}>{status}</StyledOtherClaimTag>
        )}
      </StyledClaimNumAndStatusWrapper>
      {statusDetails &&
        statusDetails.map((statusDetail, index) => (
          <InlineStatusBannerWrapper key={index}>
            <InlineStatusBanner statusDetail={statusDetail} style={{ marginTop: '12px' }} />
          </InlineStatusBannerWrapper>
        ))}
    </StyledClaimCard>
  );
};

export default ClaimCard;
