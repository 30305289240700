/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
const componentRequireContext = (require as any).context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

import tables from 'components/datatables';
import TwoFactorDisableConfirmModal from 'components/modals/2fa_disable_modal';

$(document).ready(function() {
  tables.map((t) => new t().render());

  new TwoFactorDisableConfirmModal();

  $('#add_attachment_link').on('click', (e: JQuery.ClickEvent): void => {
    e.preventDefault();

    const wrapper = $('#attachment_fields_node');
    const node = wrapper.find('> .form-group:first-child').clone();

    node.find('input').val('');
    node.find('.remove-link-wrapper').removeClass('hidden');

    wrapper.append(node);
  });

  $('#attachment_fields_node').on('click', '#remove_attachment_link', (e: JQuery.ClickEvent): void => {
    e.preventDefault();
    $(e.target).closest('.renter_policy__field').remove();

    const wrapper = $('#attachment_fields_node');

    if (wrapper.find('> .form-group').length === 1) {
      wrapper.find('.remove-link-wrapper').addClass('hidden');
    };
  });
});
