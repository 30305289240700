import React from 'react';
import styled from '@emotion/styled';
import { LegacyTag as TagBase, PALETTE } from '@sayrhino/rhino-shared-js';
import { Property } from './NewTeamMember';
import { useFormContext } from 'components/v2/App/utils/propertyMemberAssign';

const TagWrapper = styled.div({ display: 'flex', flexWrap: 'wrap', marginTop: '12px' });
const Tag = styled(TagBase)({
  paddingRight: '12px',
  border: `1px solid ${PALETTE.neutral25}`,
  color: PALETTE.neutral25,
  margin: '2px'
});

export interface IProps {
  properties: Property[];
}

const PropertyTags: React.FC<IProps> = ({ properties }: IProps) => {
  const { properties: list } = useFormContext();
  const allPropertiesSelected = properties.length === list?.length;

  return (
    <TagWrapper>
      {allPropertiesSelected ? (
        <Tag variant="state">All properties</Tag>
      ) : (
        properties.map(({ property_id, building_name }) => (
          <Tag variant="state" key={property_id}>
            {building_name}
          </Tag>
        ))
      )}
    </TagWrapper>
  );
};

export default PropertyTags;
