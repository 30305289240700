import React, { useState } from 'react';
import styled from '@emotion/styled';
import { QueryClient, QueryClientProvider } from 'react-query';
import AddProperty from './batchUploadSteps/AddProperty';
import { CloseIcon, PALETTE, Button, FONTS } from '@sayrhino/rhino-shared-js';
import UploadProperties from './batchUploadSteps/UploadProperties';
import CsvErrors from './batchUploadSteps/CsvErrors';
import { PropertyCsvUploadResponse } from 'components/tables/property_upload_table/interfaces';

import CsvSuccess from './batchUploadSteps/CsvSuccess';
import UploadComplete from './batchUploadSteps/UploadComplete';
import AssignNewTeam from './batchUploadSteps/AssignNewTeam';

const queryClient = new QueryClient();

export type UserTrackDetails = {
  first_name: string;
  last_name: string;
  email: string;
  user_id: number;
  user_type: string;
  portal_session_id: string;
};

interface IProps {
  closeModal: (currentView: string) => void;
  isAdmin: boolean;
  user: UserTrackDetails;
}

const HelpButton = styled(Button)([
  FONTS.p2Medium,
  {
    background: PALETTE.neutralDark,
    color: PALETTE.neutralLight,
    height: '32px',
    padding: '5px 20px',
    width: '150px',
    margin: '0 12px'
  }
]);

export type PropertyCsvUploadStep =
  | 'AddProperty'
  | 'UploadProperties'
  | 'CsvErrors'
  | 'CsvSuccess'
  | 'UploadComplete'
  | 'AssignTeam';

interface IStepData {
  step: PropertyCsvUploadStep;
  options?: PropertyCsvUploadResponse;
}

function Modal(props: IProps) {
  const singlePropertyUpload: boolean = (window as any).App?.featureFlags?.singlePropertyUpload;
  let step;

  if (props.isAdmin || !singlePropertyUpload) {
    step = 'AddProperty';
  } else if (singlePropertyUpload) {
    step = 'UploadProperties';
  }

  const [stepData, setStepData] = useState<IStepData>({ step });

  const help = () =>
    window.open('https://support.sayrhino.com/hc/en-us/categories/360003291332-Reaching-Rhino', '_blank');

  const currentStep = () => {
    switch (stepData.step) {
      case 'AddProperty':
        return <AddProperty onSelect={updateStep} isAdmin={props.isAdmin} user={props.user} />;
      case 'UploadProperties':
        return <UploadProperties onSubmit={updateStep} />;
      case 'CsvErrors':
        if (stepData.options) {
          return <CsvErrors data={stepData.options} onSubmit={updateStep} />;
        }
      case 'CsvSuccess':
        if (stepData.options) {
          return <CsvSuccess data={stepData.options} onSubmit={updateStep} />;
        }
      case 'UploadComplete':
        if (stepData.options) return <UploadComplete data={stepData.options} onSubmit={updateStep} />;
      case 'AssignTeam':
        if (stepData.options) return <AssignNewTeam data={stepData.options} onSubmit={updateStep} />;
      default:
        return null;
    }
  };

  const updateStep = (nextStep: PropertyCsvUploadStep, nextStepOptions?: PropertyCsvUploadResponse) => {
    setStepData({
      step: nextStep,
      options: nextStepOptions
    });
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="fullscreen-modal">
        <div className="fullscreen-modal-navbar">
          <div className="fullscreen-modal-logo"></div>
          <div className="fullscreen-modal-right">
            <HelpButton variant="tertiary" onClick={help}>
              Need help ?
            </HelpButton>
            <div className="fullscreen-modal-close" onClick={() => props.closeModal(stepData.step)} role="main">
              <CloseIcon style={{ color: PALETTE.neutralDark, width: '18px', height: '18px' }} />
            </div>
          </div>
        </div>
        {currentStep()}
      </div>
    </QueryClientProvider>
  );
}

export default Modal;
