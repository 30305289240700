import styled from '@emotion/styled';
import { PALETTE, FONTS, CloseIcon } from '@sayrhino/rhino-shared-js';
import { BREAKPOINTS, NAV_WIDTH } from '../../utils/constants';
import { mediaQueryWidth } from '../../utils';

export const Page = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1152,
  padding: 32,
  position: 'relative',
  marginLeft: NAV_WIDTH.expanded,
  [mediaQueryWidth({ value: BREAKPOINTS.xl })]: {
    width: `calc(100% - ${NAV_WIDTH.collapsed})`,
    marginLeft: NAV_WIDTH.collapsed
  }
});

export const Close = styled.a({
  borderRadius: '30px',
  backgroundColor: PALETTE.neutralLight,
  width: '30px',
  height: '30px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `0.5px solid ${PALETTE.neutral55}`,
  borderColor: PALETTE.neutral55,
  position: 'absolute',
  top: '32px',
  right: '32px'
});

export const Header = styled.div({
  width: '900px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '24px'
});

export const LargeHeader = styled.h2([FONTS.h1, { fontSize: '32px', lineHeight: '40px' }]);
