import axios from 'axios';
import { csrfToken } from 'utils/document';
import { IBankAccountInfo } from 'components/v2/App/DepositFunds/types';

const deleteBankAccount = async ({id, accountType}) => {
  const token = csrfToken();
  const { data } = await axios.delete<IBankAccountInfo>(
    `/admin/funds/accounts/${id}`,
    {
      params: {
        bank_account_uid: id,
        account_type: accountType,
      },
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return data;
};


export default deleteBankAccount;
