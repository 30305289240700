/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
  PALETTE,
  MinimizeIcon,
  ButtonLink
} from '@sayrhino/rhino-shared-js';
import { Link } from 'react-router-dom';

const DivContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px'
});

const DivContent = styled.div({
  backgroundColor: '#F5F6F7',
  width: '480px',
  padding: '20px',
  fontWeight: 'bold',
  color: '#6B3BF5',
  borderRadius: '10px'
});

const DivActionButtons = styled.div({
  display: 'flex',
  justifyContent: 'right',
  paddingTop: '20px'
});

const NavBar = (props) => {
  return (
    <div
      css={{
        borderBottom: '1px solid #F4F4F4',
        backgroundColor: PALETTE.neutralLight,
        height: '3.75rem',
        padding: '20px 48px',
        position: 'sticky',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 1
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Link
          to={props.referrer}
          css={{ display: 'flex', alignItems: 'center', marginRight: 48 }}
          aria-label="collapse"
        >
          <MinimizeIcon />
        </Link>
      </div>
    </div>
  );
};

const SuccessCancellationRequestDenial = ({
  cancellation_request,
  renterName,
  referrer
}) => {
  return (
    <div>
      <NavBar referrer={referrer} />
      <DivContainer>
        <DivContent>
          {
            cancellation_request.early_termination ?
              "Cancellation request successfully denied." :
              `Successfully denied ${renterName}’s cancellation request. They have been notified of the decision.`
          }
          <DivActionButtons>
            <ButtonLink
              id="get-started"
              type="button"
              size="small"
              usage="primary"
              to={`/admin/renter_policies`}>
              Back to Policies
            </ButtonLink>
          </DivActionButtons>
        </DivContent>
      </DivContainer>
    </div>
  );
};

export default SuccessCancellationRequestDenial;
