import React from 'react';
import {
  Subtitle,
  DisplayField,
  CoverageValue as Value,
  CoverageLabel as Label,
  SpacingAbove,
  StyledContent
} from './Styled';
import styled from '@emotion/styled';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import useUserRole from '../utils/userRole';

const LiabilityCoverageText = styled.span([FONTS.p1Medium], {
  color: PALETTE.neutral55
});

const RentersInsuranceRequirementDetails = ({ rentersInsuranceRequirements }) => {
  const { isAdmin, isPropertyManagerAdmin } = useUserRole();

  const NotAvailableText = styled(LiabilityCoverageText)([FONTS.p1]);

  const getDisplayForCoverage = () => {
    if (!rentersInsuranceRequirements) {
      return <NotAvailableText>Not specified</NotAvailableText>;
    } else if (rentersInsuranceRequirements?.liability_coverage) {
      return (
        <>
          Required • <LiabilityCoverageText>{rentersInsuranceRequirements?.liability_coverage}</LiabilityCoverageText>
        </>
      );
    } else if (rentersInsuranceRequirements?.requires_renters_insurance) {
      return 'Required';
    } else {
      return 'Not required';
    }
  };

  const getDisplayForExclusiveAgreement = () => {
    if (rentersInsuranceRequirements?.has_exclusive_agreement) {
      return 'Yes';
    } else if (
      rentersInsuranceRequirements &&
      !rentersInsuranceRequirements?.has_exclusive_agreement &&
      rentersInsuranceRequirements?.has_exclusive_agreement !== null
    ) {
      return 'No';
    } else {
      return <NotAvailableText>Not specified</NotAvailableText>;
    }
  };
  return (
    <>
      <Subtitle>Renters Insurance</Subtitle>
      {isAdmin && (
        <StyledContent>
          This information is used to help ensure each renter is covered with Rhino Renters Insurance, if required.
        </StyledContent>
      )}
      <SpacingAbove>
        <DisplayField>
          <Value id="coverage">{getDisplayForCoverage()}</Value>
          <Label htmlFor="coverage">{isPropertyManagerAdmin ? 'Required Coverage' : 'Renters insurance'}</Label>
        </DisplayField>
      </SpacingAbove>
      {isAdmin && (
        <DisplayField>
          <Value id="exclusive-agreement">{getDisplayForExclusiveAgreement()}</Value>
          <Label htmlFor="exclusive-agreement">Exclusive agreement</Label>
        </DisplayField>
      )}
    </>
  );
};

export default RentersInsuranceRequirementDetails;
