/** @jsx jsx */
/** @jsxFrag */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { groupBy, flatten } from 'lodash';
import { Button, Loading, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import {
  IData,
  ISearchResult,
  SortOrder,
  SearchResultType,
  ISearchMeta,
  ISearchResultCountsByType,
  IUpdate
} from './interfaces';

import { getHumanizedSearchResultType } from './Search';
import SearchResultItem from './SearchResultItem';
import SearchResultsTypeFilter from './SearchResultsTypeFilter';
import EmptySearchResults from './EmptySearchResults';

interface IProps {
  isLoading: boolean;
  results: IData<ISearchResult[], ISearchMeta>[] | undefined;
  onClickFetchMore: (Event) => void;
  onChangeType: (ISearchResultType) => void;
  canFetchMore: boolean;
  isFetchingMore: boolean;
  type: SearchResultType | null;
}

interface IResultsByType {
  [type: string]: IUpdate[];
}

const Divider = styled.div({
  margin: '12px -15px 24px 0px',
  borderTop: `1px solid ${PALETTE.neutral12}`
});

const Header = styled.p([FONTS.h3, { marginBottom: '24px' }]);

const NoResultsText = styled.p([FONTS.h3]);

const SearchResultItemContainer = styled.div({
  marginBottom: 24
});

const SearchResultsContainer = styled.div({
  marginTop: 24
});

const partitionResultsByType = (results: IData<ISearchResult[]>[]): IResultsByType => {
  const flatResults: IUpdate[] = flatten(results.map((group) => group.data));
  return groupBy(
    flatResults.filter((x) => x !== null),
    'type'
  );
};

const SearchResults: React.FC<IProps> = ({
  isLoading,
  results,
  type,
  onClickFetchMore,
  onChangeType,
  canFetchMore
}: IProps) => {
  /**
   * Memoize counts from the results to the component state
   * so that they can still be rendered between requests for
   * the filtered results.
   */
  const getCountsByTypeFromResults = (): ISearchResultCountsByType => {
    if (results && results[0].meta.count_by_collection) {
      return results[0].meta.count_by_collection;
    }
    return countsByType;
  };
  const [countsByType, setCountsByType] = useState<ISearchResultCountsByType>(getCountsByTypeFromResults());
  useEffect(() => setCountsByType(getCountsByTypeFromResults()), [results]);

  const renderResults = () => {
    if (results && results[0].data.length) {
      const partitionedResults = partitionResultsByType(results);
      return (
        <>
          {Object.keys(partitionedResults).map((type) => (
            <>
              <Divider />
              <Header>{getHumanizedSearchResultType(type)}</Header>
              {partitionedResults[type].map((result) => (
                <SearchResultItemContainer key={result.id}>
                  <SearchResultItem result={result} />
                </SearchResultItemContainer>
              ))}
            </>
          ))}

          {canFetchMore && <Button onClick={onClickFetchMore}>Load More</Button>}
        </>
      );
    } else if (results && results[0].data.length === 0) {
      return (
        <EmptySearchResults>
          <NoResultsText>Looks like nothing matches your search.</NoResultsText>
        </EmptySearchResults>
      );
    }
  };

  return (
    <SearchResultsContainer>
      {countsByType && (
        <SearchResultsTypeFilter counts={countsByType} value={type} onFilterResultsByType={onChangeType} />
      )}
      {renderResults()}
      {isLoading && <Loading color={PALETTE.neutralDark} />}
    </SearchResultsContainer>
  );
};

export default SearchResults;
