/** @jsx jsx */
import React, { useMemo, useRef, useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { Button, ButtonHabitat, FONTS, Loading, PlusIcon } from '@sayrhino/rhino-shared-js';
import emptyImage from '../../../../assets/images/landlord.svg';

import { useSegmentTrackOnLoad } from './utils/segmentTracker';
import { PageTitle, PageTitleWrapper } from './Integrations/Sites/SitesStyles';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import useDepositFundsListWrapper from 'api/v2/useDepositFundsListWrapper';
import useWithdrawals from 'api/v2/useWithdrawals';
import WithdrawalCard from './WithdrawalCard';
import { IWithdrawal, IWithdrawalAttributes } from './DepositFunds/types';
import useUserRole from './utils/userRole';
import axios from 'axios';
import { csrfToken } from 'utils/document';
import useMounted from './utils/useMounted';

const StyledButton = styled(ButtonHabitat)({ float: 'right', marginRight: '48px', marginBottom: '32px' });
const StyledPlusIcon = styled(PlusIcon)({
  paddingLeft: '8px'
});

const PropertyOwnerSelector = ({ propertyOwnerList, selectedId, onChange }) => {
  const [currentlySelectedId, setCurrentlySelectedId] = useState(selectedId);
  const { invalidate: invalidateQueries } = useDepositFundsListWrapper();
  const [isFetching, setIsFetching] = useState(false);
  const mounted = useMounted();

  const handleSelect = async (e) => {
    if (e.target.value === selectedId) return;
    setCurrentlySelectedId(e.target.value);
    setIsFetching(true);
    const authenticity_token = csrfToken();
    const selectedPropertyOwnerId = e.target.value;
    await axios.post(`/admin/select_deposit_funds_property_owner?property_owner_id=${selectedPropertyOwnerId}`, {
      authenticity_token
    });
    if (!mounted) return;
    onChange(e.target.value);
    invalidateQueries();
    setIsFetching(false);
  };

  return (
    <fieldset css={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <label htmlFor="property-owner-select" css={[FONTS.label]}>
        Property Owner
      </label>
      <select
        style={{ width: '100%' }}
        id="property-owner-select"
        onChange={handleSelect}
        value={currentlySelectedId}
        css={{
          color: '#212526',
          padding: '12px 8px',
          borderRadius: '8px',
          border: '1px solid #ADB4B8',
          width: ['100%', 'fit-content'],
          fontSize: '14px',
          lineHeight: ['1.25rem', 1.25]
        }}
        disabled={isFetching}
      >
        {!selectedId && (
          <option value={undefined} key={0}>
            Please select a property owner
          </option>
        )}
        {propertyOwnerList.map((propertyOwner) => {
          return (
            <option value={propertyOwner.id} key={propertyOwner.id}>
              {propertyOwner.name}
            </option>
          );
        })}
      </select>
    </fieldset>
  );
};

const DepositFundsListWrapper = () => {
  const [selectedPropertyOwnerId, setSelectedPropertyOwnerId] = useState<string | undefined>(undefined);
  const { data, isLoading } = useDepositFundsListWrapper();
  const { propertyOwnerId, propertyOwnerList } = data || { propertyOwnerId: undefined, propertyOwnerList: [] };

  useEffect(() => {
    setSelectedPropertyOwnerId(
      propertyOwnerId || (propertyOwnerList.length === 1 ? String(propertyOwnerList[0].id) : undefined)
    );
  }, [isLoading]);

  if (isLoading)
    return (
      <div
        css={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100%', padding: '24px' }}
      >
        <Loading />
      </div>
    );

  if (!selectedPropertyOwnerId) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '16px',
          padding: '16px'
        }}
      >
        <h3 css={[FONTS.h3]}>Deposit Funds</h3>
        <PropertyOwnerSelector
          propertyOwnerList={propertyOwnerList}
          selectedId={selectedPropertyOwnerId}
          onChange={setSelectedPropertyOwnerId}
        />
      </div>
    );
  } else {
    return <DepositFundsList selectedPropertyOwnerId={selectedPropertyOwnerId} />;
  }
};

const DepositFundsList = ({ selectedPropertyOwnerId }) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const { isPropertyManagerAdmin } = useUserRole();
  const {
    isSuccess,
    isLoading: isLoadingWithdrawals,
    data: withdrawalsData,
    isFetching: isFetchingWithdrawals
  } = useWithdrawals({ enabled: !!selectedPropertyOwnerId });

  const propertyOwner = withdrawalsData?.property_owner;
  const propertyOwnerList = withdrawalsData?.property_owner_list;

  const withdrawals = withdrawalsData?.data || [];
  const [withdrawalsList, setWithdrawalsList] = useState<{ attributes: IWithdrawal }[]>([]);
  const [offset, setOffset] = useState<number>(0);

  const slicedWithdrawals: { attributes: IWithdrawal }[] = useMemo(
    () => withdrawals.slice(offset, offset + 20),
    [withdrawals, offset]
  );

  const showWithdrawalsList = isSuccess && withdrawalsList.length > 0;

  useEffect(() => {
    if (isSuccess) {
      setWithdrawalsList(() => {
        const filteredWithdrawals = withdrawals.filter(
          (w) =>
            !slicedWithdrawals.find(
              (sw: { attributes: IWithdrawal }) => sw.attributes.withdraw_uid === w.attributes.withdraw_uid
            )
        );
        return [...filteredWithdrawals, ...slicedWithdrawals];
      });
    }
  }, [withdrawals.length, slicedWithdrawals]);

  const onPropertyOwnerChange = () => {
    setWithdrawalsList([]);
    setOffset(0);
  };

  const Empty =
    isSuccess && !isFetchingWithdrawals ? (
      <div css={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <img src={emptyImage} css={{ display: 'block', margin: '5.75rem auto 2.5rem' }} alt="Empty" />
        <div css={[FONTS.p, { width: '23rem', textAlign: 'center' }]}>
          Looks like you don’t have any withdrawals yet. Start a new withdrawal to get started.{' '}
        </div>
        {isPropertyManagerAdmin && (
          <Link to={'/admin/funds/withdrawals/new'}>
            <StyledButton id="withdraw-funds" size="small" usage="primary" css={{ margin: '0 !important' }}>
              Withdraw funds
            </StyledButton>
          </Link>
        )}
      </div>
    ) : null;

  useSegmentTrackOnLoad('Withdrawals Viewed');

  const Withdrawals = isSuccess ? (
    <ul css={{ paddingTop: '8px' }}>
      {withdrawalsList.map((withdrawal: IWithdrawalAttributes, i: number) => (
        <li key={`withdraw_${withdrawal.withdraw_uid || i}`}>
          <WithdrawalCard withdrawal={{ ...withdrawal }} />
        </li>
      ))}
    </ul>
  ) : null;

  const LoadMoreButtonOrDiv = (
    <Button onClick={() => setOffset(offset + 20)} disabled={false} variant="secondary">
      Load More
    </Button>
  );

  const LoadingIndicator = (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '16px'
      }}
    >
      <Loading />
    </div>
  );

  if (isLoadingWithdrawals) return LoadingIndicator;

  const showPropertyOwnerSelector = propertyOwnerList && propertyOwnerList.length > 1 && propertyOwner;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '480px',
        margin: 'auto',
        padding: '1.875rem 0'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '1.875rem' }}>
        <h2 css={[FONTS.h3]}>Deposit Funds</h2>
        {showPropertyOwnerSelector ? (
          <PropertyOwnerSelector
            propertyOwnerList={propertyOwnerList}
            selectedId={propertyOwner?.id}
            onChange={onPropertyOwnerChange}
          />
        ) : propertyOwner ? (
          <div css={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <p css={[FONTS.p]}>Property Owner:</p>
            <p css={[FONTS.p, { fontWeight: 'bold' }]}>{propertyOwner.name}</p>
          </div>
        ) : null}
      </div>
      {selectedPropertyOwnerId ? (
        <PageTitleWrapper>
          <PageTitle
            style={{
              paddingLeft: '0'
            }}
            css={{
              margin: '0 !important'
            }}
          >
            Withdrawals
          </PageTitle>
          <Link to={'/admin/funds/withdrawals/new'}>
            <StyledButton
              id={'new-deposit-withdrawal'}
              variant={'black'}
              usage={'secondary'}
              size="small"
              css={{ margin: '0 !important' }}
            >
              Withdraw funds
              <StyledPlusIcon />
            </StyledButton>
          </Link>
        </PageTitleWrapper>
      ) : null}
      {showWithdrawalsList ? Withdrawals : Empty}
      {isFetchingWithdrawals ? LoadingIndicator : null}
      {!isFetchingWithdrawals && showWithdrawalsList ? (
        <div
          ref={loadMoreRef}
          css={[
            FONTS.h4,
            {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              marginTop: '8px'
            }
          ]}
        >
          {offset < withdrawals.length ? LoadMoreButtonOrDiv : 'Nothing more to load'}
        </div>
      ) : null}
    </div>
  );
};

export default DepositFundsListWrapper;
