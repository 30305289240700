export type Nullable<T> = T | null | undefined;

export const isEmpty = (elem: unknown): boolean => {
  if (typeof elem === 'string') {
    return elem === '';
  }
  if (Array.isArray(elem)) {
    return [Array].includes((elem || {}).constructor as ArrayConstructor) && (elem?.length ?? 0) === 0;
  }
  if (typeof elem === 'object') {
    return (
      [Object].includes((elem || {}).constructor as ObjectConstructor) &&
      (Object.entries(elem || {})?.length ?? 0) === 0
    );
  }
  return elem === null;
};

const capitalize = (s: string): string | null => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const validateRequiredField = (
  value: Nullable<string | number | boolean | object>,
  fieldCaption: string
): string | null => {
  if (isEmpty(value) || !value?.toString().length || value === false) {
    return `${capitalize(fieldCaption)} required.`;
  }

  return null;
};

export const isValidEmail = (email: Nullable<string>): boolean => {
  if (!email) {
    return false;
  }
  if (!/^['A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return false;
  }

  return true;
};

export const validateEmail = (email: Nullable<string>): string | null => {
  if (!email || !email.length) {
    return 'Email required.';
  }
  if (!isValidEmail(email)) {
    return 'Enter a valid email address.';
  }

  return null;
};

export const isValidPhone = (text: Nullable<string | number>): boolean => {
  return text
    ? text
        .toString()
        .match(
          /^(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([0-9]{3})\s*\)|([0-9]{3}))\s*(?:[.-]\s*)?([0-9]{3})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i
        ) !== null
    : false;
};

export const validatePhone = (phone: Nullable<string | number>): string | null => {
  if (!phone || !phone.toString().length) {
    return 'Phone Number required.';
  }
  if (!isValidPhone(phone)) {
    return 'Please enter a valid phone address.';
  }

  return null;
};

export const doFieldsMatch = (field1: string, field2: Nullable<string>): boolean =>
  !isEmpty(field2) && field1 === field2;

export const validatePasswordsMatch = (
  password: Nullable<string>,
  passwordToMatch?: Nullable<string>
): string | null => {
  if (isEmpty(passwordToMatch) && !isEmpty(password)) {
    return 'Password confirmation required';
  }

  if (password && !doFieldsMatch(password, passwordToMatch)) {
    return 'Passwords do not match.';
  }

  return null;
};

export const validatePasswordLength = (password) => typeof password === 'string' && password.length >= 8;
export const validatePasswordContainsNumber = (password) => typeof password === 'string' && /\d/.test(password);
export const validatePasswordContainsSymbol = (password) =>
  typeof password === 'string' && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);
