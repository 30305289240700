/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { useEffect, useState } from 'react';
import { QueryObserverResult } from 'react-query';
import { IFilters, IFilterQueryParams, IPropertyFilter, IPropertyFilterOptions } from '../interfaces';
import Pagination from './Pagination';
import PropertyFilterOption from './PropertyFilterOption';
interface IPropertyFiltersOptionsProps {
  css?: any;
  query: (args: IFilterQueryParams) => QueryObserverResult<IPropertyFilterOptions>;
  selectedProperties: IPropertyFilter[];
  selectedStates: string[];
  setFilters: (args: Partial<IFilters>) => void;
}

const PropertyFiltersOptions: React.FC<IPropertyFiltersOptionsProps> = (props) => {
  const { query, selectedProperties, selectedStates, setFilters, ...rest } = props;
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (selectedStates.length > 0) {
      const newProperties = selectedProperties.filter((property) => selectedStates.includes(property.address_state));

      setFilters({ properties: newProperties });
    }

    setPage(1);
  }, [selectedStates]);

  const { data: resolvedData } = query({
    filter: { states: selectedStates },
    page,
    per: 10
  });

  return resolvedData ? (
    <div {...rest}>
      <div>
        <h5 css={[FONTS.h5, { color: PALETTE.neutral88, marginBottom: '1.5rem' }]}>Property</h5>
      </div>
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          marginBottom: '1rem',
          flexDirection: resolvedData?.meta?.total_count <= 5 ? 'column' : 'row'
        }}
      >
        {resolvedData?.data?.map((property, index) => {
          return (
            <PropertyFilterOption
              resolvedData={resolvedData}
              setFilters={setFilters}
              selectedProperties={selectedProperties}
              property={property}
              index={index}
              key={`${property.building_name}-${index}`}
            />
          );
        })}
      </div>

      {resolvedData?.meta?.total_count > 10 && (
        <Pagination
          currentPageCount={resolvedData?.data?.length}
          nextPage={resolvedData?.meta?.next_page}
          page={page}
          per={resolvedData?.meta?.per}
          prevPage={resolvedData?.meta?.prev_page}
          setPage={setPage}
          totalCount={resolvedData?.meta?.total_count}
          totalPages={resolvedData?.meta?.total_pages}
        />
      )}
    </div>
  ) : null;
};

export default PropertyFiltersOptions;
