import cancelWithdrawal from 'api/v2/cancelWithdrawal';
import { useMutation, useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import useToast from '../../toast/use-toast';
import { AxiosError } from 'axios';
import React from 'react';
import { withdrawalsQueryKey } from 'api/v2/useWithdrawals';

const useCancelWithdrawalView = () => {
  const [errors, setErrors] = React.useState(null);

  const { mutate: cancelWithdrawalMutation } = useMutation(cancelWithdrawal, {});

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const handleCancelWithdrawal = (id: string) => {
    cancelWithdrawalMutation(id, {
      onSuccess: (res) => {
        const path = generatePath(`/admin/funds`);
        queryClient.invalidateQueries(withdrawalsQueryKey);
        navigate(`${path}`);
        addToast('Withdrawal Cancelled');
      },
      onError: (e: AxiosError) => {
        handleError(e);
      }
    });
  };

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 422) {
      const data = error.response?.data;
      const responseErrors = JSON.parse(data.errors);
      setErrors(responseErrors);
    } else {
      navigate(-1);
      addToast('Something went wrong', 'error');
    }
  };

  return {
    errors,
    handleSubmit: handleCancelWithdrawal
  };
};

export { useCancelWithdrawalView };
