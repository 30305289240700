import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IStaticFilterOptions } from '../../components/v2/App/interfaces';

export const getInsurancePolicyStaticFilterOptions = async (): Promise<IStaticFilterOptions> => {
  const { data }: AxiosResponse<IStaticFilterOptions> = await axios.get<IStaticFilterOptions>(
    `/v2/admin/insurance_policies/static_filter_options`
  );

  return data;
};

export const insurancePolicyStaticFilterOptionsKey = 'insurancePolicyStaticFilterOptions';

export default function useInsurancePolicyStaticFilterOptions(): QueryObserverResult<IStaticFilterOptions> {
  return useQuery<IStaticFilterOptions>([insurancePolicyStaticFilterOptionsKey], () =>
    getInsurancePolicyStaticFilterOptions()
  );
}
