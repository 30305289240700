import React, { useState } from 'react';
import styled from '@emotion/styled';
import { StyledCardDetails } from '../ClaimDetails/ClaimStyles';
import { FONTS, ButtonHabitat, CurrencyInputUSD, DateInput as DateInputBase } from '@sayrhino/rhino-shared-js';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';
import { AxiosError } from 'axios';
import {
  useAddSubroPayment,
  SUBRO_PAYMENTS,
  useEditSubroPayment,
  useDeleteSubroPayment
} from 'api/v2/useSubrogationPayments';
import { Status } from './ClaimPaymentCard';
import { ISubrogationPayment } from '../../interfaces';
import { formatDate } from '../../utils/formatDate';
import WarningModal from '../../Integrations/WarningModal';

const CardHeader = styled.h5(FONTS.h5);

const DateInput = styled(DateInputBase)({
  width: '100%'
});

const ButtonRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const Row = styled(ButtonRow)({
  gap: '16px',
  width: '100%',
  justifyContent: 'flex-end'
});

const InputArea = styled.div({
  margin: '16px 0'
});

type IProp = {
  flipView: () => void;
  status: Status;
  payoutInfo?: ISubrogationPayment;
};

type Form = {
  paid_at: Date;
  recovered_amount_cents: number;
  processing_fee_cents: number;
  net_recovery_cents: number;
};

const SubrogationPaymentCard: React.FC<IProp> = ({ flipView, status, payoutInfo }) => {
  const paid_at = payoutInfo ? new Date(payoutInfo?.paid_at) : new Date();
  const [form, setForm] = useState<Form>(() => ({
    paid_at,
    recovered_amount_cents: payoutInfo?.recovered_amount_cents ?? 0,
    processing_fee_cents: payoutInfo?.processing_fee_cents ?? 0,
    net_recovery_cents: payoutInfo?.net_recovery_cents ?? 0
  }));
  const [errors, setErrors] = useState({});
  const { addToast } = useToast();
  const { claimsId } = useParams();
  const { addPayment, isLoading: saving } = useAddSubroPayment();
  const { editPayment, isLoading: editing } = useEditSubroPayment();
  const { deletePayment, isLoading: deleting } = useDeleteSubroPayment();
  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const queryClient = useQueryClient();

  const getError = (key: string) => {
    if (errors[key]) {
      return errors[key][0];
    }
  };

  const disableActions = () => {
    return saving || editing || deleting;
  };

  const isFormEmpty = () => {
    return !(Boolean(form.recovered_amount_cents) && Boolean(form.paid_at) && Boolean(form.net_recovery_cents));
  };

  const save = async () => {
    const data = {
      claimId: Number(claimsId),
      formInfo: form
    };

    addPayment(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries([SUBRO_PAYMENTS, Number(claimsId)]);
        flipView();
        addToast('Payment added');
      },
      onError: (err: AxiosError) => {
        const { errors } = err?.response?.data;
        setErrors(errors);

        if (errors.claim) {
          addToast(`Claim ${errors.claim}`, TOAST_STATUS.ERROR);
        }
      }
    });
  };

  const edit = async () => {
    const data = {
      claimId: Number(claimsId),
      payoutID: payoutInfo?.id,
      formInfo: form
    };

    editPayment(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries([SUBRO_PAYMENTS, Number(claimsId)]);
        flipView();
        addToast('Payment updated');
      },
      onError: (err: AxiosError) => {
        const { errors } = err?.response?.data;
        setErrors(errors);

        if (errors.claim) {
          addToast(`Claim ${errors.claim}`, TOAST_STATUS.ERROR);
        }
      }
    });
  };

  const remove = async () => {
    deletePayment(
      { claimId: claimsId, payoutId: payoutInfo?.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([SUBRO_PAYMENTS, Number(claimsId)]);
          flipView();
          addToast('Payment deleted');
        },
        onError: (err: AxiosError) => {
          addToast('An error occurred, please try again later', TOAST_STATUS.ERROR);
        }
      }
    );
  };

  return (
    <StyledCardDetails>
      {status == Status.Edit && (
        <WarningModal
          title="Are you sure you want to delete this payment?"
          subTitle="This action cannot be undone."
          isOpen={showModal}
          closeModal={closeModal}
          handleConfirm={remove}
          confirmButtonText="Yes, delete payment"
        />
      )}
      <CardHeader>{status === Status.Add ? 'Add' : 'Edit'} payments</CardHeader>
      <InputArea>
        <DateInput
          id="test-picker"
          selected={form.paid_at as Date}
          inputLabel="Select date of payment"
          isClearable={false}
          onChange={{
            onChange: (value, e) => setForm((prev) => ({ ...prev, paid_at: value })),
            onChangeRaw: (e) => null
          }}
          value={formatDate(form?.paid_at?.toLocaleDateString() ?? '')}
        />
        <CurrencyInputUSD
          id="recoveredAmount"
          value={form?.recovered_amount_cents}
          onChange={(value) => setForm((prev) => ({ ...prev, recovered_amount_cents: value }))}
          label="Enter payment amount"
          subtext={getError('recovered_amount_cents')}
        />
        <CurrencyInputUSD
          id="processingFee"
          value={form?.processing_fee_cents}
          onChange={(value) => setForm((prev) => ({ ...prev, processing_fee_cents: value }))}
          label="Enter processing fee"
          subtext={getError('processing_fee_cents')}
        />
        <CurrencyInputUSD
          id="netRecovered"
          value={form?.net_recovery_cents}
          onChange={(value) => setForm((prev) => ({ ...prev, net_recovery_cents: value }))}
          label="Enter net recovery amount"
          subtext={getError('net_recovery_cents')}
        />
      </InputArea>
      <ButtonRow>
        {status == Status.Edit && (
          <ButtonHabitat onClick={openModal} id="delete" variant="destructive" size="small" usage="secondary">
            Delete
          </ButtonHabitat>
        )}

        <Row>
          <ButtonHabitat id="cancel" variant="interaction" size="small" usage="primary" onClick={flipView}>
            Cancel
          </ButtonHabitat>
          <ButtonHabitat
            id="save"
            variant="interaction"
            size="small"
            usage="primary"
            disabled={isFormEmpty() || disableActions()}
            onClick={payoutInfo ? edit : save}
          >
            {saving || editing ? 'Saving' : 'Save Changes'}
          </ButtonHabitat>
        </Row>
      </ButtonRow>
    </StyledCardDetails>
  );
};

export default SubrogationPaymentCard;
