import styled from '@emotion/styled';
import { CheckIcon, PALETTE } from '@sayrhino/rhino-shared-js';
import React from 'react';

type StatusCheckIconProps = {
  active: boolean;
};

const StyledRoundIconDiv = styled.div<{ active: boolean }>(
  {
    borderRadius: '24px',
    height: 20,
    width: 20,
    display: 'flex',
    alignItems: 'center',
    background: PALETTE.interaction100
  },
  ({ active }) => !active && { background: PALETTE.neutral12 }
);

const StyledCheckIcon = styled(CheckIcon)({
  color: PALETTE.neutralLight,
  margin: '0 auto'
});

export const StatusCheckIcon = (props: StatusCheckIconProps) => {
  const { active } = props;
  return active ? (
    <StyledRoundIconDiv active={active}>
      <StyledCheckIcon height={16} width={16} />
    </StyledRoundIconDiv>
  ) : (
    <StyledRoundIconDiv active={active}>
      <StyledCheckIcon height={16} width={16} />
    </StyledRoundIconDiv>
  );
};
