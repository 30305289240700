import axios from 'axios';
import { IStates } from 'components/v2/App/interfaces';
import { useQuery } from 'react-query';

const getStates = async () => {
  const { data } = await axios.get<IStates>('/v2/admin/states');
  return data;
};

export default function useStates() {
  return useQuery<IStates>('states', () => getStates());
}
