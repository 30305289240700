import { act, render, waitFor, fireEvent, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React, { useState } from 'react';
import Wrapper from '../../testUtils/Wrapper';
import useInsurancePolicyPropertyFilterOptions from '../../../../../api/v2/useInsurancePolicyPropertyFilterOptions';
import useInsurancePolicyStaticFilterOptions from '../../../../../api/v2/useInsurancePolicyStaticFilterOptions';
import Filters from '../../Filters';
import PropertyFilterOptions from '../../Filters/PropertyFilterOptions';
import makeServer from '../../testUtils/server';
import InsurancePolicyStatusFilterOptions from '../../Filters/InsurancePolicyStatusFilterOptions';

let server;

const TestFilters = (props) => {
  const { staticFilterOptionsQuery } = props;
  const [filters, setFilters] = useState({ states: [], properties: [], statuses: [] });
  const mergeFilters = (newFilters) => {
    setFilters(Object.assign({}, filters, newFilters));
  };

  return (
    <Filters selectedFilters={filters} setFilters={mergeFilters} staticFilterOptions={staticFilterOptionsQuery}>
      <PropertyFilterOptions
        query={useInsurancePolicyPropertyFilterOptions}
        selectedProperties={filters.properties}
        selectedStates={filters.states}
        setFilters={mergeFilters}
      />
      <InsurancePolicyStatusFilterOptions
        query={staticFilterOptionsQuery}
        selectedStatuses={filters.statuses}
        setFilters={mergeFilters}
        title="Policies"
      />
    </Filters>
  );
};

beforeEach(() => {
  const mockIntersectionObserver = jest.fn();
  mockIntersectionObserver.mockReturnValue({
    observe: () => null,
    unobserve: () => null
  });
  window.IntersectionObserver = mockIntersectionObserver;
  server = makeServer();
});

afterEach(() => {
  server.shutdown();
});

describe('<Filters />', () => {
  test('renders filters', async () => {
    const { findByRole, findAllByRole, findByLabelText, findAllByLabelText } = render(
      <Wrapper>
        <TestFilters staticFilterOptionsQuery={useInsurancePolicyStaticFilterOptions} />
      </Wrapper>
    );

    const heading = await findByRole('heading', { name: 'Filters' });
    expect(heading).toBeDefined();

    // state filters
    const stateHeading = await findByRole('heading', { name: 'State' });
    const nyStateOption = await findByLabelText('NY');
    const caStateOption = await findByLabelText('CA');
    const vtStateOption = await findByLabelText('VT');
    expect(stateHeading).toBeDefined();
    expect(nyStateOption).toBeDefined();
    expect(caStateOption).toBeDefined();
    expect(vtStateOption).toBeDefined();

    // property filters
    const propertyHeading = await findByRole('heading', { name: 'Property' });
    const coolHauses = await findAllByLabelText(/Cool Haus/);
    const sameNames = await findAllByLabelText(/Same Building Name/);
    const sweetSpots = await findAllByLabelText(/Sweet Spot/);
    expect(propertyHeading).toBeDefined();
    // page 1 should have the first 10 results:
    expect(coolHauses).toHaveLength(10);
    expect(sameNames).toHaveLength(2);
    expect(sweetSpots).toHaveLength(8);

    // status filters
    const statusHeading = await findByRole('heading', { name: 'Policies' });

    const policyDetailStatuses = await findAllByLabelText(/Active|Expired|Missing unit|Claims window expiring/);
    const cancellationStatuses = await findAllByLabelText(/Cancellation Requested|Canceled|Moved out early/);
    const renewalStatuses = await findAllByLabelText(
      /|Eligible for renewal|Will not renew – cash security deposit needed|Will not renew – renter moving out|Expired – did not renew|renewed/
    );
    expect(statusHeading).toBeDefined();
    policyDetailStatuses.forEach((status) => expect(status).toBeDefined());
    cancellationStatuses.forEach((status) => expect(status).toBeDefined());
    renewalStatuses.forEach((status) => expect(status).toBeDefined());
  });

  test('can select and clear states filters', async () => {
    const { findByRole, findByLabelText } = render(
      <Wrapper>
        <TestFilters staticFilterOptionsQuery={useInsurancePolicyStaticFilterOptions} />
      </Wrapper>
    );

    const nyState = await findByLabelText('NY');

    await act(async () => {
      userEvent.click(nyState);
    });

    expect(nyState).toBeChecked();

    const clearButton = await findByRole('button', { name: 'Clear' });

    await act(async () => {
      userEvent.click(clearButton);
    });

    expect(nyState).not.toBeChecked();
  });

  test('can select and clear properties filters', async () => {
    const { findByRole, findByLabelText } = render(
      <Wrapper>
        <TestFilters staticFilterOptionsQuery={useInsurancePolicyStaticFilterOptions} />
      </Wrapper>
    );

    const nyProperty = await findByLabelText('Cool Haus 1');

    await act(async () => {
      userEvent.click(nyProperty);
    });

    expect(nyProperty).toBeChecked();

    const clearButton = await findByRole('button', { name: 'Clear' });

    await act(async () => {
      userEvent.click(clearButton);
    });

    expect(nyProperty).not.toBeChecked();
  });

  test('can select and clear statuses filters', async () => {
    const { findByRole, findByLabelText } = render(
      <Wrapper>
        <TestFilters staticFilterOptionsQuery={useInsurancePolicyStaticFilterOptions} />
      </Wrapper>
    );

    const activeStatus = await findByLabelText('Active');

    await act(async () => {
      userEvent.click(activeStatus);
    });

    expect(activeStatus).toBeChecked();

    const clearButton = await findByRole('button', { name: 'Clear' });

    await act(async () => {
      userEvent.click(clearButton);
    });

    expect(activeStatus).not.toBeChecked();
  });

  test('same building name in two states has state appended to name', async () => {
    const { findByText, findAllByLabelText } = render(
      <Wrapper>
        <TestFilters staticFilterOptionsQuery={useInsurancePolicyStaticFilterOptions} />
      </Wrapper>
    );

    const sameNames = await findAllByLabelText(/Same Building Name .../);
    fireEvent.mouseOver(sameNames[0]);
    const sameBuildingNameNY = await findByText('Same Building Name (NY)');
    fireEvent.mouseOver(sameNames[1]);
    const sameBuildingNameCA = await findByText('Same Building Name (CA)');

    expect(sameBuildingNameNY).toBeDefined();
    expect(sameBuildingNameCA).toBeDefined();
  });

  test('property filters remain selected when associated state remains selected', async () => {
    const { findAllByLabelText, findByLabelText, queryAllByLabelText } = render(
      <Wrapper>
        <TestFilters staticFilterOptionsQuery={useInsurancePolicyStaticFilterOptions} />
      </Wrapper>
    );

    const nyState = await findByLabelText('NY');
    const vtState = await findByLabelText('VT');

    // select NY State
    await act(async () => {
      userEvent.click(nyState);
    });

    let nyProperties = await findAllByLabelText(/Cool Haus/);
    let caProperties = queryAllByLabelText(/Sweet Spot/);
    let vtProperties = queryAllByLabelText(/Tom's Place/);
    const nyProperty = nyProperties[0];

    // only NY properties are available
    expect(nyProperties).toHaveLength(10);
    expect(caProperties).toHaveLength(0);
    expect(vtProperties).toHaveLength(0);

    // select NY Property
    await act(async () => {
      userEvent.click(nyProperty);
    });

    expect(nyProperty).toBeChecked();

    // select VT State
    await act(async () => {
      userEvent.click(vtState);
    });

    vtProperties = await findAllByLabelText(/Tom's Place/);
    caProperties = queryAllByLabelText(/Sweet Spot/);

    expect(vtProperties).toHaveLength(1);
    expect(nyProperties).toHaveLength(10);
    expect(caProperties).toHaveLength(0);

    const vtProperty = vtProperties[0];

    // select VT Property
    await act(async () => {
      userEvent.click(vtProperty);
    });

    // unselect NY State
    await act(async () => {
      userEvent.click(nyState);
    });

    await waitFor(() => {
      expect(queryAllByLabelText(/Cool Haus/)).toHaveLength(0);
    });

    expect(vtProperty).toBeChecked();
    expect(vtProperties).toHaveLength(1);
    expect(caProperties).toHaveLength(0);
  });

  test('property filters remain when state filters are cleared', async () => {
    const { findByLabelText } = render(
      <Wrapper>
        <TestFilters staticFilterOptionsQuery={useInsurancePolicyStaticFilterOptions} />
      </Wrapper>
    );

    const nyState = await findByLabelText('NY');
    const nyProperty = await findByLabelText('Cool Haus 1');

    await act(async () => {
      userEvent.click(nyState);
    });

    await act(async () => {
      userEvent.click(nyProperty);
    });

    await act(async () => {
      userEvent.click(nyState);
    });

    expect(nyState).not.toBeChecked();
    expect(nyProperty).toBeChecked();
  });

  test.skip('can paginate properties', async () => {
    const { findByRole, findByLabelText, findAllByLabelText, queryAllByLabelText } = render(
      <Wrapper>
        <TestFilters staticFilterOptionsQuery={useInsurancePolicyStaticFilterOptions} />
      </Wrapper>
    );

    const coolHauses = await findByLabelText(/Cool Haus/);
    const sameNames = await findByLabelText(/Same Building Name/);
    const sweetSpots = await findByLabelText(/Sweet Spot/);

    // page 1 should have the first 20 results:
    expect(coolHauses).toHaveLength(10);
    expect(sameNames).toHaveLength(2);
    expect(sweetSpots).toHaveLength(8);

    const paginateButton = await findByRole('button', { name: '2' });

    await act(async () => {
      userEvent.click(paginateButton);
    });

    // wait for the property filter options to update to only show NY properties
    await waitFor(() => {
      expect(queryAllByLabelText(/Tom's Place/)).toHaveLength(0);
    });

    const tomsPlaces = await findAllByLabelText(/Tom's Place/);
    const sweetSpotsPage2 = await findAllByLabelText(/Sweet Spot/);

    // page 2 should have remaining results:
    expect(sweetSpotsPage2).toHaveLength(2);
    expect(tomsPlaces).toHaveLength(1);
  });
});
