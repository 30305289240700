import React from 'react';
import { render } from '@testing-library/react';
import { getHumanizedSearchResultType } from '../Search';
import userEvent from '@testing-library/user-event';
import SearchResultsTypeFilter from '../SearchResultsTypeFilter';

describe('<SearchResultsTypeFilter />', () => {
  const mockOnFilterResultsByType = jest.fn();

  const doRender = () => render(
    <SearchResultsTypeFilter
      value={null}
      counts={{
        InsurancePolicy: 1,
        Invitation: 2,
        Claim: 3,
        Property: 4
      }}
      onFilterResultsByType={mockOnFilterResultsByType}
    />
  );

  beforeEach(() => jest.clearAllMocks());

  [
    'InsurancePolicy',
    'Claim',
    'Invitation',
    'Property'
  ].forEach(type => {
    const typeLabel = getHumanizedSearchResultType(type);

    test(`renders the label '${typeLabel}'`, async () => {
      const { findByText } = doRender();
      const element = await findByText(typeLabel);
      expect(element).toBeDefined();
    });

    test('triggers the callback with the type as the argument on click', async () => {
      const { findByText } = doRender();
      const element = await findByText(typeLabel);

      userEvent.click(element)
      expect(mockOnFilterResultsByType).toHaveBeenCalledWith(type);
    });
  });
})
