import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Checkbox, FONTS } from '@sayrhino/rhino-shared-js';

const CheckboxWrapper = styled.div({ margin: '30px 0' });
const ClaimNotification = styled.div([FONTS.p2, { marginTop: 10 }]);

const TenantResideCheckbox = () => {
  const [tenantInResidence, setTenantInResidence] = useState(false);
  return (
    <CheckboxWrapper>
      <Checkbox
        id="tenant-residence-checkbox"
        css={{ minWidth: 20 }}
        label="Renter still resides in unit"
        checked={tenantInResidence}
        onCheckedChange={(value) => setTenantInResidence(value)}
      />
      {tenantInResidence && (
        <ClaimNotification>
          Please be advised that since the tenant is currently living in the unit, claims payments may have an impact on
          eviction proceedings. You can still file the claim, but if you're planning to evict, we recommend contacting
          an attorney for further information.
        </ClaimNotification>
      )}
    </CheckboxWrapper>
  );
};
export default TenantResideCheckbox;
