/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { CaretIcon, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { text } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  items: string[];
  placeHolder?: string;
  onSelected: Function;
}

const wrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  width: '100%',
  height: '48px',
  padding: '12px 8px 12px 24px',
  border: `1px solid ${PALETTE.neutral88}`,
  borderRadius: '4px',
  color: PALETTE.neutralDark,
  cursor: 'pointer'
});

const caretCss = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  border: `1px solid ${PALETTE.neutral88}`,
  cursor: 'pointer'
});

const dropDownMenuCss = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  background: PALETTE.neutralLight,
  border: `1px solid ${PALETTE.neutral88}`,
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  padding: '12px'
});

const menuItemCss = css({
  marginBottom: '4px',
  cursor: 'pointer',
  '&:last-child': {
    marginBottom: '0px'
  },
  '&:hover': {
    fontWeight: 'bold'
  }
});

export const DropDown = ({ items, onSelected, placeHolder }: IProps) => {
  const [selectedValue, setSelectedValue] = useState(placeHolder ?? '-');
  const [isMenuVisible, setMenuVisible] = useState(false);
  const wrapperDropDown: React.RefObject<HTMLDivElement> = React.createRef();

  const onSelectItem = (item) => {
    setSelectedValue(item);
    setMenuVisible(false);
    onSelected(item);
  };

  const handleClickOutside = (ev) => {
    if (!isMenuVisible) {
      return;
    }
    if (wrapperDropDown.current && !wrapperDropDown.current.contains(ev.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div css={[FONTS.p2, wrapperStyle]} onClick={() => setMenuVisible(!isMenuVisible)}>
      <span>{selectedValue}</span>
      <div css={caretCss}>
        <CaretIcon width={10} height={10} />
      </div>
      {isMenuVisible && (
        <div css={dropDownMenuCss} ref={wrapperDropDown}>
          {items.map((item) => (
            <span key={item} onClick={() => onSelectItem(item)} css={menuItemCss}>
              {item}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
