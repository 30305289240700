import React, { useState } from 'react';
import { Switch } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';

const Label = styled.label({
  paddingRight: 10,
  fontSize: 14
});

interface IButtonProps {
  id: number;
  name: string;
  enabled: boolean;
  label: string;
  disabled: boolean;
  handleToggle: (id, status) => void;
}

const ToggleButton: React.FC<IButtonProps> = (props) => {
  const { id, handleToggle, name, enabled, label, disabled } = props;
  const [toggle, setToggle] = useState<boolean>(enabled);

  const toggleFeature = () => {
    let status = !toggle;
    setToggle(!toggle);
    handleToggle(id, status);
  };

  return (
    <div>
      <Label htmlFor={`toggle-${name}`}>{label}</Label>
      <Switch id={`toggle-${name}`} checked={toggle} disabled={disabled} onClick={() => toggleFeature()}></Switch>
    </div>
  );
};

export default ToggleButton;
