/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';

type IBackdropProps = JSX.IntrinsicElements['div'];

const Backdrop: React.FC<IBackdropProps> = (props: IBackdropProps) => {
  return (
    <div
      css={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        opacity: 0.9,
        zIndex: 100,
        top: 0,
        right: 0,
        backgroundColor: PALETTE.brand4
      }}
      {...props}
    />
  );
};

export default Backdrop;
