import { Ordering } from './application_table';
import ApplicationTable from './application_table';

class InProgressTable extends ApplicationTable {
  public selector = '#in-progress-table';
  public emptyMessageText = 'You have no in progress tenants';
  public pathPrefix = 'in_progress_dashboard/';
  public order: Ordering = [7, 'desc'];
  protected columns = [
    {data: 'full_name'},
    {data: 'email', visible: false},
    {data: 'address'},
    {data: 'unit'},
    {data: 'monthly_rent'},
    {data: 'coverage_amount'},
    {data: 'status'},
    {data: 'created_at'}
  ];
}

export default InProgressTable;
