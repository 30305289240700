import React from 'react';
import { render } from '@testing-library/react';
import { TestApp } from '../index';
import makeServer from '../testUtils/server';
import userEvent from '@testing-library/user-event';
import Wrapper from '../testUtils/Wrapper';

describe('<Layout />', () => {
  let server;
  beforeEach(() => (server = makeServer()));
  afterEach(() => server.shutdown());

  test('navigating to search', async () => {
    const app = render(
      <Wrapper>
        <TestApp user={{ role: 'PropertyManager' }} />
      </Wrapper>
    );
    const heading = await app.findByText('Search');
    const leftClick = { button: 0 };
    expect(heading).toBeDefined();
    let updatesText = await app.findAllByText('Activity feed');
    expect(updatesText).toBeDefined();

    userEvent.click(heading, leftClick);
    const searchText = await app.findByText('Start typing to search');
    expect(searchText).toBeDefined();

    updatesText = await app.queryAllByText('Activity feed');
    expect(updatesText.length).toBe(0);
    const backBtn = await app.findByRole('button', { name: 'Close' });
    expect(backBtn).toBeDefined();

    userEvent.click(backBtn, leftClick);
    updatesText = await app.findAllByText('Activity feed');
    expect(updatesText).toBeDefined();
  });
});
