import React, { useEffect, useState } from 'react';
import { Button, FONTS, PALETTE, Loading } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import { AxiosError } from 'axios';

import PropertyList, { IPropEntity, getNameFromAddr } from './UploadConfirmation';
import {
  PropertyCsvUploadResponse,
  IError,
  isPropertyCsvUploadResponse,
  PropertyCsv,
  IPropertyCsvRow
} from 'components/tables/property_upload_table/interfaces';
import usePropertiesBulkUploadMutation, { IPropertyAndUnitIds } from 'api/v2/usePropertiesBulkUploadMutation';

interface IProps {
  data: PropertyCsvUploadResponse;
  onSubmit?: (nextStep: string, options?: PropertyCsvUploadResponse) => void;
}

const Page = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const Header = styled.div({
  margin: '28px 0 0',
  width: '576px'
});

const HR = styled.hr({
  height: '1px',
  background: PALETTE.neutral12,
  margin: '28px 0'
});

const Count = styled.p([FONTS.p1Medium, { color: PALETTE.brand100, margin: '0' }]);

const Title = styled.h2([FONTS.brandH2Bold]);
const SubTitle = styled.p([FONTS.p1, { marginTop: '28px' }]);

const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '576px',
  padding: '30px 0',
  position: 'fixed',
  bottom: '0px',
  backgroundColor: PALETTE.neutralLight
});

const Overlay = styled.div({
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(254,254,254,.4)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  zIndex: 1000
});

const CsvSuccess: React.FC<IProps> = (props: IProps) => {
  const bulkUpload = usePropertiesBulkUploadMutation();

  const filename: string = isPropertyCsvUploadResponse(props.data) ? props.data.valid_csv.filename : '';

  const csv: PropertyCsv = isPropertyCsvUploadResponse(props.data) ? props.data.valid_csv.parsed_csv : [];
  const reUpload = () => {
    if (props.onSubmit) props.onSubmit('UploadProperties');
  };

  const [properties, setProperties] = useState<IPropEntity[]>([]);

  useEffect(() => {
    const sorted = new Map<string, IPropEntity>();

    //check is list contains valid properties only
    if (csv) {
      csv.forEach((element: IPropertyCsvRow) => {
        const key: string = getNameFromAddr(element.address);

        //If property has been seen before, update property count and
        //append legal_property_entity to list
        if (sorted.has(key)) {
          const hold: IPropEntity | undefined = sorted.get(key);
          if (hold) {
            hold.total_units++;
            hold.legal_property_entity.add(element.legal_property_entity || '');
          }
        } else {
          //Else add property to map using property_name or address as key
          const propt: IPropEntity = {
            total_units: 1,
            address: element.address,
            property_owner: element.property_owner,
            property_name: key,
            legal_property_entity: new Set<string>([element.legal_property_entity || ''])
          };
          sorted.set(key, propt);
        }
      });
    }
    setProperties(Array.from(sorted.values()));
  }, [csv]);

  const createProperties = () => {
    // We know the properties and units were all error-free
    // Now we can proceed to create the properties
    bulkUpload.mutate(csv, filename, {
      onSuccess: (data: IPropertyAndUnitIds) => {
        if (props.onSubmit) props.onSubmit('UploadComplete', Object.values(data));
      },
      onError: (error: AxiosError<IError>) => {
        const err = error.response?.data.error;
        alert(`Filename: ${err?.filename} \nError: ${err?.message} \nProperty: ${err?.property?.property_name}`);
      }
    });
  };

  const renderLoader = () => (
    <Overlay>
      <Loading />
    </Overlay>
  );

  return (
    <Page>
      {bulkUpload.status === 'loading' && renderLoader()}
      <Header>
        <Title>Review your properties</Title>
        <SubTitle>
          Please review the details we pulled from your file and make sure they're correct. If you see any mistakes,
          please correct them within your CSV file and reupload it.
        </SubTitle>
        <HR />
        <Count>{properties.length} Properties</Count>
      </Header>
      <PropertyList list={properties} />
      <ButtonContainer>
        <Button variant="tertiary" children="Reupload CSV" onClick={reUpload} />
        <Button
          variant="primary"
          children="Import properties"
          onClick={createProperties}
          disabled={bulkUpload.status === 'loading'}
        />
      </ButtonContainer>
    </Page>
  );
};

export default CsvSuccess;
