import axios from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation } from 'react-query';

export type PullPropertiesParams = {
  propertyOwnerId: string,
  prospectSyncDays?: number,
};

const pullProperties = async (params: PullPropertiesParams, prospectSyncDays?: number) => {
  const authenticityToken = csrfToken();
  const res = await axios.post(
    `/v2/admin/property_owners/${params.propertyOwnerId}/sync`,
    {
      "prospect_sync_days": params.prospectSyncDays,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticityToken,
      },
    }
  );
  return res;
};

export function usePullPropertiesMutation() {
  return useMutation(pullProperties);
}

