/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { PALETTE, CloseIcon, MoreVerticalIcon } from '@sayrhino/rhino-shared-js';

type Props = JSX.IntrinsicElements['button'] & {
  expanded: boolean;
};

const toggleEditStyles = css({
  color: PALETTE.neutralLight,
  backgroundColor: PALETTE.neutralDark,
  border: 'none',
  borderRadius: 50,
  height: 32,
  width: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 16
});

const ToggleControlsButton = (props: Props) => {
  const { expanded, ...rest } = props;
  return (
    <button css={toggleEditStyles} {...rest}>
      {expanded ? <CloseIcon height={16} width={16} /> : <MoreVerticalIcon />}
    </button>
  );
};

export default ToggleControlsButton;
