import React, { useRef } from 'react';
import ReactSelect, { ReactSelectProps, components } from 'react-select';
import { ArrowDown, LegacyCheckbox } from '@sayrhino/rhino-shared-js';
import { customStyles } from './styles';
import styled from '@emotion/styled';

const StyledLabel = styled.label({ display: 'none' });

const DropdownIndicator = () => <ArrowDown />;
const Option = (props) => {
  return (
    <components.Option {...props}>
      <LegacyCheckbox checked={props.isSelected} onChange={() => null} />
      {props.children}
    </components.Option>
  );
};

/* React-select v1 allowed to use strings for the value prop,
 * but with v2 and above uses arrays or objects in all the cases.
 * Pass `simpleValue` prop which will convert the value.
 * Send `labelKey` or `ValueKey` if options are different from
 * {label, value}
 */
type IProps = ReactSelectProps & {
  labelKey?: string;
  valueKey?: string;
  allowSelectAll?: boolean;
  altLabelKey?: string;
};

const MultiSelect: React.FC<IProps> = ({
  labelKey = 'label',
  valueKey = 'value',
  allowSelectAll = false,
  components,
  ...props
}) => {
  const HiddenLabel = <StyledLabel htmlFor={`react-select-${props.instanceId}-input`}>{props.instanceId}</StyledLabel>;

  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const getOptionLabel = (options) => {
    const label = options[labelKey] || options[props.altLabelKey];
    return label;
  };

  if (allowSelectAll) {
    const selectAllOption = {
      [valueKey]: '*',
      [labelKey]: 'Select All'
    };

    const isSelectAllSelected = () =>
      props.options.length > 0 ? valueRef.current.length === props.options.length : false;

    // isOptionSelected sees previous props.value after onChange
    const isOptionSelected = (option) =>
      valueRef.current.some((selected) => selected[valueKey] === option[valueKey]) || isSelectAllSelected();

    const getOptions = () => [selectAllOption, ...props.options];

    const getValue = () => (isSelectAllSelected() ? [selectAllOption] : props.value);

    const onChange = (newValue, actionMeta) => {
      const { action, option, removedValue } = actionMeta;
      if (action === 'select-option' && option[valueKey] === selectAllOption[valueKey]) {
        props.onChange(props.options, actionMeta);
      } else if (
        (action === 'deselect-option' && option[valueKey] === selectAllOption[valueKey]) ||
        (action === 'remove-value' && removedValue[valueKey] === selectAllOption[valueKey])
      ) {
        props.onChange([], actionMeta);
      } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
        const filteredValues = props.options.filter((selected) => selected[valueKey] !== option[valueKey]);
        props.onChange(filteredValues, actionMeta);
      } else {
        props.onChange(newValue || [], actionMeta);
      }
    };

    return (
      <>
        <ReactSelect
          {...props}
          isOptionSelected={isOptionSelected}
          options={getOptions()}
          value={getValue()}
          onChange={onChange}
          styles={customStyles}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          isMulti
          isClearable={false}
          getOptionLabel={getOptionLabel}
          getOptionValue={(options) => options[valueKey]}
          components={{ Option, DropdownIndicator, ...components }}
        />
        {HiddenLabel}
      </>
    );
  }

  return (
    <>
      <ReactSelect
        {...props}
        styles={customStyles}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isMulti
        isClearable={false}
        getOptionLabel={getOptionLabel}
        getOptionValue={(options) => options[valueKey]}
        components={{ Option, DropdownIndicator, ...components }}
      />
      {HiddenLabel}
    </>
  );
};

export default MultiSelect;
