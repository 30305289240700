import React, { Component } from 'react';
import Toggle from 'components/active_states/Toggle'
import { csrfToken } from 'utils/document';
import { put, request } from 'utils/request';

interface IProps {
  active: boolean;
  property_id: number;
}

class PropertyToggle extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  public handleToggle(property_id, newStatus) {
    const updatePath = '/admin/properties/' + property_id;
    const token = csrfToken();
    put(updatePath, {active: newStatus, property_id, authenticity_token: token})
      .catch((response: {error: string}) => {
        alert(response.error)
      });
  }

  public render(): JSX.Element {
    const { active, property_id } = this.props;

    return (
      <Toggle
        onColor="#F5F4FB"
        handleToggle={(newStatus) => this.handleToggle(property_id, newStatus)}
        name={`property-owner-status-${property_id}`}
        checked={active}
      />
    );
  }
}

export default PropertyToggle;
