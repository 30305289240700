/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import React from 'react';
import DashboardPanelRule from './DashboardPanelRule';
import DashboardPanelTitle from './DashboardPanelTitle';
import DashboardPanelValue from './DashboardPanelValue';
import DashboardPanelWell from './DashboardPanelWell';
import { stringifyDateLong } from './utils';

type DashboardPanelFigureProps = {
  title: string;
  description?: string;
  value: any;
  updatedDate?: Date;
};

const DashboardPanelFigure: React.FC<DashboardPanelFigureProps> = ({ title, description, value, updatedDate }) => (
  <DashboardPanelWell>
    <DashboardPanelTitle>{title}</DashboardPanelTitle>
    <p css={[FONTS.p2, { marginBottom: '2rem' }]}>{description}</p>
    <DashboardPanelRule />
    <div
      css={{
        alignItems: 'baseline',
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 1326px)': {
          flexDirection: 'column'
        }
      }}
    >
      <DashboardPanelValue>{value}</DashboardPanelValue>
      {updatedDate && (
        <div css={[FONTS.label, { color: PALETTE.neutral65 }]}>Updated {stringifyDateLong(updatedDate)}</div>
      )}
    </div>
  </DashboardPanelWell>
);

export default DashboardPanelFigure;
