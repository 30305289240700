/**
 * Simple implementation of react-tabs
 * Make sure to wrap <Tab>s in <TabList>, and wrap the whole thing in <Tabs>
 * <Tabs> correspond to <TabContent> in their dom order. So 1st tab goes to 1st <TabContent>, etc.
 */

import React, { Children, cloneElement, ReactElement, useRef, useState } from 'react';
import { TabProps, TabListProps, TabContentProps, TabContainerProps, TabsProps } from './Types';
import { StyledTab, StyledTabList } from './Styled';
import { isTab, isTabDisabled, isTabList, isTabContent } from './Helpers';

const TabList = (props: TabListProps) => {
  const { children, id, ...rest } = props;
  return (
    <StyledTabList {...rest} role="tablist">
      {children}
    </StyledTabList>
  );
};

const Tab = (props: TabProps) => {
  const { selected, id, disabled, index, children, ...rest } = props;
  return (
    <StyledTab
      role="tab"
      id={`tab-${id}`}
      index={index}
      selected={selected ?? false}
      aria-selected={selected}
      aria-controls={`panel-${id}`}
      disabled={disabled}
      aria-disabled={disabled}
      {...rest}
      data-tab
    >
      {children}
    </StyledTab>
  );
};

const TabContent = (props: TabContentProps) => {
  const { selected, children, id, ...rest } = props;
  return (
    <div {...rest} role="tabpanel" id={`panel-${id}`} aria-labelledby={`tab-${id}`}>
      {selected && children}
    </div>
  );
};

const Tabs = (props: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { children } = props;
  const handleClick = (e) => {
    let node = e.target;
    // is tab from container
    if (isTab(node)) {
      // is tab disabled
      if (isTabDisabled(node)) {
        return;
      }
      // get index of tab
      const index = [].slice.call(node.parentNode.children).filter(isTab).indexOf(node);
      setSelectedIndex(index);
    }
  };

  return (
    <TabContainer selectedIndex={selectedIndex} onClick={handleClick}>
      {children}
    </TabContainer>
  );
};

const TabContainer = (props: TabContainerProps) => {
  const { selectedIndex, children, ...rest } = props;
  const getTabs = () => {
    // specify index for tabcontent
    let index = 0;
    if (children) {
      // iterate over each child. Will be TabList or TabContent
      return Children.map(children, (child: ReactElement) => {
        let result = child;
        if (child && isTabList(child)) {
          // child is a TabList node
          let listIndex = 0;
          // iterate through TabList to remake each tab specifying selected prop and id's
          result = React.cloneElement(child, {
            children: Children.map(child.props.children, (tab) => {
              // necessary to check if tab is defined because conditionally rendering tabs can put a "false" in child.props.children, and you can't clone "false".
              if (tab) {
                const selected = selectedIndex === listIndex;
                const props = {
                  id: listIndex,
                  selected,
                  index: listIndex
                };
                listIndex++;
                // clone tab with new props
                return cloneElement(tab, props);
              }
            })
          });
        } else if (child && isTabContent(child)) {
          // child is a TabContent node
          const props = {
            id: index,
            index: index,
            selected: selectedIndex === index
          };
          index++;
          // clone TabContent to specify selected and id
          result = cloneElement(child, props);
        }
        return result;
      });
    }
  };
  return <div {...rest}>{getTabs()}</div>;
};

// Specify tabsRole properties so we can check them later
Tab.tabsRole = 'Tab';
TabContent.tabsRole = 'TabContent';
TabList.tabsRole = 'TabList';
Tabs.tabsRole = 'Tabs';

export { Tab, TabContent, TabList, Tabs };
