import React, { Fragment, useState } from 'react';
import { ExpandableCard } from '@sayrhino/rhino-shared-js';
import { initializeDate, stringifyDate } from '../../utils';
import { ClaimStatus, IClaim } from '../../interfaces';

import {
  ClaimDetailsItem,
  Splitter,
  StyledLabelDiv,
  P2Font,
  ClaimInfoWrapper,
  OtherClaimsWrapper,
  StyledBadgeDiv,
  StyledInnerBadgeDiv,
  StyledOuterBadgeDiv,
  OtherClaimsBreak
} from './ClaimStyles';

import { PolicyCardHeader } from './ClaimCardDetails';

export const PolicyDetailsCard = (claim: IClaim) => {
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);

  return (
    <ExpandableCard
      isOpen={isPolicyOpen}
      onClick={() => {
        setIsPolicyOpen(!isPolicyOpen);
      }}
      label={`${claim.insurance_policy.renter_full_name} | ${claim.unit_name}`}
      headerContent={<PolicyCardHeader policy={claim.insurance_policy!} />}
    >
      <Fragment>
        <Splitter />
        <ClaimDetailsItem>
          <P2Font>{claim.insurance_policy.renter_email}</P2Font> <StyledLabelDiv>Tenant's email</StyledLabelDiv>
        </ClaimDetailsItem>
        <Splitter />
        <ClaimDetailsItem>
          <P2Font>{stringifyDate(initializeDate(claim.last_day_to_submit_claim))}</P2Font>
          <StyledLabelDiv>Last day to submit claims</StyledLabelDiv>
        </ClaimDetailsItem>
        {claim.related_claims && claim.related_claims.length > 0 && (
          <Fragment>
            <OtherClaimsBreak />
            <ClaimDetailsItem>
              <OtherClaimsWrapper>Other Claims</OtherClaimsWrapper>
            </ClaimDetailsItem>
            {claim.related_claims.map((claimInfo, index) => {
              return (
                <Fragment key={claimInfo.claim_number}>
                  {index > 0 && <Splitter />}
                  <ClaimDetailsItem>
                    <P2Font>
                      {claimInfo.claim_type} - #{claimInfo.claim_number}
                    </P2Font>
                    <StyledBadgeDiv>
                      <ClaimInfoWrapper>{claimInfo.status}</ClaimInfoWrapper>
                      {claimInfo.status === ClaimStatus.NEW && (
                        <StyledOuterBadgeDiv>
                          <StyledInnerBadgeDiv />
                        </StyledOuterBadgeDiv>
                      )}
                    </StyledBadgeDiv>
                  </ClaimDetailsItem>
                </Fragment>
              );
            })}
          </Fragment>
        )}
      </Fragment>
    </ExpandableCard>
  );
};

export default PolicyDetailsCard;
