import styled from '@emotion/styled';
import { FONTS, PALETTE, TextInput } from '@sayrhino/rhino-shared-js';
import React from 'react';

type MaskedPasswordInputProps = {
  action?: React.ReactNode;
  children: React.ReactNode;
};

const StyledSectionHeader = styled.h4([
  FONTS.h4,
  { paddingBottom: '16px', paddingTop: '20px', borderBottom: `1px solid ${PALETTE.neutralDark}`, display: 'flex' },
  `& > button {
    margin-left: auto;
  }`
]);

const SectionHeader = (props: MaskedPasswordInputProps) => {
  const { action, children } = props;
  return (
    <StyledSectionHeader>
      {children}
      {action}
    </StyledSectionHeader>
  );
};

export default SectionHeader;
