import styled from '@emotion/styled';
import { Loading } from '@sayrhino/rhino-shared-js';
import useClaimAttachments from 'api/v2/useClaimAttachments';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import FileViewer from '../../FileViewer';
import { IClaimAttachment } from '../../interfaces';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';
import { useReferrer } from '../../utils';
import ClaimAttachments from './ClaimAttachments';

const View = styled.div({
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center'
});

const AttachmentsViewer: React.FC = () => {
  const { claimsId, blob_id } = useParams();
  const location = useLocation();
  const defaultSelected = location.state as IClaimAttachment;
  const [selectedFileData, setSelectedFileData] = useState<IClaimAttachment | undefined>(defaultSelected);
  const rawReferrer = useReferrer();
  const referrer = rawReferrer || `/admin/claims/${claimsId}`;
  const [attachmentsPage, setAttachmentsPage] = useState(1);
  const { addToast } = useToast();
  const {
    isSuccess: attachmentsSuccess,
    isLoading: attachmentsLoading,
    data: attachments
  } = useClaimAttachments(Number(claimsId), { option: 'include_notes', page: attachmentsPage }) ?? {};

  /**
   * This is useful only in cases of a redirect for the backend
   * instead of clicking a button on the frontend
   */
  const getSelectedAttachmentByBlobId = () => {
    const blob = blob_id;
    if (blob) {
      const selected = attachments?.data?.find(({ blob_id }) => blob == blob_id);
      setSelectedFileData(selected);
    }
  };

  useEffect(() => {
    getSelectedAttachmentByBlobId();
  }, [attachments]);
  const navigate = useNavigate();
  const tabIndexGroups = {};
  const getNextTabIndex = (groupId) => {
    tabIndexGroups[groupId] = tabIndexGroups[groupId] || 1000 + 1000 * groupId;
    return tabIndexGroups[groupId]++;
  };
  const downloadAttachment = (attachmentUrl, attachmentName) => {
    try {
      const link = document.createElement('a');
      link.href = `${attachmentUrl}`;
      link.setAttribute('download', attachmentName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      addToast('Something went wrong when trying to download the file', 'neutral_warning', TOAST_STATUS.ERROR);
    }
  };
  const handleAttachmentModal = (attachment) => {
    setSelectedFileData(attachment);
  };

  if (attachmentsLoading) {
    return (
      <View>
        <Loading />
      </View>
    );
  }

  if (!blob_id) {
    return <Navigate to={referrer} />;
  }

  return (
    <FileViewer selectedFile={selectedFileData} downloadFile={downloadAttachment} closeModel={() => navigate(referrer)}>
      {attachmentsSuccess && (
        <ClaimAttachments
          selectedFile={selectedFileData}
          handleDownload={downloadAttachment}
          handleModal={handleAttachmentModal}
          attachmentsData={attachments}
          page={attachmentsPage}
          isLoading={attachmentsLoading ?? false}
          setPage={setAttachmentsPage}
          isModalOpen={true}
          getNextTabIndex={getNextTabIndex}
        />
      )}
    </FileViewer>
  );
};

export default AttachmentsViewer;
