import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { PALETTE, ROUNDNESS, FONTS, Alert2Icon } from '@sayrhino/rhino-shared-js';

const textareaReset = css({
  '& > textarea': {
    outline: 'none',
    border: 'none',
    borderRadius: 12,
    width: '100%',
    backgroundColor: 'transparent'
  }
});

const TEXT_INPUT = css([
  {
    border: 'none',
    outline: 'none',
    color: PALETTE.neutral88,
    boxShadow: `inset 0 0 0 1px ${PALETTE.neutral25}`,
    backgroundColor: PALETTE.neutralLight,
    zIndex: 1,

    '&:hover': {
      boxShadow: `inset 0 0 0 1px ${PALETTE.neutral88}`,
      color: PALETTE.neutral88
    },

    '&:focus-within': {
      boxShadow: `inset 0 0 0 1px ${PALETTE.interaction100}, 0 0 0 1px ${PALETTE.interaction100}`
    }
  },
  ROUNDNESS.s,
  textareaReset
]);

const TEXT_INPUT_ERROR = css([
  {
    border: 'none',
    outline: 'none',
    boxShadow: `inset 0 0 0 1px ${PALETTE.alert125}`,
    color: PALETTE.alert125,
    backgroundColor: PALETTE.alert4,
    zIndex: 1,

    '&:hover': {
      boxShadow: `inset 0 0 0 1px ${PALETTE.alert125}`,
      color: PALETTE.alert125
    },

    '&:focus-within': {
      boxShadow: `inset 0 0 0 1px ${PALETTE.alert125}, 0 0 0 1px ${PALETTE.alert125}`,
      backgroundColor: PALETTE.neutralLight
    }
  },
  ROUNDNESS.s,
  textareaReset
]);

type Props = {
  label?: string;
  subtext?: React.ReactNode;
  error?: boolean;
  id: string;
} & JSX.IntrinsicElements['textarea'];

const StyledField = styled.div({ height: 180 });
const StyledLabel = styled.label<{ error?: boolean }>(
  [FONTS.p3Medium, { color: PALETTE.neutral65 }],
  (props) => props.error && { color: PALETTE.alert125 }
);
const StyledInputContainer = styled.div<{ error?: boolean }>(
  [TEXT_INPUT, { display: 'flex', alignItems: 'center', padding: 4, marginTop: 4, marginBottom: 4 }],
  (props) => {
    if (props.error) return TEXT_INPUT_ERROR;
  }
);
const StyledInput = styled.textarea<{ error?: boolean }>([
  FONTS.p1Light,
  {
    width: '100%',
    padding: 4,
    color: PALETTE.neutral88,
    resize: 'none',
    '&::placeholder': {
      color: PALETTE.neutral65
    }
  },
  (props) => props.error && { color: PALETTE.alert125 }
]);
const StyledSubtext = styled.div<{ error?: boolean }>(
  [FONTS.p3Light, { color: PALETTE.neutral65, margin: 0, display: ' flex', alignItems: 'center' }],
  (props) => props.error && { color: PALETTE.alert125 }
);

const StyledIcon = styled(Alert2Icon)({ marginRight: 5 });

/*
This is initial component, and does not have full functionality
TODO use TextArea from shared-js when it is built
 */
const TextAreaInput = (props: Props) => {
  const { label, subtext, error, style, className, ...rest } = props;
  return (
    <StyledField className={className} style={style}>
      {label && (
        <StyledLabel htmlFor={props.id} error={error}>
          {label}
        </StyledLabel>
      )}
      <StyledInputContainer error={error}>
        <StyledInput error={error} rows={5} role="textbox" {...rest} id={props.id} />
      </StyledInputContainer>
      {subtext && (
        <StyledSubtext error={error}>
          {error && <StyledIcon height="16px" width="16px" color={PALETTE.alert125} />} {subtext}
        </StyledSubtext>
      )}
    </StyledField>
  );
};

export default TextAreaInput;
