import React, { Component } from 'react';

import * as Styles from './Styles';
import { put } from 'utils/request';
import assignIcon from './assign.svg';
import { IClaim } from '../claims_table';
import { fetchAdministrators } from 'api/administrators';
import AsyncSelect from 'components/v2/select/AsyncSelect';

interface IProps {
  claim: IClaim;
}

interface IState {
  value: object;
  isLoading: boolean;
  showModal: boolean;
  adminInitials: string;
  adminAssigned: boolean;
}

class Assignee extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { admin_id, admin_name_or_email, admin_initials: adminInitials } = this.props.claim;
    const value = { id: admin_id, name_or_email: admin_name_or_email };
    const adminAssigned = !!admin_id;

    this.state = { showModal: false, isLoading: false, adminInitials, value, adminAssigned };
  }

  public render(): JSX.Element {
    const { showModal, adminAssigned } = this.state;
    const { id: claim_id } = this.props.claim;
    const id = `assignAdmin-${claim_id}`;

    return (
      <div>
        <Styles.AssigneeButton onClick={this.showModal} id={id} data-testid={`assignAdmin-${claim_id}`}>
          {adminAssigned ? this.changeAssignee() : this.addAssignee()}
        </Styles.AssigneeButton>
        {showModal && this.renderModal()}
      </div>
    );
  }

  private changeAssignee = () => {
    const { adminInitials } = this.state;

    return (
      <Styles.Circle>
        <Styles.Initials>{adminInitials}</Styles.Initials>
      </Styles.Circle>
    );
  };

  private renderModal = () => {
    const { value, isLoading, adminAssigned } = this.state;

    return (
      <Styles.Modal>
        <AsyncSelect
          value={value}
          aria-label="administrator"
          id="administrator_id"
          isLoading={isLoading}
          placeholder="Assign to"
          name="administrator_id"
          onChange={this.onAssign}
          getOptionLabel={(options) => options.name_or_email}
          loadOptions={this.loadAdministrators}
        />

        {adminAssigned && this.unassignLink()}
      </Styles.Modal>
    );
  };

  private addAssignee = () => {
    return <Styles.Image src={assignIcon} />;
  };

  private unassignLink = () => {
    return <Styles.UnassignLink onClick={this.unassign}>Unassign</Styles.UnassignLink>;
  };

  private showModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  private loadAdministrators = (input: string, callback: Function) => {
    this.setState({ isLoading: true });

    fetchAdministrators(
      input,
      (response) => {
        callback(response.administrators);
        this.setState({ isLoading: false });
      },
      (response) => {
        response.json().then((e) => {
          callback([]);
          this.setState({ isLoading: false });
        });
      }
    );
  };

  private onAssign = (value) => {
    if (!value) return;

    this.setState({ isLoading: true });

    const {
      claim: { id }
    } = this.props;
    const updatePath = '/admin/claims/' + id + '/assign_admin';

    put(updatePath, { administrator_id: value.id }).then((_response) => {
      const { initials: adminInitials } = value;

      this.setState({ showModal: false, isLoading: false, adminAssigned: true, adminInitials, value });
    });
  };

  private unassign = () => {
    this.setState({ isLoading: true });

    const updatePath = '/admin/claims/' + this.props.claim.id + '/assign_admin';

    put(updatePath, { administrator_id: null }).then((_response) => {
      this.setState({
        showModal: false,
        isLoading: false,
        adminAssigned: false,
        adminInitials: '',
        value: { id: null, name_or_email: null }
      });
    });
  };
}

export default Assignee;
