import http from 'j-fetch';
import env from 'utils/env';
import isPresent from '../../javascript/utils/isPresent';
const GOOGLE_API_KEY = env('GOOGLE_API_KEY');
const GOOGLE_JSON_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

declare global {
  interface Window {
    google: any;
  }
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GoogleLocation {
  lat?: () => void;
  lng?: () => void;
}

interface GooglePlaceGeometry {
  location: GoogleLocation;
  location_type: string;
  viewport: Record<string, string>;
}

interface GooglePlacePlusCode {
  compound_code: string;
  global_code: string;
}

export interface GooglePlace {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: GooglePlaceGeometry;
  place_id: string;
  plus_code: GooglePlacePlusCode;
}
export interface GooglePrediction {
  description: string;
  place_id: string;
}

export interface GooglePlaceResults {
  place: GooglePlace;
  status: string;
}

export const GOOGLE_STATUS_CODES = {
  OK: 'OK',
  ZERO_RESULTS: 'ZERO_RESULTS',
  OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
  REQUEST_DENIED: 'REQUEST_DENIED',
  INVALID_REQUEST: 'INVALID_REQUEST'
};

type StatusCode = 'OK' | 'ZERO_RESULTS' | 'OVER_QUERY_LIMIT' | 'REQUEST_DENIED' | 'INVALID_REQUEST';

export const isUnsuccessfulGoogleStatus = (status) => {
  const reachedQueryLimit = GOOGLE_STATUS_CODES.OVER_QUERY_LIMIT === status;
  const requestDenied = GOOGLE_STATUS_CODES.REQUEST_DENIED === status;
  return reachedQueryLimit || requestDenied;
};

export const googleMapsPresent = (): boolean => isPresent((window as any)?.google?.maps);

export function getAutoCompleteSessionToken(): string {
  if (googleMapsPresent()) {
    const autoCompleteSessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
    return autoCompleteSessionToken;
  }
  return '';
}

export function fetchGooglePredictions(query: string): Promise<{}> {
  return new Promise((resolve, reject) => {
    const service = new (window as any).google.maps.places.AutocompleteService();

    const callback = (predictions, status: StatusCode) => {
      return resolve({
        predictions: predictions || [],
        status: status
      });
    };

    const payload = {
      key: GOOGLE_API_KEY,
      input: query,
      componentRestrictions: { country: 'US' },
      types: ['address']
    };
    service.getPlacePredictions(payload, callback);
  });
}

export function fetchPlaceById(placeId: string): Promise<GooglePlaceResults> {
  const geocoder = new (window as any).google.maps.Geocoder();
  const getResultsOrEmpty = (status, results) => (status === GOOGLE_STATUS_CODES.OK ? results[0] : []);

  return new Promise((resolve, reject) => {
    const callback = (results, status: StatusCode) => {
      return resolve({
        place: getResultsOrEmpty(status, results),
        status
      });
    };
    geocoder.geocode({ placeId }, callback);
  });
}

export function fetchGoogleAddress(address: string): Promise<{}> {
  const query = {
    key: GOOGLE_API_KEY,
    address
  };

  return new Promise((resolve, reject) => {
    http.get({ url: GOOGLE_JSON_API_URL, query }).then((response) => {
      const { status, results } = response;

      if (status === GOOGLE_STATUS_CODES.OK || status === GOOGLE_STATUS_CODES.ZERO_RESULTS) {
        resolve(results || []);
      } else {
        reject(status);
      }
    });
  });
}

export const convertPlaceToPrediction = (place: GooglePlace): GooglePrediction | null => {
  if (typeof place === 'string' || !isPresent(place)) {
    return null;
  }
  return {
    description: place.formatted_address,
    place_id: place.place_id
  };
};

export const googlePredictionsError = (status) => {
  const unknownError = status.toString().includes('Error');
  return isUnsuccessfulGoogleStatus(status) || unknownError;
};

const googleMapsApiSrc = () => `https://maps.googleapis.com/maps/api/js?key=${env('GOOGLE_API_KEY')}&libraries=places`;
export const googleMapsApiScriptElId = 'google-maps-api-script';
export const googleMapsApiErrorContainerElId = "google-maps-api-error-message-container";
const googleMapsApiMaxRetryAttempts = 3;

let googleMapsApiRetryCount = 0, retryAttemptInProgress = false, maxAttemptsExceeded = false;

export function retryLoadGoogleMapsAPI() {
  return new Promise((resolve, reject) => {
    if (retryAttemptInProgress) return reject('retryLoadGoogleMapsAPI :: Retry attempt already in progress.');
    if (maxAttemptsExceeded) return reject('retryLoadGoogleMapsAPI :: Max attempts exceeded.');

    if (window.google) return resolve(null);

    const loadScript = () => {
      retryAttemptInProgress = true;
      const existingRetryScriptEl = document.getElementById(googleMapsApiScriptElId)
      if (existingRetryScriptEl) existingRetryScriptEl.remove();

      const script = document.createElement('script');
      script.id = googleMapsApiScriptElId;
      script.src = googleMapsApiSrc();

      script.onload = () => {
        retryAttemptInProgress = false;
        return resolve(null);
      }

      script.onerror = (error) => {
        retryAttemptInProgress = false;
        googleMapsApiRetryCount++;

        if (googleMapsApiRetryCount < googleMapsApiMaxRetryAttempts) return setTimeout(loadScript, 1000);
        maxAttemptsExceeded = true;

        const errorContainerEl = document.getElementById(googleMapsApiErrorContainerElId)

        if (errorContainerEl) errorContainerEl.style.display = 'block';
        console.error('Failed to load Google Maps API. Max Attempts Exceeded', { error, googleMapsApiRetryCount, googleMapsApiMaxRetryAttempts})
        return reject(`Failed to load Google Maps API. Max Attempts Exceeded: ${ {error, googleMapsApiRetryCount, googleMapsApiMaxRetryAttempts} }`);
      };

      document.body.appendChild(script);
    };

    loadScript();
  });
}
