/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Button, FONTS, Loading, PALETTE, Radio, TextInput } from "@sayrhino/rhino-shared-js";

const ContainerDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '15px'
});

const ContentDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F5F6F7',
  border: 'none',
  borderRadius: '10px',
  padding: '20px',
  width: '480px',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const BackButton = styled.button([
  {
    border: 'none',
    background: 'transparent',
    color: PALETTE.brand100
  },
  FONTS.h6Demi
]);

const ActionButtonsDiv = styled.div({
  display: 'flex',
  gap: '5px',
  justifyContent: 'right',
  width: '100%'
});

const CancelEarlyTerminationBanner = ({
  onBack,
  onConfirm
}) => {
  return (
    <ContainerDiv>
      <ContentDiv>
        <p>
          If the renter no longer has a lease, they should not require coverage. You will still be able to file a claim on this policy up to 60 days after cancellation.
        </p>
        <ActionButtonsDiv>
          <BackButton onClick={onBack}>Back</BackButton>
          <Button id="cancelPolicyBtn" variant="primary" onClick={onConfirm}>
            Cancel Policy
          </Button>
        </ActionButtonsDiv>
      </ContentDiv>
    </ContainerDiv>
  )
}

export default CancelEarlyTerminationBanner;
