import axios from 'axios';
import { IClaimChangelog } from 'components/v2/App/interfaces';
import { useQuery, QueryObserverResult } from 'react-query';

const getChangelog = async (claimId: number) => {
  const { data } = await axios.get<IClaimChangelog>(`/v2/admin/claims/${claimId}/audits`);
  return data;
};

export default function useChangelog(claimId: number): QueryObserverResult<IClaimChangelog> | undefined {
  if (claimId) return useQuery<any>(['changelog', claimId], () => getChangelog(claimId));
}
