import axios from 'axios';
import { IWithdrawalInfoFull } from 'components/v2/App/DepositFunds/types';
import { QueryObserverResult, useQuery } from 'react-query';


const getWithdrawalDetails = async (id: string | undefined) => {
  const { data } = await axios.get<IWithdrawalInfoFull>(`/admin/funds/withdrawals/${id}`, {
    params: {
      withdraw_uid: id
    }
  });
  return data;
};

export default function useWithdrawalDetails(id: string | undefined): QueryObserverResult<IWithdrawalInfoFull> | undefined {
  if (id) return useQuery<IWithdrawalInfoFull>(['withdrawal details', id], () => getWithdrawalDetails(id));
}
