import { csrfToken } from 'utils/document';
import axios from 'axios';

const uploadPercentageFile = async (formData) => {
  const token = csrfToken();

  const res = axios.post('/v2/admin/insurance_carrier_percentage', formData, {
    headers: {
      'X-CSRF-Token': token,
      'Content-Type': 'multipart/form-data'
    }
  });

  return res;
};

export default uploadPercentageFile;
