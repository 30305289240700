import axios from 'axios';
import { IWithdrawalInfo, IWithdrawalParams } from 'components/v2/App/DepositFunds/types';
import { csrfToken } from 'utils/document';

const createWithdrawal = async (withdrawalInfo: IWithdrawalParams) => {
  const { user_bank_account_type, user_bank_account_uid, withdrawal_amount } = withdrawalInfo;

  const token = csrfToken();

  const response = await axios.post<IWithdrawalInfo>(
    '/admin/funds/withdrawals',
    {
      withdrawal: {
        user_bank_account_type,
        user_bank_account_uid,
        amount_in_cents: withdrawal_amount
      }
    },
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default createWithdrawal;
