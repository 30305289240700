/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Link, useLocation } from 'react-router-dom';
import { SearchIcon, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import qs from 'query-string';

const SearchInputLink = () => {
  const { pathname } = useLocation();
  const searchUrl = qs.stringifyUrl({
    url: '/search',
    query: {
      referrer: pathname
    }
  });
  return (
    <Link
      to={searchUrl}
      style={{
        width: '100%',
        background: '#F8F8FF',
        height: '3.75rem',
        borderBottom: `1px solid ${PALETTE.neutral12}`,
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 48
      }}
    >
      <div css={[{ marginTop: 16 }, FONTS.p1]}>Search</div>
      <SearchIcon height="20px" width="20px" color={PALETTE.neutral25} css={{ marginTop: 19, marginRight: '7%' }} />
    </Link>
  );
};

export default SearchInputLink;
