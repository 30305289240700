/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Button, FONTS, PALETTE, UploadIcon, CloseIcon, CheckIcon } from '@sayrhino/rhino-shared-js';
import { csrfToken } from 'utils/document';
import { useDropzone } from 'react-dropzone';
import useFileUploader from '../../../../../hooks/v2/useFileUploader';
import { AxiosError, AxiosResponse, Method } from 'axios';
import { downloadCsv } from '../singleProperty/UploadOptions';
import {
  IPropertyCsvUploadErrors,
  IPropertyCsvUploadResponse,
  IPropertyCsvUploadValidationResponse,
  IPropertyCsvUploadValidationStatus,
  PropertyCsvUploadResponse
} from 'components/tables/property_upload_table/interfaces';
import { ratioToPercentageFormatter } from '../../utils';
import useCsvValidationStatus from 'api/v2/useCsvValidationStatus';

interface IProps {
  onSubmit: (nextStep: string, options?: PropertyCsvUploadResponse) => void;
}

const cardCSS = css({
  height: 'fit-content',
  padding: '36px 48px',
  position: 'relative',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '16px',
  display: 'inline-flex',
  width: '576px',
  justifyContent: 'space-between'
});

const uploadIconCardCSS = css({
  background: PALETTE.brand4,
  border: `1px solid ${PALETTE.brand4}`,
  borderRadius: '60px',
  padding: '48px 29px',
  display: 'inline-flex'
});

const chooseFileCardCSS = css({
  display: 'inline-block'
});

const headingCSS = css({
  margin: '92px 0 60px 0',
  textAlign: 'center'
});

const pageCSS = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const errorCardCSS = css({
  background: '#DF2D42',
  borderRadius: '16px',
  padding: '32px 48px',
  width: '576px',
  marginBottom: '24px',
  display: 'inline-flex',
  justifyContent: 'space-between'
});

const closeButtonCSS = css({
  borderRadius: '16px',
  height: '32px',
  width: '32px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer'
  }
});

const innerErrorCardCSS = css({ display: 'inline-block', width: '336px' });
const errorHeaderCSS = css([[FONTS.h4], { color: PALETTE.neutralLight }]);
const errorSubheaderCSS = css([[FONTS.p2], { color: PALETTE.neutralLight, marginTop: '12px' }]);

const UploadProperties: React.FC<IProps> = (props: IProps) => {
  const fileUploader = useFileUploader();
  const [filename, setFilename] = useState<string | undefined>();
  const [errorClosed, setErrorClosed] = useState(false);
  const { setValidationUrl } = useCsvValidationStatus({
    onSuccess: (data: IPropertyCsvUploadValidationStatus) => {
      if (data.status === 'complete') {
        props.onSubmit('CsvSuccess', { valid_csv: JSON.parse(data.validated_data) });
        setValidationUrl(undefined);
      }

      if (data.status === 'failed') {
        props.onSubmit('CsvErrors', { error: JSON.parse(data.validated_data) });
        setValidationUrl(undefined);
      }
    },
    onError: (_error) => {
      setValidationUrl(undefined);
    }
  });
  const getFormData = <F extends File>(droppedFile: F) => {
    const formData = new FormData();
    const token = csrfToken() || '';

    formData.append('property[file]', droppedFile);
    formData.append('authenticity_token', token);

    return formData;
  };

  function onDrop<F extends File>(droppedFiles: F[]) {
    if (droppedFiles.length === 0) {
      return;
    }
    // This library technically supports uploading multiple files, but our
    // backend is only configured to take one. Let's assert that there is at
    // least one, then take the first.
    const droppedFile = droppedFiles[0];
    setFilename(droppedFile.name);
    const uploadFileConfig = {
      url: '/admin/properties/validate_csv',
      method: 'post' as Method,
      data: getFormData(droppedFile)
    };

    function onSuccess(data: AxiosResponse<IPropertyCsvUploadResponse | IPropertyCsvUploadValidationResponse>) {
      if ('url' in data.data) {
        setValidationUrl(data.data.url);
      } else {
        props.onSubmit('CsvSuccess', data.data);
      }
    }

    function onError(error: AxiosError<IPropertyCsvUploadErrors>) {
      if (error.response?.data?.error?.error_type === 'validation_error') {
        props.onSubmit('CsvErrors', error.response?.data);
      }
      setErrorClosed(false);
    }

    fileUploader.result.mutate(uploadFileConfig, { onSuccess, onError });
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

  const chooseFileComponent = () => {
    return (
      <div
        css={[cardCSS, { '&:hover': { backgroundColor: PALETTE.brand4, border: `1px dashed ${PALETTE.brand100}` } }]}
        role="button"
        {...getRootProps()}
      >
        <div css={chooseFileCardCSS}>
          <p css={[FONTS.h5, { color: PALETTE.brand100, margin: '8px 0' }]}>Drag and drop your CSV file here</p>
          <p css={[FONTS.p3, { color: PALETTE.neutral25, marginBottom: '24px' }]}>Supports .CSV</p>
          <Button children="Choose file..." variant="tertiary" style={{ padding: '4px 20px' }} />
        </div>
        <input type="file" id="file" data-testid="file" style={{ display: 'none' }} {...getInputProps()} />
        <div css={uploadIconCardCSS}>
          <UploadIcon height="48px" width="48px" color={PALETTE.raptor2} />
        </div>
      </div>
    );
  };

  const errorType = fileUploader.result.error?.response?.data?.error?.error_type;
  const csvError = () => {
    if (errorType === 'incorrect_format') {
      return (
        <div css={innerErrorCardCSS}>
          <p css={errorHeaderCSS}>Your file is incorrectly formatted</p>
          <p css={errorSubheaderCSS}>
            Your file's fields must match our formatting guidelines. Please &nbsp;
            <u onClick={downloadCsv} role="button" css={{ cursor: 'pointer' }}>
              download the template
            </u>{' '}
            and try again.
          </p>
        </div>
      );
    } else if (errorType === 'template_guideline_error') {
      return (
        <div css={innerErrorCardCSS}>
          <p css={errorHeaderCSS}>Error uploading your file</p>
          <p css={errorSubheaderCSS}>{filename} does not match upload template guidelines</p>
        </div>
      );
    } else if (errorType === 'no_rows') {
      return (
        <div css={innerErrorCardCSS}>
          <p css={errorHeaderCSS}>CSV is empty</p>
          <p css={errorSubheaderCSS}>Please include at least one property in the csv and try again</p>
        </div>
      );
    } else {
      return (
        <div css={innerErrorCardCSS}>
          <p css={errorHeaderCSS}>Error uploading your file</p>
          <p css={errorSubheaderCSS}>Please try again</p>
        </div>
      );
    }
  };

  const csvErrorComponent = () => {
    return (
      <div css={errorCardCSS}>
        {csvError()}
        <div css={{ display: 'inline-block' }}>
          <div
            css={[closeButtonCSS, { background: PALETTE.brand4 }]}
            onClick={() => setErrorClosed(true)}
            role="button"
            id="close-error-button"
          >
            <CloseIcon height="16px" width="16px" color={PALETTE.neutralDark} />
          </div>
        </div>
      </div>
    );
  };

  const uploadingComponent = () => {
    const { progress } = fileUploader;
    const progressRatio: number = progress !== undefined ? progress.loaded / progress.total : 0;
    const progressStr: string = ratioToPercentageFormatter(progressRatio);
    return (
      <div css={[cardCSS, { padding: '32px 48px' }]}>
        <div css={chooseFileCardCSS}>
          <p css={[FONTS.h4, { color: PALETTE.brand100, margin: '8px 0' }]}>Uploading...</p>
          <p css={[FONTS.p1, { color: PALETTE.neutralDark, marginBottom: '24px' }]}>{filename}</p>
        </div>
        <div css={chooseFileCardCSS}>
          <p css={[FONTS.h4, { color: PALETTE.brand100, margin: '8px 0' }]}>{progressStr}</p>
        </div>
        <div css={chooseFileCardCSS}>
          <div css={[closeButtonCSS, { background: '#FDF2F2' }]} role="button">
            <CloseIcon height="16px" width="16px" color="#DF2D42" />
          </div>
        </div>
      </div>
    );
  };

  const uploadedComponent = () => {
    return (
      <div css={[cardCSS, { background: PALETTE.brand100, padding: '32px 48px' }]}>
        <div css={chooseFileCardCSS}>
          <p css={[FONTS.h4, { color: PALETTE.neutralLight, margin: '8px 0' }]}>Uploaded</p>
          <p css={[FONTS.p1, { color: PALETTE.neutralLight, marginBottom: '24px' }]}>{filename}</p>
        </div>
        <div css={{ display: 'inline-block' }}>
          <div css={[closeButtonCSS, { background: PALETTE.brand4 }]} role="button">
            <CheckIcon height="20px" width="20px" color={PALETTE.brand100} />
          </div>
        </div>
      </div>
    );
  };

  const cardComponent = () => {
    if (fileUploader.result.isLoading) {
      return uploadingComponent();
    } else if (fileUploader.result.isSuccess || errorType === 'validation_error') {
      return uploadedComponent();
    } else {
      return chooseFileComponent();
    }
  };

  return (
    <div css={pageCSS}>
      <div css={headingCSS}>
        <h2 css={[FONTS.brandH1Bold]}>Import properties</h2>
        <p css={[FONTS.p1, { marginTop: '28px', width: '528px' }]}>
          Import all of your properties by uploading a CSV file below.
        </p>
      </div>
      {fileUploader.result.isError && !errorClosed && csvErrorComponent()}
      {cardComponent()}
    </div>
  );
};

export default UploadProperties;
