import React, { RefObject } from 'react';

interface IntersectionObserverOptions {
  root: Element | null; // element used as the viewport for checking visibility of target
  target: RefObject<HTMLDivElement>; // the target element
  onIntersect: Function; // Function passed to be executed
  threshold?: number | number[]; // percentage of the target's visibility the observer's callback should be executed
  rootMargin?: string; // margin around the root
  enabled?: boolean | undefined; // to enable observers
} // docs https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled
}: IntersectionObserverOptions): void {
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root,
        rootMargin,
        threshold
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target.current, enabled]);
}
