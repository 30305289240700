/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ActionButtonWrapper } from './ClaimStyles';
import { Link } from 'react-router-dom';
import { ActionButton } from '@sayrhino/rhino-shared-js';

const ActionButtons = ({
  showWithdraw,
  showEdit,
  withdrawClaim,
  claimId,
  isLoading,
  newExperience
}: {
  showWithdraw: boolean;
  showEdit: boolean;
  withdrawClaim: () => void;
  claimId: number;
  isLoading: boolean;
  newExperience: boolean;
}) => {
  return (
    <ActionButtonWrapper>
      {showEdit && (
        <Link to={`/admin/claims/${claimId}/edit`}>
          <ActionButton id="EditClaim" variant="noIcon" usage="neutral" disabled={newExperience} title={newExperience ? 'Not allowed for claims created in the new experience' : undefined}>
            Edit Claim
          </ActionButton>
        </Link>
      )}

      {showWithdraw && (
        <ActionButton id="Withdraw" variant="noIcon" usage="neutral" onClick={withdrawClaim} disabled={isLoading}>
          {isLoading ? 'Withdrawing...' : 'Withdraw'}
        </ActionButton>
      )}
    </ActionButtonWrapper>
  );
};

export default ActionButtons;
