import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { IInvitationEditPOST, IPartialQuote } from 'components/v2/App/interfaces';
import { useMutation, MutationFunction } from 'react-query';

interface IUpdateInviteResponse {
  message?: string;
}

const mapToUpdateInvitation = (invitation: Partial<IPartialQuote>): Partial<IInvitationEditPOST> => {
  const {
    coverage_amount,
    coverage_months,
    first_name,
    last_name,
    lease_start_date,
    lease_end_date,
    monthly_rent,
    phone,
    property_id,
    unit_id,
    id,
    coverage,
    cash_deposit_amount,
    cash_deposit_only,
    guarantor_coverage
  } = invitation;
  return {
    coverage_amount,
    coverage_months,
    first_name,
    last_name,
    id,
    lease_start_date,
    lease_end_date,
    monthly_rent,
    phone,
    property_id,
    unit_id,
    coverage,
    cash_deposit_amount,
    cash_deposit_only,
    guarantor_coverage
  };
};

const updateInvitation: MutationFunction<AxiosResponse<IUpdateInviteResponse>, IInvitationEditPOST> = async (
  invitation: IPartialQuote
): Promise<AxiosResponse<IUpdateInviteResponse>> => {
  const authenticity_token = csrfToken();
  const partial_quote = mapToUpdateInvitation(invitation);
  const response: AxiosResponse<IUpdateInviteResponse> = await axios.put(
    '/v2/admin/invitations/' + partial_quote.id,
    {
      partial_quote
    },
    {
      headers: {
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useUpdateInvitationMutation() {
  return useMutation(updateInvitation);
}
