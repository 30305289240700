import { csrfToken } from 'utils/document';
import axios from 'axios';

type payload = {
  sla_number: string;
  id: number;
};

const slaUpdateApi = async (data: payload) => {
  const authenticity_token = csrfToken();
  const res = await axios.patch(
    `/v2/admin/landlord_policies/${data.id}`,
    { landlord_policy: { sla_number: data.sla_number } },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return res;
};

export default slaUpdateApi;
