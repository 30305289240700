import axios, { AxiosError } from 'axios';
import {
  ICoverageOptions,
  ICoverageOptionsRaw,
  rawCoverageOptionsToCoverageOptions,
  IError,
  ICoverageSelection,
  ICoverageMultiplier,
  CoverageType
} from 'components/v2/App/interfaces';
import { centsToUSDFormatter } from 'components/v2/App/utils';
import { DOLLAR_AMOUNT, MULTIPLIER } from '../../components/v2/App/utils/constants';
import Decimal from 'decimal.js';
import { useState } from 'react';
import { useQuery, QueryObserverResult } from 'react-query';
import { csrfToken } from 'utils/document';

async function getCoverageOptions(propertyId: number, unitId?: number): Promise<ICoverageOptions> {
  let url = `/v2/admin/properties/${propertyId}`;
  if (unitId) {
    url = `${url}/units/${unitId}`;
  }
  url = `${url}/coverage_options.json`;
  const csrf = csrfToken();

  const response = await axios.get<ICoverageOptionsRaw>(url, {
    headers: {
      'X-CSRF-Token': csrf
    }
  });

  return rawCoverageOptionsToCoverageOptions(response.data);
}

export interface ICoverageState {
  result: QueryObserverResult<ICoverageOptions, AxiosError<IError>>;
  multipliers: ICoverageMultiplier[];
}

export interface ICoverageSelectionState {
  selected: ICoverageSelection;
  setSelected: (selection: ICoverageSelection) => void;
  setType: (type: CoverageType) => void;
}

export type CoverageState = ICoverageSelectionState & ICoverageState;

export type InitialCoverageState = ICoverageSelectionState | ICoverageSelection;

function isInitialCoverageState(x: InitialCoverageState): x is ICoverageSelectionState {
    return (x as ICoverageSelectionState).selected !== undefined;
}

function isInitialCoverageSelection(x: InitialCoverageState): x is ICoverageSelection {
    return (x as ICoverageSelection).type !== undefined;
}
interface IUseCoverageOptionsArgs {
  propertyId: number;
  unitId?: number;
  monthlyRentCents?: number;
  initialCoverageState: InitialCoverageState;
}

export function useCoverageState(currentSelection: ICoverageSelection): ICoverageSelectionState {
  const [type, setType] = useState<CoverageType>(currentSelection.type);
  const currentDollarAmount = currentSelection.type === DOLLAR_AMOUNT ? currentSelection.value : undefined;
  const currentMultiplier = currentSelection.type === MULTIPLIER ? currentSelection.value : undefined;

  const [dollarAmount, setDollarAmount] = useState<Decimal | undefined>(currentDollarAmount);
  const [multiplier, setMultiplier] = useState<Decimal | undefined>(currentMultiplier);

  let selectedValue;

  if (type === DOLLAR_AMOUNT) {
    selectedValue = dollarAmount;
  } else if (type === MULTIPLIER) {
    selectedValue = multiplier;
  } else {
    selectedValue = undefined;
  }

  const selected: ICoverageSelection = { type, value: selectedValue };
  const setSelected = ({ type, value }: ICoverageSelection) => {
    setType(type);
    if (type === DOLLAR_AMOUNT) {
      setDollarAmount(value);
    } else if (type === MULTIPLIER) {
      setMultiplier(value);
    }
  };

  return {
    setType,
    setSelected,
    selected
  };
}

export default function useCoverageOptions(args: IUseCoverageOptionsArgs): CoverageState {
  const { propertyId, unitId, monthlyRentCents, initialCoverageState } = args;
  const result: QueryObserverResult<ICoverageOptions, AxiosError<IError>> = useQuery(
    ['coverageOptions', propertyId, unitId],
    () => getCoverageOptions(propertyId, unitId)
  );
  const { setType, setSelected, selected } = isInitialCoverageState(initialCoverageState)
    ? initialCoverageState
    : useCoverageState(initialCoverageState);

  // Process multipliers with rent if provided.
  let multipliers: ICoverageMultiplier[] = [];
  if (result.data) {
    if (monthlyRentCents) {
      multipliers = result.data.multipliers.map((opt) => {
        const coverageAmount = new Decimal(monthlyRentCents).mul(opt.value);
        const coverageAmountString = centsToUSDFormatter(coverageAmount);
        const label = `${coverageAmountString} (${opt.label})`;
        return { ...opt, label };
      });
    } else {
      multipliers = result.data.multipliers;
    }
  }

  return {
    result,
    setType,
    setSelected,
    selected,
    multipliers
  };
}
