import { createContext, useContext } from 'react';
import { userTypes } from '../interfaces';

export interface IUserContext {
  role: userTypes;
  canSendInvites?: boolean;
  id: number;
  sessionId: string;
  isClaimAdmin?: boolean | null;
  isAutoApprove: boolean;
  isTfaRequired?: boolean;
  killingInvitesFullyEnabled?: boolean; // this should be removed when removing the feature flag :killing_invites
  killingInvitesSemiEnabled?: boolean; // this should be removed when removing the feature flag :killing_invites
  updateTFArequired?: (value: boolean) => void;
}

export interface IUserRole {
  isAdmin: boolean;
  isListingAgent: boolean;
  isPropertyManager: boolean;
  isPropertyManagerAdmin: boolean;
  isThirdPartyAdmin: boolean;
  isPartner: boolean;
}

export function userRole(role) {
  return {
    isLoggedOut: role === 'LoggedOut',
    isAdmin: role === 'Administrator',
    isListingAgent: role === 'ListingAgent',
    isPropertyManager: role === 'PropertyManager',
    isPropertyManagerAdmin: role === 'PropertyManagerAdmin',
    isThirdPartyAdmin: role === 'ThirdPartyAdministrator',
    isPartner: role === 'PropertyManagerAdmin' || role === 'PropertyManager'
  };
}

export const UserContext = createContext<IUserContext>({
  role: 'LoggedOut',
  id: 0,
  sessionId: '',
  canSendInvites: false,
  isClaimAdmin: null,
  isAutoApprove: false,
  isTfaRequired: false,
  killingInvitesFullyEnabled: false, // this should be removed when removing the feature flag :killing_invites
  killingInvitesSemiEnabled: false, // this should be removed when removing the feature flag :killing_invites
  updateTFArequired: () => undefined
});

export const useUserContext: () => IUserContext = () => {
  return useContext(UserContext);
};

export const useUserRole: () => IUserRole = () => {
  const userContext = useUserContext();
  return userRole(userContext.role);
};

export const useCanSendInvite = () => {
  return useUserContext().canSendInvites;
};

export const useIsClaimAdmin = () => {
  return useUserContext().isClaimAdmin;
};

export const useUserId = () => {
  const { id } = useContext(UserContext);
  return id;
};

export const useIsAutoApprove = () => {
  const userContext = useUserContext();
  if (userContext.isAutoApprove) {
    userContext.isAutoApprove = false;
    return true;
  }
  return false;
};

export const useIsTfaRequired = () => {
  const userContext = useContext(UserContext);
  return userContext.isTfaRequired;
};

export const useUpdateTFAState = () => {
  const userContext = useContext(UserContext);
  return userContext.updateTFArequired;
};

// this should be removed when removing the feature flag :killing_invites
export const useKillingInvitesFullyEnabled = () => {
  return useUserContext().killingInvitesFullyEnabled;
}

// this should be removed when removing the feature flag :killing_invites
export const useKillingInvitesSemiEnabled = () => {
  return useUserContext().killingInvitesSemiEnabled;
}

export default useUserRole;
