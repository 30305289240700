import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FONTS, ButtonHabitat, Card, TextInput } from '@sayrhino/rhino-shared-js';
import { fetchPropertiesBySite, ISite, linkPropertiesToSite } from 'api/v2/useSites';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';

import {
  StyledTableContainer,
  StyledCell,
  StyledColumnHeader,
  StyledHeaderRow,
  StyledRow,
  StyledTableFrame
} from './SitePropertyStyles';

const CardHeader = styled.h5(FONTS.h5);

const StyledCardDetails = styled(Card)({
  padding: '1rem',
  height: 'auto !important',
  overflow: 'hidden',
  cursor: 'auto',
  marginBottom: 24,
  width: '550px !important'
});

const ButtonRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px'
});

const Row = styled(ButtonRow)({
  gap: '16px',
  justifyContent: 'flex-end',
  width: '100%'
});

const InputArea = styled.div({
  margin: '16px 0'
});

const Link = styled.a`
  color: #6318ce;
`;

type IProp = {
  flipView: () => void;
  site?: ISite;
};

const PropertySitesCard: React.FC<IProp> = ({ flipView, site }) => {
  const [propertyIds, setpropertyIds] = useState<string>('');
  const [properties, setProperties] = useState<any[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    const fetchProperties = async () => {
      const results = await fetchPropertiesBySite(site?.id);
      setProperties(results);
    };

    fetchProperties();
  }, []);

  const linkProperties = async () => {
    if (propertyIds.length == 0) return;

    const data = {
      id: site?.id,
      property_ids: propertyIds
    };

    var res = await linkPropertiesToSite(data);

    if (res.success) {
      flipView();
      addToast(res.message);
    } else addToast(res.message, TOAST_STATUS.ERROR);
  };

  const goToPropertyPage = (propertyId) => {
    window.location.replace('/admin/properties/' + propertyId);
  };

  const inputPropertyHandler = (val) => {
    setpropertyIds(val);
  };

  return (
    <StyledCardDetails>
      <CardHeader>Linked Properties</CardHeader>
      <InputArea>
        <TextInput
          id="propertyId"
          label="Add Property IDs (comma separated)"
          onChange={(e) => inputPropertyHandler(e.target.value)}
          value={propertyIds}
        />
      </InputArea>
      <ButtonHabitat id="linkProperties" variant="interaction" size="small" usage="primary" onClick={linkProperties}>
        Add Properties
      </ButtonHabitat>

      <StyledTableContainer>
        <StyledTableFrame>
          <tbody>
            <StyledHeaderRow>
              <StyledColumnHeader>ID</StyledColumnHeader>
              <StyledColumnHeader>Building Name</StyledColumnHeader>
              <StyledColumnHeader>Address Line One</StyledColumnHeader>
              <StyledColumnHeader>Status</StyledColumnHeader>
            </StyledHeaderRow>
            {properties &&
              properties.length > 0 &&
              properties.map((property, index) => {
                return [
                  <StyledRow key={index}>
                    <StyledCell>
                      <Link onClick={() => goToPropertyPage(property.id)}>{property.id}</Link>
                    </StyledCell>
                    <StyledCell>{property.building_name}</StyledCell>
                    <StyledCell>{property.address}</StyledCell>
                    <StyledCell> {property.status ? 'Enabled' : 'Disabled'}</StyledCell>
                  </StyledRow>
                ];
              })}
          </tbody>
        </StyledTableFrame>
      </StyledTableContainer>

      <ButtonRow>
        <Row>
          <ButtonHabitat id="cancel" variant="interaction" size="small" usage="primary" onClick={flipView}>
            Close
          </ButtonHabitat>
        </Row>
      </ButtonRow>
    </StyledCardDetails>
  );
};

export default PropertySitesCard;
