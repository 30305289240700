import axios from 'axios';
import { IData, ISearchResult, SortOrder, ISearchMeta, SearchResultType } from 'components/v2/App/interfaces';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

const emptySearchResponse: IData<ISearchResult[], ISearchMeta> = {
  data: [],
  meta: {
    page: 0,
    per: 0
  }
};

const getSearchResults = async (
  query: string,
  sortBy: SortOrder,
  type: SearchResultType | null,
  page: number = 0,
  priority: string
) => {
  if (query.length === 0) {
    return emptySearchResponse;
  }

  const params = {
    query,
    sort_by: sortBy,
    collection: type,
    per: 20,
    page,
    priority: priority
  };

  const { data } = await axios.get<IData<ISearchResult[], ISearchMeta>>(`/search.json`, { params });
  return data;
};

export default function useSearch(
  query: string,
  sortBy: SortOrder,
  type: SearchResultType | null,
  priority: string
): InfiniteQueryObserverResult<IData<ISearchResult[]>, ISearchMeta> {
  return useInfiniteQuery<IData<ISearchResult[]>, ISearchMeta>(
    ['searchResults', query, sortBy, type],
    ({ pageParam = 0 }) => getSearchResults(query, sortBy, type, pageParam, priority),
    {
      getNextPageParam: (lastGroup) => lastGroup.meta.next_page
    }
  );
}
