import axios from 'axios';
import { useMutation } from 'react-query';
import { csrfToken } from 'utils/document';
import { NewTeamMemberFormValues } from '../AddNewTeamMember/NewTeamMember';
import { MemberProperty } from './AssignTeamForm';

export const formatData = (existing: MemberProperty[], newUserProp: NewTeamMemberFormValues[]) => {
  const team_members = {};
  let count = 0;

  for (const item of existing) {
    let user_ids: number[] = [];
    let property_ids: number[] = [];

    item.users.forEach(({ id }) => user_ids.push(id));
    item.properties.forEach(({ property_id }) => property_ids.push(property_id));
    team_members[count++] = { user_ids, property_ids };
  }

  for (const item of newUserProp) {
    let user = {
      name: item.name,
      phone_number: item.phone,
      email: item.email,
      type: item.type
    };
    let property_ids: number[] = [];

    item.properties.forEach(({ property_id }) => property_ids.push(property_id));
    team_members[count++] = { user, property_ids };
  }

  return { team_members };
};

export const createAssignment = async (formData) => {
  const authenticity_token = csrfToken();
  const { data, status } = await axios.post('/admin/properties/assign_team_members', formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });

  return { data, status };
};

export default function useAssignmentMutation() {
  return useMutation(createAssignment);
}
