import axios from 'axios';
import { ICoverageRuleset, ICustomCoverageRuleset } from 'components/v2/App/interfaces';
import { QueryObserverResult, useQuery } from 'react-query';

export const COVERAGE_RULES_KEY = 'coverage rules';
// Retrieve coverage rules array by partner uuid
const getPartnerCoverageRules = async (partner_uuid?: string) => {
  const { data } = await axios.get<ICustomCoverageRuleset[]>(
    `/v2/integrations/partners/${partner_uuid}/coverage_rule_sets`
  );
  return data;
};

export default function usePartnerCoverageRules(
  id?: string
): QueryObserverResult<ICustomCoverageRuleset[]> | undefined {
  return useQuery<ICustomCoverageRuleset[]>([COVERAGE_RULES_KEY, id], () => getPartnerCoverageRules(id), {
    enabled: !!id
  });
}
