/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { ActionButton } from '@sayrhino/rhino-shared-js';
import { useNavigate } from 'react-router-dom';
import useUserRole from '../../utils/userRole';

const ActionGroup = styled.div({
  display: 'flex',
  gap: 8
});

const ActionButtons = ({
  showEdit,
  showDelete,
  propertyOwnerId,
  deleteAction
}: {
  showEdit: boolean;
  showDelete: boolean;
  propertyOwnerId: number;
  deleteAction: () => void;
}) => {
  const navigate = useNavigate();

  const { isPropertyManagerAdmin } = useUserRole();
  const pmaEditLink = `/v2/users/account/property_owners/${propertyOwnerId}/pma_edit`;
  const adminEditLink = `/admin/property_owners/${propertyOwnerId}/edit`;
  const editLink = isPropertyManagerAdmin ? pmaEditLink : adminEditLink;

  return (
    <ActionGroup>
      {showEdit && (
        <ActionButton
          onClick={() => navigate(editLink)}
          id="editPropertyOwner"
          variant="noIcon"
          usage="neutral"
          disabled={false}
          aria-label="Edit Property Owner"
        >
          Edit Property Owner
        </ActionButton>
      )}

      {showDelete && (
        <ActionButton
          id="deleteInvitation"
          variant="noIcon"
          usage="neutral"
          disabled={false}
          aria-label="Delete Property Owner"
          onClick={deleteAction}
        >
          Delete Property Owner
        </ActionButton>
      )}
    </ActionGroup>
  );
};

export default ActionButtons;
