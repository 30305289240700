/** @jsx jsx */
import { jsx } from '@emotion/core';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import {
  contextLineCSS,
  closeIconCSS,
  ClickHereLink,
  RhinoLogoContainer,
  PolicyRenewalDescriptionContainer,
  StyledTitle,
  StyledDescription,
  StyledLine,
  PolicyRenewalSuccessContainer,
  PaperPlaneContainer,
  LineBreak,
  RenewalRequestSentFormContainer,
  InvitationHeaderContainer,
  FormContainer,
  SendAnInviteTitle,
  CloseButton
} from './Styles';
import PaperPlane from '../PaperPlaneIcon';
import { CloseIcon, RhinoLogo } from '@sayrhino/rhino-shared-js';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import { createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobileDevice } from 'utils/mobile';
import GettingStartedSteps from './GettingStartedSteps';

const IS_MOBILE = isMobileDevice();

export const PolicyRenewalRequestSent = () => {
  const navigate = useNavigate();
  const formRef = createRef<HTMLDivElement>();

  return (
    <div>
      <GettingStartedSteps type="renewal" />
      <RenewalRequestSentFormContainer id="form-container">
        <InvitationHeaderContainer id="header" className="invitation-header">
          <SendAnInviteTitle>Send an invitation</SendAnInviteTitle>
          <CloseButton onClick={() => navigate(-1)} aria-label="Leave invitation form">
            <CloseIcon css={closeIconCSS} />
          </CloseButton>
        </InvitationHeaderContainer>

        <form>
          <FormContainer id="form" ref={formRef}>
            {IS_MOBILE && (
              <RhinoLogoContainer>
                <RhinoLogo />
              </RhinoLogoContainer>
            )}

            <PolicyRenewalSuccessContainer>
              <PaperPlaneContainer>
                <PaperPlane />
              </PaperPlaneContainer>

              <StyledTitle>Policy renewal request is sent!</StyledTitle>

              <StyledLine css={[contextLineCSS]} />

              <PolicyRenewalDescriptionContainer>
                <StyledDescription>
                  An email with the renewal details has been sent to your renter's email.
                  <LineBreak />
                  <LineBreak />
                  <LineBreak />
                  You can view this renewal request anytime from the policy detail screen, or
                  <ClickHereLink
                    target="_blank"
                    href={``}
                    // TO DO: Currently a placeholder, PEA-498 implements the route for this.
                    // onClick={() =>
                    //   ()
                    // }
                  >
                    click here
                  </ClickHereLink>
                  to view now.
                </StyledDescription>
              </PolicyRenewalDescriptionContainer>
            </PolicyRenewalSuccessContainer>
          </FormContainer>
        </form>
      </RenewalRequestSentFormContainer>
    </div>
  );
};

export default PolicyRenewalRequestSent;
