import React from 'react';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { render } from '@testing-library/react';
import ClaimAttachments from '../Claims/ClaimDetails/ClaimAttachments';

const attachmentsData = {
  data: [
    {
      blob_id:
        'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMTBmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--140df7b92e245f3ac734d49d2ea6db22ce1ac279',
      filename: 'lion.jpeg',
      url: 'http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMTBmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--140df7b92e245f3ac734d49d2ea6db22ce1ac279/images%20(4).jpeg',
      attached_to: 'Claim'
    },
    {
      blob_id:
        'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMTRmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c7128864a83913c261b8ba91c45cfa0d8254934a',
      filename: 'nature.jpeg',
      url: 'http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMTRmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c7128864a83913c261b8ba91c45cfa0d8254934a/images%20(3).jpeg',
      attached_to: 'Claim'
    },
    {
      blob_id:
        'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMXdmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--2e5dc55dea77d6cfeab9f3d9fb18688c616980d9',
      filename: 'peacock.jpeg',
      url: 'http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMXdmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--2e5dc55dea77d6cfeab9f3d9fb18688c616980d9/images%20(2).jpeg',
      attached_to: 'Claim'
    }
  ],
  meta: { total_count: 3, next_page: null, page: 1, per: 20 }
};

const handleModal = () => {
  return 'openModal';
};

const handleDownlaod = () => {
  return 'donwloaded';
};

let server;

describe('<ClaimAttachments />', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('Can view notes and attachments', async () => {
    const dummyGetNextTabIndex = (groupdId) => {
      return 0;
    };
    const { findByText } = render(
      <Wrapper>
        <ClaimAttachments
          attachmentsData={attachmentsData}
          selectedFile={attachmentsData[0]}
          isLoading={false}
          page={1}
          setPage={() => jest.fn()}
          handleDownload={handleDownlaod}
          handleModal={handleModal}
          isModalOpen={false}
          getNextTabIndex={dummyGetNextTabIndex}
        />
      </Wrapper>
    );
    let attachment = await findByText('lion.jpeg');
    expect(attachment).toBeDefined();
    attachment = await findByText('nature.jpeg');
    expect(attachment).toBeDefined();
  });
});
