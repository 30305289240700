import React from 'react';
import { render } from '@testing-library/react';
import AdminDashboardPanelItems from '../AdminDashboardPanelItems';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { IAdminSummaryMetricsData } from '../interfaces';

describe('<AdminDashboardPanelItems />', () => {
  beforeAll(() => makeServer());

  const doRender = (data) =>
    render(
      <Wrapper>
        <AdminDashboardPanelItems data={data} />
      </Wrapper>
    );

  const makeMockData = (overrides?: object): IAdminSummaryMetricsData => ({
    average_days_to_claim_payout_ytd: 1,
    average_days_to_first_renter_ytd: 2,
    invitations_completed_this_month_count: 3,
    invitations_sent_this_month_count: 4,
    invitations_started_total_count: 5,
    invitations_this_month_total_count: 6,
    invitations_total_count: 8,
    new_claims_this_month_count: 7,
    new_subscribers_monthly_goal: 8,
    new_subscribers_this_month_count: 9,
    ...overrides
  });

  test('renders the average time to claim payout metric when the average days to claim payout metric is present', async () => {
    const data = makeMockData();
    const { findByText } = doRender(data);
    const item = await findByText('Average time to claim payout');

    expect(item).toBeDefined();
  });

  test('does not render the average time to claim payout metric when the average days to claim payout metric is not present', async () => {
    const data = makeMockData({ average_days_to_claim_payout_ytd: null });
    const { queryByText } = doRender(data);
    const item = await queryByText('Average time to claim payout');

    expect(item).toBeNull();
  });

  test('renders the average time to first renter metric when the metric is present in the data', async () => {
    const data = makeMockData();
    const { findByText } = doRender(data);
    const item = await findByText('Average time to first renter');

    expect(item).toBeDefined();
  });

  test('does not render the average time to first renter metric when the metric is not present in the data', async () => {
    const data = makeMockData({ average_days_to_first_renter_ytd: null });
    const { queryByText } = doRender(data);
    const item = await queryByText('Average time to first renter');

    expect(item).toBeNull();
  });

  test('renders the number of in-progress invitations as 0 when not present in the data', async () => {
    const data = makeMockData({ invitations_started_total_count: null });
    const { findAllByRole } = doRender(data);
    const listItems = await findAllByRole('listitem');
    const inProgressInvitationItem = listItems[4];

    expect(inProgressInvitationItem).toHaveTextContent('0');
  });

  test('renders the number of total invitations as 0 when not present in the data', async () => {
    const data = makeMockData({ invitations_total_count: null });
    const { findAllByRole } = doRender(data);
    const listItems = await findAllByRole('listitem');
    const totalInvitationsItem = listItems[5];

    expect(totalInvitationsItem).toHaveTextContent('0');
  });

  test('renders the number of new claims as 0 when not present in the data', async () => {
    const data = makeMockData({ new_claims_this_month_count: null });
    const { findAllByRole } = doRender(data);
    const listItems = await findAllByRole('listitem');
    const newClaimsItem = listItems[6];

    expect(newClaimsItem).toHaveTextContent('0');
  });

  test('renders the sign up rate for invitations sent when the metric is present in the data', async () => {
    const data = makeMockData();
    const { findByText } = doRender(data);
    const item = await findByText('Sign up rate for invitations sent');

    expect(item).toBeDefined();
  });

  test('renders an invitation CTA when the sign up rate cannot be calculated', async () => {
    const data = makeMockData({
      invitations_completed_this_month_count: null,
      invitations_this_month_total: null
    });
    const { findByText } = doRender(data);

    const item = await findByText('Invite renters to unlock this stat');
    expect(item).toBeDefined();
  });
});
