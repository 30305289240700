/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { CloseIcon, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import React from 'react';
import { QueryObserverResult } from 'react-query';
import { Link } from 'react-router-dom';
import { IClaimStaticFilterOptions, IFilters, IStaticFilterOptions } from '../interfaces';
import { useReferrer } from '../utils';
import { useFilterContext } from '../utils/filters';
import AppliedFilters from './AppliedFilters';
import StateFilterOptions from './StateFilterOptions';

export const StyledCheckbox = styled.div([
  FONTS.p2,
  {
    flexBasis: '50%',
    marginBottom: '0.75rem',
    '&:nth-of-type(2n+2)': {
      paddingLeft: '1.5rem'
    },
    '&:nth-of-type(2n+1)': {
      paddingRight: '1.5rem'
    }
  }
]);

export const clearButtonCSS = ({ canClear }) =>
  css([FONTS.h6], {
    backgroundColor: 'transparent',
    border: 'none',
    display: canClear ? 'block' : 'none',
    paddingRight: '0 .75rem 0 0',
    color: `${PALETTE.alert125}`
  });

const closeButtonCSS = css({
  alignItems: 'center',
  backgroundColor: PALETTE.neutral4,
  border: 'none',
  borderRadius: '20px',
  display: 'flex',
  height: '2rem',
  justifyContent: 'center',
  width: '2rem'
});

const headerCSS = css({
  alignItems: 'center',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  display: 'flex',
  height: '3.75rem',
  justifyContent: 'space-between',
  marginBottom: '2.5rem',
  padding: '1rem 2rem',
  width: '100%'
});

export const Divider = styled.div({
  borderTop: `1px solid ${PALETTE.neutral12}`
});

export const SectionTitle = styled.h4([FONTS.h4], { color: PALETTE.neutral88, marginBottom: '15px' });

export const TitleContainer = styled.div({ marginBottom: '24px', padding: '0 48px' });

export const ClearButton = styled.button([FONTS.h6], {
  backgroundColor: 'transparent',
  border: 'none',
  paddingRight: '0 .75rem 0 0',
  color: `${PALETTE.alert125}`
});

export const TitleWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline'
});

export const newSelections = (selection, selected) =>
  selected.includes(selection) ? selected.filter((s) => s !== selection) : selected.concat([selection]);

export const trackFilters = (filters, selectedFilter, segmentUser) => {
  const isPoliciesPage = window.location.href.includes('renter_policies');
  if (isPoliciesPage) {
    const filterType = Object.keys(filters)[0];
    const newFilterApplied = !selectedFilter.includes(filters[filterType]);
    if (newFilterApplied) {
      window.analytics.track('Policy Filter Applied', {
        ...segmentUser,
        filters
      });
    }
  }
};

const Filters: React.FC<{
  selectedFilters: IFilters;
  setFilters: (args: Partial<IFilters>) => void;
  staticFilterOptions: () => QueryObserverResult<IClaimStaticFilterOptions | IStaticFilterOptions>;
}> = ({ children, selectedFilters, setFilters, staticFilterOptions }) => {
  const referrer = useReferrer() || '..';
  const { data } = staticFilterOptions();
  const statesCount = data?.states.length;
  const { filtersCount } = useFilterContext();

  return (
    <React.Fragment>
      <div css={headerCSS}>
        <h5 css={[FONTS.h5, { color: PALETTE.neutral25 }]}>Filters</h5>
        <Link to={referrer} css={closeButtonCSS} aria-label="collapse">
          <CloseIcon color={PALETTE.neutral25} height="16px" width="16px" />
        </Link>
      </div>
      {filtersCount > 0 ? (
        <React.Fragment>
          <TitleContainer>
            <TitleWrapper>
              <SectionTitle>Applied Filters</SectionTitle>
              {Object.values(selectedFilters).some((filters) => filters.length > 0) ? (
                <ClearButton onClick={() => setFilters({ markets: [], states: [], properties: [], statuses: [] })}>
                  Clear all
                </ClearButton>
              ) : null}
            </TitleWrapper>
            <Divider />
          </TitleContainer>
          <AppliedFilters query={staticFilterOptions} />
        </React.Fragment>
      ) : null}
      <TitleContainer>
        <TitleWrapper>
          <SectionTitle>Location</SectionTitle>
          {selectedFilters.states.length || selectedFilters.properties.length ? (
            <ClearButton onClick={() => setFilters({ states: [], properties: [] })}>Clear</ClearButton>
          ) : null}
        </TitleWrapper>
        <Divider />
      </TitleContainer>

      <div>
        {statesCount && statesCount > 1 ? (
          <StateFilterOptions
            query={staticFilterOptions}
            selectedStates={selectedFilters.states}
            setFilters={setFilters}
          />
        ) : null}
        {children}
      </div>
    </React.Fragment>
  );
};

export default Filters;
