import axios from 'axios';
import { IInvitation } from 'components/v2/App/interfaces';
import { QueryObserverResult, useQuery } from 'react-query';

const getInvitation = async (id: number) => {
  const { data } = await axios.get<IInvitation>(`/v2/admin/invitations/${id}`);
  return data;
};

export const getInvitationQueryKey = (id: number) => ['invitation', id];

export default function useInvitation(id: number): QueryObserverResult<IInvitation> {
  return useQuery<IInvitation>(getInvitationQueryKey(id), () => getInvitation(id));
}
