import ApplicationTable from './application_table';
import { EditColumn } from './utils';

class PropertyPolicyTable extends ApplicationTable {
  public selector = '#property_policies';
  public pathPrefix = 'property_policies/';
  public emptyMessageText = 'There are no property policies to view';
  public dom = '<"search"fl><"top">rt<"bottom"ip><"clear">';

  protected columns = [
    {data: 'property'},
    {data: 'display_id'},
    {data: 'status'},
    {data: 'created_at'},
    EditColumn
  ];
  protected columnDefs = [
    {
      targets: [2, 3, 4],
      data: undefined,
      defaultContent: ''
    }
  ];
}

export default PropertyPolicyTable;
