import React, { ReactNode } from 'react';
import { jsx } from '@emotion/core';
import { Alert2Icon, InlineNotification, PALETTE } from '@sayrhino/rhino-shared-js';
import { MULTIPLIER, CUSTOM_DOLLAR_AMT_COVERAGE, PROPERTY_DEFAULT_AMT_COVERAGE } from './utils/constants';
import { centsToUSDFormatter, stringifyDate } from './utils/index';
import Decimal from 'decimal.js';

interface IPropertyDefault {
  value?: number;
  label: string;
  type: string;
  coverageAmountCents?: number;
}

export interface IPendingChangesetCamelized {
  propertyId?: number;
  unitId?: number;
  buildingName?: string;
  unitName?: string;
  monthlyRentCents?: number;
  monthlyRent?: string;
  leaseStartDate?: string;
  leaseEndDate?: string;
  coverageOptionSelected?: string;
  coverageOptionValue?: number;
  customCoverageAmountCents?: number;
  customFlatCoverage?: string;
  status: string;
  needsTenantApproval: boolean;
  fullAddress?: string;
  propertyDefault: IPropertyDefault;
}

interface IProps {
  changeset: IPendingChangesetCamelized;
  policyLeaseStartDate: Date;
  policyLeaseEndDate: Date;
}

const setChangesetMessage = (changeset, policyLeaseStartDate, policyLeaseEndDate) => {
  const {
    fullAddress,
    monthlyRentCents,
    monthlyRent,
    leaseStartDate,
    leaseEndDate,
    coverageOptionSelected,
    customCoverageAmountCents,
    customFlatCoverage,
    propertyDefault
  } = changeset;

  const msgs: ReactNode[] = [];

  if (fullAddress) {
    msgs.push(
      <li
        id="full-address"
        key="pending-changeset-banner-full-address"
        style={{ color: 'unset' }}
      >{`Lease address: ${fullAddress}`}</li>
    );
  }

  if (monthlyRentCents) {
    msgs.push(
      <li
        id="monthly-rent"
        key="pending-changeset-banner-monthly-rent"
        style={{ color: 'unset' }}
      >{`Monthly rent: ${monthlyRent}`}</li>
    );
  }

  if (leaseStartDate || leaseEndDate) {
    const startDate = leaseStartDate || stringifyDate(policyLeaseStartDate);
    const endDate = leaseEndDate || stringifyDate(policyLeaseEndDate);

    msgs.push(
      <li
        id="lease-dates"
        key="pending-changeset-banner-lease-dates"
        style={{ color: 'unset' }}
      >{`Lease dates: ${startDate} — ${endDate}`}</li>
    );
  }

  if (coverageOptionSelected === PROPERTY_DEFAULT_AMT_COVERAGE) {
    let msg = 'Coverage option: ';

    if (propertyDefault.type === MULTIPLIER) {
      msg += propertyDefault.label;
    } else {
      msg += `${centsToUSDFormatter(new Decimal(propertyDefault.value))}`;
    }

    msg += ' (property default)';
    msgs.push(
      <li id="coverage-option" key="pending-changeset-banner-coverage-option" style={{ color: 'unset' }}>
        {msg}
      </li>
    );
  } else if (coverageOptionSelected && customCoverageAmountCents) {
    let msg = `Coverage option: ${customFlatCoverage}`;

    if (coverageOptionSelected !== CUSTOM_DOLLAR_AMT_COVERAGE) {
      msg += ` (${coverageOptionSelected})`;
    }

    msgs.push(
      <li id="coverage-option" key="pending-changeset-banner-coverage-option" style={{ color: 'unset' }}>
        {msg}
      </li>
    );
  }

  return msgs;
};

const PendingChangesetBanner: React.FC<IProps> = ({ changeset, policyLeaseStartDate, policyLeaseEndDate }) => {
  const styling = {
    marginBottom: '24px',
    borderRadius: '8px',
    height: '100%',
    padding: '13px 0px 13px 24px'
  };

  return (
    <InlineNotification variant="highPriority" style={styling}>
      <div style={{ color: 'unset' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ color: PALETTE.alert100 }}>{'Waiting for renter confirmation of the following changes:'}</span>
          <div style={{ color: 'unset', padding: '0px 0px 0px 45px' }}>
            <Alert2Icon color={PALETTE.alert100} height="20px" width="20px" />
          </div>
        </div>
        <ul>{setChangesetMessage(changeset, policyLeaseStartDate, policyLeaseEndDate)}</ul>
      </div>
    </InlineNotification>
  );
};

export default PendingChangesetBanner;
