import React from 'react';
import makeServer from '../testUtils/server';
import { Route, Routes } from 'react-router-dom';
import Wrapper from '../testUtils/Wrapper';
import { render, screen } from '@testing-library/react';
import { UserContext } from '../utils/userRole';
import ClaimNotes from '../Claims/ClaimDetails/ClaimNotes';

const handleModal = () => {
  return 'false';
};
const handleDownload = () => {
  return 'Downloaded';
};
let server;

describe('<ClaimDetails />', () => {
  const renderAsRole = (role, claimId) =>
    render(
      <UserContext.Provider value={{ id: 1, role: role, sessionId: '', isAutoApprove: false }}>
        <Wrapper initialEntries={[`/admin/claims/${claimId}`]}>
          <Routes>
            <Route
              path="/admin/claims/:claimsId"
              element={<ClaimNotes handleModal={handleModal} handleDownload={handleDownload} />}
            />
          </Routes>
        </Wrapper>
      </UserContext.Provider>
    );

  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('Can view notes and attachments', async () => {
    // render with assigned claim
    renderAsRole('Administrator', 8509);

    const note1 = await screen.findByText('lets add a note with attachments');
    expect(note1).toBeDefined();
    const author1 = await screen.findByText('John Doe');
    expect(author1).toBeDefined();
    const attachment1 = await screen.findByText('another-meme.png');
    expect(attachment1).toBeDefined();
    const attachment2 = await screen.findByText('just-a-meme.png');
    expect(attachment2).toBeDefined();
  });
});
