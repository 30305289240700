import styled from '@emotion/styled';
import { FONTS, PALETTE, Button, CloseButton } from '@sayrhino/rhino-shared-js';

export const DrawerModalHeader = styled.h3(FONTS.h4);

export const ImageTag = styled.img({
  maxWidth: '100%',
  height: 'auto'
});

export const FileTag = styled.embed({
  width: '100%',
  height: '100%'
});

export const FileViewerDownloadButtonContainer = styled.div([FONTS.p2Medium], {
  display: 'flex',
  alignItems: 'center'
});

export const FileViewerDownloadButton = styled(Button)({
  marginTop: '24px',
  height: '30px !important',
  '&:hover': {
    span: {
      color: PALETTE.neutralLight
    },
    svg: {
      color: PALETTE.neutralLight
    }
  }
});

export const FileViewerLeftSectionForImage = styled.div({
  flex: '0.7',
  background: PALETTE.neutral4,
  overflow: 'auto',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh'
});

export const FileViewerLeftSectionForPDF = styled.div({
  flex: '0.7',
  background: PALETTE.neutral4,
  height: '100%'
});

export const FileViewerLeftSectionForLoading = styled.div({
  flex: '0.7',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

export const FileViewerRightSection = styled.div({
  flex: '0.3',
  padding: '40px',
  flexDirection: 'column',
  background: PALETTE.neutralLight,
  height: '100vh'
});

export const FileViewerRightUpperSection = styled.div({
  flex: '0.3',
  padding: '0px'
});

export const FileViewerRightLowerSection = styled.div({
  marginTop: '60px',
  flex: '0.7',
  padding: '0'
});

export const FileViewerCloseButton = styled(CloseButton)({
  background: PALETTE.brand4
});

export const FileViewerContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  overflowx: 'auto',
  height: '100vh',
  '@media only screen and (max-width: 1366px)': {
    width: '80%'
  }
});

export const FileViewerSubText = styled.p([FONTS.p3Light], { color: PALETTE.neutral65 });
