/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { useState } from 'react';
import { TextInput as TextInputBase, Radio, FONTS } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import Toggle from 'components/active_states/Toggle';

export const RadioRoot: any = Radio.Root;
export const TextInput = styled(TextInputBase)({
  width: '100%'
});

export const Title = styled.p([
  FONTS.p1Medium,
  {
    fontSize: '18px',
    lineHeight: '32px',
    margin: '24px 0'
  }
]);

const Subtext = styled.p([FONTS.p3]);

export enum CashDepositStatus {
  ENABLE = 'on',
  DISABLE = 'off'
}

export const CashSecurityDepositInformation = () => {
  const [cashDepositEnabled, setCashDepositEnabled] = useState<string>('off');
  const [showCashDepositStatusSection, setshowCashDepositStatusSection] = useState<boolean>(false);

  return (
    <div>
      <h4 css={[FONTS.h4, { marginBottom: 30 }]}>Cash Security Deposit Information</h4>

      <div className="form-group renter_policy__field">
        <label htmlFor="change_cash_deposit_enabled" className="control-label">
          Change Cash Deposit Enabled
        </label>

        <span className="float-right" style={{ marginTop: '5px' }}>
          <Toggle
            onColor="#F5F4FB"
            name="change_cash_deposit_status"
            checked={false}
            handleToggle={(value) => setshowCashDepositStatusSection(value)}
          />
        </span>
      </div>

      {showCashDepositStatusSection && (
        <div>
          <p css={[FONTS.p1Extended, { marginBottom: 20 }]}>Cash Deposit Status</p>

          <RadioRoot
            id="cash_deposit_enabled"
            value={cashDepositEnabled}
            name="cash_deposit_enabled"
            onValueChange={(value: CashDepositStatus) => {
              setCashDepositEnabled(value);
            }}
          >
            <Radio.Option
              data-cy="enabledCashDeposits"
              value={CashDepositStatus.ENABLE}
              label="Enable cash deposits for all properties"
            />
            <Radio.Option
              data-cy="disabledCashDeposits"
              value={CashDepositStatus.DISABLE}
              label="Disable cash deposits for all properties"
            />
          </RadioRoot>
        </div>
      )}
    </div>
  );
};

export default CashSecurityDepositInformation;
