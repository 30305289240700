import mask from './mask';

export interface IFormatOptions {
  allowNegative?: boolean;
  decimalSeparator?: string;
  precision?: string;
  prefix?: string;
  suffix?: string;
  thousandSeparator?: string;
  allowEmpty?: boolean;
}

export const CURRENCY_FORMAT_OPTIONS: IFormatOptions = {
  allowNegative: false,
  decimalSeparator: '.',
  precision: '2',
  prefix: '$',
  suffix: '',
  thousandSeparator: ','
};

export const formatMoney = (value: number, options: IFormatOptions = {}) => {
  const {
    precision, decimalSeparator, thousandSeparator, allowNegative, prefix, suffix
  } = Object.assign({}, CURRENCY_FORMAT_OPTIONS, options);

  return mask(value / 100, Number(precision), decimalSeparator, thousandSeparator, allowNegative, prefix, suffix);
};
