/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { contextParaCSS, contextHeadingCSS, numberCircleCSS, contextLineCSS } from './Styles';
import { mediaQueryWidth } from '../utils';
import { BREAKPOINTS } from '../utils/constants';

export interface IGettingStartedStepsProps {
  type: string;
}

export const GettingStartedSteps = ({ type }: IGettingStartedStepsProps) => {
  const GettingStartedStepsContainer = styled.div({
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',
    background: PALETTE.brand4,
    padding: 48,
    borderRight: `1px solid ${PALETTE.neutral12}`,
    width: '384px',
    [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
      display: 'none'
    }
  });

  return (
    <div css={{ display: 'flex' }}>
      {type === 'invitation' && (
        <GettingStartedStepsContainer>
          <h3 css={[FONTS.h3, { color: `${PALETTE.brand100}` }]}>Getting Started</h3>
          <span css={[FONTS.p2, contextParaCSS, { paddingBottom: 40 }]}>
            Help residents enroll with Rhino and waive their security deposit requirement.
          </span>
          <div>
            <div css={[FONTS.p2, numberCircleCSS]}>1</div>
            <span css={[FONTS.h5, contextHeadingCSS]}>Send out an invitation</span>
          </div>
          <span css={[FONTS.p2, contextParaCSS, { marginLeft: 38 }]}>
            Submit the invite applicant form. You’ll have up to 14 days after the coverage starts to make updates to the
            Rhino policy.
          </span>
          <hr css={[contextLineCSS]} />
          <div>
            <div css={[FONTS.p2, numberCircleCSS]}>2</div>
            <span css={[FONTS.h5, contextHeadingCSS]}>Applicants apply</span>
          </div>
          <span css={[FONTS.p2, contextParaCSS, { marginLeft: 38 }]}>
            We'll email the applicant with instructions to sign up for Rhino.
          </span>
          <hr css={[contextLineCSS]} />
          <div>
            <div css={[FONTS.p2, numberCircleCSS]}>3</div>
            <span css={[FONTS.h5, contextHeadingCSS]}>You’ll be notified</span>
          </div>
          <span css={[FONTS.p2, contextParaCSS, { marginLeft: 38 }]}>
            Leasing teams will receive confirmation when the enrollment is complete.
          </span>
        </GettingStartedStepsContainer>
      )}
      {type === 'renewal' && (
        <GettingStartedStepsContainer>
          <h3 css={[FONTS.h3, { color: `${PALETTE.brand100}` }]}>Sending policy renewal requests</h3>
          <span css={[FONTS.p2, contextParaCSS, { paddingBottom: 40 }]}>
            Steps to request a renter renew their Rhino policy when they renew their lease.
          </span>
          <div>
            <div css={[FONTS.p2, numberCircleCSS]}>1</div>
            <span css={[FONTS.h5, contextHeadingCSS]}>Select a renter</span>
          </div>
          <span css={[FONTS.p2, contextParaCSS, { marginLeft: 38 }]}>
            You can select any renter with an active policy, who is up-to-date on their policy payments, and is within
            90 to 46 days of policy expiration.
          </span>
          <hr css={[contextLineCSS]} />
          <div>
            <div css={[FONTS.p2, numberCircleCSS]}>2</div>
            <span css={[FONTS.h5, contextHeadingCSS]}>Update policy details</span>
          </div>
          <span css={[FONTS.p2, contextParaCSS, { marginLeft: 38 }]}>
            Update the policy information from the renter's original policy, like the lease dates or coverage amount.
          </span>
          <hr css={[contextLineCSS]} />
          <div>
            <div css={[FONTS.p2, numberCircleCSS]}>3</div>
            <span css={[FONTS.h5, contextHeadingCSS]}>We'll notify the renter</span>
          </div>
          <span css={[FONTS.p2, contextParaCSS, { marginLeft: 38 }]}>
            We'll notify the renter of the policy renewal terms, and they can either manually renew right away or
            auto-renew at the terms you've set.
          </span>
        </GettingStartedStepsContainer>
      )}
    </div>
  );
};

export default GettingStartedSteps;
