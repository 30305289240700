/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PALETTE, FONTS, FileUploader, DeleteIcon, FileIcon } from '@sayrhino/rhino-shared-js';
import axios from 'axios';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const ATTACHMENTS_URL = '/uploads/attachments';

const bannerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px',
  marginTop: 12,
  width: '100%',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: 4,
  backgroundColor: PALETTE.neutralLight
});

const buttonReset = css({
  border: 'none',
  background: 'transparent',
  padding: 0,
  margin: 0
});

const centerIcon = css({
  display: 'flex',
  alignItems: 'center'
});

// Future goal, immediately upload onDrop and have the list resist via react-query cache
// currently managing files list locally in state since react-query isn't available in v1 claims

// formsy will get removed asap for v2. only follwing input is needed
// other registers data to formsy
// <input {...getInputProps()} />
const Dropzone = () => {
  const [filesList, setFilesList] = React.useState<any[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,application/pdf',
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((f, index) => {
        const data = new FormData();
        data.append('file', f);
        axios
          .post(ATTACHMENTS_URL, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((res) => {
            // @ts-ignore
            f.id = res.data.id;
          });
      });
      setFilesList((currentFiles) => [...currentFiles, ...acceptedFiles]);
    }
  });

  const removeAttachment = (e, file) => {
    e.preventDefault();
    axios.delete(`${ATTACHMENTS_URL}/${file.id}`).then((res) => {
      setFilesList((currentFiles) => currentFiles.filter((f) => f.name !== file.name));
    });
  };

  return (
    <React.Fragment>
      <div {...getRootProps({ className: 'dz-clickable attachments' })}>
        <FileUploader>
          <input {...getInputProps({ id: 'dz-input' })} />
        </FileUploader>
      </div>
      <div>
        {filesList.length > 0 && (
          <h3 css={[FONTS.h6Demi, { color: PALETTE.neutral65, marginTop: 24, marginBottom: 12 }]}>Attachments</h3>
        )}
        {filesList.map((file) => {
          return (
            <div key={file.name} css={bannerStyles}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <FileIcon style={{ marginRight: 12, color: PALETTE.interaction100 }} />
                {file.name}
              </div>
              <button css={[buttonReset, centerIcon]} onClick={(e) => removeAttachment(e, file)}>
                <DeleteIcon
                  css={{
                    ':hover': {
                      color: PALETTE.alert100
                    }
                  }}
                />
              </button>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Dropzone;
