/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useMatch, useLocation } from 'react-router-dom';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import { ACCOUNT_ROUTES, PROPERTY_OWNER_ROUTES } from './utils/routeHelpers';

type Props = JSX.IntrinsicElements['div'];

const routeMatchList = [
  '/admin/renter_policies/:policyId/*',
  '/admin/claims/:claimsId/*',
  '/admin/invitations/:invitationId/*',
  '/admin/claims/batch_assign',
  ACCOUNT_ROUTES.twoFactorSetup,
  ACCOUNT_ROUTES.twoFactorCodes,
  `${ACCOUNT_ROUTES.propertyOwnerView}/*`,
  `${ACCOUNT_ROUTES.legalPropertyEntitiesView}/*`,
  `${PROPERTY_OWNER_ROUTES.details}/*`
];

const Drawer = (props: Props) => {
  const routeMatches = routeMatchList.some((route) => useMatch(route));
  const { pathname } = useLocation();

  return (
    <div style={{ width: '100%' }}>
      {routeMatches && (
        <div
          id="drawer-modal"
          css={{
            position: 'absolute',
            left: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            minHeight: '100vh',
            overflow: 'auto',
            zIndex: 200,
            background: PALETTE.neutralLight
          }}
          key={pathname}
        >
          <div style={props.style}>{props.children}</div>
        </div>
      )}
    </div>
  );
};

export default Drawer;
