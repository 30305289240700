import React from 'react';
import styled from '@emotion/styled';
import { Card as CardBase, FONTS, LegacyTag as TagBase, PALETTE } from '@sayrhino/rhino-shared-js';

export interface IPropEntity {
  total_units: number;
  legal_property_entity: Set<string>;
  property_owner: string | null;
  address: string | null;
  property_name: string | null;
}

interface IProps {
  list: IPropEntity[];
}

const Card = styled(CardBase)({
  height: 'auto',
  width: '576px',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '24px',
  padding: '28px',
  cursor: 'auto',
  margin: '16px auto',
  '&:hover': {
    boxShadow: 'none'
  }
});

const List = styled.div({ marginBottom: '100px' });

const Tag = styled(TagBase)({
  paddingRight: '12px',
  color: PALETTE.neutralDark,
  margin: '4px',
  backgroundColor: PALETTE.neutral4,
  border: 'none'
});

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const BuildingName = styled.p([FONTS.h4, { textTransform: 'capitalize' }]);
const Unit = styled.p(FONTS.p1);
const Address = styled.p([FONTS.p2, { color: PALETTE.neutral65, textTransform: 'capitalize' }]);
const SmallText = styled.p([FONTS.label, { color: PALETTE.neutral65 }]);
const SBS = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  margin: '4px 0'
});

export const getNameFromAddr = (addr: string | null) => addr?.split(',')[0] || '';

export default function PropertyList({ list }: IProps) {
  const renderLPE = (legal_property_entity: Set<string>) => {
    return Array.from(legal_property_entity.values()).map((lpe: string, index: number) => (
      <Tag key={index} variant="state">
        {lpe}
      </Tag>
    ));
  };

  const EntityCard = ({ property_name, address, total_units, property_owner, legal_property_entity }: IPropEntity) => (
    <Card>
      <Container>
        <BuildingName>{property_name ? property_name : getNameFromAddr(address)}</BuildingName>
        <Unit>{`${total_units} ${total_units > 1 ? 'units' : 'unit'}`}</Unit>
      </Container>
      <Address>{address}</Address>
      <SBS>
        <SmallText>Property owner</SmallText>
        <Tag variant="state">{property_owner}</Tag>
      </SBS>
      <SBS>
        <SmallText>Legal property entity</SmallText>
        {renderLPE(legal_property_entity)}
      </SBS>
    </Card>
  );

  const renderProperties = () => {
    return list?.map((prop: IPropEntity, index: number) => <EntityCard key={index} {...prop} />);
  };

  return <List>{renderProperties()}</List>;
}
