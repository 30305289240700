import React from 'react';
import { render, screen, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import App from '../index';
import { PolicyDetailsEditView } from '../PolicyDetailsEdit';
import PolicyDetails from '../PolicyDetails';
import PolicyDetailsEdit from '../PolicyDetailsEdit';
import { Routes, Route } from 'react-router-dom';
import Wrapper from '../testUtils/Wrapper';
import makeServer from '../testUtils/server';
import { waitForAnimationFrame } from '../testUtils';

let server;

describe('<App /> level policy edit interactions', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the dashboard', async () => {
    render(<App />);
    const heading = await screen.findByRole('heading', { level: 6, name: /Action items/i });
    expect(heading).toBeDefined();
  });

  // skipping because this flow has an undetermined issue that causes the an event element to be null
  test.skip('It expands policy details and opens the Edit section though the Edit CTA', async () => {
    render(<App />);
    const leftClick = { button: 0 };

    const policyCard = await screen.getByRole('link', { name: /Jacob DeGrom/i });
    expect(policyCard).toBeDefined();

    await act(async () => {
      userEvent.click(policyCard, leftClick);

      // forces drawer animation to finish
      await waitForAnimationFrame();
    });

    // header in details
    const policyDetails = await screen.getByRole('heading', { level: 4, name: /Policy Coverage/ });
    const showEditButton = await screen.getByRole('button', { name: /display edit options/i });

    await act(async () => {
      userEvent.click(showEditButton, leftClick);

      // forces edit CTA section fade in
      await waitForAnimationFrame();
    });

    const editLink = await screen.getByRole('link', { name: /collapse/i });

    await act(async () => {
      userEvent.click(editLink, leftClick);

      // wait for edit section to transition in
      await waitForAnimationFrame();
    });

    const editSection = screen.findByRole('heading', { level: 4, name: /Edit policy/i });

    expect(editSection).toBeDefined();
  });
});

describe('<PolicyDetailsEdit', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test.skip('It renders <PolicyDetailsEdit>', async () => {
    render(
      <Wrapper initialEntries={['/admin/renter_policies/25921/edit']}>
        <PolicyDetailsEditView />
      </Wrapper>
    );
    const heading = await screen.findByRole('heading', { level: 4, name: /Edit policy/i });
    expect(heading).toBeDefined();
  });

  // skipped until we remove the depreacted property address typeahead
  test.skip('It should have an editable lease address', async () => {
    render(
      <Wrapper initialEntries={['/admin/renter_policies/25921/edit']}>
        <PolicyDetailsEditView />
      </Wrapper>
    );

    // update address
    const leaseAddressField = await screen.getByLabelText(/Lease address/i);
    await userEvent.type(leaseAddressField, '');
    userEvent.type(leaseAddressField, '100 South French St, Wilmington, DE 19801');
    const leaseAddressInput = await screen.getByRole('textbox', { name: /address/i });
    expect(leaseAddressInput).toHaveValue('100 South French St, Wilmington, DE 19801');
  });

  // skipped until we remove the depreacted property address typeahead
  test.skip('It should have an editable unit', async () => {
    render(
      <Wrapper initialEntries={['/admin/renter_policies/25921/edit']}>
        <PolicyDetailsEditView />
      </Wrapper>
    );

    // update unit
    const unitField = await screen.getByLabelText(/Lease unit/i);
    await userEvent.type(unitField, '');
    userEvent.type(unitField, 'Tr 1');
    const unitInput = screen.getByRole('textbox', { name: /unit/i });
    expect(unitInput).toHaveValue('Tr 1');
  });

  test.skip('It should allow for rent to be updated', async () => {
    render(
      <Wrapper initialEntries={['/admin/renter_policies/25921/edit']}>
        <PolicyDetailsEditView />
      </Wrapper>
    );

    // monthly rent currency format, cents to stringified dollars
    const rentField = await screen.getByLabelText(/Monthly rent/i);
    userEvent.type(rentField, '150000');
    const rentInput = await screen.getByRole('textbox', { name: /monthly rent/i });
    expect(rentInput).toHaveValue('$1,500.00');
  });

  test('It should parse non digit chars out of the rent input', async () => {
    render(
      <Wrapper initialEntries={['/admin/renter_policies/25921/edit']}>
        <PolicyDetailsEditView />
      </Wrapper>
    );

    // monthly rent currency format, cents to stringified dollars
    const rentField = await screen.getByLabelText(/Monthly rent/i);
    userEvent.type(rentField, '25s000f0');
    const rentInput = await screen.getByRole('textbox', { name: /monthly rent/i });
    expect(rentInput).toHaveValue('$2,500.00');
  });
});

// Unfortunately these need to be skipped until we remove the legacy property typeahead or we
// commit to properly handling error case responses. err.json() results in a type error that fails
// this describe block
describe.skip('Change request submissions', () => {
  beforeEach(() => {
    server = makeServer();
    server.logging = true;
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders <PolicyDetailsEdit>', async () => {
    render(
      <Wrapper initialEntries={['/admin/renter_policies/25921/edit']}>
        <Routes>
          <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          <Route path="/admin/renter_policies/:policyId/edit" element={<PolicyDetailsEdit />} />
        </Routes>
      </Wrapper>
    );

    const heading = await screen.findByRole('heading', { level: 4, name: /Edit policy/i });
    expect(heading).toBeDefined();

    // change to multiplier
    const multiplierRadio = await screen.getByLabelText(/MULTIPLIER/i);
    await act(async () => {
      userEvent.click(multiplierRadio);
    });

    // submit form
    const submitButton = await screen.getByRole('button');
    await act(async () => {
      userEvent.click(submitButton);
    });

    // wait for transition on submit
    await waitForAnimationFrame();

    // see if back on details page
    const detailsHeader = screen.getByRole('heading', { level: 4, name: /Policy Coverage/i });
    expect(detailsHeader).not.toBeNull();
  });
});
