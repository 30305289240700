import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IClaimStaticFilterOptions } from '../../components/v2/App/interfaces';

export const getClaimStaticFilterOptions = async (): Promise<IClaimStaticFilterOptions> => {
  const { data }: AxiosResponse<IClaimStaticFilterOptions> = await axios.get<IClaimStaticFilterOptions>(
    `/v2/admin/claims/static_filter_options`
  );

  return data;
};

export const claimStaticFilterOptionsKey = 'claimStaticFilterOptions';

export default function useClaimStaticFilterOptions(): QueryObserverResult<IClaimStaticFilterOptions> {
  return useQuery<IClaimStaticFilterOptions>([claimStaticFilterOptionsKey], () => getClaimStaticFilterOptions());
}
