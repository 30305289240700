import { ChangeEvent, useEffect, useState } from 'react';

import { CoverageTypeEnum, ICoverageRule } from '../interfaces';
import { centsToUSDFormatter, sanitizeCurrencyInput } from '../utils';
import Decimal from 'decimal.js';

export const MAX_COVERAGE_AMOUNT = 20000000;
export const MAX_RULESET_NAME_CHAR_COUNT = 120;

const useCoverageRuleForm = (coverageRule: ICoverageRule | undefined) => {
  const [hasCoverageValueChanged, setHasCoverageValueChanged] = useState<boolean>(false);

  const GetCoverageTypeEnumFromCoverageType = (coverageType: string | undefined, allowNoCoverage = true) => {
    if (coverageType === 'amount_cents') {
      return CoverageTypeEnum.DOLLAR_AMOUNT;
    }
    if (coverageType === 'multiplier') {
      return CoverageTypeEnum.MULTIPLIER;
    }
    if (coverageType === 'no_coverage' && allowNoCoverage) {
      return CoverageTypeEnum.NO_COVERAGE;
    }
    if (coverageType === 'realpage_deposit_charges') {
      return CoverageTypeEnum.RP_DEPOSIT_CHARGES;
    }
    return CoverageTypeEnum.MULTIPLIER;
  };

  const getCashCoverageValue = (coverageRule: ICoverageRule | undefined) => {
    if (coverageRule?.cash_deposit_type === 'no_coverage') {
      // we'll be swapping this to be a multiplier (just for UI)
      // it won't actually change the value, since the only way for this to be no_coverage
      // would be if same_cash_deposit is true, in which case we override whatever value
      // is in cashCoverageValue
      return 1;
    } else {
      return coverageRule?.cash_deposit_coverage_value ?? 1;
    }
  };

  const [coverageType, setCoverageType] = useState<CoverageTypeEnum>(
    GetCoverageTypeEnumFromCoverageType(coverageRule?.coverage_type)
  );
  const [coverageCashType, setCoverageCashType] = useState<CoverageTypeEnum>(
    GetCoverageTypeEnumFromCoverageType(coverageRule?.cash_deposit_type, false)
  );
  const [coverageSameCashDeposit, setCoverageSameCashDeposit] = useState<boolean>(
    coverageRule?.same_cash_deposit === false ? false : true
  );
  const [coverageValue, setCoverageValue] = useState<number>(coverageRule?.coverage_value ?? 1);
  const [coverageCashValue, setCoverageCashValue] = useState<number>(getCashCoverageValue(coverageRule));
  const [coverageError, setCoverageError] = useState<boolean>(false);
  const [coverageCashError, setCoverageCashError] = useState<boolean>(false);
  const [coverageDollarError, setCoverageDollarError] = useState<string>('');
  const [coverageCashDollarError, setCoverageCashDollarError] = useState<string>('');

  useEffect(() => {
    if (
      coverageValue != coverageRule?.coverage_value ||
      coverageCashValue != coverageRule?.cash_deposit_coverage_value ||
      coverageSameCashDeposit != coverageRule?.same_cash_deposit
    ) {
      setHasCoverageValueChanged(true);
    } else {
      setHasCoverageValueChanged(false);
    }
  }, [coverageValue, coverageCashValue, coverageSameCashDeposit]);

  const convertCoverageToCents = (e: ChangeEvent<HTMLInputElement>) => {
    setCoverageDollarError(''); // clear errors
    let coverageAmountCents = sanitizeCurrencyInput(e);
    setCoverageValue(coverageAmountCents);
  };

  // this is being added specifically for old iss view realpage support
  // can be removed once realpage moves to new_iss components
  const convertCoverageCashToCents = (e: ChangeEvent<HTMLInputElement>) => {
    setCoverageCashDollarError(''); // clear errors
    let coverageCashAmountCents = sanitizeCurrencyInput(e);
    setCoverageCashValue(coverageCashAmountCents);
  };

  const setCoverageValueDefaults = (value: CoverageTypeEnum) => {
    // handles default value being either 1/0 when toggling coverage selection
    setCoverageValue(value === CoverageTypeEnum.DOLLAR_AMOUNT ? 0 : 1);
    setCoverageError(false);
  };

  // this is being added specifically for old iss view realpage support
  // can be removed once realpage moves to new_iss components
  const setCoverageCashValueDefaults = (value: CoverageTypeEnum) => {
    // handles default value being either 1/0 when toggling coverage selection
    setCoverageCashValue(value === CoverageTypeEnum.DOLLAR_AMOUNT ? 0 : 1);
    setCoverageCashError(false);
  };

  const handleSwitchSelect = (type: CoverageTypeEnum) => {
    // set coverage type in state
    setCoverageType(type);
    // handle toggling default values in state
    setCoverageValueDefaults(type);
    // clear errors
    setCoverageDollarError('');
    // make sure if coverage type is no coverage, cash type will be too
    if (type === CoverageTypeEnum.NO_COVERAGE) {
      setCoverageSameCashDeposit(true);
    }
  };

  // this is being added specifically for old iss view realpage support
  // can be removed once realpage moves to new_iss components
  const handleCashSwitchSelect = (type: CoverageTypeEnum) => {
    setCoverageCashType(type);
    setCoverageCashValueDefaults(type);
    setCoverageCashDollarError('');
  };

  const isNoCoverage = (type) => {
    return type === CoverageTypeEnum.NO_COVERAGE;
  };

  const isDeposit = (type) => {
    return type === CoverageTypeEnum.RP_DEPOSIT_CHARGES;
  };

  const defaultCoverageValue = (type: CoverageTypeEnum, value: number) => {
    if (isNoCoverage(type) || isDeposit(type)) {
      return 0;
    }
    return value;
  };

  const getPartnerCoverageRule = (coverageOptionName: string, conditionType: string | undefined = undefined) => {
    return {
      coverage_option_name: coverageOptionName,
      coverage_type: coverageType,
      coverage_value: defaultCoverageValue(coverageType, coverageValue),
      same_cash_deposit: coverageSameCashDeposit,
      cash_deposit_type: coverageCashType,
      cash_deposit_coverage_value: defaultCoverageValue(coverageCashType, coverageCashValue),
      id: coverageRule?.id,
      cash_deposit_rule_id: coverageRule?.cash_deposit_rule_id,
      condition_type: conditionType
    };
  };

  const isPresent = () => {
    return coverageType && coverageValue;
  };

  const isValid = () => {
    return (
      coverageType &&
      coverageValue &&
      coverageCashValue <= MAX_COVERAGE_AMOUNT &&
      coverageCashValue <= MAX_COVERAGE_AMOUNT
    );
  };

  const raiseCoverageErrors = () => {
    if (coverageType === CoverageTypeEnum.DOLLAR_AMOUNT && coverageValue === 0) {
      setCoverageDollarError('You must enter a dollar amount greater than $0.00');
    }
    if (coverageType === CoverageTypeEnum.DOLLAR_AMOUNT && !coverageSameCashDeposit && coverageCashValue === 0) {
      setCoverageCashDollarError('You must enter a dollar amount greater than $0.00');
    }
    if (coverageValue > MAX_COVERAGE_AMOUNT) {
      setCoverageDollarError(
        `You must enter a dollar amount less than ${centsToUSDFormatter(new Decimal(MAX_COVERAGE_AMOUNT))}`
      );
    }
    if (coverageCashValue > MAX_COVERAGE_AMOUNT) {
      setCoverageCashDollarError(
        `You must enter a dollar amount less than ${centsToUSDFormatter(new Decimal(MAX_COVERAGE_AMOUNT))}`
      );
    }

    setCoverageError(!coverageType);
    setCoverageCashError(!coverageCashType);
  };

  return {
    coverageType,
    coverageCashType,
    coverageValue,
    coverageCashValue,
    coverageError,
    coverageCashError,
    coverageDollarError,
    coverageCashDollarError,
    coverageSameCashDeposit,
    setCoverageCashType,
    setCoverageValue,
    setCoverageCashValue,
    setCoverageSameCashDeposit,
    convertCoverageToCents,
    convertCoverageCashToCents,
    handleSwitchSelect,
    handleCashSwitchSelect,
    hasCoverageValueChanged,
    isPresent,
    getPartnerCoverageRule,
    isValid,
    raiseCoverageErrors
  };
};

export type UseCoverageRuleFormReturn = ReturnType<typeof useCoverageRuleForm>;
export default useCoverageRuleForm;
