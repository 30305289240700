import React from 'react';
import styled from '@emotion/styled';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import { Outlet, Route, Routes } from 'react-router-dom';
import Backdrop from '../drawer/backdrop';
import Drawer from './Drawer';

import useOverlay from './utils/overlay';
import { ACCOUNT_ROUTES } from './utils/routeHelpers';
import TwoFactorSetup from './Account/TwoFactorSetup';
import PropertyOwnerDetails from './PropertyOwners/PropertyOwnerDetails';
import PropertyOwnerEdit from './Account/PropertyOwnerEdit';
import PropertyEntityDetails from './Account/PropertyEntityDetails';
import PropertyEntityEdit from './Account/PropertyEntityEdit';
import TwoFactorCodes from './Account/TwoFactorCodes';
import PropertyOwnerForm from './PropertyOwners/PropertyOwnerForm';
import { BREAKPOINTS, NAV_WIDTH } from './utils/constants';
import { mediaQueryWidth } from './utils';

const Wrapper = styled.div({
  display: 'flex',
  height: '100vh',
  maxWidth: 1152,
  minWidth: 1056,
  marginLeft: NAV_WIDTH.expanded,
  background: '#F8F8FF',
  [mediaQueryWidth({ value: BREAKPOINTS.xl })]: {
    width: `calc(100% - ${NAV_WIDTH.collapsed})`,
    marginLeft: NAV_WIDTH.collapsed
  }
});
const BasePanel = styled.div({
  width: '100%',
  overflow: 'auto'
});
const RightPanel = styled.div({
  flex: '0 0 50%',
  height: '100vh',
  overflowY: 'scroll',
  borderRight: `1px solid ${PALETTE.neutral12}`
});

// Panel Routes are defined in TabLayout to life them as high as possible while
// maintaining access to an independent <Outlet />. This let's us use routes to
// control when the panel is displayed, giving us support for deep linking to
// expanded states
const TabLayout: React.FC = () => {
  const { showOverlay } = useOverlay();

  return (
    <>
      <Wrapper>
        <BasePanel>
          <Outlet />
        </BasePanel>
        <Drawer style={{ overflowY: 'auto', width: '100%' }}>
          <RightPanel>
            <Routes>
              <Route path={ACCOUNT_ROUTES.twoFactorSetup} element={<TwoFactorSetup />} />
              <Route path={ACCOUNT_ROUTES.twoFactorCodes} element={<TwoFactorCodes />} />
              <Route path={ACCOUNT_ROUTES.propertyOwners}>
                <Route path=":propertyOwnerId" element={<PropertyOwnerDetails />} />
                <Route path=":propertyOwnerId/edit" element={<PropertyOwnerEdit />} />
                <Route path=":propertyOwnerId/pma_edit" element={<PropertyOwnerForm />} />
              </Route>
              <Route path={ACCOUNT_ROUTES.legalPropertyEntitiesView} element={<PropertyEntityDetails />} />
              <Route path={ACCOUNT_ROUTES.legalPropertyEntitiesEdit} element={<PropertyEntityEdit />} />
            </Routes>
          </RightPanel>
        </Drawer>
      </Wrapper>
      {showOverlay && <Backdrop />}
    </>
  );
};

export default TabLayout;
