import React from 'react';
import { render, screen, waitFor, within } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import FileClaim from '../FileClaim';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';

const leftClick = { button: 0 };

describe('<FileClaim />', () => {
  const renderFileClaim = () =>
    render(
      <Wrapper>
        <FileClaim />
      </Wrapper>
    );
  let server;

  beforeEach(() => (server = makeServer()));
  afterEach(() => server.shutdown());
  test('renders file claim headers', async () => {
    const { findByText, findByRole } = renderFileClaim();
    const topHeading = await findByText('File a Claim');
    expect(topHeading).toBeDefined();
    const formHeading = await findByRole('heading', { level: 3, name: /Claim submission form/i });
    expect(formHeading).toBeDefined();
  });

  test('Tenant Information Section', async () => {
    const { findByText, findByRole, findByLabelText, getByLabelText } = renderFileClaim();
    const sectionHeading = await findByRole('heading', { level: 5, name: /Getting started/i });
    expect(sectionHeading).toBeDefined();
    let stringEl;
    stringEl = await findByText('Property name or address');
    expect(stringEl).toBeDefined();
    stringEl = await findByText('Policy associated with your claim');
    expect(stringEl).toBeDefined();
    const propertyDropdown = await findByText('Select property...');
    expect(propertyDropdown).toBeDefined();
    const policyDropdown = await findByText('Select policy...');
    expect(policyDropdown).toBeDefined();
    const tenantResidesCheckbox = await findByLabelText('Renter still resides in unit');
    userEvent.click(tenantResidesCheckbox);
    stringEl = await findByText(
      `Please be advised that since the tenant is currently living in the unit, claims payments may have an impact on eviction proceedings. You can still file the claim, but if you're planning to evict, we recommend contacting an attorney for further information.`
    );
    expect(stringEl).toBeDefined();
    stringEl = await findByLabelText('Renter was notified of claim');
    expect(stringEl).toBeDefined();
  });

  test('Claim Detail Section', async () => {
    const { findByText, findByRole, getByLabelText, findByPlaceholderText } = renderFileClaim();
    const topHeading = await findByRole('heading', { level: 5, name: /Claim details/i });
    expect(topHeading).toBeDefined();
    const dropDownLabelEl = await findByRole('heading', { level: 6, name: /What kind of claim are you filing?/i });
    expect(dropDownLabelEl).toBeDefined();
    const dropDownHeaderEl = await findByText('-');
    expect(dropDownHeaderEl).toBeDefined();
    userEvent.click(dropDownHeaderEl);
    await waitFor(() => screen.getByText('Loss of Rent'));
    await waitFor(() => screen.getByText('Damages'));
    const menuEl1 = await findByText('Loss of Rent');
    expect(menuEl1).toBeDefined();
    const menuEl2 = await findByText('Damages');
    expect(menuEl2).toBeDefined();
    userEvent.click(menuEl1);
    const needLabelsForRent = [
      'Loss of Rent - 1',
      'When did rent arrears begin?',
      'During the lease term',
      'After renter move-out',
      'Date of loss',
      'Arrear amount',
      'Late fees (Optional)',
      'Other fees (Optional)',
      '+ Add another month',
      'Loss of Rent Total'
    ];
    needLabelsForRent.forEach(async (str) => {
      const el = await findByText(str);
      expect(el).toBeDefined();
    });
    const listHeaderEl = await findByText('Loss of Rent');
    expect(listHeaderEl).toBeDefined();
    userEvent.click(listHeaderEl);
    await waitFor(() => screen.getByText('Damages'));
    const menuItemEl = await findByText('Damages');
    expect(menuItemEl).toBeDefined();
    userEvent.click(menuItemEl);
    let stringEl;
    stringEl = await getByLabelText('damages amount');
    expect(stringEl).toBeDefined();
    const needLabelsForDamage = [
      'date of loss',
      'Please enter the full amount owed in damages',
      'Where did the damage occur?',
      'select an area...'
    ];
    needLabelsForDamage.forEach(async (str) => {
      const el = await findByText(str);
      expect(el).toBeDefined();
    });
    const notes = await findByPlaceholderText('Additional notes for this claim');
    expect(notes).toBeDefined();
    userEvent.type(notes, 'new note about claim');
    expect(notes).toHaveValue('new note about claim');
  });

  test('Claim Documentation Section', async () => {
    const { findByText, findByRole } = renderFileClaim();
    const sectionHeading = await findByRole('heading', { level: 5, name: /Claim documentation/i });
    expect(sectionHeading).toBeDefined();
    const paragraph =
      'Upload all relevant documentation or photo evidence to support your claim. If the unit becomes vacant, you are required to submit screenshots or links to rental listings showing that you’re actively trying to find a renter for the unit.';
    const paragraphEl = await findByText(paragraph);
    expect(paragraphEl).toBeDefined();
    const dropZoneText = 'browse';
    const dropZoneTextEl = await findByText(dropZoneText);
    expect(dropZoneTextEl).toBeDefined();
  });

  test('Payment Info Section', async () => {
    const { findByText, findByRole, getByRole } = renderFileClaim();
    const sectionHeading = await findByRole('heading', { level: 5, name: /Select payout method/i });
    expect(sectionHeading).toBeDefined();
    const checkEl1 = getByRole('radio', { name: 'Direct deposit' });
    expect(checkEl1).toBeDefined();
    const checkEl2 = getByRole('radio', { name: 'Physical check' });
    expect(checkEl2).toBeDefined();
    userEvent.click(checkEl1, leftClick);
    await waitFor(() => screen.getByText('Routing number associated with bank account'));
    const needLabelForOption1 = [
      'Please enter your bank account information. You’ll receive a wire transfer once your claim has been approved (typically within 48 hours).',
      'Routing number associated with bank account',
      'Account number associated with bank account'
    ];
    needLabelForOption1.forEach(async (str) => {
      const el = await findByText(str);
      expect(el).toBeDefined();
    });
    const paymentOption2 = await findByText('Physical check');
    userEvent.click(paymentOption2);
    await waitFor(() => screen.getByText('City'));
    const needLabelForOption2 = [
      'Please provide the address where you’d like the check to be mailed.',
      'Street address',
      'Suite / Floor / Unit (Optional)',
      'City',
      'State',
      'Zip code'
    ];
    needLabelForOption2.forEach(async (str) => {
      const el = await findByText(str);
      expect(el).toBeDefined();
    });
  });

  // Create follow up tickets for this test case
  test('Hide Payment Info when selected property has linked bank account', async () => {
    const { findByLabelText, findByText, findByRole, getByRole, queryByRole } = renderFileClaim();
    const sectionHeading = await findByRole('heading', { level: 5, name: /Select payout method/i });
    expect(sectionHeading).toBeDefined();
    const checkEl1 = getByRole('radio', { name: 'Direct deposit' });
    expect(checkEl1).toBeDefined();
    const checkEl2 = getByRole('radio', { name: 'Physical check' });
    expect(checkEl2).toBeDefined();

    const propertyDropdown = await findByLabelText('property-search');
    userEvent.type(propertyDropdown, 'Buena');
    const selectProperty = await findByText('Vista, 1607 Parkmoor Avenue, 95128');
    expect(selectProperty).toBeDefined();
    userEvent.click(selectProperty);
    await waitFor(() => {
      expect(sectionHeading).not.toBeInTheDocument();
      expect(checkEl1).not.toBeInTheDocument();
      expect(checkEl2).not.toBeInTheDocument();
    });
  });

  test('Form Validation', async () => {
    const { findByText, findByRole, queryByText } = renderFileClaim();
    const submitButton = await findByRole('button', { name: 'Submit claim' });
    userEvent.click(submitButton);
    const claimError = await findByText('Please indicate claim type');
    expect(claimError).toBeDefined();
    const claimDocError = await findByText('Please attach a photo or document');
    expect(claimDocError).toBeDefined();
    const topHeading = await findByRole('heading', { level: 5, name: /Claim details/i });
    expect(topHeading).toBeDefined();
    const dropDownHeaderEl = await findByText('-');
    expect(dropDownHeaderEl).toBeDefined();
    userEvent.click(dropDownHeaderEl);
    await waitFor(() => screen.getByText('Loss of Rent'));
    await waitFor(() => screen.getByText('Damages'));
    const menuEl1 = await findByText('Loss of Rent');
    expect(menuEl1).toBeDefined();
    userEvent.click(menuEl1);
    userEvent.click(submitButton);
    await waitFor(() => {
      expect(queryByText('Please indicate claim type')).not.toBeInTheDocument();
    });
  });

  test('Loss of rent validation', async () => {
    const { findByText, findByRole } = renderFileClaim();
    const dropDownLabelEl = await findByRole('heading', { level: 6, name: /What kind of claim are you filing?/i });
    expect(dropDownLabelEl).toBeDefined();
    const dropDownHeaderEl = await findByText('-');
    expect(dropDownHeaderEl).toBeDefined();
    userEvent.click(dropDownHeaderEl);
    await waitFor(() => screen.getByText('Loss of Rent'));
    await waitFor(() => screen.getByText('Damages'));
    const menuEl1 = await findByText('Loss of Rent');
    expect(menuEl1).toBeDefined();
    const menuEl2 = await findByText('Damages');
    expect(menuEl2).toBeDefined();
    userEvent.click(menuEl1);
    const submitButton = await findByRole('button', { name: 'Submit claim' });
    userEvent.click(submitButton);
    const lossOfRentErrors = [
      'Please indicate a subcategory',
      'Cannot be after the end of the lease',
      'Please indicate claim amount greater than 0'
    ];
    lossOfRentErrors.map(async (err) => {
      const lossOfRentError = await findByText(err);
      expect(lossOfRentError).toBeDefined();
    });
  });
});
