import axios from 'axios';
import { QueryObserverResult, useQuery, useQueryClient } from 'react-query';
import { withdrawalsQueryKey } from './useWithdrawals';
import { userAccountBalanceQueryKey } from './useUserAccountBalance';
import { fundsBankAccountsQueryKey } from './useFundsBankAccounts';

interface IDepositFundsWrapperResponse {
  propertyOwnerId: string;
  propertyOwnerList: {
    id: string;
    name: string;
  }[];
}

export const depositFundsListWrapperQueryKey = 'deposit_funds_list_wrapper';

const getDepositFundsWrapper = async () => {
  const { data } = await axios.get<IDepositFundsWrapperResponse>('/admin/deposit_funds_initial_data');
  return data;
};

export default function useDepositFundsListWrapper(): QueryObserverResult<IDepositFundsWrapperResponse> & { invalidate: () => void } {
  const queryClient = useQueryClient();
  const query = useQuery<IDepositFundsWrapperResponse>([depositFundsListWrapperQueryKey], getDepositFundsWrapper);
  const invalidate = () => {
    queryClient.invalidateQueries(depositFundsListWrapperQueryKey);
    queryClient.invalidateQueries(withdrawalsQueryKey);
    queryClient.invalidateQueries(userAccountBalanceQueryKey);
    queryClient.invalidateQueries(fundsBankAccountsQueryKey);
  };

  return {
    ...query,
    invalidate
  };
}
