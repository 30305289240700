import React, { useEffect, useState } from 'react';
import { ISiteListItem, useSites, usePartnerNames, ISite } from 'api/v2/useSites';
import {
  ActionButton,
  Loading,
  ROUNDNESS,
  PALETTE,
  Button,
  Switch,
  FONTS,
  SquareButton
} from '@sayrhino/rhino-shared-js';
import Pagination from '../../Filters/Pagination';
import { AxiosError } from 'axios';
import AsyncSelect from 'components/v2/select/AsyncSelect';

import {
  PageWrapper,
  PageTitleWrapper,
  PageTitle,
  LoadingWrapper,
  StyledAddNewRowLink,
  StyledCell,
  StyledCardRow,
  StyledColumnHeader,
  StyledHeaderRow,
  StyledRow,
  StyledTableContainer,
  StyledTableFooter,
  StyledTableFrame,
  StyledEditCell
} from './SitesStyles';
import SitesCard, { Status } from './SitesCard';
import PropertySitesCard from './PropertySitesCard';

import Modal from 'react-modal';
import styled from '@emotion/styled';
import { IPaginatedMeta } from '../../interfaces';

export interface IPartnersNameOptions {
  name: string;
  id: number;
  label: string;
  value: number;
}

interface IPageMeta {
  next_page: number;
  prev_page: number;
  total_count: number;
}

// export interface IPropertyOwners {
//   label: string;
//   value: number;
// }

let partnerNamesOptions: IPartnersNameOptions[] = [];
// let propertyOwnersOptions: IPropertyOwners[] = [];
let shouldDisplayPagination = false;

const SitesTable = () => {
  const [page, setPage] = useState(1);
  const [partnerName, setPartnerName] = useState<string>();
  // const [editId, setEditId] = useState<number>();
  const [site, setSite] = useState<ISite | undefined>();
  const [showCard, setShowCard] = useState<boolean>();
  const { isSuccess, isLoading, data: sitesData } = useSites({ page: page, partner_name: partnerName }) ?? {};
  const [sitesList, setSitesList] = useState<ISiteListItem[] | undefined>([]);
  const per = sitesData?.meta?.per ?? 20;
  const [pageMeta, setPageMeta] = useState<IPaginatedMeta>();
  // const { propertyOwners } = usePropertyOwners();
  const { partnerNames } = usePartnerNames();
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (sitesData && sitesData.data.length > 0) {
      const { data, meta } = sitesData;
      setSitesList(data);
      setPageMeta(meta);
      shouldDisplayPagination = isSuccess && (meta?.total_count ?? 0) > 20;
    }
  }, [sitesData]);

  useEffect(() => {
    const query = {
      limit: 100
    };

    partnerNames(query, {
      onSuccess: (res) => {
        partnerNamesOptions = res.data.map((partner) => {
          return { label: partner.integration_partnerable.name, value: partner.integration_partnerable_id };
        });
      },
      onError: (err: AxiosError) => {
        const { errors } = err?.response?.data;
        setErrors(errors);
      }
    });
  }, []);

  const Link = styled.a`
    color: #6318ce;
  `;

  const filterByPartnerName = async (selectSite) => {
    setSelected(selectSite);
    setPartnerName(selectSite?.label);
    setPage(1);
  };

  const handleEdit = (selectedSite) => {
    setSite(selectedSite);
    setShowCard(true);
  };

  const loadOptions = (input: string, callback: Function) => {
    callback(
      partnerNamesOptions.filter(({ label }) => label.toLocaleLowerCase().includes(input.toLowerCase())),
      input
    );
  };

  // We will use property owner names in future
  // useEffect(() => {
  //   const query = {
  //     limit: 10000
  //   };

  //   propertyOwners((query), {
  //     onSuccess: (res) => {
  //       propertyOwnersOptions = res;
  //     },
  //     onError: (err: AxiosError) => {
  //       const { errors } = err?.response?.data;
  //       setErrors(errors);
  //     }
  //   });
  // }, [sitesData])

  const StyledModal = styled(Modal)({
    width: '480px',
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    background: PALETTE.neutralLight,
    borderRadius: '8px',
    outline: 0,
    '@media(max-width:768px)': {
      padding: '88px 20px 64px 20px',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      borderRadius: '0',
      transform: 'none'
    }
  });

  const Wrapper = styled.div([
    ROUNDNESS.s,
    {
      border: `1px solid ${PALETTE.neutral25}`,
      padding: '0 4px',
      margin: '4px 0 10px 0',
      width: '20%'
    }
  ]);

  const Label = styled.label([
    FONTS.labelMedium,
    {
      fontSize: '12px',
      lineHeight: '20px',
      color: PALETTE.neutral65
    }
  ]);

  const showSitePropertiesModal = (selectedSite) => {
    setSite(selectedSite);
    setShowModal(true);
  };

  return (
    <PageWrapper>
      <PageTitleWrapper>
        <PageTitle>Sites</PageTitle>
      </PageTitleWrapper>

      <div style={{ paddingLeft: '48px' }}>
        <Label htmlFor="filter_by_partner">Filter by Partner</Label>
        <Wrapper id="filter_by_partner">
          <AsyncSelect
            name="filter_partner_name"
            id="filter_partner_name"
            getOptionLabel={(options) => options.label}
            getOptionValue={(options) => options.value}
            value={selected}
            isClearable={true}
            isLoading={isLoading}
            placeholder="Partner name"
            loadOptions={loadOptions}
            onChange={filterByPartnerName}
          />
        </Wrapper>

        <div
          style={{
            float: 'right',
            marginRight: '30px'
          }}
        >
          <Button onClick={() => setShowCard(true)}>Add Site</Button>
        </div>
      </div>
      <StyledModal isOpen={showCard}>
        <SitesCard
          status={Status.Add}
          flipView={() => {
            setSite(undefined);
            setShowCard(false);
          }}
          site={site}
          partnerNameOptions={partnerNamesOptions}
        />
      </StyledModal>

      <StyledModal isOpen={showModal}>
        <PropertySitesCard
          flipView={() => {
            setShowModal(false);
          }}
          site={site}
        />
      </StyledModal>

      {isLoading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {isSuccess && (
        <StyledTableContainer>
          <StyledTableFrame>
            <tbody>
              <StyledHeaderRow>
                <StyledColumnHeader>Site ID</StyledColumnHeader>
                <StyledColumnHeader>Partner Name</StyledColumnHeader>
                <StyledColumnHeader>Site Name</StyledColumnHeader>
                <StyledColumnHeader>Property Count</StyledColumnHeader>
                <StyledColumnHeader>Type</StyledColumnHeader>
                <StyledColumnHeader>Status</StyledColumnHeader>
                <StyledColumnHeader>External Id</StyledColumnHeader>
                <StyledColumnHeader>Action</StyledColumnHeader>
                <StyledColumnHeader />
              </StyledHeaderRow>
              {sitesList &&
                sitesList.length > 0 &&
                sitesList.map((site, index) => {
                  return [
                    <StyledRow key={index}>
                      <StyledCell>{site.id}</StyledCell>
                      <StyledCell>{site.partner.name}</StyledCell>
                      <StyledCell>{site.name}</StyledCell>
                      <StyledCell>
                        {<Link onClick={() => showSitePropertiesModal(site)}>{site.site_properties_count}</Link>}
                      </StyledCell>
                      <StyledCell>{site.type}</StyledCell>
                      <StyledCell> {site.enabled ? 'Enabled' : 'Disabled'}</StyledCell>
                      <StyledCell>{site.external_id}</StyledCell>
                      {/* EDIT EXISTING SITE */}
                      <StyledEditCell>
                        <ActionButton
                          usage="quiet"
                          id={`edit-${index}`}
                          variant="noLabel"
                          icon="edit"
                          size="small"
                          onClick={() => handleEdit(site)}
                        />
                      </StyledEditCell>
                    </StyledRow>
                    // DISPLAY EDIT SITE CARD
                    // editId === site.id && (
                    //   <StyledCardRow>
                    //     <StyledCell colSpan={100}>
                    //       <SitesCard status={Status.Edit} flipView={() => setEditId(undefined)} site={site} />
                    //     </StyledCell>
                    //   </StyledCardRow>
                    // )
                  ];
                })}
            </tbody>
          </StyledTableFrame>
          {shouldDisplayPagination && (
            <Pagination
              currentPageCount={sitesList?.length ?? 0}
              nextPage={pageMeta?.next_page}
              page={page}
              per={per}
              prevPage={pageMeta?.prev_page}
              setPage={setPage}
              totalCount={pageMeta?.total_count ?? 0}
              totalPages={Math.ceil((pageMeta?.total_count ?? 0) / per)}
            />
          )}
          {/* ADD NEW SITE */}
          {/* <StyledTableFooter>
            {!showCard ? (
              <StyledAddNewRowLink href="#" onClick={() => setShowCard(true)}>
                + Add another site
              </StyledAddNewRowLink>
            ) : (
              <SitesCard status={Status.Add} flipView={() => setShowCard(false)} />
            )}
          </StyledTableFooter> */}
        </StyledTableContainer>
      )}
    </PageWrapper>
  );
};

export default SitesTable;
