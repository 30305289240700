import React from 'react';
import { FONTS, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';

import AssignNewTeamMember from './AssignNewTeamMember/AssignPropertyToTeam';
import { PropertyCsvUploadResponse, isPropertyCsvUploaded } from 'components/tables/property_upload_table/interfaces';
import { FormContext, useMembersProperties, IContext, IUploadedProp } from '../../utils/propertyMemberAssign';

interface IProps {
  onSubmit: (nextStep: string) => void;
  data: PropertyCsvUploadResponse;
}

const Page = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const Header = styled.div({
  margin: '60px 0 60px 0',
  textAlign: 'center',
  width: '528px'
});

const Title = styled.h2([FONT_FAMILY.sectraBold, { fontSize: '44px', lineHeight: '56px' }]);
const SubTitle = styled.p([FONTS.p1, { marginTop: '28px' }]);

const AssignNewTeam: React.FC<IProps> = (props: IProps) => {
  const properties = isPropertyCsvUploaded(props.data) ? props.data : [];
  const contextValues: IContext = useMembersProperties(properties);

  return (
    <FormContext.Provider value={contextValues}>
      <Page>
        <Header>
          <Title>Assign your team to properties</Title>
          <SubTitle>You can add your property managers and leasing agents below to keep them in the loop.</SubTitle>
        </Header>

        <AssignNewTeamMember />
      </Page>
    </FormContext.Provider>
  );
};

export default AssignNewTeam;
