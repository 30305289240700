import React from 'react';
import { flatMap } from 'lodash';
import { generatePath, useLocation } from 'react-router-dom';
import { Card } from '@sayrhino/rhino-shared-js';
import PagingList from '../PagingList';
import { ACCOUNT_ROUTES } from '../utils/routeHelpers';
import { PageWrapper, PageTitle, Name } from './Styled';
import CardLink from '../CardLink';

const PropertyEntities = ({ propertyEntitiesResponse }) => {
  const { hasNextPage, fetchNextPage, isSuccess, isFetchingNextPage, data } = propertyEntitiesResponse;
  const propertyOwners = flatMap(data?.pages, (d) => d.data || []);
  const totalCount = isSuccess && propertyEntitiesResponse.data?.pages[0].meta.total_count;
  const location = useLocation();
  return (
    <PageWrapper>
      <PageTitle>Property Entities</PageTitle>
      {isSuccess &&
        propertyOwners.map(({ id, name }) => {
          return (
            <CardLink key={id} to={String(id)}>
              <Card style={{ height: 'initial', width: 'auto' }}>
                <Name>{name}</Name>
              </Card>
            </CardLink>
          );
        })}
      {totalCount > 20 && (
        <PagingList
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasData //We are already checking that there is data to display the component
        />
      )}
    </PageWrapper>
  );
};

export default PropertyEntities;
