import { createContext, useContext, useState } from 'react';
import { some } from 'lodash';
import { useMatch } from 'react-router-dom';

interface IClaim {
  administrator: string;
  claim_type: string;
  id: number | undefined;
  property_address_line_one: string;
  renter_full_name: string;
}

interface IAnalyst {
  analystID: number | undefined;
  analystName: string;
}

export const ClaimContext = createContext<{
  inBatchAssignMode: boolean;
  setBatchAssignMode(): void;
  batchedClaims: IClaim[];
  setBatchSelectedClaims(claim?: IClaim): void;
  selectedAnalyst: IAnalyst;
  setSelectedAnalyst(analyst: IAnalyst): void;
  setBatchedClaims(claims: IClaim[]): void;
}>({
  inBatchAssignMode: false,
  setBatchAssignMode: () => undefined,
  batchedClaims: [],
  setBatchSelectedClaims: () => undefined,
  selectedAnalyst: { analystID: undefined, analystName: '' },
  setSelectedAnalyst: ({}) => undefined,
  setBatchedClaims: () => []
});

export const useBatchAssign = () => {
  const batchAssignActive = Boolean(useMatch('/admin/claims/batch_assign'));

  const [inBatchAssignMode, setInBatchAssignMode] = useState<boolean>(batchAssignActive);
  const [batchedClaims, setBatchedClaims] = useState<IClaim[]>([]);
  const [selectedAnalyst, setAnalyst] = useState<IAnalyst>({ analystID: undefined, analystName: '' });

  const setBatchAssignMode = () => {
    setInBatchAssignMode(!inBatchAssignMode);
  };

  const setBatchSelectedClaims = (claim: IClaim) => {
    if (claim === undefined) {
      setBatchedClaims([]);
    } else {
      if (some(batchedClaims, ['id', claim.id])) {
        setBatchedClaims((claims) => claims.filter((c) => c.id !== claim.id));
      } else {
        setBatchedClaims((claims) => [...claims, claim]);
      }
    }
  };

  const setSelectedAnalyst = (analyst: IAnalyst) => {
    setAnalyst({ analystID: analyst.analystID, analystName: analyst.analystName });
  };

  return {
    inBatchAssignMode,
    setBatchAssignMode,
    batchedClaims,
    setBatchSelectedClaims,
    selectedAnalyst,
    setSelectedAnalyst,
    setBatchedClaims
  };
};

export const useClaimContext = () => {
  return useContext(ClaimContext);
};
