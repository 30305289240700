export const isTab = (node) => {
  return isNode(node) && node.getAttribute('data-tab');
};

export const isNode = (node) => {
  return node && 'getAttribute' in node;
};

export const isTabDisabled = (node) => {
  return isNode(node) && node.getAttribute('aria-disabled') === 'true';
};

export const isTabList = (node) => {
  return !!node.type && node.type.tabsRole === 'TabList';
};

export const isTabContent = (node) => {
  return !!node.type && node.type.tabsRole === 'TabContent';
};
