import React, { Component } from 'react';
import StateSection from './StateSection';

interface IProps {
  states: IActiveState[];
  sendNewActiveStates: Function;
}

interface IActiveState {
  active: boolean;
  full_name: string;
  name: string;
}

interface IState {
  updatedStates: object;
  stateSections: IStateSection[];
}

interface IStateSection {
  title: string;
  data: IActiveState[];
}

class Dashboard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      updatedStates: {},
      stateSections: [
        { title: 'A', data: [] },
        { title: 'B', data: [] },
        { title: 'C', data: [] },
        { title: 'D', data: [] },
        { title: 'E', data: [] },
        { title: 'F', data: [] },
        { title: 'G', data: [] },
        { title: 'H', data: [] },
        { title: 'I', data: [] },
        { title: 'J', data: [] },
        { title: 'K', data: [] },
        { title: 'L', data: [] },
        { title: 'M', data: [] },
        { title: 'N', data: [] },
        { title: 'O', data: [] },
        { title: 'P', data: [] },
        { title: 'Q', data: [] },
        { title: 'R', data: [] },
        { title: 'S', data: [] },
        { title: 'T', data: [] },
        { title: 'U', data: [] },
        { title: 'V', data: [] },
        { title: 'W', data: [] },
        { title: 'X', data: [] },
        { title: 'Y', data: [] },
        { title: 'Z', data: [] }
      ]
    };
  }

  public componentWillMount() {
    this.splitStatesAlphabetically();
  }

  public splitStatesAlphabetically() {
    const updatedSections = this.state.stateSections;

    this.props.states.forEach(function (state) {
      const firstLetter = state.name.charAt(0);
      const result = updatedSections.find((x) => x.title === firstLetter);
      if (result) {
        result.data.push(state);
      }
    });

    this.setState({ stateSections: updatedSections });
  }

  public saveActiveStates = (value) => {
    let updatedStates = this.state.updatedStates;

    const obj = {};
    obj[value.name] = value.active;
    updatedStates = { ...updatedStates, ...obj };

    this.setState({ updatedStates });
    this.props.sendNewActiveStates(updatedStates);
  };

  public render(): JSX.Element {
    return (
      <div>
        {this.state.stateSections.map((item) => (
          <div>{item.data.length > 0 && <StateSection item={item} onStatesChange={this.saveActiveStates} />}</div>
        ))}
      </div>
    );
  }
}

export default Dashboard;
