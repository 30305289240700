import React from 'react';
import { render } from '@testing-library/react';
import InsurancePolicyCard from '../InsurancePolicyCard';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';

let server;

describe('<InsurancePolicyCard />', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('renders policy information', async () => {
    const { findByText } = render(
      <Wrapper>
        <InsurancePolicyCard
          coverageAmount="$3,700.00"
          coverageDates="Sep 24, 2020 - Aug 31, 2021"
          property={{
            name: 'Meadow Apartments',
            state: 'CA'
          }}
          status="active"
          statusDetail={{
            severity: 'lowPriority',
            label: 'status label',
            message: 'my message',
            indicator_label: 'my indicator',
            days_before_expiration: 1
          }}
          tenantFullName="Josh Conel"
          unitName="56"
        />
      </Wrapper>
    );
    const name = await findByText('Josh Conel');
    const unit = await findByText('56');
    const coverageDates = await findByText('Sep 24, 2020 - Aug 31, 2021');
    const state = await findByText('CA');
    const coverageAmount = await findByText('$3,700.00');
    const status = await findByText('active');

    expect(name).toBeDefined();
    expect(name).toHaveTextContent('Josh Conel');

    expect(unit).toBeDefined();
    expect(unit).toHaveTextContent('56');

    expect(coverageDates).toBeDefined();
    expect(coverageDates).toHaveTextContent('Sep 24, 2020 - Aug 31, 2021');

    expect(state).toBeDefined();
    expect(state).toHaveTextContent('CA');

    expect(coverageAmount).toBeDefined();
    expect(coverageAmount).toHaveTextContent('$3,700.00');

    expect(status).toBeDefined();
    expect(status).toHaveTextContent('active');
  });
});
