export const COVERAGE_MULTIPLIER_OPTIONS = [
  { label: '0.5X monthly rent', value: '0.5' },
  { label: '1X monthly rent', value: '1.0' },
  { label: '1.5X monthly rent', value: '1.5' },
  { label: '2X monthly rent', value: '2.0' },
  { label: '2.5X monthly rent', value: '2.5' },
  { label: '3X monthly rent', value: '3.0' },
  { label: '4X monthly rent', value: '4.0' },
  { label: '5X monthly rent', value: '5.0' },
  { label: '6X monthly rent', value: '6.0' }
];

export const MULTIPLIER_OPTIONS_FOR_UNIT_PROPERTY = [
  { label: '0.5X', value: '0.5' },
  { label: '1X', value: '1.0' },
  { label: '1.5X', value: '1.5' },
  { label: '2X', value: '2.0' },
  { label: '2.5X', value: '2.5' },
  { label: '3X', value: '3.0' },
  { label: '3.5X', value: '3.5' },
  { label: '4X', value: '4.0' },
  { label: '5X', value: '5.0' },
  { label: '6X', value: '6.0' }
];

export const RESIDENT_STATUSES_OPTIONS = [
  { label: 'approved applicants', value: [] },
  { label: 'approved or future applicants', value: ['Future'] },
  { label: 'approved or current or future applicants', value: ['Future', 'Current'] }
];
