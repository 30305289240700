import React from 'react';
import styled from '@emotion/styled';
import { FileIcon, DeleteIcon, PALETTE } from '@sayrhino/rhino-shared-js';
import { FileIconDiv, FileName } from './ClaimDetails/ClaimStyles';

const FileItemDiv = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px',
  marginTop: 12,
  width: '100%',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: 4,
  backgroundColor: PALETTE.neutralLight
});

const Button = styled.button({
  border: 'none',
  background: 'transparent',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center'
});

export interface Attachment {
  id: number;
  name: string;
}

interface AttachmentFileItemProps {
  attachment: Attachment;
  removeAttachment?: (e, attachment) => void;
}

const AttachmentFileItem: React.FC<AttachmentFileItemProps> = ({ attachment, removeAttachment }) => {
  return (
    <FileItemDiv key={attachment.name}>
      <FileIconDiv>
        <FileIcon style={{ marginRight: 12, color: PALETTE.interaction100 }} />
        <FileName>{attachment.name}</FileName>
      </FileIconDiv>
      {removeAttachment && (
        <Button
          onClick={(e) => removeAttachment(e, attachment)}
          name={attachment.name}
          aria-label={`remove-${attachment.name}`}
        >
          <DeleteIcon css={{ ':hover': { color: PALETTE.alert100 } }} />
        </Button>
      )}
    </FileItemDiv>
  );
};

export default AttachmentFileItem;
