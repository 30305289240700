import { IClaimUpdate, IInsurancePolicyUpdate, IInvitationUpdate, IPropertyUpdate } from 'components/v2/App/interfaces';

export const getClaimPath = (claimUpdate: IClaimUpdate) => `admin/claims/${claimUpdate.id}`;
export const getClaimAttachmentPath = ({ claimId, blob_id }: { blob_id: string; claimId: string }) =>
  `admin/claims/${claimId}/attachments/${blob_id}`;
export const getInsurancePolicyPath = (insurancePolicyUpdate: IInsurancePolicyUpdate) =>
  `admin/renter_policies/${insurancePolicyUpdate.id}`;
export const getInvitationPath = (invitationUpdate: IInvitationUpdate) => `admin/partial_quotes/${invitationUpdate.id}`;
export const getV2InvitationPath = (invitationUpdate: IInvitationUpdate) => `admin/invitations/${invitationUpdate.id}`;
export const getPropertyPath = (propertyUpdate: IPropertyUpdate) => `admin/properties/${propertyUpdate.id}`;

export const ROUTES = {
  account: 'v2/users/account',
  two_fa: 'v2/users/account/two_factor',
  propertyOwner: 'admin/property_owners'
};

export const ACCOUNT_ROUTES = {
  edit: `${ROUTES.account}/edit`,
  twoFactorSetup: `${ROUTES.two_fa}/setup`,
  twoFactorCodes: `${ROUTES.two_fa}/codes`,
  propertyOwners: `${ROUTES.account}/property_owners`,
  propertyOwnerView: `${ROUTES.account}/property_owners/:propertyOwnerId`,
  propertyOwnerEdit: `${ROUTES.account}/property_owners/:propertyOwnerId/edit`,
  legalPropertyEntities: `${ROUTES.account}/legal_property_entities`,
  legalPropertyEntitiesView: `${ROUTES.account}/legal_property_entities/:propertyEntityId`,
  legalPropertyEntitiesEdit: `${ROUTES.account}/legal_property_entities/:propertyEntityId/edit`
};

export const PROPERTY_OWNER_ROUTES = {
  details: `${ROUTES.propertyOwner}/:propertyOwnerId`,
  new: `${ROUTES.propertyOwner}/new`,
  edit: `${ROUTES.propertyOwner}/:propertyOwnerId/edit`
};

export const PARTNER_INTEGRATION_ROUTES = {
  new: `integrations/partners/new`,
  edit: `:propertyOwnerId/integrations/partners/:partnerIntegrationId/edit`,
  yardiPush: `policy_push/:partnerIntegrationId`,
  coverage: `integrations/partners/:partnerIntegrationId/coverage`
};
