import React from 'react';
import { Button } from '@sayrhino/rhino-shared-js';

const UpdatePercentageButton: React.FC = () => {
  const updateLocation = () => {
    window.location.href = '/v2/admin/insurance_carrier_percentage/new';
  };

  return (
    <Button variant="secondary" onClick={updateLocation}>
      Update Insurance Carrier Percentages
    </Button>
  );
};

export default UpdatePercentageButton;
