import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

export const StyledTableFrame = styled.table({
  width: '100%',
  marginTop: '20px',
  height: '100px',
  overflow: 'auto'
});

export const StyledTableContainer = styled.div({
  overflow: 'auto',
  height: '200px'
});

const rowPadding = { padding: '10px 0' };
export const StyledRow = styled.tr(rowPadding, {
  borderBottom: `solid 1px ${PALETTE.neutral12}`,
  ':hover': {
    cursor: 'pointer',
    backgroundColor: PALETTE.interaction4
  }
});

export const StyledCell = styled.td([
  FONTS.p2Light,
  { minWidth: '60px', paddingBottom: '10px', paddingTop: '10px', paddingRight: '16px', margin: '20px 0' }
]);

export const StyledColumnHeader = styled.th([
  FONTS.p3Light,
  { minWidth: '60px', paddingTop: '12px', paddingRight: '16px', textAlign: 'left' }
]);
export const StyledHeaderRow = styled.tr(rowPadding);
