import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IFilterQueryParams, IPropertyFilterOptions } from '../../components/v2/App/interfaces';
import { stringify } from 'qs';

export const getInsurancePolicyPropertyFilterOptions = async (params = {}): Promise<IPropertyFilterOptions> => {
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data }: AxiosResponse<IPropertyFilterOptions> = await axios.get<IPropertyFilterOptions>(
    '/v2/admin/insurance_policies/property_filter_options',
    { params, paramsSerializer }
  );

  return data;
};

export const insurancePolicyPropertyFilterOptionsKey = 'insurancePolicyPropertyFilterOptions';

export default function useInsurancePolicyPropertyFilterOptions(
  queryParams: IFilterQueryParams
): QueryObserverResult<IPropertyFilterOptions> {
  return useQuery(
    [insurancePolicyPropertyFilterOptionsKey, queryParams],
    () => getInsurancePolicyPropertyFilterOptions(queryParams),
    {
      keepPreviousData: true
    }
  );
}
