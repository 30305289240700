import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IRentersInsuranceLiabilityCoverages } from 'components/v2/App/interfaces';
const getRentersInsuranceLiabilityCoverage = async () => {
  const { data } = await axios.get<IRentersInsuranceLiabilityCoverages>(
    `/v2/admin/renters_insurance_requirements.json`
  );
  return data;
};

export default function useRentersInsuranceLiabilityCoverage(): QueryObserverResult<IRentersInsuranceLiabilityCoverages> {
  return useQuery<IRentersInsuranceLiabilityCoverages>('renters insurance liability coverage', () =>
    getRentersInsuranceLiabilityCoverage()
  );
}
