/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';

const PercentageBar = ({ data }) => (
  <div
    css={{
      height: '48px',
      borderRadius: '100px',
      display: 'flex',
      overflow: 'hidden'
    }}
  >
    {data.map((item) => (
      <div
        css={{
          backgroundColor: item.color,
          height: '100%',
          flex: item.value
        }}
        key={item.id}
      />
    ))}
  </div>
);

export default PercentageBar;
