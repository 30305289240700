import { PropertyManagerResult } from './PropertyManagerTypeahead';
import { ListingAgentResult } from './ListingAgentTypeahead';
import { KeyboardEventHandler, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import {
  PROPERTY_UNITS_KEY,
  Response,
  SPU_PROPERTY_CACHE_KEY,
  useListingAgents,
  useProperty,
  usePropertyManagers,
  usePropertyUnits,
  useSPUEditMutation,
  useSPUMutation,
  validationFields,
  useSyncProspects
} from './queryHooks';
import { getUserDetails } from '.';
import useStates from 'api/v2/useStates';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';
import { centsToUSDFormatter, redirectTo } from '../../utils';
import { Coverage, FormInputValues, property_types, Option, State } from './types';
import { useQueryClient } from 'react-query';
import { FormMode } from '../../interfaces';
import Decimal from 'decimal.js';
import { sanitizeCurrencyInput } from '../../utils/index';
import { LemonadeInterestedParty } from 'entities/lemonade';

const initialValuesForCreate = {
  units: [],
  coverage_details: {
    type: Coverage.MULTIPLIER,
    value: undefined
  },
  property_type: property_types.SINGLE,
  integration_type: 'no_integration',
  stripe_bank_account_id: null,
  stripe_bank_account_display_name: null,
  renter_cash_deposit_portal_name: null,
  renter_cash_deposit_portal_url: null,
  renters_insurance_enabled: false,
} as FormInputValues;

const formatCreatedData = (
  prop: FormInputValues,
  listingAgents: ListingAgentResult[],
  propertyManagers: PropertyManagerResult[],
  googlePlaceId
): FormInputValues => {
  let policy_holder = prop.policy_holder;
  let property_owner = prop.property_owner;
  let { coverage_details } = prop;
  const listing_agent_ids = listingAgents?.map(({ id }) => id);
  const property_manager_ids = propertyManagers?.map(({ id }) => id);

  if (prop.property_type !== property_types.MULTI && prop.property_type !== property_types.SAGC)
    prop.units = ['N/A'];

  if (policy_holder?.__isNew__) {
    policy_holder = { id: null, name: policy_holder.name };
  }

  if (property_owner?.__isNew__) {
    property_owner = { id: null, name: property_owner.name };
  }

  if (coverage_details.type === Coverage.AMOUNT) {
    let amount = coverage_details.value as number;
    coverage_details = { ...coverage_details, value: amount };
  }

  return {
    ...prop,
    multiple_owners: prop.property_type != property_types.MULTI ? false : prop.multiple_owners,
    policy_holder,
    property_owner,
    coverage_details,
    google_place_id: googlePlaceId,
    listing_agent_ids,
    property_manager_ids
  };
};

type Prop = (res: Response) => void;

/**
 *
 * @param formMode enum to determine whether form is creating or editing
 * @param propertyData the retrieved property data from useProperty hook
 * @returns "empty" object for new form (basic info/default selections) or a filled object for existing form
 */
const setDefaultPropertyValue = (formMode: FormMode, propertyData: Response | undefined): FormInputValues => {
  if (formMode === FormMode.CREATE) {
    return initialValuesForCreate as FormInputValues;
  } else {
    return propertyData as FormInputValues;
  }
};

/**
 * Contains all shared logic for SPU used in both create and edit View including
 * input states, error / validation states, mutation methods
 *
 * @param setResponse callback function that take the newly created property returned from the backend
 * @returns An object containing all method and state used on the SPU forms
 */
const useSinglePropertyUploadLogic = (setResponse?: Prop) => {
  const { propertyId } = useParams();
  const formMode: FormMode = propertyId ? FormMode.EDIT : FormMode.CREATE;
  const defaultError = Boolean(propertyId) ? {} : [];
  const { data: propertyData, isLoading: isPropertyLoading, isSuccess: isPropertySuccess } = useProperty(propertyId);
  const { data: lAgents } = useListingAgents(propertyId);
  const { data: pManagers } = usePropertyManagers(propertyId);
  const [property, setProperty] = useState<FormInputValues>(setDefaultPropertyValue(formMode, propertyData));
  const [propertyManagers, setPropertyManagers] = useState<PropertyManagerResult[]>([]);
  const [listingAgents, setListingAgents] = useState<ListingAgentResult[]>([]);
  const [googlePlaceId, setGooglePlaceId] = useState<string>();
  const { addProperty, isLoading } = useSPUMutation();
  const { editProperty, isLoading: updating } = useSPUEditMutation();
  const { syncProspects } = useSyncProspects();
  const [validationError, setValidationError] = useState({});
  const [errors, setErrors] = useState<string[] | object>(defaultError);
  const { user } = getUserDetails();
  const { data: statesData } = useStates();
  const { data: unit_info, isLoading: isUnitLoading, isSuccess: isUnitSuccess } = usePropertyUnits(propertyId, true);
  const { addToast } = useToast();
  const [cashDeposit, setCashDeposit] = useState<boolean>();
  const [rentersInsuranceEnabled, setRentersInsuranceEnabled] = useState<boolean>();
  const [interestedParty, setInterestedParty] = useState<LemonadeInterestedParty|undefined>();
  const [partnerBankAccDisabled, setPartnerBankAccDisabled] = useState<boolean>();
  const [usesPartnerEnrollment, setUsesPartnerEnrollment] = useState<boolean>();
  const isFormLoading = isUnitLoading && isPropertyLoading;
  const isFormSuccess = isPropertySuccess && isUnitSuccess;
  const queryClient = useQueryClient();

  /**
   * For Creatable
   * Takes property units (should always be null) and returns an Option object for Createable default options
   * @param units string[] of options
   * @returns Options[], or empty []
   */
  const createDefaultOptions = (units: string[]) => {
    const options: Option[] = [];
    if (units) {
      options.push(...units.map((x) => ({ label: x, value: x })));
    }
    return options;
  };
  // state for Creatable component
  const [state, setState] = useState<State>({
    inputValue: '',
    value: unit_info ? createDefaultOptions(unit_info.units.map((x) => x.name)) : []
  });
  const multiFamEdit: boolean = property?.property_type === property_types.MULTI || property?.property_type === property_types.SAGC;

  useEffect(() => {
    if (propertyData) {
      setProperty(propertyData);
      setGooglePlaceId(propertyData?.google_place_id);
    }
    if (pManagers) {
      setPropertyManagers(pManagers.property_managers);
    }
    if (lAgents) {
      setListingAgents(lAgents.listing_agents);
    }
    if (unit_info && propertyData) {
      const units = unit_info?.units?.map(({ name }) => name);
      setProperty({ ...propertyData, units });
    }
    if (state) {
      // update state to most recent unit values, and ensures the property_type overwrtes the data useEffect check
      // persist property type when the state updates (keeps it from flipping back to single_family)
      if (property?.property_type && property.property_type === property_types.MULTI) {
        setProperty((prev) => ({ ...prev, units: getOptionValues(), property_type: property_types.MULTI }));
      } else if (property?.property_type && property.property_type === property_types.SAGC) {
        setProperty((prev) => ({ ...prev, units: getOptionValues(), property_type: property_types.SAGC }));
      } else {
        setProperty((prev) => ({ ...prev, units: getOptionValues() }));
      }
    }

    setCashDeposit(propertyData?.cash_deposit_enabled);
    setPartnerBankAccDisabled(propertyData?.deposify_partner_bank_account_disabled);
    setUsesPartnerEnrollment(propertyData?.uses_partner_enrollment);
    setRentersInsuranceEnabled(propertyData?.renters_insurance_enabled);
    setInterestedParty(propertyData?.lemonade_interested_party)
  }, [propertyData, lAgents, pManagers, unit_info, state]);

  /**
   * Handles segment tracking
   *
   * @param value the title of the segment event being tracked
   * @param others Require properties for each segment event
   */
  const tracker = (value: string, others?: object) => {
    window.analytics.track(value, { ...user, ...others });
  };

  /**
   * Checks if a form field is in an error state
   *
   * @param key represent a form field
   * @returns return true or false indicating error state of a form field
   */
  const formErrors = (key: string) => {
    if (validationError[key] || errors[key]) {
      return true;
    }
  };

  const getError = (key: string) => {
    //Frontend error eg empty required fields
    if (validationError[key]) return validationError[key];
    //Error returned from the backend
    if (errors[key]) return errors[key][0];
  };

  /**
   * We only want to track full address that is after address_line_one
   * address_state, city and zip has been enter to prevent multiple
   * tracking with in complete data
   */
  const fullAddressEntered = () => {
    if (
      Boolean(property.address_city) &&
      Boolean(property.address_line_one) &&
      Boolean(property.address_state) &&
      Boolean(property.address_zip)
    ) {
      tracker('New Property - Building Address Entered');
    }
  };

  const clearMultiPropertyInput = () => {
    setState({ inputValue: '', value: unit_info ? createDefaultOptions(unit_info.units.map((x) => x.name)) : [] });
  };

  /**
   * This function handles updating the form state
   *
   * @param event HTMLInputEvent
   * @param key form field property
   */
  const handleChange = (event, key: string) => {
    if (key === 'coverage_details_dollar_amount') {
      const value = sanitizeCurrencyInput(event);
      if (value < 33000) {
        setValidationError({
          ...errors,
          coverage_details_value: ['cannot be less than $330']
        });
      } else {
        setValidationError({
          ...errors,
          coverage_details_value: undefined
        });
        setProperty((prev) => ({ ...prev, coverage_details_value: event.target.value }));
      }
    } else if (key === 'address_state') {
      setValidationError((prev) => ({ ...prev, [key]: undefined }));
      setProperty((prev) => ({ ...prev, [key]: event.value }));
    } else if (event.target.value !== undefined) {
      setValidationError((prev) => ({ ...prev, [key]: undefined }));
      setProperty((prev) => ({ ...prev, [key]: event.target.value }));
    }
  };

  const handleInterestedPartyChange = (value?: LemonadeInterestedParty) => {
    setInterestedParty(value);

    if (value) {
      setProperty((prev) => ({ ...prev, lemonade_interested_party_attributes: value }));
    } else {
      const { lemonade_interested_party_attributes, ...newProperty } = property;
      setProperty(newProperty);
    }
  };

  const onCreate = async () => {
    const errors = validationFields(property);
    const hasErrors: boolean = Object.keys(errors).length > 0;
    const data = formatCreatedData(property, listingAgents, propertyManagers, googlePlaceId);
    data.cash_deposit_enabled = cashDeposit;
    if (hasErrors) {
      setValidationError(errors);
      setErrors(errors);
    } else {
      setValidationError({});
      addProperty(data, {
        onSuccess: (res) => {
          tracker('Create Property Button Clicked Successfully', { property_id: res.data.property.id });
          if (setResponse) {
            setResponse(res.data.property);
          }
        },
        onError: (error: AxiosError) => {
          setErrors(error?.response?.data.errors);
        }
      });
    }
  };
  //Createable hooks and methods

  const getOptionValues = () => {
    return state.value.map((x) => x.value);
  };

  const createOption = (label: string) => ({
    label,
    value: label
  });

  // Only triggers when typing in input
  const handleUnitInputChange = (inputValue: string) => {
    setState({
      inputValue,
      value: [...state.value]
    });
  };

  // Only triggers on unit delete
  const handleUnitChange = (value: OnChangeValue<Option, true>) => {
    // clear input value, set value to parameters (string array)
    setState({
      inputValue: '',
      value
    });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const { inputValue, value } = state;
    if (!inputValue || !multiFamEdit) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setState({
          inputValue: '',
          value: [...value, createOption(inputValue)]
        });
        clearErrors();
        event.preventDefault();
    }
  };

  const clearErrors = () => {
    setValidationError({});
    setErrors({});
  };

  const onEdit = async () => {
    const errors = validationFields(property);
    const hasErrors: boolean = Object.keys(errors).length > 0;
    const data = formatCreatedData(
      { id: Number(propertyId), ...property },
      listingAgents,
      propertyManagers,
      googlePlaceId
    );

    data.renters_insurance_enabled = rentersInsuranceEnabled;
    data.cash_deposit_enabled = cashDeposit;
    data.deposify_partner_bank_account_disabled = partnerBankAccDisabled;
    data.uses_partner_enrollment = usesPartnerEnrollment;

    if (hasErrors) {
      setValidationError(errors);
      setErrors(errors);
    } else {
      setValidationError({});
      editProperty(data, {
        onSuccess: (_res) => {
          addToast('Property updated', TOAST_STATUS.SUCCESS);
          redirectTo(`/admin/properties/${propertyId}`);
          invalidatePropertyCache();
        },
        onError: (error: AxiosError) => {
          const errors = error?.response?.data.errors;
          setErrors(errors);

          if (errors?.deposify) {
            errors.deposify.forEach((error: string) => addToast(error, TOAST_STATUS.ERROR));
          } else {
            addToast('An error has occurred', TOAST_STATUS.ERROR);
          }
        }
      });
    }
  };

  const onSyncProspects = async () => {
    syncProspects(propertyId, {
      onSuccess: (res) => {
        const message =
          parseInt(res.data.new_prospects) > 0 ? `${res.data.new_prospects} new prospects` : 'No new prospects';
        addToast(message, TOAST_STATUS.SUCCESS);
      },
      onError: (error: AxiosError) => {
        const errorMessage = error?.response?.data.error;
        addToast(errorMessage, TOAST_STATUS.ERROR);
      }
    });
  };

  const invalidatePropertyCache = () => {
    queryClient.invalidateQueries([PROPERTY_UNITS_KEY, propertyId]);
    queryClient.invalidateQueries([SPU_PROPERTY_CACHE_KEY, propertyId]);
  };

  const safeConvertToDecimal = (value) => {
    if (value === undefined || value === null) {
      return new Decimal(0);
    }
    return new Decimal(value);
  };

  const convertToDecimalAndFormatUSD = (value) => {
    const convertedValue = safeConvertToDecimal(value);
    return centsToUSDFormatter(convertedValue);
  };

  const interestedPartyErrors = {
    name: getError('lemonade_interested_party.name'),
    email: getError('lemonade_interested_party.email'),
    address: getError('lemonade_interested_party.address'),
  }

  return {
    handleChange,
    property,
    setProperty,
    propertyManagers,
    listingAgents,
    setPropertyManagers,
    setListingAgents,
    googlePlaceId,
    setGooglePlaceId,
    onSubmit: propertyId ? onEdit : onCreate,
    onSyncProspects,
    fullAddressEntered,
    formErrors,
    statesData,
    validationError,
    setValidationError,
    tracker,
    user,
    errors,
    isLoading,
    unit_info,
    updating,
    getError,
    state,
    setState,
    handleKeyDown,
    handleUnitChange,
    handleUnitInputChange,
    getOptionValues,
    clearMultiPropertyInput,
    convertToDecimalAndFormatUSD,
    formMeta: {
      isFormLoading,
      isFormSuccess,
      formMode
    },
    cashDeposit,
    setCashDeposit,
    partnerBankAccDisabled,
    setPartnerBankAccDisabled,
    usesPartnerEnrollment,
    setUsesPartnerEnrollment,
    rentersInsuranceEnabled,
    setRentersInsuranceEnabled,
    interestedParty,
    handleInterestedPartyChange,
    interestedPartyErrors
  };
};

export default useSinglePropertyUploadLogic;
