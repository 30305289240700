import React from 'react';
import { render, screen, fireEvent, within, getByText, waitFor, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import CreateInvitation from '../Invitation/CreateInvitation';
import makeServer from '../testUtils/server';
import { MemoryRouter } from 'react-router-dom';
import { ReactQueryWrapper } from '../testUtils/Wrapper';
import { RENTAL_STATUS_OPTIONS } from '../Invitation/CreateInvitationForm';

let server;
describe('<CreateInvitation />', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  const doRender = () => {
    act(() => {
      render(
        <MemoryRouter>
          <ReactQueryWrapper>
            <CreateInvitation />
          </ReactQueryWrapper>
        </MemoryRouter>
      );
    });
  };

  test('It renders the correct heading', async () => {
    doRender();

    const heading = await screen.findByRole('heading', { level: 3, name: /New Invitation/i });
    expect(heading).toBeDefined();
  });

  test('It should allow for first name to be updated', async () => {
    doRender();

    const firstNameField = await screen.findByLabelText(/First name/i);
    userEvent.type(firstNameField, 'Bob');
    const firstNameInput = screen.getByRole('textbox', { name: /First name/i });
    expect(firstNameInput).toHaveValue('Bob');
  });

  test('It should allow for last name to be updated', async () => {
    doRender();

    const lastNameField = await screen.findByLabelText(/Last name/i);
    userEvent.type(lastNameField, 'Marley');
    const lastNameInput = screen.getByRole('textbox', { name: /Last name/i });
    expect(lastNameInput).toHaveValue('Marley');
  });

  test('It should allow for email to be updated', async () => {
    doRender();

    const emailField = await screen.findByLabelText(/Email/i);
    userEvent.type(emailField, 'bob.marley@gmail.com');
    const emailInput = screen.getByRole('textbox', { name: /Email/i });
    expect(emailInput).toHaveValue('bob.marley@gmail.com');
  });

  test('It should allow for phone to be updated', async () => {
    doRender();

    const phoneField = await screen.findByLabelText(/Phone/i);
    userEvent.type(phoneField, '1234567890');
    const phoneInput = screen.getByRole('textbox', { name: /Phone/i });
    expect(phoneInput).toHaveValue('(123) 456-7890');
  });

  test('It shows the rental status when rental status is selected', async () => {
    doRender();

    const rentalStatusField = await screen.findByTitle(/Rental status container/i);
    const rentalStatusInput = within(rentalStatusField).getByRole('combobox');

    RENTAL_STATUS_OPTIONS.forEach(option => {
      // RENTAL_STATUS_OPTIONS contains items of format { label: 'text', value: 'enum' }
      const optionText = option['label'];
      // open the dropdown menu
      fireEvent.keyDown(rentalStatusInput, { key: 'ArrowDown' });
      const vacancyOption = getByText(rentalStatusField, optionText);
      userEvent.click(vacancyOption);
      expect(rentalStatusField).toHaveTextContent(optionText);
    });
  });

  test('It should have an editable lease address', async () => {
    doRender();

    // Search endpoint returns details for a property at 1607 Parkmoor Avenue
    // as specified in the server.ts. The options should be formatted as:
    // building, address_line_one, city, state, zip
    const mockAddress = "Vista, 1607 Parkmoor Avenue, San Jose, CA, 95128"

    const leaseAddressField = await screen.findByTitle(/Lease address container/i);
    const leaseAddressInput = within(leaseAddressField).getByRole('combobox');
    userEvent.type(leaseAddressInput, '1');
    await waitFor(() => screen.getByText('enter at least three', { exact: false }))
    expect(screen.getByText('enter at least three', { exact: false })).toBeDefined();

    // note: technically, we could put anything here as long as it has at least 3 characters
    // since the server endpoint isn't receiving/processing any parameters
    userEvent.type(leaseAddressInput, '607');
    // this is also serving the purpose of waiting long enough that the next waitFor
    // won't time out before the debounce & search happens
    await waitFor(() => screen.getByText('Searching for 1607'));
    expect(screen.getByText('Searching for 1607')).toBeDefined();

    // find and select matching option in dropdown, use exact false because of bolding in text
    await waitFor(() => getByText(leaseAddressField, mockAddress, { exact: false }));
    const addressOption = getByText(leaseAddressField, mockAddress, { exact: false });
    fireEvent.click(addressOption);
    expect(leaseAddressField).toHaveTextContent(mockAddress);
  });

  test('It should have an editable unit', async () => {
    doRender();

    const leaseUnitField = await screen.findByTitle(/Lease unit container/i);
    const leaseUnitInput = within(leaseUnitField).getByRole('combobox');
    // test that text can be entered in input
    userEvent.type(leaseUnitInput, 'Tr 1');
    // wait for state updates -- we could really wait for anything, but () => {} is discouraged
    await waitFor(() => within(leaseUnitField).getByRole('combobox'));
    expect(leaseUnitInput).toHaveDisplayValue('Tr 1');
  });

  test('It should allow for rent to be updated', async () => {
    doRender();

    // monthly rent currency format, string to number
    const rentField = await screen.findByLabelText(/Monthly rent/i);
    userEvent.type(rentField, '1500');
    expect(rentField).toHaveValue(1500);
  });
});
