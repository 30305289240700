import { titleize } from 'utils/string';

const DEFAULT_SERVICE_FEE_LABEL = 'service fee';

const SERVICE_FEES_LABELS = {
  AL: 'new application policy fee',
  FL: 'policy fee',
  GA: 'policy fee',
  LA: 'agency fee',
  MO: 'policy fee',
  MS: 'policy fee',
  TX: 'policy fee',
  VA: 'policy fee'
};

const DEFAULT_PROCESSING_FEE_LABEL = 'processing fee';

const PROCESSING_FEES_LABELS = {
  // TODO: add processing fee labels
};

interface IFeeLabelOptions {
  titleize: boolean;
}

export const createServiceFeeLabel = (
  state: string,
  service_fee_cents: string,
  options: Partial<IFeeLabelOptions> = {}
) => {
  const label = SERVICE_FEES_LABELS[state] || DEFAULT_SERVICE_FEE_LABEL;
  const formattedLabel = options.titleize ? titleize(label) : label;
  const serviceFeeLabel = `+${service_fee_cents} one-time ${formattedLabel}`;
  return serviceFeeLabel;
};

export const createProcessingFeeLabel = (
  state: string,
  processing_fee_cents: string,
  is_upfront: boolean,
  options: Partial<IFeeLabelOptions> = {}
) => {
  const label = PROCESSING_FEES_LABELS[state] || DEFAULT_PROCESSING_FEE_LABEL;
  const formattedLabel = options.titleize ? titleize(label) : label;
  const processingFeeLabel = `+${processing_fee_cents} ${is_upfront ? '' : '/month'} ${formattedLabel}`;
  return processingFeeLabel;
};
