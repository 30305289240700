import { useState, Dispatch, SetStateAction } from 'react';
import { fetchGooglePredictions, GOOGLE_STATUS_CODES, isPresent } from '../../utils';
import { CheckingInfo } from '../../components/v2/App/Claims/CreateClaim/claimInterface';
import { GooglePrediction, isUnsuccessfulGoogleStatus } from '../../utils/google';

const isInvalidRequest = (status) => GOOGLE_STATUS_CODES.INVALID_REQUEST === status;

interface TypeaheadOption {
  description: string;
  place_id: string;
}
interface GooglePredictionInputProps {
  onOptionChange: (option: TypeaheadOption) => void;
}

const useGooglePredictionsInput = ({ onOptionChange }: GooglePredictionInputProps) => {
  const [searchError, setSearchError] = useState<string>('');
  const [googlePrediction, setGooglePrediction] = useState<GooglePrediction | null>(null);
  const [googlePredictions, setGooglePredictions] = useState<GooglePrediction[] | Array<any>>([]);
  const [googlePredictionsStatus, setGooglePredictionsStatus] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadOptions = async (query) => {
    try {
      setIsLoading(true);
      const res = (await fetchGooglePredictions(query)) as any[];
      const { predictions, status } = res as any;
      if (isInvalidRequest(status)) {
        setSearchError(status);
      }
      setGooglePredictions(predictions);
      setGooglePredictionsStatus(status || '');
      setIsLoading(false);
      return predictions;
    } catch (err) {
      console.log('error is ', err.toString());
      setSearchError(err.toString());
    }
  };

  const onChange = (option) => {
    if (!isPresent(option)) {
      setGooglePrediction(null);
      onOptionChange({ description: '', place_id: '' });
      return null;
    }
    setGooglePrediction(option);
    onOptionChange(option);
  };

  return {
    setGooglePrediction,
    setGooglePredictions,
    googlePrediction,
    googlePredictions,
    googlePredictionsStatus,
    loadOptions,
    isLoading,
    searchError,
    onChange
  };
};

export default useGooglePredictionsInput;
