import { fullTextSearchProperties } from 'api/properties';
import React, { Component } from 'react';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import ReactAsyncSelect from 'react-select/async';
import { ReactSelectProps } from 'react-select';
import { getSelectStyles } from 'components/v2/select/styles';
interface IProps extends ReactSelectProps {
  onChange: (property: any) => any;
  name?: string;
  id?: string;
  valueKey?: string;
  labelKey: string;
  value?: any;
  clearable?: boolean;
  placeholder?: string;
  errors: string | null;
  className?: string;
  refresh?: boolean;
  disabled?: boolean;
  isMulti?: boolean;
}

interface IState {
  selected?: any;
  isLoading: boolean;
  selectedIds?: string[];
  defaultOptions: Properties[];
}

interface Properties {
  id: number;
  full_address: string;
}

class PropertySelect extends Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    name: 'property',
    id: 'property',
    valueKey: 'id',
    clearable: true,
    placeholder: ''
  };
  selectRef: React.RefObject<ReactAsyncSelect>;

  constructor(props: IProps) {
    super(props);

    this.selectRef = React.createRef();

    let ids: string[] = [];

    if (props.isMulti) {
      for (let selected of props.value!) {
        ids = [...ids, selected?.id];
      }
    }

    this.state = {
      isLoading: false,
      selected: props.value,
      defaultOptions: [],
      selectedIds: ids
    };
  }

  public onChangeSingle = (selected) => {
    this.setState({ selected });
    this.props.onChange(selected);
  };

  public onChangeMulti = (selected) => {
    const propertyIds = selected.map((select) => {
      return select.id;
    });

    this.setState({ selectedIds: propertyIds, selected });
  };

  public componentDidMount = () => {
    const { selectRef } = this;

    if (selectRef.current) {
      selectRef.current.select?.input?.input?.setAttribute('autocomplete', 'chrome-off');
    }
  };

  public renderSinglePropertySelect = () => {
    const { name, id, valueKey, labelKey, clearable, placeholder, disabled } = this.props;

    const { selected, isLoading } = this.state;

    return (
      <AsyncSelect
        name={name}
        id={id}
        inputId={id}
        instanceId={id}
        getOptionLabel={(options) => options[labelKey] || 'label'}
        getOptionValue={(options) => options[valueKey || 'value']}
        value={selected}
        isClearable={clearable}
        isLoading={isLoading}
        placeholder={placeholder}
        loadOptions={this.loadProperties}
        onChange={this.onChangeSingle}
        isDisabled={disabled}
        styles={getSelectStyles({})}
      />
    );
  };

  public renderMultiPropertySelect = () => {
    const { name, id, valueKey, labelKey, clearable, placeholder, disabled, isMulti } = this.props;

    const { selected, isLoading, selectedIds } = this.state;

    return (
      <>
        <AsyncSelect
          name={name}
          id={id}
          inputId={id}
          instanceId={id}
          getOptionLabel={(options) => options[labelKey] || 'label'}
          getOptionValue={(options) => options[valueKey || 'value']}
          value={selected}
          isClearable={clearable}
          isLoading={isLoading}
          placeholder={placeholder}
          loadOptions={this.loadProperties}
          onChange={this.onChangeMulti}
          isDisabled={disabled}
          ref={this.selectRef}
          isMulti={isMulti}
          formatOptionLabel={(option, { context }) => {
            return context === 'menu' ? (
              <>
                <b>{option.building_name}</b>
                {option.address_line_one ? `: ${option.address_line_one}, ${option.address_zip}` : ''}
              </>
            ) : (
              option[labelKey] || option.address_line_one
            );
          }}
        />
        <div id="sanitized_property_ids">
          {selectedIds?.map((selected_id, index) => {
            return (
              <input key={`sanitize-${index}`} hidden={true} value={selected_id} name="user[property_ids][]"></input>
            );
          })}
        </div>
      </>
    );
  };

  public render() {
    const { errors, isMulti } = this.props;

    return (
      <div id="property_select" className={this.props.errors ? 'form__input_wrapper error required' : ''}>
        {isMulti ? this.renderMultiPropertySelect() : this.renderSinglePropertySelect()}
        {errors === 'must exist' ? (
          <div className="error">Can't be blank.</div>
        ) : (
          <div className="error">
            {errors}
            {errors && errors.includes('Please submit a help ticket') && (
              <a href="https://support.sayrhino.com">here</a>
            )}
          </div>
        )}
      </div>
    );
  }

  private loadProperties = (input: string, callback: any) => {
    this.setState({ isLoading: true });

    fullTextSearchProperties(
      input,
      (properties) => {
        callback(properties);
        this.setState({ isLoading: false });
      },
      (response) => {
        response.json().then((e) => {
          callback([]);
          this.setState({ isLoading: false });
        });
      }
    );
  };
}

export default PropertySelect;
