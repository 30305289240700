import { PALETTE } from '@sayrhino/rhino-shared-js';
import React, { useState } from 'react';
import AddCoverageRule from './AddCoverageRule';
import { ICoverageRuleCard } from './interface';
import { Header, StyledEditLink, StyledRowLi, Card, HeaderWithoutBorder } from './styles/CoverageRuleCardStyles';
import { AddCoverageTypeEnum } from './util/rulesetConstant';
import { coverageValue } from '../utils';

const CoverageRuleCard = ({
  id,
  editCoverage,
  getUnusedRenterTypes,
  integration,
  saveCoverageRuleList,
  removeCoverage,
  coverageRuleList,
  toggleExpandStatus
}: ICoverageRuleCard) => {
  const [showEditCoverage, setShowEditCoverage] = useState(false);

  const isDepositCharge = (coverageType: string) => coverageType === AddCoverageTypeEnum.RP_DEPOSIT_CHARGES;

  // if nil, true by default
  const sameCashDeposit = editCoverage?.same_cash_deposit === false ? false : true;

  return (
    <Card variant="border" style={{ border: `1px solid ${PALETTE.neutral12} ` }}>
      {showEditCoverage ? (
        <>
          <HeaderWithoutBorder>
            <StyledEditLink onClick={() => {
              setShowEditCoverage(!showEditCoverage)
              toggleExpandStatus(`${id}-coverageRule`, false);
            }}>Close</StyledEditLink>
          </HeaderWithoutBorder>
          <AddCoverageRule
            editCoverage={editCoverage}
            showEditCoverage={(value) => setShowEditCoverage(value)}
            getUnusedRenterTypes={getUnusedRenterTypes}
            integration={integration}
            coverageRuleList={coverageRuleList}
            saveCoverageRuleList={saveCoverageRuleList}
            toggleExpandStatus={toggleExpandStatus}
            id={`${id}-coverageRule`}
          />
        </>
      ) : (
        <>
          {editCoverage && (
            <>
              <Header>
                <StyledEditLink onClick={() => {
                  setShowEditCoverage(!showEditCoverage);
                  toggleExpandStatus(`${id}-coverageRule`, true);
                }}>Edit</StyledEditLink>
                <StyledEditLink onClick={() => removeCoverage(editCoverage)}>Remove</StyledEditLink>
              </Header>
              <ul>
                <StyledRowLi>
                  Renter Type
                  <span>{editCoverage.coverage_option_name}</span>
                </StyledRowLi>

                <StyledRowLi>
                  Coverage: {editCoverage.coverage_type}
                  {!isDepositCharge(editCoverage.coverage_type) &&
                    <span>{coverageValue(editCoverage.coverage_type, editCoverage.coverage_value)}</span>
                  }
                </StyledRowLi>

                {sameCashDeposit ? (
                  <StyledRowLi>
                    Same cash rule?
                    <span>Yes</span>
                  </StyledRowLi> 
                ) : (
                  <StyledRowLi>
                    Cash: {editCoverage.cash_deposit_type}
                    {!isDepositCharge(editCoverage.cash_deposit_type) &&
                      <span>
                        {
                          coverageValue(
                            editCoverage.cash_deposit_type,
                            editCoverage.cash_deposit_coverage_value
                          )
                        }
                      </span>
                    }
                  </StyledRowLi>
                )}
              </ul>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default CoverageRuleCard;
