import React, { Component } from 'react';

interface IProps {
  checked: boolean;
  onColor: string;
  handleToggle: Function;
  name: string;
}

interface IState {
  checked: boolean;
}

class Toggle extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = props;
  }

  public onChange = (event) => {
    this.setState({ checked: event.target.checked });

    if (this.props.handleToggle) {
      this.props.handleToggle(event.target.checked);
    }
  };

  public render(): JSX.Element {
    return (
      <div>
        <input
          checked={this.state.checked}
          onChange={this.onChange}
          className="react-switch-checkbox"
          id={`react-switch-new-` + this.props.name}
          type="checkbox"
          name={this.props.name}
        />
        <label
          style={{ background: this.state.checked ? this.props.onColor : undefined }}
          className="react-switch-label"
          htmlFor={`react-switch-new-` + this.props.name}
        >
          <span className={`react-switch-button`} />
        </label>
      </div>
    );
  }
}

export default Toggle;
