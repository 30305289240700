import React from 'react';
import styled from '@emotion/styled';
import { Loading, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { CoverageTypeEnum } from '../interfaces';
import FormField from '../FormField';
import usePropertyOwner from '../PropertyOwners/usePropertyOwner';
import {
  LoadingWrapper,
  Banner,
  DisplayField,
  FormSubtitle,
  NotificationWrapper,
  InlineNotification,
  UpdateButton,
  CoverageLabel,
  CoverageValue
} from './Styled';

const PageWrapper = styled.div({ borderRight: `1px solid ${PALETTE.neutral12}`, height: '100vh' });
const Form = styled.div({ marginTop: 32 });
const Title = styled.h3([FONTS.h3, { padding: '32px 96px 0' }]);
const SubTitle = styled(FormSubtitle)({ marginBottom: 0 });

const PropertyOwnerView = ({ propertyOwnerId }: { propertyOwnerId: number }) => {
  const { handleSubmit, formField, errorMessage, data } = usePropertyOwner(propertyOwnerId);
  const { name, setName, nameError } = formField;

  return (
    <PageWrapper>
      <form onSubmit={handleSubmit} method="POST">
        {!!errorMessage?.length && (
          <NotificationWrapper>
            {errorMessage.map((message) => (
              <InlineNotification key={message} variant="highPriority" aria-label="form error message">
                {`${message}`.trim()}
              </InlineNotification>
            ))}
          </NotificationWrapper>
        )}
        <Title>Property owner</Title>
        <Banner>
          <SubTitle>Information</SubTitle>
          {!data?.actions.edit ? (
            <Form>
              <FormField
                error={nameError}
                input={
                  <input
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setName(e.target.value);
                    }}
                  />
                }
                label={<label htmlFor="name">Name</label>}
              />
            </Form>
          ) : (
            <DisplayField>
              <div id="name">{data?.name ?? ''}</div>
              <label htmlFor="name">Name</label>
            </DisplayField>
          )}

          <SubTitle>Coverage Type</SubTitle>
          {data?.default_coverage_type === CoverageTypeEnum.MULTIPLIER ? (
            <>
              <CoverageLabel>Coverage Multiplier</CoverageLabel>
              <DisplayField>
                <CoverageValue>
                  {data?.coverage_multiplier_limits?.map((multiplier) => `${Number(multiplier)}x`).join(', ') ?? ''}
                </CoverageValue>
              </DisplayField>
            </>
          ) : (
            <>
              <CoverageLabel>Dollar amount</CoverageLabel>
              <DisplayField>
                <CoverageValue>{data?.default_coverage_amount_cents ?? ''}</CoverageValue>
              </DisplayField>
            </>
          )}
          {data?.actions.edit && (
            <UpdateButton variant="primary" type="submit">
              Update
            </UpdateButton>
          )}
        </Banner>
      </form>
    </PageWrapper>
  );
};

const PropertyOwner = ({ propertyOwnerId }: { propertyOwnerId: number | undefined }) => {
  if (!propertyOwnerId) return null;
  const { isSuccess } = usePropertyOwner(propertyOwnerId);

  return isSuccess ? (
    <PropertyOwnerView propertyOwnerId={propertyOwnerId} />
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default PropertyOwner;
