/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { InlineNotification, Alert2Icon, PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  errors: string[];
}

const Errors = styled.div({
  width: '480px',
  margin: '10px auto'
});

const fadeOut = keyframes`
    0% {opacity: 1}
    25% {opacity: .6}
    50% {opacity: .4}
    100% {opacity: 0}
  `;

const ErrorItem = styled(InlineNotification)({
  width: '480px',
  borderRadius: '8px',
  margin: '10px auto',
  animation: `${fadeOut} 1s ease-out 4s`
});

const ErrorNotification = ({ errors }: IProps) => {
  return (
    <Errors>
      {errors.map((error, index) => (
        <ErrorItem key={index} variant="highPriority">
          <div style={{ color: 'unset' }}>
            <span style={{ color: 'unset' }}>{error}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', color: 'unset' }}>
            <Alert2Icon color={PALETTE.alert100} style={{ marginLeft: '8px' }} height="20px" width="20px" />
          </div>
        </ErrorItem>
      ))}
    </Errors>
  );
};

export default ErrorNotification;
