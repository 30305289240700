import axios from 'axios';
import { IWithdrawal } from 'components/v2/App/DepositFunds/types';
import { QueryObserverResult, useQuery } from 'react-query';

interface IWithdrawalsResponse {
  data: { attributes: IWithdrawal }[];
  property_owner: {
    id: string;
    name: string;
  };
  property_owner_list: {
    id: string;
    name: string;
  }[];
  meta: {
    total_count: number;
  };
}

export const withdrawalsQueryKey = 'withdrawals';

const getWithdrawals = async (page: number = 1) => {
  const params = Object.assign({}, { page });

  const { data } = await axios.get<IWithdrawalsResponse>('/admin/funds/withdrawals', {
    params
  });

  return data;
};

export default function useWithdrawals(
  {
    enabled
  }: {
    enabled: boolean;
  } = { enabled: true }
): QueryObserverResult<IWithdrawalsResponse> {
  return useQuery<IWithdrawalsResponse>([withdrawalsQueryKey], ({ pageParam }) => getWithdrawals(pageParam), {
    enabled
  });
}
