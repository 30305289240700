const CONFIRM_CSS = 'rhino_btn log-in-btn pull-right';
const CANCEL_CSS = 'tingle-btn tingle-btn--danger';
const DISABLE_CONFIRMATION = 'Are you sure you want to disable two-factor authentication?';

export default class TwoFactorDisableConfirmModal {
  private selector: string = '.disable_2fa_btn';
  private modal: any;

  constructor() {
    this.modal = new (window as any).tingle.modal({
      footer: true,
      closeMethods: []
    });

    this.modal.addFooterBtn('Cancel', CANCEL_CSS, () => {
      this.modal.close();
    });

    this.modal.addFooterBtn('Confirm', CONFIRM_CSS, () => {
      window.location.href = $(this.selector).prop('href');
    });

    $(this.selector).on('click', this.openModal);
  }

  private openModal = (e: JQuery.Event): void => {
    e.preventDefault();

    this.modal.setContent(DISABLE_CONFIRMATION);
    this.modal.open();
  }
}
