import axios, { AxiosError } from 'axios';
import { chunk } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { MutationStatus, useMutation, UseMutationResult } from 'react-query';

import { IPropertyCsvRow, PropertyCsv, IError } from 'components/tables/property_upload_table/interfaces';
import { csrfToken } from 'utils/document';
import { IUploadedProp } from 'components/v2/App/utils/propertyMemberAssign';

export interface IPropertyAndUnitIds {
  [propertyId: number]: IUploadedProp;
}

interface IBulkPropertiesUploadResult {
  property_and_unit_ids: IPropertyAndUnitIds;
}

type MutationResult = UseMutationResult<IPropertyAndUnitIds, AxiosError<IError>, IBulkPropertiesUploadParams>;

export interface IBulkPropertiesUpload {
  completed: number;
  data?: IPropertyAndUnitIds;
  error?: AxiosError<IError>;
  status: MutationStatus;
  mutate: (csv: PropertyCsv, filename: string, options?: IBulkPropertiesUploadOptions) => boolean;
}

interface IBulkPropertiesUploadParams {
  currentBatch: PropertyCsv;
  filename: string;
}
async function bulkPropertiesUpload(data: IBulkPropertiesUploadParams): Promise<IPropertyAndUnitIds> {
  const token = csrfToken();
  const payload = {
    authenticity_token: token,
    data: data.currentBatch,
    filename: data.filename
  };
  const result = await axios.post<IBulkPropertiesUploadResult>('/admin/properties/bulk_upload', payload);
  return result.data.property_and_unit_ids;
}

interface IRequestState {
  batch: number;
  status: MutationStatus;
  batches: PropertyCsv[];
  filename: string;
}

const defaultRequestState: IRequestState = {
  batch: 0,
  status: 'idle',
  batches: [],
  filename: ''
};

export interface IBulkPropertiesUploadOptions {
  onSuccess?: (data: IPropertyAndUnitIds) => void;
  onError?: (error: AxiosError<IError>) => void;
}

/**
 * Implements uploading of a potentially large CSV in batches. This was
 * designed to roughly mimic the API of react-query mutations (excluding bits
 * that weren't necessary for our use case.) I decided to take a state-machine
 * approach with React state and an effect. The general flow is:
 *
 * - The "state machine" is managed by `requestState`, which contains:
 *   - A set of batches
 *   - A current batch number
 *   - The overall status of the batch request
 * - We initiate a new request by setting the state to 'loading', chunking the
 *   input into batches no greater than the `batchSize` and setting the current
 *   batch to 0. We ignore requests with zero rows.
 * - useEffect monitors the batch and the status for changes, every time it
 *   notices a change we:
 *   - Checks status, if 'loading' we continue, otherwise stop there
 *   - Get the current batch
 *   - Dispatch a request with the batch
 *   - On success:
 *     - We recurse if more batches remain or set the status to 'success' if
 *       we are done
 *     - We merge the result from the most recent batch into our aggregate
 *       result
 *   - On error:
 *     - We set the status to 'error' and stop recurring
 *
 * The return value is described by `IBulkPropertiesUpload`. The `data` will
 * only be present if all batches have been processed successfully. If any of
 * the requests fail, we short circuit and the error from that batch is
 * returned.
 * @param batchSize The number of CSV rows to upload per request
 */
export default function usePropertiesBulkUploadMutation(batchSize: number = 50): IBulkPropertiesUpload {
  const [requestState, setRequestState] = useState(defaultRequestState);
  const { batch, batches, status, filename } = requestState;
  const [data, setData] = useState<IPropertyAndUnitIds>({});
  const uploadInfo: MutationResult = useMutation(bulkPropertiesUpload);
  const batchCount = batches.length;
  const mutateOptions = useRef<IBulkPropertiesUploadOptions>();

  useEffect(() => {
    if (status !== 'loading') {
      return;
    }

    const currentBatch = batches[batch];

    uploadInfo.mutate(
      { currentBatch, filename },
      {
        onSuccess: (newData: IPropertyAndUnitIds) => {
          const updatedData = {
            ...data,
            ...newData
          };
          setData(updatedData);

          const nextStatus = batch + 1 < batchCount ? 'loading' : 'success';
          setRequestState({
            ...requestState,
            batch: batch + 1,
            status: nextStatus
          });

          if (nextStatus === 'success' && mutateOptions.current?.onSuccess !== undefined) {
            mutateOptions.current.onSuccess(updatedData);
          }
        },
        onError: (error: AxiosError<IError>) => {
          setRequestState({
            ...requestState,
            status: 'error'
          });

          if (mutateOptions.current?.onError !== undefined) {
            mutateOptions.current.onError(error);
          }
        }
      }
    );
  }, [batch, status]);

  function mutate(csv: PropertyCsv, filename: string, options: IBulkPropertiesUploadOptions = {}): boolean {
    const batches: IPropertyCsvRow[][] = chunk(csv, batchSize);
    if (status === 'loading' || batches.length === 0) {
      return false;
    }

    mutateOptions.current = options;
    setRequestState({
      batch: 0,
      status: 'loading',
      filename: filename,
      batches
    });

    return true;
  }

  return {
    completed: batch,
    mutate,
    status,
    data: status === 'success' ? data : undefined,
    error: uploadInfo.error || undefined
  };
}
