import ApplicationTable from './application_table';

class UserPropertiesTable extends ApplicationTable {

  public selector: string = '#user-properties-table';

  public pageLength: number = 25;

  public emptyMessageText = 'You have no properties to view';

  public pathPrefix = 'properties/';

  public getColumns(): DataTables.ColumnSettings[] {
    const columns: DataTables.ColumnSettings[] = [];

    columns.push(
      {data: 'id'},
      {data: 'building_name'},
      {data: 'address_line_one'},
      {data: 'owner_name'}
    );

    if (this.canEdit()) {
      columns.push({data: 'edit'});
    }

    if (this.canDestroy()) {
      columns.push({data: 'delete'});
    }
    return columns;
  }
}

export default UserPropertiesTable;
