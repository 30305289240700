import { IData, IPaginatedMeta, IPMAPropertyEntities } from 'components/v2/App/interfaces';
import axios from 'axios';
import { stringify } from 'qs';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

const getPMAPropertyOwners = async (page: number, per: number) => {
  const params = { page, per };
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data } = await axios.get<IData<IPMAPropertyEntities[], IPaginatedMeta>>(`/v2/admin/policy_holders`, {
    params,
    paramsSerializer
  });
  return data;
};

export const PMAPropertyEntitiesKey = 'PMAPropertyEntities';

export default function usePMAPropertyEntities(
  isPropertyManagerAdmin: boolean,
  isPropertyManager: boolean,
  per: number = 20
): InfiniteQueryObserverResult<IData<IPMAPropertyEntities[], IPaginatedMeta>> | undefined {
  if (isPropertyManagerAdmin || isPropertyManager) {
    return useInfiniteQuery<IData<IPMAPropertyEntities[], IPaginatedMeta>>(
      [PMAPropertyEntitiesKey],
      ({ pageParam = 1 }) => getPMAPropertyOwners(pageParam, per),
      {
        getNextPageParam: (lastGroup) => lastGroup.meta.next_page
      }
    );
  }
}
