import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button as ButtonBase, Card, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import NewTeamMemberForm from './NewTeamMemberForm';
import { NewTeamMemberFormValues, TeamMemberType } from './NewTeamMember';
import PropertyTags from './PropertyTags';

const cardStyle = {
  height: 'auto',
  border: `1px solid ${PALETTE.neutral12}`,
  padding: '40px 48px',
  cursor: 'auto',
  margin: '0 auto 32px',
  width: '600px'
};
const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '4px'
});
const Title = styled.h4([FONTS.h4]);
const FormTitle = styled(Title)({ color: PALETTE.brand100 });
const Text = styled.p([FONTS.p1]);
const SecondaryButton = styled(ButtonBase)({
  border: 'none',
  textDecoration: 'underline',
  '&:hover': {
    background: 'none',
    color: PALETTE.neutralDark
  }
});

export interface IProps {
  handleUpdate: (newTeamMember: NewTeamMemberFormValues, idx: number) => void;
  handleDelete: (idx: number) => void;
  teamMember: NewTeamMemberFormValues;
  idx: number;
}

const TeamMember: React.FC<IProps> = ({ teamMember, idx, handleUpdate, handleDelete }: IProps) => {
  const [showForm, setShowForm] = useState(false);
  const handleClick = (newTeamMember: NewTeamMemberFormValues) => {
    setShowForm(false);
    handleUpdate(newTeamMember, idx);
  };

  const displayTeamMember = (teamMember) => {
    const isLeasingAgent = teamMember.type === TeamMemberType.LISTING_AGENT;
    const isPropertyManagerAdmin = teamMember.type === TeamMemberType.PROPERTY_MANAGER_ADMIN;
    if (isLeasingAgent) {
      return 'Leasing Agent';
    }
    if (isPropertyManagerAdmin) {
      return 'Property Manager Admin';
    }
    return 'Property Manager';
  };

  return (
    <Card variant="border" style={cardStyle}>
      {showForm ? (
        <>
          <FormTitle>Update new team member</FormTitle>
          <NewTeamMemberForm
            handleClick={handleClick}
            handleDelete={() => handleDelete(idx)}
            initialValues={teamMember}
            buttonText="Update"
          />
        </>
      ) : (
        <>
          <Header>
            <Title>{teamMember.name}</Title>
            <SecondaryButton variant="tertiary" onClick={() => setShowForm(true)}>
              Edit
            </SecondaryButton>
          </Header>
          <Text>{displayTeamMember(teamMember)}</Text>
          <Text>{teamMember.email}</Text>
          <Text>{teamMember.phone}</Text>
          <PropertyTags properties={teamMember.properties} />
        </>
      )}
    </Card>
  );
};

export default TeamMember;
