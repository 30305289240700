import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  FONTS,
  ButtonHabitat,
  DateInput as DateInputBase,
  Card,
  TextInput,
  Select,
  Switch,
  ROUNDNESS,
  PALETTE
} from '@sayrhino/rhino-shared-js';
import { SITES_KEY, useAddSite, useUpdateSite, ISite } from 'api/v2/useSites';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';
import { AxiosError } from 'axios';
import { IPartnersNameOptions } from './SitesTable';
import AsyncSelect from 'components/v2/select/AsyncSelect';

const CardHeader = styled.h5(FONTS.h5);

const StyledCardDetails = styled(Card)({
  padding: '1rem',
  height: 'auto !important',
  overflow: 'hidden',
  cursor: 'auto',
  marginBottom: 24
});

const ButtonRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const Row = styled(ButtonRow)({
  gap: '16px',
  justifyContent: 'flex-end',
  width: '100%'
});

const InputArea = styled.div({
  margin: '16px 0'
});

export enum Status {
  Add = 'add',
  Edit = 'edit'
}

type IProp = {
  flipView: () => void;
  status: Status;
  site?: ISite;
  partnerNameOptions: IPartnersNameOptions[];
};

const typeOptions = [
  { value: 'realpage', label: 'RealPage' },
  { value: 'eqr::Partner', label: 'EQR' }
];

const Wrapper = styled.div([
  ROUNDNESS.s,
  {
    border: `1px solid ${PALETTE.neutral25}`,
    padding: '0 4px',
    margin: '4px 0 10px 0'
  }
]);

const Label = styled.label([
  FONTS.labelMedium,
  {
    fontSize: '12px',
    lineHeight: '20px',
    color: PALETTE.neutral65
  }
]);

const SitesCard: React.FC<IProp> = ({ flipView, status, site, partnerNameOptions }) => {
  const [name, setName] = useState<string>(site?.name ?? '');
  // const [partnerName, setPartnersName] = useState<IPartnersNameOptions | undefined>(partnerNameOptions.find((label) => label.name === site?.label));
  const [type, setType] = useState<{ label: string; value: string } | undefined>(
    typeOptions.find((option) => option.value === site?.type)
  );

  const [enabled, setEnabled] = useState<boolean>(site?.enabled ?? false);
  const [externalId, setExternalId] = useState<string>(site?.external_id ?? '');
  const [errors, setErrors] = useState({});
  const { addToast } = useToast();
  const { claimsId } = useParams();
  // const saving = false;
  // const editing = false;
  const { addSite, isLoading: saving } = useAddSite();
  const { editSite, isLoading: editing } = useUpdateSite();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<{ label: string; value: number } | null>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (site?.partner) setSelected(partnerNameOptions.find((e) => e.label === site.partner.name)!);
  }, [site]);

  const disableActions = () => {
    return saving || editing;
  };

  const isFormEmpty = () => {
    return !Boolean(name) || !String(externalId);
  };

  const save = async () => {
    const data = {
      formInfo: {
        name,
        enabled,
        type: type?.value,
        partner_type: type?.value,
        partner: {
          id: selected?.value,
          type: type?.value
        },
        external_id: externalId
      }
    };

    addSite(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries([SITES_KEY, Number(claimsId)]);
        flipView();
        addToast('Site added');
      },
      onError: (err: AxiosError) => {
        const { errors } = err?.response?.data;
        setErrors(errors);

        if (errors.claim && !errors.amount_paid_cents) {
          addToast(`Claim ${errors.claim}`, TOAST_STATUS.ERROR);
        }
      }
    });
  };

  const edit = async () => {
    const data = {
      siteId: site?.id,
      formInfo: {
        name,
        enabled,
        type: type?.value,
        partner_type: type?.value,
        external_id: externalId,
        partner_id: selected?.value
      }
    };

    editSite(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries([SITES_KEY, Number(claimsId)]);
        flipView();
        addToast('Site updated');
      },
      onError: (err: AxiosError) => {
        const { errors } = err?.response?.data;
        setErrors(errors);

        if (errors.claim) {
          addToast(`Claim ${errors.claim}`, TOAST_STATUS.ERROR);
        }
      }
    });
  };

  const loadOptions = (input: string, callback: Function) => {
    callback(
      partnerNameOptions.filter(({ label }) => label.toLocaleLowerCase().includes(input.toLowerCase())),
      input
    );
  };

  const filterByPartnerName = async (selectSite) => {
    setSelected(selectSite);
  };

  return (
    <StyledCardDetails>
      <CardHeader>{site && Object.keys(site).length > 0 ? 'Edit' : 'Add'} Site</CardHeader>
      <InputArea>
        <Label htmlFor="filter_by_partner">Filter by Partner</Label>
        <Wrapper id="filter_by_partner">
          <AsyncSelect
            name="filter_partner_name"
            id="filter_partner_name"
            getOptionLabel={(options) => options.label}
            getOptionValue={(options) => options.value}
            value={selected}
            isClearable={true}
            placeholder="Partner name"
            loadOptions={loadOptions}
            onChange={filterByPartnerName}
          />
        </Wrapper>

        <Select
          id="partner_type"
          label="Partner Type"
          onChange={(option: { label: string; value: string }) => {
            setType(option);
          }}
          options={typeOptions}
          value={type}
        />

        <TextInput
          id="name"
          label="Site Name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
        <TextInput
          id="externalId"
          label="Site External Id"
          onChange={(e) => {
            setExternalId(e.target.value);
          }}
          value={externalId}
        />

        <div>
          <label htmlFor="site-enabled" style={{ paddingRight: 15 }}>
            Enabled
          </label>
          <Switch id="site-enabled" checked={enabled} onCheckedChange={() => setEnabled(!enabled)} />
        </div>
      </InputArea>
      <ButtonRow>
        <Row>
          <ButtonHabitat
            id="cancel"
            variant="interaction"
            size="small"
            usage="primary"
            onClick={flipView}
            disabled={disableActions()}
          >
            Cancel
          </ButtonHabitat>
          <ButtonHabitat
            id="save"
            variant="interaction"
            size="small"
            usage="primary"
            onClick={site ? edit : save}
            disabled={disableActions() || isFormEmpty()}
          >
            {saving || editing ? 'Saving' : 'Save Changes'}
          </ButtonHabitat>
        </Row>
      </ButtonRow>
    </StyledCardDetails>
  );
};

export default SitesCard;
