/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import {
  ArrowLeft
} from '@sayrhino/rhino-shared-js';

import { SortOrder } from './interfaces';

const styles = {
  orderLabel: css({
    display: 'flex',
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  lastModified: css({
    paddingLeft: '20px'
  }),
  arrowDown: css({
    transform: 'rotate(-90deg)',
    margin: '-2px 0px 0px 2px'
  }),
  arrowUp: css({
    transform: 'rotate(90deg)',
    margin: '-2px 0px 0px 2px'
  })
};

interface IProps {
  value: SortOrder;
  onChange: (ISortOrder) => void;
}

const SortToggle: React.FC<IProps> = ({ value, onChange }) => (
  <div css={{ display: 'flex' }}>
    <div css={styles.orderLabel}>
      <p onClick={() => onChange(0)}>
        Latest
      </p>
      <ArrowLeft css={value === 0 ? styles.arrowDown : styles.arrowUp} />
    </div>
    <div css={styles.orderLabel}>
      <p css={styles.lastModified} onClick={() => onChange(1)}>
        Last Modified
      </p>
      <ArrowLeft css={value === 1 ? styles.arrowDown : styles.arrowUp} />
    </div>
  </div>
);

export default SortToggle;
