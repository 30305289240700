import React from 'react';
import DashboardPanelFigure from './DashboardPanelFigure';
import DashboardPanelLink from './DashboardPanelLink';
import DashboardPanelPillLink from './DashboardPanelPillLink';
import DashboardPanelProgressFigure from './DashboardPanelProgressFigure';
import { IAdminSummaryMetricsData } from './interfaces';
import { formatDays } from './utils';
import {
  IN_PROGRESS_INVITATIONS_TITLE,
  SIGN_UP_RATE_DESCRIPTION,
  SIGN_UP_RATE_LINK_TEXT,
  SIGN_UP_RATE_TITLE,
  TOTAL_INVITATIONS_TITLE
} from './utils/constants';

const AdminDashboardPanelItems = ({ data }: { data: IAdminSummaryMetricsData }) => {
  const invitationsMonthlySignupRate: number = Math.round(
    ((0.0 + data.invitations_completed_this_month_count) / data.invitations_this_month_total_count) * 100
  );

  return (
    <>
      <DashboardPanelProgressFigure
        title="Monthly subs goal"
        numerator={data.new_subscribers_this_month_count}
        denominator={data.new_subscribers_monthly_goal}
      />
      {data.invitations_completed_this_month_count && data.invitations_this_month_total_count ? (
        <DashboardPanelFigure
          title={SIGN_UP_RATE_TITLE}
          description={SIGN_UP_RATE_DESCRIPTION}
          value={isNaN(invitationsMonthlySignupRate) ? 'N/A' : `${invitationsMonthlySignupRate}%`}
          updatedDate={new Date()}
        />
      ) : (
        <DashboardPanelLink
          description={SIGN_UP_RATE_DESCRIPTION}
          linkText={SIGN_UP_RATE_LINK_TEXT}
          title={SIGN_UP_RATE_TITLE}
          to="/admin/invitations/new"
          useServer={false}
        />
      )}
      {!!data.average_days_to_first_renter_ytd && (
        <DashboardPanelFigure
          title="Average time to first renter"
          value={formatDays(data.average_days_to_first_renter_ytd)}
          updatedDate={new Date()}
        />
      )}
      {!!data.average_days_to_claim_payout_ytd && (
        <DashboardPanelFigure
          title="Average time to claim payout"
          value={formatDays(data.average_days_to_claim_payout_ytd)}
          updatedDate={new Date()}
        />
      )}
      <DashboardPanelPillLink
        to="/admin/invitations/filters?status=started"
        title={IN_PROGRESS_INVITATIONS_TITLE}
        value={data.invitations_started_total_count || 0}
      />
      <DashboardPanelPillLink
        to="/admin/invitations"
        title={TOTAL_INVITATIONS_TITLE}
        value={data.invitations_total_count || 0}
      />
      <DashboardPanelPillLink
        to="/admin/claims?status=new&table=true"
        title="New claims"
        value={data.new_claims_this_month_count || 0}
        useServer={true}
      />
    </>
  );
};

export default AdminDashboardPanelItems;
