import React from 'react';
import { render, screen, fireEvent } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { InvitationDetailsEditView } from '../InvitationDetailsEdit';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';

let server;

describe('<InvitationDetialsEdit/> level invitation edit interactions', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the invitation edit page', async () => {
    render(
      <Wrapper initialEntries={['/admin/invitations/36/edit']}>
        <InvitationDetailsEditView />
      </Wrapper>
    );
    const heading = await screen.findByRole('heading', { level: 4, name: /Edit invitation/i });

    expect(heading).toBeDefined();
    expect(heading).toHaveTextContent('Edit invitation');
  });

  test('It should allow for rent to be updated', async () => {
    render(
      <Wrapper initialEntries={['/admin/invitations/36/edit']}>
        <InvitationDetailsEditView />
      </Wrapper>
    );

    const rentField = await screen.getByLabelText(/Monthly rent/i);
    userEvent.type(rentField, '1500');
    const rentInput = await screen.getByRole('textbox', { name: /monthly rent/i });
    expect(rentInput).toHaveValue('1,500');
  });

  test('It should allow for first & last name to be updated', async () => {
    render(
      <Wrapper initialEntries={['/admin/invitations/36/edit']}>
        <InvitationDetailsEditView />
      </Wrapper>
    );

    const lastName = await screen.getByLabelText(/Last Name/i);
    const firstName = await screen.getByLabelText(/First Name/i);
    userEvent.type(lastName, 'Davidson');
    userEvent.type(firstName, 'Dave');
    const lastNameInput = await screen.getByRole('textbox', { name: /last name/i });
    expect(lastNameInput).toHaveValue('Davidson');
    const firstNameInput = await screen.getByRole('textbox', { name: /first name/i });
    expect(firstNameInput).toHaveValue('Dave');
  });

  test('View Monthly rent error', async () => {
    render(
      <Wrapper initialEntries={['/admin/invitations/36/edit']}>
        <InvitationDetailsEditView />
      </Wrapper>
    );

    const rentField = await screen.getByLabelText(/Monthly rent/i);
    userEvent.type(rentField, '0');
    const rentInput = await screen.getByRole('textbox', { name: /monthly rent/i });
    expect(rentInput).toHaveValue('0');
    const updateButton = await screen.findByRole('button', { name: 'Update Invitation' });
    userEvent.click(updateButton);
    const rentError = await screen.queryByText('must be greater than $100');
    expect(rentError).toBeDefined();
  });
});
