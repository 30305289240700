import React from 'react';
import styled from '@emotion/styled';
import { FileIcon, DeleteIcon, PALETTE } from '@sayrhino/rhino-shared-js';
import { AttachmentFileNameProps } from './interfaces';

const FileIconDiv = styled.img({
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer'
  },
  verticalAlign: 'middle'
});

const FileName = styled.button<AttachmentFileNameProps>(
  {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: 0,
    backgroundColor: 'transparent',
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:active': {
      outlineStyle: 'auto'
    },
    '&:focus': {
      outlineStyle: 'auto'
    }
  },
  ({ isSelected }) =>
    isSelected && {
      fontWeight: 'bold'
    }
);

const FileItemDiv = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 12px',
  width: '75%',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: 4,
  backgroundColor: PALETTE.neutralLight
});

const Button = styled.button({
  border: 'none',
  background: 'transparent',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center'
});

export interface Attachment {
  id: number;
  filename: string;
  url: string;
}

interface AttachmentFileItemProps {
  attachment: Attachment;
  removeAttachment?: (e, attachment) => void;
}

const AttachmentFileItem: React.FC<AttachmentFileItemProps> = ({ attachment, removeAttachment }) => {
  return (
    <FileItemDiv key={attachment.filename}>
      <a href={attachment.url} target='_blank' rel="noopener noreferrer">
        <FileIconDiv src={attachment.url} width={75} height={25} />
        <FileName>{attachment.filename}</FileName>
      </a>
      {removeAttachment && (
        <Button
          onClick={(e) => removeAttachment(e, attachment)}
          name={attachment.filename}
          aria-label={`remove-${attachment.filename}`}
        >
          <DeleteIcon css={{ ':hover': { color: PALETTE.alert100 } }} />
        </Button>
      )}
    </FileItemDiv>
  );
};

export default AttachmentFileItem;
