import React from 'react';
import { useParams } from 'react-router-dom';
import { useUserRole } from '../../utils/userRole';
import {
  Splitter,
  StyledCardDetails,
  StyledSubroSplitter,
  StyledSubroHeaderDiv,
  StyledSubroText,
  StyledEditPaymentLink,
  StyledEditPaymentDiv
} from './ClaimStyles';

export const SubrogationDetails = () => {
  const { claimsId } = useParams();
  const userRole = useUserRole();
  const { isThirdPartyAdmin } = userRole;


  return (
    <>
      <StyledSubroSplitter />
      <StyledCardDetails variant="border">
        <StyledSubroHeaderDiv>
          <StyledSubroText>Subrogation Details</StyledSubroText>
        </StyledSubroHeaderDiv>
        <Splitter />
        <StyledEditPaymentDiv>
          <StyledEditPaymentLink to={`/admin/claims/${claimsId}/subrogation_payments`}>
            {isThirdPartyAdmin ? "View Payments" : "Edit Payments"}
          </StyledEditPaymentLink>
        </StyledEditPaymentDiv>
      </StyledCardDetails>
    </>
  );
};
