import React from 'react';
import styled from '@emotion/styled';
import { Radio, FONTS, Select, PALETTE } from '@sayrhino/rhino-shared-js';
import ReactTooltip from 'react-tooltip';
import useRentersInsuranceLiabilityCoverage from 'api/v2/useRentersInsuranceLiabilityCoverage';
import TooltipInfoIcon from '../TooltipInfoIcon';
import useUserRole from '../utils/userRole';

const FormHeader = styled.h5([FONTS.h5], { marginBottom: '24px' });

const Wrapper = styled.div({
  paddingTop: '20px',
  marginBottom: '30px'
});

const SelectWrapper = styled.div({
  marginTop: '24px',
  marginLeft: '42px',
  '#react-select-3-placeholder': {
    color: PALETTE.neutral55
  }
});

const StyledLabel = styled.label([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral65,
    display: 'flex',
    alignItems: 'center'
  }
]);

const Tooltip = styled(ReactTooltip)({
  padding: '12px !important',
  border: `1px solid ${PALETTE.neutral12} !important`,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16) !important',
  borderRadius: '8px !important',
  opacity: '10 !important',
  width: '238px !important',
  '&:hover': {
    visibility: 'visible'
  }
});

const TooltipText = styled.span([[FONTS.p3]]);

const TooltipIconContainer = styled.div({
  display: 'flex',
  marginLeft: '5px',
  'svg:hover': {
    color: PALETTE.interaction100
  }
});

const Header = styled.h4([FONTS.h4], {
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  paddingBottom: '8px',
  marginTop: '48px'
});

const Subtext = styled.p([FONTS.p2], {
  margin: '24px 0'
});

const TooltipContainer = styled.div({ display: 'flex', alignItems: 'center' });

const LineBreak = styled.div({
  marginTop: '5px'
});

const RadioWrapper = styled.div({ marginTop: '40px' });

const ExclusiveAgreementContainer = styled.div([FONTS.h6Medium], {
  backgroundColor: PALETTE.neutral4,
  padding: '12px 17px 12px 24px',
  marginTop: '24px'
});

// temp fix for Radio.Root props issue
const RadioRoot: any = Radio.Root;

const RentersInsuranceRequirementForm = ({
  liabilityCoverage,
  setLiabilityCoverage,
  setRIRequiredSelection,
  RIRequiredSelection,
  getDefaultLiabilitySelection,
  exclusiveMarketingAgreementExists,
  setExclusiveMarketingAgreementExists
}) => {
  const { isAdmin, isPropertyManagerAdmin } = useUserRole();

  const { data } = useRentersInsuranceLiabilityCoverage();

  const isRIRequired = RIRequiredSelection === 'yes';

  const getFormHeader = () =>
    isPropertyManagerAdmin
      ? `Do you require renters insurance for each of your renters?`
      : `Does the partner require renters insurance for each renter?`;

  const getFormSubtext = () =>
    isPropertyManagerAdmin
      ? `If you require it, we offer renters insurance to your renters at a 20% discount and alert you if a renter signs
        up for coverage.`
      : `Please designate the partner's renters insurance preferences.`;

  return (
    <Wrapper>
      <Header>
        Renters Insurance
        {isPropertyManagerAdmin && (
          <TooltipContainer>
            <TooltipIconContainer data-tip data-for="renters-insurance-info-tooltip">
              <TooltipInfoIcon />
            </TooltipIconContainer>
            <Tooltip id="renters-insurance-info-tooltip" type="light" effect="solid" place="bottom">
              <TooltipText>
                Rhino offers renters insurance for $15,000 in contents coverage and $100,000 in liability coverage for
                your protection.
              </TooltipText>
            </Tooltip>
          </TooltipContainer>
        )}
      </Header>
      <Subtext>{getFormSubtext()}</Subtext>
      <FormHeader>{getFormHeader()}</FormHeader>
      <RadioRoot
        aria-label="Do you require renters insurance for each of your renters?"
        name="ri_required"
        value={RIRequiredSelection}
        onValueChange={(value) => {
          setRIRequiredSelection(value);
        }}
      >
        <Radio.Option data-cy="RentersInsuranceIsRequired" value="yes" label="Yes" />
        {isRIRequired && (
          <SelectWrapper>
            <StyledLabel htmlFor="select-coverage-amount">
              Required liability coverage amount
              {isPropertyManagerAdmin && (
                <TooltipContainer>
                  <TooltipIconContainer data-tip data-for="renters-insurance-liability-info-tooltip">
                    <TooltipInfoIcon />
                  </TooltipIconContainer>
                  <Tooltip id="renters-insurance-liability-info-tooltip" type="light" effect="solid" place="bottom">
                    <TooltipText>
                      Please share your property's desired liability coverage amount, if you have one.
                      <LineBreak />
                      We'll use this information to help us offer customizable coverage amounts in the future.
                    </TooltipText>
                  </Tooltip>
                </TooltipContainer>
              )}
            </StyledLabel>
            <Select
              placeholder="Select from dropdown"
              defaultValue={liabilityCoverage || getDefaultLiabilitySelection()}
              onChange={(selection) => {
                setLiabilityCoverage(selection);
              }}
              options={data?.liability_coverages}
              id="select-coverage-amount"
            />
          </SelectWrapper>
        )}
        <Radio.Option data-cy="RentersInsuranceIsNotRequired" value="no" label="No" />
      </RadioRoot>
      {isAdmin && (
        <>
          <RadioWrapper>
            <FormHeader>
              Does the partner have an exclusive marketing agreement with a renters insurance provider?
            </FormHeader>
            <RadioRoot
              name="exclusive-marketing-agreement"
              value={exclusiveMarketingAgreementExists}
              onValueChange={(value) => {
                setExclusiveMarketingAgreementExists(value);
              }}
            >
              <Radio.Option data-cy="ExclusiveMarketingAgreementExists" value="yes" label="Yes" />
              <Radio.Option data-cy="ExclusiveMarketingAgreementDoesNotExist" value="no" label="No" />
            </RadioRoot>
          </RadioWrapper>
          {exclusiveMarketingAgreementExists === 'yes' && (
            <ExclusiveAgreementContainer>
              Since an exclusive agreement exists, we will not offer Rhino Renters Insurance to their partners' renters.
            </ExclusiveAgreementContainer>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default RentersInsuranceRequirementForm;
