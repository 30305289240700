import styled from '@emotion/styled';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import '@reach/combobox/styles.css';
import React, { useState } from 'react';
import { PALETTE, FONT_FAMILY, FONTS, ButtonHabitat } from '@sayrhino/rhino-shared-js';
import { getPropertiesByPropertyOwner } from 'api/v2/usePropertySearch';
import { useQueryClient } from 'react-query';
import { IPropertySearchResult } from '../interfaces';
import { useParams } from 'react-router-dom';

type styledProps = { error?: boolean };
const StyledWrapper = styled.div<styledProps>(({ error }) => ({
  width: '100%',
  marginBottom: '24px',
  '.property-multiselect__control': error
    ? {
        borderColor: 'transparent',
        boxShadow: `inset 0 0 0 1px ${PALETTE.alert125}`,
        backgroundColor: PALETTE.alert4,
        '&:hover': {
          boxShadow: `inset 0 0 0 1px ${PALETTE.alert125}`,
          color: PALETTE.alert125
        },

        '&:focus-within': {
          borderColor: PALETTE.alert125,
          boxShadow: `inset 0 0 0 1px ${PALETTE.alert125}`,
          backgroundColor: PALETTE.neutralLight
        }
      }
    : {}
}));
const StyledLabel = styled.label<styledProps>(({ error }) => [
  FONTS.p3Medium,
  { color: error ? PALETTE.alert125 : PALETTE.neutral65 }
]);
const StyledErrorMessage = styled.label([FONTS.p3Light, { color: PALETTE.alert125, whiteSpace: 'pre-line' }]);
const BuildingName = styled.span([FONT_FAMILY.extendedDemi]);
const InsideDropdownButtonStyled = styled.div({ marginLeft: 'auto' });

interface IProps {
  id: string;
  selectedProperties?: IPropertySearchResult[];
  onChange: (value: IPropertySearchResult[]) => void;
  error: string;
}
const styles = {
  control: (styles) => ({
    ...styles,
    display: 'flex',
    border: `solid 1px ${PALETTE.neutral25}`,
    borderRadius: '4px',
    justifyContent: 'center',
    boxShadow: PALETTE.neutral25,
    ':hover': {
      borderColor: 'transparent',
      boxShadow: `inset 0 0 0 1px ${PALETTE.neutral88}`,
      color: PALETTE.neutral88
    },
    ':focus-within': {
      borderColor: PALETTE.interaction100,
      boxShadow: `inset 0 0 0 1px ${PALETTE.interaction100}`
    }
  })
};

const PropertyMultiSelect: React.FC<IProps> = ({ selectedProperties, onChange, id, error }) => {
  const { propertyOwnerId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const loadProperties = async (input: string) => {
    if (input.length >= 3) {
      setIsLoading(() => true);
      try {
        const properties = await queryClient.fetchQuery(['propertySearch', input], () =>
          getPropertiesByPropertyOwner(input, Number(propertyOwnerId))
        );
        setIsLoading(() => false);
        return properties;
      } catch (error) {
        setIsLoading(() => false);
        return [];
      }
    }
  };

  const ifThisItemFirstAppearance = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const selectPropertiesByBuildingName = async (oldOptions, selectedOption) => {
    let properties = await queryClient.fetchQuery(['propertySearch', selectedOption.building_name], () =>
      getPropertiesByPropertyOwner(selectedOption.building_name, Number(propertyOwnerId), 1000)
    );
    properties = properties.filter((e) => e.building_name == selectedOption.building_name);
    const options = oldOptions !== undefined ? properties.concat(oldOptions) : properties;
    const setOptions = options.filter(ifThisItemFirstAppearance);

    onChange(setOptions);
    setIsButtonDisabled(false);
  };

  return (
    <StyledWrapper error={!!error}>
      <StyledLabel error={!!error} htmlFor={id}>
        Properties
      </StyledLabel>
      <AsyncSelect
        name={id}
        id={id + '-container'}
        inputId={id}
        instanceId={id}
        classNamePrefix="property-multiselect"
        getOptionLabel={(options) => options.address_line_one}
        getOptionValue={(options) => options.id}
        value={selectedProperties}
        isClearable={true}
        isLoading={isLoading}
        placeholder=""
        loadOptions={loadProperties}
        onChange={(value) => onChange(value)}
        isOptionDisabled={(option) => isButtonDisabled}
        formatOptionLabel={(option, { context }) => {
          return context === 'menu' ? (
            <>
              <BuildingName>{option.building_name}</BuildingName>
              {option.address_line_one ? `: ${option.address_line_one} ${option.address_zip}` : ''}
              <InsideDropdownButtonStyled>
                <ButtonHabitat
                  id="all-building-name"
                  size="small"
                  usage="primary"
                  type="button"
                  onMouseOver={() => setIsButtonDisabled(true)}
                  onMouseOut={() => setIsButtonDisabled(false)}
                  onClick={() => selectPropertiesByBuildingName(selectedProperties, option)}
                >
                  All building name
                </ButtonHabitat>
              </InsideDropdownButtonStyled>
            </>
          ) : (
            option.address_line_one
          );
        }}
        isMulti
        styles={styles}
      />
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </StyledWrapper>
  );
};

export default PropertyMultiSelect;
