import React from 'react';
import { render, screen, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import ClaimBatchAssignPanel from '../ClaimBatchAssignPanel';
import { ClaimContext } from '../utils/claimBatchAssign';

let server;

interface IClaim {
  administrator: string;
  claim_type: string;
  id: number | undefined;
  property_address_line_one: string;
  renter_full_name: string;
}

interface IAnalyst {
  analystID: number | undefined;
  analystName: string;
}

interface ClaimContext {
  inBatchAssignMode: boolean;
  setBatchAssignMode(): void;
  batchedClaims: IClaim[];
  setBatchSelectedClaims(claim?: IClaim): void;
  selectedAnalyst: IAnalyst;
  setSelectedAnalyst(analyst: IAnalyst): void;
  setBatchedClaims(claims: IClaim[]): void;
}

describe('<ClaimBatchAssignPanel />', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();

    render(
      <Wrapper>
        <ClaimContext.Provider value={claimContext}>
          <ClaimBatchAssignPanel />
        </ClaimContext.Provider>
      </Wrapper>
    );
  });

  const claimContext: ClaimContext = {
    inBatchAssignMode: false,
    setBatchAssignMode: () => undefined,
    batchedClaims: [
      {
        administrator: 'John Smith',
        claim_type: 'Loss of rent',
        id: 1,
        property_address_line_one: '111 Maple St',
        renter_full_name: 'Susan Smith'
      }
    ],
    setBatchSelectedClaims: jest.fn(),
    selectedAnalyst: { analystID: 2, analystName: 'John Smith' },
    setSelectedAnalyst: ({}) => undefined,
    setBatchedClaims: jest.fn()
  };

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the claim batch assign panel', async () => {
    const heading = await screen.findByRole('heading', { level: 5, name: /Assign claims/i });
    const assignClaimsButton = await screen.getByRole('button', { name: 'Assign Claims' });

    expect(heading).toBeDefined();

    expect(assignClaimsButton).toBeDefined();
  });

  test('It should allow for claims analyst to be updated', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select analyst' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
    });
  });

  // TODO -- update once tests for right panel ClaimList claim batch assign functionality
  // are written (both panels use ClaimContext Provider)
  // test('It should allow for claims to be cleared', async () => {
  //   const clearAll = screen.getByLabelText(/^clear all$/);
  //   expect(clearAll).toBeDefined();

  //   let batchedClaim = screen.queryByTestId('claim');
  //   expect(batchedClaim).toBeDefined();

  //   fireEvent.click(clearAll);

  //   expect(batchedClaim).not.toBeDefined();
  // });

  // test('It should allow for individual claims to be cleared', async () => {
  // });

  test('It should bulk assign claims', async () => {
    const assignButton = await screen.getByRole('button', { name: 'Assign Claims' });
    expect(assignButton).toBeDefined();
    expect(assignButton).not.toBeDisabled();
    userEvent.click(assignButton);
  });
});
