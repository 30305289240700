export const invitations = {
  data: [
    {
      type: 'Invitation',
      id: 37,
      attributes: {
        address_state: 'CO',
        address_unit: '1011',
        building_name: 'The Pullman',
        coverage_amount: '$500',
        coverage_dates: 'Dec 15, 2020 - Dec 14, 2021',
        coverage_months: '0x',
        monthly_rent: '$0',
        required_coverage: 500,
        status: 'Sent',
        tenant_full_name: 'Emmerson Neubelt'
      }
    },
    {
      type: 'Invitation',
      id: 36,
      attributes: {
        address_state: 'CO',
        address_unit: '1003',
        building_name: 'The Pullman',
        coverage_amount: '0',
        coverage_dates: 'Dec 13, 2020 - Dec 12, 2021',
        coverage_months: '2.5x',
        monthly_rent: '$3,800',
        required_coverage: 0,
        status: 'Sent',
        tenant_full_name: 'Lexi Bell'
      }
    },
    {
      type: 'Invitation',
      id: 35,
      attributes: {
        address_state: 'CO',
        address_unit: '1720',
        building_name: 'Zia',
        coverage_amount: '$150',
        coverage_dates: 'Dec 15, 2020 - Dec 14, 2021',
        coverage_months: '0x',
        monthly_rent: '$2,700',
        required_coverage: 150,
        status: 'In Progress',
        tenant_full_name: 'Cora Feenan'
      }
    },
    {
      type: 'Invitation',
      id: 34,
      attributes: {
        address_state: 'CO',
        address_unit: '1721',
        building_name: 'Zia',
        coverage_amount: '$0',
        coverage_dates: 'Dec 15, 2020 - Dec 14, 2021',
        coverage_months: '1x',
        monthly_rent: '$0',
        required_coverage: 0,
        status: 'In Progress',
        tenant_full_name: 'Ellie Nelson'
      }
    },
    {
      type: 'Invitation',
      id: 33,
      attributes: {
        address_state: 'CO',
        address_unit: '434',
        building_name: 'Modera',
        coverage_amount: '$600',
        coverage_dates: 'Oct 13, 2020 - Oct 12, 2021',
        coverage_months: '0x',
        monthly_rent: '$2,800',
        required_coverage: 600,
        status: 'Subscribed',
        tenant_full_name: 'Matthew Crawley'
      }
    },
    {
      type: 'Invitation',
      id: 32,
      attributes: {
        address_state: 'CO',
        address_unit: null,
        building_name: 'Modera',
        coverage_amount: '$600',
        coverage_dates: 'Nov 13, 2020 - Nov 12, 2021',
        coverage_months: '0x',
        monthly_rent: '$2,800',
        required_coverage: 600,
        status: 'Subscribed',
        tenant_email: 'sybil@downton.org',
        tenant_full_name: ' '
      }
    }
  ],
  meta: { total_count: 22, next_page: 2, page: '1', per: 20 }
};

export const invitations2 = {
  data: [
    {
      type: 'Invitation',
      id: 31,
      attributes: {
        address_state: 'TX',
        address_unit: '4053',
        building_name: 'Amerika',
        coverage_amount: '0',
        coverage_dates: '',
        coverage_months: '4x',
        monthly_rent: '$5,666',
        required_coverage: 0,
        status: 'Subscribed',
        tenant_full_name: 'John Smithik'
      }
    },
    {
      type: 'Invitation',
      id: 30,
      attributes: {
        address_state: 'CO',
        address_unit: '1111',
        building_name: 'The Pullman',
        coverage_amount: '$500',
        coverage_dates: 'Nov 13, 2020 - Nov 12, 2021',
        coverage_months: '0x',
        monthly_rent: '$4,800',
        required_coverage: 500,
        status: 'Subscribed',
        tenant_full_name: 'Archie Schramm'
      }
    },
    {
      type: 'Invitation',
      id: 29,
      attributes: {
        address_state: 'CO',
        address_unit: '1103',
        building_name: 'The Pullman',
        coverage_amount: '$0',
        coverage_dates: 'Nov 13, 2020 - Nov 12, 2021',
        coverage_months: '2x',
        monthly_rent: '$4,800',
        required_coverage: 0,
        status: 'In Progress',
        tenant_full_name: 'Ellis Adams'
      }
    }
  ],
  meta: { total_count: 22, next_page: 2, page: '2', per: 20 }
};
