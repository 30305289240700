import { IPMAPropertyOwnerDetails } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

const getPropertyOwnerDetails = async (id: number) => {
  const { data } = await axios.get<IPMAPropertyOwnerDetails>(`/v2/admin/property_owners/${id}`);
  return data;
};

export default function usePropertyOwnerDetails(id: number): QueryObserverResult<IPMAPropertyOwnerDetails> | undefined {
  if (id) return useQuery<IPMAPropertyOwnerDetails>(['property owner details', id], () => getPropertyOwnerDetails(id));
}
