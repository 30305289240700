import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

interface ICarrierMarketDetails {
  insurance_carrier_market: {
    id: number;
    carrier_name: string;
    enabled: boolean;
    market_type: number;
    actions: {
      editable: boolean;
    };
  };
}

const getCarrierMarketDetails = async (id: number) => {
  const { data } = await axios.get<ICarrierMarketDetails>(`/v2/admin/insurance_carrier_markets/${id}.json`);
  return data;
};

export default function useCarrierMarketDetails(id: number): QueryObserverResult<ICarrierMarketDetails> {
  return useQuery<ICarrierMarketDetails>(['carrier market details', id], () => getCarrierMarketDetails(id));
}
