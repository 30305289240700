import createPartnerIntegration from 'api/v2/createPartnerIntegration';
import updatePartnerIntegration from 'api/v2/updatePartnerIntegration';
import validatePartnerIntegration from 'api/v2/validatePartnerIntegration';
import usePartnerIntegration from 'api/v2/usePartnerIntegration';
import { FormEvent, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FormMode, IRealPageIntegration, IYardiIntegration } from '../interfaces';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import useCustomCoverageRule from './useCustomCoverageRule';
import usePartnerCoverageRulesForm from './usePartnerCoverageRulesForm';
import { PROPERTY_OWNERS_CACHE_KEY } from 'api/v2/usePropertyOwners';
import { setLocal } from '../utils/localStorage';

export interface IPartner {
  name: string;
  displayName: string;
}

export interface IPropMgmtCompany {
  id: string;
  error: string;
}

export interface IInput {
  value: string;
  error: string;
}

export interface INumberInput {
  value?: number;
  error: string;
}

enum PartnerType {
  RealPage = 'RealPage',
  Yardi = 'Yardi',
  Entrata = 'Entrata'
}

export const usePartnerIntegrationForm = () => {
  const { partnerIntegrationId, propertyOwnerId } = useParams();
  const queryClient = useQueryClient();
  const defaultEmpty = { value: '', error: '' };
  const { data } = usePartnerIntegration(partnerIntegrationId) ?? {};
  const { integration } = data ?? {};
  const formMode = integration?.uuid ? FormMode.EDIT : FormMode.CREATE;
  const yardiIntegration = integration as IYardiIntegration;
  const realpageIntegration = integration as IRealPageIntegration;
  const {
    createManyCoverageRules,
    areCoverageRulesValid,
    raiseCoverageErrors,
    coverageRuleSet,
    isCoverageRulesLoading,
    hasCoverageValueChanged,
    ...coverageRuleRadio
  } = usePartnerCoverageRulesForm();
  const { mutate: createPartnerMutation, isLoading: isCreatePartnerLoading } = useMutation(
    createPartnerIntegration,
    {}
  );
  const { mutate: updatePartnerMutation, isLoading: isUpdatePartnerLoading } = useMutation(
    updatePartnerIntegration,
    {}
  );
  const { mutate: validatePartnerIntegrationMutation } = useMutation(validatePartnerIntegration);
  const getPartnerName = (name: string) => {
    if (name === 'realpage') return PartnerType.RealPage;
    if (name === 'yardi') return PartnerType.Yardi;
    if (name === 'entrata') return PartnerType.Entrata;
    return '';
  };

  const doesIntegrationHaveIlsgcInfo = () => {
    return yardiIntegration?.ils_entity && yardiIntegration.ils_entity.length > 0;
  };

  const defaultIlsgcSelection = () => {
    if (integration?.type === 'yardi') {
      if (doesIntegrationHaveIlsgcInfo()) {
        return true;
      }
    }
    return false;
  };

  const { addToast } = useToast();
  const navigate = useNavigate();
  const [partner, setIntegrationPartner] = useState<IPartner>(
    integration
      ? { name: integration.type, displayName: getPartnerName(integration.type) }
      : { name: '', displayName: '' }
  );
  const [propMgmtCompany, setPropMgmtCompany] = useState<IPropMgmtCompany>(
    integration ? { id: realpageIntegration.pmc_id, error: '' } : { id: '', error: '' }
  );
  const [nameInfo, setNameInfo] = useState<IInput>(integration ? { value: integration.name, error: '' } : defaultEmpty);
  const [partnerUrl, setPartnerUrl] = useState<IInput>(
    integration ? { value: integration.base_uri, error: '' } : defaultEmpty
  );
  const [usernameInfo, setUsername] = useState<IInput>(
    yardiIntegration ? { value: yardiIntegration.username, error: '' } : defaultEmpty
  );
  const [passwordInfo, setPassword] = useState<IInput>(
    yardiIntegration ? { value: '0000000000', error: '' } : defaultEmpty
  );
  const [hasPmsPasswordChanged, setHasPmsPasswordChanged] = useState<boolean>(false);

  const [serverName, setServerName] = useState<IInput>(
    yardiIntegration ? { value: yardiIntegration.server_name, error: '' } : defaultEmpty
  );

  const [depositChargeCode, setDepositChargeCode] = useState<IInput>(
    defaultEmpty
  );

  const [depositPaymentCode, setDepositPaymentCode] = useState<IInput>(
    realpageIntegration ? { value: realpageIntegration.deposit_payment_code, error: '' } : defaultEmpty
  );

  const [isDataPullEnabled, setIsDataPullEnabled] = useState<boolean>(integration ? integration.enabled : false);
  const [isAutoInviteEnabled, setIsAutoInviteEnabled] = useState<boolean>(
    integration ? integration.auto_invite_enabled : false
  );
  const [IlsgcSelection, setIlsgcSelection] = useState<boolean>(defaultIlsgcSelection());
  const [IlsgcUsername, setIlsgcUsername] = useState<IInput>(
    yardiIntegration ? { value: yardiIntegration.ils_username ?? '', error: '' } : defaultEmpty
  );
  const [IlsgcPassword, setIlsgcPassword] = useState<IInput>(
    yardiIntegration?.ils_entity && yardiIntegration?.ils_entity.length > 0
      ? { value: '0000000000', error: '' }
      : defaultEmpty
  );
  const [allowWrites, setAllowWrites] = useState<IInput>(yardiIntegration ? { value: yardiIntegration.allow_writes ? '1' : '0', error: '' } : defaultEmpty);
  const [singleFamilyOnly, setSingleFamilyOnly] = useState<IInput>(yardiIntegration ? { value: yardiIntegration.single_family_only ? '1' : '0', error: '' } : defaultEmpty);
  const [inviteResidentStatuses, setInviteResidentStatuses] = useState<Array<string>>(
    yardiIntegration?.invite_resident_statuses
  );
  const [inviteResidentCreditStatuses, setInviteResidentCreditStatuses] = useState<Array<string>>(
    yardiIntegration?.invite_resident_credit_statuses
  );
  const [hasIlsgcPasswordChanged, setHasIlsgcPasswordChanged] = useState<boolean>(false);
  const IlsgcLoginExists = doesIntegrationHaveIlsgcInfo();
  const [isAutoInviteEnableModalOpen, setIsAutoInviteEnableModalOpen] = useState<boolean>(false);
  const [showPartnerRulesets, setShowPartnerRulesets] = useState<boolean>(
    integration ? integration.enabled_partner_rule_set : true
  );
  const [showCustomRulesets, setShowCustomRulesets] = useState<boolean>(
    integration ? integration.enabled_custom_rule_set : true
  );

  const customRuleCoverage = useCustomCoverageRule(showCustomRulesets);
  const isCustomCoverageRuleEmpty: boolean = showCustomRulesets && customRuleCoverage.customRuleSet.length === 0;

  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);

  const checkIfEditedYardi = () => {
    if (
      nameInfo.value !== integration?.name ||
      yardiIntegration.base_uri !== partnerUrl.value ||
      yardiIntegration.server_name !== serverName.value ||
      yardiIntegration.username !== usernameInfo.value ||
      hasPmsPasswordChanged ||
      yardiIntegration.ils_username !== IlsgcUsername.value ||
      hasIlsgcPasswordChanged ||
      isAutoInviteEnabled !== integration?.auto_invite_enabled ||
      isDataPullEnabled !== integration?.enabled ||
      integration?.enabled_partner_rule_set !== showPartnerRulesets ||
      integration?.enabled_custom_rule_set !== showCustomRulesets ||
      hasCoverageValueChanged ||
      customRuleCoverage?.hasCustomRuleSetChanged ||
      JSON.stringify(yardiIntegration?.invite_resident_statuses) !== JSON.stringify(inviteResidentStatuses)
    ) {
      setHasFormChanged(true);
      return;
    }
    setHasFormChanged(false);
  };

  const checkIfEditedRealPage = () => {
    if (
      nameInfo.value != integration?.name ||
      realpageIntegration.pmc_id != propMgmtCompany.id ||
      isAutoInviteEnabled != integration?.auto_invite_enabled ||
      isDataPullEnabled != integration?.enabled ||
      integration?.enabled_partner_rule_set != showPartnerRulesets ||
      integration?.enabled_custom_rule_set != showCustomRulesets ||
      hasCoverageValueChanged ||
      customRuleCoverage?.hasCustomRuleSetChanged
    ) {
      setHasFormChanged(true);
      return;
    }
    setHasFormChanged(false);
  };

  useEffect(() => {
    if (integration?.type === 'yardi' || integration?.type === 'entrata') {
      checkIfEditedYardi();
    } else if (integration?.type === 'realpage') {
      checkIfEditedRealPage();
    }
  }, [
    nameInfo,
    serverName,
    passwordInfo,
    usernameInfo,
    partnerUrl,
    isAutoInviteEnabled,
    isDataPullEnabled,
    integration,
    showCustomRulesets,
    IlsgcPassword,
    IlsgcUsername,
    propMgmtCompany,
    hasCoverageValueChanged,
    customRuleCoverage?.hasCustomRuleSetChanged,
    inviteResidentStatuses,
    inviteResidentCreditStatuses
  ]);

  const handleConfirmAutoInvite = () => {
    setIsAutoInviteEnabled(true);
    setIsAutoInviteEnableModalOpen(false);
  };

  const setPartner = (partner: IPartner) => {
    setIntegrationPartner(partner);
  };

  const setEnableDataPull = (value: boolean) => {
    setIsDataPullEnabled(value);
  };

  const setEnableAutoInvite = (value: boolean) => {
    if (value) {
      setIsAutoInviteEnableModalOpen(value);
    } else {
      setIsAutoInviteEnabled(value);
    }
  };

  // contains client side validation checks
  const isFormValid = () => {
    return areIlsgcFieldsValid();
  };
  // Object of the shared properties to submit when creating/updating integration
  // Password and ILSGC fields are excluded since they're not always included
  const partialIntegrationInfoPayload = {
    type: partner.name,
    pmcID: propMgmtCompany.id,
    name: nameInfo.value,
    propertyOwnerID: propertyOwnerId,
    base_uri: partnerUrl.value,
    username: usernameInfo.value,
    server_name: serverName.value,
    deposit_charge_code: depositChargeCode.value,
    deposit_payment_code: depositPaymentCode.value,
    allow_writes: allowWrites.value == '1',
    single_family_only: singleFamilyOnly.value == '1',
  };

  const handleRedirect = () => {
    // add success toast
    addToast('Integration setup submitted');
    // redirect to the previous page
    navigate(-1);
  };

  const createCoverageRules = (): void => {
    if (areCoverageRulesValid() && !isCustomCoverageRuleEmpty && partnerIntegrationId) {
      createManyCoverageRules(
        partnerIntegrationId,
        [...customRuleCoverage.customRuleSet, ...customRuleCoverage.deletedRuleSets],
        showPartnerRulesets,
        showCustomRulesets,
        integration?.type,
        propertyOwnerId,
        inviteResidentStatuses,
        inviteResidentCreditStatuses
      );
    } else {
      // missing coverage rules
      raiseCoverageErrors();
      raiseCustomCoverageErrors();
    }
  };

  /**
   * Creates local storage key/value pairs to denote first time setup
   */
  const createFirstIntegrationFlag = (partnerId: string) => {
    setLocal(`covg-rules-${partnerId}`, '1');
    setLocal(`policy-push-${partnerId}`, '1');
  };

  const createPartner = (event: FormEvent): void => {
    event.preventDefault();
    // verify default coverage rules fields are valid before submitting form
    if (isFormValid()) {
      createPartnerMutation(
        {
          ...partialIntegrationInfoPayload,
          password: passwordInfo.value,
          ...(IlsgcSelection && {
            ils_username: IlsgcUsername.value,
            ils_entity: 'rhino prospects',
            ils_password: IlsgcPassword.value
          }),
          invite_resident_statuses: inviteResidentStatuses,
          invite_resident_credit_statuses: inviteResidentCreditStatuses
        },
        {
          onSuccess: (response: any) => {
            // on successful partner creation, take partner's id (uuid) and build coverage rules
            let partnerId: string = response.data.integration.uuid;
            // create first time setup flags
            createFirstIntegrationFlag(partnerId);
            // don't submit coverage rules, just show message and redirect
            handleRedirect();
            // begin validation
            validatePartnerIntegrationMutation(partnerId, {
              onSuccess: () => {
                queryClient.invalidateQueries([PROPERTY_OWNERS_CACHE_KEY]);
              }
            });
          },
          onError: (error: { response: { data?: { errors?: string }; status: number; statusText: string } }) => {
            if (error.response?.status == 422) {
              const createPartnerErrors = error.response?.data?.errors;
              if (createPartnerErrors !== undefined) {
                handleFormErrors(createPartnerErrors);
              }
            } else {
              // if no error message exists, use generic error message
              const errorMesage =
                error?.response?.statusText.length > 0
                  ? error?.response?.statusText
                  : 'An error has occured, please try again later.';
              addToast(errorMesage, 'error', TOAST_STATUS.ERROR);
            }
          }
        }
      );
    } else {
      raiseIlsgcFieldErrors();
    }
  };

  const updatePartner = (event: FormEvent): void => {
    event.preventDefault();
    if (isFormValid()) {
      const integrationInfo = {
        ...partialIntegrationInfoPayload,
        uuid: integration?.uuid,
        ...(IlsgcSelection && {
          ils_username: IlsgcUsername.value,
          ils_entity: 'rhino prospects'
        })
      };
      // only include passwords in update payload if they were changed
      let partnerIntegrationInfo = {
        ...(hasPmsPasswordChanged && { password: passwordInfo.value }),
        ...(hasIlsgcPasswordChanged && { ils_password: IlsgcPassword?.value }),
        ...integrationInfo,
        invite_resident_statuses: inviteResidentStatuses,
        invite_resident_credit_statuses: inviteResidentCreditStatuses
      };
      updatePartnerMutation(partnerIntegrationInfo, {
        onSuccess: (response: any) => {
          // don't submit coverage rules, just show message and redirect
          handleRedirect();
          // begin validation
          validatePartnerIntegrationMutation(integration?.uuid, {
            onSuccess: () => {
              queryClient.invalidateQueries([PROPERTY_OWNERS_CACHE_KEY]);
            }
          });
        },
        onError: (error: { response: { data?: { errors?: string }; status: number; statusText: string } }) => {
          if (error.response?.status == 422) {
            const createPartnerErrors = error.response?.data?.errors;
            if (createPartnerErrors !== undefined) {
              handleFormErrors(createPartnerErrors);
            }
          } else {
            // if no error message exists, use generic error message
            const errorMesage =
              error?.response?.statusText.length > 0
                ? error?.response?.statusText
                : 'An error has occured, please try again later.';
            addToast(errorMesage, 'error', TOAST_STATUS.ERROR);
          }
        }
      });
    } else {
      raiseIlsgcFieldErrors();
    }
  };

  var partnerOptions = [
    { name: 'realpage', displayName: 'RealPage' },
    { name: 'yardi', displayName: 'Yardi' },
    { name: 'entrata', displayName: 'Entrata'}
  ];

  const areIlsgcFieldsValid = () => {
    if (IlsgcSelection === false || partner.name !== 'yardi') {
      return true;
    }
    if (IlsgcUsername.value.length > 0 && IlsgcPassword.value.length > 0) {
      return true;
    }
    return false;
  };

  const raiseIlsgcFieldErrors = () => {
    if (IlsgcUsername.value.length === 0) {
      setIlsgcUsername({ value: IlsgcUsername.value, error: 'ILS/GC username is required' });
    }
    if (IlsgcPassword.value.length === 0) {
      setIlsgcPassword({ value: IlsgcPassword.value, error: 'ILS/GC password is required' });
    }
  };
  const raiseCustomCoverageErrors = () => {
    if (isCustomCoverageRuleEmpty)
      addToast('In order to enable custom rulesets you must add at least one ruleset.', 'failed', TOAST_STATUS.ERROR);
  };

  const handleFormErrors = (createPartnerErrors: string) => {
    const partnerTypeError = createPartnerErrors['type'];
    if (partnerTypeError !== undefined) {
      // switching partner types is not currently supported, drop error toast
      addToast(partnerTypeError[0], 'error', TOAST_STATUS.ERROR);
    }

    if (partner.name === 'realpage') {
      const pmcIdError = createPartnerErrors['integration_partnerable.pmc_id'];
      const nameError = createPartnerErrors['integration_partnerable.name'];
      const partnerURLError = createPartnerErrors['integration_partnerable.base_uri'];
      if (pmcIdError !== undefined) {
        setPropMgmtCompany({
          id: propMgmtCompany.id,
          error: 'PMC ID ' + pmcIdError[0]
        });
      }
      if (nameError !== undefined) {
        setNameInfo({
          value: nameInfo.value,
          error: 'Name ' + nameError[0]
        });
      }
      if (partnerURLError !== undefined) {
        setPartnerUrl({
          value: partnerUrl.value,
          error: 'Partner Url ' + partnerURLError[0]
        });
      }
    }
    if (partner.name === 'yardi' || partner.name === 'entrata') {
      const partnerUrlError = createPartnerErrors['integration_partnerable.base_uri'];
      const usernameError = createPartnerErrors['integration_partnerable.username'];
      const passwordError = createPartnerErrors['integration_partnerable.password'];
      const serverNameError = createPartnerErrors['integration_partnerable.server_name'];
      const nameError = createPartnerErrors['integration_partnerable.name'];
      if (partnerUrlError !== undefined) {
        setPartnerUrl({
          value: partnerUrl.value,
          error: 'Partner Url ' + partnerUrlError[0]
        });
      }
      if (usernameError !== undefined) {
        setUsername({
          value: usernameInfo.value,
          error: 'Username ' + usernameError[0]
        });
      }
      if (passwordError !== undefined) {
        setPassword({
          value: passwordInfo.value,
          error: 'Password ' + passwordError[0]
        });
      }
      if (serverNameError !== undefined) {
        setServerName({
          value: serverName.value,
          error: 'Server Name ' + serverNameError[0]
        });
      }
      if (nameError !== undefined) {
        setNameInfo({
          value: nameInfo.value,
          error: 'Name ' + nameError[0]
        });
      }
    }
  };

  return {
    partner,
    setPartner,
    propMgmtCompany,
    setPropMgmtCompany,
    nameInfo,
    setNameInfo,
    partnerUrl,
    setPartnerUrl,
    usernameInfo,
    setUsername,
    passwordInfo,
    setHasPmsPasswordChanged,
    hasPmsPasswordChanged,
    setHasIlsgcPasswordChanged,
    setIlsgcSelection,
    hasIlsgcPasswordChanged,
    allowWrites,
    setAllowWrites,
    singleFamilyOnly,
    setSingleFamilyOnly,
    setPassword,
    serverName,
    setServerName,
    depositChargeCode,
    setDepositChargeCode,
    depositPaymentCode,
    setDepositPaymentCode,
    isDataPullEnabled,
    setEnableDataPull,
    isAutoInviteEnabled,
    isAutoInviteEnableModalOpen,
    setIsAutoInviteEnableModalOpen,
    inviteResidentStatuses,
    setInviteResidentStatuses,
    inviteResidentCreditStatuses,
    setInviteResidentCreditStatuses,
    handleConfirmAutoInvite,
    setEnableAutoInvite,
    integration,
    coverageRuleSet,
    formMode,
    createPartner,
    updatePartner,
    isLoading: isCreatePartnerLoading || isUpdatePartnerLoading || isCoverageRulesLoading,
    partnerOptions,
    ILSGC: {
      IlsgcSelection,
      IlsgcUsername,
      setIlsgcUsername,
      IlsgcPassword,
      setIlsgcPassword,
      IlsgcLoginExists,
      passwordInputProps: {
        id: 'ilsgc-password',
        label: 'ILS/GC Password',
        password: IlsgcPassword.value
      }
    },
    partnerPasswordInputProps: {
      id: 'password',
      password: passwordInfo.value
    },
    showPartnerRulesets,
    setShowPartnerRulesets,
    showCustomRulesets,
    setShowCustomRulesets,
    ...coverageRuleRadio,
    customRuleCoverage,
    createCoverageRules,
    hasFormChanged: formMode === FormMode.EDIT ? hasFormChanged : true
  };
};
