import React from 'react';
import { render, screen, act, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import CreatePMSIntegrationSetup from '../Integrations/CreatePMSIntegrationSetup';
import { within } from '@testing-library/dom';

let server;

describe('<CreatePMSIntegrationSetup />', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();

    render(
      <Wrapper>
        <CreatePMSIntegrationSetup />
      </Wrapper>
    );
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the create PMS integration setup', async () => {
    const heading = await screen.findAllByText('PMS Integration Setup');

    expect(heading).toBeDefined();
  });

  test('It should allow for partner type to be updated', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select partner type' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('RealPage');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('Yardi');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });
  });
});

// Test yardi specific fields
describe('<CreatePMSIntegrationSetup />', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();

    render(
      <Wrapper>
        <CreatePMSIntegrationSetup />
      </Wrapper>
    );
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It should show Yardi specific fields', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select partner type' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('Yardi');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });

    const subheading = await screen.findAllByText('Integration details');
    expect(subheading).toBeDefined();

    // find Name, Partner URL, Yardi Username, Yardi Password and Yardi Server Name fields
    const nameInput = await screen.findByLabelText('Name');
    expect(nameInput).toBeDefined();

    const partnerUrlInput = await screen.findByLabelText('Partner Url');
    expect(partnerUrlInput).toBeDefined();

    const yardiUsernameInput = await screen.findByLabelText('Yardi Username');
    expect(yardiUsernameInput).toBeDefined();

    const yardiPasswordInput = await screen.findByLabelText('Yardi Password');
    expect(yardiPasswordInput).toBeDefined();

    const yardiServerInput = await screen.findByLabelText('Yardi Server Name');
    expect(yardiServerInput).toBeDefined();
  });

  // Skipped test because these fields are no longer on this form. Leaving as skipped to build other tests with it
  test.skip('Coverage rules radio groups should show a text input when "Dollar Amount" is selected and a dropdown when "Multiplier" is selected', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select partner type' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('Yardi');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });

    const coverageOptions = await screen.getByLabelText('default-approval');
    expect(coverageOptions).toBeDefined();

    // verify dollar amount button is selectable
    const dollarAmountButton = within(coverageOptions).getByRole('tab', { name: 'Dollar amount' });
    expect(dollarAmountButton).toBeDefined();

    userEvent.click(dollarAmountButton);

    // verify clicking dollar amount button shows coverage amount input
    const coverageWithRulesAmountInput = within(coverageOptions).findByLabelText('Dollar amount');
    expect(coverageWithRulesAmountInput).toBeDefined();

    // verify coverage multiplier button is selectable
    const coverageMultiplierButton = within(coverageOptions).getByRole('tab', { name: 'Multiplier' });
    expect(coverageMultiplierButton).toBeDefined();

    // verify clicking coverage multiplier button shows coverage multiplier dropdown
    userEvent.click(coverageMultiplierButton);
    const coverageMultiplierInput = within(coverageOptions).findByLabelText('Rent multiplier');
    expect(coverageMultiplierInput).toBeDefined();
  });

  // Skipped test because these fields are no longer on this form. Leaving as skipped to build other tests with it
  test.skip('Coverage rules with conditions radio groups should show a text input when "Dollar Amount" is selected and a dropdown when "Multiplier" is selected', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select partner type' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('Yardi');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });

    const coverageOptions = await screen.getByLabelText('default-approval-conditions');
    expect(coverageOptions).toBeDefined();

    // verify dollar amount button is selectable
    const dollarAmountButton = within(coverageOptions).getByRole('tab', { name: 'Dollar amount' });
    expect(dollarAmountButton).toBeDefined();

    userEvent.click(dollarAmountButton);

    // verify clicking dollar amount button shows coverage amount input
    const coverageWithRulesAmountInput = within(coverageOptions).findByLabelText('Dollar amount');
    expect(coverageWithRulesAmountInput).toBeDefined();

    // verify coverage multiplier button is selectable
    const coverageMultiplierButton = within(coverageOptions).getByRole('tab', { name: 'Multiplier' });
    expect(coverageMultiplierButton).toBeDefined();

    // verify clicking coverage multiplier button shows coverage multiplier dropdown
    userEvent.click(coverageMultiplierButton);
    const coverageMultiplierInput = within(coverageOptions).findByLabelText('Rent multiplier');
    expect(coverageMultiplierInput).toBeDefined();
  });

  // Skipped test because these fields are no longer on this form. Leaving as skipped to build other tests with it
  test.skip('ILS/GC Fields should show', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select partner type' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('Yardi');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });

    const ilsgcOptions = await screen.getByRole('radiogroup', {
      name: 'Set up Internet Listing Service/Guest Card?'
    });
    expect(ilsgcOptions).toBeDefined();

    const ilsgcYesOption = within(ilsgcOptions).getByLabelText('Yes');
    expect(ilsgcYesOption).toBeDefined();
    userEvent.click(ilsgcYesOption);

    const ilsgcUsername = await screen.getByLabelText('ILS/GC Username');
    expect(ilsgcUsername).toBeDefined();

    const ilsgcPassword = await screen.getByLabelText('ILS/GC Password');
    expect(ilsgcPassword).toBeDefined();
  });

  // Skipped test because these fields are no longer on this form. Leaving as skipped to build other tests with it
  test.skip('disable Enable vacancy auto-invites if ILS/GC is set to No ', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select partner type' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('Yardi');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });

    const ilsgcOptions = await screen.getByRole('radiogroup', {
      name: 'Set up Internet Listing Service/Guest Card?'
    });
    expect(ilsgcOptions).toBeDefined();

    const autoInviteCheckbox = await screen.findByLabelText('Enable vacancy auto-invites');
    expect(autoInviteCheckbox).not.toBeDisabled();

    const ilsgcNoOption = within(ilsgcOptions).getByLabelText('No');
    expect(ilsgcNoOption).toBeDefined();
    userEvent.click(ilsgcNoOption);
    expect(autoInviteCheckbox).not.toBeDisabled();
  });

  // Skipped test because these fields are no longer on this form. Leaving as skipped to build other tests with it
  test.skip('Shows "Add Coverage Rule" form', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select partner type' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('Yardi');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });

    const addNewRulesetButton = await screen.getByRole('button', { name: 'Add custom ruleset' });
    expect(addNewRulesetButton).toBeDefined();
    expect(addNewRulesetButton).toBeDisabled();

    // ruleset name input
    const rulesetNameInput = await screen.getByLabelText('Ruleset name');
    expect(rulesetNameInput).toBeDefined();
    userEvent.type(rulesetNameInput, 'asdf');

    // verify form is showing

    // custom coverage rules
    const approvalRulesetDiv = await screen.getByLabelText('0-approval');
    expect(approvalRulesetDiv).toBeDefined();

    // coverage rule buttons
    const approvalRulesetButtons = within(approvalRulesetDiv).getByRole('tab', { name: 'Multiplier' });
    expect(approvalRulesetButtons).toBeDefined();

    // coverage multiplier dropdown
    const approvalMultiplierDropdown = within(approvalRulesetDiv).getByRole('button', {
      name: 'Rent multiplier'
    });
    expect(approvalMultiplierDropdown).toBeDefined();

    // properties typeahead
    const typeahead = await screen.getByLabelText('Properties');
    expect(typeahead).toBeDefined();
  });

  // Skipped test because these fields are no longer on this form. Leaving as skipped to build other tests with it
  test.skip('Hides coverage rules section with toggle', async () => {
    const dropdown = await screen.getByRole('button', { name: 'select partner type' });
    expect(dropdown).toBeDefined();

    await act(async () => {
      userEvent.click(dropdown);
      const partner = await screen.findByText('Yardi');
      expect(partner).toBeDefined();

      userEvent.click(partner);
    });

    // find toggle partner coverage rules button
    const toggleRulesetSwitch = await screen.getByRole('switch', { name: 'Toggle partner coverage rules' });
    expect(toggleRulesetSwitch).toBeDefined();

    // find toggle custom coverage rules button
    const toggleCustomRulesetSwitch = await screen.getByRole('switch', { name: 'Toggle custom coverage rules' });
    expect(toggleCustomRulesetSwitch).toBeDefined();

    // validate default coverage rules show
    const coverageOptions = await screen.getByLabelText('default-approval');
    expect(coverageOptions).toBeDefined();
    const coverageOptionsConditions = await screen.getByLabelText('default-approval-conditions');
    expect(coverageOptionsConditions).toBeDefined();

    // auto-invite checkbox
    const autoInviteCheckbox = await screen.findByLabelText('Enable vacancy auto-invites');
    expect(autoInviteCheckbox).not.toBeDisabled();

    // hide coverage rules
    userEvent.click(toggleRulesetSwitch);

    // expect coverage rules to no longer be in document
    await waitFor(() => {
      expect(coverageOptions).not.toBeInTheDocument();
      expect(coverageOptionsConditions).not.toBeInTheDocument();
      expect(autoInviteCheckbox).not.toBeDisabled();
    });
  });
});
