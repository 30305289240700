/** @jsx jsx */
import { jsx } from '@emotion/core';
import useInvitationPropertyFilterOptions from 'api/v2/useInvitationPropertyFilterOptions';
import useInvitationStaticFilterOptions from 'api/v2/useInvitationStaticFilterOptions';
import { useFilterContext } from '../utils/filters';
import PropertyFilterOptions from './PropertyFilterOptions';
import StatusFilterOptions from './StatusFilterOptions';
import Filters, { ClearButton, Divider, SectionTitle, TitleWrapper } from './index';
import styled from '@emotion/styled';

const TitleContainer = styled.div({
  marginBottom: '24px'
});

const InvitationFilters = () => {
  const { selectedFilters, setFilters } = useFilterContext();

  return (
    <Filters
      selectedFilters={selectedFilters}
      setFilters={setFilters}
      staticFilterOptions={useInvitationStaticFilterOptions}
    >
      <div css={{ paddingLeft: '48px', paddingRight: '48px' }}>
        <PropertyFilterOptions
          css={{ marginBottom: '1.5rem' }}
          query={useInvitationPropertyFilterOptions}
          selectedProperties={selectedFilters.properties}
          selectedStates={selectedFilters.states}
          setFilters={setFilters}
        />
        <TitleContainer>
          <TitleWrapper>
            <SectionTitle>Invitations</SectionTitle>
            {selectedFilters.statuses.length ? (
              <ClearButton onClick={() => setFilters({ statuses: [] })}>Clear</ClearButton>
            ) : null}
          </TitleWrapper>
          <Divider />
        </TitleContainer>
        <StatusFilterOptions
          query={useInvitationStaticFilterOptions}
          selectedStatuses={selectedFilters.statuses}
          setFilters={setFilters}
          title="Invitation Status"
        />
      </div>
    </Filters>
  );
};

export default InvitationFilters;
