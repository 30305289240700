import FullScreenModal from './Modal';
import { FONTS, SquareButton } from '@sayrhino/rhino-shared-js';
import React, { Fragment } from 'react';
import { DrawerModalFooter, DrawerModalHeader, DrawerModalText, StyledModal } from '../Claims/ClaimDetails/ClaimStyles';
import styled from '@emotion/styled';

interface IProps {
  title: string;
  subTitle: string;
  errorMessage?: string;
  errors?: Array<string>;
  isOpen: boolean;
  closeModal: () => void;
}

const StyledListItem = styled.li([FONTS.p2]);

const ErrorModal = ({ title, subTitle, errorMessage, errors, isOpen, closeModal }: IProps) => {
  const renderError = (row, index) => {
    return <StyledListItem key={index}>{row}</StyledListItem>;
  };

  return (
    <FullScreenModal
      isOpen={isOpen}
      closeModal={closeModal}
      ariaLabel={title}
      ariaDescribedBy={'error_modal_subtitle error_modal_body'}
      children={
        <Fragment>
          <StyledModal>
            <DrawerModalHeader>{title}</DrawerModalHeader>
            <DrawerModalText id="error_modal_subtitle">{subTitle}</DrawerModalText>
            <span id="error_modal_body">
              {errorMessage && <DrawerModalText>{errorMessage}</DrawerModalText>}
              {errors && <ul>{errors?.map(renderError)}</ul>}
            </span>
            <DrawerModalFooter>
              <SquareButton onClick={() => closeModal()} variant="secondary">
                Close
              </SquareButton>
            </DrawerModalFooter>
          </StyledModal>
        </Fragment>
      }
    />
  );
};

export default ErrorModal;
