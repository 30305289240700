import { stringifyMonth } from '../../utils';

describe('stringifyMonth', () => {
  [
    ['Jan 1, 2020', 'Jan 2020'],
    ['Feb 3, 2001', 'Feb 2001'],
    ['December 4, 1992', 'Dec 1992']
  ].forEach(([dateString, expected]) => {
    test(`returns a properly formatted short month string for ${dateString}`, () => {
      expect(stringifyMonth(new Date(dateString))).toEqual(expected);
    });
  });
});
