import React from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { PARTNER_INTEGRATION_ROUTES } from '../utils/routeHelpers';
import { IntegrationButton, StyledContent } from './Styled';

const IntegrationSetup: React.FC = () => {
  const navigate = useNavigate();
  const { propertyOwnerId } = useParams();
  return (
    <>
      <StyledContent>
        No property management system integration for this property yet — set up your first one now!
      </StyledContent>
      <IntegrationButton
        id="new-integration"
        variant="interaction"
        size="small"
        onClick={() =>
          navigate(
            generatePath(PARTNER_INTEGRATION_ROUTES.new, {
              propertyOwnerId
            })
          )
        }
      >
        Set up Integration
      </IntegrationButton>
    </>
  );
};

export default IntegrationSetup;
