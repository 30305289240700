import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Form from './form';
const queryClient = new QueryClient();

function teamInvite() {
  return (
    <QueryClientProvider client={queryClient}>
      <Form />
    </QueryClientProvider>
  );
}

export default teamInvite;
