/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

const pillCardCSS = css({
  alignItems: 'center',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '100px',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1.75rem',
  overflow: 'hidden',
  padding: '1.5rem 3rem',
  position: 'relative'
});

const darkPillCardCSS = css({
  background: 'black',
  color: 'white'
});

interface IProps {
  title: string;
  value?: any;
  dark?: boolean;
}

const DashboardPanelPill: React.FC<IProps> = ({ title, value, dark }: IProps) => {
  const displayValue = typeof value === 'number' ? value.toLocaleString() : value;

  return (
    <li css={[pillCardCSS, dark ? darkPillCardCSS : null]}>
      <span css={[{ display: 'block' }, FONTS.h6Demi, dark ? { color: 'white' } : null]}>{title}</span>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          height: '2.75rem',
          justifyContent: 'center'
        }}
      >
        <span
          css={[
            {
              display: 'block'
            },
            FONTS.h2,
            dark ? { color: 'white' } : null
          ]}
        >
          {displayValue}
        </span>
      </div>
    </li>
  );
};

export default DashboardPanelPill;
