import React from 'react';
import makeServer from '../testUtils/server';
import { Route, Routes } from 'react-router-dom';
import Wrapper from '../testUtils/Wrapper';
import PropertyOwners from '../PropertyOwners/PropertyOwners';
import { render, screen } from '@testing-library/react';
import { UserContext } from '../utils/userRole';
import userEvent from '@testing-library/user-event';

const mockUseLocationValue = {
  pathname: '/admin/property_owners',
  search: '',
  hash: '',
  state: { prevPath: '/admin/property_owners' }
};
jest.mock('react-router-dom', () => ({
  ...(jest.requireActual('react-router-dom') as {}),
  useLocation: jest.fn().mockImplementation(() => {
    return mockUseLocationValue;
  })
}));
let server;

describe('<PropertyOwners />', () => {
  const renderAsRole = (role) =>
    render(
      <UserContext.Provider value={{ id: 1, role: role, sessionId: '', isAutoApprove: false }}>
        <Wrapper initialEntries={[`/admin/property_owners`]}>
          <Routes>
            <Route path="/admin/property_owners/" element={<PropertyOwners />} />
          </Routes>
        </Wrapper>
      </UserContext.Provider>
    );

  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('lists result count in search bar label', async () => {
    renderAsRole('Administrator');

    const newPropertyOwnerButton = await screen.findByRole('button', { name: 'New property owner' });
    expect(newPropertyOwnerButton).toBeDefined();

    const searchBar = await screen.findByLabelText('Search property owners');
    expect(searchBar).toBeDefined();

    userEvent.type(searchBar, 'john doe');

    const searchBarEmpty = await screen.findByLabelText('Search property owners 2 results');
    expect(searchBarEmpty).toBeDefined();
  });

  test('shows property owner list', async () => {
    renderAsRole('Administrator');

    const newPropertyOwnerButton = await screen.findByRole('button', { name: 'New property owner' });
    expect(newPropertyOwnerButton).toBeDefined();

    const propertyOwner = await screen.findByText('john doe 1');
    expect(propertyOwner).toBeDefined();

    const propertyOwnerIntegrationStatus = await screen.findByText('No integration');
    expect(propertyOwnerIntegrationStatus).toBeDefined();

    const propertyOwner2 = await screen.findByText('john doe 2');
    expect(propertyOwner2).toBeDefined();

    const propertyOwner2IntegrationStatus = await screen.findByText('Yardi');
    expect(propertyOwner2IntegrationStatus).toBeDefined();

    const propertyOwner3 = await screen.findByText('john doe 3');
    expect(propertyOwner3).toBeDefined();

    const propertyOwner3IntegrationStatus = await screen.findByText('RealPage');
    expect(propertyOwner3IntegrationStatus).toBeDefined();

    const propertyOwnerMessage = await screen.findByLabelText('john doe 1 is active and has no integration.');
    expect(propertyOwnerMessage).toBeDefined();

    const propertyOwner2Message = await screen.findByLabelText('john doe 2 is active and has Yardi integration.');
    expect(propertyOwner2Message).toBeDefined();

    const propertyOwner3Message = await screen.findByLabelText('john doe 3 is active and has RealPage integration.');
    expect(propertyOwner3Message).toBeDefined();
  });
});
