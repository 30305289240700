/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import useCoverageOptions, {
  CoverageState,
  ICoverageSelectionState,
  useCoverageState
} from 'api/v2/useCoverageOptions';
import { add, startOfMonth, subDays, differenceInCalendarDays } from 'date-fns';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobileDevice } from 'utils/mobile';
import { v4 as uuid } from 'uuid';
import createInvitation from '../../../../api/v2/createInvitation';
import { RefObject } from '../CreateInvitationTypeahead/PropertyTypeahead';
import { ICoverageSelection } from '../interfaces';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import { addYears, formatPhoneNumber, parseIntDecimal } from '../utils';
import { PROPERTY_DEFAULT } from '../utils/constants';
import { useSegmentTrackOnLoad, useSegmentTrackProgress, useSegmentUser } from '../utils/segmentTracker';
import useUserRole from '../utils/userRole';
import CoverageOptionsRadioButtons from '../CoverageOptionsRadioButtons';

export interface IInvitation {
  first_name?: string;
  last_name?: string;
  phone?: number;
  email?: string;
  monthly_rent?: number;
  property_id?: number;
  unit_id?: number;
  lease_start_date?: Date;
  lease_end_date?: Date;
  coverage_amount?: number;
  coverage_months?: number;
  rental_status?: number;
  coverage?: ICoverageSelection;
  cash_deposit_amount?: number;
  guarantor_coverage?: boolean;
  guarantor_coverage_capable?: boolean;
}

export interface IErrors {
  email?: [string];
  phone?: [string];
  coverage_amount_cents?: [string];
  lease_start_date?: [string];
  lease_end_date?: [string];
  monthly_rent_cents?: [string];
  cash_deposit_amount_cents?: [string];
  coverage_months?: [string];
  property?: [string];
  unit?: [string];
  first_name?: [string];
  last_name?: [string];
  unit_existing_policy_id?: [number];
  property_existing_policy_id?: [number];
  existing_invitation_id?: [number];
}

export declare type Property = {
  address_city: string;
  address_line_one: string;
  address_state: string;
  address_zip: string;
  building_name: string;
  full_address: string;
  id: number;
};

export declare type PropertyUnit = {
  address_city: string;
  address_line_one: string;
  address_line_two: string;
  address_state: string;
  address_zip: string;
  id: number;
};
export interface ICoverageWrapperProps {
  coverageSelectionState: ICoverageSelectionState;
  propertyId: number;
  unitId?: number;
  monthlyRentCents?: number;
  errors?: IErrors;
}

let coverageInfo: CoverageState;
export const CoverageOptionsRadioButtonsWrapper = (props: ICoverageWrapperProps) => {
  coverageInfo = useCoverageOptions({
    propertyId: props.propertyId,
    unitId: props.unitId,
    monthlyRentCents: props?.monthlyRentCents ? props.monthlyRentCents : undefined,
    initialCoverageState: props.coverageSelectionState
  });
  return <CoverageOptionsRadioButtons coverageInfo={coverageInfo} disabled={false} errors={props.errors} />;
};

const IS_MOBILE = isMobileDevice();

const useCreateInvitationHook = () => {
  const initialCoverageSelection: ICoverageSelection = {
    type: PROPERTY_DEFAULT
  };
  const today = new Date();
  const oneYearFromToday = add(today, { years: 1 });
  const oneYearFromTodayLeaseEndDate = subDays(oneYearFromToday, 1);
  const requiredFields = ['first_name', 'last_name', 'email', 'property_id', 'rental_status', 'monthly_rent'];
  const topBarElement = window.document.getElementById('topBar');

  const coverageSelectionState = useCoverageState(initialCoverageSelection);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const segmentUser = useSegmentUser();
  const { isAdmin } = useUserRole();

  const [invitation, setInvitation] = useState<IInvitation | undefined>();
  const [startDate, setStartDate] = useState(today);
  const [phone, setPhone] = useState('');
  const [endDate, setEndDate] = useState(oneYearFromTodayLeaseEndDate);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<IErrors | undefined>();
  const [inviteSessionId, _setInviteSessionId] = useState(uuid());
  const [cashDepositEnabled, setCashDepositEnabled] = useState<boolean | undefined>(false);
  const [collectCashDepositEnabled, setCollectCashDepositEnabled] = useState<boolean>(false);
  const [guarantorCoverageEnabled, setGuarantorCoverageEnabled] = useState<boolean>(false);

  const formRef = createRef<HTMLDivElement>();
  const AddressRef = useRef<RefObject>(null);
  const UnitRef = useRef<RefObject>(null);

  if (isAdmin) requiredFields.splice(requiredFields.indexOf('monthly_rent'), 1);

  if (cashDepositEnabled) requiredFields.push('cash_deposit_amount');

  if (IS_MOBILE) topBarElement!.style.visibility = 'hidden';

  const trackAnalyticsEvents = (eventName: string, optional: object) => {
    const common_attrs = {
      ...segmentUser,
      invite_session_id: inviteSessionId,
      portal_session_id: segmentUser.portal_session_id
    };

    window.analytics.track(eventName, { ...common_attrs, ...optional });
  };

  if (collectCashDepositEnabled) {
    trackAnalyticsEvents('Cash Deposit Only Clicked', {
      user_id: segmentUser.user_id,
      cash_deposit_only_toggle_status: collectCashDepositEnabled,
      cash_deposit_amount_cents: invitation?.cash_deposit_amount
    });
  }

  useEffect(() => {
    window.addEventListener('resize', screenSize);
    return () => window.removeEventListener('resize', screenSize);
  }, []);

  const screenSize = () => (topBarElement!.style.visibility = IS_MOBILE ? 'hidden' : 'visible');

  const cashDepositAmountValid = () => {
    if (!cashDepositEnabled) return true;
    if (guarantorCoverageEnabled) return true;

    return Number(invitation?.cash_deposit_amount) > 0;
  };

  useEffect(() => {
    if (invitation) {
      const hasRequiredFields = requiredFields.every(
        (item) => invitation.hasOwnProperty(item) && invitation[item] !== ''
      );

      setIsFormValid(hasRequiredFields && Number(invitation.monthly_rent) > 100 && cashDepositAmountValid());
    }
  }, [invitation]);

  useSegmentTrackOnLoad('Invitation Started', {
    invite_session_id: inviteSessionId,
    cta_referrer: document.referrer.split('/').slice(3).join('/')
  });

  useSegmentTrackProgress('Invitation Abandoned', formRef, {
    invitation_session_id: inviteSessionId,
    form_data: invitation,
    coverage_info: coverageSelectionState
  });

  const vanityURL = window.location.href.includes('/invite');
  vanityURL && useSegmentTrackOnLoad('Invite Vanity URL Used');

  const handleLeaseStartDateChange = (date: Date) => {
    setStartDate(date);

    const oneYearFromStartDate = addYears(date, 1);
    const oneYearAgoFromStartDate = addYears(date, -1);
    const oneYearFromStartDateLeaseEndDate = subDays(oneYearFromStartDate, 1);
    setEndDate(oneYearFromStartDateLeaseEndDate);

    if (date < oneYearAgoFromStartDate) {
      setErrors({
        ...errors,
        lease_start_date: ['The lease start date is earlier than 1 year ago.'],
        lease_end_date: undefined
      });
    } else {
      setErrors({ ...errors, lease_start_date: undefined, lease_end_date: undefined });
    }

    setInvitation({
      ...invitation,
      lease_start_date: date,
      lease_end_date: oneYearFromStartDateLeaseEndDate
    });
  };

  const handleLeaseEndDateChange = (date: Date) => {
    setEndDate(date);
    const start = new Date(startDate.toISOString());
    const end = new Date(date.toISOString());
    const diffMonths = monthDiff(start, end);

    if (end < start) {
      setErrors({ ...errors, lease_end_date: ['must be on or after the lease start date'] });
    } else if (diffMonths > 28) {
      setErrors({ ...errors, lease_end_date: ['cannot be more than 28 months later than the start date.'] });
    } else {
      setErrors({ ...errors, lease_end_date: undefined });
    }
    setInvitation({
      ...invitation,
      lease_end_date: date
    });
  };

  const monthDiff = (d1: Date, d2: Date) => {
    let diff = (d2.getTime() - d1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff)) - 1;
  };

  const onPropertyChange = (propertyId) => {
    setInvitation({
      ...invitation,
      property_id: propertyId,
      unit_id: undefined
    });
  };

  const onUnitChange = (unitId) => {
    setInvitation({
      ...invitation,
      unit_id: unitId
    });
  };

  const handleCoverage = () => {
    var coverage;
    if (collectCashDepositEnabled) {
      coverage = {
        type: null,
        value: null
      };
    } else {
      coverage = coverageInfo.selected;
    }
    return coverage;
  };

  const submitForm = async () => {
    try {
      const response = await createInvitation({
        ...invitation,
        lease_start_date: startDate ? startDate : today,
        lease_end_date: endDate ? endDate : oneYearFromTodayLeaseEndDate,
        source: 0,
        coverage: handleCoverage()
      });
      if (response.status === 201) {
        trackAnalyticsEvents('Invitation Sent', {
          cash_deposit_only_toggle_status: collectCashDepositEnabled,
          cash_deposit_amount_cents: invitation?.cash_deposit_amount
        });

        if (IS_MOBILE) {
          window.document.querySelector('form')?.reset();
          resetStates();
          addToast('Invitation sent', 'info', TOAST_STATUS.SUCCESS);
          return;
        }
        navigate('/admin/invitations/' + response.data);
      }
    } catch (_e) {
      const e = _e;

      window.analytics.track('Invitation Failed', {
        ...segmentUser,
        invite_session_id: inviteSessionId,
        errors: e.response?.data
      });
      if (e.response.status === 500) {
        addToast('Internal error — Try again', 'failed', TOAST_STATUS.ERROR);
      }
      setErrors(e.response?.data);
      scrollToError();
      scrollToErrorWithFlag();
    }
  };

  const trackErrorLinkClick = (eventName: string, link: string) => {
    window.analytics.track(eventName, {
      userId: segmentUser.user_id,
      userType: segmentUser.user_type,
      sessionId: segmentUser.portal_session_id,
      link
    });
  };

  const scrollToError = () => {
    const items: any = window.document.getElementsByClassName('infoError');
    const visible = [...items].filter((el) => {
      return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
    });

    if (visible.length > 0) {
      window.scrollTo({
        top: items[0].offsetTop - 150,
        behavior: 'smooth'
      });
    }
  };
  const scrollToErrorWithFlag = () => {
    const items: any = document.querySelectorAll('.info p');
    const visible = [...items].filter((el) => {
      return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
    });

    if (visible.length > 0) {
      window.scrollTo({
        top: items[0].offsetTop - 150,
        behavior: 'smooth'
      });
    }
  };

  const resetStates = () => {
    window.scrollTo(0, 0);
    setPhone('');
    setStartDate(today);
    setEndDate(oneYearFromTodayLeaseEndDate);
    setErrors(undefined);
    setInvitation(undefined);
    AddressRef.current?.reset();
    UnitRef.current?.reset();
  };

  const handleChange = (event, key: string) => {
    if (key === 'guarantor_coverage') {
      let enabled = event;

      setGuarantorCoverageEnabled(enabled);

      if (enabled && cashDepositEnabled) {
        requiredFields.push('cash_deposit_amount');
      }

      const index = requiredFields.indexOf('cash_deposit_amount');
      if (enabled && index !== -1) requiredFields.splice(index, 1);
      else if (index > -1 && cashDepositEnabled) requiredFields.push('cash_deposit_amount');

      let cashDepositCentsError: [string] | undefined = cashDepositEnabled
        ? ['must be equal or greater than $1']
        : undefined;

      setErrors({
        ...errors,
        cash_deposit_amount_cents: enabled ? undefined : cashDepositCentsError
      });

      return setInvitation({
        ...invitation,
        guarantor_coverage: enabled,
        cash_deposit_amount: undefined
      });
    }

    if (key === 'monthly_rent') {
      setErrors({
        ...errors,
        monthly_rent_cents: Number(event.target.value) <= 100 ? ['must be greater than $100'] : undefined
      });
    }

    setInvitation({ ...invitation, [key]: event.target.value });

    if (key === 'cash_deposit_amount') {
      const value = Number(event.target.value) > 0;

      setErrors({
        ...errors,
        cash_deposit_amount_cents: value ? undefined : ['must be equal or greater than $1']
      });

      if (value) {
        const timeoutId = setTimeout(
          () =>
            trackAnalyticsEvents('Cash Coverage Amount Entered', {
              user_id: segmentUser.user_id,
              property_id: invitation?.property_id,
              cash_deposit_amount_cents: event.target.value
            }),
          1000
        );

        return () => clearTimeout(timeoutId);
      }
    }
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInvitation({ ...invitation, email: event.target.value });
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, '');
    const phone = parseIntDecimal(value);
    setErrors({ ...errors, phone: value.length !== 10 ? ['should be 10 digits long.'] : undefined });
    setPhone(formatPhoneNumber(event.target.value));
    setInvitation({ ...invitation, phone });
  };

  return {
    invitation,
    startDate,
    endDate,
    coverageSelectionState,
    phone,
    isFormValid,
    handleLeaseStartDateChange,
    handleLeaseEndDateChange,
    onPropertyChange,
    onUnitChange,
    submitForm,
    trackErrorLinkClick,
    handleChange,
    handleEmailChange,
    handlePhoneChange,
    errors,
    setInvitation,
    formRef,
    UnitRef,
    AddressRef,
    setCashDepositEnabled,
    setCollectCashDepositEnabled,
    collectCashDepositEnabled,
    guarantorCoverageEnabled,
    setGuarantorCoverageEnabled
  };
};

export default useCreateInvitationHook;
