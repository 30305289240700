import React from 'react';
import { render, screen, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { TestApp } from '../index';
import makeServer from '../testUtils/server';
import { Routes, Route } from 'react-router-dom';
import Wrapper from '../testUtils/Wrapper';
import CancelPolicy from '../CancelPolicy';
import PolicyDetails from '../PolicyDetails';
import { waitForAnimationFrame } from '../testUtils';

let server;

describe('<CancelPolicy />', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the correct heading', async () => {
    render(
      <Wrapper>
        <CancelPolicy />
      </Wrapper>
    );

    const heading = await screen.findByRole('heading', { level: 3, name: /Cancel Policy?/i });
    expect(heading).toBeDefined();
  });

  test('It shows the cancellation reasons', async () => {
    render(
      <Wrapper>
        <CancelPolicy />
      </Wrapper>
    );

    const cancellationReasonRadio = await screen.getByLabelText(/Did not sign lease/i);
    expect(cancellationReasonRadio).toBeDefined();

    const cancellationReasonRadio2 = await screen.getByLabelText(/Did not renew/i);
    expect(cancellationReasonRadio2).toBeDefined();

    const cancellationReasonRadio3 = await screen.getByLabelText(/Roommate signed up/i);
    expect(cancellationReasonRadio3).toBeDefined();

    const cancellationReasonRadio4 = await screen.getByLabelText(/Duplicate policy/i);
    expect(cancellationReasonRadio4).toBeDefined();

    const cancellationReasonRadio5 = await screen.getByLabelText(/Paid cash deposit/i);
    expect(cancellationReasonRadio5).toBeDefined();
  });

  test('It cancels a policy', async () => {
    jest.useFakeTimers();

    const { findByRole, queryByRole } = render(
      <Wrapper initialEntries={['/admin/renter_policies/12345']}>
        <TestApp />
      </Wrapper>
    );

    const leftClick = { button: 0 };

    await waitForAnimationFrame();

    const cancelLink = await findByRole('link', { name: /cancel/i });
    expect(cancelLink).toBeDefined();

    await act(async () => {
      userEvent.click(cancelLink, leftClick);

      // wait for cancel section to transition in
      await waitForAnimationFrame();
    });

    const heading = await findByRole('heading', { level: 3, name: /Cancel Policy/i });

    expect(heading).toBeDefined();

    const cancellationReasonRadio = await screen.getByLabelText(/Did not sign lease/i);
    expect(cancellationReasonRadio).toBeDefined();
    userEvent.click(cancellationReasonRadio);

    const submit = await findByRole('button', { name: /Yes, cancel policy/i });
    await act(async () => {
      userEvent.click(submit, leftClick);

      await waitForAnimationFrame();
    });

    await act(async () => {
      jest.advanceTimersByTime(1000);
    });

    expect(
      server.pretender.handledRequests.find(
        (element) => element.url === '/admin/renter_policies/12345/cancel_subscription' && element.method === 'POST'
      )
    ).toBeDefined();

    const headingGone = await queryByRole('heading', { level: 3, name: /Cancel Policy/i });
    expect(headingGone).toBeNull();

    await act(async () => {
      jest.runOnlyPendingTimers();
    });
    jest.useRealTimers();
  });

  test('It does not display move out option if policy not eligible for early term', async () => {
    jest.useFakeTimers();

    const { findByRole, queryByRole } = render(
      <Wrapper initialEntries={['/admin/renter_policies/111']}>
        <Routes>
          <Route path="/admin/renter_policies/:policyId/cancel" element={<CancelPolicy />} />
        </Routes>
      </Wrapper>
    );

    const cancellationReasonRadio = await screen.queryByRole('label', { name: /Moved out/i });
    expect(cancellationReasonRadio).toBeNull();
  });

  test('It does not display the move out option if policy is eligible for early term and is less than thirty days after lease start date', async () => {
    jest.useFakeTimers();

    const { findByRole, queryByRole } = render(
      <Wrapper initialEntries={['/admin/renter_policies/12345']}>
        <Routes>
          <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          <Route path="/admin/renter_policies/:policyId/cancel" element={<CancelPolicy />} />
        </Routes>
      </Wrapper>
    );

    const leftClick = { button: 0 };

    await waitForAnimationFrame();
    const cancelLink = await findByRole('link', { name: /cancel/i });
    expect(cancelLink).toBeDefined();

    await act(async () => {
      userEvent.click(cancelLink, leftClick);

      // wait for cancel section to transition in
      await waitForAnimationFrame();
    });

    const cancellationReasonRadio = await screen.queryByLabelText(/Moved out/i);
    expect(cancellationReasonRadio).toBeNull();
  });
  test('It displays the move out option if policy is eligible for early term and thirty days or more after start date and can terminate a policy', async () => {
    jest.useFakeTimers();

    const { findByRole, queryByRole } = render(
      <Wrapper initialEntries={['/admin/renter_policies/25921']}>
        <Routes>
          <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          <Route path="/admin/renter_policies/:policyId/cancel" element={<CancelPolicy />} />
        </Routes>
      </Wrapper>
    );

    const leftClick = { button: 0 };

    await waitForAnimationFrame();
    const cancelLink = await findByRole('link', { name: /cancel/i });
    expect(cancelLink).toBeDefined();

    await act(async () => {
      userEvent.click(cancelLink, leftClick);

      // wait for cancel section to transition in
      await waitForAnimationFrame();
    });

    const cancellationReasonRadio = await screen.getByLabelText(/Moved out/i);
    expect(cancellationReasonRadio).toBeDefined();
    userEvent.click(cancellationReasonRadio);

    const submit = await findByRole('button', { name: /Yes, cancel policy/i });
    await act(async () => {
      userEvent.click(submit, leftClick);

      await waitForAnimationFrame();
    });

    await act(async () => {
      jest.advanceTimersByTime(1000);
    });

    expect(
      server.pretender.handledRequests.find(
        (element) => element.url === '/admin/admitted_policies/25921/terminated_earlies' && element.method === 'PUT'
      )
    ).toBeDefined();

    const headingGone = await queryByRole('heading', { level: 3, name: /Cancel Policy/i });
    expect(headingGone).toBeNull();

    await act(async () => {
      jest.runOnlyPendingTimers();
    });
    jest.useRealTimers();
  });

  test('It displays the Other option if policy is eligible for early term and thirty days or more after start date and can terminate a policy', async () => {
    jest.useFakeTimers();

    const { findByRole, queryByRole } = render(
      <Wrapper initialEntries={['/admin/renter_policies/25921']}>
        <Routes>
          <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          <Route path="/admin/renter_policies/:policyId/cancel" element={<CancelPolicy />} />
        </Routes>
      </Wrapper>
    );

    const leftClick = { button: 0 };

    await waitForAnimationFrame();
    const cancelLink = await findByRole('link', { name: /cancel/i });
    expect(cancelLink).toBeDefined();

    await act(async () => {
      userEvent.click(cancelLink, leftClick);

      // wait for cancel section to transition in
      await waitForAnimationFrame();
    });

    const cancellationReasonRadio = await screen.getByLabelText(/Other/i);
    expect(cancellationReasonRadio).toBeDefined();
  });
});
