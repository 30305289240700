import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { csrfToken } from 'utils/document';
import { IBankAccountInfo, IWithdrawal, IWithdrawalInfoFull } from 'components/v2/App/DepositFunds/types';



const cancelWithdrawal = async (id: string) => {
  const token = csrfToken();
  const { data } = await axios.delete<IWithdrawalInfoFull>(
    `/admin/funds/withdrawals/${id}`,
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return data;
};

export default cancelWithdrawal;
