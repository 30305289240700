import React from 'react';
import CardSelection from '../CardSelection';
import { Button, DownloadIcon } from '@sayrhino/rhino-shared-js';
import { redirectTo } from '../../utils';
import { UserTrackDetails } from '../Modal';
import useFeatureFlags from 'hooks/v2/useFeatureFlags';

interface IProps {
  isAdmin: boolean;
  onSelect: (nextStep: string) => void;
  user: UserTrackDetails;
}

export const downloadCsv = () => {
  window.location.assign('/admin/properties/generate_template_csv');
};
export const downloadSampleSheet = () => {
  window.location.assign('https://landlord-self-service-public.s3.amazonaws.com/Sample+Sheet+and+Instructions.zip');
};

const AddProperty: React.FC<IProps> = (props: IProps) => {
  const { featureFlags } = useFeatureFlags();
  const track = (value) => {
    window.analytics.track(value, props.user);
  };

  return (
    <div>
      <h2 className="bold" style={styles.heading}>
        Add new property
      </h2>
      <div className="row">
        <div style={styles.cardSelection}>
          {props.isAdmin && (
            <CardSelection
              heading="Create new property"
              bodyText="Manually add new properties to Portal. Don’t worry, we’ll guide you through it."
              id="new-property-card"
              onClick={() => redirectTo('/admin/properties/add/single')}
            />
          )}
          <CardSelection
            heading="Bulk import properties"
            bodyText="Download the CSV template and fill it out to quickly batch upload your new properties with the least headache."
            id="batch-property-card"
            onClick={() => {
              track('ADD MULTIPLE PROPERTIES Button Clicked');
              props.onSelect('UploadProperties');
            }}
          />
        </div>
      </div>
      <div className="row" style={styles.buttonContainer}>
        <span style={styles.downloadButton}>
          <Button variant="tertiary" onClick={downloadSampleSheet} id="download-csv-button">
            Download sample sheet and instructions <DownloadIcon />
          </Button>
        </span>
        <span style={styles.downloadButton}>
          <Button
            variant="tertiary"
            onClick={() => {
              track('DOWNLOAD CSV TEMPLATE Button Clicked');
              downloadCsv();
            }}
            id="download-csv-button"
          >
            Download CSV template <DownloadIcon />
          </Button>
        </span>
      </div>
    </div>
  );
};

const styles = {
  heading: {
    textAlign: 'center' as 'center',
    margin: '92px 0 60px 0'
  },
  buttonContainer: {
    marginTop: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  downloadButton: {
    margin: '0 5px',
    borderRadius: '8px'
  },
  cardSelection: {
    display: 'flex',
    justifyContent: 'center'
  }
};

export default AddProperty;
