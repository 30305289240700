import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, PALETTE, Card as CardBase } from '@sayrhino/rhino-shared-js';

import AssignTeamForm, { MemberProperty, MODE, UnderlinedButton } from './AssignTeamForm';
import TeamTags from './TeamTag';
import NewTeamMember, { NewTeamMemberFormValues } from '../AddNewTeamMember/NewTeamMember';
import PropertiesTags from '../AddNewTeamMember/PropertyTags';
import { useFormContext } from 'components/v2/App/utils/propertyMemberAssign';
import useAssignmentMutation, { createAssignment, formatData } from './assign';
import ErrorNotification from './ErrorNotification';
import { useEffect } from 'react';

const Card = styled(CardBase)({
  height: 'auto',
  border: `1px solid ${PALETTE.neutral12}`,
  padding: '40px 40px',
  cursor: 'auto',
  margin: '32px auto',
  display: 'flex',
  flexDirection: 'row',
  width: '600px !important',
  '&:hover': {
    boxShadow: 'none'
  }
});

const EditButton = styled(UnderlinedButton)({ alignSelf: 'flex-end', flex: '.5', display: 'flex', padding: 'none' });
const SkipButton = styled(Button)({ borderColor: PALETTE.neutral12 });

const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '528px',
  padding: '30px'
});

const Content = styled.div({
  flex: '9.5'
});

type RCType = {
  item: MemberProperty;
  onEditClicked: () => void;
};

type IError = {
  response: {
    data: {
      errors: any;
    };
  };
};

export default function AssignTeamMember() {
  const [assignMembers, setAssignedMembers] = useState<MemberProperty[]>([]);
  const [newTeamMembers, setNewTeamMembers] = useState<NewTeamMemberFormValues[]>([]);
  const [edit, setEdit] = useState<number>(-1);
  const { members, hasTeam } = useFormContext();
  const [errors, setErrors] = useState<string[]>([]);
  const [showForm, setShowForm] = useState(!hasTeam);
  const assignMutation = useAssignmentMutation();

  useEffect(() => {
    if (members) setShowForm(members.length <= 0);
  }, [members]);

  useEffect(() => {
    if (errors.length > 0) {
      setTimeout(() => setErrors([]), 5000);
    }
  }, [errors]);

  const assign = async () => {
    if (assignMutation.isLoading) return;

    const payload = formatData(assignMembers, newTeamMembers);

    assignMutation.mutate(payload, {
      onSuccess: (data) => {
        window.location.href = '/admin/properties?table=true';
      },
      onError: (error: IError) => {
        const msg: string[] = [].concat(Object.values(error.response.data.errors));
        setErrors(msg ? msg : ['Sorry, something went wrong']);
      }
    });
  };

  const setTeamMember = (values: NewTeamMemberFormValues[]) => {
    setNewTeamMembers(values);
  };

  const closeModal = () => {
    if (confirm('Are you sure you want to exit?')) {
      window.location.href = '/admin/properties?table=true';
    }
  };

  const handleAdd = (assigned: MemberProperty) => {
    setAssignedMembers((prev) => [...prev, assigned]);
  };

  const handleUpdate = (assigned: MemberProperty, idx: number) => {
    setAssignedMembers((prev) =>
      prev.map((item, index) => {
        if (idx === index) return assigned;
        return item;
      })
    );
    setEdit(-1);
  };

  const handleDelete = (idx: number) => {
    setAssignedMembers((prev) => prev.filter((_, index) => index !== idx));
    setEdit(-1);
  };

  const RelationCard = ({ item, onEditClicked }: RCType) => (
    <Card>
      <Content>
        <TeamTags team={item.users} />
        <PropertiesTags properties={item.properties} />
      </Content>
      <EditButton children="Edit" onClick={onEditClicked} variant="tertiary" />
    </Card>
  );

  const renderCards = () => {
    return assignMembers.map((assigned: MemberProperty, index) => {
      if (index === edit)
        return (
          <AssignTeamForm
            key={index}
            mode={MODE.EDIT}
            idx={index}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            initialValue={assigned}
          />
        );
      return <RelationCard key={index} item={assigned} onEditClicked={() => setEdit(index)} />;
    });
  };

  return (
    <div>
      {renderCards()}
      {hasTeam && <AssignTeamForm mode={MODE.CREATE} handleAdd={handleAdd} />}
      <NewTeamMember
        showForm={showForm}
        flipForm={(val) => setShowForm(val)}
        setTeamMembers={setTeamMember}
        newTeamMembers={newTeamMembers}
      />
      {errors.length > 0 && <ErrorNotification errors={errors} />}
      <ButtonContainer>
        <SkipButton variant="tertiary" children="Skip" onClick={closeModal} />
        {(assignMembers.length > 0 || newTeamMembers.length > 0) && (
          <Button
            variant="primary"
            children={assignMutation.isLoading ? 'Loading...' : 'Assign and send invite'}
            onClick={assign}
          />
        )}
      </ButtonContainer>
    </div>
  );
}
