import { act, render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import { TestApp } from '../../index';
import Wrapper from '../../testUtils/Wrapper';
import { waitForAnimationFrame } from '../../testUtils';
import makeServer from '../../testUtils/server';

let server;

beforeEach(() => {
  const mockIntersectionObserver = jest.fn();
  mockIntersectionObserver.mockReturnValue({
    observe: () => null,
    unobserve: () => null
  });
  window.IntersectionObserver = mockIntersectionObserver;
  server = makeServer();
  jest.useFakeTimers();
});

afterEach(async () => {
  await act(async () => {
    jest.runOnlyPendingTimers();
  });
  jest.useRealTimers();
  server.shutdown();
});

describe('Invitations Filters', () => {
  test('can toggle filters', async () => {
    const { findByRole } = render(
      <Wrapper initialEntries={['/admin/invitations']}>
        <TestApp />
      </Wrapper>
    );

    const filterLink = await findByRole('link', { name: 'Filter' });

    await act(async () => {
      userEvent.click(filterLink);
      await waitForAnimationFrame();
    });

    const heading = await findByRole('heading', { name: 'Filters' });

    expect(heading).toBeDefined();

    await act(async () => {
      userEvent.click(filterLink);
      await waitForAnimationFrame();
    });

    expect(heading).not.toBeInTheDocument();
  });

  test('can filter by states', async () => {
    expect.assertions(2);

    server.get('/v2/admin/invitations', (_, request) => {
      if (request.queryParams['filter[states]']) {
        expect(request.queryParams['filter[states]']).toEqual(expect.arrayContaining(['NY']));
      }

      return {
        data: [],
        meta: {
          next_page: 2,
          page: 1,
          per: 1
        }
      };
    });

    const { findByRole, findByLabelText } = render(
      <Wrapper initialEntries={['/admin/invitations/filters']}>
        <TestApp />
      </Wrapper>
    );

    const nyState = await findByLabelText('NY');

    await act(async () => {
      userEvent.click(nyState);
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    const filterLink = await findByRole('link', { name: /Filter/ });

    expect(filterLink).toHaveTextContent('1');
  });

  test.skip('can filter by properties', async () => {
    expect.assertions(9);

    server.get('/v2/admin/invitations', (_, request) => {
      if (request.queryParams['filter[properties][][address_state]']) {
        expect(request.queryParams['filter[properties][][address_state]']).toEqual('NY');
        expect(request.queryParams['filter[properties][][building_name]']).toEqual('Cool Haus 1');
      }

      return {
        data: [],
        meta: {
          next_page: 2,
          page: 1,
          per: 1
        }
      };
    });

    const { findByRole, findByLabelText } = render(
      <Wrapper initialEntries={['/admin/invitations/filters']}>
        <TestApp />
      </Wrapper>
    );

    const filterLink = await findByRole('link', { name: 'Filter' });
    const nyProperty = await findByLabelText('Cool Haus 1');
    const nyProperty2 = await findByLabelText('Cool Haus 2');

    await act(async () => {
      userEvent.click(nyProperty);
      await waitForAnimationFrame();
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    expect(nyProperty).toBeChecked();
    expect(filterLink).toHaveTextContent('1');

    server.get('/v2/admin/invitations', (_, request) => {
      if (request.queryParams['filter[properties][][address_state]']) {
        expect(request.queryParams['filter[properties][][address_state]']).toEqual('NY');
        expect(request.queryParams['filter[properties][][building_name]']).toEqual('Cool Haus 2');
      }

      return {
        data: [],
        meta: {
          next_page: 2,
          page: 1,
          per: 1
        }
      };
    });

    await act(async () => {
      userEvent.click(nyProperty2);
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    expect(nyProperty).toBeChecked();
    expect(nyProperty2).toBeChecked();
    expect(filterLink).toHaveTextContent('2');
  });

  test('can filter by statuses', async () => {
    expect.assertions(2);

    const { findByRole, findByLabelText } = render(
      <Wrapper initialEntries={['/admin/invitations/filters']}>
        <TestApp />
      </Wrapper>
    );

    const filterLink = await findByRole('link', { name: 'Filter' });
    const sentStatus = await findByLabelText('Sent');

    server.get('/v2/admin/invitations', (_, request) => {
      if (request.queryParams['filter[statuses]']) {
        expect(request.queryParams['filter[statuses]']).toEqual(expect.arrayContaining(['sent']));
      }

      return {
        data: [],
        meta: {
          next_page: 2,
          page: 1,
          per: 1
        }
      };
    });

    await act(async () => {
      userEvent.click(sentStatus);
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    expect(filterLink).toHaveTextContent('1');
  });

  test('can clear states filters', async () => {
    expect.assertions(2);

    const { findByRole, findByLabelText } = render(
      <Wrapper initialEntries={['/admin/invitations/filters']}>
        <TestApp />
      </Wrapper>
    );

    const nyState = await findByLabelText('NY');

    server.get('/v2/admin/invitations', (_, request) => {
      if (request.queryParams['filter[states]']) {
        expect(request.queryParams['filter[states]']).toEqual(expect.arrayContaining(['NY']));
      } else {
        expect(request.queryParams['filter[states]']).not.toBeDefined();
      }

      return {
        data: [],
        meta: {
          next_page: 2,
          page: 1,
          per: 1
        }
      };
    });

    // select NY state
    await act(async () => {
      userEvent.click(nyState);
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    const clearButton = await findByRole('button', { name: 'Clear' });

    await act(async () => {
      userEvent.click(clearButton);
    });
  });

  test.skip('can clear all filters', async () => {
    const { findByRole, findByLabelText } = render(
      <Wrapper initialEntries={['/admin/invitations']}>
        <TestApp />
      </Wrapper>
    );

    const filterLink = await findByRole('link', { name: 'Filter' });

    await act(async () => {
      userEvent.click(filterLink);
      await waitForAnimationFrame();
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    // Add a few filters
    const nyState = await findByLabelText('NY');
    const coolHaus = await findByLabelText('Cool Haus 1');
    const sentFilter = await findByLabelText('Sent');

    await act(async () => {
      userEvent.click(nyState);
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    await act(async () => {
      userEvent.click(coolHaus);
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    await act(async () => {
      userEvent.click(sentFilter);
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    // expect(nyState).toBeChecked(); // TODO(FR): Change to checkbox input
    expect(coolHaus).toBeChecked();
    expect(sentFilter).toBeChecked();

    // The Clear all link should clear all filters (duh)
    const clearAll = await findByRole('button', { name: 'Clear all' });

    await act(async () => {
      userEvent.click(clearAll);
    });

    await act(async () => {
      // advance timer for debounce delay
      jest.advanceTimersByTime(1000);
    });

    expect(coolHaus).not.toBeChecked();
    expect(sentFilter).not.toBeChecked();
  });

  test.skip('can collapse invitation details and return to filters view', async () => {
    const { findByRole, findByTestId } = render(
      <Wrapper initialEntries={['/admin/invitations']}>
        <TestApp />
      </Wrapper>
    );

    const filterLink = await findByRole('link', { name: 'Filter' });

    await act(async () => {
      userEvent.click(filterLink);
      await waitForAnimationFrame();
    });

    const filtersHeading = await findByRole('heading', { name: 'Filters' });
    expect(filtersHeading).toBeDefined(); // we're on the filters view

    const invitationCard = await findByRole('link', { name: /John Smithik/ });

    await act(async () => {
      userEvent.click(invitationCard);
      await waitForAnimationFrame();
    });

    const invitationFullAddress = await findByTestId('fullAddress');
    expect(invitationFullAddress).toBeDefined(); // we're on the invitation details view

    const collapseLink = await findByRole('link', { name: 'collapse' });
    await act(async () => {
      userEvent.click(collapseLink);
      await waitForAnimationFrame();
    });

    expect(filtersHeading).toBeDefined(); // we're back on the filters view
  });
});
