/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Routes, Route } from 'react-router-dom';
import CreateInvitation from './Invitation/CreateInvitation';
import CreatePolicyRenewalInvitation from './Invitation/CreatePolicyRenewalInvitation';
import FileClaim from './FileClaim';
import CoverageRulesForm from './Integrations/CoverageRulesForm';
import { CreatePartnerIntegration } from './Integrations/CreatePMSIntegrationSetup';
import SitesTable from './Integrations/Sites/SitesTable';
import PolicyRenewalRequestSent from './Invitation/PolicyRenewalRequestSent';
import { BREAKPOINTS, NAV_WIDTH } from './utils/constants';
import { mediaQueryWidth } from './utils';

const FullPage = () => {
  return (
    <div
      css={{
        height: '100vh',
        marginLeft: NAV_WIDTH.expanded,
        [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
          marginLeft: NAV_WIDTH.collapsed
        }
      }}
    >
      <Routes>
        <Route path="admin/invitations/new" element={<CreateInvitation />} />
        <Route path="invite" element={<CreateInvitation />} />
        <Route path="admin/invitations/policy_renewal" element={<CreatePolicyRenewalInvitation />} />
        <Route path="admin/invitations/policy_renewal_success" element={<PolicyRenewalRequestSent />} />
        <Route path="admin/claims/new" element={<FileClaim />} />
        <Route path="admin/claims/:claimsId/edit" element={<FileClaim />} />
        <Route path="admin/integrations/sites" element={<SitesTable />} />
        <Route
          path="admin/property_owners/:propertyOwnerId/integrations/partners/new"
          element={<CreatePartnerIntegration />}
        />
        <Route
          path="admin/property_owners/:propertyOwnerId/integrations/partners/:partnerIntegrationId/edit"
          element={<CreatePartnerIntegration />}
        />
        <Route
          path="admin/property_owners/:propertyOwnerId/integrations/partners/:partnerIntegrationId/coverage"
          element={<CoverageRulesForm />}
        />
      </Routes>
    </div>
  );
};

export default FullPage;
