/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

const DashboardPanelProgressFigure = ({ title, numerator, denominator }) => {
  const percentage: number = Math.round((numerator / denominator) * 100);

  return (
    <li
      css={{
        background: PALETTE.neutral25,
        borderRadius: '100px',
        marginBottom: '1.75rem',
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <div
        css={{
          background: PALETTE.neutralDark,
          height: '100%',
          position: 'absolute',
          width: `${percentage}%`
        }}
      ></div>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          position: 'relative',
          width: '100%'
        }}
      >
        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: '1.5rem 2.75rem'
          }}
        >
          <span css={[{ display: 'block', color: PALETTE.neutralLight }, FONTS.h6Medium]}>{title}</span>
          <div css={[{ color: PALETTE.neutralLight }, FONTS.h2]}>
            {numerator} / {denominator}
          </div>
        </div>
      </div>
    </li>
  );
};

export default DashboardPanelProgressFigure;
