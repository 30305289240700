/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';

const fileViewerModalCSS = css({
  position: 'fixed',
  display: 'flex',
  height: '100vh',
  zIndex: 201,
  background: PALETTE.neutral4,
  width: '1366px',
  left: '0',
  overflow: 'auto',
  top: 0
});

const FileViewerModal = (props) => {
  return (
    <div css={fileViewerModalCSS}>
      <div css={{ width: '100%', margin: 'auto', borderRadius: '8px' }}>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default FileViewerModal;
