/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Card, FONTS, LegacyTag, PALETTE } from '@sayrhino/rhino-shared-js';
import { WithdrawalStatus } from './interfaces';
import { initializeDate, stringifyDate, numberToUSDFormatter, getTagVariant } from './utils';
import { StyledIndicatorIcon, StyledOtherClaimTag } from './Claims/ClaimDetails/ClaimStyles';
import CardLink from './CardLink';
import { IWithdrawalAttributes } from './DepositFunds/types';

interface IProps {
  withdrawal: IWithdrawalAttributes;
}

const StyledSectionWrapper = styled.div({
  flex: '1',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
});

const StyledDetailInfoSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '4px',
  '&:last-child': {
    marginRight: '0px'
  }
});

const StyledNumAndStatusWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const StyledStatusWrapper = styled.div([FONTS.neutral88, { margin: '0.25rem' }]);

const StyledNewStatusTag = styled(LegacyTag)([
  FONTS.h6Medium,
  {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '28px',
    padding: '2px 2px 2px 4px',
    marginLeft: '12px',
    background: PALETTE.neutralLight,
    border: `1px solid ${PALETTE.neutral12}`
  }
]);

const StyledLabelP = styled.p([FONTS.labelMedium, { marginBottom: '4px' }]);

const StyledCardSectionWrapper = styled.div({ display: 'flex', marginTop: '12px' });

const StyledDateP = styled.p([FONTS.p2Medium, { color: PALETTE.neutral88 }]);

const StyledTotalAmountP = styled.p([FONTS.p2Medium, { color: PALETTE.interaction100 }]);

const StyledCard = styled(Card)({
  height: 'fit-content',
  padding: '20px 32px',
  position: 'relative',
  margin: '8px auto'
});

const stateText = (state: string) => {
  if (state === 'payment_complete')
    return WithdrawalStatus.PAYMENT_COMPLETE;
  if (state === 'payment_pending')
    return "paid";
  return state;
}

export const WithdrawalCard = ({ withdrawal: { attributes } }: IProps) => {
  const { amount_in_cents, created_timestamp, state, withdraw_uid } = attributes;

  return (
    <CardLink to={`/admin/funds/withdrawals/${withdraw_uid}`} aria-label={String(amount_in_cents)}>
      <StyledCard style={{ height: 'auto', width: 'auto' }}>
        <StyledCardSectionWrapper>
          <StyledSectionWrapper>
            <StyledDetailInfoSection>
              <StyledLabelP>Withdraw amount</StyledLabelP>
              <StyledTotalAmountP>{numberToUSDFormatter(amount_in_cents / 100)}</StyledTotalAmountP>
            </StyledDetailInfoSection>
            <StyledDetailInfoSection>
              <StyledLabelP>Date requested</StyledLabelP>
              <StyledDateP>{stringifyDate(initializeDate(created_timestamp))}</StyledDateP>
            </StyledDetailInfoSection>
            <StyledNumAndStatusWrapper>
              {state === WithdrawalStatus.PENDING ? (
                <StyledNewStatusTag>
                  <StyledStatusWrapper>{state}</StyledStatusWrapper>
                  <StyledIndicatorIcon />
                </StyledNewStatusTag>
              ) : (
                <StyledOtherClaimTag variant={getTagVariant(state)}>
                  {stateText(state)}
                </StyledOtherClaimTag>
              )}
            </StyledNumAndStatusWrapper>
          </StyledSectionWrapper>
        </StyledCardSectionWrapper>
      </StyledCard>
    </CardLink>
  );
};

export default WithdrawalCard;
