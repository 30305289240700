/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { CloseIcon, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  onClickClose: (Event) => void;
}

const closeButtonCSS = css({
  background: PALETTE.neutralDark,
  borderRadius: '20px',
  height: '32px',
  width: '32px',
  textAlign: 'center',
  border: 'none'
});

const SearchResultsHeader: React.FC<IProps> = ({ onClickClose }) => (
  <div css={{ display: 'flex', justifyContent: 'space-between' }}>
    <p css={FONTS.h3}>Search</p>
    <button css={closeButtonCSS} onClick={onClickClose} aria-label="Close">
      <CloseIcon height="16px" width="16px" color={PALETTE.neutralLight} css={{ marginTop: '7px' }} />
    </button>
  </div>
);

export default SearchResultsHeader;
