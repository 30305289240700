import { mapKeys, camelCase } from 'lodash';

export const camelize = (obj) => {
  const objectKeys = Object.keys(obj);

  objectKeys.forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = camelize(obj[key])
    }
  });

  return mapKeys(obj, (val, key) => camelCase(key))
};
