import React from 'react';
import { formatMoney } from 'utils/money';
import {
  StyledSummaryCard,
  ApprovableHeaderWrapper,
  StyledApprovableHeader,
  StyledApprovableAmount
} from './ClaimStyles';

export const ApprovedClaimAmountCard = ({ adjusted_amount_cents }) => {
  return (
    <>
      <StyledSummaryCard>
        <ApprovableHeaderWrapper>
          <StyledApprovableHeader>Approvable claim amount</StyledApprovableHeader>
        </ApprovableHeaderWrapper>
        <StyledApprovableAmount adjusted_amount_cents={adjusted_amount_cents}>
          {formatMoney(adjusted_amount_cents).maskedValue}
        </StyledApprovableAmount>
      </StyledSummaryCard>
    </>
  );
};
