import React from 'react';
import Modal from 'react-modal';
import styled from '@emotion/styled';
import { PALETTE, CloseIcon as CloseIconBase, Button, FONTS } from '@sayrhino/rhino-shared-js';
import { useUserId } from './utils/userRole';
import createPropertyInvitationAccessRequest from '../../../api/v2/createPropertyInvitationAccessRequest';
import useToast, { TOAST_STATUS } from './toast/use-toast';

Modal.setAppElement('body');

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  '@media(max-width:768px)': {
    flexDirection: 'column'
  }
});

const CloseIcon = styled(CloseIconBase)({
  position: 'absolute',
  top: '31px',
  right: '46px',
  cursor: 'pointer'
});

const ModalAddressText = styled.span({ color: PALETTE.brand100, fontWeight: 'bold' });

const ModalP = styled.p([FONTS.h5], { marginBottom: '68px', fontFamily: 'MaisonNeueLight' });

const ModalPMAEmailAddressText = styled.span({ fontWeight: 'bold' });

const ModalTitle = styled.h3([FONTS.h3], { marginBottom: '32px' });

const NevermindButton = styled(Button)({
  '&&': {
    width: '216px',
    color: PALETTE.brand100,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: PALETTE.brand100,
      color: PALETTE.neutralLight
    },
    '@media(max-width:768px)': {
      width: '100%',
      marginBottom: '10px'
    }
  }
});

const SendRequestButton = styled(Button)({
  '&&': {
    width: '216px',
    backgroundColor: PALETTE.brand100,
    justifyContent: 'center',
    '@media(max-width:768px)': {
      width: '100%'
    }
  }
});

const StyledModal = styled(Modal)({
  padding: '88px 44px 64px 44px',
  width: '574px',
  position: 'relative',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  background: PALETTE.neutralLight,
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '8px',
  outline: 0,
  '@media(max-width:768px)': {
    padding: '88px 20px 64px 20px',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    borderRadius: '0',
    transform: 'none'
  }
});

export const InvitationRequestPropertyAccessModal = ({
  modalAddressString,
  closeModal,
  isModalOpen,
  propertyManagerAdmins,
  propertyId
}) => {
  const userId = useUserId();
  const { addToast } = useToast();

  const handleSendRequest = async () => {
    try {
      const response = await createPropertyInvitationAccessRequest({
        property_id: propertyId,
        user_id: userId
      });
      if (response.status === 201) {
        addToast('Successfully requested access to this property', 'info', TOAST_STATUS.SUCCESS);
        return;
      }
    } catch (_e) {
      const e = _e;
      addToast('Internal error — Try again', 'error', TOAST_STATUS.ERROR);
    }

    closeModal();
  };

  return (
    <StyledModal isOpen={isModalOpen} onRequestClose={closeModal}>
      <ModalTitle>Request access to this property?</ModalTitle>
      <ModalP>
        We’ll send an email requesting access to <ModalAddressText>{modalAddressString}</ModalAddressText> to the
        following email address(es): <br />
        <ModalPMAEmailAddressText> {propertyManagerAdmins.map((p) => p.email).join(', ')}</ModalPMAEmailAddressText>
      </ModalP>
      <ButtonContainer>
        <NevermindButton variant="secondary" onClick={closeModal}>
          Never mind
        </NevermindButton>
        <SendRequestButton onClick={() => handleSendRequest()}>Send request</SendRequestButton>
      </ButtonContainer>
      <CloseIcon onClick={closeModal} />
    </StyledModal>
  );
};
