import axios, { AxiosResponse } from 'axios';
import { ACCOUNT_ROUTES } from 'components/v2/App/utils/routeHelpers';
import { csrfToken } from 'utils/document';
import { useMutation, MutationFunction } from 'react-query';

type IDisableTFAResponse = void | { errors: string[] };

const disableTFA: MutationFunction<AxiosResponse<IDisableTFAResponse>> = async (): Promise<
  AxiosResponse<IDisableTFAResponse>
> => {
  const authenticity_token = csrfToken();
  const response: AxiosResponse<IDisableTFAResponse> = await axios.post(
    '/v2/users/account/two_factor/disable',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useDisableTFAMutation() {
  return useMutation(disableTFA);
}
