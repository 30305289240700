import axios from 'axios';
import { LemonadeInterestedParty } from 'entities/lemonade';
import { csrfToken } from 'utils/document';

interface IPropertyOwnerInfo {
  id?: number;
  name: string;
  default_coverage_type: string;
  coverage_multiplier_limits?: Array<string> | null;
  default_coverage_amount_cents?: number;
  max_coverage_amount_cents?: number;
  claim_classification?: string;
  requires_invitation?: boolean | null;
  renters_insurance_requirement_attributes?: {
    requires_renters_insurance: boolean | null;
    has_exclusive_agreement: boolean | null;
    liability_coverage?: number | null;
    id: number | null;
  };
  lemonade_interested_party?: LemonadeInterestedParty
}

const createPropertyOwner = async (propertyOwnerInfo: IPropertyOwnerInfo) => {
  const {
    id,
    name,
    default_coverage_amount_cents,
    coverage_multiplier_limits,
    default_coverage_type,
    max_coverage_amount_cents,
    requires_invitation,
    renters_insurance_requirement_attributes,
    claim_classification,
    lemonade_interested_party
  } = propertyOwnerInfo;

  const token = csrfToken();

  let payload = {
    id,
    name,
    default_coverage_amount_cents,
    coverage_multiplier_limits,
    default_coverage_type,
    max_coverage_amount_cents,
    requires_invitation,
    renters_insurance_requirement_attributes,
    claim_classification
  }

  // @ts-ignore
  if (lemonade_interested_party) payload.lemonade_interested_party_attributes = lemonade_interested_party;

  const response = await axios.post<IPropertyOwnerInfo>(
    '/v2/admin/property_owners',
    payload,
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default createPropertyOwner;
