import React from 'react';
import { PALETTE, FONTS, FONT_FAMILY, Button, ButtonLink, ButtonHabitat } from '@sayrhino/rhino-shared-js';
import ReactTooltip from 'react-tooltip';
import styled from '@emotion/styled';
import Decimal from 'decimal.js';

import useRentersInsurancePolicyDetails from 'api/v2/useRentersInsurancePolicyDetails';
import usePropertyDetails from 'api/v2/usePropertyDetails';
import DownloadBanner from './DownloadBanner';
import { useSegmentUser } from './utils/segmentTracker';
import { centsToUSDFormatter, stringifyDate } from './utils';
import { useUserRole } from './utils/userRole';
import EmptyStateBanner from './emptyStateBanner';

const DetailsWrapper = styled.div({
  width: '480px',
  margin: 'auto',
  padding: '0 32px 89px 32px'
});

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 48px 16px 48px'
});

const Header = styled.h4([FONTS.h5]);

// uses !important to override default styles in ReactToolTip
const Tooltip = styled(ReactTooltip)({
  padding: '12px !important',
  border: `1px solid ${PALETTE.neutral12} !important`,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16) !important',
  borderRadius: '8px !important',
  opacity: '10 !important',
  maxWidth: '238px',
  left: '1080px!important',
  '&:hover': {
    visibility: 'visible'
  }
});

const TooltipText = styled.span([[FONTS.p2]]);

const TooltipHeaderText = styled.span([[FONTS.p3]]);

const TooltipIcon = styled.div([FONT_FAMILY.medium], {
  border: `1px solid ${PALETTE.neutralDark}`,
  height: '14px',
  width: '14px',
  lineHeight: '14px',
  fontSize: '10px',
  borderRadius: '10px',
  textAlign: 'center',
  marginLeft: '5px',
  '&:hover': {
    cursor: 'default',
    color: PALETTE.interaction100,
    border: `1px solid ${PALETTE.interaction100}`
  }
});

const TooltipContainer = styled.div({ display: 'flex', alignItems: 'center' });

const BannerTitle = styled.h5([[FONTS.p2Medium]]);

const BannerCreatedAt = styled.p([FONTS.p3Light, { color: PALETTE.neutral25 }]);

const FormField = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '12px',
  borderBottom: '1px solid #F4F4F4',
  margin: '12px 48px 0 48px',
  input: [
    FONTS.p1Light,
    {
      border: 'none',
      width: '75%'
    }
  ],
  label: [
    FONTS.p3Light,
    {
      color: PALETTE.neutral25,
      textAlign: 'right'
    }
  ]
});

const LearnMoreLink = styled.a({
  color: PALETTE.interaction100,
  marginLeft: '5px',
  textDecoration: 'underline'
});

const Divider = styled.div({
  margin: '80px 48px 0px 48px',
  borderTop: `1px solid ${PALETTE.neutral4}`
});

const InlineNotificationBanner = styled.div({
  padding: '0px 12px',
  height: '48px',
  width: '100%',
  borderRadius: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  background: PALETTE.neutral4
});

const StyledBoldSpan = styled.span([FONTS.p2Medium], {
  color: PALETTE.neutral65
});

const StyledSpan = styled.span([FONTS.p2], {
  color: PALETTE.neutral65
});

const RentersInsurancePolicyDetails = ({ stringifyDateRange, tenantId, propertyId }) => {
  const segmentUser = useSegmentUser();
  const { data: rentersInsurancePolicies } = useRentersInsurancePolicyDetails(tenantId);
  const { data: propertyDetails, isLoading } = usePropertyDetails(propertyId);
  const userRole = useUserRole();

  const hasExclusiveAgreement =
    propertyDetails?.property.renters_insurance_requirement_attributes?.has_exclusive_agreement;
  const hasRI = propertyDetails?.property.renters_insurance_requirement_attributes?.requires_renters_insurance;
  const PropertyOwnerId = propertyDetails?.property.property_owner?.id;

  const trackDownloadPolicy = (policyId) => {
    window.analytics.track('Renters Insurance Policy Document Downloading', {
      ...segmentUser,
      policyId
    });
  };

  const showPolicyStatus = (status: string, coverageStartDate: string, coverageEndDate: string) => {
    let today = new Date();
    switch (status) {
      case 'confirmed':
        if (new Date(coverageEndDate) < today) return 'Expired';
        else if (new Date(coverageStartDate) > today) return 'Upcoming';
        else {
          return 'Active';
        }
      case 'cancelled':
        return 'Cancelled';
      default:
        return status;
    }
  };

  const emptyState = () => {
    if (userRole.isPropertyManagerAdmin && hasRI === (null || undefined)) {
      return (
        <EmptyStateBanner
          title="No policy from Rhino"
          content="Set your requirements and we'll offer renters insurance to your renters at a 20% discount"
          callToAction={true}
          propertyOwnerID={PropertyOwnerId?.toString()}
        />
      );
    }
    if (!userRole.isPropertyManagerAdmin && !hasRI) {
      return (
        <EmptyStateBanner
          title="No policy"
          content="This renter has not signed up for renters insurance through Rhino."
        />
      );
    }
    if (hasRI) {
      return (
        <EmptyStateBanner
          title="No policy"
          content="This renter has not signed up for renters insurance through Rhino. Invite them to enroll and they'll get 20% off!"
        />
      );
    }
  };

  return (
    <DetailsWrapper>
      <HeaderContainer>
        <Header>Renters Insurance</Header>
        <TooltipContainer>
          <TooltipHeaderText>By Rhino</TooltipHeaderText>
          <TooltipIcon data-tip data-for="renters-insurance-tooltip">
            ?
          </TooltipIcon>
          <Tooltip
            id="renters-insurance-tooltip"
            type="light"
            effect="solid"
            place="bottom"
            delayHide={1000}
            clickable={true}
          >
            <TooltipText>
              Our renters insurance protects renters from certain perils and liabilities.
              <LearnMoreLink
                target="_blank"
                href="https://support.sayrhino.com/hc/en-us/articles/4404682463124-All-About-Renters-Insurance"
              >
                Learn more
              </LearnMoreLink>
            </TooltipText>
          </Tooltip>
        </TooltipContainer>
      </HeaderContainer>

      {rentersInsurancePolicies?.length ? (
        rentersInsurancePolicies.map((policy, idx) => {
          return (
            <div key={policy.id}>
              <div>
                {policy && policy.status === 'confirmed' && (
                  <>
                    <a
                      href={policy?.download_policy_document_url}
                      target="_blank"
                      onClick={() => trackDownloadPolicy(policy.id)}
                    >
                      <DownloadBanner iconVisible>
                        <BannerTitle>RI Policy Document</BannerTitle>
                        <BannerCreatedAt>
                          Created {policy.confirmed_at && stringifyDate(new Date(policy.confirmed_at))}
                        </BannerCreatedAt>
                      </DownloadBanner>
                    </a>
                  </>
                )}
                <FormField>
                  <input name="liabilityCoverage" value={centsToUSDFormatter(new Decimal(policy.liability))} readOnly />
                  <label htmlFor="liabilityCoverage">Liability coverage</label>
                </FormField>
                <FormField>
                  <input name="policyTerm" value={stringifyDate(new Date(policy.coverage_start_date))} readOnly />
                  <label htmlFor="policyTerm">Coverage start date</label>
                </FormField>
                <FormField>
                  <input
                    name="policyStatus"
                    value={showPolicyStatus(policy.status, policy.coverage_start_date, policy.coverage_end_date)}
                    readOnly
                  />
                  <label htmlFor="policyStatus">Policy status</label>
                </FormField>
              </div>
              {idx < rentersInsurancePolicies.length - 1 && <Divider />}
            </div>
          );
        })
      ) : (
        <>
          {hasExclusiveAgreement ? (
            <InlineNotificationBanner>
              <StyledBoldSpan>Disabled </StyledBoldSpan>
              <StyledSpan>&nbsp;&mdash; Due to an existing exclusive marketing agreement</StyledSpan>
            </InlineNotificationBanner>
          ) : (
            <>{!isLoading && emptyState()}</>
          )}
        </>
      )}
    </DetailsWrapper>
  );
};

export default RentersInsurancePolicyDetails;
