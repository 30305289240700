import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IPropertyUnitSearchResult } from 'components/v2/App/interfaces';

type PropertySearchResults = IPropertyUnitSearchResult[];

export const getUnits = async (query: string, propertyId?: number) => {
  if (!propertyId) {
    return [];
  }
  const { data } = await axios.get<PropertySearchResults>(`/properties/${propertyId}/units/search`, {
    params: { query }
  });

  return data;
};

export default function usePropertyUnitSearch(
  query: string,
  propertyId?: number
): QueryObserverResult<PropertySearchResults> {
  return useQuery<PropertySearchResults>(['propertyUnitSearch', query], () => getUnits(query, propertyId));
}
