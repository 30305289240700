/** @jsx jsx */
import { useState, useRef, useEffect, ChangeEvent } from 'react';
import useSearch from '../../../api/v2/useSearch';
import { jsx } from '@emotion/core';
import { Location } from 'history';
import useDebouncedEffect from 'use-debounced-effect-hook';
import qs from 'query-string';
import { matchPath } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchInput from './SearchInput';
import SearchResultsHeader from './SearchResultsHeader';
import SearchResults from './SearchResults';
import EmptySearchResults from './EmptySearchResults';
import { SortOrder, SearchResultType, isSortOrder, isSearchResultType } from './interfaces';
import { parseIntDecimal, useReferrer } from './utils';
import { FONTS } from '@sayrhino/rhino-shared-js';

const REQUEST_INTERVAL = 500; // ms

export const humanizedSearchResultTypeMappings = {
  InsurancePolicy: 'Policies',
  Claim: 'Claims',
  Invitation: 'Invitations',
  Property: 'Properties'
};

export const getHumanizedSearchResultType = (type) => humanizedSearchResultTypeMappings[type];

export interface ISearchParams {
  query: string;
  sortBy: SortOrder;
  type: SearchResultType | null;
  referrer?: string;
  priority: string;
}

function isSearchUrl(url: string): boolean {
  const parsedUrl = qs.parseUrl(url);
  return matchPath('/search', parsedUrl.url) !== null;
}

export function parseSearchParams(location: Location, referrer?: string): ISearchParams {
  const referrerUrl = referrer !== undefined ? qs.parseUrl(referrer, { parseNumbers: false }) : undefined;
  referrerUrl?.query;
  const queryString =
    referrerUrl !== undefined && isSearchUrl(referrerUrl.url)
      ? referrerUrl.query
      : qs.parse(location.search, { parseNumbers: false });

  const query = typeof queryString.query === 'string' ? queryString.query : '';
  const sortByInt = queryString.sortBy !== 'string' ? 0 : parseIntDecimal(queryString.sortBy);
  const sortBy = isSortOrder(sortByInt) ? sortByInt : SortOrder.Latest;
  const type = isSearchResultType(queryString.type) ? queryString.type : null;
  const searchReferrer = typeof queryString.referrer === 'string' ? queryString.referrer : undefined;

  let priorityObj = '';
  if (referrer === '/admin/renter_policies') {
    priorityObj = 'InsurancePolicy';
  } else if (referrer === '/admin/invitations') {
    priorityObj = 'Invitation';
  } else if (referrer === '/admin/claims') {
    priorityObj = 'Claim';
  } else {
    priorityObj = 'Property';
  }

  const priority = typeof queryString.priority === 'string' ? queryString.priority : priorityObj;

  return {
    query,
    sortBy,
    type,
    referrer: searchReferrer,
    priority
  };
}

export function searchUrlFromParams(params: ISearchParams) {
  const { query, sortBy, type, referrer } = params;
  return qs.stringifyUrl({
    url: '/search',
    query: {
      query,
      sortBy,
      type,
      referrer: referrer || null
    }
  });
}

export const Search = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const referrer = useReferrer();
  const searchParams = parseSearchParams(location, referrer);
  const [query, setQuery] = useState<string>(searchParams.query);
  const [type, setType] = useState<SearchResultType | null>(searchParams.type);
  const inputRef = useRef<HTMLInputElement>(null);
  const response = useSearch(searchParams.query, searchParams.sortBy, searchParams.type, searchParams.priority);
  const { isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = response;
  const results = response.data;

  useEffect(() => inputRef.current?.focus(), []);

  const navigateToNextQuery = (): void => {
    const searchQuery = { query, sortBy: SortOrder.Latest, type };
    let url = qs.stringifyUrl({
      url: location.pathname,
      query: {
        ...searchQuery,
        referrer
      }
    });
    if (referrer !== undefined && isSearchUrl(referrer)) {
      const referrerUrl = qs.parseUrl(referrer);
      url = qs.stringifyUrl({
        url: location.pathname + location.search,
        query: {
          referrer: qs.stringifyUrl({
            url: referrerUrl.url,
            query: {
              ...referrerUrl.query,
              ...searchQuery
            }
          })
        }
      });
    }
    if (url !== location.pathname + location.search) {
      const options = { replace: true };
      navigate(url, options);
    }
  };
  useEffect(navigateToNextQuery, [type]);
  useDebouncedEffect(navigateToNextQuery, [query], REQUEST_INTERVAL);

  const handleChangeType = (nextType) => setType(nextType === type ? null : nextType);

  function handleCloseSearch() {
    const options = { replace: true };
    if (searchParams.referrer !== undefined) {
      navigate(searchParams.referrer, options);
    } else {
      navigate('/', options);
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e?.target?.value);
  };

  return (
    <div>
      <SearchInput value={query} onChange={handleInputChange} ref={inputRef} />

      <div css={{ padding: '32px 48px' }}>
        <SearchResultsHeader onClickClose={() => handleCloseSearch()} />

        {query?.length ? (
          <SearchResults
            isLoading={isLoading}
            results={results?.pages}
            onClickFetchMore={() => fetchNextPage()}
            onChangeType={handleChangeType}
            isFetchingMore={Boolean(isFetchingNextPage)}
            canFetchMore={!!hasNextPage}
            type={type}
          />
        ) : (
          <EmptySearchResults>
            <span>
              <p css={FONTS.h3}>Start typing to search</p>
              <p css={FONTS.p1}>Search for policies, claims, properties, or invitations</p>
            </span>
          </EmptySearchResults>
        )}
      </div>
    </div>
  );
};

export default Search;
