import styled from '@emotion/styled';
import React from 'react';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import MultiSelect from 'components/v2/select/MultiSelect';

export const StyledBorder = styled.div({
  display: 'flex',
  border: `solid 1px ${PALETTE.neutral25}`,
  borderRadius: '4px',
  justifyContent: 'center',
  padding: '4px 1px 1px 4px',
  width: '100%',
  boxShadow: PALETTE.neutral25
});

const StyledBuildingName = styled.div(FONTS.h6Medium);

interface Property {
  property_id: number;
  building_name: string;
  address: string;
}

export interface IProps {
  properties: Property[];
  handleChange: (selectedValues: Property[]) => void;
  error?: string | null;
}

const IntegrationPropertySelect: React.FC<IProps> = ({ properties, handleChange, error }) => {
  //TODO ge api to get all properties by integration
  // use fake data for now
  const list = [{ property_id: 123, building_name: 'cool haus', address: '111 new street, NY 10011' }];

  return (
    <StyledBorder>
      <MultiSelect
        id="propertySelect"
        placeholder="Properties"
        options={list || []}
        value={properties}
        allowSelectAll
        labelKey="building_name"
        altLabelKey="address"
        valueKey="property_id"
        onChange={handleChange}
        formatOptionLabel={(option, { context }) => {
          return context === 'menu' ? (
            <>
              <StyledBuildingName>{option.building_name}</StyledBuildingName>
              {option.address ? `: ${option.address}` : ''}
            </>
          ) : (
            option.building_name
          );
        }}
      />
    </StyledBorder>
  );
};

export default IntegrationPropertySelect;
