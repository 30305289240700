import createBulkCoverageRules from 'api/v2/createUpdateBulkCoverageRules';
import updatePartnerIntegration from 'api/v2/updatePartnerIntegration';
import usePartnerCoverageRules, { COVERAGE_RULES_KEY } from 'api/v2/usePartnerCoverageRules';
import usePartnerIntegration from 'api/v2/usePartnerIntegration';
import usePropertyCoverageRules, { PROPERTY_COVERAGE_RULES_KEY } from 'api/v2/usePropertyCoverageRules';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ICoverageRule, ICoverageRulesetPostNew, IRulesetError } from '../interfaces';
import useToast from '../toast/use-toast';
import { IInviteCriteria } from './interface';
import { AddCoverageTypeEnum, CoverageInputEnum } from './util/rulesetConstant';

const useCoverageRuleFormNew = () => {
  const { partnerIntegrationId, propertyOwnerId } = useParams();
  const { data: propertyRuleSetData } = usePropertyCoverageRules(propertyOwnerId) ?? {};
  const { data } = usePartnerIntegration(partnerIntegrationId) ?? {};
  const { integration } = data ?? {};
  const { mutate: createCoverageRulesMutation } = useMutation(createBulkCoverageRules, {});
  const { mutate: updatePartnerMutation, isLoading: isUpdatePartnerLoading } = useMutation(
    updatePartnerIntegration,
    {}
  );
  const { data: coverageRuleSetData } = usePartnerCoverageRules(partnerIntegrationId) ?? {};
  const partnerCoverageRuleSet = coverageRuleSetData
    ? coverageRuleSetData.filter((ruleset) => ruleset.default === true)[0]
    : undefined;

  const partnerRuleSetId: number | undefined = partnerCoverageRuleSet?.id;
  const { addToast } = useToast();
  const navigate = useNavigate();

  const formatCoverageRulesets = (rulesets?: ICoverageRulesetPostNew[]) => {
    return rulesets?.map((item) => {
      return formatRuleset(item);
    });
  };

  const getCoverageType = (coverageType: string | undefined) => {
    if (coverageType === 'amount_cents') {
      return AddCoverageTypeEnum.DOLLAR_AMOUNT;
    }
    if (coverageType === 'multiplier') {
      return AddCoverageTypeEnum.MONTHLY_RENT;
    }
    if (coverageType === 'unit_property_multiplier') {
      return AddCoverageTypeEnum.UNIT_PROPERTY;
    }
    if (coverageType === 'realpage_deposit_charges') {
      return AddCoverageTypeEnum.RP_DEPOSIT_CHARGES;
    }
    return AddCoverageTypeEnum.MONTHLY_RENT;
  };

  // we might need default protection here
  const customCoverageType = (coverageType: string) => {
    switch (coverageType) {
      case AddCoverageTypeEnum.DOLLAR_AMOUNT:
        return CoverageInputEnum.AMOUNT_CENTS;
      case AddCoverageTypeEnum.MONTHLY_RENT:
        return CoverageInputEnum.MULTIPLIER;
      case AddCoverageTypeEnum.UNIT_PROPERTY:
        return CoverageInputEnum.UNIT_PROPERTY;
      case AddCoverageTypeEnum.RP_DEPOSIT_CHARGES:
        return CoverageInputEnum.RP_DEPOSIT_CHARGES;
    }
  };

  const formatRuleset = (item: ICoverageRulesetPostNew) => {
    const coverage_rules = item.coverage_rules.map((elem) => {
      let map = {
        Approval: 'Approved',
        'Approval with Conditions': 'Approved with Conditions',
        'All Other Yardi Residents': 'All other credit check statuses'
      };
      let coverage_option_name = map[elem.coverage_option_name];

      return {
        ...elem,
        coverage_option_name: coverage_option_name,
        coverage_type: getCoverageType(elem.coverage_type),
        cash_deposit_type: getCoverageType(elem.cash_deposit_type)
      };
    });

    return { ...item, coverage_rules };
  };

  const [partnerRulesets, setPartnerRulesets] = useState<ICoverageRulesetPostNew>(
    partnerCoverageRuleSet
      ? formatRuleset(partnerCoverageRuleSet)
      : {
          type: 'integration_partner',
          coverageable_ids: [partnerIntegrationId!],
          coverage_rules: [],
          default_coverageable_ids: [partnerIntegrationId!],
          invite_criteria: {
            allowed_screening_results: [],
            allowed_credit_check_statuses: [],
            use_renter_approval_date: false
          },
          name: 'default',
          id: partnerRuleSetId
        }
  );

  const [partnerRulesetError, setPartnerRulesetsError] = useState<IRulesetError>({
    coverage_rules: ''
  });

  const [customRulesets, setCustomRulesets] = useState<ICoverageRulesetPostNew[]>(
    formatCoverageRulesets(propertyRuleSetData) || []
  );

  const [deletedCustomRulesets, setDeletedCustomRulesets] = useState<ICoverageRulesetPostNew[]>([]);

  const [showPartnerRulesets, setShowPartnerRulesets] = useState<boolean>(
    integration ? integration.enabled_partner_rule_set : true
  );
  const [showCustomRulesets, setShowCustomRulesets] = useState<boolean>(
    integration ? integration.enabled_custom_rule_set : true
  );
  const queryCache = useQueryClient();

  const coverageRules = (coverage_rules: ICoverageRule[]) => {
    setPartnerRulesets({ ...partnerRulesets, coverage_rules });
  };

  const addInviteCriteria = ({ screening, creditCheckStatus, userRenter }: IInviteCriteria) => {
    const invite_criteria = inviteCriteriaStructure({ screening, creditCheckStatus, userRenter });
    setPartnerRulesets({ ...partnerRulesets, invite_criteria });
  };

  const inviteCriteriaStructure = ({ screening, creditCheckStatus, userRenter }: IInviteCriteria) => {
    return {
      allowed_screening_results: screening || [],
      allowed_credit_check_statuses: creditCheckStatus || [],
      use_renter_approval_date: userRenter ?? false
    };
  };

  const checkPartnerHasError = () => {
    let coverageRuleErr = '';
    if (partnerRulesets.coverage_rules.length === 0 && showPartnerRulesets) {
      coverageRuleErr = 'Create at least one coverage amount on partner ruleset';
      setPartnerRulesetsError({
        coverage_rules: coverageRuleErr
      });
      addToast('Create at least one coverage amount on partner ruleset', 'error');
      return true;
    }
    return;
  };

  const createCoverageRule = () => {
    if (checkPartnerHasError()) {
      return;
    }

    if (showCustomRulesets && customRulesets.length === 0) {
      addToast('Create at least one custom ruleset', 'error');
      return;
    }

    const allRulesets: ICoverageRulesetPostNew[] = [];
    allRulesets.push(partnerRulesets);

    if (customRulesets.length > 0) {
      allRulesets.push(...customRulesets);
    }

    if (deletedCustomRulesets.length > 0) {
      allRulesets.push(...deletedCustomRulesets);
    }

    const payload = allRulesets.map((ruleset) => {
      const coverage_rules = ruleset.coverage_rules.map((coverageRule) => {
        let map = {
          Approved: 'Approval',
          'Approved with Conditions': 'Approval with Conditions',
          'All other credit check statuses': 'All Other Yardi Residents'
        };
        let coverage_option_name = map[coverageRule.coverage_option_name];

        if (coverage_option_name === undefined) return coverageRule;

        return {
          ...coverageRule,
          coverage_option_name: coverage_option_name,
          // we're forcing the typescript here, but this isn't a new workaround
          // the previous implementation had the same problem, but wasn't typed
          // so no error was thrown
          coverage_type: customCoverageType(coverageRule.coverage_type) as string,
          cash_deposit_type: customCoverageType(coverageRule.cash_deposit_type) as string
        };
      });
      return { ...ruleset, coverage_rules };
    });

    createCoverageRulesMutation(payload, {
      onSuccess: (e) => {
        addToast('Integration setup submitted');
        // invalidate queries
        queryCache.invalidateQueries([PROPERTY_COVERAGE_RULES_KEY, propertyOwnerId]);
        queryCache.invalidateQueries([COVERAGE_RULES_KEY, partnerIntegrationId]);

        // update integration to denote which coverage rules are active
        if (integration?.type) {
          updateCoverageRuleUse(showPartnerRulesets, showCustomRulesets, partnerIntegrationId!, integration.type);
        }
        navigate(-1);
      },
      onError: (e: AxiosError) => {
        const { data } = e?.response ?? {};
        addToast('Something went wrong', 'error');
        const [partnerRuleSetError, ...customRuleSetErrors] = data.map(({ errors }) => {
          const formattedError: string[] = [];
          errors?.forEach(({ coverage_rule_set }) => {
            if (coverage_rule_set) formattedError.push(coverage_rule_set?.join('\n'));
          });
          return formattedError.join('\n');
        });
      }
    });
  };

  const updateCoverageRuleUse = (
    enabled_partner_rule_set: boolean,
    enabled_custom_rule_set: boolean,
    uuid: string,
    type: string,
    invite_resident_statuses?: Array<string>,
    invite_resident_credit_statuses?: Array<string>
  ) => {
    updatePartnerMutation({
      enabled_partner_rule_set,
      enabled_custom_rule_set,
      uuid,
      type,
      invite_resident_statuses,
      invite_resident_credit_statuses
    });
  };

  return {
    partnerRulesets,
    setPartnerRulesets,
    coverageRules,
    addInviteCriteria,
    createCoverageRule,
    customRulesets,
    setCustomRulesets,
    deletedCustomRulesets,
    setDeletedCustomRulesets,
    showPartnerRulesets,
    setShowPartnerRulesets,
    showCustomRulesets,
    setShowCustomRulesets,
    partnerRulesetError,
    setPartnerRulesetsError
  };
};

export default useCoverageRuleFormNew;
