import React from 'react';
import { flatMap } from 'lodash';
import { AlertIcon, InlineNotification, PALETTE, Card } from '@sayrhino/rhino-shared-js';
import PagingList from '../PagingList';
import { PageWrapper, PageTitle, Name } from './Styled';
import useUserRole from '../utils/userRole';
import styled from '@emotion/styled';
import CardLink from '../CardLink';

const PropertyOwners = ({ propertyOwnersResponse }) => {
  const { isPropertyManagerAdmin } = useUserRole();
  const { hasNextPage, fetchNextPage, isSuccess, isFetchingNextPage, data } = propertyOwnersResponse;
  const propertyOwners = flatMap(data?.pages, (d) => d.data || []);
  const totalCount = isSuccess && propertyOwnersResponse.data?.pages[0].meta.total_count;

  const OrangeNotificationBanner = styled.div({
    margin: 'auto',
    borderRadius: '8px',
    padding: '0px 24px 8px 8px'
  });

  return (
    <PageWrapper>
      <PageTitle>Property Owner{propertyOwners.length > 1 ? 's' : ''}</PageTitle>
      {isSuccess &&
        propertyOwners.map(({ id, name, renters_insurance_requirement_attributes }) => {
          return (
            <CardLink key={id} to={String(id)}>
              <Card style={{ height: 'initial', width: 'auto' }}>
                <Name>{name}</Name>
                {isPropertyManagerAdmin &&
                  (!renters_insurance_requirement_attributes ||
                    renters_insurance_requirement_attributes.requires_renters_insurance === null) && (
                    <OrangeNotificationBanner>
                      <InlineNotification variant="lowPriority">
                        Update &mdash; Missing Renters Insurance requirements
                        <AlertIcon color={PALETTE.warning100} height="20px" width="20px" />
                      </InlineNotification>
                    </OrangeNotificationBanner>
                  )}
              </Card>
            </CardLink>
          );
        })}
      {totalCount > 20 && (
        <PagingList
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasData //We are already checking that there is data to display the component
        />
      )}
    </PageWrapper>
  );
};

export default PropertyOwners;
