import React from 'react';
import { Name } from '../Account/Styled';
import styled from '@emotion/styled';

export const Card = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid black',
  padding: '25px 30px',
  margin: '10px'
});

const ButtonsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '240px',
  alignItems: 'center'
});

const Link = styled.a({
  fontSize: 14,
  textDecoration: 'underline'
});

const Status = styled.p`
  font-size: 18px;
  font-weight: bolder;
  color: ${(props) => props.color};
`;

interface IInsuranceCarrierMarketProps {
  id: number;
  carrier_name: string;
  enabled: boolean;
  market_type: number;
  actions: {
    editable: boolean;
  };
}

interface IInsuranceCarrierMarketArrayProps {
  insurance_carrier_markets: IInsuranceCarrierMarketProps[];
}

const InsuranceCarrierMarkets: React.FC<IInsuranceCarrierMarketArrayProps> = (props) => {
  const { insurance_carrier_markets } = props;
  const insuranceCarrierMarkets = insurance_carrier_markets;

  return (
    <div>
      {insuranceCarrierMarkets.map(({ id, carrier_name, market_type, enabled, actions }) => {
        return (
          <Card key={id}>
            <div>
              <Link href={`/v2/admin/insurance_carrier_markets/${id}`} id={`${carrier_name}_${market_type}_button`}>
                <Name>
                  {carrier_name} - {market_type}
                </Name>
              </Link>
            </div>
            <ButtonsWrapper>
              <Status color={enabled ? 'green' : 'red'}>{enabled ? 'ENABLED' : 'DISABLED'}</Status>
              <div>
                <Link
                  href={`/v2/admin/insurance_carrier_markets/${id}/states`}
                  id={`${carrier_name}_${market_type}_button`}
                >
                  View States
                </Link>
              </div>
            </ButtonsWrapper>
          </Card>
        );
      })}
    </div>
  );
};

export default InsuranceCarrierMarkets;
