import axios from 'axios';
import { csrfToken } from 'utils/document';
import { IRenewalRequest } from 'components/v2/App/Invitation/PolicyRenewalForm';

export default function createRenewalRequest(params: IRenewalRequest) {
  const token = csrfToken();
  return axios.post(`/v2/admin/insurance_policies/${params.insurance_policy_id}/renewal_request`, {
    authenticity_token: token,
    unit_id: params.unit_id,
    renewal_request: params
  });
}
