import React, { Component } from 'react';

import Select from 'components/v2/select/Select';
import { fetchGooglePredictions, fetchPlaceById, GooglePlaceResults } from 'utils/google';
import headOr from 'utils/headOr';

interface IProps {
  value?: string;
  resource?: string;
  disabled?: boolean;
}
interface IState {
  options: IGooglePrediction[];
  selected?: IGooglePrediction;
  isLoading: boolean;
}
interface IGooglePrediction {
  place_id: string;
  description: string;
}
interface IGooglePlace {
  place_id: string;
  formatted_address: string;
}

class GooglePlaceIdSelect extends Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    resource: 'property'
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      options: [],
      selected: { place_id: '', description: '' },
      isLoading: false
    };
  }

  public componentDidMount() {
    if (this.props.value) {
      fetchPlaceById(this.props.value).then((results: GooglePlaceResults) => {
        const { place } = results;
        if (!place) {
          return;
        }

        const { place_id, formatted_address } = place;

        const selected: IGooglePrediction = {
          place_id,
          description: formatted_address
        };

        this.setState({ selected, options: [selected] });
      });
    }
  }

  public render() {
    const { isLoading, selected, options } = this.state;
    const { resource, disabled } = this.props;
    const value = selected ? selected.place_id : '';

    return (
      <div id="react_select_google_place_id">
        <Select
          name="google_place_select"
          id="google_place_select"
          labelKey="description"
          valueKey="place_id"
          simpleValue={value}
          autoload={false}
          isLoading={isLoading}
          options={options}
          onInputChange={this.fetchPredictions}
          onChange={this.onChange}
          placeholder="Type address..."
          isDisabled={disabled}
        />

        <input
          type="hidden"
          name={`${resource}[google_place_id]`}
          id={`${resource}_google_place_id`}
          value={value}
          readOnly={true}
        />
      </div>
    );
  }

  private onChange = (selected: IGooglePrediction) => {
    this.setState({ selected });
  };

  private fetchPredictions = (input: string) => {
    if (!input) {
      return;
    }

    this.setState({ isLoading: true });

    fetchGooglePredictions(input)
      .then((options: IGooglePrediction[]) => {
        this.setState({ isLoading: false, options });
      })
      .catch((errorStatus: string) => {
        this.setState({ isLoading: false, options: [] });
      });
  };
}

export default GooglePlaceIdSelect;
