import styled from 'styled-components';

export const Sidebar = styled.div`
  position: fixed;
  z-index: 100;
  width: 578px;
  height: 100%;
  right: 0px;
  top: 75px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(61,0,148,0.1);
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 80px;
  overflow-y: scroll;
`;

export const Section = styled.div`
  :first-child {
    padding-top: 46px;
    padding-bottom: 5px;
  }
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F4F4F4;
`;

export const Title = styled.div`
  font-family: MaisonNeueExtendedMedium;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 15px;
`;

export const Clear = styled.a`
  padding-right: 30px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Right = styled.div`
  float: right;
  display: table;
  > a, svg {
    display: table-cell;
    vertical-align: middle;
  }
`;

export const Li = styled.li`
  font-family:MaisonNeueLight;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  margin-top: 21px;
  margin-bottom: 30px;
  :first-child {
    margin-top: 0px;
  }
  :last-child {
    margin-bottom: 10px;
  }
  &.checked {
    background: #000000;
    font-family: MaisonNeueMedium;
    color: white;
    span {
    color: black;
    }
  }
`;

export const Count = styled.span`
  font-family: MaisonNeueMedium;
  background: #F4F4F4;
  border-radius: 20px;
  padding-left: 18px;
  padding-right: 18px;
  float: right;
  padding-top: 6px;
  margin-left: 17px;
  margin-right: -13px;
  margin-top: -5px;
  height: 34px;
`

export const Checkbox = styled.label`
  position: relative;
  font-family:MaisonNeueLight;
  font-size: 14px;

  input {
    margin-right: 8px;
    vertical-align: middle;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    appearance: none;
    +span {
      display: none;
    }
    :checked {
      background: #000000;
      border-color:black;
    }
    :checked + span {
      display: inline;
      position: absolute;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      left: 3px;
      top: 5px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.28564 5.2853L5.43199 9.2853L13.4285 0.713867' stroke='white'/%3E%3C/svg%3E");
    }
  }
`;
