import { IPMAPropertyEntityDetails } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

const getPropertyEntityDetails = async (id: number) => {
  const { data } = await axios.get<IPMAPropertyEntityDetails>(`/v2/admin/policy_holders/${id}`);
  return data;
};

export default function usePropertyEntityDetails(id: number): QueryObserverResult<IPMAPropertyEntityDetails> {
  return useQuery<IPMAPropertyEntityDetails>(['property entity details', id], () => getPropertyEntityDetails(id));
}
