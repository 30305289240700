/** @jsx jsx */
import { jsx } from '@emotion/core';
import { InlineNotification, Alert2Icon, PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  numRows: number | undefined;
}

const ErrorNotification = (props: IProps) => {
  const { numRows } = props;
  let rowProgressMessage;

  if (numRows === undefined) {
    rowProgressMessage = null;
  } else if (numRows > 1) {
    rowProgressMessage = `${numRows} rows to go!`;
  } else {
    rowProgressMessage = '1 row to go!';
  }

  return (
    <InlineNotification
      variant="highPriority"
      css={{ width: '576px', position: 'fixed', bottom: '112px', left: '35%', borderRadius: '8px' }}
    >
      <div style={{ color: 'unset' }}>
        <span style={{ color: 'unset' }}>Please fix all remaining errors before continuing</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', color: 'unset' }}>
        {rowProgressMessage}
        <Alert2Icon color={PALETTE.alert100} style={{ marginLeft: '8px' }} height="20px" width="20px" />
      </div>
    </InlineNotification>
  );
};

export default ErrorNotification;
