import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IInsurancePolicySummaryMetricsData } from 'components/v2/App/interfaces';

const getInsurancePolicySummaryMetrics = async (): Promise<IInsurancePolicySummaryMetricsData> => {
  const {
    data
  }: AxiosResponse<IInsurancePolicySummaryMetricsData> = await axios.get<IInsurancePolicySummaryMetricsData>(
    `/v2/admin/insurance_policies/summary_metrics`
  );
  return data;
};

export function useInsurancePolicySummaryMetrics(): QueryObserverResult<IInsurancePolicySummaryMetricsData> {
  return useQuery<IInsurancePolicySummaryMetricsData>(
    'insurancePolicySummaryMetrics',
    getInsurancePolicySummaryMetrics
  );
}
