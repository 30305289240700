import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IStaticFilterOptions } from '../../components/v2/App/interfaces';

export const getInvitationStaticFilterOptions = async (): Promise<IStaticFilterOptions> => {
  const { data }: AxiosResponse<IStaticFilterOptions> = await axios.get<IStaticFilterOptions>(
    `/v2/admin/invitations/static_filter_options`
  );

  return data;
};

export const invitationStaticFilterOptionsKey = 'invitationStaticFilterOptions';

export default function useInvitationStaticFilterOptions(): QueryObserverResult<IStaticFilterOptions> {
  return useQuery<IStaticFilterOptions>([invitationStaticFilterOptionsKey], () => getInvitationStaticFilterOptions());
}
