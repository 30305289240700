import React, { PureComponent, ReactNode } from 'react';

interface IProps {
  className?: string;
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  children: ReactNode;
}

class Column extends PureComponent<IProps, {}> {
  public render() {
    const { className = '', xs, sm, md, lg, children } = this.props;

    let colClass = '';
    if (xs) { colClass += `col-xs-${xs} `; }
    if (sm) { colClass += `col-sm-${sm} `; }
    if (md) { colClass += `col-md-${md} `; }
    if (lg) { colClass += `col-lg-${lg} `; }

    return (
      <div className={`${colClass} ${className}`}>
        {children}
      </div>
    );
  }
}

export default Column;
