import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  IPropertyCsvUploadErrors,
  IPropertyCsvUploadResponse,
  IPropertyCsvUploadValidationResponse,
  PropertyCsv
} from 'components/tables/property_upload_table/interfaces';
import { useMutation, UseMutationResult } from 'react-query';
import { csrfToken } from 'utils/document';

export interface IValidatePropertyUploadVariables {
  csv: PropertyCsv;
}

export function validatePropertyUpload({
  csv
}: IValidatePropertyUploadVariables): Promise<
  AxiosResponse<IPropertyCsvUploadResponse | IPropertyCsvUploadValidationResponse>
> {
  const token = csrfToken();
  return axios.put('/admin/properties/validate_json', { authenticity_token: token, csv });
}

export default function useValidatePropertyUploadMutation(
  config = {}
): UseMutationResult<
  AxiosResponse<IPropertyCsvUploadResponse | IPropertyCsvUploadValidationResponse>,
  AxiosError<IPropertyCsvUploadErrors>,
  IValidatePropertyUploadVariables
> {
  return useMutation(validatePropertyUpload, config);
}
