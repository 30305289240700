import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Alert2Icon, Card as CardBase, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { centsToUSDFormatter } from '../utils';
import Decimal from 'decimal.js';
import CustomCoverageRulesForm from './CustomCoverageRulesForm';
import { FormMode, ICoverageRule, ICustomCoverageRuleset, IPropertySearchResult } from '../interfaces';
import WarningModal from './WarningModal';

const Card = styled(CardBase)({
  height: 'auto !important',
  width: '100% !important',
  padding: '30px 48px',
  cursor: 'auto !important',
  margin: '15px auto'
});

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${PALETTE.neutral25}`,
  paddingBottom: '16px'
});

const Title = styled.h4([FONTS.h4, { color: PALETTE.neutral88, fontSize: '16px' }]);
const StyledRowLi = styled.li({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${PALETTE.neutral25}`,
  padding: '12px 0',
  fontSize: '16px',
  lineHeight: '28px'
});

const StyledEditLink = styled.a(FONTS.p2Medium, {
  cursor: 'pointer',
  textDecoration: 'underline',
  color: PALETTE.interaction100,
  lineHeight: '24px'
});
const StyledTagWrapper = styled.div({ display: 'flex', alignItems: 'center', marginTop: '16px' });
const StyledTag = styled.div([
  FONTS.p2Medium,
  {
    background: PALETTE.neutral4,
    padding: '4px 8px',
    borderRadius: '4px',
    color: PALETTE.neutral65,
    marginRight: '8px'
  }
]);
const StyledError = styled.div({ display: 'flex', alignItems: 'center', marginTop: '8px' });
const StyledErrorMessage = styled.label([
  FONTS.p3Light,
  { color: PALETTE.alert125, whiteSpace: 'pre-line', width: '85%' }
]);
const BoldText = styled.span([FONTS.p2Medium]);

interface IProps {
  rulesetName: string;
  approvalRule: ICoverageRule;
  approvalConditionsRule: ICoverageRule;
  approvalConditionsGTDepositRule: ICoverageRule;
  approvalConditionsLTEDepositRule: ICoverageRule;
  rulesetDepositThresholdCents?: number;
  properties: IPropertySearchResult[];
  index: number;
  handleRemoveRuleSet?: (index: number) => void;
  handleUpdateRuleset: (value: ICustomCoverageRuleset) => void;
  uuid: string;
  error?: string;
  id?: number;
  handleErrorReset: () => void;
}

const CustomCoverageRuleCard: React.FC<IProps> = ({
  rulesetName,
  rulesetDepositThresholdCents,
  approvalRule,
  approvalConditionsRule,
  approvalConditionsGTDepositRule,
  approvalConditionsLTEDepositRule,
  properties,
  handleRemoveRuleSet,
  handleUpdateRuleset,
  index,
  uuid,
  error,
  id,
  handleErrorReset
}) => {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [isWarningModalOpen, setWarningModal] = useState<boolean>(false);

  const formattedRuleValue = (rule: ICoverageRule, cash: boolean) => {
    if (!rule) {
      return '';
    }
    if (cash && rule.coverage_option_name === "Approval") {
      console.log('cash', rule);
    }
    const value = rule[`${cash? 'cash_deposit_coverage_value' : 'coverage_value'}`];
    const type = rule[`${cash? 'cash_deposit_type' : 'coverage_type'}`];

    switch(type) {
      case 'realpage_deposit_charges':
        return 'RealPage Deposits';
      case 'multiplier':
        return `${value}X Monthly rent`;
      default:
        return centsToUSDFormatter(new Decimal(value));
    };
  };

  const formatedRulesetDepositThresholdCents = centsToUSDFormatter(new Decimal(rulesetDepositThresholdCents || 0));

  const customCoverageRule = (coverageRule: ICoverageRule) => {
    return {
      coverage_option_name: coverageRule.coverage_option_name,
      coverage_type: coverageRule.coverage_type,
      coverage_value: coverageRule.coverage_value,
      ...(coverageRule.same_cash_deposit ? {
        same_cash_deposit: true,
        cash_deposit_type: coverageRule.coverage_type,
        cash_deposit_coverage_value: coverageRule.coverage_value,
      } : {
        same_cash_deposit: false,
        cash_deposit_type: coverageRule.cash_deposit_type,
        cash_deposit_coverage_value: coverageRule.cash_deposit_coverage_value
      }),
      id: coverageRule.id
    };
  }

  const ruleset = (): ICustomCoverageRuleset => {
    let customCoverargeRuleset = {
      name: rulesetName,
      coverage_rules: [
        customCoverageRule(approvalRule),
        customCoverageRule(approvalConditionsRule),
      ],
      default: false,
      coverageable_ids: [],
      properties: properties,
      deposit_threshold_cents: rulesetDepositThresholdCents,
      type: 'property',
      uuid: uuid,
      id: id
    };

    let depositRules = [approvalConditionsGTDepositRule, approvalConditionsLTEDepositRule];
    depositRules.forEach((rule) => {
      if (rule) {
        customCoverargeRuleset.coverage_rules.push(customCoverageRule(rule));
      }
    });

    return customCoverargeRuleset;
  }

  const toggleEdit = () => {
    setEditMode(!isEditMode);
  };

  const handleUpdate = (value) => {
    handleUpdateRuleset(value);
    handleErrorReset();
  };

  return (
    <>
      <WarningModal
        title="Are you sure you want to delete this ruleset?"
        subTitle={
          <React.Fragment>
            The coverage rules for all properties associated with this ruleset will revert to your partner ruleset.
            <BoldText> This action cannot be undone.</BoldText>
          </React.Fragment>
        }
        confirmButtonText="Yes, delete ruleset"
        isOpen={isWarningModalOpen}
        handleConfirm={() => {
          handleRemoveRuleSet && handleRemoveRuleSet(index);
          setWarningModal(false);
          if (toggleEdit) {
            toggleEdit();
          }
        }}
        closeModal={() => setWarningModal(false)}
      />
      {isEditMode ? (
        <CustomCoverageRulesForm
          index={index}
          key={'edit-form'}
          handleAddOrUpdateRuleset={handleUpdate}
          mode={FormMode.EDIT}
          ruleset={ruleset()}
          toggleEditForm={toggleEdit}
          error={error}
          id={id}
          setWarningModal={setWarningModal}
        />
      ) : (
        <Card variant="border" style={{ border: `1px solid ${error ? PALETTE.alert125 : PALETTE.neutral12}` }}>
          <Header>
            <Title>{rulesetName}</Title>
            <StyledEditLink onClick={() => toggleEdit()}>Edit</StyledEditLink>
          </Header>
          <ul>
            <StyledRowLi>
              Approvals
              <span>{formattedRuleValue(approvalRule, false)}</span>
            </StyledRowLi>
            <StyledRowLi>
              Cash Approvals
              <span>{formattedRuleValue(approvalRule, true)}</span>
            </StyledRowLi>
            <StyledRowLi>
              Conditional approvals
              <span>{formattedRuleValue(approvalConditionsRule, false)}</span>
            </StyledRowLi>
            <StyledRowLi>
              Cash conditional approvals
              <span>{formattedRuleValue(approvalConditionsRule, true)}</span>
            </StyledRowLi>
            {rulesetDepositThresholdCents && (
              <>
                <StyledRowLi>
                  Deposit Threshold Amount
                  <span>{formatedRulesetDepositThresholdCents}</span>
                </StyledRowLi>
                <StyledRowLi>
                  Conditional approvals &gt; {formatedRulesetDepositThresholdCents} Deposit
                  <span>{formattedRuleValue(approvalConditionsGTDepositRule, false)}</span>
                </StyledRowLi>
                <StyledRowLi>
                  Cash conditional approvals &gt; {formatedRulesetDepositThresholdCents} Deposit
                  <span>{formattedRuleValue(approvalConditionsGTDepositRule, true)}</span>
                </StyledRowLi>
                <StyledRowLi>
                  Conditional approvals &lt;= {formatedRulesetDepositThresholdCents} Deposit
                  <span>{formattedRuleValue(approvalConditionsLTEDepositRule, false)}</span>
                </StyledRowLi>
                <StyledRowLi>
                  Cash conditional approvals &lt;= {formatedRulesetDepositThresholdCents} Deposit
                  <span>{formattedRuleValue(approvalConditionsLTEDepositRule, true)}</span>
                </StyledRowLi>
              </>
            )}
          </ul>
          {properties && (
            <StyledTagWrapper>
              {/* I don't like this, would rather show all the properties in here */}
              {properties[0]?.id && <StyledTag>{properties[0].address_line_one}</StyledTag>}
              {properties[1]?.id && <StyledTag>{properties[1].address_line_one}</StyledTag>}
              {properties.length > 2 && <StyledTag>+ {properties.length - 2}</StyledTag>}
            </StyledTagWrapper>
          )}
          {error && (
            <StyledError>
              <Alert2Icon color={PALETTE.alert125} style={{ marginRight: '8px' }} height="20px" width="20px" />
              <StyledErrorMessage>{error}</StyledErrorMessage>
            </StyledError>
          )}
        </Card>
      )}
    </>
  );
};

export default CustomCoverageRuleCard;
