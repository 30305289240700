import axios from 'axios';
import { ACCOUNT_ROUTES } from 'components/v2/App/utils/routeHelpers';
import { QueryObserverResult, useQuery } from 'react-query';

const getTwoFactorCodes = async () => {
  const { data } = await axios.get<string[]>('/v2/users/account/two_factor/list_two_factor_codes');
  return data;
};

export default function useTwoFactorCodes(): QueryObserverResult<string[]> {
  return useQuery('TwoFactorCodes', () => getTwoFactorCodes(), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });
}
