/** @jsx jsx */

import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { newSelectedProperties } from './PropertyFilterOption';
import { newSelections } from '.';
import { useFilterContext } from '../utils/filters';
import AppliedFiltersTag from './AppliedFiltersTag';

const AppliedFiltersContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '480px',
  marginBottom: '48px'
});

const Wrapper = styled.div({
  marginLeft: '48px'
});

const AppliedFilters = ({ query }) => {
  const { selectedFilters, setFilters } = useFilterContext();
  const {
    states: selectedStates,
    statuses: selectedStatuses,
    properties: selectedProperties,
    markets: selectedMarkets
  } = selectedFilters;
  const { data } = query();

  const getStatusText = (statusValue) => {
    let statuses;
    if (data?.statuses.cancellations) {
      statuses = [...data?.statuses.cancellations, ...data?.statuses.policy_details, ...data?.statuses.renewals];
    } else {
      statuses = data?.statuses;
    }
    const filteredStatuses = statuses.filter((status) => status.value === statusValue);
    return filteredStatuses[0] ? filteredStatuses[0].label_text : statusValue;
  };

  const getMarketText = (value) => {
    const filteredMarkets = data?.markets.filter((market) => market.value === value);
    return filteredMarkets[0] ? filteredMarkets[0].label_text : value;
  };

  return (
    <Wrapper>
      <AppliedFiltersContainer>
        {selectedStates.map((state, index) => {
          return (
            <AppliedFiltersTag
              index={index}
              onDelete={() => setFilters({ states: newSelections(state, selectedStates) })}
              tagText={state}
            />
          );
        })}
        {selectedProperties.map((property, index) => {
          return (
            <AppliedFiltersTag
              index={index}
              onDelete={() => setFilters({ properties: newSelectedProperties(property, selectedProperties) })}
              tagText={property.building_name}
            />
          );
        })}
        {selectedMarkets &&
          selectedMarkets.map((market, index) => {
            return (
              <AppliedFiltersTag
                index={index}
                onDelete={() => setFilters({ markets: newSelections(market, selectedMarkets) })}
                tagText={getMarketText(market)}
              />
            );
          })}
        {selectedStatuses.map((status, index) => {
          return (
            <AppliedFiltersTag
              index={index}
              onDelete={() => setFilters({ statuses: newSelections(status, selectedStatuses) })}
              tagText={getStatusText(status)}
            />
          );
        })}
      </AppliedFiltersContainer>
    </Wrapper>
  );
};

export default AppliedFilters;
