import { act, render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { TestApp } from '../index';
import { InvitationDetails } from '../InvitationDetails';
import { InvitationList } from '../InvitationList';
import { waitForAnimationFrame } from '../testUtils';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { FilterContext } from '../utils/filters';

let server;

describe('<InvitationList /> level invitation details interactions', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the invitations list', async () => {
    render(
      <Wrapper>
        <FilterContext.Provider
          value={{
            debouncedFilters: {
              states: [],
              properties: [],
              statuses: []
            },
            filtersCount: 0,
            selectedFilters: {
              states: [],
              properties: [],
              statuses: []
            },
            setFilters: ({}) => undefined
          }}
        >
          <InvitationList />
        </FilterContext.Provider>
      </Wrapper>
    );
    const heading = await screen.findByText('Invitations');

    expect(heading).toBeDefined();
    expect(heading).toHaveTextContent('Invitations');
  });

  test.skip('It expands and collapses the invitation details when an invitation card is clicked on', async () => {
    render(
      <Wrapper initialEntries={['/admin/invitations']}>
        <TestApp />
      </Wrapper>
    );

    const heading = await screen.findByText('Invitations');
    const inviteCard = await screen.findByRole('link', { name: /Lexi Bell/i });

    expect(heading).toBeDefined();
    expect(heading).toHaveTextContent('Invitations');

    expect(inviteCard).toBeDefined();
    expect(inviteCard).toHaveTextContent('Lexi Bell');

    const leftClick = { button: 0 };
    await act(async () => {
      userEvent.click(inviteCard, leftClick);
      await waitForAnimationFrame();
    });

    // header in details
    const invitationDetails = await screen.findByRole('heading', { level: 4, name: /Tenant/ });
    const collapseLink = await screen.findByRole('link', { name: /collapse/i });

    expect(invitationDetails).toBeDefined();
    expect(collapseLink).toBeDefined();

    await act(async () => {
      userEvent.click(collapseLink, leftClick);
      await waitForAnimationFrame();
    });

    // should no longer have the invitation details section
    expect(invitationDetails).not.toBeInTheDocument();
  });

  test.skip('It should render the proper invitation data on the details page', async () => {
    render(
      <Wrapper initialEntries={['/admin/invitations/36']}>
        <TestApp />
      </Wrapper>
    );

    await waitForAnimationFrame();

    const inviteCard = await screen.findByRole('link', { name: /Lexi Bell/i });

    const leftClick = { button: 0 };
    await act(async () => {
      userEvent.click(inviteCard, leftClick);
      await waitForAnimationFrame();
    });

    const invitationDetails = await screen.findByRole('heading', { level: 4, name: /Tenant/ });
    const coverageAmount = await screen.findByRole('textbox', { name: /Coverage Amount/i });
    const email = await await screen.findByRole('textbox', { name: /Email/i });
    const fullAddress = await screen.findByTestId('fullAddress');
    const createdAt = await screen.findByTestId('createdAt');
    const phoneNum = await screen.findByRole('textbox', { name: /Phone number/i });
    const leaseDates = await screen.findByRole('textbox', { name: /Lease Dates/i });

    expect(invitationDetails).toBeDefined();

    // coverage amount
    expect(coverageAmount).toBeDefined();
    expect(coverageAmount).toHaveValue('$9,500 (2.5x monthly rent)');

    // phone number
    expect(email).toBeDefined();
    expect(email).toHaveValue('lexi@bell.com');

    // full address
    expect(fullAddress).toBeDefined();
    expect(fullAddress).toHaveTextContent('1959 Wewatta St, Denver, CO 80202');

    // created at
    expect(createdAt).toBeDefined();
    expect(createdAt).toHaveTextContent('Created Oct 06, 2020');

    // phone
    expect(phoneNum).toBeDefined();
    expect(phoneNum).toHaveValue('(303) 555-9876');

    // lease dates
    expect(leaseDates).toBeDefined();
    expect(leaseDates).toHaveValue('Dec 13, 2020 - Dec 12, 2021');
  });

  test('It should render the edit button', async () => {
    render(
      <Wrapper initialEntries={['/admin/invitations/36']}>
        <Routes>
          <Route path="/admin/invitations/:invitationId" element={<InvitationDetails />} />
        </Routes>
      </Wrapper>
    );

    await waitForAnimationFrame();

    const invitationDetails = await screen.findByRole('heading', { level: 4, name: /Tenant/ });
    expect(invitationDetails).toBeDefined();

    const editButton = await screen.findByRole('button', { name: /edit/i });
    expect(editButton).toBeDefined();
  });

  // write a spec that tests whether the resend button is rendered
  describe('Resend Invitation button', () => {
    it('renders when partial quote status is sent', async () => {
      render(
        <Wrapper initialEntries={['/admin/invitations/32']}>
          <Routes>
            <Route path="/admin/invitations/:invitationId" element={<InvitationDetails />} />
          </Routes>
        </Wrapper>
      );

      await waitForAnimationFrame();

      const invitationDetails = await screen.findByRole('heading', { level: 4, name: /Tenant/ });
      expect(invitationDetails).toBeDefined();

      const resendButton = await screen.findByRole('button', { name: /resend/i });
      expect(resendButton).toBeDefined();
    });

    it('renders when partial quote status is in progress', async () => {
      render(
        <Wrapper initialEntries={['/admin/invitations/31']}>
          <Routes>
            <Route path="/admin/invitations/:invitationId" element={<InvitationDetails />} />
          </Routes>
        </Wrapper>
      );

      await waitForAnimationFrame();

      const invitationDetails = await screen.findByRole('heading', { level: 4, name: /Tenant/ });
      expect(invitationDetails).toBeDefined();

      const resendButton = await screen.findByRole('button', { name: /resend/i });
      expect(resendButton).toBeDefined();
    });

    it('renders when partial quote SDI has been declined, but the user has a cash deposit option', async () => {
      render(
        <Wrapper initialEntries={['/admin/invitations/29']}>
          <Routes>
            <Route path="/admin/invitations/:invitationId" element={<InvitationDetails />} />
          </Routes>
        </Wrapper>
      );

      await waitForAnimationFrame();

      const invitationDetails = await screen.findByRole('heading', { level: 4, name: /Tenant/ });
      expect(invitationDetails).toBeDefined();

      const resendButton = await screen.findByRole('button', { name: /resend/i });
      expect(resendButton).toBeDefined();
    });

    it('does not render when status returns completed', async () => {
      render(
        <Wrapper initialEntries={['/admin/invitations/30']}>
          <Routes>
            <Route path="/admin/invitations/:invitationId" element={<InvitationDetails />} />
          </Routes>
        </Wrapper>
      );

      await waitForAnimationFrame();

      const invitationDetails = await screen.findByRole('heading', { level: 4, name: /Tenant/ });
      expect(invitationDetails).toBeDefined();

      const resendButton = screen.queryByRole('button', { name: /resend/i });
      expect(resendButton).toBeNull();
    });

    it('does not render when status returns paid when the user was declined for SDI and funded a cash deposit', async () => {
      render(
        <Wrapper initialEntries={['/admin/invitations/30']}>
          <Routes>
            <Route path="/admin/invitations/:invitationId" element={<InvitationDetails />} />
          </Routes>
        </Wrapper>
      );

      await waitForAnimationFrame();

      const invitationDetails = await screen.findByRole('heading', { level: 4, name: /Tenant/ });
      expect(invitationDetails).toBeDefined();

      const resendButton = screen.queryByRole('button', { name: /resend/i });
      expect(resendButton).toBeNull();
    });
  });
});
