import React from 'react';
import ReactAsyncSelect from 'react-select/async';
import { ReactSelectProps } from 'react-select';
import { customStyles } from './styles';
import { ArrowDown } from '@sayrhino/rhino-shared-js';

const AsyncSelect: React.FC<ReactSelectProps> = (props) => (
  <ReactAsyncSelect
    cacheOptions
    defaultOptions
    {...props}
    styles={{ ...customStyles, ...props.styles }}
    components={{ DropdownIndicator: () => (props.isMulti ? <ArrowDown /> : null), ...props.components }}
  />
);

export default AsyncSelect;
