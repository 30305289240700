import React, { Component } from 'react';
import Toggle from './Toggle';

interface IProps {
  state: IActiveState;
  updateStateActiveStatus: Function;
}

interface IState {
  checked: boolean;
}

interface IActiveState {
  active: boolean;
  full_name: string;
  name: string;
}

class StateRow extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      checked: this.props.state.active
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  public handleToggle = (event) => {
    this.setState({ checked: event });
    this.props.state.active = event;
    this.props.updateStateActiveStatus(this.props.state);
  };

  public render(): JSX.Element {
    return (
      <div>
        <div className="row active-state-row">
          <div className="col col-md-9 col-xs-9 active-state-title">
            <h1>{this.props.state.full_name}</h1>
          </div>
          <div className="col col-md-3 col-xs-3 active-state-toggle">
            <Toggle
              checked={this.state.checked}
              onColor={'#F5F4FB'}
              handleToggle={this.handleToggle}
              name={this.props.state.name}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default StateRow;
