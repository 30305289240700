import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { PALETTE, FONTS, Alert2Icon } from '@sayrhino/rhino-shared-js';

interface FormFieldProps {
  disabled?: boolean;
  error?: string | null;
  input: JSX.Element;
  label?: JSX.Element;
}

interface StyledProps {
  disabled?: boolean;
}

const StyledFormField = styled.div<StyledProps>(({ disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 4,
  borderBottom: `1px solid ${disabled ? PALETTE.neutral4 : PALETTE.neutral12}`,
  '& > input': [
    FONTS.p1,
    {
      border: 'none',
      backgroundColor: 'transparent',
      width: '75%',
      '&:focus': {
        outline: 'none'
      },
      '&:disabled::placeholder': {
        color: PALETTE.neutral12
      }
    }
  ],
  label: [
    FONTS.p3,
    {
      color: disabled ? PALETTE.neutral12 : PALETTE.neutral65,
      textAlign: 'right'
    }
  ]
}));
const fadeIn = keyframes` 
    0% {opacity:0;}
    100% {opacity:1;}
  `;
const ErrorWrapper = styled.div({ height: '20px', marginTop: '4px' });
const ErrorMessage = styled.p([
  FONTS.p3,
  {
    color: PALETTE.alert125,
    paddingLeft: '4px'
  }
]);
const Error = styled.div({ display: 'flex', alignItems: 'center', animation: `${fadeIn} linear 0.1s` });

const FormField = ({ disabled, input, label, error, ...rest }: FormFieldProps) => {
  return (
    <div {...rest}>
      <StyledFormField disabled={disabled}>
        {input}
        {label}
      </StyledFormField>
      <ErrorWrapper>
        {error && (
          <Error>
            <Alert2Icon height="16px" width="16px" color={PALETTE.alert125} />
            <ErrorMessage>{error}</ErrorMessage>
          </Error>
        )}
      </ErrorWrapper>
    </div>
  );
};

export default FormField;
