import React, { ChangeEvent, useRef, useState } from 'react';
import { flatMap } from 'lodash';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ButtonHabitat, Loading, PALETTE, PlusIcon } from '@sayrhino/rhino-shared-js';
import PagingList from '../PagingList';
import { PageTitle, LoadingWrapper } from '../Account/Styled';
import SearchInput from '../SearchInput';
import EmptySearchResults from '../EmptySearchResults';
import { IPropertyOwnerSearchResult } from '../interfaces';
import usePropertyOwners from 'api/v2/usePropertyOwners';
import styled from '@emotion/styled';
import useUserRole from '../utils/userRole';
import { PropertyOwnersCard } from './PropertyOwnersCard';
import qs from 'query-string';
import { StyledEmptySearchP } from './Styled';
const StyledButton = styled(ButtonHabitat)({
  float: 'right',
  marginRight: '48px',
  marginBottom: '32px',
  whiteSpace: 'nowrap'
});
const StyledSearchWrapper = styled.div({ paddingBottom: '30px' });
const StyledPlusIcon = styled(PlusIcon)({
  paddingLeft: '8px'
});
export const PageWrapper = styled.div({ padding: '0px', borderRight: `1px solid ${PALETTE.neutral12}` });
const PageTitleWrapper = styled.div({
  display: 'flex',
  width: 'auto',
  justifyContent: 'space-between',
  alignItems: 'center'
});
const getSearchQueryFromUrl = (url: string) => {
  const searchQuery = qs.parse(url);
  if (typeof searchQuery.query === 'string') {
    return searchQuery.query;
  } else {
    return '';
  }
};

const PropertyOwners = () => {
  const location = useLocation();
  const { isAdmin } = useUserRole();
  const queryString = getSearchQueryFromUrl(location.search);
  const [query, setQuery] = useState<string>(queryString);
  const propertyOwnersResponse = usePropertyOwners(query, isAdmin, 20);
  const { hasNextPage, fetchNextPage, isSuccess, isLoading, isFetchingNextPage, data } = propertyOwnersResponse ?? {};

  const propertyOwners: IPropertyOwnerSearchResult[] = flatMap(data?.pages, (d) => d.data || []);
  const totalCount = (isSuccess && data?.pages[0].meta.total_count) ?? 0;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value !== query) {
      setQuery(e?.target?.value);
    }
  };
  const trimName = (val: string): string => {
    const MAX_NAME_LENGTH = 100;
    if (val.length > MAX_NAME_LENGTH) return val.slice(0, MAX_NAME_LENGTH - 3) + '...';
    return val;
  };

  const capitalizedIntegration: Record<string, string> = {
    yardi: 'Yardi',
    realpage: 'RealPage',
    entrata: 'Entrata'
  };

  return (
    <PageWrapper>
      <StyledSearchWrapper>
        <SearchInput
          aria-label={`Search property owners ${query.length ? `${totalCount} results` : ''}`}
          value={query}
          onChange={handleInputChange}
          ref={inputRef}
        />
      </StyledSearchWrapper>
      <PageTitleWrapper>
        <PageTitle>Property Owners</PageTitle>
        <Link to={'/admin/property_owners/new'}>
          <StyledButton id={'new-property-owner'} variant={'black'} usage={'secondary'} size="small" tabIndex={-1}>
            New property owner
            <StyledPlusIcon />
          </StyledButton>
        </Link>
      </PageTitleWrapper>
      {isLoading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {isSuccess && (
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {propertyOwners.map((propertyOwner) => (
            <PropertyOwnersCard
              propertyOwnerName={trimName(propertyOwner.name)}
              integrationType={capitalizedIntegration[propertyOwner.integration_type]}
              integrationStatus={propertyOwner.integration_status}
              propertyOwnerId={propertyOwner.id}
              path={String(propertyOwner.id)}
              activeIntegration={!!propertyOwner.integration_type}
              activePropertyOwner={propertyOwner.active}
              key={propertyOwner.id}
              claimClassification={propertyOwner.claim_classification}
            />
          ))}
        </ul>
      )}
      {totalCount === 0 && (
        <EmptySearchResults>
          <span>
            <StyledEmptySearchP>No property owners found</StyledEmptySearchP>
          </span>
        </EmptySearchResults>
      )}
      {totalCount > 20 && (
        <PagingList
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasData //We are already checking that there is data to display the component
        />
      )}
    </PageWrapper>
  );
};

export default PropertyOwners;
