import { PALETTE } from '@sayrhino/rhino-shared-js';
import React, { useCallback } from 'react';
import { useTable, usePagination } from 'react-table';

export interface IProps<Data, Columns> {
  data: Data;
  columns: Columns;
}

const styles = {
  table: {
    width: '100%',
    borderSpacing: '0',
    border: `1px solid ${PALETTE.neutral12}`
  },
  cell: {
    margin: '0',
    padding: '1rem',
    borderBottom: `1px solid ${PALETTE.neutral12}`
  },
  header: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '13px',
    lineHeight: '17px',
    color: '#5D5D5D'
  },
  pagination: {
    textAlign: 'left' as 'left',
    display: 'inline-block',
    paddingLeft: '0',
    margin: '20px 0',
    alignSelf: 'center' as 'center',
    position: 'relative' as 'relative',
    borderRadius: '4px',
    width: '100%'
  },
  pageSize: {
    position: 'absolute' as 'absolute',
    right: '0'
  },
  link: {
    color: PALETTE.brand100,
    cursor: 'pointer'
  }
};

/**
 * This is a stripped down, client-side paginated table per react-table
 * defaults meant for use exclusively within the property uploader while
 * we work on implementing windowing/virtualization for this table.
 * @param props Data and columns per react-table standards
 */
export default function Table<Data, Columns>(props: IProps<Data, Columns>) {
  const { columns, data } = props;

  const tableParams = {
    columns,
    data,
    autoResetPage: true,
    initialState: {
      pageSize: 25,
      pageIndex: 0
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    gotoPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(tableParams, usePagination);

  const renderRow = useCallback(
    (row) => {
      prepareRow(row);

      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return (
              <td
                data-tip={true}
                data-for={`${cell.column.id}-${cell.row.id}`}
                {...cell.getCellProps()}
                style={Object.assign({}, styles.cell, cell.column.style && cell.column.style(cell))}
              >
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );
    },
    [prepareRow, page]
  );

  return (
    <>
      <table {...getTableProps()} style={Object.assign({}, styles.table)}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={Object.assign({}, styles.cell, styles.header, column.headerStyle && column.headerStyle())}
                >
                  <div>{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>{page.map(renderRow)}</tbody>
      </table>

      <div style={styles.pagination}>
        <button aria-label="First page" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button aria-label="Previous page" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <button aria-label="Next page" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button aria-label="Last page" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onWheel={ event => event.currentTarget.blur() }
            onChange={(e) => {
              const selectedPage = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(selectedPage);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          style={styles.pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100].map((perPage) => (
            <option key={perPage} value={perPage}>
              Show {perPage}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
