import axios, { AxiosResponse } from 'axios';
import { IInsurancePolicy } from 'components/v2/App/interfaces';
import { useMutation, MutationFunction } from 'react-query';

interface ICancelResponse {
  message?: string;
}

export interface ICancelVariables {
  token?: string;
  cancellationReason?: number;
  requestedDate: string;
  acceptedDate: string;
  policyId?: string;
}

const cancelPolicy: MutationFunction<AxiosResponse<ICancelResponse>, ICancelVariables> = async ({
  token,
  cancellationReason,
  requestedDate,
  acceptedDate,
  policyId
}: ICancelVariables): Promise<AxiosResponse<ICancelResponse>> => {
  const response: AxiosResponse<ICancelResponse> = await axios.post(
    '/admin/renter_policies/' + policyId + '/cancel_subscription',
    {
      request: {
        cancellation_reason: cancellationReason,
        cancellation_request_date: requestedDate,
        cancellation_acceptance_date: acceptedDate
      }
    },
    {
      headers: {
        'X-CSRF-Token': token || ''
      }
    }
  );
  return response;
};

export default function useCancelPolicyMutation() {
  return useMutation(cancelPolicy);
}
