import axios from 'axios';
import { IInsurancePolicy } from 'components/v2/App/interfaces';
import { QueryObserverResult, useQuery } from 'react-query';

const getPolicy = async (id: number) => {
  /*
    check if the id is NaN
    With using this "usePolicy" hook,
      i.e. app/javascript/components/v2/App/PolicyTypeahead.tsx
      usePolicy(Number(policyId));
      policyId can be undefined and Number(policyId) will be NaN
    Also, if the user called this hook with out id (param), then it doesn't need to be called.
  */
  if (!id) {
    return null;
  }
  const urlParams = new URLSearchParams(window.location.search);
  const auth_token = urlParams.get('auth_token');
  const { data } = await axios.get<IInsurancePolicy>(`/v2/admin/insurance_policies/${id}`, {
    params: { auth_token: auth_token }
  });
  return data;
};

export default function usePolicy(id: number): QueryObserverResult<IInsurancePolicy> {
  return useQuery<any>(['policy', id], () => getPolicy(id));
}
