export const errorHash = {
  error: {
    data: [
      {
        legal_property_entity: [
          {
            detail: 'Please enter an existing Legal Property Entity',
            message: 'Legal Property Entity does not exist'
          }
        ],
        address: [
          {
            detail: 'Please provide a valid address.',
            message: 'Invalid Address'
          }
        ],
        row: 0
      }
    ],
    parsed_csv: [
      {
        property_name: 'Building 1',
        address: null,
        type: 'garden_community',
        unit: '2A',
        property_owner: 'TestOwner1',
        legal_property_entity: 'Policy Holder',
        property_default: '2x',
        unit_default: '1234',
        salesforce_opportunity_id: 'sf-123',
        student_housing: 'TRUE',
        integration_code: 'yardi12345',
        integration_type: 'yardi'
      },
      {
        property_name: 'BuildInG 2',
        address: '1701 Telluride Way, Louisville, KY 40223',
        type: 'single_faaamily',
        unit: null,
        property_owner: 'TestOwner1',
        legal_property_entity: 'Old Policy Holder',
        property_default: 'butter',
        unit_default: null,
        salesforce_opportunity_id: null,
        student_housing: null,
        integration_code: null,
        integration_type: null
      }
    ],
    error_type: 'validation_error',
    filename: 'file.csv'
  }
};
