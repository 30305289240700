/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import { Outlet, Route, Routes, useLocation, useMatch } from 'react-router-dom';
import Backdrop from '../drawer/backdrop';
import CancelPolicy from './CancelPolicy';
import { ClaimDetailsView } from './Claims/ClaimDetails/ClaimDetails';
import DashboardPanel from './DashboardPanel';
import Drawer from './Drawer';
import InsurancePolicySummaryMetrics from './InsurancePolicySummaryMetrics';
import InvitationSummaryMetrics from './InvitationSummaryMetrics';
import ClaimSummaryMetrics from './ClaimSummaryMetrics';
import ClaimFilters from './Filters/ClaimFilters';
import InsurancePolicyFilters from './Filters/InsurancePolicyFilters';
import InvitationFilters from './Filters/InvitationFilters';
import { TestComponent } from './index';
import InvitationDetails from './InvitationDetails';
import InvitationDetailsEdit from './InvitationDetailsEdit';
import Panel from './Panel';
import PolicyDetails from './PolicyDetails';
import DocumentRevisionsList from './DocumentRevisionsList';
import PolicyDetailsEdit from './PolicyDetailsEdit';
import SearchInputLink from './SearchInputLink';
import { mediaQueryWidth, useStickiedRoutes } from './utils';
import useOverlay from './utils/overlay';
import ClaimBatchAssignPanel from './ClaimBatchAssignPanel';
import { PROPERTY_OWNER_ROUTES, ROUTES } from './utils/routeHelpers';
import PropertyOwnerDetails from './PropertyOwners/PropertyOwnerDetails';
import PropertyOwnerForm from './PropertyOwners/PropertyOwnerForm';
import YardiPushForm from './PropertyOwners/yardiPushForm';
import PaymentsTable from './Claims/ClaimDetails/SubrogationPayments';
import { ClaimPayments } from './Claims/ClaimDetails/ClaimPayments';
import AttachmentsViewer from './Claims/ClaimDetails/AttachmentsViewer';
import DepositFundsSummaryMetrics from './DepositFundsSummaryMetrics';
import WithdrawalForm from './DepositFunds/Withdrawals/WithdrawalForm';
import { WithdrawalDetails } from './DepositFunds/Withdrawals/WithdrawalDetails';
import { DeleteBankAccountConfirmation } from './DepositFunds/BankAccounts/DeleteAccountConfirmation';
import BankAccountForm from './DepositFunds/BankAccounts/BankAccountForm';
import { BREAKPOINTS, NAV_WIDTH } from './utils/constants';

const panelStyles: any = {
  width: '100%',
  height: 'auto',
  minHeight: '100vh',
  overflowY: 'auto',
  overscrollBehaviorY: 'contain',
  borderRight: `1px solid ${PALETTE.neutral12}`,
  position: 'relative'
};

// Panel Routes are defined in Layout to life them as high as possible while
// maintaining access to an independent <Outlet />. This let's us use routes to
// control when the panel is displayed, giving us support for deeplinking to
// expanded states
const Layout = () => {
  const { showOverlay } = useOverlay();
  const loc = useLocation();
  const stickied = useStickiedRoutes();
  // list of routes to not automatically display a search bar on
  const HIDE_SEARCH_ROUTES = [PROPERTY_OWNER_ROUTES.details, ROUTES.propertyOwner, `${ROUTES.propertyOwner}/*`];

  return (
    <div css={{ position: 'relative' }}>
      <div
        css={{
          display: 'flex',
          height: '100vh',
          maxWidth: 1152,
          minWidth: 1056,
          marginLeft: NAV_WIDTH.expanded,
          background: '#F8F8FF',
          [mediaQueryWidth({ value: BREAKPOINTS.xl })]: {
            width: `calc(100% - ${NAV_WIDTH.collapsed})`,
            marginLeft: NAV_WIDTH.collapsed
          }
        }}
      >
        <div style={panelStyles} role="main" id="left-dashboard">
          {loc.pathname !== '/search' &&
            stickied.search === false &&
            !HIDE_SEARCH_ROUTES.some((route) => useMatch(route)) && <SearchInputLink />}
          <Outlet />
        </div>
        <div style={{ ...panelStyles, backgroundColor: '#ffffff' }}>
          <Routes>
            <Route path="" element={<Panel />}>
              <Route path="/" element={<DashboardPanel />} />
              <Route path="admin/funds" element={<DepositFundsSummaryMetrics />} />
              <Route path="admin/funds">
                <Route path="accounts">
                  <Route path="new" element={<BankAccountForm />} />
                  <Route path=":accountId/edit" element={<BankAccountForm />} />
                  <Route path=":accountId/delete" element={<DeleteBankAccountConfirmation />} />
                </Route>
              </Route>
              <Route path="admin/funds">
                <Route path="withdrawals">
                  <Route path="new" element={<WithdrawalForm />} />
                  <Route path=":withdrawalId" element={<WithdrawalDetails />} />
                  <Route path=":withdrawalId/edit" element={<WithdrawalForm />} />
                </Route>
              </Route>
              <Route path="admin/renter_policies" element={<InsurancePolicySummaryMetrics />} />
              <Route path="admin/invitations" element={<InvitationSummaryMetrics />} />
              <Route path="admin/claims" element={<ClaimSummaryMetrics />} />
            </Route>
          </Routes>
          <Drawer style={{ width: '100%' }}>
            <Routes>
              <Route path="admin" element={<Panel />}>
                <Route path="property_owners">
                  <Route path="new" element={<PropertyOwnerForm />} />
                  <Route path=":propertyOwnerId" element={<PropertyOwnerDetails />} />
                  <Route path=":propertyOwnerId/edit" element={<PropertyOwnerForm />} />
                  <Route path=":propertyOwnerId/policy_push/:partnerIntegrationId" element={<YardiPushForm />} />
                </Route>

                <Route path="claims">
                  <Route path=":claimsId" element={<ClaimDetailsView />} />
                  <Route path=":claimsId/attachments/:blob_id" element={<AttachmentsViewer />} />
                  <Route path="edit" element={<TestComponent name="edit claims layout view" />} />
                  <Route path="filters" element={<ClaimFilters />} />
                  <Route path="batch_assign" element={<ClaimBatchAssignPanel />} />
                  <Route path=":claimsId/subrogation_payments" element={<PaymentsTable />} />
                  <Route path=":claimsId/claim_payouts" element={<ClaimPayments />} />
                  <Route path="*" element={<TestComponent name="not found" />} />
                </Route>
                <Route path="renter_policies">
                  <Route path=":policyId" element={<PolicyDetails />} />
                  <Route path=":policyId/document_revisions" element={<DocumentRevisionsList />} />
                  <Route path=":policyId/cancel" element={<CancelPolicy />} />
                  <Route path=":policyId/edit" element={<PolicyDetailsEdit />} />
                  <Route path="filters" element={<InsurancePolicyFilters />} />
                </Route>
                <Route path="search"></Route>
                <Route path="invitations">
                  <Route path=":invitationId" element={<InvitationDetails />} />
                  <Route path=":invitationId/edit" element={<InvitationDetailsEdit />} />
                  <Route path="filters" element={<InvitationFilters />} />
                </Route>
              </Route>
            </Routes>
          </Drawer>
        </div>
      </div>
      {showOverlay && <Backdrop />}
    </div>
  );
};

export default Layout;
