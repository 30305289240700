import { IData, IPaginatedMeta, IPMAPropertyOwners } from 'components/v2/App/interfaces';
import axios from 'axios';
import { stringify } from 'qs';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

const getPMAPropertyOwners = async (id: number, page: number, per: number) => {
  const params = { page, per };
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data } = await axios.get<IData<IPMAPropertyOwners[], IPaginatedMeta>>(
    `/v2/admin/property_managers/${id}/property_owners`,
    {
      params,
      paramsSerializer
    }
  );
  return data;
};

export const PMAPropertyOwnersKey = 'PMAPropertyOwners';

export default function usePMAPropertyOwners(
  id: number,
  isPropertyManagerAdmin: boolean,
  per: number = 20
): InfiniteQueryObserverResult<IData<IPMAPropertyOwners[], IPaginatedMeta>> | undefined {
  if (isPropertyManagerAdmin) {
    return useInfiniteQuery<IData<IPMAPropertyOwners[], IPaginatedMeta>>(
      [PMAPropertyOwnersKey],
      ({ pageParam = 1 }) => getPMAPropertyOwners(id, pageParam, per),
      {
        getNextPageParam: (lastGroup) => lastGroup.meta.next_page
      }
    );
  }
}
