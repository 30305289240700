import { fetchPropertyManagers } from 'api/properties';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import IPropertyManager from 'entities/property_manager';
import React, { Component } from 'react';

interface IState {
  value: IPropertyManager;
}

interface IProps {
  name?: string;
  id?: string;
  valueKey?: string;
  labelKey: string;
  clearable?: boolean;
  placeholder?: string;
}

const default_value = { id: undefined };

class PropertyManagerSelect extends Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    name: 'property_manager',
    id: 'property_manager_id',
    valueKey: 'id',
    labelKey: 'email',
    clearable: true,
    placeholder: 'Select Property Manager'
  };

  constructor(props: IProps) {
    super(props);
    this.state = { value: default_value };
  }

  public onManagerChange = (value) => {
    this.setState({ value: value || default_value });
  };

  public render(): JSX.Element {
    const { name, id, valueKey, labelKey, clearable, placeholder } = this.props;
    const { value } = this.state;

    return (
      <div id="property_manager_select">
        <AsyncSelect
          name={name}
          id={id}
          getOptionLabel={(options) => options[labelKey || 'label']}
          getOptionValue={(options) => options[valueKey || 'value']}
          value={value}
          loadOptions={this.loadPropertyManagers}
          onChange={this.onManagerChange}
          isClearable={clearable}
          placeholder={placeholder}
        />

        <input
          type="hidden"
          name="property[property_manager_ids]"
          id="property_property_manager_ids"
          value={value.id || 0}
        />
      </div>
    );
  }

  private loadPropertyManagers = (input: string, callback: Function) => {
    fetchPropertyManagers(
      input,
      (response) => {
        callback(response.managers);
      },
      (response) => {
        response.json().then(() => {
          callback([]);
        });
      }
    );
  };
}

export default PropertyManagerSelect;
