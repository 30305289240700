import React from 'react';
import ReactAsyncSelect from 'react-select/async-creatable';
import { ReactSelectProps } from 'react-select';
import { customStyles, getSelectStyles } from './styles';
import { ArrowDown, FONTS, PALETTE, PlusIcon } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';

const StyledField = styled.div({ height: 70 });

const CreateLabel = styled.div({
  width: '100%',
  background: PALETTE.neutral4,
  color: PALETTE.neutralLight,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '32px',
  padding: '4px 12px 4px 12px'
});

const AddText = styled.p(FONTS.p2Light, {
  color: PALETTE.neutral88,
  margin: '0 8px'
});

const NewText = styled.p(FONTS.p2Medium, {
  color: PALETTE.interaction100,
  margin: '0'
});

const Cirle = styled.div({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  background: PALETTE.neutral25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const ErrorSpan = styled.span([
  FONTS.p3Light,
  {
    color: PALETTE.alert125,
    marginBottom: '12px'
  }
]);

const CreatableAsyncSelect: React.FC<ReactSelectProps> = (props) => {
  const formatCreateLabel = (inputValue) => (
    <CreateLabel>
      <Cirle>
        <PlusIcon width="14px" height="14px" color={PALETTE.neutralLight} />
      </Cirle>
      <AddText>Add</AddText>
      <NewText>"{inputValue}"</NewText>
    </CreateLabel>
  );
  return (
    <StyledField>
      <ReactAsyncSelect
        {...props}
        cacheOptions
        defaultOptions
        formatCreateLabel={formatCreateLabel}
        components={{ DropdownIndicator: () => (props.isMulti ? <ArrowDown /> : null), ...props.components }}
        styles={getSelectStyles({ isError: props.error })}
      />
      {props.error && <ErrorSpan>{props.error}</ErrorSpan>}
    </StyledField>
  );
};

export default CreatableAsyncSelect;
