import usePropertyCoverageRules from 'api/v2/usePropertyCoverageRules';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ICustomCoverageRuleset } from '../interfaces';

const useCustomCoverageRule = (showCustomRuleset: boolean) => {
  const { propertyOwnerId } = useParams();
  const { data: propertyRuleSetData } = usePropertyCoverageRules(propertyOwnerId) ?? {};

  const [customRuleSet, setCustomRuleSet] = useState<ICustomCoverageRuleset[]>(propertyRuleSetData ?? []);
  const [deletedRuleSets, setDeletedRulesets] = useState<ICustomCoverageRuleset[]>([]);
  const [showForm, setShowForm] = useState<boolean>(showCustomRuleset && customRuleSet.length < 1);
  const [hasCustomRuleSetChanged, setHasCustomRuleSetChanged] = useState<boolean>();

  // create a new Instance of CoverageRuleForm, so the main state
  const handleAddNewRuleSet = (value: ICustomCoverageRuleset) => {
    setCustomRuleSet([...customRuleSet, value]);
    setShowForm(false);
    setHasCustomRuleSetChanged(true);
  };

  const handleRemoveRuleSet = (index) => {
    const ruleSets = [...customRuleSet];
    const removedRuleset = ruleSets.splice(index, 1)[0];
    setCustomRuleSet(ruleSets);
    addToDeletedRulesets(removedRuleset);
  };

  const handleEditRuleset = (value, index) => {
    setCustomRuleSet(
      [...customRuleSet].map((ruleSet, idx) => {
        if (idx === index) return value;
        else return ruleSet;
      })
    );
    setHasCustomRuleSetChanged(true);
  };

  const addToDeletedRulesets = (value: ICustomCoverageRuleset) => {
    // clear coverageable_ids (denotes to BE that this should be deleted)
    value.coverageable_ids = [];
    // only add this to deleted rulesets if it has a valid id
    if (value.id) {
      setDeletedRulesets([...deletedRuleSets, value]);
      setHasCustomRuleSetChanged(true);
    }
  };

  return {
    customRuleSet,
    deletedRuleSets,
    handleAddNewRuleSet,
    handleRemoveRuleSet,
    handleEditRuleset,
    setShowForm,
    showForm,
    ruleSetLength: customRuleSet.length,
    hasCustomRuleSetChanged
  };
};
export default useCustomCoverageRule;
