import axios from 'axios';
import useUserRole from 'components/v2/App/utils/userRole';
import { QueryObserverResult, useQuery } from 'react-query';

export interface IUserAccountBalance {
  locked_balance_in_cents: number;
  total_balance_in_cents: number;
  free_balance_in_cents: number;
  last_updated_timestamp: string;
}

export const userAccountBalanceQueryKey = 'user_account_balance';

const getUserAccountBalance = async () => {
  const { data } = await axios.get(`/admin/funds/account_balance`);
  return data;
};

export default function useUserAccountBalance(
  {
    enabled
  }: {
    enabled: boolean;
  } = { enabled: true }
): QueryObserverResult<IUserAccountBalance> {
  const { isPropertyManagerAdmin } = useUserRole();
  return useQuery<IUserAccountBalance>([userAccountBalanceQueryKey], () => getUserAccountBalance(), {
    enabled: isPropertyManagerAdmin && enabled
  });
}
