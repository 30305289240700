import React from 'react';

import { QueryObserverResult } from 'react-query';
import { Loading } from '@sayrhino/rhino-shared-js';

import { useClaimSummaryMetrics } from 'api/v2/useClaimSummaryMetrics';
import { IClaimSummaryMetricsData, IClaimSummaryTeamOverview } from './interfaces';

import makeSummaryMetricsContainer from './makeSummaryMetricsContainer';
import DashboardPanelPill from './DashboardPanelPill';
import DashboardPanelFigure from './DashboardPanelFigure';
import DashboardPanelPercentagesChart from './DashboardPanelPercentagesChart';
import { stringifyMonth, numberToUSDFormatter } from './utils';
import { useUserRole } from './utils/userRole';

import {
  OPEN_CLAIMS_TITLE,
  RESOLVED_CLAIMS_TITLE,
  LOSS_OF_RENT_TITLE,
  DAMAGES_TITLE,
  TOTAL_CLAIM_PAYOUT_TITLE,
  TOTAL_RECLAIMED_TITLE,
  AVERAGE_DAY_APPROVE_TITLE,
  AVERAGE_DAY_APPROVE_DESC
} from './utils/constants';

import { PALETTE } from '@sayrhino/rhino-shared-js';

const ClaimSummaryMetrics = makeSummaryMetricsContainer(stringifyMonth(new Date()), () => {
  const { isThirdPartyAdmin } = useUserRole();
  if (isThirdPartyAdmin) {
    return null;
  }
  const response: QueryObserverResult<IClaimSummaryMetricsData> = useClaimSummaryMetrics();

  if (response.status === 'error') {
    return null;
  }

  if (response.isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Loading />
      </div>
    );
  }

  const claimSummaryData: IClaimSummaryMetricsData | undefined = response.data;

  if (!claimSummaryData) {
    return;
  }

  const convertToChartFormat = (teamOverview: IClaimSummaryTeamOverview[]) => {
    const palette = [
      PALETTE.interaction125,
      PALETTE.interaction100,
      PALETTE.warning100,
      PALETTE.success125,
      PALETTE.alert4,
      PALETTE.brand100,
      PALETTE.alert100
    ];
    const COLOR_CNT = palette.length;
    return teamOverview.map((data, index) => {
      return {
        id: data.name,
        label: data.name,
        value: data.open_claims,
        color: palette[index % COLOR_CNT]
      };
    });
  };

  return (
    <React.Fragment>
      <DashboardPanelPill title={OPEN_CLAIMS_TITLE} value={claimSummaryData.open_claims} dark />
      {claimSummaryData.resolved_claims_this_month !== undefined && (
        <DashboardPanelPill title={RESOLVED_CLAIMS_TITLE} value={claimSummaryData.resolved_claims_this_month} />
      )}
      {Array.isArray(claimSummaryData.team_overview) && (
        <DashboardPanelPercentagesChart
          title="Team Overview"
          data={convertToChartFormat(claimSummaryData.team_overview)}
        />
      )}
      <DashboardPanelPill title={LOSS_OF_RENT_TITLE} value={claimSummaryData.loss_of_rent} />
      <DashboardPanelPill title={DAMAGES_TITLE} value={claimSummaryData.damages} />
      {claimSummaryData.avg_days_to_payout !== undefined && (
        <DashboardPanelFigure
          title={AVERAGE_DAY_APPROVE_TITLE}
          description={AVERAGE_DAY_APPROVE_DESC}
          value={`${claimSummaryData.avg_days_to_payout} days`}
        />
      )}
      {claimSummaryData.total_funds_paid_out !== undefined && (
        <DashboardPanelPill
          title={TOTAL_CLAIM_PAYOUT_TITLE}
          value={numberToUSDFormatter(claimSummaryData.total_funds_paid_out)}
        />
      )}
      {claimSummaryData.total_funds_reclaimed !== undefined && (
        <DashboardPanelPill
          title={TOTAL_RECLAIMED_TITLE}
          value={numberToUSDFormatter(claimSummaryData.total_funds_reclaimed)}
        />
      )}
    </React.Fragment>
  );
});

export default ClaimSummaryMetrics;
