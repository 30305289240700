import axios from 'axios';
import { IBankAccountInfo } from 'components/v2/App/DepositFunds/types';
import { IPaginatedMeta } from 'components/v2/App/interfaces';
import useUserRole from 'components/v2/App/utils/userRole';
import { QueryObserverResult, useQuery } from 'react-query';

export interface IBankAccountsResponse {
  data: IBankAccountInfo[];
  meta: Pick<IPaginatedMeta, 'total_count' | 'page'>;
}

export const fundsBankAccountsQueryKey = 'funds_bank_accounts';

const getFundsBankAccounts = async () => {
  const { data } = await axios.get<IBankAccountsResponse>('/admin/funds/accounts');

  return data;
};

export default function useFundsBankAccounts(
  {
    enabled
  }: {
    enabled: boolean;
  } = { enabled: true }
): QueryObserverResult<IBankAccountsResponse> {
  const { isPropertyManagerAdmin } = useUserRole();
  return useQuery([fundsBankAccountsQueryKey], () => getFundsBankAccounts(), {
    enabled: isPropertyManagerAdmin && enabled
  });
}
