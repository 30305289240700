import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation, MutationFunction } from 'react-query';

type IPropertyOwnerPostStatus = { id: number; active: boolean };

const deletePropertyOwner: MutationFunction<AxiosResponse, Number> = async (
  property_owner_id: Number
): Promise<AxiosResponse> => {
  const authenticity_token = csrfToken();
  const response: AxiosResponse = await axios.delete(`/v2/admin/property_owners/${property_owner_id}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });
  return response;
};

export default function useDeletePropertyOwner() {
  return useMutation(deletePropertyOwner);
}
