/** @jsx jsx */
import { jsx } from '@emotion/core';

const TooltipInfoIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_224:19050)">
      <path
        fill="currentColor"
        clipRule="evenodd"
        d="M14.1665 8.00008C14.1665 11.4058 11.4055 14.1667 7.99984 14.1667C4.59416 14.1667 1.83317 11.4058 1.83317 8.00008C1.83317 4.5944 4.59416 1.83341 7.99984 1.83341C11.4055 1.83341 14.1665 4.59441 14.1665 8.00008ZM7.99984 15.1667C11.9578 15.1667 15.1665 11.958 15.1665 8.00008C15.1665 4.04212 11.9578 0.833414 7.99984 0.833413C4.04188 0.833413 0.833171 4.04212 0.833171 8.00008C0.83317 11.958 4.04188 15.1667 7.99984 15.1667ZM7.49984 11.8334L7.49984 11.3334L7.49984 8.00008L7.49984 7.50008L8.49984 7.50008L8.49984 8.00008L8.49984 11.3334L8.49984 11.8334L7.49984 11.8334ZM7.49984 5.83341L7.49984 5.33341L7.49984 4.66675L7.49984 4.16675L8.49984 4.16675L8.49984 4.66675L8.49984 5.33341L8.49984 5.83341L7.49984 5.83341Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_224:19050">
        <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);

export default TooltipInfoIcon;
