import React from 'react';
import styled from '@emotion/styled';
import { Button, PALETTE, FONTS, CloseIcon } from '@sayrhino/rhino-shared-js';
import { redirectTo } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Response } from '../singleProperty/queryHooks';

const Page = styled.div({
  height: 'auto',
  minHeight: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  background: PALETTE.brand4
});

const Title = styled.h2([
  FONTS.h3,
  {
    fontSize: '25px',
    lineHeight: '35px',
    textAlign: 'center',
    marginBottom: '28px',
    width: '395px'
  }
]);

const Close = styled.a({
  borderRadius: '30px',
  backgroundColor: PALETTE.neutralLight,
  width: '30px',
  height: '30px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `0.5px solid ${PALETTE.neutral55}`,
  borderColor: PALETTE.neutral55,
  position: 'absolute',
  top: '30px',
  right: '50px'
});

const GetStarted = styled.a({
  color: 'white'
});

const InviteNotification: React.FC<{ property: Response }> = ({ property }) => {
  const navigate = useNavigate();
  return (
    <Page>
      <Title>Now, let's send an invitation — it only takes a few seconds.</Title>
      <Close href="/admin/properties?table=true">
        <CloseIcon width="15px" height="15px" />
      </Close>
      <Button
        onClick={() => {
          navigate('/invite', { state: property });
        }}
        variant="primary"
      >
        Get started
      </Button>
    </Page>
  );
};

export default InviteNotification;
