import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ButtonHabitat, FONTS, FONT_FAMILY, PALETTE, Switch, TextInput } from '@sayrhino/rhino-shared-js';

import useCoverageRulesForm from './useCoverageRulesForm';
import { CoverageRuleSwitch } from './CoverageRuleSwitch';
import { IInput, INumberInput } from './usePartnerIntegrationForm';
import { CoverageTypeEnum, FormMode, ICustomCoverageRuleset, IPropertySearchResult } from '../interfaces';
import { v4 as uuid } from 'uuid';
import PropertyMultiSelect from './PropertyMultiSelect';
import Label from 'components/common/formsy/Label';
import Spacer from './SpacingTokens';
import CoverageRuleForm from './CoverageRuleForm';

const IntegrationDetailsHeader = styled.h4(FONTS.h5, {
  paddingBottom: 8,
  marginLeft: '0.5px',
  marginRight: '0.5px',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  width: '100%',
  marginBottom: 24
});

const RulesetFormDivided = styled.hr({
  display: 'block',
  borderTop: `1px solid ${PALETTE.neutralDark}`,
  marginTop: 0
});
export const StyledCoverageRulesBorder = styled.div({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '8px',
  padding: '24px 48px',
  margin: '0px 0px'
});
const StyledRemoveDiv = styled.div({ display: 'flex' });
const StyledRemoveSpan = styled.span({ marginLeft: 'auto' });

const StyledSaveDiv = styled.div({ display: 'flex', justifyContent: 'flex-end' });

const StyledDeleteLink = styled.a({ cursor: 'pointer', textDecoration: 'underline', color: PALETTE.interaction100 });
const FormInputSectionTitle = styled.h6([FONTS.h6Demi], { color: PALETTE.neutral88 });
const FormInputSectionSubtitle = styled.p([FONTS.p2Light, { marginTop: '8px', marginBottom: '24px' }]);

const StyledLabel = styled(Label)(FONT_FAMILY.extendedMedium, { color: PALETTE.neutral88, lineHeight: '24px' });
const StyledCopySubP = styled.p(FONT_FAMILY.extendedLight, {
  lineHeight: '24px',
  color: PALETTE.neutral88
});
const SameCashToggleContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
});

interface IProps {
  mode: FormMode;
  index: number;
  handleAddOrUpdateRuleset: (value: ICustomCoverageRuleset) => void;
  ruleset?: ICustomCoverageRuleset;
  toggleEditForm?: () => void;
  error?: string;
  id?: number;
  setWarningModal?: (bool: boolean) => void;
}

export const MAX_COVERAGE_AMOUNT = 20000000;
export const MAX_RULESET_NAME_CHAR_COUNT = 120;

const CustomCoverageRulesForm: React.FC<IProps> = ({
  index,
  handleAddOrUpdateRuleset,
  mode,
  ruleset,
  toggleEditForm,
  error,
  id,
  setWarningModal
}) => {
  const {
    coverageRule,
    conditionalCoverageRule,
    conditionalGTDepositCoverageRule,
    conditionalLTEDepositCoverageRule,
    getPartnerCoverageRules,
    raiseCoverageErrors,
  } = useCoverageRulesForm(ruleset);
  const defaultEmpty = { value: '', error: '' };
  const defaultNull = { value: undefined, error: '' };

  const [ruleSetName, setRuleSetName] = useState<IInput>(ruleset ? { value: ruleset.name, error: '' } : defaultEmpty);
  const [ruleSetDepositThresholdCents, setRuleSetDepositThresholdCents] = useState<INumberInput>(
    ruleset ? { value: ruleset.deposit_threshold_cents, error: '' } : defaultNull
  );
  const [selectedProperties, setSelectedProperties] = useState<{ value: IPropertySearchResult[]; error: string }>({
    value: ruleset?.properties ?? [],
    error: error || ''
  });

  //TODO create onEdit function which can send the new object back to the parent array
  const formattedCoverageRules: ICustomCoverageRuleset = {
    name: ruleSetName.value,
    properties: selectedProperties.value ?? [],
    type: 'property',
    deposit_threshold_cents: ruleSetDepositThresholdCents.value,
    coverageable_ids: selectedProperties.value?.map(({ id }) => id.toString()),
    coverage_rules: getPartnerCoverageRules(),
    uuid: uuid(),
    default: false,
    id: id
  };

  const prepareCashValueForCoverageRule = (rule) => {
    if (rule.coverageType === 'no_coverage') {
      rule.setCoverageSameCashDeposit(true);
    }
    if (rule.coverageSameCashDeposit) {
      rule.setCoverageCashType(rule.coverageType);
      rule.setCoverageCashValue(rule.coverageValue);
    }
  }

  const prepareCashValues = () => {
    prepareCashValueForCoverageRule(coverageRule);
    prepareCashValueForCoverageRule(conditionalCoverageRule);
    if (validRuleSetDepositThreshold()) {
      prepareCashValueForCoverageRule(conditionalGTDepositCoverageRule);
      prepareCashValueForCoverageRule(conditionalLTEDepositCoverageRule);
    }
  };

  const coverageRulesInvalid = () => {
    const coverageAttributesToValidate: [type: CoverageTypeEnum, value: number][] = [
      [coverageRule.coverageType, coverageRule.coverageValue],
      [conditionalCoverageRule.coverageType, conditionalCoverageRule.coverageValue],
      [conditionalGTDepositCoverageRule.coverageType, conditionalGTDepositCoverageRule.coverageValue],
      [conditionalLTEDepositCoverageRule.coverageType, conditionalLTEDepositCoverageRule.coverageValue]
    ];
    const cashAttributesToValidate: [type: CoverageTypeEnum, value: number, sameCash: boolean][] = [
      [coverageRule.coverageCashType, coverageRule.coverageCashValue, coverageRule.coverageSameCashDeposit],
      [
        conditionalCoverageRule.coverageCashType,
        conditionalCoverageRule.coverageCashValue,
        conditionalCoverageRule.coverageSameCashDeposit
      ],
      [
        conditionalGTDepositCoverageRule.coverageCashType,
        conditionalGTDepositCoverageRule.coverageCashValue,
        conditionalGTDepositCoverageRule.coverageSameCashDeposit
      ],
      [
        conditionalLTEDepositCoverageRule.coverageCashType,
        conditionalLTEDepositCoverageRule.coverageCashValue,
        conditionalLTEDepositCoverageRule.coverageSameCashDeposit
      ]
    ];

    return (coverageAttributesToValidate.some(([type, value]) =>
      (type === 'amount_cents' || type === 'multiplier') && (value <= 0 || value > MAX_COVERAGE_AMOUNT)
    )) || (cashAttributesToValidate.some(([type, value, sameCash]) =>
      !sameCash && (type === 'amount_cents' || type === 'multiplier') && (value <= 0 || value > MAX_COVERAGE_AMOUNT)
    ));
  };

  const validateCustomCoverageRules = (): boolean => {
    return (
      ruleSetName.value.length > 0 &&
      ruleSetName.value.length < MAX_RULESET_NAME_CHAR_COUNT &&
      validRuleSetDepositThreshold() &&
      !coverageRulesInvalid() &&
      selectedProperties.value.length > 0
    );
  };

  const validRuleSetDepositThreshold = (): boolean => {
    return typeof ruleSetDepositThresholdCents.value === 'undefined' || ruleSetDepositThresholdCents.value > 0;
  }

  const raiseErrors = () => {
    if (ruleSetName.value === '') setRuleSetName({ value: '', error: 'You must input a unique name for the ruleset' });
    if (ruleSetName.value.length > MAX_RULESET_NAME_CHAR_COUNT)
      setRuleSetName({ value: ruleSetName.value, error: 'You must enter a ruleset name under 120 characters.' });
    if (selectedProperties.value.length === 0) setSelectedProperties({ value: [], error: 'Cannot be empty' });
    raiseCoverageErrors();
  };

  return (
    <>
      <StyledCoverageRulesBorder>
        <StyledRemoveDiv>
          <IntegrationDetailsHeader>Ruleset {index + 1}</IntegrationDetailsHeader>
          <StyledRemoveSpan>
            {mode === FormMode.EDIT && setWarningModal && (
              <StyledDeleteLink type="button" onClick={() => setWarningModal(true)}>
                Delete
              </StyledDeleteLink>
            )}
          </StyledRemoveSpan>
        </StyledRemoveDiv>
        <TextInput
          id={`${index}-ruleset-name`}
          label="Ruleset name"
          onChange={(e) => {
            setRuleSetName({ value: e.target.value, error: '' });
          }}
          value={ruleSetName.value}
          subtext={ruleSetName.error}
          error={!!ruleSetName.error}
        />
        <RulesetFormDivided />

        {/* Approval form */}
        <div>
          <StyledLabel>Rule for approvals</StyledLabel>
          <StyledCopySubP>
            Please enter the security deposit coverage that would be required at this property for an approved renter.
          </StyledCopySubP>
        </div>

        <Spacer amount={3} />

        <CoverageRuleSwitch
          coverageType={coverageRule.coverageType}
          value={coverageRule.coverageValue}
          handleCoverageDollarValue={coverageRule.convertCoverageToCents}
          onSelect={coverageRule.handleSwitchSelect}
          setCoverageValue={coverageRule.setCoverageValue}
          coverageDollarError={coverageRule.coverageDollarError}
          label="Rule for approvals"
          id={`${index}-approval`}
        />

        {coverageRule.coverageType !== 'no_coverage' &&
          <>
            <SameCashToggleContainer>
              <label id={`${index}-same-cash-amount`}>Same cash amount?</label>
              <Switch
                id={`${index}-toggle-same-cash-amount`}
                aria-labelledby={`${index}-same-cash-amount`}
                checked={coverageRule.coverageSameCashDeposit}
                onCheckedChange={() => coverageRule.setCoverageSameCashDeposit((prev) => !prev)}
              ></Switch>
            </SameCashToggleContainer>

            <Spacer amount={3} />

            {!coverageRule.coverageSameCashDeposit &&
              <CoverageRuleSwitch
                coverageType={coverageRule.coverageCashType}
                value={coverageRule.coverageCashValue}
                handleCoverageDollarValue={coverageRule.convertCoverageCashToCents}
                onSelect={coverageRule.handleCashSwitchSelect}
                setCoverageValue={coverageRule.setCoverageCashValue}
                coverageDollarError={coverageRule.coverageCashDollarError}
                label="Cash rule for approvals"
                includeNoCoverage={false}
                id={`${index}-default-cash-approval`}
              />
            }
          </>
        }

        <Spacer amount={3} />

        {/* Approval with conditions form */}

        <div>
          <StyledLabel>Rule for approvals with conditions</StyledLabel>
          <StyledCopySubP>
            {
              "Please enter the security deposit coverage that would be required at this property for \
              a conditionally approved renter."
            }
          </StyledCopySubP>
        </div>

        <CoverageRuleForm
          coverageRule={conditionalCoverageRule}
          coverageTitle={`Base Rule`}
          coverageLabel="Custom rule for approvals with conditions"
          coverageCashLabel="Custom cash rule for approvals with conditions"
          coverageId="default-approval-conditions"
          index={index}
          handleSwitchSelect={conditionalCoverageRule.handleSwitchSelect}
          handleCashSwitchSelect={conditionalCoverageRule.handleCashSwitchSelect}
        />

        <Spacer amount={2} />

        <div>
          <StyledLabel>Conditional Logic</StyledLabel>
          <StyledCopySubP>
            {
              "Please enter the threshold Dollar amount to enable deposit conditions."
            }
          </StyledCopySubP>
        </div>

        <Spacer amount={2} />

        <TextInput
          id={`${index}-ruleset-deposit-threshold-cents`}
          label="Deposit Threshold Dollar Amount (Cents)"
          onChange={(e) => {
            setRuleSetDepositThresholdCents({ value: parseInt(e.target.value), error: '' });
          }}
          value={ruleSetDepositThresholdCents.value}
          subtext={ruleSetDepositThresholdCents.error}
          error={!!ruleSetDepositThresholdCents.error}
        />

        {ruleSetDepositThresholdCents.value && (
          <>
            <CoverageRuleForm
              coverageRule={conditionalGTDepositCoverageRule}
              coverageTitle={`Deposit is > ${ruleSetDepositThresholdCents.value} cents`}
              coverageLabel="Custom rule for approvals with conditions"
              coverageCashLabel="Custom cash rule for approvals with conditions"
              coverageId="default-approval-conditions-gt-deposit"
              index={index}
              handleSwitchSelect={conditionalGTDepositCoverageRule.handleSwitchSelect}
              handleCashSwitchSelect={conditionalGTDepositCoverageRule.handleCashSwitchSelect}
            />

            <CoverageRuleForm
              coverageRule={conditionalLTEDepositCoverageRule}
              coverageTitle={`Deposit is <= ${ruleSetDepositThresholdCents.value} cents`}
              coverageLabel="Custom rule for approvals with conditions"
              coverageCashLabel="Custom cash rule for approvals with conditions"
              coverageId="default-approval-conditions-lte-deposit"
              index={index}
              handleSwitchSelect={conditionalLTEDepositCoverageRule.handleSwitchSelect}
              handleCashSwitchSelect={conditionalLTEDepositCoverageRule.handleCashSwitchSelect}
            />
          </>
          )}

        <Spacer amount={3} />

        <RulesetFormDivided />
        <FormInputSectionTitle>Applicable properties</FormInputSectionTitle>
        <FormInputSectionSubtitle>Please select the properties that this ruleset applies to.</FormInputSectionSubtitle>
        <PropertyMultiSelect
          id={`${index}-ruleset-property-select`}
          selectedProperties={selectedProperties.value}
          onChange={(value) => setSelectedProperties({ value, error: '' })}
          error={selectedProperties.error}
        />
        <StyledSaveDiv>
          <ButtonHabitat
            id="add-update-ruleset"
            variant="interaction"
            size="small"
            usage="primary"
            type="button"
            onClick={() => {
              prepareCashValues();
              if (validateCustomCoverageRules()) {
                handleAddOrUpdateRuleset(formattedCoverageRules);
                if (mode === FormMode.EDIT && toggleEditForm) {
                  toggleEditForm();
                }
              } else {
                raiseErrors();
              }
            }}
          >
            Save ruleset
          </ButtonHabitat>
        </StyledSaveDiv>
      </StyledCoverageRulesBorder>
    </>
  );
};
export default CustomCoverageRulesForm;
