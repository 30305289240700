/** @jsx jsx */
import { Fragment } from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
  CloseIcon,
  DropdownMenu,
  TextInput,
  PALETTE,
  FONTS,
  FONT_FAMILY,
  Loading,
  Radio,
  ArrowDown,
  ButtonHabitat,
  Checkbox
} from '@sayrhino/rhino-shared-js';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePartnerIntegrationForm } from './usePartnerIntegrationForm';
import { useParams } from 'react-router-dom';
import { BoolString, BoolStringToBool, FormMode } from '../interfaces';
import usePartnerIntegration from 'api/v2/usePartnerIntegration';
import usePartnerCoverageRules from 'api/v2/usePartnerCoverageRules';
import Label from 'components/common/formsy/Label';
import usePropertyCoverageRules from 'api/v2/usePropertyCoverageRules';
import MaskedPasswordInput from './MaskedPasswordInput';
import SectionHeader from './SectionHeader';
import Spacer from './SpacingTokens';

const contextParaCSS = css({
  color: PALETTE.brand100,
  padding: '12px 0'
});

const contextHeadingCSS = css({
  color: PALETTE.brand100
});

const numberCircleCSS = css({
  border: `1px solid ${PALETTE.brand100}`,
  borderRadius: '50%',
  height: '28px',
  width: '28px',
  display: 'inline-block',
  textAlign: 'center',
  color: PALETTE.brand100,
  marginRight: 14,
  padding: 1
});

const contextLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  margin: '1em 0 1em -48px',
  paddingBottom: 32,
  width: '384px'
});

const closeButtonCSS = css({
  background: PALETTE.neutral4,
  borderRadius: '20px',
  height: '32px',
  width: '32px',
  textAlign: 'center',
  border: 'none'
});

const InfoRailDiv = styled.div({
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'flex-start',
  background: PALETTE.brand4,
  padding: 48,
  borderRight: `1px solid ${PALETTE.neutral12}`,
  width: '384px'
});

const FormHeaderDiv = styled.div({
  backgroundColor: PALETTE.neutralLight,
  padding: '20px 48px',
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1
});

const IntegrationSetupPageDiv = styled.div({ display: 'flex' });

const InfoRailHeader = styled.h3(FONTS.h3, { color: PALETTE.brand100 });
const InfoRailSubHeader = styled.span(FONTS.p2, contextParaCSS, { paddingBottom: 40 });
const InfoRailStepContainer = styled.div({ marginBottom: 50 });
const InfoRailStepNumber = styled.div(FONTS.p2, numberCircleCSS);
const InfoRailStepText = styled.span(FONTS.h5, contextHeadingCSS);
const IntegrationFormDiv = styled.div({ flex: 1, marginLeft: 384 });

const FormHeaderSpan = styled.span(FONTS.p1, FONT_FAMILY.extendedMedium, {
  color: PALETTE.neutral65
});
const FormHeaderCloseButton = styled.button(closeButtonCSS);
const StyledFormHeaderCloseIcon = styled(CloseIcon)({ marginTop: '7px', color: PALETTE.neutral55 });

const StyledLabel = styled.label([FONTS.p3Medium, { color: PALETTE.neutral65 }]);
const PMSIntegrationSetupHeader = styled.h3(FONTS.h3);
const PMSIntegrationSetupDivider = styled.hr({ contextLineCSS });
const PMSIntegrationForm = styled.form({ padding: '40px 96px', marginBottom: 100, maxWidth: '900px' });

const StyledSubmitButton = styled(ButtonHabitat)({
  float: 'right'
});

export const StyledDropdownMenuTrigger = styled(DropdownMenu.Trigger)(
  {
    width: '100%',
    textAlign: 'left',
    color: '#000000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    marginTop: '4px'
  },
  (props) => ({
    backgroundColor: `${props.disabled && PALETTE.neutral12}`
  })
);
export const StyledDropdownMenuItem = styled(DropdownMenu.Item)({ minWidth: '650px' });
const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
});
const RadioButtonLabelWrapper = styled.div({ paddingTop: '8px', paddingBottom: '12px' });
const RadioButtonLabel = styled(Label)(FONT_FAMILY.extendedMedium);
const StyledPaddedDiv = styled.div({ paddingTop: '8px', paddingBottom: '8px' });
const BoldText = styled.span([FONTS.p2Medium]);

// number text input with css to hide the tickers
const NumericTextInput = styled(TextInput)({
  '& > div > input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  }
});

// temp fix for Radio.Root props issue
const RadioRoot: any = Radio.Root;

export const CreatePMSIntegrationSetup = () => {
  const navigate = useNavigate();
  const {
    partner,
    setPartner,
    propMgmtCompany,
    setPropMgmtCompany,
    nameInfo,
    setNameInfo,
    partnerUrl,
    setPartnerUrl,
    usernameInfo,
    setUsername,
    passwordInfo,
    setPassword,
    setHasPmsPasswordChanged,
    hasPmsPasswordChanged,
    setHasIlsgcPasswordChanged,
    hasIlsgcPasswordChanged,
    allowWrites,
    setAllowWrites,
    singleFamilyOnly,
    setSingleFamilyOnly,
    serverName,
    setServerName,

    depositPaymentCode,
    setDepositPaymentCode,
    formMode,
    createPartner,
    updatePartner,
    isLoading,
    partnerOptions,
    ILSGC,
    partnerPasswordInputProps,
    setIlsgcSelection,
    hasFormChanged
  } = usePartnerIntegrationForm();

  const formAction = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (formMode === FormMode.CREATE) {
      createPartner(event);
    } else {
      updatePartner(event);
    }
  };

  const isFieldEmpty = (value) => {
    if (value == null) return true;
    if (typeof value === 'string') return value.trim() === '';
    return false;
  };

  const isEditMode = formMode === FormMode.EDIT;
  const showIlsgcPasswordMask = isEditMode && ILSGC.IlsgcLoginExists;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isLedgerSyncEnabled = params.get('isEnabledLedgerSync') === 'true';

  const isValidUrl = (url) => {
    try {
      const uri = new URL(url);
      return uri.protocol === 'http:' || uri.protocol === 'https:';
    } catch (error) {
      return false;
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errorMsgSuffix = ' These configurations are required and in use. To delete them, the Ledger Sync feature must be disabled.';

    if (isLedgerSyncEnabled && isEditMode) {
      if (partner.name === 'realpage' && isFieldEmpty(propMgmtCompany.id)) {
        setPropMgmtCompany(prevState => ({ ...prevState, error: 'PMC ID cannot be blank' + errorMsgSuffix }));
        isValid = false;
      }

      if (isFieldEmpty(partnerUrl.value)) {
        setPartnerUrl(prevState => ({ ...prevState, error: 'Partner URL cannot be blank.' + errorMsgSuffix }));
        isValid = false;
      }

      if(!isValidUrl(partnerUrl.value)) {
        setPartnerUrl(prevState => ({ ...prevState, error: 'Partner URL is not a valid URL.' }));
        isValid = false;
      }

      if (isFieldEmpty(usernameInfo.value)) {
        setUsername(prevState => ({ ...prevState, error: 'Username cannot be blank.' + errorMsgSuffix }));
        isValid = false;
      }

      if (isFieldEmpty(passwordInfo.value)) {
        setPassword(prevState => ({ ...prevState, error: 'Password cannot be blank.' + errorMsgSuffix }));
        isValid = false;
      }

      if (!isValid) {
        return false;
      }
    }

    return true;
  };

  return (
    <IntegrationSetupPageDiv>
      <InfoRailDiv>
        <InfoRailHeader>PMS Integration Setup</InfoRailHeader>
        <InfoRailSubHeader>Follow these instructions to set up your PMS integration.</InfoRailSubHeader>
        <PMSIntegrationSetupDivider />
        <InfoRailStepContainer>
          <InfoRailStepNumber>1</InfoRailStepNumber>
          <InfoRailStepText>Select your PMS</InfoRailStepText>
        </InfoRailStepContainer>
        <PMSIntegrationSetupDivider />
        <div>
          <InfoRailStepNumber>2</InfoRailStepNumber>
          <InfoRailStepText>Add Integration details</InfoRailStepText>
        </div>
      </InfoRailDiv>

      <IntegrationFormDiv>
        <FormHeaderDiv>
          <FormHeaderSpan>PMS Integration Setup</FormHeaderSpan>
          <FormHeaderCloseButton onClick={() => navigate(-1)} aria-label="Leave integration form">
            <StyledFormHeaderCloseIcon height="16px" width="16px" />
          </FormHeaderCloseButton>
        </FormHeaderDiv>

        <PMSIntegrationForm onSubmit={formAction}>
          <PMSIntegrationSetupHeader>PMS Integration Setup</PMSIntegrationSetupHeader>
          <Spacer amount={5} />
          <section>
            <SectionHeader>{formMode == FormMode.CREATE ? 'Getting started' : 'Edit integration'}</SectionHeader>
            <Spacer amount={3} />
            <StyledLabel>PMS integration</StyledLabel>
            <DropdownMenu.Root>
              <StyledDropdownMenuTrigger
                aria-label="select partner type"
                value={partner.name}
                disabled={formMode == FormMode.EDIT}
              >
                <span>{partner.name == '' ? '-' : partner.displayName}</span>
                <ArrowDown width="24px" height="24px" />
              </StyledDropdownMenuTrigger>
              <DropdownMenu.Content>
                {partnerOptions.map((partnerInfo, index) => (
                  <StyledDropdownMenuItem
                    aria-label={partnerInfo.name}
                    onSelect={() => setPartner(partnerInfo)}
                    key={index}
                  >
                    {partnerInfo.displayName}
                  </StyledDropdownMenuItem>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            <Spacer amount={5} />
          </section>
          {partner.name && (
            <Fragment>
              <section>
                <SectionHeader>Integration details</SectionHeader>
                <Spacer amount={3} />
                {partner.name === 'realpage' && (
                  <Fragment>
                    <NumericTextInput
                      id="pmc-id"
                      label="PMC ID"
                      onChange={(e) => {
                        setPropMgmtCompany({ id: e.target.value, error: '' });
                      }}
                      value={propMgmtCompany.id}
                      subtext={propMgmtCompany.error.length > 0 && propMgmtCompany.error}
                      error={propMgmtCompany.error.length > 0}
                      onWheel={ event => event.currentTarget.blur() }
                      type="number"
                    />
                    <Spacer amount={3} />
                  </Fragment>
                )}
                {partner.name === 'realpage' && (
                  <Fragment>
                    <TextInput
                      id="name"
                      label="Name"
                      onChange={(e) => {
                        setNameInfo({ value: e.target.value, error: '' });
                      }}
                      value={nameInfo.value}
                      subtext={nameInfo.error.length > 0 && nameInfo.error}
                      error={nameInfo.error.length > 0}
                    />
                    <Spacer amount={3} />

                    <TextInput
                      id="partner-url"
                      label="SDE URL"
                      onChange={(e) => {
                        setPartnerUrl({ value: e.target.value, error: '' });
                      }}
                      value={partnerUrl.value}
                      subtext={partnerUrl.error.length > 0 && partnerUrl.error}
                      error={partnerUrl.error.length > 0}
                    />
                    <Spacer amount={3} />

                    <TextInput
                      id="username"
                      label="SDE Username"
                      onChange={(e) => {
                        setUsername({ value: e.target.value, error: '' });
                      }}
                      value={usernameInfo.value}
                      subtext={usernameInfo.error.length > 0 && usernameInfo.error}
                      error={usernameInfo.error.length > 0}
                    />
                    <Spacer amount={3} />

                    {isEditMode ? (
                      <MaskedPasswordInput
                        setPassword={(val) => {
                          setPassword({ value: val, error: '' });
                        }}
                        setHasPasswordChanged={setHasPmsPasswordChanged}
                        hasPasswordChanged={hasPmsPasswordChanged}
                        error={passwordInfo.error}
                        showMask={!hasPmsPasswordChanged}
                        label="SDE Password"
                        {...partnerPasswordInputProps}
                      ></MaskedPasswordInput>
                    ) : (
                      <TextInput
                        onChange={(e) => {
                          setPassword({ value: e.target.value, error: '' });
                        }}
                        value={passwordInfo.value}
                        subtext={passwordInfo.error.length > 0 ? passwordInfo.error : ''}
                        error={passwordInfo.error.length > 0}
                        label="SDE Password"
                        {...partnerPasswordInputProps}
                      />
                    )}
                    <Spacer amount={3} />
                    <TextInput
                      id="deposit-payment-code"
                      label="Deposit Payment Code"
                      onChange={(e) => {
                        setDepositPaymentCode({ value: e.target.value, error: '' });
                      }}
                      value={depositPaymentCode.value}
                      placeholder='e.g. DEPOSIT'
                    />
                    <Spacer amount={3} />
                  </Fragment>
                )}

                {['yardi','entrata'].includes(partner.name) && (
                  <Fragment>
                    <TextInput
                      id="name"
                      label="Name"
                      onChange={(e) => {
                        setNameInfo({ value: e.target.value, error: '' });
                      }}
                      value={nameInfo.value}
                      subtext={nameInfo.error.length > 0 && nameInfo.error}
                      error={nameInfo.error.length > 0}
                    />
                    <Spacer amount={3} />

                    <TextInput
                      id="partner-url"
                      label="Partner Url"
                      onChange={(e) => {
                        setPartnerUrl({ value: e.target.value, error: '' });
                      }}
                      value={partnerUrl.value}
                      subtext={partnerUrl.error.length > 0 && partnerUrl.error}
                      error={partnerUrl.error.length > 0}
                    />
                    <Spacer amount={3} />

                    <TextInput
                      id="username"
                      label={`${partner.displayName} Username`}
                      onChange={(e) => {
                        setUsername({ value: e.target.value, error: '' });
                      }}
                      value={usernameInfo.value}
                      subtext={usernameInfo.error.length > 0 && usernameInfo.error}
                      error={usernameInfo.error.length > 0}
                    />
                    <Spacer amount={3} />

                    {isEditMode ? (
                      <MaskedPasswordInput
                        setPassword={(val) => {
                          setPassword({ value: val, error: '' });
                        }}
                        setHasPasswordChanged={setHasPmsPasswordChanged}
                        hasPasswordChanged={hasPmsPasswordChanged}
                        error={passwordInfo.error}
                        showMask={!hasPmsPasswordChanged}
                        label={`${partner.displayName} Password`}
                        {...partnerPasswordInputProps}
                      ></MaskedPasswordInput>
                    ) : (
                      <TextInput
                        onChange={(e) => {
                          setPassword({ value: e.target.value, error: '' });
                        }}
                        value={passwordInfo.value}
                        subtext={passwordInfo.error.length > 0 ? passwordInfo.error : ''}
                        error={passwordInfo.error.length > 0}
                        label={`${partner.displayName} Password`}
                        {...partnerPasswordInputProps}
                      />
                    )}
                  </Fragment>
                )}

                {partner.name === 'yardi' && (
                  <Fragment>    
                    <TextInput
                      id="server-name"
                      label="Yardi Server Name"
                      onChange={(e) => {
                        setServerName({ value: e.target.value, error: '' });
                      }}
                      value={serverName.value}
                      subtext={serverName.error.length > 0 && serverName.error}
                      error={serverName.error.length > 0}
                    />
                    <Spacer amount={3} />
                  </Fragment>
                )}

                <Spacer />

                {partner.name == 'yardi' && (
                  <Fragment>
                    <RadioButtonLabelWrapper>
                      <RadioButtonLabel id="ilsgc-label">
                        Set up Internet Listing Service / Guest Card?:
                      </RadioButtonLabel>
                    </RadioButtonLabelWrapper>
                    <RadioRoot
                      value={String(ILSGC.IlsgcSelection)}
                      onValueChange={(value: BoolString) => {
                        setIlsgcSelection(BoolStringToBool(value));
                      }}
                      aria-label="Set up Internet Listing Service/Guest Card?"
                      name="ilsgc-radio"
                    >
                      <Spacer />
                      <Radio.Option value={BoolString.TRUE} label="Yes"></Radio.Option>
                      <Spacer />
                      <Radio.Option value={BoolString.FALSE} label="No"></Radio.Option>
                    </RadioRoot>
                    {ILSGC.IlsgcSelection && (
                      <Fragment>
                        <StyledPaddedDiv>
                          <TextInput
                            id="ilsgc-username"
                            label="ILS/GC Username"
                            onChange={(e) => {
                              ILSGC.setIlsgcUsername({ value: e.target.value, error: '' });
                            }}
                            value={ILSGC.IlsgcUsername.value}
                            subtext={ILSGC.IlsgcUsername.error}
                            error={ILSGC.IlsgcUsername.error.length > 0}
                          />
                          {showIlsgcPasswordMask ? (
                            <MaskedPasswordInput
                              setPassword={(val) => {
                                ILSGC.setIlsgcPassword({ value: val, error: '' });
                              }}
                              setHasPasswordChanged={setHasIlsgcPasswordChanged}
                              hasPasswordChanged={hasIlsgcPasswordChanged}
                              showMask={!hasIlsgcPasswordChanged}
                              error={ILSGC.IlsgcPassword.error}
                              {...ILSGC.passwordInputProps}
                            ></MaskedPasswordInput>
                          ) : (
                            <TextInput
                              onChange={(e) => {
                                ILSGC.setIlsgcPassword({ value: e.target.value, error: '' });
                                setHasIlsgcPasswordChanged(e.target.value.length > 0);
                              }}
                              value={ILSGC.IlsgcPassword.value}
                              subtext={ILSGC.IlsgcPassword.error.length > 0 ? ILSGC.IlsgcPassword.error : ''}
                              error={ILSGC.IlsgcPassword.error.length > 0}
                              {...ILSGC.passwordInputProps}
                            />
                          )}
                        </StyledPaddedDiv>
                      </Fragment>
                    )}
                    <Spacer amount={5} />
                    {window.App.env.APP_ENV !== 'production' && (
                      <Checkbox
                        id="allow_writes"
                        checked={allowWrites.value == '1'}
                        onCheckedChange={(on) => setAllowWrites({value: on ? '1' : '0', error: ''})}
                        label={`Allow writes from ${window.App.env.APP_ENV}`}
                      />
                    )}
                    <Checkbox
                      id="single_family_only"
                      checked={singleFamilyOnly.value == '1'}
                      onCheckedChange={(on) => setSingleFamilyOnly({value: on ? '1' : '0', error: ''})}
                      label={`All properties are single-family in Yardi`}
                    />
                  </Fragment>
                )}
              </section>
              <Spacer amount={5} />
              <StyledSubmitButton
                id="iss-form-submit"
                type="submit"
                usage="primary"
                variant="interaction"
                disabled={!hasFormChanged || isLoading}
              >
                Submit
              </StyledSubmitButton>
            </Fragment>
          )}
        </PMSIntegrationForm>
      </IntegrationFormDiv>
    </IntegrationSetupPageDiv>
  );
};

export const CreatePartnerIntegration = () => {
  const { partnerIntegrationId, propertyOwnerId } = useParams();

  const { isSuccess } = usePartnerIntegration(partnerIntegrationId) ?? {};
  const { isSuccess: isCoverageSuccess } = usePartnerCoverageRules(partnerIntegrationId) ?? {};
  const { isSuccess: isPropertyCoverageSuccess } = usePropertyCoverageRules(propertyOwnerId) ?? {};

  return !partnerIntegrationId || (isSuccess && isCoverageSuccess && isPropertyCoverageSuccess) ? (
    <CreatePMSIntegrationSetup />
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default CreatePartnerIntegration;
