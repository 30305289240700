import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import { FONTS, PALETTE, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import { mediaQueryWidth } from '../utils';
import { BREAKPOINTS } from '../utils/constants';

export const RentalStatusContainer = styled.div({
  padding: '0px 0px 32px 0px'
});

export const DatesContainer = styled.div({
  width: '100%',
  display: 'flex'
});

export const SectionContainer = styled.div({
  padding: '0px 0px 32px 0px'
});

export const TypeaheadContainer = styled.div({
  padding: '0px 0px 16px 0px'
});

export const StartDateInputContainer = styled.div({
  flexDirection: 'row',
  width: '50%',
  padding: '0px 23px 0px 0px',
  '@media(max-width:425px)': {
    padding: '0px 13px 0px 0px'
  }
});

export const EndDateInputContainer = styled.div({
  flexDirection: 'row',
  width: '50%',
  padding: '0px 0px 0px 23px',
  '@media(max-width:425px)': {
    padding: '0px 0px 0px 13px'
  }
});

export const StyledLine = styled.hr({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral4}`,
  margin: '1em 0',
  paddingBottom: 4,
  paddingTop: 16
});

export const ErrorText = styled.p([FONTS.p3], {
  color: PALETTE.alert100,
  padding: '0px 0px 12px 0px'
});

export const RenewalRequestSentFormContainer = styled.div({
  flex: '1',
  marginLeft: '384px',
  [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
    marginLeft: '0'
  }
});

export const InvitationHeaderContainer = styled.div({
  borderBottom: `1px solid ${PALETTE.neutral4}`,
  backgroundColor: PALETTE.neutralLight,
  padding: '20px 48px',
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 0,
  [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
    display: 'none'
  }
});

export const FormContainer = styled.div({
  padding: '40px 96px',
  marginBottom: 100,
  [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
    padding: '40px 16px'
  }
});

export const PolicyRenewalDescriptionContainer = styled.div({
  textAlign: 'left',
  width: '392px',
  marginTop: '-40px'
});

export const PolicyRenewalSuccessContainer = styled.div({
  marginTop: '150px',
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

export const LineBreak = styled.div({
  marginTop: '15px'
});

export const PaperPlaneContainer = styled.div({
  textAlign: 'center',
  marginBottom: '40px'
});

export const RhinoLogoContainer = styled.div({
  marginBottom: '40px'
});

export const CloseButton = styled.button({
  background: PALETTE.neutral4,
  borderRadius: '20px',
  height: '32px',
  width: '32px',
  textAlign: 'center',
  border: 'none'
});

export const ClickHereLink = styled.a([
  {
    color: PALETTE.interaction100,
    textDecoration: 'underline',
    margin: '0px 3px 0px 3px'
  },
  FONTS.p1Medium
]);

export const closeIconCSS = css({
  height: '16px',
  width: '16px',
  color: PALETTE.neutral25,
  marginTop: '7px'
});

export const contextParaCSS = css({
  color: PALETTE.brand100,
  padding: '12px 0'
});

export const contextHeadingCSS = css({
  color: PALETTE.brand100
});

export const numberCircleCSS = css({
  border: `1px solid ${PALETTE.brand100}`,
  borderRadius: '50%',
  height: '28px',
  width: '28px',
  display: 'inline-block',
  textAlign: 'center',
  color: PALETTE.brand100,
  marginRight: 14,
  padding: 1
});

export const contextLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  margin: '1em 0 1em -48px',
  paddingBottom: 32,
  width: '384px'
});

export const closeButtonCSS = css({
  background: PALETTE.neutral4,
  borderRadius: '20px',
  height: '32px',
  width: '32px',
  textAlign: 'center',
  border: 'none'
});

export const fieldStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 12,
  borderBottom: '1px solid #F4F4F4',
  input: [
    FONTS.p1,
    {
      border: 'none',
      width: '75%'
    }
  ],
  label: [
    FONTS.p3,
    {
      color: PALETTE.neutral25,
      textAlign: 'right'
    }
  ]
});

export const fieldMargin = css({
  marginLeft: '0.5px',
  marginRight: '0.5px'
});

export const formLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutralDark}`,
  margin: '1em 0',
  paddingBottom: 32,
  paddingTop: 16
});

export const errorCSS = css([
  {
    color: PALETTE.alert100
  },
  FONTS.p3
]);

export const newErrorCSS = css([
  {
    color: PALETTE.alert125,
    margin: '2px 0px 0px 8px'
  },
  FONTS.p3Light
]);

export const errorLinkCSS = css([
  {
    color: PALETTE.alert100,
    textDecoration: 'underline',
    margin: '0 3px'
  },
  FONTS.p3
]);

export const newErrorLinkCSS = css([
  {
    color: PALETTE.alert125,
    textDecoration: 'underline',
    margin: '0px 0px 0px 3px'
  },
  FONTS.p3Light
]);

export const SendAnInviteTitle = styled.h5([FONTS.h5, { color: PALETTE.neutral25 }]);
export const StyledTitle = styled.h3([FONTS.h3, { paddingBottom: '8px' }]);
export const StyledDescription = styled.p([FONTS.p1, { paddingBottom: '60px', paddingTop: '40px' }]);
export const StyledH4 = styled.h4([FONTS.h5, { marginBottom: 16 }, fieldMargin]);

export const FeatureButton = styled.button([
  {
    background: PALETTE.success100,
    fontWeight: 500,
    color: PALETTE.neutralLight,
    borderRadius: '3px',
    border: 'none',
    marginLeft: '5px',
    pointerEvents: 'none'
  }
]);
