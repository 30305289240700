import { act, findByLabelText, render } from '@testing-library/react';
import React from 'react';
import { TestApp } from '../../index';
import makeServer from '../../testUtils/server';
import Wrapper from '../../testUtils/Wrapper';

const statusTestForAdminAndTpa = async (findByRole, findByLabelText) => {
  const statusHeading = await findByRole('heading', { name: 'Claim Status' });
  const openStatus = await findByLabelText('Open');
  const closedStatus = await findByLabelText('Closed');
  const subrogation = await findByLabelText('Subrogation');
  const subrogationPaidInFull = await findByLabelText('Subrogation - Paid in full');
  const collections = await findByLabelText('Collections');
  const collectionsPaid = await findByLabelText('Collections - Paid');
  const collectionsUnpaid = await findByLabelText('Collections - Unpaid');
  const paymentPlan = await findByLabelText('Payment plan');
  const paymentPlanInProgress = await findByLabelText('Payment plan - In progress');
  const newStatus = await findByLabelText('New');
  expect(statusHeading).toBeDefined();
  expect(openStatus).toBeDefined();
  expect(closedStatus).toBeDefined();
  expect(subrogation).toBeDefined();
  expect(subrogationPaidInFull).toBeDefined();
  expect(collections).toBeDefined();
  expect(collectionsPaid).toBeDefined();
  expect(collectionsUnpaid).toBeDefined();
  expect(paymentPlan).toBeDefined();
  expect(paymentPlanInProgress).toBeDefined();
  expect(newStatus).toBeDefined();
};
const statusTestForIlaPmPma = async (findByRole, queryByText) => {
  const statusHeading = await findByRole('heading', { name: 'Claim Status' });
  const openStatus = await queryByText('Open');
  const closedStatus = await queryByText('Closed');
  const subrogation = await queryByText('Subrogation');
  const subrogationPaidInFull = await queryByText('Subrogation - Paid in full');
  const collections = await queryByText('Collections');
  const collectionsPaid = await queryByText('Collections - Paid');
  const collectionsUnpaid = await queryByText('Collections - Unpaid');
  const paymentPlan = await queryByText('Payment plan');
  const paymentPlanInProgress = await queryByText('Payment plan - In progress');
  const newStatus = await queryByText('New');
  expect(statusHeading).toBeDefined();
  expect(openStatus).toBeDefined();
  expect(closedStatus).toBeDefined();
  expect(subrogation).toBeDefined();
  expect(subrogationPaidInFull).toBeDefined();
  expect(collections).toBeDefined();
  expect(collectionsPaid).toBeDefined();
  expect(collectionsUnpaid).toBeDefined();
  expect(paymentPlan).toBeDefined();
  expect(paymentPlanInProgress).toBeDefined();
  expect(newStatus).toBeDefined();
};

let server;

beforeEach(() => {
  const mockIntersectionObserver = jest.fn();
  mockIntersectionObserver.mockReturnValue({
    observe: () => null,
    unobserve: () => null
  });
  window.IntersectionObserver = mockIntersectionObserver;
  server = makeServer();
  jest.useFakeTimers();
});

afterEach(async () => {
  await act(async () => {
    jest.runOnlyPendingTimers();
  });
  jest.useRealTimers();
  server.shutdown();
});

describe('Claims Filters', () => {
  test('renders filters', async () => {
    const { findByRole, findByLabelText, findAllByLabelText } = render(
      <Wrapper initialEntries={['/admin/claims/filters']}>
        <TestApp />
      </Wrapper>
    );

    const heading = await findByRole('heading', { name: 'Filters' });
    expect(heading).toBeDefined();

    // state filters
    const stateHeading = await findByRole('heading', { name: 'State' });
    const nyStateOption = await findByLabelText('NY');
    expect(stateHeading).toBeDefined();
    expect(nyStateOption).toBeDefined();

    // market filters
    const marketHeading = await findByRole('heading', { name: 'Insurance Market' });
    const admittedMarket = await findByLabelText('Admitted');
    const excessLinesMarket = await findByLabelText('E&S');
    expect(marketHeading).toBeDefined();
    expect(admittedMarket).toBeDefined();
    expect(excessLinesMarket).toBeDefined();

    // status filters
    await statusTestForAdminAndTpa(findByRole, findByLabelText);

    // property filters
    const propertyHeading = await findByRole('heading', { name: 'Property' });
    const coolHauses = await findAllByLabelText(/Cool Haus/);
    expect(propertyHeading).toBeDefined();
    expect(coolHauses).toHaveLength(10);
  });
});

//As Listing agent
describe('Claims Status Filters', () => {
  test('renders filters', async () => {
    const { findByRole, queryByText } = render(
      <Wrapper initialEntries={['/admin/claims/filters']}>
        <TestApp user={{ role: 'ListingAgent' }} />
      </Wrapper>
    );

    const heading = await findByRole('heading', { name: 'Filters' });
    expect(heading).toBeDefined();

    // status filters
    await statusTestForIlaPmPma(findByRole, queryByText);
  });
});

//As Property Manager
describe('Claims Status Filters', () => {
  test('renders filters', async () => {
    const { findByRole, queryByText } = render(
      <Wrapper initialEntries={['/admin/claims/filters']}>
        <TestApp user={{ role: 'PropertyManager' }} />
      </Wrapper>
    );

    const heading = await findByRole('heading', { name: 'Filters' });
    expect(heading).toBeDefined();

    // status filters
    await statusTestForIlaPmPma(findByRole, queryByText);
  });
});

//As Property Manager Admin
describe('Claims Status Filters', () => {
  test('renders filters', async () => {
    const { findByRole, queryByText } = render(
      <Wrapper initialEntries={['/admin/claims/filters']}>
        <TestApp user={{ role: 'PropertyManagerAdmin' }} />
      </Wrapper>
    );

    const heading = await findByRole('heading', { name: 'Filters' });
    expect(heading).toBeDefined();

    // status filters
    await statusTestForIlaPmPma(findByRole, queryByText);
  });
});

//As ThirdPartyAdministrator
describe('Claims Status Filters', () => {
  test('renders filters', async () => {
    const { findByRole, findByLabelText } = render(
      <Wrapper initialEntries={['/admin/claims/filters']}>
        <TestApp user={{ role: 'ThirdPartyAdministrator' }} />
      </Wrapper>
    );

    const heading = await findByRole('heading', { name: 'Filters' });
    expect(heading).toBeDefined();

    // status filters
    await statusTestForAdminAndTpa(findByRole, findByLabelText);
  });
});
