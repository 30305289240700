import React from 'react';

import { QueryObserverResult } from 'react-query';
import { Loading } from '@sayrhino/rhino-shared-js';

import { useInvitationSummaryMetrics } from 'api/v2/useInvitationSummaryMetrics';
import { IInvitationSummaryMetricsData } from './interfaces';

import makeSummaryMetricsContainer from './makeSummaryMetricsContainer';
import DashboardPanelPill from './DashboardPanelPill';
import DashboardPanelPillLink from './DashboardPanelPillLink';
import DashboardPanelRangeBarChart from './DashboardPanelRangeBarChart';

import { SENT_INVITATIONS_TITLE, SUBSCRIBED_TITLE } from './utils/constants';

const InvitationSummaryMetrics = makeSummaryMetricsContainer('Invitation Summary', () => {
  const response: QueryObserverResult<IInvitationSummaryMetricsData> = useInvitationSummaryMetrics();
  if (response.isLoading) {
    return <Loading />;
  }

  const data: IInvitationSummaryMetricsData | undefined = response.data;

  if (!data) {
    return;
  }

  const showDaysToSignUp = () =>
    data.min_days_to_sign_up > 0 || data.max_days_to_sign_up > 0 || data.average_days_to_sign_up > 0;

  return (
    <React.Fragment>
      <DashboardPanelPill title={SENT_INVITATIONS_TITLE} value={data.sent_invitations_count} dark />
      <DashboardPanelPill title={SUBSCRIBED_TITLE} value={data.subscribed_count} />
      {showDaysToSignUp() && (
        <DashboardPanelRangeBarChart
          title="Days to sign up"
          description="Averaging the amount of time that elapsed for potential renters to sign up from invitation"
          range={{ min: data.min_days_to_sign_up, max: data.max_days_to_sign_up }}
          average={data.average_days_to_sign_up}
          unit="days"
          dateRangeInDays={30}
          updatedDate={new Date()}
        />
      )}
    </React.Fragment>
  );
});

export default InvitationSummaryMetrics;
