import { createServer, Response } from 'miragejs';
import { subDays, addDays, startOfToday } from 'date-fns';
import {
  IClaimsAnalysts,
  IAdminSummaryMetricsData,
  IClaimStaticFilterOptions,
  IClaimSummaryMetricsData,
  IInsurancePolicySummaryMetricsData,
  IInvitationSummaryMetricsData,
  IInsurancePolicyAttributes,
  IPropertyFilterOptions,
  IStaticFilterOptions,
  IData,
  ITask,
  IPaginatedMeta,
  IInsurancePolicy,
  IInvitation,
  IPMAPropertyOwnerDetails,
  CoverageTypeEnum,
  IPolicyFilterOptions,
  IPropertyUnitSearchResult
} from '../interfaces';
import { properties } from './mocks/properties';
import { updates, updates2 } from './mocks/updates';
import { mockSearchResults } from './mocks/searchResults';
import { tasks } from './mocks/tasks';
import { invitations, invitations2 } from './mocks/invitations';
import { chunk, uniq } from 'lodash';
import { parseIntDecimal } from '../utils';

// not ideal but I'm struggling to have the test pass through proper page params
// so for now we'll count requests to /updates to return the second page
let numberOfReqs = 0;

export default function ({ environment = 'test' } = {}) {
  return createServer({
    environment,
    trackRequests: true,

    routes() {
      this.get('/v2/admin/administrators?query=', () => {
        const response = {
          administrators: [
            {
              id: 1,
              first_name: 'Lakenya',
              last_name: 'Sporer',
              name_or_email: 'Lakenya Sporer',
              initials: 'LS'
            },
            {
              id: 2,
              first_name: 'Rudolf',
              last_name: 'Rutherford',
              name_or_email: 'Rudolf Rutherford',
              initials: 'RR'
            }
          ]
        };

        return response;
      });

      this.put('/v2/admin/claims/bulk_assign_admin', () => {
        return new Response(204, {});
      });

      this.get('/v2/admin/claims');

      this.get('/v2/admin/claims/8509', () => {
        const response = {
          id: 8509,
          status: 'closed_without_payment',
          claim_number: '0000018509',
          claim_type: 'Loss of rent',
          attachments: [
            {
              blob_id: 'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeklmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ',
              filename: 'dog.png',
              url: 'https://www.rd.com/wp-content/uploads/2019/09/GettyImages-621924830-scaled.jpg'
            },
            {
              blob_id:
                'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBek1mQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--7bd893ecc58feedd6d7d8f7a29d3916ce92c91b1',
              filename: 'meme.png',
              url: 'https://i.pinimg.com/originals/de/36/96/de36964639866f51b7093f0b5adbb55a.jpg'
            }
          ],
          insurance_policy: {
            id: 52129,
            renter_full_name: 'Julius Murazik',
            renter_email: 'juliusmurazik@test.com',
            remaining_coverage_amount_cents: 0,
            coverage_amount_cents: 162250,
            policy_number: 'CUS066032688',
            policy_coverage_dates: 'Nov 18, 2020 — Oct 31, 2021',
            claims_reporting_window: false
          },
          created_date: '2021-05-12T21:55:59.383Z',
          created_by: {
            full_name: 'Mark Kirlin',
            email: 'mkirlin@test.com'
          },
          amount_cents: 162250,
          total_amount_paid_cents: 0,
          property: {
            name: '1056 North Irvington Avenue',
            full_address: '1056 North Irvington Avenue, Tulsa, OK, 74115, United States'
          },
          property_owner: { name: 'Elmer Schneider' },
          policy_holder: { name: 'Ira Stracke' },
          start_date: '2020-11-17T15:30:51.000Z',
          unit_name: 'B',
          renter_full_name: 'Vicente Nienow',
          single_or_garden: false,
          tenant_notified_of_rent_arrears: true,
          payment_option_selected: 'ACH',
          subcategory_2: null,
          last_day_to_submit_claim: '2022-01-28T00:00:00.000Z',
          bank_account: {
            routing_number: null,
            account_number: null
          },
          loss_of_rents: [],
          administrator: {
            id: 1,
            name_or_email: 'Lakenya Sporer',
            initials: 'LS'
          },
          related_claims: [],
          actions: {
            withdraw: true,
            edit: true,
            available_statuses: [
              {
                value: 'new',
                label: 'new'
              },
              {
                value: 'withdrawn',
                label: 'withdrawn'
              },
              {
                value: 'processing',
                label: 'processing'
              }
            ]
          }
        };

        return response;
      });

      this.get('/v2/admin/claims/11021', () => {
        const response = {
          id: 11021,
          status: 'paid',
          claim_number: '0000021021',
          claim_type: 'Excessive wear and tear',
          created_date: '2021-07-15T20:02:15.200Z',
          amount_cents: 5000,
          damaged_room: 'Bathroom',
          total_amount_paid_cents: 0,
          start_date: '2020-08-28T00:44:50.000Z',
          end_date: '2022-01-29T00:00:00.000Z',
          last_day_to_submit_claim: '2022-01-28T00:00:00.000Z',
          unit_name: '58A',
          renter_full_name: 'John_81754 Doe_81754',
          tenant_notified_of_rent_arrears: true,
          payment_option_selected: 'ACH',
          subcategory_2: '["Doors", "Lighting"]',
          administrator: { id: 123463, name_or_email: 'John_123463 Doe_123463', initials: 'JD' },
          insurance_policy: {
            id: 36510,
            renter_full_name: 'John_81754 Doe_81754',
            renter_email: 'portal+81754@example.com',
            remaining_coverage_amount_cents: 475000,
            coverage_amount_cents: 480000,
            policy_number: 'NYO-CUS066011419',
            policy_coverage_dates: 'Sep 01, 2020 — Nov 30, 2021',
            claims_reporting_window: false
          },
          created_by: { full_name: 'John_107357 Doe_107357', email: 'portal+107357@example.com' },
          property: { id: 2050, name: 'Sky', full_address: '605 West 42nd Street, New York, NY, 10036, United States' },
          property_owner: { name: 'Owner_68' },
          policy_holder: { name: 'Holder_494' },
          single_or_garden: false,
          bank_account: { routing_number: '121122676', account_number: '0123456789012' },
          loss_of_rents: [],
          mailing_address: null,
          actions: {
            withdraw: true,
            edit: true,
            available_statuses: [
              { value: 'accepted', label: 'Accept' },
              { value: 'closed_without_payment', label: 'Closed Without Payment' },
              { value: 'subrogation', label: 'Subrogation' }
            ]
          },
          related_claims: []
        };

        return response;
      });

      this.get('/v2/admin/claims/8510', () => {
        const response = {
          id: 8510,
          status: 'new',
          claim_number: '0000018510',
          claim_type: 'Loss of rent',
          last_day_to_submit_claim: '2022-01-28T00:00:00.000Z',
          attachments: [
            {
              blob_id: 'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeklmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ',
              filename: 'dog.png',
              url: 'https://www.rd.com/wp-content/uploads/2019/09/GettyImages-621924830-scaled.jpg'
            },
            {
              blob_id:
                'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBek1mQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--7bd893ecc58feedd6d7d8f7a29d3916ce92c91b1',
              filename: 'meme.png',
              url: 'https://i.pinimg.com/originals/de/36/96/de36964639866f51b7093f0b5adbb55a.jpg'
            }
          ],
          insurance_policy: {
            id: 52129,
            renter_full_name: 'Julius Murazik',
            renter_email: 'juliusmurazik@test.com',
            remaining_coverage_amount_cents: 0,
            coverage_amount_cents: 162250,
            policy_number: 'CUS066032688',
            policy_coverage_dates: 'Nov 18, 2020 — Oct 31, 2021',
            claims_reporting_window: false
          },
          created_date: '2021-05-12T21:55:59.383Z',
          created_by: {
            full_name: 'Mark Kirlin',
            email: 'mkirlin@test.com'
          },
          amount_cents: 162250,
          total_amount_paid_cents: 0,
          property: {
            name: '1056 North Irvington Avenue',
            full_address: '1056 North Irvington Avenue, Tulsa, OK, 74115, United States'
          },
          property_owner: { name: 'Elmer Schneider' },
          policy_holder: { name: 'Ira Stracke' },
          start_date: '2020-11-17T15:30:51.000Z',
          unit_name: 'B',
          renter_full_name: 'Vicente Nienow',
          single_or_garden: false,
          tenant_notified_of_rent_arrears: true,
          payment_option_selected: 'ACH',
          subcategory_2: null,
          bank_account: {
            routing_number: null,
            account_number: null
          },
          loss_of_rents: [],
          administrator: null,
          related_claims: [],
          actions: {
            withdraw: true
          }
        };

        return response;
      });

      this.put('/v2/admin/claims/assign_admin', () => {
        return new Response(204, {});
      });

      this.get('/v2/admin/claims/property_filter_options', (_, request) => {
        const page = parseIntDecimal(request.queryParams.page);
        const stateFilters = request.queryParams['filter[states]'];
        const filteredProperties = stateFilters
          ? properties.filter((property) => stateFilters.includes(property.address_state))
          : properties;
        const totalPages = Math.ceil(filteredProperties.length / 20);
        const pagedProperties = chunk(filteredProperties, 20);

        const response: IPropertyFilterOptions = {
          data: pagedProperties[page - 1],
          meta: {
            total_count: properties.length,
            total_pages: totalPages,
            next_page: totalPages === page ? null : page + 1,
            prev_page: page === 1 ? null : page - 1,
            page,
            per: 20
          }
        };

        return response;
      });

      this.get('/v2/admin/claims/static_filter_options', () => {
        const response: IClaimStaticFilterOptions = {
          markets: [
            { label_text: 'Admitted', value: 'AdmittedPolicy' },
            { label_text: 'E&S', value: 'RenterPolicy' }
          ],
          states: uniq(properties.map((property) => property.address_state)),
          statuses: [
            { label_text: 'Open', value: 'open' },
            { label_text: 'Closed', value: 'closed' },
            { label_text: 'Subrogation', value: 'subrogation' }
          ],
          claim_classifications: [
            { label_text: 'VIP', value: 'vip' },
            { label_text: 'SIU', value: 'siu' }
          ]
        };

        return response;
      });

      this.get('/v2/admin/claims/summary_metrics', () => {
        const response: IClaimSummaryMetricsData = {
          avg_days_to_payout: 2.5,
          damages: 8,
          loss_of_rent: 6,
          open_claims: 1400,
          team_overview: [
            { name: 'Michael LaSala', open_claims: 39 },
            { name: 'Caroline Jackson', open_claims: 43 },
            { name: 'Reese Roche', open_claims: 12 },
            { name: 'Courtney Sims', open_claims: 24 }
          ],
          total_funds_paid_out: 125033.89,
          total_funds_reclaimed: 105000.22
        };

        return response;
      });

      this.get('/v2/admin/summary_metrics', () => {
        const response: IAdminSummaryMetricsData = {
          average_days_to_claim_payout_ytd: 1,
          average_days_to_first_renter_ytd: 2,
          invitations_completed_this_month_count: 3,
          invitations_sent_this_month_count: 4,
          invitations_started_total_count: 5,
          invitations_this_month_total_count: 6,
          invitations_total_count: 8,
          new_claims_this_month_count: 7,
          new_subscribers_monthly_goal: 9,
          new_subscribers_this_month_count: 8
        };

        return response;
      });

      this.get('/v2/admin/updates', (_, request) => {
        numberOfReqs += 1;
        return numberOfReqs > 1 ? updates2 : updates;
      });

      this.get('/v2/admin/tasks', () => {
        return tasks;
      });

      this.get('/v2/admin/insurance_policies', () => {
        const attributes: IInsurancePolicyAttributes = {
          coverage_amount: '$3,700.00',
          coverage_dates: 'Sep 24, 2020 - Aug 31, 2021',
          lease_start_date: 'Sep 25, 2020',
          lease_end_date: 'Sep 1, 2021',
          policy_number: 'CUS066000001',
          property_name: 'Meadow Apartments',
          property_state: 'CA',
          remaining_coverage_amount_cents: 73,
          status: 'active',
          tenant_full_name: 'Josh Conel',
          unit_name: '56',
          status_detail: {
            severity: 'lowPriority',
            label: 'Review and update information by Oct 7, 2020',
            message: undefined,
            indicator_label: '14 days left'
          },
          pending_changeset: {
            property_id: null,
            unit_id: null,
            building_name: null,
            unit_name: null,
            monthly_rent_cents: 500000,
            monthly_rent: '$5,000.00',
            lease_start_date: null,
            lease_end_date: null,
            coverage_option_selected: null,
            coverage_option_value: null,
            custom_coverage_amount_cents: null,
            custom_flat_coverage: null,
            status: 'pending',
            needs_tenant_approval: true,
            full_address: null
          }
        };

        const response: IData<ITask[], IPaginatedMeta> = {
          data: [
            {
              id: 56,
              type: 'InsurancePolicy',
              attributes
            }
          ],
          meta: {
            next_page: 2,
            page: 1,
            per: 1
          }
        };

        return response;
      });

      this.get('/v2/admin/insurance_policies/:policyId', (_, request) => {
        const ids_to_statuses = {
          '17433': 'expired',
          '17888': 'incomplete'
        };

        const response: Partial<IInsurancePolicy> = {
          id: 1,
          binding_date: subDays(new Date(), 7).toISOString(),
          can_cancel: true,
          can_edit: true,
          can_terminate_early: request.params.policyId === '111' ? false : true,
          cancellation_request: {
            id: 3,
            other_reason: null,
            reason: 2,
            reason_string: 'Roommate signed up',
            roommate_email: 'test@test.com',
            terminated_at: null,
            effective_from: null,
            response_deadline: null,
            info: null,
            status: 'pending',
            early_termination: true,
            reason_complement: ''
          },
          coverage_type: 'MULTIPLIER',
          tenant_email: 'jdegrom@mets.com',
          tenant_phone_number: '555-555-5555',
          rhino_plan_monthly_rate: '$23.00',
          rhino_plan_in_full_rate: '$230.00',
          monthly_rent: '$36,000',
          monthly_rent_cents: 3600000,
          lease_start_date:
            request.params.policyId === '12345'
              ? subDays(startOfToday(), 15).toISOString()
              : subDays(startOfToday(), 45).toISOString(),
          lease_end_date: 'Jul 23, 2021',
          policy_created_at: '2020-277T00:00:00-04:00',
          coverage_amount: '$3,700.00',
          coverage_start_date: subDays(startOfToday(), 15).toISOString(),
          coverage_end_date: '2021-243T00:00:00-04:00',
          policy_number: 'CUS066000001',
          property_address: 'Citi Field, Queens, NY 11368',
          remaining_coverage_amount_cents: 73,
          status: ids_to_statuses[request.params.policyId] || 'active',
          status_detail: [
            {
              severity: 'lowPriority',
              label: 'Review and update information by Oct 7, 2020',
              message: undefined,
              indicator_label: '14 days left'
            }
          ],
          pending_changeset: {
            property_id: null,
            unit_id: null,
            building_name: null,
            unit_name: null,
            monthly_rent_cents: 500000,
            monthly_rent: '$5,000.00',
            lease_start_date: null,
            lease_end_date: null,
            coverage_option_selected: null,
            coverage_option_value: null,
            custom_coverage_amount_cents: null,
            custom_flat_coverage: null,
            status: 'pending',
            needs_tenant_approval: true,
            full_address: null
          },
          tenant_full_name: 'Josh Conel',
          unit_name: '56',
          market_name: 'Admitted',
          upfront: parseIntDecimal(request.params.policyId) % 2 === 1 ? true : false
        };

        if (request.params.policyId === '17434') {
          response!.cancellation_request!.id = 7;
          return new Response(200, {}, response);
        }
        if (request.params.policyId === '17435') {
          return new Response(200, {}, { ...response, cancellation_request: null });
        }
        if (request.params.policyId === '25921') {
          return new Response(200, {}, { ...response, coverage_start_date: '2020-268T00:00:00-04:00'});
        }
        return new Response(200, {}, response);
      });

      this.put('/v2/admin/insurance_policy_cancellation_requests/:id', (_, request) => {
        if (request?.params?.id === '7') {
          return new Response(422, {});
        }
        return new Response(204, {});
      });

      this.post('/v2/admin/insurance_policies/generate_csv', () => {
        return new Response(200, {}, { result: 'ok' });
      });

      this.get('/v2/admin/insurance_policies/property_filter_options', (_, request) => {
        const page = parseIntDecimal(request.queryParams.page);
        const stateFilters = request.queryParams['filter[states]'];
        const filteredProperties = stateFilters
          ? properties.filter((property) => stateFilters.includes(property.address_state))
          : properties;
        const totalPages = Math.ceil(filteredProperties.length / 20);
        const pagedProperties = chunk(filteredProperties, 20);

        const response: IPropertyFilterOptions = {
          data: pagedProperties[page - 1],
          meta: {
            total_count: properties.length,
            total_pages: totalPages,
            next_page: totalPages === page ? null : page + 1,
            prev_page: page === 1 ? null : page - 1,
            page,
            per: 20
          }
        };

        return response;
      });

      this.get('/v2/admin/insurance_policies/static_filter_options', () => {
        const response: IPolicyFilterOptions = {
          states: uniq(properties.map((property) => property.address_state)),
          statuses: {
            policy_details: [
              {
                label_text: 'Active',
                value: 'active'
              },
              {
                label_text: 'Expired',
                value: 'expired'
              },
              {
                label_text: 'Missing unit',
                value: 'missing_unit'
              },
              {
                label_text: 'Claims window expiring',
                value: 'claims_window_expiring'
              }
            ],
            cancellations: [
              {
                label_text: 'Cancellation requested',
                value: 'cancellation_requested'
              },
              {
                label_text: 'Canceled',
                value: 'canceled'
              },
              {
                label_text: 'Moved out early',
                value: 'early_terminated'
              }
            ],
            renewals: [
              {
                label_text: 'Eligible for renewal',
                value: 'eligible_for_renewal'
              },
              {
                label_text: 'Will not renew – cash security deposit needed',
                value: 'will_not_renew_cash_security_deposit_needed'
              },
              {
                label_text: 'Will not renew – renter moving out',
                value: 'will_not_renew_renter_moving_out'
              },
              {
                label_text: 'Did not renew',
                value: 'did_not_renew'
              },
              {
                label_text: 'Renewed',
                value: 'upcoming'
              },
              {
                label_text: 'At risk of not renewing',
                value: 'at_risk_of_not_renewing'
              }
            ]
          }
        };

        return response;
      });

      this.get('/v2/admin/insurance_policies/summary_metrics', () => {
        const response: IInsurancePolicySummaryMetricsData = {
          partial_quotes_count: 1,
          active_policies_count: 2,
          total_units_count: 2,
          new_policies_this_month_count: 3,
          renewed_policies_last_30_days_count: 4,
          expired_policies_last_30_days_count: 5,
          active_policies_coverage_begun: 1
        };

        return response;
      });

      this.get('/v2/admin/invitations', () => {
        numberOfReqs += 1;
        return numberOfReqs > 1 ? invitations2 : invitations;
      });

      this.get('/v2/admin/invitations/:inviteId', (_, request) => {
        const response: Partial<IInvitation> = {
          can_edit: true,
          coverage_amount: '0',
          coverage_amount_cents: 0,
          coverage_dates: 'Dec 13, 2020 - Dec 12, 2021',
          coverage_months: 2.5,
          coverage_months_amount: '$9,500',
          created_at: 'Oct 06, 2020',
          full_address: '1959 Wewatta St, Denver, CO 80202',
          lease_end_date: '2021-10-08',
          lease_start_date: '2020-10-08',
          id: 13,
          monthly_rent: '$3,800',
          monthly_rent_cents: {
            cents: 380000
          },
          property_id: 4,
          required_coverage: 0,
          status: 'Sent',
          email: 'lexi@bell.com',
          first_name: 'Lexi',
          tenant_full_name: 'Lexi Bell',
          last_name: 'Bell',
          formatted_phone_number: '(303) 555-9876',
          unit_id: 7,
          unit_name: '1003',
          editable: true
        };

        if (request.params.inviteId === '31') {
          response.status = 'In Progress';
          return new Response(200, {}, response);
        } else if (request.params.inviteId === '30') {
          response.status = 'Completed';
          return new Response(200, {}, response);
        } else if (request.params.inviteId === '29') {
          response.declined = true;
          response.cash_deposit_amount_cents = { cents: 100000 };
          response.status = 'Completed';
          return new Response(200, {}, response);
        } else {
          return response;
        }
      });

      this.get('/v2/admin/invitations/property_filter_options', (_, request) => {
        const page = parseIntDecimal(request.queryParams.page);
        const stateFilters = request.queryParams['filter[states]'];
        const filteredProperties = stateFilters
          ? properties.filter((property) => stateFilters.includes(property.address_state))
          : properties;
        const totalPages = Math.ceil(filteredProperties.length / 20);
        const pagedProperties = chunk(filteredProperties, 20);

        const response: IPropertyFilterOptions = {
          data: pagedProperties[page - 1],
          meta: {
            total_count: properties.length,
            total_pages: totalPages,
            next_page: totalPages === page ? null : page + 1,
            prev_page: page === 1 ? null : page - 1,
            page,
            per: 20
          }
        };

        return response;
      });

      this.get('/v2/admin/invitations/static_filter_options', () => {
        const response: IStaticFilterOptions = {
          states: uniq(properties.map((property) => property.address_state)),
          statuses: [
            { label_text: 'Sent', value: 'sent' },
            { label_text: 'In Progress', value: 'started' },
            { label_text: 'Subscribed', value: 'completed' }
          ]
        };

        return response;
      });

      this.get('/v2/admin/invitations/summary_metrics', () => {
        const response: IInvitationSummaryMetricsData = {
          sent_invitations_count: 1,
          subscribed_count: 1,
          min_days_to_sign_up: 1,
          max_days_to_sign_up: 10,
          average_days_to_sign_up: 5
        };

        return response;
      });

      this.get('/v2/admin/properties/:propertyId/coverage_options.json', (_, req) => {
        return {
          coverage_options: {
            types: ['PROPERTY_DEFAULT', 'DOLLAR_AMOUNT', 'MULTIPLIER'],
            max_coverage_amount_cents: 10000_00,
            multipliers: [
              {
                label: '1X monthly rent',
                value: 1
              },
              {
                label: '1.5X monthly rent',
                value: 1.5
              },
              {
                label: '2X monthly rent',
                value: 2
              },
              {
                label: '3X monthly rent',
                value: 3
              }
            ],
            default: {
              type: 'DOLLAR_AMOUNT',
              label: 'Dollar amount',
              value: 1000_00
            }
          }
        };
      });

      this.get('/v2/admin/properties/:propertyId/units/:unitId/coverage_options.json', (_, req) => {
        return {
          coverage_options: {
            types: ['PROPERTY_DEFAULT', 'DOLLAR_AMOUNT', 'MULTIPLIER'],
            max_coverage_amount_cents: 10000_00,
            multipliers: [
              {
                label: '1X monthly rent',
                value: 1
              },
              {
                label: '1.5X monthly rent',
                value: 1.5
              },
              {
                label: '2X monthly rent',
                value: 2
              },
              {
                label: '3X monthly rent',
                value: 3
              }
            ],
            default: {
              type: 'DOLLAR_AMOUNT',
              label: 'Dollar amount',
              value: 1000_00
            }
          }
        };
      });

      this.get('/properties/search', () => {
        const response = {
          address_line_one: '123 Test St',
          address_city: 'New York',
          address_state: 'NY'
        };

        return new Response(200, {}, response);
      });

      this.get('/v2/admin/claims/8509/notes.json', () => {
        return {
          data: [
            {
              id: 68576,
              description: 'lets add a note with attachments',
              attachments: [
                {
                  blob_id: 'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeklmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ',
                  filename: 'another-meme.png',
                  url: 'https://thumbor.granitemedia.com/cat-memes/lWRBFUEiQjYLtPs8XBKibZtLLq8=/480x480/filters:format(webp):quality(80)/granite-web-prod/91/09/910963e0fbf947e1a6368be30e73ed19.jpeg'
                },
                {
                  blob_id:
                    'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBek1mQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--7bd893ecc58feedd6d7d8f7a29d3916ce92c91b1',
                  filename: 'just-a-meme.png',
                  url: 'https://i.pinimg.com/736x/62/bf/0d/62bf0d6b9ccd73f5e15aad8fa1d6163c.jpg'
                }
              ],
              created_by: { full_name: 'John Doe', email: 'johnDoe@example.com' },
              created_at: '2021-07-20'
            },
            {
              id: 68577,
              description: 'new note lets add it in',
              attachments: [],
              created_by: { full_name: '', email: 'administrator@example.com' },
              created_at: '2021-06-20'
            }
          ],
          meta: { total_count: 2, next_page: null, page: 1, per: 20 }
        };
      });

      this.get('v2/admin/claims/8509/attachments.json', () => {
        return {
          data: [
            {
              blob_id:
                'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMTBmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--140df7b92e245f3ac734d49d2ea6db22ce1ac279',
              filename: 'lion.jpeg',
              url: 'http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMTBmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--140df7b92e245f3ac734d49d2ea6db22ce1ac279/images%20(4).jpeg',
              attached_to: 'Claim'
            },
            {
              blob_id:
                'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMTRmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c7128864a83913c261b8ba91c45cfa0d8254934a',
              filename: 'nature.jpeg',
              url: 'http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMTRmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--c7128864a83913c261b8ba91c45cfa0d8254934a/images%20(3).jpeg',
              attached_to: 'Claim'
            },
            {
              blob_id:
                'eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMXdmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--2e5dc55dea77d6cfeab9f3d9fb18688c616980d9',
              filename: 'peacock.jpeg',
              url: 'http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMXdmQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--2e5dc55dea77d6cfeab9f3d9fb18688c616980d9/images%20(2).jpeg',
              attached_to: 'Claim'
            }
          ],
          meta: { total_count: 3, next_page: null, page: 1, per: 20 }
        };
      });

      this.post('/admin/renter_policies/:policyId/cancel_subscription', () => {
        const response = {
          message: 'Your request is being processed'
        };
        return response;
      });

      this.get('/search.json', (_, request) => {
        if (request.queryParams.query) {
          return mockSearchResults;
        } else {
          return {
            meta: {
              count: 0,
              total_count: 20,
              total_pages: 1,
              next_page: null,
              prev_page: null,
              page: 1,
              per: 20
            },
            data: []
          };
        }
      });

      this.put('/admin/admitted_policies/:policyId/terminated_earlies', () => {
        return new Response(200, {}, { result: 'ok' });
      });

      this.post('/admin/insurance_policy_change_requests', () => {
        const response = {
          message: 'Your request was submitted.',
          pending_changeset: {
            property_id: null,
            full_address: null,
            building_name: null,
            unit_id: null,
            unit_name: '3E',
            monthly_rent_cents: null,
            monthly_rent: '$0.00',
            lease_start_date: '12/10/2020',
            lease_end_date: '09/29/2021',
            coverage_option_selected: 'Enter dollar amount',
            needs_tenant_approval: true,
            status: 'pending',
            previous_unit_id: null,
            coverage_option_value: 0,
            custom_coverage_amount_cents: null,
            custom_flat_coverage: '$0.00'
          }
        };

        return new Response(200, {}, response);
      });

      this.get('/v2/admin/properties/search', () => {
        const resp = [
          {
            address_city: 'San Jose',
            address_line_one: '1607 Parkmoor Avenue',
            address_state: 'CA',
            address_zip: '95128',
            building_name: 'Vista',
            full_address: '1607 Parkmoor Avenue, San Jose, CA, 95128, United States',
            has_bank_account: true,
            id: 155345
          }
        ];

        return resp;
      });

      this.get('/properties/:property_id/units/search', (_, request) => {
        const query: string | undefined = request?.queryParams?.query;
        let units: IPropertyUnitSearchResult[] = [];
        for (let i = 1; i <= 10; i++) {
          const unit = {
            id: i,
            address_line_one: '123 Example Street',
            address_city: 'Brooklyn',
            address_state: 'NY',
            address_zip: '11122',
            address_line_two: i.toString(),
            // these don't seem to be in response by observation, but are in the type requirement
            building_name: 'Cool Building',
            full_address: '123 Example Street, Brooklyn, NY, 11122, United States'
          }
          units.push(unit);
        }
        if(query) {
          let units_filtered = units.filter(unit => unit.address_line_two.includes(query));
          return new Response(200, {}, units_filtered);
        } else {
          return new Response(200, {}, units);
        }
      });

      this.get('/v2/admin/properties/:property_id/renter_policies', () => {
        const resp = [
          {
            id: 72473,
            coverage_start_date: '2021-03-01T00:00:00Z',
            coverage_end_date: '2022-02-28T00:00:00Z',
            unit_name: 'Unit test',
            tenant_full_name: 'John_139268 Doe_139268'
          }
        ];

        return resp;
      });

      this.post('/admin/properties/validate_csv', (_, req) => {
        //@ts-ignore
        const responseType = req.requestBody.entries().next().value[1].type;
        switch (responseType) {
          case 'incorrect_format':
            return new Response(422, {}, { error: { error_type: 'incorrect_format' } });
          case 'validation_error':
            return new Response(422, {}, { error: { error_type: 'validation_error' } });
          case 'template_guideline_error':
            return new Response(422, {}, { error: { error_type: 'template_guideline_error' } });
          case 'no_rows':
            return new Response(422, {}, { error: { error_type: 'no_rows' } });
          case 'error':
            return new Response(422, {}, { error: { error_type: 'error' } });
          default:
            return new Response(200);
        }
      });

      this.post('/uploads/attachments', (_, req) => {
        return new Response(201, {}, { id: 'abcd' });
      });

      this.delete('/uploads/attachments/abcd', () => {
        return new Response(200, {}, {});
      });

      this.post('/v2/admin/claims', (_, req) => {
        return new Response(
          422,
          {},
          {
            claim: {
              errors: {
                attachments: ['Please attach a photo or document'],
                claim_type: ['Please indicate claim type'],
                loss_of_rent_line_items: [
                  {
                    date_of_loss: '2021-08-20',
                    errors: {
                      arrears_amount_cents: ['Please indicate claim amount greater than 0'],
                      date_of_loss: ['Cannot be after the end of the lease'],
                      loss_of_rent_type: ['Please indicate a subcategory']
                    },
                    id: null
                  }
                ]
              }
            }
          }
        );
      });

      this.get(`/v2/admin/claims/static_filter_options`, () => {
        const resp = {
          states: ['NJ', 'NY'],
          statuses: [
            { label_text: 'Open', value: 'open' },
            { label_text: 'Closed', value: 'closed' },
            { label_text: 'New', value: 'new' },
            { label_text: 'Processing', value: 'processing' },
            { label_text: 'Accepted', value: 'accepted' },
            { label_text: 'Denied', value: 'denied' },
            { label_text: 'Withdrawn', value: 'withdrawn' },
            { label_text: 'Paid', value: 'paid' },
            { label_text: 'Closed - Without payment', value: 'closed_without_payment' },
            { label_text: 'Subrogation', value: 'subrogation' },
            { label_text: 'Subrogation - Paid in full', value: 'subrogation_paid_in_full' },
            { label_text: 'Collections', value: 'collections' },
            { label_text: 'Collections - Paid', value: 'collections_paid' },
            { label_text: 'Collections - Unpaid', value: 'collections_unpaid' },
            { label_text: 'Payment plan', value: 'payment_plan' },
            { label_text: 'Payment plan - In progress', value: 'payment_plan_in_progress' }
          ],
          markets: [
            { label_text: 'Admitted', value: 'AdmittedPolicy' },
            { label_text: 'E\u0026S', value: 'RenterPolicy' }
          ],
          claim_classifications: [
            { label_text: 'VIP', value: 'vip' },
            { label_text: 'SIU', value: 'siu' }
          ]
        };

        return resp;
      });

      this.get('v2/admin/property_owners/:propertyOwnerId', (_, request) => {
        const id = request?.params?.propertyOwnerId;
        switch (id) {
          case '1':
            // integration, not validated
            return {
              id: 1,
              actions: { edit: true, delete: true },
              active: true,
              default_coverage_amount_cents: null,
              default_coverage_type: CoverageTypeEnum.MULTIPLIER,
              coverage_multiplier_limits: ['1.0', '1.5', '2.0', '2.5', '3.0', '4.0', '5.0', '6.0'],
              integration_partner_id: 'ffef210a-b313-47d2-9980-333cc4d65c76',
              integration_type: 'realpage',
              integration_status: 'not_validated',
              name: 'Owner_1'
            };
          case '2':
            // no integration, not validated
            return {
              id: 2,
              name: 'Jane Smith 7',
              active: true,
              coverage_multiplier_limits: ['1.0', '1.5', '2.0', '2.5', '3.0', '4.0', '5.0', '6.0'],
              default_coverage_type: CoverageTypeEnum.MULTIPLIER,
              max_coverage_amount_cents: undefined,
              default_coverage_amount_cents: null,
              actions: { edit: true, delete: true },
              integration_partner_id: null,
              integration_type: null,
              integration_status: null
            };
          case '3':
            // integration, validated
            return {
              id: 3,
              name: 'Jane Smith 5',
              active: true,
              coverage_multiplier_limits: ['1.0', '1.5', '2.0', '2.5', '3.0', '4.0', '5.0', '6.0'],
              default_coverage_type: CoverageTypeEnum.MULTIPLIER,
              max_coverage_amount_cents: undefined,
              default_coverage_amount_cents: null,
              actions: { edit: true, delete: true },
              integration_partner_id: 'ffef210a-b313-47d2-9980-333cc4d65c76',
              integration_type: 'realpage',
              integration_status: 'success'
            };
          default:
            return {};
        }
      });

      this.get('/v2/integrations/partners/:partnerIntegrationId', () => {
        const response: any = {
          integration: {
            actions: { edit_integration: true, validate_integration: false },
            enabled_partner_rule_set: true,
            enabled_custom_rule_set: true,
            integration_validation: {
              credential_check_status: 'success',
              properties_match_status: 'not_validated',
              unit_match_status: 'not_validated'
            },
            rule_set: 'Baseline',
            type: 'realpage',
            uuid: 'b85742b2-c760-48a2-a1dc-e41baef469a7',
            name: '333',
            pmc_id: 123,
            enabled: true,
            auto_invite_enabled: false,
            property_owner_id: 4,
            deposit_coverage_enabled: false,
            property_coverage_enabled: false,
            conditional_only_additional_deposit_enabled: false,
            renewal_invite_enabled: false,
            pet_deposit_enabled: false,
            unit_name_format: '(?-mix:.*)',
            update_enabled: false,
            integration_status: 'success'
          }
        };

        return response;
      });
      this.get('/v2/admin/property_owners/:propertyOwnerId/properties/search?query', () => {
        return [];
      });

      this.post('v2/admin/property_owners', () => {
        return new Response(200, {}, { data: { id: 123, name: 'abc', defaultCoverageType: 'stuff' } });
      });

      this.get('v2/admin/property_owners', () => {
        const response = {
          data: [
            {
              actions: { edit: true },
              active: true,
              coverage_multiplier_limits: ['1.0', '1.5', '3.0'],
              default_coverage_amount_cents: 10000,
              default_coverage_type: 'multiplier',
              id: 2596,
              integration_partner_id: null,
              integration_type: null,
              max_coverage_amount_cents: 10000,
              name: 'john doe 1',
              integration_status: null
            },
            {
              actions: { edit: true },
              active: true,
              coverage_multiplier_limits: ['1.0', '1.5', '3.0'],
              default_coverage_amount_cents: 1000,
              default_coverage_type: 'amount_cents',
              id: 2595,
              integration_partner_id: '76633d22-ce58-4269-affa-062571a4224b',
              integration_type: 'yardi',
              max_coverage_amount_cents: 10000,
              name: 'john doe 2',
              integration_status: 'success'
            },
            {
              id: 7321,
              name: 'john doe 3',
              active: true,
              coverage_multiplier_limits: ['1.0', '1.5', '2.0', '2.5', '3.0', '4.0', '5.0', '6.0'],
              default_coverage_type: 'multiplier',
              max_coverage_amount_cents: null,
              default_coverage_amount_cents: null,
              requires_invitation: null,
              actions: { edit: true, delete: true, can_update_claim_classification: false },
              integration_partner_id: 'f1816e9d-1d76-4588-9608-a6b3794d8f9a',
              integration_type: 'realpage',
              integration_status: 'success'
            }
          ],
          meta: { total_count: 2, next_page: 1, page: 1, per: 20 }
        };
        return response;
      });

      this.get('v2/admin/states', () => {
        return { states: ['CT', 'NY', 'VA'] };
      });

      this.get('v2/admin/claims/:claimId/audits', (_, request) => {
        return new Response(
          200,
          {},
          {
            audits: [
              {
                user: 'John Smithy',
                createdAt: '04/29/2022 05:00:33 PM',
                status: 'new',
              },
              {
                user: 'John Smithy',
                createdAt: '04/29/2022 05:23:33 PM',
                status: 'processing',
              }
            ]
          }
        );
      });
    }
  });
}
