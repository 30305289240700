import React from 'react';
import { render } from '@testing-library/react';
import ClaimSummaryMetrics from '../ClaimSummaryMetrics';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';

describe('<ClaimSummaryMetrics />', () => {
  const renderSummaryMetrics = () =>
    render(
      <Wrapper>
        <ClaimSummaryMetrics />
      </Wrapper>
    );
  let server;

  beforeEach(() => (server = makeServer()));
  afterEach(() => server.shutdown());

  test('renders summary metrics', async () => {
    const { findByText, findAllByRole } = renderSummaryMetrics();

    const listItems = await findAllByRole('listitem');

    expect(listItems[0]).toHaveTextContent('Open Claims');
    expect(listItems[0]).toHaveTextContent('1,400');

    expect(listItems[1]).toHaveTextContent('Team Overview');

    expect(listItems[2]).toHaveTextContent('Michael LaSala');
    expect(listItems[2]).toHaveTextContent('39');

    expect(listItems[3]).toHaveTextContent('Caroline Jackson');
    expect(listItems[3]).toHaveTextContent('43');

    expect(listItems[4]).toHaveTextContent('Reese Roche');
    expect(listItems[4]).toHaveTextContent('12');

    expect(listItems[5]).toHaveTextContent('Courtney Sims');
    expect(listItems[5]).toHaveTextContent('24');

    expect(listItems[6]).toHaveTextContent('Loss of rent');
    expect(listItems[6]).toHaveTextContent('6');

    expect(listItems[7]).toHaveTextContent('Damages');
    expect(listItems[7]).toHaveTextContent('8');

    expect(listItems[8]).toHaveTextContent('Average days to payout approved claims');
    expect(listItems[8]).toHaveTextContent('Filed claims usually have a 4 day settle rate.');
    expect(listItems[8]).toHaveTextContent('2.5 days');

    expect(listItems[9]).toHaveTextContent('Total claims payout');
    expect(listItems[9]).toHaveTextContent('$125,033.89');

    expect(listItems[10]).toHaveTextContent('Total reclaimed');
    expect(listItems[10]).toHaveTextContent('$105,000.22');
  });
});
