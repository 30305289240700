import React, { Component } from 'react';

import Column from 'components/common/grid/Column';
import Row from 'components/common/grid/Row';

import RadioLabel from 'components/common/forms/RadioLabel';
import { Radio, RadioGroup } from 'react-radio-group';
import { CurrencyInputUSD } from '@sayrhino/rhino-shared-js';

interface IProps {
  form_scope: string;
  coverage_type: string;
  coverage_multiplier: string;
  coverage_multiplier_limits: ICoverage[];
  coverage_amount_cents: number;
  coverage_amount_cents_error: string;
}

interface ICoverage {
  label: string;
  value: string;
}

interface IState {
  coverage_type: string;
  coverage_multiplier: string;
  coverage_amount_cents: number;
}

const COVERAGE_TYPES = [
  { text: 'Multiplier', value: 'multiplier' },
  { text: 'Dollar amount', value: 'amount_cents' }
];

class Coverage extends Component<IProps, {}> {
  public static defaultProps = {
    coverage_type: '',
    coverage_multiplier_limits: []
  };

  public props: IProps;
  public state: IState;

  constructor(props: IProps) {
    super(props);

    const { coverage_type } = props;
    const coverage_amount_cents = props.coverage_amount_cents || 0;
    const coverage_multiplier = coverage_type && (props.coverage_multiplier || 1.0).toString();

    this.state = {
      coverage_multiplier,
      coverage_type,
      coverage_amount_cents
    };
  }

  public render(): JSX.Element {
    return (
      <div>
        {this.renderRadioGroup()}
        {this.renderCoverageInput()}
      </div>
    );
  }

  private renderRadioGroup = () => {
    const { form_scope } = this.props;
    const { coverage_type } = this.state;

    return (
      <div className="form-group renter_policy__field">
        <label className="control-label col-sm-12">Coverage type</label>

        <Column sm={'10'}>
          <RadioGroup
            name={`${form_scope}[coverage_type]`}
            selectedValue={coverage_type}
            onChange={this.onChange}
            className="form__radio_group form__radio_group--raptor"
          >
            <Row>{this.coverageTypes()}</Row>
          </RadioGroup>
        </Column>
      </div>
    );
  };

  private renderCoverageInput = () => {
    const { coverage_type } = this.state;
    const multiplier = !coverage_type || coverage_type === 'multiplier';

    return multiplier ? this.renderCoverageMultiplierInput() : this.renderCoverageAmountCentsInput();
  };

  private renderCoverageMultiplierInput = () => {
    const { form_scope, coverage_multiplier_limits } = this.props;
    const { coverage_multiplier } = this.state;
    const id = `${form_scope}_coverage_multiplier`;
    const multiplierOptions = [{ label: '', value: '' }, ...coverage_multiplier_limits];

    const coverageOptions = multiplierOptions.map((coverage) => {
      const { label, value } = coverage;

      return (
        <option key={value} value={value}>
          {label}
        </option>
      );
    });

    return (
      <div className="form-group renter_policy__field" id="coverage-select">
        <label htmlFor={id} className="control-label col-sm-12">
          Coverage multiplier
        </label>

        <Column sm={'10'}>
          <select
            id={id}
            name={`${form_scope}[coverage_multiplier]`}
            value={coverage_multiplier}
            className="form-control form__input"
            onChange={this.onChangeCoverageMultiplier}
          >
            {coverageOptions}
          </select>
        </Column>
      </div>
    );
  };

  private renderCoverageAmountCentsInput = () => {
    const { form_scope } = this.props;
    const { coverage_amount_cents } = this.state;
    const id = `${form_scope}_coverage_amount_cents`;
    return (
      <>
        <input type="hidden" name={`${form_scope}[coverage_amount_cents]`} value={coverage_amount_cents} />
        <CurrencyInputUSD
          id={id}
          value={coverage_amount_cents}
          className="col-sm-10"
          onChange={(value) => this.onChangeCoverageAmountCents(value)}
          label="Coverage amount"
          error={this.props.coverage_amount_cents_error.length > 0}
          subtext={this.props.coverage_amount_cents_error}
        />
      </>
    );
  };

  private coverageTypes = (): JSX.Element[] => {
    const { form_scope } = this.props;
    const colSize = (12 / COVERAGE_TYPES.length).toString();

    return COVERAGE_TYPES.map((type, i) => {
      const value = type.value;
      const id = `${form_scope}_coverage_type_${value}`;

      return (
        <Column xs={colSize} sm={colSize} md={colSize} lg={colSize} key={i}>
          <RadioLabel target={id} className="form__radio_wrapper">
            <Radio id={id} value={value} /> {type.text}
            <span className="checkmark" />
          </RadioLabel>
        </Column>
      );
    });
  };

  private onChange = (coverage_type: string) => {
    this.setState({ coverage_type });
  };

  private onChangeCoverageMultiplier = (event) => {
    this.setState({ coverage_multiplier: event.target.value });
  };

  private onChangeCoverageAmountCents = (amount: number) => {
    this.setState({
      coverage_amount_cents: amount
    });
  };
}

export default Coverage;
