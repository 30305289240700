import axios from 'axios';
import { csrfToken } from 'utils/document';

export default function assignAdmin(id, administrator_id) {
  const token = csrfToken();
  return axios.put(`/v2/admin/claims/assign_admin`, {
    authenticity_token: token,
    id: id,
    claim: { administrator_id: administrator_id }
  });
}
