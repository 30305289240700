import React, { useMemo, useEffect } from 'react';
import { TextInput } from '@sayrhino/rhino-shared-js';
import { googleMapsPresent } from '../../../../../utils';
import GoogleAddressInput from '../../GoogleAddressInput';
import { isPresent, fetchPlaceById } from '../../../../../utils';
import { CheckingInfo } from '../../Claims/CreateClaim/claimInterface';
import { convertPlaceToPrediction, GooglePlaceResults, googlePredictionsError } from '../../../../../utils/google';
import useGooglePredictionsInput from '../../../../../hooks/v2/useGooglePredictionsInput';

interface TypeaheadOption {
  description: string;
  place_id: string;
}

const AddressOneTextInputOrTypeahead = ({
  id,
  label,
  onChangeCheckInfo,
  placeholder,
  checkMailingAddress,
  subtext,
  error,
  setCheckMailingAddress
}) => {
  const isGoogleMapsPresent = useMemo(() => googleMapsPresent(), [id]);

  /**
   * Converts google place result into an address object
   * Takes best guess on locality (checks sublocality first, then uses locality)
   * @param place google place result
   * @returns address details object
   */
  const convertPlaceToAddressObject = (place: GooglePlaceResults): CheckingInfo => {
    const street_number = getAddressData(place, 'street_number', 'long_name');
    const subpremise = getAddressData(place, 'subpremise', 'long_name');
    const route = getAddressData(place, 'route', 'long_name');
    const sublocality = getAddressData(place, 'sublocality', 'long_name');
    const locality = sublocality.length > 0 ? sublocality : getAddressData(place, 'locality', 'long_name');
    const state = getAddressData(place, 'administrative_area_level_1', 'short_name');
    const country = getAddressData(place, 'country', 'short_name');
    const zip = getAddressData(place, 'postal_code', 'short_name');

    const placeObj = {
      addressOne: `${street_number} ${route}`,
      addressTwo: `${subpremise}`,
      googlePlaceId: place.place.place_id,
      city: `${locality}`,
      state: `${state}`,
      zip: `${zip}`,
      country: `${country}`,
      formatted_address: `${place.place.formatted_address}`
    };
    return placeObj;
  };

  /**
   * Returns address_components from google place result
   * @param place google place result
   * @returns address_components object
   */
  const getAddressComponents = (place) => place?.place?.address_components ?? {};

  /**
   *
   * @param place google place result
   * @param includeStr the address_components.type to search for
   * @param key the property you're trying to retrieve, can be long_name or short_name
   * @param fallback the fallback value if the address component type cannot be found/returns undefined. Defaults to "".
   * @returns
   */
  const getAddressData = (place, includeStr, key: 'long_name' | 'short_name', fallback = '') => {
    const addressComponents = getAddressComponents(place);
    const component = addressComponents.find((addressComponent) => addressComponent.types.includes(includeStr));
    return component ? component?.[key] : fallback;
  };

  const onOptionChange = (option: TypeaheadOption): void => {
    const { description, place_id } = option;

    const placePromise = fetchPlaceById(place_id);
    placePromise.then((place) => {
      // look up address info from google place id
      const address = convertPlaceToAddressObject(place);
      // update check mailing address object with new place info
      setCheckMailingAddress(
        (checkMailingAddress: CheckingInfo): CheckingInfo => ({
          ...checkMailingAddress,
          addressOne: address.addressOne,
          addressTwo: address.addressTwo,
          city: address.city,
          state: address.state,
          zip: address.zip,
          googlePlaceId: address.googlePlaceId
        })
      );
    });
  };

  const {
    googlePredictionsStatus,
    googlePrediction,
    googlePredictions,
    setGooglePredictions,
    setGooglePrediction,
    loadOptions,
    isLoading,
    searchError,
    onChange
  } = useGooglePredictionsInput({ onOptionChange });

  const { googlePlaceId } = checkMailingAddress;

  const fetchPlaceAndSetPrediction = async () => {
    const res = await fetchPlaceById(googlePlaceId);
    const { place, status } = res;
    const placeToPrediction = convertPlaceToPrediction(place);
    setGooglePrediction(placeToPrediction);
    setGooglePredictions([placeToPrediction]);
  };

  useEffect(() => {
    if (isPresent(googlePlaceId)) {
      fetchPlaceAndSetPrediction();
    }
  }, [googlePlaceId]);

  const shouldNotRenderTypeahead = !isGoogleMapsPresent || googlePredictionsError(googlePredictionsStatus);

  if (shouldNotRenderTypeahead) {
    /// We render the classic text input from before if one of these conditions occur:
    /// 1. Google maps is not present. That means the google maps link or env var was not rendered in the DOM.
    /// 2. If we receive a google prediction error of OVER_QUERY_LIMIT or REQUEST_DENIED.
    return (
      <TextInput
        id={id}
        label={label}
        placeholder={placeholder}
        value={checkMailingAddress.addressOne}
        onChange={(e) => onChangeCheckInfo(e.target.value, 'addressOne')}
        subtext={subtext}
        error={error}
      />
    );
  }
  /// If none of the three conditions occur above, then we render our brand spanking new typeahead.
  return (
    <GoogleAddressInput
      addressOneError={error}
      subtext={subtext}
      label={label}
      id={id}
      searchError={searchError}
      isLoading={isLoading}
      value={googlePrediction}
      defaultOptions={googlePredictions}
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default AddressOneTextInputOrTypeahead;
