import styled from 'styled-components';
import arrow from './arrow.svg';

export const BulkActions = styled.div`
  line-height: 60px;
  position: relative;
  float: left;
`;

export const BulkActionLink = styled.a`
  font-size: 18px;
  color: black;
  cursor: pointer;
`;

export const BulkActionExpand = styled.ul`
  display: inline-block;
  position: absolute;
  top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  left: -20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  min-width: 220px;

  > li {
    font-size: 14px;
    font-family: MaisonNeueLight;
    line-height: initial;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }
`;

export const BulkActionArrowDown = styled.img.attrs(() => ({ src: arrow }))`
  vertical-align: middle;
  margin-left: 3px;
`;

export const BulkActionArrowUp = styled(BulkActionArrowDown)`
  transform: scaleY(-1);
`;
