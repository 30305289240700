/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import React from 'react';

export const FigureList = ({ children }) => <ul>{children}</ul>;

interface IFigureListItemProps {
  label: string;
  value: string;
  icon?: React.ReactNode;
}

export const FigureListItem: React.FC<IFigureListItemProps> = ({ label, value, icon }) => (
  <li
    css={{
      borderBottom: `1px solid ${PALETTE.neutral12}`,
      padding: '4px 0',
      display: 'flex',
      justifyContent: 'space-between'
    }}
  >
    <span>{label}</span>
    <span css={FONTS.h4}>
      <span css={{ fontWeight: 'bold' }}>{value}</span>
      <span css={{ marginLeft: 8 }}>{icon}</span>
    </span>
  </li>
);
