import axios, { AxiosResponse } from 'axios';
import { QueryKey, QueryObserverResult, useQuery } from 'react-query';
import { IAdminSummaryMetricsData, IPropertyManagerSummaryMetricsData } from 'components/v2/App/interfaces';

type IAdminSummaryMetricsKey = 'adminSummaryMetrics';
type IPropertyManagerSummaryMetricsKey = 'propertyManagerSummaryMetrics';
type ISummaryMetrics<T> = T extends IAdminSummaryMetricsKey
  ? IAdminSummaryMetricsData
  : T extends IPropertyManagerSummaryMetricsKey
  ? IPropertyManagerSummaryMetricsData
  : never;

const getSummaryMetrics = async (): Promise<ISummaryMetrics<QueryKey>> => {
  const { data }: AxiosResponse<ISummaryMetrics<QueryKey>> = await axios.get<ISummaryMetrics<QueryKey>>(
    `/v2/admin/summary_metrics`
  );
  return data;
};

export function useAdminSummaryMetrics(): QueryObserverResult<IAdminSummaryMetricsData> {
  return useQuery<IAdminSummaryMetricsData>('adminSummaryMetrics', () => getSummaryMetrics());
}

export function usePropertyManagerSummaryMetrics(): QueryObserverResult<IPropertyManagerSummaryMetricsData> {
  return useQuery<IPropertyManagerSummaryMetricsData>('propertyManagerSummaryMetrics', () => getSummaryMetrics());
}
