import { fullTextSearchPolicyHolders } from 'api/policy_holders';
import React, { Component } from 'react';
import AsyncSelect from 'components/v2/select/AsyncSelect';

import debounce from 'lodash.debounce';

interface IProps {
  prefix?: string;
  value?: IPolicyHolder;
}

interface IPolicyHolder {
  id: number;
  name: string;
}

interface IState {
  selected: IPolicyHolder | null;
  isLoading: boolean;
}

class PolicyHolderSelect extends Component<IProps, IState> {
  loadPolicyHolders: Function;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false,
      selected: props.value || null
    };

    this.loadPolicyHolders = debounce(this._loadPolicyHolders, 400);
  }

  public onChange = (selected) => {
    this.setState({ selected });
  };

  public render() {
    const { selected, isLoading } = this.state;
    let prefix = 'unit';

    if (this.props.prefix) {
      prefix = this.props.prefix;
    }

    return (
      <div id="policy_holder_select">
        <AsyncSelect
          name={`${prefix}[policy_holder_name]`}
          id={`${prefix}_policy_holder_name`}
          getOptionLabel={(options) => options.name}
          getOptionValue={(options) => options.name}
          value={selected}
          isClearable={true}
          isLoading={isLoading}
          placeholder="Type to search..."
          loadOptions={this.loadPolicyHolders}
          onChange={this.onChange}
        />
      </div>
    );
  }

  private _loadPolicyHolders = (input: string, callback: Function) => {
    this.setState({ isLoading: true });

    fullTextSearchPolicyHolders(input)
      .then((results) => {
        callback(results);
        this.setState({ isLoading: false });
      })
      .catch((results) => {
        callback([]);
        this.setState({ isLoading: false });
      });
  };
}

export default PolicyHolderSelect;
