import axios from 'axios';
import { IData, IInvitationPayload, IPaginatedMeta } from 'components/v2/App/interfaces';
import { stringify } from 'qs';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

const getInvitations = async (filter, page: number = 1) => {
  const params = Object.assign({}, { page, filter });
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data } = await axios.get<IData<IInvitationPayload[], IPaginatedMeta>>('/v2/admin/invitations', {
    params,
    paramsSerializer
  });
  return data;
};

export const invitationsQueryKey = 'invitations';

export default function useInvitations(
  filter,
  onSuccess
): InfiniteQueryObserverResult<IData<IInvitationPayload[], IPaginatedMeta>> {
  return useInfiniteQuery<IData<IInvitationPayload[], IPaginatedMeta>>(
    [invitationsQueryKey, filter],
    ({ pageParam }) => getInvitations(filter, pageParam),
    {
      getNextPageParam: (lastGroup) => lastGroup.meta.next_page,
      onSuccess
    }
  );
}
