import React from 'react';
import { render, screen } from '@testing-library/react';
import PropertyManagerDashboardPanelItems from '../PropertyManagerDashboardPanelItems';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { IPropertyManagerSummaryMetricsData } from '../interfaces';
import { UserContext } from '../utils/userRole';
import userEvent from '@testing-library/user-event';

describe('<PropertyManagerDashboardPanelItems />', () => {
  beforeAll(() => makeServer());

  const doRender = (data) =>
    render(
      <UserContext.Provider value={{ id: 1, role: 'PropertyManager', sessionId: '', isAutoApprove: false }}>
        <Wrapper>
          <PropertyManagerDashboardPanelItems data={data} />
        </Wrapper>
      </UserContext.Provider>
    );

  const makeMockData = (overrides?: object): IPropertyManagerSummaryMetricsData => ({
    claims_in_review_count: 8,
    covered_units_count: 1,
    in_progress_invitations_count: 7,
    invitations_completed_count: 5,
    renewed_policies_count: 4,
    total_invitations_count: 6,
    total_units_count: 2,
    total_invitations_count_this_month: 9,
    units_up_for_renewal_count: 3,
    ...overrides
  });

  test('renders the number of covered units when the metric is present', async () => {
    const data = makeMockData();
    const { findByText } = doRender(data);
    const item = await findByText('Covered units');

    expect(item).toBeDefined();
  });

  test('renders an "Add your first property" CTA when no units are associated to the property manager', async () => {
    const data = makeMockData({ total_units_count: 0 });
    const { findByText } = doRender(data);
    const item = await findByText('Add your first property');

    expect(item).toBeDefined();
  });

  test('Add your first property CTA redirects to Property Upload modal', async () => {
    const data = makeMockData({ total_units_count: 0 });
    const { getByRole } = doRender(data);
    const item = getByRole('button', { name: /Add your first property/i });

    expect(item).toHaveAttribute('href', '/admin/properties/add/new');
    userEvent.click(item);

    const modalHeading = await screen.queryByRole('heading', { level: 2, name: /Add new property/ });
    const singleButton = await screen.queryByText('Create new property');
    const batchButton = await screen.queryByText('Batch upload properties');
    expect(modalHeading).toBeDefined();
    expect(singleButton).toBeNull();
    expect(batchButton).toBeDefined();
  });

  test('renders the number of policies renewed when the metric is present', async () => {
    const data = makeMockData();
    const { findByText } = doRender(data);
    const item = await findByText('Renewed policies');

    expect(item).toBeDefined();
  });

  test('does not render the number of policies renewed when the metric is not present', async () => {
    const data = makeMockData({ renewed_policies_count: null });
    const { queryByText } = doRender(data);
    const item = await queryByText('Renewed policies');

    expect(item).toBeNull();
  });

  test('does not render the number of policies renewed when the metric is 0', async () => {
    const data = makeMockData({ renewed_policies_count: 0 });
    const { queryByText } = doRender(data);
    const item = await queryByText('0');

    expect(item).toBeNull();
  });

  test('renders the number of policies eligible for renewal when the metric is present', async () => {
    const data = makeMockData();
    const { findByText } = doRender(data);
    const item = await findByText('Up for renewal');

    expect(item).toBeDefined();
  });

  test('does not render the number of policies eligible for renewal when the metric is not present', async () => {
    const data = makeMockData({ units_up_for_renewal_count: null });
    const { queryByText } = doRender(data);
    const item = await queryByText('Up for renewal');

    expect(item).toBeNull();
  });

  test('does not render the number of policies eligible for renewal when the metric is 0', async () => {
    const data = makeMockData({ units_up_for_renewal_count: 0 });
    const { queryByText } = doRender(data);
    const item = await queryByText('0');

    expect(item).toBeNull();
  });

  test('renders the sign up rate when data is present', async () => {
    const data = makeMockData();
    const { findByText } = doRender(data);
    const item = await findByText('Sign up rate for invitations sent');

    expect(item).toBeDefined();
  });

  test('renders an invitation CTA when the sign up rate cannot be calculated', async () => {
    const data = makeMockData({
      invitations_completed_count: 0,
      total_invitations_count: 0
    });
    const { findByText } = doRender(data);

    const item = await findByText('Invite renters to unlock this stat');
    expect(item).toBeDefined();
  });

  test('renders the number of total invitations when present', async () => {
    const data = makeMockData();
    const { findAllByRole } = doRender(data);
    const listItems = await findAllByRole('listitem');
    const totalInvitationsItem = listItems[4];

    expect(totalInvitationsItem).toHaveTextContent('6');
  });

  test('renders the number of in-progress invitations when present', async () => {
    const data = makeMockData();
    const { findAllByRole } = doRender(data);
    const listItems = await findAllByRole('listitem');
    const totalInvitationsItem = listItems[5];

    expect(totalInvitationsItem).toHaveTextContent('7');
  });

  test('renders the number of in-progress invitations as 0 when not present in the data', async () => {
    const data = makeMockData({ in_progress_invitations_count: null });
    const { findAllByRole } = doRender(data);
    const listItems = await findAllByRole('listitem');
    const totalInvitationsItem = listItems[5];

    expect(totalInvitationsItem).toHaveTextContent('0');
  });

  test('renders the number claims in review when present', async () => {
    const data = makeMockData();
    const { findAllByRole } = doRender(data);
    const listItems = await findAllByRole('listitem');
    const totalInvitationsItem = listItems[6];

    expect(totalInvitationsItem).toHaveTextContent('8');
  });

  test('renders the number claims in review as 0 when not present in the data', async () => {
    const data = makeMockData({ claims_in_review_count: null });
    const { findAllByRole } = doRender(data);
    const listItems = await findAllByRole('listitem');
    const totalInvitationsItem = listItems[6];

    expect(totalInvitationsItem).toHaveTextContent('0');
  });

  test('does renders the sign up rate when the total_invitations_count is not present', async () => {
    const data = makeMockData({ total_invitations_count: null });
    const { queryByText } = doRender(data);
    const item = await queryByText('Sign up rate for invitations sent');

    expect(item).toBeNull();
  });

  test('does renders the sign up rate when the invitations_completed_count is not present', async () => {
    const data = makeMockData({ invitations_completed_count: null });
    const { queryByText } = doRender(data);
    const item = await queryByText('Sign up rate for invitations sent');

    expect(item).toBeNull();
  });
});
