import React, { Component } from 'react';
import { fetchPlaceById, GooglePlaceResults } from 'utils/google';

interface IProps {
  place_id?: string;
}
interface IGooglePlace {
  place_id: string;
  formatted_address: string;
}
interface IState {
  place?: IGooglePlace;
  isLoading: boolean;
}

class GooglePlaceId extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: !!this.props.place_id
    };
  }

  public componentDidMount() {
    if (this.props.place_id) {
      fetchPlaceById(this.props.place_id).then((results: GooglePlaceResults) => {
        const { place } = results;
        this.setState({ place: place, isLoading: false });
      });
    }
  }

  public render() {
    const { place, isLoading } = this.state;

    if (isLoading) {
      return <div></div>;
    }

    if (!place) {
      return <div>Place not selected or not found</div>;
    }

    return (
      <div id="geocode">
        <div>ID: {place.place_id}</div>
        <div>Address: {place.formatted_address}</div>
      </div>
    );
  }
}

export default GooglePlaceId;
