import axios from 'axios';
import { IData, IPaginatedMeta, ITask } from 'components/v2/App/interfaces';
import { stringify } from 'qs';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';
import { IFilters } from '../../components/v2/App/interfaces';

const getClaims = async (filter: IFilters, page: number = 1) => {
  const params = Object.assign({}, { page, filter });
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data } = await axios.get<IData<ITask[], IPaginatedMeta>>('/v2/admin/claims', {
    params,
    paramsSerializer
  });

  return data;
};

export default function useClaims(filter): InfiniteQueryObserverResult<IData<ITask[], IPaginatedMeta>> {
  return useInfiniteQuery<IData<ITask[], IPaginatedMeta>>(
    ['claims', filter],
    ({ pageParam }) => getClaims(filter, pageParam),
    {
      getNextPageParam: (lastGroup) => lastGroup.meta.next_page
    }
  );
}
