import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import CreatableAsyncSelect from 'components/v2/select/CreatableAsyncSelect';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import { PALETTE, ROUNDNESS, FONTS, Alert2Icon } from '@sayrhino/rhino-shared-js';
import Select from 'components/v2/select/Select';
import { useQueryClient } from 'react-query';
import { fetchMultiplier, fetchPolicyHolder, fetchProperties, fetchPropertyOwner, fetchUnits } from './queryHooks';
import Tooltip from '../../Tooltip';

const Wrapper = styled.div([
  ROUNDNESS.s,
  {
    border: `1px solid ${PALETTE.neutral25}`,
    padding: '0 4px',
    margin: '4px 0 10px 0'
  }
]);

const Error = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const Label = styled.label([
  FONTS.labelMedium,
  {
    fontSize: '12px',
    lineHeight: '20px',
    color: PALETTE.neutral65
  }
]);

const ErrorLabel = styled.label([
  FONTS.p3Medium,
  {
    color: PALETTE.alert125
  }
]);

const Span = styled.span([
  PALETTE.alert100,
  FONTS.labelMedium,
  {
    color: PALETTE.alert100
  }
]);

const ErrorSpan = styled.span([
  FONTS.p3Light,
  {
    color: PALETTE.alert125,
    marginBottom: '12px'
  }
]);

type MProps = {
  id?: number | null | string;
  error?: string;
  value?: { value?: number | string; label?: string };
  onChange?: (value: { label?: string; value?: number | string }) => void;
  label?: string;
  errorPresent?: boolean;
  subtext?: string;
};

export type Value = {
  id: string | number | undefined | null;
  name: string;
  __isNew__?: boolean;
};

type AProps = {
  label?: string;
  value?: Value;
  error?: string;
  onChange?: (value: { id: string | undefined; name: string }) => void;
};

export const ErrorDisplay: React.FC<{ error: string }> = ({ error }) => {
  return (
    <Error>
      <Alert2Icon width="12px" height="12px" color={PALETTE.alert100} />
      <Span>{error}</Span>
    </Error>
  );
};

export const MultiplierSelect: React.FC<MProps> = ({ id, error, value, onChange }) => {
  const [options, setOptions] = useState([]);
  const queryClient = useQueryClient();

  const loadOptions = async (id?: string | number | null) => {
    try {
      const data = await queryClient.fetchQuery(['multiplier'], () => fetchMultiplier(id));
      setOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadOptions(id);
  }, [id]);

  return (
    <>
      <Label htmlFor="multiplier">Multiplier</Label>
      <Wrapper id="multiplier">
        <Select options={options} isMulti={false} value={value} onChange={onChange} isClearable={true} placeholder="" />
      </Wrapper>
      {error && <ErrorDisplay error={error} />}
    </>
  );
};

export const MultiplierPropertySelect: React.FC<MProps> = ({ id, label, errorPresent, value, onChange, subtext }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const loadOptions = async (id?: string | number | null) => {
    setIsLoading(true);
    try {
      const data = await queryClient.fetchQuery(['multiplier'], () => fetchMultiplier(id));
      setOptions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadOptions(id);
  }, [id]);

  return (
    <>
      {errorPresent ? (
        <ErrorLabel htmlFor="multiplier">{label}</ErrorLabel>
      ) : (
        <Label htmlFor="multiplier">{label}</Label>
      )}
      <Wrapper id="multiplier">
        <AsyncSelect
          defaultOptions={options}
          options={options}
          loadOptions={loadOptions}
          isMulti={false}
          value={{ value: value?.value, label: value?.label }}
          onChange={onChange}
          isClearable={true}
          placeholder=""
          error={errorPresent}
          errorStyled={true}
          isLoading={isLoading}
        />
      </Wrapper>
      {subtext && <ErrorSpan>{subtext} </ErrorSpan>}
    </>
  );
};

export const PropertyOwnerSelect: React.FC<AProps> = ({ label, error, value, onChange }) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadOptions = async (input: string, callback: any) => {
    setIsLoading(() => true);
    try {
      const data = await queryClient.fetchQuery(['propertyOwnerSearch'], () => fetchPropertyOwner(input));
      callback(data);
      setIsLoading(() => false);
    } catch (error) {
      callback([]);
      setIsLoading(() => false);
    }
  };

  return (
    <>
      {error ? <ErrorLabel htmlFor="multiplier">{label}</ErrorLabel> : <Label htmlFor="multiplier">{label}</Label>}
      <Tooltip message="Please contact Rhino if you’d like to add or modify the options in this list." icon="question-mark" />
      <Wrapper>
        <AsyncSelect
          isMulti={false}
          onChange={onChange}
          value={value}
          getOptionValue={(options: Value) => options.id}
          getOptionLabel={(options: Value) => options.name}
          isClearable={true}
          isLoading={isLoading}
          placeholder=""
          defaultOptions
          loadOptions={loadOptions}
          error={error}
        />
      </Wrapper>
    </>
  );
};

export const PolicyHolderSelect: React.FC<AProps> = ({ label, error, value, onChange }) => {
  const getNewOptionData = (inputValue, optionLabel) => ({ id: inputValue, name: optionLabel, __isNew__: true });
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadOptions = async (input: string, callback: any) => {
    setIsLoading(() => true);
    try {
      const data = await queryClient.fetchQuery(['policyHolderSearch'], () => fetchPolicyHolder(input));
      callback(data);
      setIsLoading(() => false);
    } catch (error) {
      callback([]);
      setIsLoading(() => false);
    }
  };

  return (
    <>
      {error ? <ErrorLabel htmlFor="multiplier">{label}</ErrorLabel> : <Label htmlFor="multiplier">{label}</Label>}
      <CreatableAsyncSelect
        isMulti={false}
        onChange={onChange}
        value={value}
        getOptionValue={(options: Value) => options.id}
        getOptionLabel={(options: Value) => options.name}
        getNewOptionData={getNewOptionData}
        isClearable={true}
        isLoading={isLoading}
        placeholder=""
        loadOptions={loadOptions}
        error={error}
      />
    </>
  );
};

type IProperty = {
  id: number;
  building_name: string;
  address_line_one: string;
  address_state: string;
  address_zip: string;
  address_city: string;
};

type PProps = {
  id?: number;
  label?: string;
  value?: IProperty;
  error?: string;
  onChange?: (value: IProperty) => void;
};

type Unit = {
  id?: number;
  label?: string;
  value?: { id: number; address_line_two: string };
  error?: string;
  onChange?: (value: any) => void;
};

export const PropertySelect: React.FC<PProps> = ({ label, error, value, onChange }) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadOptions = async (input: string, callback: any) => {
    setIsLoading(() => true);
    try {
      const data = await queryClient.fetchQuery(['propertySearch'], () => fetchProperties(input));
      callback(data);
      setIsLoading(() => false);
    } catch (error) {
      callback([]);
      setIsLoading(() => false);
    }
  };

  const formatOptionLabel = (options: IProperty) => {
    let addr = '';
    if (options.building_name) {
      addr += options.building_name + ', ';
    }
    addr += `${options.address_line_one}, ${options.address_city}, ${options.address_state}, ${options.address_zip}`;
    return addr;
  };

  return (
    <>
      <Label htmlFor="property">{label}</Label>
      <Wrapper id="property">
        <AsyncSelect
          isMulti={false}
          onChange={onChange}
          value={value}
          getOptionValue={(options: IProperty) => options.id}
          getOptionLabel={formatOptionLabel}
          isClearable={true}
          isLoading={isLoading}
          placeholder=""
          loadOptions={loadOptions}
        />
      </Wrapper>
      {error && <ErrorDisplay error={error} />}
    </>
  );
};

export const UnitSelect: React.FC<Unit> = ({ id, label, error, value, onChange }) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadOptions = async (input: string, callback: any) => {
    setIsLoading(() => true);
    try {
      const data = await queryClient.fetchQuery(['unitSearch'], () => fetchUnits(id, input));
      callback(data);
      setIsLoading(() => false);
    } catch (error) {
      callback([]);
      setIsLoading(() => false);
    }
  };

  return (
    <>
      <Label htmlFor="unit">{label}</Label>
      <Wrapper id="unit">
        <AsyncSelect
          isMulti={false}
          onChange={onChange}
          value={value}
          getOptionValue={(options: any) => options.id}
          getOptionLabel={(options: any) => options.address_line_two}
          isClearable={true}
          isLoading={isLoading}
          placeholder=""
          loadOptions={loadOptions}
        />
      </Wrapper>
      {error && <ErrorDisplay error={error} />}
    </>
  );
};

export const integrationTypes = [
  'no_integration',
  'yardi',
  'realpage',
  'udr',
  'rent_manager',
  'mri',
  'entrata',
  'eqr'
] as const;
const integrationOptions = integrationTypes.map((option) => ({
  label: option,
  value: option
}));

type IValue = {
  label: string;
  value?: typeof integrationTypes[number];
};

type IntegrationProp = {
  id?: number;
  label?: string;
  value?: typeof integrationTypes[number];
  error?: string;
  onChange?: (value: IValue) => void;
};

export const IntegrationSelect: React.FC<IntegrationProp> = ({ label, error, value, onChange }) => {
  return (
    <div>
      <Label htmlFor="integration">{label}</Label>
      <Wrapper id="integration">
        <Select
          isMulti={false}
          onChange={onChange}
          value={{ value, label: value }}
          isClearable={true}
          placeholder=""
          options={integrationOptions}
        />
      </Wrapper>
      {error && <ErrorDisplay error={error} />}
    </div>
  );
};
