import React from 'react';
import {
  SectionHeader,
  SectionDivider,
  ClaimDetailIndividualWrapper,
  AttachmentList,
  LoadingWrapper,
  FileName,
  FileNameLink,
  AttachmentButtonContainer,
  AttachmentTag,
  AttachmentSectionDivider,
  AttachmentNameDivCSS,
  FileIconDivCSS,
  StyledAttachmentCopy,
  FileListItem
} from './ClaimStyles';
import { FileIcon, PALETTE, Loading, DownloadIcon } from '@sayrhino/rhino-shared-js';
import Pagination from '../../Filters/Pagination';
import useUserRole from '../../utils/userRole';
import { getClaimAttachmentPath } from '../../utils/routeHelpers';
import { useParams } from 'react-router-dom';

const ClaimAttachments = ({
  attachmentsData,
  page,
  setPage,
  isLoading,
  handleModal,
  handleDownload,
  isModalOpen,
  getNextTabIndex,
  selectedFile,
  hideTopmostDivider = false
}) => {
  const userRole = useUserRole();
  const { claimsId } = useParams();
  const { isPropertyManager, isPropertyManagerAdmin } = userRole;
  const hideAttachmentTag = isPropertyManager || isPropertyManagerAdmin || isModalOpen;

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  const isSelectedAttachment = (attachment) => {
    return selectedFile && selectedFile.blob_id === attachment.blob_id;
  };

  return (
    <>
      {!hideTopmostDivider && !isModalOpen && <SectionDivider />}
      <ClaimDetailIndividualWrapper>
        <SectionHeader>Attachments</SectionHeader>
        {isModalOpen && <AttachmentSectionDivider />}
        <AttachmentList isModal={isModalOpen}>
          {attachmentsData?.data?.map((attachment) => (
            <FileListItem
              isSelected={isSelectedAttachment(attachment)}
              isModalOpen={isModalOpen}
              key={attachment.blob_id}
              onClick={() => handleModal(attachment)}
            >
              <div style={isModalOpen ? AttachmentNameDivCSS : FileIconDivCSS}>
                {!isModalOpen && <FileIcon style={{ marginRight: 12, color: PALETTE.interaction100 }} />}
                <FileNameLink
                  to={`/${getClaimAttachmentPath({ blob_id: attachment.blob_id, claimId: claimsId ?? '' })}`}
                  state={attachment}
                >
                  {attachment.filename}
                </FileNameLink>
              </div>
              <AttachmentButtonContainer>
                <DownloadIcon
                  tabIndex={getNextTabIndex(1)}
                  onClick={() => handleDownload(attachment.download_url, attachment.filename)}
                  height={32}
                  width={32}
                  css={{
                    padding: 6,
                    border: `1px solid ${PALETTE.neutralDark}`,
                    borderRadius: 60,
                    '&:hover': {
                      cursor: 'pointer'
                    },
                    '&:active': {
                      outlineStyle: 'auto'
                    },
                    '&:focus': {
                      outlineStyle: 'auto'
                    }
                  }}
                />
                {!hideAttachmentTag && <AttachmentTag>{attachment.attached_to}</AttachmentTag>}
              </AttachmentButtonContainer>
            </FileListItem>
          ))}
        </AttachmentList>
        {attachmentsData?.meta?.total_count > 20 && (
          <Pagination
            currentPageCount={attachmentsData?.data?.length}
            nextPage={attachmentsData?.meta?.next_page}
            page={page}
            per={attachmentsData?.meta?.per}
            prevPage={attachmentsData?.meta?.prev_page}
            setPage={setPage}
            totalCount={attachmentsData?.meta?.total_count}
            totalPages={Math.ceil(attachmentsData?.meta?.total_count / attachmentsData?.meta?.per)}
          />
        )}
        {!isModalOpen && (
          <StyledAttachmentCopy>
            If the unit has been vacant for 1 or more months, you are required to submit evidence that you are actively
            trying to find a renter for the unit. We accept screenshots or links as evidence of your effort to rerent
            the unit.
          </StyledAttachmentCopy>
        )}
      </ClaimDetailIndividualWrapper>
    </>
  );
};

export default ClaimAttachments;
