import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

export const StyledLabel = styled.label([FONTS.p3Medium, { color: PALETTE.neutral65 }]);
export const StyledSaveButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end'
});
export const ErrorText = styled.p([
  {
    color: PALETTE.alert100
  },
  FONTS.p3
]);

export const ToggleContainer = styled.div(
  {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginBottom: '16px'
  }
)
