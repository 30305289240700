/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { PALETTE, DownloadIcon } from '@sayrhino/rhino-shared-js';
import DocumentIcon from './DocumentIcon';

interface IProps {
  iconVisible?: boolean;
  fileViewer?: boolean;
  children: React.ReactNode;
  onIconClick?: () => void;
  onContainerClick?: () => void;
}

type Props = IProps & JSX.IntrinsicElements['button'];

const DownloadBanner: React.FC<Props> = (props) => {
  const { children, onIconClick, onContainerClick, iconVisible, fileViewer, ...rest } = props;

  return (
    <button
      css={{
        border: `1px solid ${PALETTE.neutral12}`,
        background: 'none',
        width: '100%',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 4
      }}
      onClick={() => props.onContainerClick && props.onContainerClick()}
      {...rest}
    >
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {iconVisible && (
          <div
            css={{
              marginTop: 16,
              marginBottom: 16,
              marginLeft: 20,
              borderRadius: 8,
              marginRight: 24
            }}
          >
            <DocumentIcon />
          </div>
        )}
        <div css={{ textAlign: 'left' }}>{children}</div>
      </div>
      <DownloadIcon
        height={32}
        width={32}
        css={{
          marginRight: 20,
          padding: 6,
          border: `1px solid ${PALETTE.neutralDark}`,
          borderRadius: 60
        }}
        onClick={(e) => {
          if (props.onIconClick) {
            e.stopPropagation();
            props.onIconClick();
          }
        }}
      />
    </button>
  );
};

export default DownloadBanner;
