/** @jsx jsx */

import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { PALETTE, Tag } from '@sayrhino/rhino-shared-js';

const StyledTag = styled(Tag)({
  marginRight: '8px',
  marginBottom: '8px',
  button: { backgroundColor: PALETTE.neutral25 }
});

const AppliedFiltersTag = ({ index, tagText, onDelete }) => {
  return (
    <StyledTag id={`status-filter-option-${index}`} key={`status-filter-option-${index}`} onDelete={onDelete}>
      {tagText}
    </StyledTag>
  );
};

export default AppliedFiltersTag;
