import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Button as ButtonBase,
  FONTS,
  PALETTE,
  Alert2Icon,
  InlineNotification,
  Loading,
  TextInput
} from '@sayrhino/rhino-shared-js';
import { Link } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import FormFieldBase from '../FormField';
import { ACCOUNT_ROUTES } from '../utils/routeHelpers';
import {
  validateEmail,
  validatePasswordsMatch,
  validatePasswordLength,
  validatePasswordContainsNumber,
  validatePasswordContainsSymbol
} from 'components/v2/App/utils/formValidations';
import useAccountDetails from 'api/v2/useAccountDetails';
import useDisableTFAMutation from 'api/v2/useDisableTFAMutation';
import { useUpdateTFAState, useUserId, useUserRole } from '../utils/userRole';
import { AxiosError } from 'axios';
import updateAccountDetails from 'api/v2/updateAccountDetails';
import { NotificationWrapper } from './Styled';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import ValidationBase from 'components/v2/validation';

const PageWrapper = styled.div({ padding: '30px 48px' });
const Title = styled.h3([FONTS.h3, { marginBottom: '36px' }]);
const FormField = styled(FormFieldBase)({ marginTop: '28px' });
const HelperText = styled.p([FONTS.p2, { marginTop: '-18px', marginBottom: '24px' }]);
const SubText = styled.p([FONTS.h6Medium, { marginTop: '24px' }]);
const ButtonGroup = styled.div({ display: 'flex' });
const Button = styled(ButtonBase)({ margin: '24px', marginLeft: 0 });
const LinkButton = styled(Link)([
  FONTS.h5,
  {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    padding: '0 24px',
    borderRadius: '24px',
    cursor: 'pointer',
    margin: '24px 24px 24px 0',
    width: '157px',
    backgroundColor: 'transparent',
    color: PALETTE.neutralDark,
    border: '1px solid',
    borderColor: PALETTE.neutralDark,
    '&:hover': {
      backgroundColor: PALETTE.neutralDark,
      color: PALETTE.neutralLight
    },
    '&:disabled': {
      color: PALETTE.neutral25,
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'not-allowed'
    }
  }
]);
const TwoFactorWrapper = styled.div({
  borderBottom: '1px',
  borderColor: '#E3E3E3',
  borderStyle: 'solid',
  marginBottom: '24px'
});

const SubtextErrorWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const SubtextErrorMessage = styled.span(FONTS.p3Light, {
  color: PALETTE.alert125,
  marginLeft: '8px'
});

const ValidationWrapper = styled.div({ paddingTop: '12px', marginBottom: '32px' });
const Validation = styled(ValidationBase)({ marginBottom: '16px' });

const MyProfileFormView: React.FC = () => {
  const userId = useUserId();
  const { addToast } = useToast();
  const { data: user } = useAccountDetails(userId);
  const [firstName, setFirstName] = useState(user?.first_name);
  const [lastName, setLastName] = useState(user?.last_name);
  const [email, setEmail] = useState(user?.email);
  const [currentPassword, setCurrentPassword] = useState<string | undefined>('');
  const [password, setPassword] = useState<string | undefined>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string | undefined>('');
  const [errorMessage, setErrorMessage] = useState<Array<string> | undefined>();
  const [emailInputError, setEmailInputError] = useState<string>();
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string>();
  const queryCache = useQueryClient();
  const updateTFABanner = useUpdateTFAState();
  const role = useUserRole();

  // Necessary when user goes directly to Accounts page to update TFA Banner
  if (updateTFABanner) {
    const admin = role.isAdmin || role.isThirdPartyAdmin;
    const showBanner = admin
      ? !Boolean(user?.okta_integration) && !Boolean(user?.otp_required_for_login)
      : Boolean(user?.two_factor_required);
    updateTFABanner(showBanner);
  }

  const validateForm = () => {
    let disable = false;

    if (emailInputError !== undefined) disable = true;
    if (passwordConfirmationError !== undefined) disable = true;
    if (password !== '') {
      if (!validatePasswordLength(password)) disable = true;
      if (!validatePasswordContainsNumber(password)) disable = true;
      if (!validatePasswordContainsSymbol(password)) disable = true;
    }
    return disable;
  };

  const validateEmailInput = () => {
    const emailValidation = validateEmail(email);
    if (emailValidation) {
      setEmailInputError(emailValidation);
    } else {
      setEmailInputError(undefined);
    }
  };

  const validatePasswordInput = (value) => {
    setPassword(value);

    if (value !== passwordConfirmation && passwordConfirmation !== undefined) {
      validatePasswordConfirmation(passwordConfirmation, value);
    }
    if (value === passwordConfirmation) {
      setPasswordConfirmationError(undefined);
    }
  };

  const validateCurrentPasswordInput = (value) => {
    setCurrentPassword(value);
  };

  const validatePasswordConfirmation = (value, password) => {
    if (value !== passwordConfirmation) {
      setPasswordConfirmation(value);
    }

    const passwordValidation = validatePasswordsMatch(password, value);
    if (passwordValidation) {
      setPasswordConfirmationError(passwordValidation);
    } else {
      setPasswordConfirmationError(undefined);
    }
  };

  const { mutate: updateAccountMutation } = useMutation(updateAccountDetails);
  const { mutate: disableTfa } = useDisableTFAMutation();

  const handleDisableTFA = async () => {
    disableTfa(null, {
      onSuccess: () => {
        if (updateTFABanner) updateTFABanner(true);

        queryCache.invalidateQueries('account details');
      }
    });
  };

  const subtextError = (error) => {
    return (
      <SubtextErrorWrapper>
        <Alert2Icon color={PALETTE.alert125} height="16px" width="16px" />
        <SubtextErrorMessage>{error}</SubtextErrorMessage>
      </SubtextErrorWrapper>
    );
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    updateAccountMutation(
      {
        id: userId,
        first_name: firstName,
        last_name: lastName,
        email,
        current_password: currentPassword,
        password,
        password_confirmation: passwordConfirmation
      },
      {
        onSuccess: () => {
          addToast('Your account has been updated!', '', TOAST_STATUS.SUCCESS);
        },
        onError: (error: AxiosError) => {
          const accountErrorResponse = error?.response?.data?.errors;
          if (accountErrorResponse && accountErrorResponse.length) {
            setErrorMessage(accountErrorResponse);
            addToast(accountErrorResponse, '', TOAST_STATUS.ERROR);
          }
        },
        onSettled: () => {
          queryCache.invalidateQueries('account details');
        }
      }
    );
  };

  return (
    <PageWrapper>
      <Title>My Profile</Title>
      <form onSubmit={handleSubmit} method="POST">
        <TextInput
          id="first_name"
          label="First Name"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextInput
          id="last_name"
          label="Last Name"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextInput
          id="email"
          label="Email"
          placeholder="Email"
          subtext={emailInputError ? subtextError(emailInputError) : ''}
          error={emailInputError !== undefined}
          value={email}
          onBlur={() => validateEmailInput()}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextInput
          id="current_password"
          label="Current Password"
          placeholder="Current Password"
          value={currentPassword}
          autoComplete="off"
          onChange={(e) => validateCurrentPasswordInput(e.target.value)}
        />
        <TextInput
          id="password"
          label="New Password"
          placeholder="New Password"
          value={password}
          autoComplete="off"
          onChange={(e) => validatePasswordInput(e.target.value)}
        />
        <HelperText>8 characters minimum. Leave blank if you don’t want to change your password</HelperText>
        <TextInput
          id="password_confirmation"
          label="Password Confirmation"
          placeholder="Password Confirmation"
          subtext={passwordConfirmationError ? subtextError(passwordConfirmationError) : ''}
          error={passwordConfirmationError !== undefined}
          value={passwordConfirmation}
          onChange={(e) => validatePasswordConfirmation(e.target.value, password)}
        />
        <ValidationWrapper>
          <Validation label={'At least 8 characters'} active={validatePasswordLength(password)} />
          <Validation label={'At least 1 number'} active={validatePasswordContainsNumber(password)} />
          <Validation label={'At least 1 symbol'} active={validatePasswordContainsSymbol(password)} />
        </ValidationWrapper>
        <TwoFactorWrapper>
          <SubText>Two-Factor Authentication Setting</SubText>
          {user?.otp_required_for_login ? (
            <ButtonGroup>
              <Button onClick={handleDisableTFA} variant="white">
                Disable 2FA
              </Button>
              <Link to={'two_factor/codes'}>
                <Button variant="tertiary">Regenerate Backup Codes</Button>
              </Link>
            </ButtonGroup>
          ) : (
            <LinkButton to={'two_factor/setup'}>Activate 2FA</LinkButton>
          )}
        </TwoFactorWrapper>
        <ButtonBase variant="primary" type="submit" disabled={validateForm()}>
          Update
        </ButtonBase>
      </form>
    </PageWrapper>
  );
};

const MyProfileForm = () => {
  const userId = useUserId();
  const { isSuccess } = useAccountDetails(userId);
  return isSuccess ? <MyProfileFormView /> : <Loading />;
};

export default MyProfileForm;
