import React, { useMemo, useEffect } from 'react';
import { googleMapsPresent } from '../../../../../utils';
import { isPresent, fetchPlaceById } from '../../../../../utils';
import { convertPlaceToPrediction, googlePredictionsError } from '../../../../../utils/google';
import useGooglePredictionsInput from '../../../../../hooks/v2/useGooglePredictionsInput';
import GoogleAddressInput from '../../GoogleAddressInput';

interface TypeaheadOption {
  description: string;
  place_id: string;
}
interface IProps {
  id: string;
  label: string;
  googlePlaceId: string;
  setGooglePlaceId: (placeId: string) => void;
  subtext?: string;
  error?: string;
}

const GooglePlaceIdTypeahead: React.FC<IProps> = ({ id, label, googlePlaceId, subtext, error, setGooglePlaceId }) => {
  const isGoogleMapsPresent = useMemo(() => googleMapsPresent(), [id]);

  const onOptionChange = (option: TypeaheadOption): void => {
    const { description, place_id } = option;
    setGooglePlaceId(place_id);
  };

  const {
    googlePredictionsStatus,
    googlePrediction,
    googlePredictions,
    setGooglePredictions,
    setGooglePrediction,
    loadOptions,
    isLoading,
    searchError,
    onChange
  } = useGooglePredictionsInput({ onOptionChange });

  const fetchPlaceAndSetPrediction = async () => {
    const res = await fetchPlaceById(googlePlaceId);
    const { place, status } = res;
    const placeToPrediction = convertPlaceToPrediction(place);
    setGooglePrediction(placeToPrediction);
    setGooglePredictions([placeToPrediction]);
  };

  useEffect(() => {
    if (isPresent(googlePlaceId)) {
      fetchPlaceAndSetPrediction();
    }
  }, [googlePlaceId]);

  const shouldNotRenderTypeahead = !isGoogleMapsPresent || googlePredictionsError(googlePredictionsStatus);

  /// We render the classic text input from before if one of the three conditions occur:
  /// 1. Google maps is not present. That means the google maps link or env var was not rendered in the DOM.
  /// 2. If we receive a google prediction error of OVER_QUERY_LIMIT or REQUEST_DENIED.
  ///   - Gotta love those rate limiting and API denial errors.
  if (shouldNotRenderTypeahead) return null;

  /// If none of the two conditions occur above, then we render our brand spanking new typeahead.
  return (
    <GoogleAddressInput
      addressOneError={error ?? ''}
      subtext={subtext ?? ''}
      label={label}
      id={id}
      searchError={searchError}
      isLoading={isLoading}
      value={googlePrediction}
      defaultOptions={googlePredictions}
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default GooglePlaceIdTypeahead;
