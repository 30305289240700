import React, { useState } from 'react';
import styled from '@emotion/styled';
import { StyledCardDetails } from '../ClaimDetails/ClaimStyles';
import { FONTS, ButtonHabitat, CurrencyInputUSD, DateInput as DateInputBase } from '@sayrhino/rhino-shared-js';
import {
  useAddClaimPayment,
  CLAIM_PAYOUTS,
  useUpdateClaimPayment,
  useDeleteClaimPayment
} from 'api/v2/useClaimPayments';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';
import { AxiosError } from 'axios';
import { formatDate } from '../../utils/formatDate';
import WarningModal from '../../Integrations/WarningModal';

const CardHeader = styled.h5(FONTS.h5);

const DateInput = styled(DateInputBase)({
  width: '100%'
});

const ButtonRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const Row = styled(ButtonRow)({
  gap: '16px',
  justifyContent: 'flex-end',
  width: '100%'
});

const InputArea = styled.div({
  margin: '16px 0'
});

export enum Status {
  Add = 'add',
  Edit = 'edit'
}

type IProp = {
  flipView: () => void;
  status: Status;
  payoutInfo?: {
    paid_at: Date | string;
    amount_paid_cents: number;
    id: number;
  };
};

const ClaimPaymentCard: React.FC<IProp> = ({ flipView, status, payoutInfo }) => {
  const paid_at = payoutInfo ? new Date(payoutInfo?.paid_at) : new Date();
  const [date, setDate] = useState<Date>(paid_at);
  const [amount, setAmount] = useState<number>(payoutInfo?.amount_paid_cents ?? 0);
  const [errors, setErrors] = useState({});
  const { addToast } = useToast();
  const { claimsId } = useParams();
  const { addPayment, isLoading: saving } = useAddClaimPayment();
  const { editPayment, isLoading: editing } = useUpdateClaimPayment();
  const { deletePayment, isLoading: deleting } = useDeleteClaimPayment();
  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const queryClient = useQueryClient();

  const getError = (key: string) => {
    if (errors[key]) {
      return errors[key][0];
    }
  };

  const disableActions = () => {
    return saving || editing || deleting;
  };

  const isFormEmpty = () => {
    return !Boolean(amount) || !Boolean(date);
  };

  const save = async () => {
    const data = {
      claimId: Number(claimsId),
      formInfo: {
        paid_at: date,
        amount_paid_cents: amount
      }
    };

    addPayment(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries([CLAIM_PAYOUTS, Number(claimsId)]);
        flipView();
        addToast('Payment added');
      },
      onError: (err: AxiosError) => {
        const { errors } = err?.response?.data;
        setErrors(errors);

        if (errors.claim && !errors.amount_paid_cents) {
          addToast(`Claim ${errors.claim}`, TOAST_STATUS.ERROR);
        }
      }
    });
  };

  const edit = async () => {
    const data = {
      claimId: Number(claimsId),
      payoutId: payoutInfo?.id,
      formInfo: {
        paid_at: date,
        amount_paid_cents: amount
      }
    };

    editPayment(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries([CLAIM_PAYOUTS, Number(claimsId)]);
        flipView();
        addToast('Payment updated');
      },
      onError: (err: AxiosError) => {
        const { errors } = err?.response?.data;
        setErrors(errors);

        if (errors.claim) {
          addToast(`Claim ${errors.claim}`, TOAST_STATUS.ERROR);
        }
      }
    });
  };

  const remove = async () => {
    deletePayment(
      { claimId: claimsId, payoutId: payoutInfo?.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([CLAIM_PAYOUTS, Number(claimsId)]);
          flipView();
          addToast('Payout deleted');
        },
        onError: (err: AxiosError) => {
          addToast('An error occurred, please try again later', TOAST_STATUS.ERROR);
        }
      }
    );
  };

  return (
    <StyledCardDetails>
      {status == Status.Edit && (
        <WarningModal
          title="Are you sure you want to delete this payment?"
          subTitle="This action cannot be undone."
          isOpen={showModal}
          closeModal={closeModal}
          handleConfirm={remove}
          confirmButtonText="Yes, delete payment"
        />
      )}
      <CardHeader>{status === Status.Add ? 'Add' : 'Edit'} payments</CardHeader>
      <InputArea>
        <DateInput
          id="date-picker"
          selected={date}
          inputLabel="Select date of payment"
          isClearable={false}
          onChange={{
            onChange: (value, e) => setDate(value),
            onChangeRaw: (e) => null
          }}
          value={formatDate(date?.toLocaleDateString() ?? '')}
        />
        <CurrencyInputUSD
          id="recoveredAmount"
          value={amount}
          onChange={(value) => setAmount(value)}
          label="Enter amount paid"
          subtext={getError('amount_paid_cents')}
        />
      </InputArea>
      <ButtonRow>
        {status == Status.Edit && (
          <ButtonHabitat
            id="delete"
            variant="destructive"
            size="small"
            usage="secondary"
            onClick={openModal}
            disabled={disableActions()}
          >
            Delete
          </ButtonHabitat>
        )}

        <Row>
          <ButtonHabitat
            id="cancel"
            variant="interaction"
            size="small"
            usage="primary"
            onClick={flipView}
            disabled={disableActions()}
          >
            Cancel
          </ButtonHabitat>
          <ButtonHabitat
            id="save"
            variant="interaction"
            size="small"
            usage="primary"
            onClick={payoutInfo ? edit : save}
            disabled={disableActions() || isFormEmpty()}
          >
            {saving || editing ? 'Saving' : 'Save Changes'}
          </ButtonHabitat>
        </Row>
      </ButtonRow>
    </StyledCardDetails>
  );
};

export default ClaimPaymentCard;
