import React, { useState } from 'react';
import { isEmpty, isArray } from 'lodash';
import CloseIcon from './CloseIcon';
import { IOption } from './types';
import { Sidebar, Title, Section, Right } from './styles';
import Checkboxes from './Checkboxes';
import Radios from './Radios';
import Clear from './Clear';

interface IPublicOption {
  id: string | number;
  text: string;
}

interface IFilter {
  label: string;
  type: 'radio' | 'checkbox';
  // disabled the rule because `IPublicOption[] | string[]` notation causes TypeScript error on options.map call
  options: Array<IPublicOption | string>; // tslint:disable-line array-type
  name: string;
}

export interface IFilterValue {
  name: string;
  value: any;
}

interface IProps {
  filters: IFilter[];
  filterValues?: object;
  onChange(values: object): void;
  onClose(): void;
}

function normalizeOption(option: IPublicOption | string): IOption {
  if (typeof (option) === 'string') {
    let text = option.replace(/[_-]/g, ' ');
    text = text.charAt(0).toUpperCase() + text.slice(1)
    return { id: option as string, text };
  } else {
    return { ...option, id: option.id as string };
  }
}

const filterControls = (filter: IFilter, value: any, onChange: Function) => {
  const options = filter.options.map((option) => normalizeOption(option));

  switch (filter.type) {
    case 'radio':
      return <Radios
        name={filter.name}
        value={value}
        onChange={onChange}
        options={options}
      />;
    case 'checkbox':
      return <Checkboxes
        name={filter.name}
        value={value}
        onChange={onChange}
        options={options}
      />
  }
}

const Filter = (props: IProps) => {
  const { filters, onChange, onClose, filterValues } = props;

  const [values, setValues] = useState(filterValues || {});

  const onFilterChange = (name: string, value: any) => {
    const new_values = {...values, [name]: value};
    setValues(new_values);
    onChange(new_values);
  }

  const clear = () => {
    setValues({});
    onChange({});
  }

  const filtersAmount = () =>
    filters
      .map((filter) => values[filter.name])
      .filter((value) => isArray(value) ? !isEmpty(value) : !!value)
      .length

  return <Sidebar>
    <Section>
      <Title>
        Filters
        <Right>
          <Clear filtersAmount={filtersAmount()} onClick={clear}/>
          <a href="#" onClick={onClose}><CloseIcon /></a>
        </Right>
      </Title>
    </Section>
    {filters.map((filter) =>
      <Section key={filter.label}>
        <Title>{filter.label}</Title>
        {filterControls(filter, values[filter.name], onFilterChange)}
      </Section>
    )}
  </Sidebar>
};

export default Filter;
