import React, { useState } from 'react';
import { CoverageValue, DisplayField, DisplayFieldControls } from './Styled';
import { ActionButton, ButtonHabitat, TextInput } from '@sayrhino/rhino-shared-js';

interface IProps {
  chargeCodeValue: string;
  chargeCodeLabel: string;
  setChargeCode: (value: string) => void;
  updateChargeCode: () => void;
}

const ChargeCode = ({ chargeCodeValue, setChargeCode, updateChargeCode, chargeCodeLabel }: IProps) => {
  const [editChargeCode, setEditChargeCode] = useState(chargeCodeValue === '');

  const handleSubmit = () => {
    updateChargeCode();
    setEditChargeCode(false);
  };

  const chargeCodeId = chargeCodeLabel.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  );

  return (
    <>
      {editChargeCode ? (
        <DisplayField>
          <TextInput
            id={`${chargeCodeId}-input`}
            label={chargeCodeLabel}
            data-cy="chargeCode"
            name={chargeCodeId}
            value={chargeCodeValue}
            onChange={(event) => setChargeCode(event.target.value)}
          />
          <ButtonHabitat
            id={`${chargeCodeId}-submit`}
            type="submit"
            usage="primary"
            variant="interaction"
            disabled={false}
            onClick={handleSubmit}
            size="small"
          >
            Submit
          </ButtonHabitat>
        </DisplayField>
      ) : (
        <DisplayField>
          <CoverageValue id={chargeCodeId}>{chargeCodeLabel}</CoverageValue>
          <DisplayFieldControls>
            <ActionButton
              id="policyPush"
              variant="noLabel"
              usage="quiet"
              icon="edit"
              tooltip={`Edit ${chargeCodeLabel}`}
              onClick={() => setEditChargeCode(true)}
            />
            {chargeCodeValue}
          </DisplayFieldControls>
        </DisplayField>
      )}
    </>
  );
};

export default ChargeCode;
