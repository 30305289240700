import React from 'react';
import { ClaimDetailViewWrapper, ClaimDetailWrapper, LoadingWrapper, StyledReferrerLink } from './ClaimStyles';
import { MinimizeIcon, Loading } from '@sayrhino/rhino-shared-js';
import { useReferrer } from '../../utils';
import { useParams } from 'react-router-dom';
import useClaimPayments from 'api/v2/useClaimPayments';
import ClaimsPaymentTable from './ClaimsPayoutTable';
import { ToastContextProvider } from '../../toast/toast-context';

export const ClaimPayments: React.FC = () => {
  const { claimsId } = useParams();
  const { isLoading } = useClaimPayments(Number(claimsId)) ?? {};
  const rawReferrer = useReferrer();
  const referrer = rawReferrer || `/admin/claims/${claimsId}`;

  return !isLoading ? (
    <ToastContextProvider position="bottom-right">
      <ClaimDetailWrapper>
        <ClaimDetailViewWrapper>
          <StyledReferrerLink to={referrer} aria-label="collapse">
            <MinimizeIcon />
          </StyledReferrerLink>
          <h3>Edit Payments</h3>
        </ClaimDetailViewWrapper>
      </ClaimDetailWrapper>
      <ClaimsPaymentTable />
    </ToastContextProvider>
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};
