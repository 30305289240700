import axios, { AxiosResponse } from 'axios';
import { IData, IPaginatedMeta } from 'components/v2/App/interfaces';
import { stringify } from 'qs';
import { QueryObserverResult, useQuery, useMutation } from 'react-query';
import { csrfToken } from 'utils/document';

interface IPartnersite {
  formInfo?: object;
  siteId?: number;
  partner?: object;
  limit?: number;
}

export interface ISitesQueryParams {
  type?: string;
  page?: number;
  per?: number;
  partner_name?: string;
}

interface IPartner {
  id: number;
  name: string;
  type: 'realpage' | 'eqr';
}

export interface ISite {
  id: number;
  name: string;
  type: 'realpage' | 'eqr';
  enabled: boolean;
  external_id: string;
  attached_properties_count: number;
  partner: IPartner;
  partner_type?: string;
  label: string;
  siteId?: number;
}

interface ISiteResponse {
  site: ISite;
}

interface ISiteListData {
  data: ISiteListItem[];
  meta: Required<Omit<IPaginatedMeta, 'count'>>;
}

export interface ISiteListItem {
  id: number;
  name: string;
  type: 'realpage' | 'eqr';
  enabled: boolean;
  external_id: string;
  partner: IPartner;
  site_properties_count: number;
}

export const SITES_KEY = 'sites';

const authenticity_token = csrfToken();
const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': authenticity_token
};

export const getSites = async (params = {}): Promise<ISiteListData> => {
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data }: AxiosResponse<ISiteListData> = await axios.get<ISiteListData>(`/v2/integrations/sites.json`, {
    params,
    paramsSerializer
  });

  return data;
};

export function useSites(queryParams: ISitesQueryParams): QueryObserverResult<IData<ISiteListItem[], IPaginatedMeta>> {
  return useQuery([SITES_KEY, queryParams], () => searchPartnerSite(queryParams), {
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });
}

const addPartnerSite = async ({ formInfo }: IPartnersite) => {
  const { data } = await axios.post(
    `/v2/integrations/sites`,
    { site: formInfo },
    {
      headers: headers
    }
  );

  return data;
};

const updatePartnerSite = async ({ formInfo, siteId }: IPartnersite) => {
  const { data } = await axios.put(`/v2/integrations/sites/${siteId}`, formInfo, {
    headers: headers
  });

  return data;
};

const searchPartnerSite = async (queryParams: ISitesQueryParams) => {
  const { data } = await axios.post(`/v2/integrations/sites/search`, queryParams, {
    headers: headers
  });

  return data;
};

const fetchPartnerNames = async ({ limit }: IPartnersite) => {
  const { data } = await axios.get(`/v2/integrations/partners/search`, {
    headers: headers
  });

  return data;
};

export const linkPropertiesToSite = async (params) => {
  const { data } = await axios.post(`/v2/integrations/sites/${params.id}/link_properties`, params, {
    headers: headers
  });

  return data;
};

export const fetchPropertiesBySite = async (siteId?: number) => {
  const { data } = await axios.get(`/v2/integrations/sites/${siteId}/properties_by_site`, {
    headers: headers
  });

  return data;
};

export const useAddSite = () => {
  const { mutate: addSite, ...rest } = useMutation(addPartnerSite);
  return {
    addSite,
    ...rest
  };
};

export const useUpdateSite = () => {
  const { mutate: editSite, ...rest } = useMutation(updatePartnerSite);
  return {
    editSite,
    ...rest
  };
};

export const useSearchSite = () => {
  const { mutate: searchSite, ...rest } = useMutation(searchPartnerSite);
  return {
    searchSite,
    ...rest
  };
};

export const usePartnerNames = () => {
  const { mutate: partnerNames, ...rest } = useMutation(fetchPartnerNames);
  return {
    partnerNames,
    ...rest
  };
};
