/**
 *
 * @param bytes
 * @returns up to GB's in formatted bytes
 */
const bytesFormatter = (bytes) => {
  const units = ['bytes', 'KB', 'MB', 'GB'];
  let unit = 0;
  let fileSize = parseInt(bytes, 10) || 0;
  while (fileSize >= 1024 && ++unit) fileSize = fileSize / 1024;
  return fileSize.toFixed(fileSize < 10 && unit > 0 ? 1 : 0) + ' ' + units[unit];
};

export default bytesFormatter;
