import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FONTS, PALETTE, CloseIcon, Loading } from '@sayrhino/rhino-shared-js';
import useOverlay from '../utils/overlay';
import { ACCOUNT_ROUTES } from '../utils/routeHelpers';
import useTwoFactorCodes from 'api/v2/useTwoFactorCodes';

const PageWrapper = styled.div({ padding: '80px 48px' });
const Title = styled.h4([
  FONTS.h4,
  { marginBottom: '36px', paddingBottom: '28px', borderBottom: `1px solid ${PALETTE.neutral12}` }
]);
const SubTitle = styled.h5([FONTS.h5, { marginBottom: '8px' }]);
const CloseButton = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  width: 32,
  backgroundColor: PALETTE.neutral4,
  borderRadius: 75,
  float: 'right',
  marginTop: '14px',
  marginRight: '32px'
});

const TwoFactorCodes: React.FC = () => {
  const { data: tfaCodes, isFetchedAfterMount } = useTwoFactorCodes();

  function CodeList(props) {
    const listItems = props.tfaCodes.map((code: string) => <li key={tfaCodes?.indexOf(code)}>{code}</li>);
    return <ul>{listItems}</ul>;
  }
  const { setShowOverlay } = useOverlay();
  useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return (
    <>
      <CloseButton to={`/${ACCOUNT_ROUTES.edit}`} aria-label="return to edit My Profile" id="two_factor_close_link">
        <CloseIcon height={14} width={14} />
      </CloseButton>
      <PageWrapper>
        <Title>Two-Factor Authentication Backup Codes</Title>
        <SubTitle>
          Each of these recovery codes can be used one time each to regain access to your account. Please save them in a
          safe place, or you will lose access to your account:
          {isFetchedAfterMount ? <CodeList tfaCodes={tfaCodes} /> : <Loading />}
        </SubTitle>
      </PageWrapper>
    </>
  );
};

export default TwoFactorCodes;
