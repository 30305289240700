import React from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { CloseIcon, Loading } from '@sayrhino/rhino-shared-js';
import FormField from '../FormField';
import useOverlay from '../utils/overlay';
import { ACCOUNT_ROUTES } from '../utils/routeHelpers';
import usePropertyOwner from '../PropertyOwners/usePropertyOwner';
import {
  LoadingWrapper,
  PageHeader,
  Banner,
  CloseIconButton,
  FormHeaderTitle,
  FormTitle,
  FormSubtitle,
  NotificationWrapper,
  InlineNotification,
  UpdateButton
} from './Styled';

export const PropertyOwnerEditView: React.FC = () => {
  const { propertyOwnerId } = useParams();
  const { handleSubmit, formField, errorMessage, data } = usePropertyOwner(Number(propertyOwnerId));
  const { name, setName, nameError } = formField;

  return (
    <div>
      <PageHeader>
        <FormHeaderTitle>Edit property owner</FormHeaderTitle>
        <CloseIconButton
          to={generatePath(ACCOUNT_ROUTES.propertyOwnerView, { propertyOwnerId })}
          aria-label="view policy details"
        >
          <CloseIcon height={14} width={14} />
        </CloseIconButton>
      </PageHeader>
      {!!errorMessage?.length && (
        <NotificationWrapper>
          {errorMessage.map((message) => (
            <InlineNotification key={message} variant="highPriority" aria-label="form error message">
              {`${message}`.trim()}
            </InlineNotification>
          ))}
        </NotificationWrapper>
      )}
      <Banner>
        <FormTitle>{data?.name}</FormTitle>
        <FormSubtitle>Property owner</FormSubtitle>
        <form onSubmit={handleSubmit} method="POST">
          <FormField
            error={nameError}
            input={
              <input
                name="name"
                id="name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setName(e.target.value);
                }}
              />
            }
            label={<label htmlFor="name">Name</label>}
          />

          <UpdateButton variant="primary" type="submit">
            Update
          </UpdateButton>
        </form>
      </Banner>
    </div>
  );
};

const PropertyOwnerEdit = () => {
  const { propertyOwnerId } = useParams();
  const { isSuccess } = usePropertyOwner(Number(propertyOwnerId));
  const { setShowOverlay } = useOverlay();

  React.useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return isSuccess ? (
    <PropertyOwnerEditView />
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default PropertyOwnerEdit;
