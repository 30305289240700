import { IDataTablesApi } from './application_table';

class PropertyDataSortTable {
  public $table: JQuery;
  public dataTable: IDataTablesApi;
  public selector = '.property-data-table-sort';
  public order = [[2, 'asc']];

  public settings() {
    return {
      dom: '<"search"fl><"top">rt<"bottom"ip><"clear">',
      retrieve: true,
      searching: true,
      pageLength: 100,
      conditionalPaging: true,
      pagingType: 'full_numbers',
      order: this.order
    };
  }

  public render(): void {
    this.$table = $(this.selector);

    if (!this.$table.length) { return; }

    this.dataTable = this.$table.DataTable(this.settings()) as IDataTablesApi;
  }
}

export default PropertyDataSortTable;
