import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import Select from 'components/v2/select/Select';

const Label = styled.label([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral65
  }
]);

const SelectWrapper = styled.div({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '4px',
  padding: '0 4px'
});

const Error = styled.span([PALETTE.alert100, FONTS.labelLight, {}]);

type Option = { value: string; label: string };

export interface IProps {
  handleChange?: (selectedValues: Option) => void;
  label?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
}

const sFieldsOptions = [
  'sFields0',
  'sFields1',
  'sFields2',
  'sFields3',
  'sFields4',
  'sFields5',
  'sFields6',
  'sFields7',
  'sFields8',
  'sFields9',
  'sFields10',
  'sFields11',
  'sFields12',
  'sFields13'
];

const SFields: React.FC<IProps> = ({ handleChange, label, value, error, disabled }) => {
  return (
    <>
      <Label htmlFor={label}>{label}</Label>
      <SelectWrapper id={label}>
        <Select
          id="sFields"
          placeholder="sFields"
          options={sFieldsOptions.map((item) => ({ label: item, value: item }))}
          value={{ label: value, value }}
          onChange={handleChange}
          isDisabled={disabled}
          components={{
            DropdownIndicator: () => null
          }}
        />
      </SelectWrapper>
      <Error>{error}</Error>
    </>
  );
};

export default SFields;
