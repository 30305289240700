import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, Button, Card } from '@sayrhino/rhino-shared-js';
import { validateRequiredField } from 'components/v2/App/utils/formValidations';
import TeamsMultiSelect, { Member } from './TeamSelect';
import { Property } from '../AddNewTeamMember/NewTeamMember';
import PropertiesMultiSelect from '../AddNewTeamMember/PropertiesMultiSelect';

const cardStyle = {
  height: 'auto',
  border: `1px solid ${PALETTE.neutral12}`,
  padding: '40px 48px',
  cursor: 'auto',
  margin: '32px auto',
  width: '600px',
  '&:hover': {
    boxShadow: 'none'
  }
};

export const UnderlinedButton = styled(Button)({
  border: 'none',
  textDecoration: 'underline',
  '&:hover': {
    background: 'none',
    color: PALETTE.neutralDark
  }
});

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const Form = styled.div({
  margin: '0px 0px 20px'
});

const FormGroup = styled.div({ margin: '20px 0' });

const Title = styled.h4([FONTS.h4, { color: PALETTE.brand100, margin: '2px 0' }]);

export enum MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export type mode = MODE.CREATE | MODE.EDIT;

interface IProps {
  mode: mode;
  handleAdd?: (assigned: MemberProperty) => void;
  handleDelete?: (idx: number) => void;
  handleUpdate?: (assigned: MemberProperty, idx: number) => void;
  initialValue?: MemberProperty;
  idx?: number;
}

export interface MemberProperty {
  users: Member[];
  properties: Property[];
}

const initial_value: MemberProperty = {
  users: [],
  properties: []
};

export default function AssignTeamForm({
  mode,
  idx,
  initialValue = initial_value,
  handleAdd,
  handleDelete,
  handleUpdate
}: IProps) {
  const [assigned, setAssigned] = useState<MemberProperty>(initialValue);
  const [teamInputError, setTeamInputError] = useState<string>();
  const [propertiesInputError, setPropertiesInputError] = useState<string>();

  const resetErrors = () => {
    setTeamInputError(undefined);
    setPropertiesInputError(undefined);
  };
  const handleTeamChange = (members: Member[]) => setAssigned((prev) => ({ ...prev, users: members }));
  const handlePropertiesChange = (properties: Property[]) =>
    setAssigned((prev) => ({ ...prev, properties: properties }));

  const validateForm = () => {
    let isValid = true;

    const teamValidation = validateRequiredField(assigned.users, 'Team Members');
    if (teamValidation) {
      setTeamInputError(teamValidation);
      isValid = false;
    }
    const propertiesValidation = validateRequiredField(assigned.properties, 'Properties');
    if (propertiesValidation) {
      setPropertiesInputError(propertiesValidation);
      isValid = false;
    }

    return isValid;
  };

  const onAdd = () => {
    resetErrors();
    if (validateForm() && handleAdd) {
      handleAdd(assigned);
      setAssigned(initial_value);
    }
  };

  const onUpdate = () => {
    resetErrors();
    if (validateForm() && handleUpdate && idx !== undefined) {
      handleUpdate(assigned, idx);
      setAssigned(initial_value);
    }
  };

  const onDelete = () => {
    resetErrors();
    if (handleDelete && idx !== undefined) {
      handleDelete(idx);
    }
  };

  const renderAddButton = () => {
    return (
      <Container>
        <Button onClick={onAdd} children="Add" variant="tertiary" />
      </Container>
    );
  };

  const renderUpdateDeleteButton = () => {
    return (
      <Container>
        <Button onClick={onUpdate} children="Update" variant="tertiary" />
        <UnderlinedButton onClick={onDelete} children="Delete" variant="tertiary" />
      </Container>
    );
  };

  const renderFormInput = () => {
    return (
      <Form>
        <FormGroup>
          <TeamsMultiSelect error={teamInputError} team={assigned.users} handleChange={handleTeamChange} />
        </FormGroup>
        <FormGroup>
          <PropertiesMultiSelect
            error={propertiesInputError}
            properties={assigned.properties}
            handleChange={handlePropertiesChange}
          />
        </FormGroup>
      </Form>
    );
  };

  return (
    <Card variant="border" style={cardStyle}>
      <Title>Assign team members</Title>
      {renderFormInput()}
      {mode === MODE.CREATE && renderAddButton()}
      {mode === MODE.EDIT && renderUpdateDeleteButton()}
    </Card>
  );
}
