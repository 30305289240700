import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

export const customStyles = {
  container: (styles) => ({ ...styles, width: '100%' }),
  control: (styles) => ({
    ...styles,
    border: 'none',
    boxShadow: 'none',
    borderRadius: '0px',
    ':hover': { borderColor: 'transparent', boxShadow: 'none' }
  }),
  option: (styles) => ({
    ...styles,
    display: 'flex',
    backgroundColor: 'transparent',
    color: PALETTE.neutral65,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':active': {
      backgroundColor: 'transparent'
    },
    ':hover': {
      cursor: 'pointer'
    }
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '6px 0 6px 6px',
    color: PALETTE.neutral88,
    fontFamily: FONTS.extendedMedium,
    fontSize: '16px',
    lineHeight: '28px'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  multiValue: (styles) => ({
    ...styles,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    position: 'relative',
    width: 'auto',
    height: '32px',
    background: PALETTE.neutral4,
    borderRadius: '4px'
  }),

  multiValueLabel: (styles) => ({
    ...styles,
    color: PALETTE.neutralDark
  }),

  multiValueRemove: (styles) => ({
    ...styles,
    color: PALETTE.neutral4,
    borderRadius: '100px',
    height: '18px',
    width: '18px',
    display: 'flex',
    flex: 'none',
    margin: '0px 6px',
    justifyContent: 'center',
    backgroundColor: PALETTE.neutral65,
    position: 'static',
    ':hover': {
      backgroundColor: 'none',
      color: PALETTE.interaction25,
      cursor: 'pointer'
    }
  })
};

export const getSelectStyles = ({ isError }: { isError?: string }) => ({
  control: (providedStyles) => ({
    ...providedStyles,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    outline: 'none',
    boxShadow: `inset 0 0 0 1px ${isError ? PALETTE.alert125 : PALETTE.neutral25}`,
    borderRadius: '4px',
    marginTop: '4px',
    marginBottom: '4px',
    color: isError ? PALETTE.alert125 : PALETTE.neutral88,
    backgroundColor: isError ? PALETTE.alert4 : 'transparent',
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    lineHeight: '28px',
    ':hover': {
      boxShadow: `inset 0 0 0 1px ${isError ? PALETTE.alert125 : PALETTE.neutral88}`,
      color: PALETTE.neutral88
    },
    ':focus-within': {
      boxShadow: `inset 0 0 0 1px ${isError ? PALETTE.alert125 : PALETTE.interaction100}, 0 0 0 1px ${
        isError ? PALETTE.alert125 : PALETTE.interaction100
      }`
    },
    ':has(:disabled)': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: PALETTE.neutral12,
      zIndex: 1,

      '&:hover': {
        boxShadow: 'none'
      }
    }
  }),
  input: (providedStyles) => ({
    ...providedStyles,
    paddingTop: '3px',
    paddingBottom: '3px',
    margin: 0
  }),
  option: (providedStyles, state) => ({
    ...providedStyles,
    fontFamily: 'MaisonNeueLight',
    fontSize: '14px',
    lineHeight: '24px',
    backgroundColor: state.isFocused ? PALETTE.neutral4 : 'transparent',
    color: PALETTE.neutral88
  }),
  indicatorSeparator: (providedStyles) => ({
    ...providedStyles,
    display: 'none'
  }),
  singleValue: (providedStyles) => ({
    ...providedStyles,
    color: PALETTE.neutral88
  })
});
