import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IClaimAttachmentQueryParams, IClaimAttachmentFilterOptions } from '../../components/v2/App/interfaces';
import { stringify } from 'qs';

export const CLAIM_ATTACHMENTS_KEY = 'claim attachments';
export const getClaimAttachments = async (claimsId, params = {}): Promise<IClaimAttachmentFilterOptions> => {
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data }: AxiosResponse<IClaimAttachmentFilterOptions> = await axios.get<IClaimAttachmentFilterOptions>(
    `/v2/admin/claims/${claimsId}/attachments.json`,
    { params, paramsSerializer }
  );

  return data;
};

export const claimPropertyFilterOptionsKey = 'claimPropertyFilterOptions';

export default function useClaimAttachments(
  claimsId: number,
  queryParams: IClaimAttachmentQueryParams
): QueryObserverResult<IClaimAttachmentFilterOptions> | undefined {
  if (claimsId) {
    return useQuery([CLAIM_ATTACHMENTS_KEY, queryParams], () => getClaimAttachments(claimsId, queryParams), {
      keepPreviousData: true
    });
  }
}
