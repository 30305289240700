export const validColumns = {
  property_name: { width: 236, colName: 'Property name' },
  address: { width: 456, colName: 'Address' },
  type: { width: 160, colName: 'Type' },
  unit: { width: 112, colName: 'Unit' },
  property_owner: { width: 192, colName: 'Property owner' },
  legal_property_entity: { width: 192, colName: 'Legal property entity' },
  property_default: { width: 168, colName: 'Property coverage' },
  unit_default: { width: 168, colName: 'Unit coverage' },
  salesforce_opportunity_id: { width: 168, colName: 'Salesforce opportunity id' },
  student_housing: { width: 168, colName: 'Student housing' },
  integration_type: { width: 168, colName: 'Integration type' },
  integration_code: { width: 168, colName: 'Integration code' },
  property_integration_code: { width: 168, colName: 'Property integration code' },
  unit_integration_code: { width: 168, colName: 'Unit integration code' }
};
