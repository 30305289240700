import React from 'react';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import App from '../index';
import makeServer from '../testUtils/server';
import { Route, Routes } from 'react-router-dom';
import Wrapper, { ReactQueryWrapper } from '../testUtils/Wrapper';
import PolicyDetails from '../PolicyDetails';

let server;

describe('<App /> level policy details interactions', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the dashboard', async () => {
    render(
      <ReactQueryWrapper>
        <App />
      </ReactQueryWrapper>
    );
    const heading = await screen.findByRole('heading', { level: 6, name: /Action items/i });
    expect(heading).toBeDefined();
  });

  describe('inactive policy', () => {
    test('shows the download documents button', async () => {
      render(
        <Wrapper initialEntries={['/admin/renter_policies/17433']}>
          <Routes>
            <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          </Routes>
        </Wrapper>
      );

      const policyDetails = await screen.findByRole('heading', { level: 4, name: /Policy Coverage/ });
      expect(policyDetails).toBeDefined();

      const downloadDocumentButton = screen.queryByRole('button', { name: /Policy Document/i });
      expect(downloadDocumentButton).toBeInTheDocument();
    });
  });

  describe('inactive policy', () => {
    test('does not show the download documents button', async () => {
      render(
        <Wrapper initialEntries={['/admin/renter_policies/17888']}>
          <Routes>
            <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          </Routes>
        </Wrapper>
      );

      const policyDetails = await screen.findByRole('heading', { level: 4, name: /Policy Coverage/ });
      expect(policyDetails).toBeDefined();

      const downloadDocumentButton = screen.queryByRole('button', { name: /Policy Document/i });
      expect(downloadDocumentButton).not.toBeInTheDocument();
    });
  });

  describe('rhino plan', () => {
    test('It shows upfront payment', async () => {
      render(
        <Wrapper initialEntries={['/admin/renter_policies/25921']}>
          <Routes>
            <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          </Routes>
        </Wrapper>
      );

      const rhinoPlan = await screen.findByRole('textbox', { name: /policy price/i });
      expect(rhinoPlan).toHaveValue('$230.00 / in full');
    });

    test('It shows monthly payment', async () => {
      render(
        <Wrapper initialEntries={['/admin/renter_policies/25920']}>
          <Routes>
            <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          </Routes>
        </Wrapper>
      );

      const rhinoPlan = await screen.findByRole('textbox', { name: /policy price/i });
      expect(rhinoPlan).toHaveValue('$23.00 / month');
    });
  });

  test('It displays relevant status and changeset banners', async () => {
    render(
      <Wrapper initialEntries={['/admin/renter_policies/25920']}>
        <Routes>
          <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
        </Routes>
      </Wrapper>
    );
    const statusBanner = await screen.findByText('Review and update information by Oct 7, 2020');
    expect(statusBanner).toBeDefined();

    const changeset = await screen.findByText('Monthly rent: $5,000.00');
    expect(changeset).toBeDefined();
  });

  describe('pending policy cancellation', () => {
    test('shows the policy cancellation banner', async () => {
      render(
        <Wrapper initialEntries={['/admin/renter_policies/17433']}>
          <Routes>
            <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          </Routes>
        </Wrapper>
      );

      const policyCancellation = await screen.findByRole('heading', { level: 3, name: /Policy cancellation request/ });
      expect(policyCancellation).toBeDefined();

      const approveCancellationButton = screen.queryByRole('button', { name: /Approve/i });
      expect(approveCancellationButton).toBeInTheDocument();

      const denyCancellationButton = screen.queryByRole('button', { name: /Deny/i });
      expect(denyCancellationButton).toBeInTheDocument();
    });

    test.skip('the policy cancellation error shows on error', async () => {
      render(
        <Wrapper initialEntries={['/admin/renter_policies/17434']}>
          <Routes>
            <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          </Routes>
        </Wrapper>
      );

      const policyCancellation = await screen.findByRole('heading', { level: 3, name: /Policy cancellation request/ });
      expect(policyCancellation).toBeDefined();

      const approveCancellationButton = await screen.getByRole('button', { name: /Approve/i });
      // click confirm - return error
      userEvent.click(approveCancellationButton);

      const policyCancellationError = await screen.getByText(/Error — Server cannot be reached/);
      expect(policyCancellationError).toBeDefined();
    });

    test('the policy cancellation confirmation succeeds', async () => {
      render(
        <Wrapper initialEntries={['/admin/renter_policies/17433']}>
          <Routes>
            <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          </Routes>
        </Wrapper>
      );

      const policyCancellation = await screen.findByRole('heading', { level: 3, name: /Policy cancellation request/ });
      expect(policyCancellation).toBeDefined();
    });

    test('the policy cancellation confirmation is declined', async () => {
      render(
        <Wrapper initialEntries={['/admin/renter_policies/17433']}>
          <Routes>
            <Route path="/admin/renter_policies/:policyId" element={<PolicyDetails />} />
          </Routes>
        </Wrapper>
      );

      const policyCancellation = await screen.findByRole('heading', { level: 3, name: /Policy cancellation request/ });
      expect(policyCancellation).toBeDefined();
    });
  });
});
