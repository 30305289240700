/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { BackdropProps } from '@material-ui/core';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  coverageAmount: string;
  coverageDates: string;
  coverageMonths: string;
  monthlyRent: string;
  requiredCoverage: number;
  cashDepositAmount: string;
  status: 'In Progress' | 'Sent' | 'Subscribed';
  cashDepositOnly: boolean;
}

const borderStyles = css({
  borderLeft: `1px solid ${PALETTE.neutral25}`,
  marginLeft: 12,
  paddingLeft: 12
});

const borderPurpleCSS = css({
  borderLeft: `1px solid ${PALETTE.neutral25}`,
  marginLeft: 12,
  paddingLeft: 12,
  color: `${PALETTE.brand100}`
});

const colorGray3 = css({ color: PALETTE.neutral25 });

export const InvitationCoverageType = (props: IProps) => {
  const {
    cashDepositAmount,
    cashDepositOnly,
    coverageAmount,
    coverageDates,
    coverageMonths,
    monthlyRent,
    requiredCoverage,
    status
  } = props;

  let coverageType;
  if (status === 'Subscribed' && coverageDates.length > 1) {
    coverageType = <span css={[FONTS.p2, borderStyles, colorGray3]}>{coverageDates}</span>;
  } else if (requiredCoverage !== 0 && coverageAmount !== '$0') {
    coverageType = <span css={[FONTS.p2, borderPurpleCSS, { marginLeft: '36px !important' }]}>{coverageAmount}</span>;
  } else if (monthlyRent === '$0') {
    coverageType = <span css={[FONTS.p2, borderPurpleCSS]}>{coverageMonths} Monthly Rent</span>;
  } else {
    coverageType = (
      <span>
        <span css={[FONTS.p2, { color: `${PALETTE.brand100}`, marginLeft: '36px !important' }, borderPurpleCSS]}>
          {monthlyRent}
        </span>
        <span css={[colorGray3, { fontFamily: 'monospace', margin: '0 12px' }]}>∙</span>
        <span css={[FONTS.p2, colorGray3]}>{coverageMonths} Monthly Rent</span>
      </span>
    );
  }
  return (
    <div>
      <div>
        {!cashDepositOnly && (
          <p>
            <span css={FONTS.label}>Coverage</span>
            {coverageType}
          </p>
        )}
        {cashDepositAmount && cashDepositAmount !== '$0' && (
          <p>
            <span css={[FONTS.label, { padding: '0px 7.5px 0px 0px' }]}>Cash Deposit</span>

            {status === 'Subscribed' && coverageDates.length > 1 ? (
              <span css={[FONTS.p2, borderStyles, colorGray3]}>{coverageDates}</span>
            ) : (
              <span css={[FONTS.p2, { color: `${PALETTE.brand100}` }, borderPurpleCSS]}>{cashDepositAmount}</span>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default InvitationCoverageType;
