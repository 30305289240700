import React from 'react';
import DashboardPanelFigure from './DashboardPanelFigure';
import DashboardPanelLink from './DashboardPanelLink';
import DashboardPanelLinkFigure from './DashboardPanelLinkFigure';
import DashboardPanelPillLink from './DashboardPanelPillLink';
import DashboardPanelProgressFigure from './DashboardPanelProgressFigure';
import { IPropertyManagerSummaryMetricsData } from './interfaces';
import {
  IN_PROGRESS_INVITATIONS_TITLE,
  SIGN_UP_RATE_DESCRIPTION,
  SIGN_UP_RATE_LINK_TEXT,
  SIGN_UP_RATE_TITLE,
  TOTAL_INVITATIONS_TITLE
} from './utils/constants';
import useUserRole from './utils/userRole';

/**
 * Summary metric item components for
 *
 * - Property managers
 * - Property manager admins
 * - Listing agents
 */
const PropertyManagerDashboardPanelItems = ({ data }: { data: IPropertyManagerSummaryMetricsData }) => {
  const { isPropertyManager, isPropertyManagerAdmin } = useUserRole();

  const hasUnits = !!(data.total_units_count && data.total_units_count > 0);

  const renderCoveredUnits = () => {
    if (isPropertyManager || isPropertyManagerAdmin) {
      return hasUnits ? (
        <DashboardPanelProgressFigure
          title="Covered units"
          numerator={data.covered_units_count || 0}
          denominator={data.total_units_count}
        />
      ) : (
        <DashboardPanelPillLink to="/admin/properties/add/new" title="Add your first property" useServer={true} />
      );
    }
  };

  const renderSignupRate = () => {
    const { invitations_completed_count, total_invitations_count } = data;

    if (total_invitations_count == undefined || invitations_completed_count == undefined) return null;

    const signupRate = (invitations_completed_count / total_invitations_count) * 100;

    return isNaN(signupRate) ? (
      <DashboardPanelLink
        description={SIGN_UP_RATE_DESCRIPTION}
        linkText={SIGN_UP_RATE_LINK_TEXT}
        title={SIGN_UP_RATE_TITLE}
        to="/admin/invitations/new"
        useServer={false}
      />
    ) : (
      <DashboardPanelFigure
        title={SIGN_UP_RATE_TITLE}
        description={SIGN_UP_RATE_DESCRIPTION}
        value={`${signupRate.toFixed(1)}%`}
        updatedDate={new Date()}
      />
    );
  };

  return (
    <>
      {renderCoveredUnits()}
      {!!(data.units_up_for_renewal_count && data.units_up_for_renewal_count > 0) && (
        <DashboardPanelLinkFigure
          value={data.units_up_for_renewal_count}
          valueLabel="Up for renewal"
          description="Stay ahead of the lapses, review units within the renewal timeframe (90 to 0 days from end of policy)."
          to="/admin/renter_policies?status=up_for_renewal"
        />
      )}
      {!!(data.renewed_policies_count && data.renewed_policies_count > 0) && (
        <DashboardPanelPillLink
          to="/admin/renter_policies?status=upcoming"
          title="Renewed policies"
          value={data.renewed_policies_count}
        />
      )}
      {renderSignupRate()}
      <DashboardPanelPillLink
        to="/admin/invitations"
        title={TOTAL_INVITATIONS_TITLE}
        value={data.total_invitations_count || 0}
      />
      <DashboardPanelPillLink
        to="/admin/invitations?status=started"
        title={IN_PROGRESS_INVITATIONS_TITLE}
        value={data.in_progress_invitations_count || 0}
      />
      {data.claims_in_review_count !== undefined && (
        <DashboardPanelPillLink
          to="/admin/claims?status=new&table=true"
          title="Claims in review"
          value={data.claims_in_review_count || 0}
          useServer={true}
        />
      )}
    </>
  );
};

export default PropertyManagerDashboardPanelItems;
