import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import Sidebar from './sidebar';
import { add, startOfMonth } from 'date-fns';
import Decimal from 'decimal.js';
import {
  FONTS,
  PALETTE,
  CloseButton as CB,
  TextInput,
  PhoneInput,
  DateInput as DBase,
  Radio,
  Button
} from '@sayrhino/rhino-shared-js';
import { useLocation } from 'react-router-dom';
import { MultiplierSelect, PropertySelect, UnitSelect } from '../singleProperty/select';
import { Response } from '../singleProperty/queryHooks';
import { Coverage } from '../singleProperty/types';
import { isValidEmail, isValidPhone } from '../../utils/formValidations';
import { useMutation, useQueryClient } from 'react-query';
import { createInvitation, fetchUnits, getCoverage } from '../singleProperty/queryHooks';
import { AxiosError } from 'axios';
import ErrorNotification from '../singleProperty/ErrorNotification';
import { ICoverageOptionsRaw } from '../../interfaces';
import { centsToUSDFormatter, sanitizeCurrencyInput } from '../../utils';

export interface IInvitation {
  first_name?: string;
  last_name?: string;
  phone?: number;
  email?: string;
  monthly_rent?: number | string;
  property_id?: {
    id: number;
    building_name: string;
    address_line_one: string;
    address_state: string;
    address_zip: string;
    address_city: string;
  };
  unit_id?: { id: number; address_line_two: string };
  lease_start_date?: Date;
  lease_end_date?: Date;
  coverage_amount?: number;
  coverage_months?: number;
  rental_status?: number;
  coverage?: {
    label?: string;
    type?: Coverage | string;
    value?: string | number;
  } | null;
}

type IRequest = {
  first_name?: string;
  last_name?: string;
  phone?: number;
  email?: string;
  monthly_rent?: number | string;
  property_id?: number;
  unit_id?: number;
  lease_start_date?: Date;
  lease_end_date?: Date;
  coverage_amount?: number;
  coverage_months?: number;
  rental_status?: number;
  coverage?: {
    label?: string;
    type?: Coverage | string;
    value?: string | number;
  } | null;
};

export interface IErrors {
  email?: [string];
  phone?: [string];
  coverage_amount_cents?: [string];
  lease_start_date?: [string];
  lease_end_date?: [string];
  monthly_rent_cents?: [string];
  coverage_months?: [string];
  property?: [string];
  unit?: [string];
  first_name?: [string];
  last_name?: [string];
  unit_existing_policy_id?: [number];
  property_existing_policy_id?: [number];
  existing_invitation_id?: [number];
}

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 0px 0px 100px 50px;
  display: flex;
  flex-direction: row;

  @media (min-width: 1200px) {
    margin-left: 150px;
  }
`;

const Main = styled.div({
  width: 'inherit',
  minHeight: '100%',
  paddingLeft: '384px',
  position: 'relative'
});

const Header = styled.header({
  padding: '16px 48px',
  borderBottom: `1px solid ${PALETTE.neutral4}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  background: '#ffffff'
});

const HeaderText = styled.p([
  FONTS.p1Extended,
  {
    fontSize: '16px',
    lineHeight: '28px',
    color: PALETTE.neutral55
  }
]);

const Content = styled.form({
  padding: '40px 96px',
  maxWidth: '768px'
});

const Title = styled.h6([
  FONTS.p1Extended,
  {
    fontSize: '24px',
    lineHeight: '36px'
  }
]);

const Subtitle = styled.p([
  FONTS.p3Light,
  {
    fontSize: '16px',
    lineHeight: '28px',
    margin: '0 0 30px'
  }
]);

const Section = styled.div({
  margin: '30px 0'
});

const CloseButton = styled(CB)({
  position: 'fixed',
  right: '50px',
  top: '16px',
  zIndex: 2000
});

const SectionHeader = styled.h6([
  FONTS.p1Extended,
  {
    fontSize: '16px',
    lineHeight: '28px',
    borderBottom: `1px solid ${PALETTE.neutralDark}`,
    paddingBottom: '16px',
    marginBottom: '24px'
  }
]);

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center'
});

const DateInput = styled(DBase)({
  flex: 1
});

const DefaultDiv = styled.div({
  marginLeft: '50px',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  padding: '20px 0'
});

const DefaultPrice = styled.p([
  FONTS.p1Medium,
  {
    fontSize: '16px',
    lineHeight: '28px'
  }
]);

const Link = styled.a([
  FONTS.p1Medium,
  {
    fontSize: '14px',
    lineHeight: '24px',
    textDecoration: 'underline'
  }
]);

const DefaultText = styled.p([
  FONTS.p1Light,
  {
    fontSize: '14px',
    lineHeight: '24px'
  }
]);

const Wrap = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const ButtonCont = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end'
});

const SubmitButton = styled(Button)({
  alignSelf: 'flex-end'
});

const RadioRoot: any = Radio.Root;

const InvitePage: React.FC = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(createInvitation);
  const location = useLocation();
  const property = location.state as Response;
  if (!property) {
    window.location.href = '/admin/invitations/new';
  }
  const [defaultCoverage, setDefaultCoverage] = useState<ICoverageOptionsRaw>();
  const today = new Date();
  const sixMonthsFromToday = add(today, { months: 7 });
  const [phone, setPhone] = useState<number>();
  const [endDate, setEndDate] = useState(sixMonthsFromToday);
  const [startDate, setStartDate] = useState(today);
  const requiredFields = ['first_name', 'last_name', 'email', 'property_id', 'monthly_rent'];
  const [errors, setErrors] = useState<any>();
  const [backendError, setBackendError] = useState<string[]>();
  const [invite, setInvite] = useState<IInvitation>(() => {
    return {
      coverage: { type: Coverage.DEFAULT },
      property_id: {
        ...property
      }
    } as IInvitation;
  });

  const fetchCoverage = async () => {
    try {
      const data = await queryClient.fetchQuery(['coverageSearch'], () => getCoverage(invite.property_id?.id));
      setDefaultCoverage(data);
    } catch (error) {
      setDefaultCoverage(undefined);
    }
  };

  const getUnits = async () => {
    try {
      const data = await queryClient.fetchQuery(['unitSearch'], () => fetchUnits(property?.id));
      if (data.length === 1) {
        setInvite((prev) => ({ ...prev, unit_id: data[0] }));
      }
    } catch (error) {
      setDefaultCoverage(undefined);
    }
  };

  useEffect(() => {
    getUnits();
  }, []);

  useEffect(() => {
    fetchCoverage();
    if (invite?.property_id?.id != property?.id) {
      setInvite((prev) => ({ ...prev, unit_id: undefined }));
    } else {
      getUnits();
    }
  }, [invite.property_id]);

  const monthDiff = (d1: Date, d2: Date) => {
    let diff = (d2.getTime() - d1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff)) - 1;
  };

  const validateLeaseStartDateChange = (err: any) => {
    if (startDate < startOfMonth(new Date())) {
      err['lease_start_date'] = 'The lease start date is earlier than the first of the current month.';
    }
  };

  const validateLeaseEndDateChange = (err: any) => {
    const start = new Date(startDate.toISOString());
    const end = new Date(endDate.toISOString());
    const diffMonths = monthDiff(start, end);
    if (end < start) {
      err['lease_end_date'] = 'must be on or after the lease start date.';
    } else if (diffMonths > 28) {
      err['lease_end_date'] = 'cannot be more than 28 months later than the start date.';
    }
  };

  const validateForm = () => {
    let err: any = {};
    requiredFields.forEach((x: string) => {
      if (!Boolean(invite[x])) {
        err[x] = 'This field is required';
      }
    });

    if (!isValidEmail(invite?.email)) {
      err = { ...err, email: 'Invalid email address' };
    }
    if (!isValidPhone(invite?.phone)) {
      err['phone'] = 'Invalid phone number';
    }
    validateLeaseStartDateChange(err);
    validateLeaseEndDateChange(err);
    return err;
  };

  const formatData = (): IRequest => {
    return {
      ...invite,
      unit_id: invite.unit_id?.id,
      coverage:
        invite?.coverage?.type === Coverage.DEFAULT ? defaultCoverage?.coverage_options.default : invite.coverage,
      property_id: invite.property_id?.id,
      phone,
      lease_start_date: startDate ?? today,
      lease_end_date: endDate ?? sixMonthsFromToday
    };
  };

  const onSubmit = () => {
    let err = validateForm();
    const hasErrors: boolean = Object.keys(err).length > 0;

    if (hasErrors) {
      setErrors(err);
      return;
    }

    const data = formatData();
    setErrors(undefined);
    mutate(data, {
      onSuccess: (res) => {
        window.location.href = '/admin/invitations/' + res.data;
      },
      onError: (error: AxiosError<IErrors>) => {
        if (error?.response?.data) {
          let err: string[] = [];
          Object.entries(error?.response?.data).map(([key, value], index) => {
            err.push(value[0]);
          });
          setBackendError(err);
        }

        setTimeout(() => setBackendError(undefined), 5000);
      }
    });
  };

  return (
    <Page>
      <Sidebar />
      <CloseButton onClick={() => (window.location.href = '/admin/properties?table=true')} />
      <Main>
        <Header>
          <HeaderText>Send an invitation</HeaderText>
        </Header>
        <Content>
          <Title>New Invitation</Title>
          <Subtitle>Please enter the renter’s information below to send them a Rhino invitation.</Subtitle>
          <Section>
            <SectionHeader>Renter</SectionHeader>
            <TextInput
              label="First Name"
              id="firstName"
              data-cy="firstName"
              value={invite?.first_name}
              onChange={(e) => setInvite((prev) => ({ ...prev, first_name: e.target.value }))}
              error={Boolean(errors?.first_name)}
              subtext={errors?.first_name}
            />
            <TextInput
              label="Last Name"
              id="lastName"
              data-cy="lastName"
              value={invite?.last_name}
              onChange={(e) => setInvite((prev) => ({ ...prev, last_name: e.target.value }))}
              error={Boolean(errors?.last_name)}
              subtext={errors?.last_name}
            />
            <TextInput
              label="Email"
              id="email"
              data-cy="email"
              value={invite?.email}
              onChange={(e) => setInvite((prev) => ({ ...prev, email: e.target.value }))}
              error={Boolean(errors?.email)}
              subtext={errors?.email}
            />
            <PhoneInput
              id="phone"
              label="Phone number"
              data-cy="phone"
              value={invite?.phone}
              onChange={(value) => setInvite((prev) => ({ ...prev, phone: value }))}
              getRawValue={(val) => setPhone(val)}
              error={Boolean(errors?.phone)}
              subtext={errors?.phone}
            />
          </Section>
          <Section>
            <SectionHeader>Property</SectionHeader>
            <PropertySelect
              label="Lease address"
              data-cy="leaseAddress"
              value={invite?.property_id}
              onChange={(val) => setInvite((prev) => ({ ...prev, property_id: val }))}
              error={errors?.property_id}
            />
            <UnitSelect
              label="Apt / Suite / Floor"
              data-cy="leaseUnit"
              id={invite?.property_id?.id}
              value={invite?.unit_id}
              onChange={(val) => setInvite((prev) => ({ ...prev, unit_id: val }))}
              error={errors?.unit_id}
            />
            <Row>
              <DateInput
                id="startDate"
                inputLabel="Lease start date"
                selected={startDate}
                onChange={{ onChange: (newDate: Date) => setStartDate(newDate), onChangeRaw: (e) => console.log(e) }}
                value={invite?.lease_start_date?.toDateString() || new Date().toDateString()}
                style={{ width: '100%', marginRight: '10px' }}
                data-cy="leaseStateDate"
              />
              <DateInput
                id="endDate"
                inputLabel="Lease end date"
                selected={endDate}
                onChange={{ onChange: (newDate: Date) => setEndDate(newDate), onChangeRaw: (e) => console.log(e) }}
                onChangeRaw={(e) => {}}
                value={endDate.toDateString()}
                style={{ width: '100%', marginLeft: '10px' }}
                data-cy="leaseEndDate"
              />
            </Row>
            <TextInput
              data-cy="monthlyRent"
              id="monthlyRent"
              label="Monthly rent"
              value={invite?.monthly_rent ?? 0}
              leftIcon={'$'}
              onChange={(e) =>
                setInvite((prev) => ({
                  ...prev,
                  monthly_rent: sanitizeCurrencyInput(e)
                }))
              }
              error={Boolean(errors?.monthly_rent)}
              subtext={errors?.monthly_rent}
            />
          </Section>
          <Section>
            <SectionHeader>Coverage</SectionHeader>
            <RadioRoot
              name="property_default"
              value={invite?.coverage?.type}
              onValueChange={(value: string) => {
                setInvite((prev) => ({
                  ...prev,
                  coverage: { ...prev?.coverage, type: value }
                }));
              }}
            >
              <Radio.Option data-cy="defaultOption" label="Property default" value={Coverage.DEFAULT} />
              {invite?.coverage?.type === Coverage.DEFAULT && (
                <DefaultDiv>
                  <DefaultPrice>
                    {defaultCoverage?.coverage_options.default?.type === Coverage.AMOUNT
                      ? centsToUSDFormatter(new Decimal(defaultCoverage?.coverage_options.default?.value ?? 0))
                      : defaultCoverage?.coverage_options.default?.label}
                  </DefaultPrice>
                  <Wrap>
                    <DefaultText>
                      This is set as the default coverage amount for this property. If this seems incorrect, please{' '}
                      <Link href="https://support.sayrhino.com/hc/en-us/requests/new">submit a support ticket</Link>.
                    </DefaultText>
                  </Wrap>
                </DefaultDiv>
              )}
              <Radio.Option data-cy="amountOption" label="Dollar amount" value={Coverage.AMOUNT} />
              {invite?.coverage?.type === Coverage.AMOUNT && (
                <DefaultDiv>
                  <TextInput
                    label="Coverage amount"
                    id="aptSuiteFloor"
                    value={centsToUSDFormatter(new Decimal(invite?.coverage.value ?? 0))}
                    data-cy="coverageAmount"
                    onChange={(e) =>
                      setInvite((prev) => ({
                        ...prev,
                        coverage: { ...prev?.coverage, value: sanitizeCurrencyInput(e) }
                      }))
                    }
                  />
                  <DefaultText>
                    Please enter a value up to{' '}
                    {centsToUSDFormatter(
                      new Decimal(defaultCoverage?.coverage_options?.max_coverage_amount_cents ?? 0)
                    )}
                  </DefaultText>
                  <Wrap>
                    <DefaultText>
                      This is set as the maximum coverage amount for this property. If this seems incorrect, please{' '}
                      <Link href="https://support.sayrhino.com/hc/en-us/requests/new">submit a support ticket</Link>.
                    </DefaultText>
                  </Wrap>
                </DefaultDiv>
              )}
              <Radio.Option data-cy="multiplierOption" label="Multiplier" value={Coverage.MULTIPLIER} />
              {invite?.coverage?.type === Coverage.MULTIPLIER && (
                <DefaultDiv>
                  <MultiplierSelect
                    data-cy="coverageMultiplier"
                    value={invite?.coverage}
                    onChange={(mulp) => {
                      setInvite((prev) => ({
                        ...prev,
                        coverage: { ...prev?.coverage, ...mulp }
                      }));
                    }}
                  />
                </DefaultDiv>
              )}
            </RadioRoot>
          </Section>
          {backendError && <ErrorNotification errors={backendError} />}
          <ButtonCont>
            <SubmitButton
              children={isLoading ? 'Sending...' : 'Send invitation'}
              variant="tertiary"
              type="button"
              onClick={onSubmit}
              disabled={isLoading}
            />
          </ButtonCont>
        </Content>
      </Main>
    </Page>
  );
};

export default InvitePage;
