import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery, QueryObserverResult } from 'react-query';
import { Member } from '../PropertyUpload/batchUploadSteps/AssignNewTeamMember/TeamSelect';

export interface IUploadedProp {
  property_id: number;
  address: string;
  building_name: string;
  units?: object[];
}

export interface IContext {
  members: Member[] | undefined;
  properties: IUploadedProp[];
  hasTeam: boolean;
  searchTeamMember(param?: string): void;
  searchProperties(param?: string): void;
}

export const FormContext = createContext<IContext>({
  members: [],
  properties: [],
  hasTeam: false,
  searchTeamMember: () => undefined,
  searchProperties: () => undefined
});

const getTeamMembers = async (param: string = '') => {
  const { data } = await axios.get<{ users: Member[] }>(`/users/search?query=${encodeURIComponent(param)}`);
  return data;
};

export const useMembersProperties = (properties: IUploadedProp[]) => {
  const [team, setTeam] = useState<string>('');
  const [props, setProps] = useState<string>('');
  const [hasTeam, setHasTeam] = useState(false);

  const { data }: QueryObserverResult<{ users: Member[] }> = useQuery(['teamMembers', team], () =>
    getTeamMembers(team)
  );

  useEffect(() => {
    if (!hasTeam && data?.users) setHasTeam(data?.users.length > 0);
  }, [data?.users]);

  const searchTeamMember = (param: string) => {
    setTeam(param);
  };

  const searchProperties = (param: string) => {
    setProps(param);
  };

  const filterProperties = () => {
    return properties.filter(({ building_name, address }) => building_name.includes(props) || address.includes(props));
  };

  return {
    members: data?.users,
    properties: filterProperties(),
    hasTeam,
    searchTeamMember,
    searchProperties
  };
};

export const useFormContext = () => {
  return useContext(FormContext);
};
