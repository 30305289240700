import { IDataTablesApi } from './application_table';

class DataSortTable {
  public $table: JQuery;
  public dataTable: IDataTablesApi;
  public selector = '.data-table-sort';

  public settings() {
    return {
      dom: '<"search"fl><"top">rt<"bottom"ip><"clear">',
      retrieve: true,
      searching: true,
      pageLength: 10,
      conditionalPaging: true,
      pagingType: 'full_numbers'
    };
  }

  public render(): void {
    this.$table = $(this.selector);

    if (!this.$table.length) { return; }

    this.dataTable = this.$table.DataTable(this.settings()) as IDataTablesApi;
  }
}

export default DataSortTable;
