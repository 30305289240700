import { debounce, isEqual } from 'lodash';
import React, { Component } from 'react';

import { Link } from './Styles';
import Assignee from './Assignee';
import Filter from '../filter';
import { request } from 'utils/request';
import Table, { QueryParams, IPaginated } from '../Table';
import BulkAssign from './BulkAssign';
import { IAssignee } from './types';

import { fetchAdministrators } from 'api/administrators';

export interface IClaim {
  id: number;
  unit: string;
  state: string;
  status: string;
  admin_id: number;
  claim_type: string;
  created_at: string;
  amount_paid: string;
  tenant_name: string;
  claim_number: string;
  claim_amount: string;
  claim_path: string;
  building_name: string;
  admin_initials: string;
  coverage_amount: string;
  admin_name_or_email: string;
  policy_number: string;
  policy_path: string;
}

interface IProps {
  status: string;
  statuses?: string[];
  isAdmin: boolean;
  isPM: boolean;
  claims: IPaginated<IClaim>;
  claimsStatuses: string[];
  claimsTypes: string[];
  canBulkUpdate: boolean;
}

interface IState {
  claims: IPaginated<IClaim>;
  assignees: IAssignee[];
  filterVisble: boolean;
}

class ClaimsTable extends Component<IProps, IState> {
  private updateDataDebounced;
  private queryParams: QueryParams = [0, 10, undefined, undefined, undefined, Array(0)];
  private filters = {};

  constructor(props) {
    super(props);
    const { isAdmin, statuses } = props;

    if (isAdmin) {
      Object.assign(this.filters, { status: statuses });
    }

    this.state = { claims: props.claims, assignees: [], filterVisble: false };
    this.updateDataDebounced = debounce(this.updateData.bind(this), 500);
    this.loadAssignees();
  }

  public render() {
    const data = this.state.claims;
    const { assignees, filterVisble } = this.state;
    const { isAdmin, isPM, claimsStatuses, claimsTypes, canBulkUpdate } = this.props;

    const columns = [
      {
        Header: 'CLAIM NUMBER',
        accessor: 'claim_number',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.claim_number}</a>
      },
      {
        Header: 'POLICY NUMBER',
        accessor: 'policy_number',
        Cell: (props) => <a href={props.row.original.policy_path}>{props.row.original.policy_number}</a>
      },
      {
        Header: 'BUILDING NAME',
        accessor: 'building_name',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.building_name}</a>
      },
      {
        Header: 'UNIT',
        accessor: 'unit',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.unit}</a>
      },
      {
        Header: 'STATE',
        accessor: 'state',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.state}</a>
      },
      {
        Header: 'COVERAGE AMOUNT',
        accessor: 'coverage_amount',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.coverage_amount}</a>
      },
      {
        Header: 'CLAIM AMOUNT',
        accessor: 'claim_amount',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.claim_amount}</a>
      },
      {
        Header: 'AMOUNT PAID',
        accessor: 'amount_paid',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.amount_paid}</a>
      },
      {
        Header: 'TENANT NAME',
        accessor: 'tenant_name',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.tenant_name}</a>
      },
      {
        Header: 'CLAIM TYPES',
        accessor: 'claim_type',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.claim_type}</a>
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.status}</a>
      },
      {
        Header: 'CREATED AT',
        accessor: 'created_at',
        Cell: (props) => <a href={props.row.original.claim_path}>{props.row.original.created_at}</a>
      },
      isAdmin
        ? {
            Header: 'ASSIGNEE',
            accessor: 'assignee',
            Cell: (props) => <Assignee claim={props.row.original} />
          }
        : { id: 'assign' },
      isAdmin
        ? {
            Header: '',
            accessor: 'edit',
            Cell: (props) => (
              <>
                <Link href={`/admin/claims/${props.row.original.id}/edit`}>Edit</Link>
                <br />
                <Link
                  href={`/admin/claims/${props.row.original.id}/`}
                  data-method="delete"
                  data-confirm="Are you certain you want to delete this?"
                >
                  Delete
                </Link>
              </>
            )
          }
        : { id: 'edit' }
    ];

    const filters = [
      {
        label: 'Assignee',
        name: 'administrator_id',
        type: 'radio' as const,
        options: assignees && assignees.map((assignee) => ({ id: assignee.id, text: assignee.name_or_email }))
      },
      {
        label: 'Status',
        name: 'status',
        type: 'checkbox' as const,
        options: claimsStatuses
      },
      {
        label: 'Type',
        name: 'claim_type',
        type: 'checkbox' as const,
        options: claimsTypes
      }
    ];

    var selectedStatus = 'all';
    if (window.location.href.indexOf('status') > -1) {
      selectedStatus = window.location.search.split('&')[0].split('=')[1];
    }
    const highlightedLink = { color: '#6318CE', textDecoration: 'underline', paddingRight: 10 };

    return (
      <>
        {isPM && (
          <div style={{ display: 'inline-flex' }}>
            <p style={{ paddingRight: 10 }}>Show:</p>
            <a
              style={selectedStatus === 'all' ? highlightedLink : { paddingRight: 10 }}
              href="/admin/claims?table=true"
            >
              All Claims
            </a>
            <a
              style={selectedStatus === 'opened' ? highlightedLink : { paddingRight: 10 }}
              href="/admin/claims?status=opened&table=true"
            >
              Open Claims
            </a>
            <a
              style={selectedStatus === 'closed' ? highlightedLink : { paddingRight: 10 }}
              href="/admin/claims?status=closed&table=true"
            >
              Closed Claims
            </a>
            <a
              style={selectedStatus === 'withdrawn' ? highlightedLink : { paddingRight: 10 }}
              href="/admin/claims?status=withdrawn&table=true"
            >
              Withdrawn Claims
            </a>
          </div>
        )}
        {filterVisble && (
          <Filter
            filters={filters}
            filterValues={this.filters}
            onChange={this.onFilterChange}
            onClose={this.toggleFilter}
          />
        )}
        <Table
          paginatedData={data}
          columns={columns}
          update={this.updateDataDebounced}
          toggleExtendedFilter={isAdmin ? this.toggleFilter : undefined}
          bulkActions={(canBulkUpdate && isAdmin && ((props) => this.bulkActions(props))) || undefined}
        />
      </>
    );
  }

  private bulkActions = ({ selectedIds }) => (
    <BulkAssign
      selectedIds={selectedIds}
      assignees={this.state.assignees}
      onSuccess={() => this.updateData(this.queryParams as QueryParams, true)}
    />
  );

  private loadAssignees = () => {
    fetchAdministrators(
      '',
      (response) => {
        this.setState({ assignees: response.administrators });
      },
      () => {
        return;
      }
    );
  };

  private toggleFilter = () => {
    this.setState({ filterVisble: !this.state.filterVisble });
  };

  private onFilterChange = (filters: object) => {
    this.filters = filters;
    this.updateDataDebounced(this.queryParams, true);
  };

  private updateData(params: QueryParams, force = false) {
    if (!force && isEqual(params, this.queryParams)) {
      return;
    }

    params = params || [];
    this.queryParams = params;
    const { isAdmin } = this.props;

    const [pageIndex, pageSize, sortById, sortByDesc, filterValue] = params;
    const query = {
      page_size: pageSize,
      page_index: pageIndex,
      filter: this.filters
    };

    if (!isAdmin) {
      Object.assign(query, { status: this.props.status });
    }

    if (sortById !== undefined && sortByDesc !== undefined) {
      Object.assign(query, {
        sort_by: sortById,
        sort_desc: sortByDesc.toString()
      });
    }
    if (filterValue !== undefined) {
      Object.assign(query, {
        filter_value: filterValue
      });
    }
    request('/admin/claims', query).then((claims) => {
      this.setState({ claims });
    });
  }
}

export default ClaimsTable;
