import React, { ChangeEvent, useState } from 'react';
import styled from '@emotion/styled';
import { MultiSelect, TextInput, PALETTE } from '@sayrhino/rhino-shared-js';
import { centsToUSDFormatter } from '../utils';
import { CoverageTypeEnum } from '../interfaces';
import Decimal from 'decimal.js';
import { StyledBorder } from '../CoverageOptionSelect/IntegrationPropertySelect';
import { COVERAGE_MULTIPLIER_OPTIONS } from '../Integrations/CoverageOptions';
import SwitchSelector from '../SwitchSelector';

const StyledCoverageInputDiv = styled.div({
  marginTop: 18,
  marginBottom: 10
});
const StyledMultiplierInputDiv = styled.div({ marginTop: 18, marginBottom: 36 });
const SwitchWrapper = styled.div({ display: 'flex', alignItems: 'center' });
interface ICoverage {
  label: string;
  value: string;
}

const COVERAGE_SWITCH_OPTIONS = [
  { label: 'Multiplier', value: CoverageTypeEnum.MULTIPLIER },
  { label: 'Dollar amount', value: CoverageTypeEnum.DOLLAR_AMOUNT }
];
interface IProps {
  coverageError: boolean;
  coverageType: CoverageTypeEnum;
  value: number;
  onSelect: (value: CoverageTypeEnum) => void;
  handleCoverageDollarValue: (e: ChangeEvent<HTMLInputElement>) => void;
  setCoverageValue: (value: number) => void;
  updateDefaultCoverageAmount: number;
  coverageDollarError: string | undefined;
  label: string;
  subText?: string;
  id?: string;
  setMultipliers: (value: string[]) => void;
  multipliers: string[];
  coverageMultiplierLimitsError: string | undefined;
  setCoverageMultiplierLimitsError: (value: string) => void;
}

const SubText = styled.div<{ error?: boolean }>(({ error }) => [
  {
    fontSize: '12px',
    lineHeight: '20px',
    marginTop: '-46px',
    fontFamily: 'MaisonNeueLight',
    color: !error ? PALETTE.neutral25 : '#a72131'
  }
]);

const convertMultipliersToCoverage = (multipliers: string[]) => {
  // filter coverageMultipliers to get values from multipliers
  const multiplierArray: ICoverage[] = COVERAGE_MULTIPLIER_OPTIONS.filter((x) => multipliers.includes(x.value));
  return multiplierArray;
};

export const CoverageTypeSwitch: React.FC<IProps> = ({
  label,
  coverageType,
  value,
  handleCoverageDollarValue,
  onSelect,
  coverageDollarError,
  id,
  setMultipliers,
  multipliers,
  coverageMultiplierLimitsError,
  setCoverageMultiplierLimitsError,
  updateDefaultCoverageAmount
}) => {
  const [coverageCollection, setCoverageCollection] = useState<ICoverage[]>(convertMultipliersToCoverage(multipliers));
  const handleApprovalSelect = (value: string) => {
    const covgType: CoverageTypeEnum = value as CoverageTypeEnum;
    onSelect(covgType);
  };
  const formatCoverageValue = (value: number, coverageValue: number): string => {
    if (value === 0 && coverageValue === 0) return '';
    return centsToUSDFormatter(new Decimal(value !== 0 ? value : coverageValue));
  };

  return (
    <div aria-label={id}>
      <SwitchWrapper>
        <SwitchSelector
          active={coverageType}
          list={COVERAGE_SWITCH_OPTIONS}
          onClick={handleApprovalSelect}
          width={230}
        />
      </SwitchWrapper>
      {coverageType === CoverageTypeEnum.DOLLAR_AMOUNT && (
        <StyledCoverageInputDiv>
          <TextInput
            id={`${label}-approval-amount`}
            label="Dollar amount"
            onChange={(e) => {
              handleCoverageDollarValue(e);
            }}
            value={formatCoverageValue(value, updateDefaultCoverageAmount)}
            subtext={coverageDollarError}
            error={!!coverageDollarError}
            placeholder={'e.g. $1,500'}
            maxLength={11}
          />
        </StyledCoverageInputDiv>
      )}

      {coverageType === CoverageTypeEnum.MULTIPLIER && (
        <StyledMultiplierInputDiv>
          <MultiSelect
            aria-label={'Select Multiplier(s)'}
            id={id + '-multiselect'}
            name={'Select Multiplier(s)'}
            value={coverageCollection}
            onChange={(e: ICoverage[]) => {
              const multiplierValueArray = Array.isArray(e) ? e.map((x) => x.value) : [];
              setCoverageCollection(e);
              setMultipliers(multiplierValueArray);
              setCoverageMultiplierLimitsError('');
            }}
            options={COVERAGE_MULTIPLIER_OPTIONS}
            error={!!coverageMultiplierLimitsError}
            isOptionSelected={(option, values) => {
              return values.includes(option);
            }}
          />
          <SubText error={!!coverageMultiplierLimitsError}>{coverageMultiplierLimitsError}</SubText>
        </StyledMultiplierInputDiv>
      )}
    </div>
  );
};
