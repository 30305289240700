/** @jsx jsx */
import React, { useRef, useState, ChangeEvent, useEffect, useMemo } from 'react';
import { jsx, css } from '@emotion/core';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { PALETTE, FONTS, CloseIcon, Button, Datepicker, Loading, TextInput, Switch } from '@sayrhino/rhino-shared-js';
import { parseISO } from 'date-fns';

import useOverlay from './utils/overlay';
import PropertySelect from 'components/properties/PropertySelect';
import PropertyUnitSelect from 'components/units/PropertyUnitSelect';
import {
  parseIntDecimal,
  stringifyDate,
  sanitizeCurrencyInput,
  formatPhoneNumber,
  addYears,
  firstOfMonth
} from './utils';
import useInvitation, { getInvitationQueryKey } from '../../../api/v2/useInvitation';
import useUpdateInvitationMutation from '../../../api/v2/updateInvitation';
import { IPartialQuote, ICoverageSelection } from './interfaces';
import CoverageOptionsSelect, { ICoverageOptionSelectProps } from './CoverageOptionSelect/index';
import Decimal from 'decimal.js';
import useCoverageOptions, { CoverageState } from 'api/v2/useCoverageOptions';
import { DOLLAR_AMOUNT, INVITATION_TYPE } from './utils/constants';
import { useSegmentTrackOnLoad, useSegmentUser } from './utils/segmentTracker';

import '@sayrhino/rhino-shared-js/build/datepicker.css';
import { useQueryClient } from 'react-query';
import { StyledLine, fieldMargin } from 'components/v2/App/Invitation/Styles';
import { invitationsQueryKey } from 'api/v2/useInvitations';

export interface IErrors {
  coverage_amount_cents?: [string];
  coverage_months?: [string];
  email?: [string];
  first_name?: [string];
  last_name?: [string];
  lease_start_date?: [string];
  lease_end_date?: [string];
  monthly_rent?: [string];
  formatted_phone_number?: [string];
  property_id?: [string];
  status?: [string];
  unit?: [string];
  cash_deposit_amount_cents?: [string];
}

const formHeader = css({ paddingBottom: 16, borderBottom: `${PALETTE.neutralDark} solid 1px` });

const errorCSS = css([
  {
    color: 'red'
  },
  FONTS.p3
]);

const labelCSS = css([FONTS.p3Medium, { color: PALETTE.neutral65, marginBottom: 0 }]);

const buttonLinkCSS = css({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  padding: '0 24px',
  borderRadius: '24px',
  fontFamily: 'MaisonNeueExtendedMedium',
  fontSize: '16px',
  lineHeight: '28px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: PALETTE.neutralDark,
  border: '1px solid',
  borderColor: PALETTE.neutralDark,
  '&:hover': {
    backgroundColor: PALETTE.neutralDark,
    color: PALETTE.neutralLight
  },
  alignSelf: 'flex-start'
});

type PropertyTypeahead = {
  id?: number;
  full_address?: string;
  cash_deposit_enabled?: boolean;
};

type UnitTypeahead = {
  id?: number;
  unit?: string;
};

export const InvitationDetailsEditView = () => {
  let coverageInfo: CoverageState | undefined;
  let initialCoverageSelection: ICoverageSelection | undefined;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { invitationId } = useParams();
  const { data: invitationData } = useInvitation(Number(invitationId));
  const [invitation, setInvitation] = useState<IPartialQuote>({
    first_name: invitationData?.first_name,
    last_name: invitationData?.last_name,
    formatted_phone_number: invitationData?.formatted_phone_number,
    email: invitationData?.email,
    monthly_rent: invitationData?.monthly_rent_cents ? invitationData.monthly_rent_cents.cents / 100 : 0,
    property_id: invitationData?.property_id,
    unit_id: invitationData?.unit_id,
    lease_start_date: invitationData?.lease_start_date ? parseISO(invitationData.lease_start_date) : new Date(),
    lease_end_date: invitationData?.lease_end_date ? parseISO(invitationData.lease_end_date) : new Date(),
    coverage_amount: invitationData?.coverage_amount,
    coverage_amount_cents: invitationData?.coverage_amount_cents,
    coverage_months: Number(invitationData?.coverage_months),
    id: invitationData?.id || 0,
    cash_deposit_only: invitationData?.cash_deposit_only,
    cash_deposit_amount: invitationData?.cash_deposit_amount_cents
      ? invitationData?.cash_deposit_amount_cents.cents / 100
      : undefined,
    coverage: coverageInfo?.selected,
    guarantor_coverage: invitationData?.guarantor_coverage,
    guarantor_coverage_capable: invitationData?.guarantor_coverage_capable
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialInvitation = useRef(invitation);
  const segmentUser = useSegmentUser();

  const [unit, setUnit] = React.useState<UnitTypeahead>({
    id: invitationData?.unit_id,
    unit: invitationData?.unit_name || ''
  });
  const [property, setProperty] = React.useState<PropertyTypeahead>({
    id: invitationData?.property_id,
    full_address: invitationData?.full_address || '',
    cash_deposit_enabled: invitationData?.cash_deposit_enabled
  });

  const [startDate, setStartDate] = useState(
    invitationData?.lease_start_date ? parseISO(invitationData.lease_start_date) : new Date()
  );
  const [endDate, setEndDate] = useState(
    invitationData?.lease_start_date ? parseISO(invitationData.lease_end_date) : new Date()
  );
  const { mutate: updateInvitation } = useUpdateInvitationMutation();
  const [errors, setErrors] = useState<IErrors | undefined>();

  const [isFormValid, setIsFormValid] = useState(true);
  const inviteCashDepositOnly = (window as any).App?.featureFlags?.inviteCashDepositOnly;

  useSegmentTrackOnLoad('Invitation Edit Started', { invitation_id: invitationId });

  const setDepositInsuranceCoverageAmount = (depositInsuranceSection) => {
    setDepositInsuranceCoverageSection(depositInsuranceSection);
  };

  const [depositInsuranceCoverageSection, setDepositInsuranceCoverageSection] = React.useState<boolean>(
    invitationData?.coverage_details == null ? true : false
  );

  const disabledForPartnerAutoInvite = useMemo(
    () => invitationData?.pms_is_source_of_truth,
    [invitationData]
  );

  if (property?.id !== undefined) {
    initialCoverageSelection = {
      type: DOLLAR_AMOUNT,
      value: new Decimal(invitationData?.coverage_amount_cents || 0)
    };

    if (invitationData?.coverage_details) {
      initialCoverageSelection = {
        type: invitationData.coverage_details.type,
        value:
          invitationData.coverage_details.value !== undefined
            ? new Decimal(invitationData.coverage_details.value)
            : undefined
      };
    }

    coverageInfo = useCoverageOptions({
      propertyId: property?.id,
      unitId: unit.id,
      monthlyRentCents: invitation.monthly_rent ? invitation.monthly_rent * 100 : 0,
      initialCoverageState: initialCoverageSelection
    });
  }

  const handleLeaseStartDateChange = (date) => {
    setStartDate(date);
    setInvitation({
      ...invitation,
      lease_start_date: date
    });
  };

  const handleLeaseEndDateChange = (date) => {
    setEndDate(date);
    setInvitation({
      ...invitation,
      lease_end_date: date
    });
  };

  const onPropertyChange = (propertyEvent) => {
    if (propertyEvent === null) {
      setInvitation({
        ...invitation,
        property_id: undefined,
        full_address: undefined,
        unit_id: undefined
      });
      return;
    }

    setInvitation({
      ...invitation,
      property_id: propertyEvent?.id,
      full_address: propertyEvent?.full_address,
      unit_id: undefined
    });
    setProperty({ id: propertyEvent?.id, cash_deposit_enabled: !!propertyEvent?.cash_deposit_enabled });
    setUnit({});
  };

  const onUnitChange = (unitEvent) => {
    setInvitation({
      ...invitation,
      unit_id: unitEvent?.id
    });
  };

  const calcEditedFields = () => {
    const editedFields = new Set();
    const initial = initialInvitation.current;
    for (let key in { ...initial }) {
      if (initial[key] !== invitation[key]) {
        editedFields.add(key);
      }
    }
    return Array.from(editedFields).join(',');
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (!isFormValid) return;

    setIsSubmitting(true);
    updateInvitation(
      {
        ...invitation,
        coverage: handleCoverage()
      },
      {
        onSuccess: () => {
          window.analytics.track('Invitation Edit Saved', {
            ...segmentUser,
            invitation_id: invitationId,
            edited_fields: calcEditedFields()
          });
          queryClient.invalidateQueries(getInvitationQueryKey(Number(invitationId)), { exact: true });
          queryClient.invalidateQueries(invitationsQueryKey);
          navigate(-1);
        },
        // @ts-ignore
        onError: (e) => setErrors(e.response.data),
        onSettled: () => {
          setIsSubmitting(false);
        }
      }
    );
  };

  let coverageOptionsSelectProps: ICoverageOptionSelectProps | undefined;
  if (coverageInfo) {
    coverageOptionsSelectProps = {
      coverageInfo,
      disabled: false
    };
  }

  const handleCoverage = () => {
    var coverage;
    if (depositInsuranceCoverageSection) {
      coverage = {
        type: null,
        value: null
      };
    } else {
      coverage = coverageInfo?.selected;
    }
    return coverage;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setInvitation({ ...invitation, [key]: event.target.value });
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const phone = parseIntDecimal(event.target.value.replace(/\D/g, ''));
    setInvitation({ ...invitation, formatted_phone_number: formatPhoneNumber(event.target.value), phone });
  };

  const handleMonthlyRent = (event: ChangeEvent<HTMLInputElement>) => {
    const monthlyRent = sanitizeCurrencyInput(event);
    setInvitation({
      ...invitation,
      monthly_rent: monthlyRent === 0 ? null : monthlyRent
    });
  };

  const handleCashDepositAmount = (event: ChangeEvent<HTMLInputElement>) => {
    const cashDepostAmount = sanitizeCurrencyInput(event);
    setInvitation({
      ...invitation,
      cash_deposit_amount: cashDepostAmount || null
    });
  };

  const handleGuarantorCoverageChange = (enabled: boolean) => {
    setInvitation({
      ...invitation,
      guarantor_coverage: enabled,
      cash_deposit_amount: null
    });
  };

  const validateForm = () => {
    if (!invitation) return setIsFormValid(false);

    const errors: IErrors = {};
    let isValid = true;

    if (!disabledForPartnerAutoInvite) {
      const requiredFields = [
        'first_name',
        'last_name',
        'formatted_phone_number',
        'email',
        'property_id',
        'monthly_rent'
      ];
      requiredFields.forEach((field) => {
        if (!invitation[field]) {
          errors[field] = ['is required'];
          isValid = false;
        }
      });
    }

    if (property?.cash_deposit_enabled && !invitation.guarantor_coverage) {
      if (invitation.cash_deposit_amount === undefined) {
        errors.cash_deposit_amount_cents = ['is required'];
        isValid = false;
      } else if (Number(invitation.cash_deposit_amount) < 1) {
        errors.cash_deposit_amount_cents = ['must be equal or greater than $1'];
        isValid = false;
      }
    }

    setErrors(errors);
    setIsFormValid(isValid);
  };

  useEffect(validateForm, [invitation]);

  return errors?.status ? (
    <section css={{ display: 'inline' }}>
      <div
        css={{
          borderBottom: `1px solid ${PALETTE.neutral4}`,
          backgroundColor: PALETTE.neutralLight,
          height: '3.75rem',
          padding: '20px 96px',
          position: 'sticky',
          top: 0,
          display: 'flex',
          zIndex: 1,
          justifyContent: 'flex-end'
        }}
      >
        <Link
          to={`/admin/invitations/${invitationId}`}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 32,
            width: 32,
            backgroundColor: PALETTE.neutral4,
            borderRadius: 75
          }}
          aria-label="view invitation details"
        >
          <CloseIcon height={14} width={14} />
        </Link>
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
          flexDirection: 'column',
          paddingLeft: '96px',
          paddingRight: '96px'
        }}
      >
        <div css={[FONTS.h3]}>Oops, invitation has been completed. You can no longer edit invitation.</div>
        <div css={[FONTS.p1, { paddingBottom: '42px' }]}>{errors.status}</div>
        <Link to={`/admin/invitations/${invitationId}`} css={[buttonLinkCSS]}>
          Okay
        </Link>
      </div>
    </section>
  ) : (
    <section>
      <div
        css={{
          borderBottom: `1px solid ${PALETTE.neutral4}`,
          backgroundColor: PALETTE.neutralLight,
          height: '3.75rem',
          padding: '20px 32px',
          position: 'sticky',
          top: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 50
        }}
      >
        <h4 css={[FONTS.h5, { color: PALETTE.neutral25 }]}>Edit invitation</h4>
        <div
          css={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Link
            to={`/admin/invitations/${invitationId}`}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 32,
              width: 32,
              backgroundColor: PALETTE.neutral4,
              borderRadius: 75
            }}
            aria-label="view invitation details"
          >
            <CloseIcon height={14} width={14} />
          </Link>
        </div>
      </div>
      <form
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
          padding: '32px',
          overflow: 'auto',
          background: 'white'
        }}
      >
        <h3 css={[FONTS.h3, formHeader]}>{invitationData?.tenant_full_name}</h3>

        {disabledForPartnerAutoInvite ? (
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              background: PALETTE.brand4,
              padding: '12px 20px',
              borderRadius: '4px',
              boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.12)',
              alignSelf: 'stretch'
            }}
          >
            <h5 css={[FONTS.h5, { color: PALETTE.brand125 }]}>Did you know?</h5>
            <p css={[FONTS.p2Medium, { color: PALETTE.neutral65, textWrap: 'pretty' }]}>
              We regularly pull data from the Property Management System associated with this invitation in order to
              keep our data as accurate as possible. If you'd like to edit these fields, please do so in your Property
              Management System. These edits can take up to an hour to be reflected on this page.
            </p>
          </section>
        ) : null}

        <h4 css={[FONTS.h5, formHeader]}>Tenant</h4>
        <fieldset>
          <TextInput
            name="first name"
            label="First name"
            id="firstName"
            value={invitation.first_name}
            subtext={errors?.first_name && <p css={errorCSS}> First name {errors?.first_name?.join()} </p>}
            onChange={(event) => handleChange(event, 'first_name')}
            disabled={disabledForPartnerAutoInvite}
          />
          <TextInput
            name="last name"
            label="Last Name"
            id="lastName"
            value={invitation.last_name}
            subtext={errors?.last_name && <p css={errorCSS}> Last name {errors?.last_name?.join()} </p>}
            onChange={(event) => handleChange(event, 'last_name')}
            disabled={disabledForPartnerAutoInvite}
          />
          <TextInput name="email address" label="Email" id="email" value={invitationData?.email} disabled />
          <TextInput
            id="phoneNumber"
            label="Phone number"
            maxLength={14}
            value={invitation.formatted_phone_number}
            subtext={
              errors?.formatted_phone_number ? <p css={errorCSS}>Phone number {errors.formatted_phone_number}</p> : null
            }
            onChange={(formatted_phone_number) => handlePhoneNumberChange(formatted_phone_number)}
            type="tel"
            error={errors?.formatted_phone_number?.length! > 0}
            disabled={disabledForPartnerAutoInvite}
          />
        </fieldset>

        <h4 css={[FONTS.h5, formHeader]}>Apartment</h4>
        <fieldset style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <label htmlFor="leaseAddress" css={labelCSS}>
              Lease address
            </label>
            <PropertySelect
              labelKey="full_address"
              placeholder="123 Main Street, Brooklyn, NY, United States"
              value={{
                id: invitationData?.property_id,
                full_address: invitationData?.full_address
              }}
              onChange={onPropertyChange}
              id="invitation_property_id"
              errors={null}
              refresh={false}
              disabled={disabledForPartnerAutoInvite}
            />
            {errors?.property_id ? <p css={errorCSS}>Property {errors.property_id}</p> : null}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <label htmlFor="leaseUnit" css={labelCSS}>
              Lease Unit
            </label>
            <PropertyUnitSelect
              id="unit"
              labelKey="address_line_two"
              propertyId={property?.id}
              value={unit.id}
              onChange={onUnitChange}
              placeholder="Lease unit"
              clearable={false}
              key={property?.id}
              refresh
              disabled={disabledForPartnerAutoInvite}
            />
            {errors?.unit ? <p css={errorCSS}>Unit {errors.unit}</p> : null}
          </div>
          <div id="inviteEdit-leaseStartDate" style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <label htmlFor="leaseStartDate" css={labelCSS}>
              Lease start date
            </label>
            <Datepicker
              onChange={(date) => handleLeaseStartDateChange(date)}
              minDate={addYears(new Date(), -1)}
              maxDate={addYears(new Date(), 5)}
              selected={startDate}
              value={stringifyDate(startDate)}
              selectsRange={false}
              disabled={disabledForPartnerAutoInvite}
              inputLabel={null}
              style={{
                backgroundColor: disabledForPartnerAutoInvite ? '#E0E0E0' : 'transparent',
                borderRadius: '4px'
              }}
            />
            {errors?.lease_start_date && <p css={errorCSS}> Start date {errors.lease_start_date} </p>}
          </div>
          <div id="inviteEdit-leaseEndDate" style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <label htmlFor="leaseStartDate" css={labelCSS}>
              Lease end date
            </label>
            <Datepicker
              onChange={(date) => handleLeaseEndDateChange(date)}
              startDate={endDate}
              endDate={endDate}
              selected={endDate}
              value={stringifyDate(endDate)}
              selectsRange={false}
              disabled={disabledForPartnerAutoInvite}
              inputLabel={null}
              style={{
                backgroundColor: disabledForPartnerAutoInvite ? '#E0E0E0' : 'transparent',
                borderRadius: '4px'
              }}
            />
            {errors?.lease_end_date && <p css={errorCSS}> End date {errors.lease_end_date} </p>}
          </div>

          <TextInput
            label="Monthly rent"
            id="monthlyRent"
            leftIcon="$"
            type="text"
            onWheel={(event) => event.currentTarget.blur()}
            value={invitation.monthly_rent?.toLocaleString() || 0}
            onChange={(event) => handleMonthlyRent(event)}
            subtext={errors?.monthly_rent && <p css={errorCSS}>Monthly rent {errors.monthly_rent}</p>}
            disabled={disabledForPartnerAutoInvite}
          />

          {invitation?.guarantor_coverage_capable ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <label
                aria-label="Guarantor Coverage"
                htmlFor="guarantor-enabled-toggle"
                style={{ display: 'flex', gap: '16px', alignItems: 'center' }}
              >
                <span css={[FONTS.h5]}>Guarantor Coverage</span>
                <Switch
                  aria-label={`Toggle Guarantor Coverage`}
                  id={'guarantor-enabled-toggle'}
                  checked={invitation.guarantor_coverage}
                  onCheckedChange={(checked) => handleGuarantorCoverageChange(checked)}
                />
              </label>
              {property?.cash_deposit_enabled ? (
                <p css={[FONTS.p1Light]}>Cash Deposit not permitted with Guarantor Coverage enabled.</p>
              ) : null}
            </div>
          ) : null}

          {property?.cash_deposit_enabled ? (
            <div>
              {inviteCashDepositOnly && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <Switch
                    aria-label={`Toggle partner coverage rules`}
                    id={'coverage-toggle-partner'}
                    checked={depositInsuranceCoverageSection}
                    onCheckedChange={() => setDepositInsuranceCoverageAmount(!depositInsuranceCoverageSection)}
                  ></Switch>

                  <div css={{ paddingLeft: 16 }}>
                    <h5 css={[FONTS.h6, fieldMargin]}>Collect Cash Deposits only</h5>
                    <p css={[FONTS.p2Light]}>Renter will not have the option to buy deposit insurance.</p>
                  </div>
                </div>
              )}
              {!depositInsuranceCoverageSection && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <h4 css={[FONTS.h5, fieldMargin]}>Deposit Insurance Coverage Amount</h4>
                  <div>
                    {coverageOptionsSelectProps && (
                      <CoverageOptionsSelect {...coverageOptionsSelectProps} errors={errors} />
                    )}
                  </div>
                </div>
              )}

              <h4 css={[FONTS.h5, fieldMargin]}>Security Deposit Requirement</h4>
              <p css={[FONTS.p1Light]}>Set your security deposit requirement options.</p>
              <StyledLine />

              <h4 css={[FONTS.h5, fieldMargin]}>Cash Deposit Amount</h4>
              <p css={[FONTS.p1Light]}>Set the required security deposit amount.</p>

              <TextInput
                id="securityCashDeposit"
                label="Security Deposit Amount"
                data-cy="securityCashDeposit"
                leftIcon="$"
                type="number"
                value={invitation?.cash_deposit_amount || ''}
                disabled={invitation?.guarantor_coverage}
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(event) => handleCashDepositAmount(event)}
                subtext={
                  errors?.cash_deposit_amount_cents && (
                    <p className="infoError" css={errorCSS}>
                      Security Deposit Amount {errors?.cash_deposit_amount_cents.join()}
                    </p>
                  )
                }
              />
            </div>
          ) : (
            <div>
              <h4 css={[FONTS.h5, fieldMargin]}>Coverage</h4>
              {coverageOptionsSelectProps && <CoverageOptionsSelect {...coverageOptionsSelectProps} errors={errors} />}
            </div>
          )}
        </fieldset>
        <Button
          variant="primary"
          onClick={handleSubmit}
          css={{ alignSelf: 'end' }}
          disabled={!isFormValid || isSubmitting}
        >
          {isSubmitting ? 'Updating Invitation' : 'Update Invitation'}
        </Button>
      </form>
    </section>
  );
};

const InvitationDetailsEdit = () => {
  const { invitationId } = useParams();
  const { isSuccess } = useInvitation(Number(invitationId));
  const { setShowOverlay } = useOverlay();

  React.useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return isSuccess ? (
    <InvitationDetailsEditView />
  ) : (
    <div css={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Loading />
    </div>
  );
};

export default InvitationDetailsEdit;
