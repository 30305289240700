import axios from 'axios';
import { IForm } from 'components/v2/App/PropertyOwners/yardiPushForm';
import { csrfToken } from 'utils/document';

type IUpdate = {
  uuid?: string;
  form: {
    integration: {
      fields_schema: IForm;
    };
  };
};

const updateYardiPolicyPush = async ({ uuid, form }: IUpdate) => {
  const authenticity_token = csrfToken();
  const { data } = await axios.patch(`/v2/integrations/partners/${uuid}/yardi_policy_push`, form, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });
  return data;
};

export default updateYardiPolicyPush;
