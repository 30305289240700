import React from 'react';
import styled from '@emotion/styled';
import {
  Card as CardBase,
  PALETTE,
  FONTS,
  TextInput,
  Button as ButtonBase,
  Alert2Icon,
  InlineNotification
} from '@sayrhino/rhino-shared-js';
import ValidationBase from 'components/v2/validation';

import { keyframes } from '@emotion/react';

export const Page = styled.div({
  background: PALETTE.brand4,
  width: '100%',
  minHeight: '100%',
  height: 'auto'
});

export const Header = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '26px 100px'
});

export const Logo = styled.img({
  width: '98px',
  height: '28px'
});

export const FormArea = styled.form({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});

export const Card = styled(CardBase)({
  margin: '32px',
  padding: '40px 48px',
  width: '432px !important',
  height: 'auto !important',

  ':hover': {
    boxShadow: 'none !important',
    cursor: 'default'
  }
});

export const BottomText = styled.p([
  FONTS.p2Light,
  {
    color: PALETTE.neutralDark,
    marginBottom: '32px'
  }
]);

export const Link = styled.a({
  color: PALETTE.neutralDark,
  textDecoration: 'underline'
});

export const Heading = styled.h6([
  FONTS.h6Medium,
  {
    fontSize: '18px',
    lineHeight: '32px',
    marginBottom: '16px'
  }
]);

export const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center'
});

export const LastName = styled(TextInput)({
  marginLeft: '16px'
});

export const FirstName = styled(TextInput)({
  marginRight: '16px'
});

export const Terms = styled.p([
  FONTS.p2Light,
  {
    fontSize: '14px'
  }
]);

export const TermsLink = styled.a({
  color: PALETTE.interaction100
});

export const ValidationWrapper = styled.div({ paddingTop: '12px', marginBottom: '32px' });
export const Validation = styled(ValidationBase)({ marginBottom: '16px' });

export const SubtextErrorWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

export const SubtextErrorMessage = styled.span(FONTS.p3Light, {
  color: PALETTE.alert125,
  marginLeft: '8px'
});

export const Button = styled(ButtonBase)(
  {
    width: '100%',
    margin: '32px 0 0',
    alignItems: 'center !important',
    display: 'flex',
    justifyContent: 'center'
  },
  (props) => ({
    backgroundColor: `${props.disabled ? PALETTE.neutral12 : PALETTE.brand100} !important`,
    color: `${props.disabled ? PALETTE.neutralDark : PALETTE.neutralLight} !important`
  })
);

export const Errors = styled.div({
  margin: '10px auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const fadeOut = keyframes` 
    0% {opacity: 1}
    25% {opacity: .6}
    50% {opacity: .4}
    100% {opacity: 0}
  `;

export const ErrorItem = styled(InlineNotification)({
  width: '360px',
  maxWidth: '360px',
  borderRadius: '8px',
  animation: `${fadeOut} 1s ease-out 4s`
});

export const ErrorNotification = ({ error }: { error: string }) => {
  return (
    <Errors>
      <ErrorItem variant="highPriority">
        <div style={{ color: 'unset' }}>
          <span style={{ color: 'unset' }}>{error}</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', color: 'unset' }}>
          <Alert2Icon color={PALETTE.alert100} style={{ marginLeft: '8px' }} height="20px" width="20px" />
        </div>
      </ErrorItem>
    </Errors>
  );
};
