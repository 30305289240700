/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { PALETTE, FONTS, RightIcon } from '@sayrhino/rhino-shared-js';
import { Link } from 'react-router-dom';

const pillCardCSS = css({
  alignItems: 'center',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '100px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1.75rem',
  overflow: 'hidden',
  padding: '1.5rem 3rem',
  position: 'relative',
  '&:hover': {
    boxShadow: `0px 4px 16px 0px ${PALETTE.neutral12}`,
    border: `1px solid ${PALETTE.neutralLight}`
  }
});

interface IProps {
  to: string;
  title: string;
  value?: any;
  useServer?: boolean;
}

const DashboardPanelPillLink: React.FC<IProps> = ({ to, title, useServer, value }: IProps) => {
  const children = (
    <React.Fragment>
      <span css={[{ display: 'block' }, FONTS.h6Demi]}>{title}</span>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          height: '2.75rem',
          justifyContent: 'center'
        }}
      >
        <span
          css={[
            {
              display: 'block',
              paddingRight: '1rem'
            },
            FONTS.h2
          ]}
        >
          {value}
        </span>
        <RightIcon style={{ color: PALETTE.neutralDark }} />
      </div>
    </React.Fragment>
  );

  return (
    <li>
      {useServer ? (
        <a href={to} role="button" css={pillCardCSS} children={children} />
      ) : (
        <Link to={to} css={pillCardCSS} children={children} />
      )}
    </li>
  );
};

export default DashboardPanelPillLink;
