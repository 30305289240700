import axios from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation } from 'react-query';

type PolicyCancellation = { status: 'accepted' | 'declined' };
type CancellationId = { id: number };

const updateCancellation = async (cancellation: PolicyCancellation & CancellationId) => {
  const authenticity_token = csrfToken();
  const urlParams = new URLSearchParams(window.location.search);
  const auth_token = urlParams.get('auth_token');
  const { status, id } = cancellation;
  const { data } = await axios.put<PolicyCancellation>(
    `/v2/admin/insurance_policy_cancellation_requests/${id}`,
    { status, auth_token },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return data;
};

const usePolicyCancellation = () => useMutation(updateCancellation);

export default usePolicyCancellation;
