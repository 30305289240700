import { csrfToken } from 'utils/document';
import axios from 'axios';

const deletePercentageFile = async (filename) => {
  const token = csrfToken();

  const res = axios.delete(`/v2/admin/insurance_carrier_percentage`, {
    headers: {
      'X-CSRF-Token': token,
      'Content-Type': 'application/json'
    },
    data: {
      filename: filename
    }
  });

  return res;
};

export default deletePercentageFile;
