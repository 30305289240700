import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FONT_FAMILY, ArrowDown, PALETTE } from '@sayrhino/rhino-shared-js';
import { Property } from './NewTeamMember';
import MultiSelect from 'components/v2/select/MultiSelect';
import FormField from '../../../FormField';
import { useFormContext } from 'components/v2/App/utils/propertyMemberAssign';

const BuildingName = styled.span([FONT_FAMILY.extendedDemi]);

const Label = styled.label({
  whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export interface IProps {
  properties: Property[];
  handleChange: (selectedValues: Property[]) => void;
  error?: string | null;
}

const PropertiesMultiSelect: React.FC<IProps> = ({ properties, handleChange, error }) => {
  const { properties: list, searchProperties } = useFormContext();
  const [search, setSearch] = useState<string>('');

  const onSearchChange = (value: string) => {
    setSearch(value);
    searchProperties(value);
  };

  return (
    <FormField
      error={error}
      input={
        <MultiSelect
          id="propertySelect"
          placeholder="Properties"
          options={list}
          value={properties}
          allowSelectAll
          labelKey="building_name"
          altLabelKey="address"
          valueKey="property_id"
          inputValue={search}
          onInputChange={onSearchChange}
          onChange={handleChange}
          formatOptionLabel={(option, { context }) => {
            return context === 'menu' ? (
              <>
                <BuildingName>{option.building_name}</BuildingName>
                {option.address ? `: ${option.address}` : ''}
              </>
            ) : (
              option.building_name
            );
          }}
          components={{ DropdownIndicator: () => null }}
        />
      }
      label={
        <Label htmlFor="propertySelect">
          {' '}
          Select properties <ArrowDown style={{ color: PALETTE.neutral65 }} />{' '}
        </Label>
      }
    />
  );
};

export default PropertiesMultiSelect;
