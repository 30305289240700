import React  from 'react';

const CloseIcon = () =>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#F4F4F4" />
    <rect x="8" y="8" width="16" height="16" rx="8" fill="#F4F4F4" />
    <path d="M22.0933 10L9.90716 22.1861" stroke="#777777" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M22.0928 22.1861L9.90674 10" stroke="#777777" strokeLinecap="square" strokeLinejoin="round" />
  </svg>;

export default CloseIcon;
