import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import React from 'react';
import { CoverageLabel as Label, CoverageValue as Value, DisplayField, SpacingAbove, Subtitle } from './Styled';

const RequiresInvitationDetails = ({ requiresInvitation }) => {
  const NotAvailableText = styled.span([FONTS.p1], {
    color: PALETTE.neutral55
  });

  const getInvitationDetails = () => {
    if (requiresInvitation) {
      return 'Yes';
    } else if (requiresInvitation !== null) {
      return 'No';
    } else {
      return <NotAvailableText>Not specified</NotAvailableText>;
    }
  };
  return (
    <>
      <Subtitle>Homepage Sign-Ups</Subtitle>
      <SpacingAbove>
        <DisplayField>
          <Value id="requires invitation">{getInvitationDetails()} </Value>
          <Label htmlFor="requires invitation">Block Homepage Sign-Ups</Label>
        </DisplayField>
      </SpacingAbove>
    </>
  );
};

export default RequiresInvitationDetails;
