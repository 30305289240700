import React from 'react';
import IntegrationSetup from './IntegrationSetup';
import Integration from './Integration';
import { Subtitle } from './Styled';
interface IProps {
  partnerIntegrationId?: string;
  propertyOwnerIntegrationLogo?: any;
}
const IntegrationDetails: React.FC<IProps> = ({ partnerIntegrationId, propertyOwnerIntegrationLogo }) => {
  return (
    <>
      {!partnerIntegrationId && <Subtitle>Integration</Subtitle>}
      {partnerIntegrationId ? <Integration partnerIntegrationId={partnerIntegrationId} logo={propertyOwnerIntegrationLogo} /> : <IntegrationSetup />}
    </>
  );
};

export default IntegrationDetails;
