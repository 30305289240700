import React from 'react';
import { render, screen, fireEvent } from '@testing-library/react';
import { Server } from 'miragejs';
import ClaimsTable from '../../claims_table';

const CLAIMS = {
  data: [
    {
      id: 2474,
      claim_number: '0000012474',
      policy_number: 'NYO-CUS066000627-0',
      tenant_name: 'John_9835 Doe_9835',
      building_name: '90W',
      unit: '7S',
      state: 'NY',
      coverage_amount: '$5400.00',
      claim_amount: '$2634.00',
      amount_paid: '$0.00',
      claim_type: 'Loss of rent',
      created_at: '09/02/2020',
      status: 'New',
      admin_id: null,
      admin_email: null,
      admin_initials: '',
      admin_name_or_email: null,
      claim_path: '/admin/claims/2474',
      policy_path: '/admin/renter_policies/2313'
    },
    {
      id: 2473,
      claim_number: '0000012473',
      policy_number: 'NYO-CUS066009924',
      tenant_name: 'John_72136 Doe_72136',
      building_name: 'Timber Point- 6210NR',
      unit: '101',
      state: 'IN',
      coverage_amount: '$1000.00',
      claim_amount: '$1000.00',
      amount_paid: '$0.00',
      claim_type: 'Loss of rent',
      created_at: '09/01/2020',
      status: 'New',
      admin_id: 4745,
      admin_email: 'portal+4745@example.com',
      admin_initials: 'JD',
      admin_name_or_email: 'John_4745 Doe_4745',
      claim_path: '/admin/claims/2473',
      policy_path: '/admin/renter_policies/30656'
    }
  ],
  pageCount: 1,
  pageIndex: 0,
  pageSize: 10
};

const ADMINS = [
  {
    id: 4745,
    name_or_email: 'Michael LaSala',
    initials: 'ML'
  },
  {
    id: 26375,
    name_or_email: 'Caroline Jackson',
    initials: 'CJ'
  }
];

const RenderedClaimsTable = (props) => (
  <ClaimsTable
    status={null}
    isAdmin
    claimsTypes={['Loss of rent', 'Excessive wear and tear']}
    claimsStatuses={['accepted', 'closed_without_payment', 'collections paid']}
    claims={CLAIMS}
    {...props}
  />
);

let server;

xdescribe('<ClaimsTable />', () => {
  beforeEach(() => {
    server = new Server();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('renders a claims table', async () => {
    render(<RenderedClaimsTable />);

    expect(screen.getByText('CLAIM NUMBER')).toBeDefined();
  });

  test('can assign an admin to a claim', async () => {
    server.get('/admin/administrators', () => {
      return { administrators: ADMINS };
    });
    server.get('/admin/claims', () => {
      return CLAIMS;
    });

    const { getByText, getByLabelText, queryByTestId } = render(<RenderedClaimsTable />);
    expect(getByText('ASSIGNEE')).toBeDefined();

    const assignButton = queryByTestId('assignAdmin-2474');
    assignButton?.click();
    const select = getByLabelText(/administrator/);
    fireEvent.change(select, { target: { value: '23' } });
    expect(select).toBeDefined();
    expect(select.getAttribute('value')).toBe('23');
  });

  test('can unassign an admin from a claim', async () => {
    const { getByText, container, getByRole } = render(<RenderedClaimsTable />);

    expect(getByText('ASSIGNEE')).toBeDefined();
    const assignButton = getByRole('button', { name: 'JD' });
    fireEvent.click(assignButton);

    const unassign = container.querySelector('.has-value');
    expect(unassign).toBeDefined();
  });
});
