import http from 'j-fetch';
import React, { Component } from 'react';

const TIMEOUT = 1500;

interface IProps {
  document_owner_id: number;
  document_owner_type: string;
  document_type: string;
  visible?: boolean;
  openInNewTab?: boolean;
  onDocumentDownloaded(): void;
}

enum ReadyState {
  INITIAL,
  ERROR,
  LOADING,
  READY
}

interface IState {
  ready_state: ReadyState;
  document_url?: string;
}

function humanDocumentType(document_type: string): string {
  if (document_type === 'complaint_notice' || document_type === 'admitted_archive') {
    return 'Document';
  } else {
    return document_type
      .replace(/_/g, ' ')
      .split(' ')
      .map((s) => s[0].toUpperCase() + s.slice(1))
      .join(' ');
  }
}

class ViewDocument extends Component<IProps, IState> {
  private titles: string[] = [];

  constructor(props: IProps) {
    super(props);

    this.buildTitles();

    this.state = {
      ready_state: ReadyState.INITIAL
    };
  }

  public url(action: string): string {
    const { document_owner_id, document_type, document_owner_type } = this.props;
    let document_endpoint;
    switch (document_type) {
      case 'covered_units':
        document_endpoint = 'legacy_documents';
        break;
      case 'archive':
        document_endpoint = 'unit_endorsements';
        break;
      default:
        document_endpoint = 'documents';
        break;
    }
    return `/admin/${document_owner_type}/${document_owner_id}/${document_endpoint}/${document_type}/${action}`;
  }

  public title(): string {
    return this.titles[this.state.ready_state];
  }

  public generateDocument(): Promise<Response> {
    const url = this.url('generate');
    return http.get({ url });
  }

  public checkDocument = (): void => {
    const url = this.url('ready');

    const { document_type } = this.props;

    if (
      document_type === 'archive' ||
      document_type === 'admitted_archive' ||
      document_type === 'excess_lines_archive'
    ) {
      http
        .get({ url })
        .then((response) => response.text())
        .then((response) => {
          if (response === 'true') {
            this.fetchDocumentUrl();
          } else {
            setTimeout(this.checkDocument, TIMEOUT);
          }
        })
        .catch(this.error);
    } else {
      http
        .get({ url })
        .then((response) => {
          if (response) {
            this.fetchDocumentUrl();
          } else {
            setTimeout(this.checkDocument, TIMEOUT);
          }
        })
        .catch(this.error);
    }
  };

  public fetchDocumentUrl() {
    const url = this.url('download_url');
    http
      .get({ url })
      .then((response) => response.text())
      .then((response) =>
        this.setState({ document_url: response, ready_state: ReadyState.READY }, () => this.openDocument())
      )
      .catch(this.error);
  }

  public openDocument() {
    if (this.state.document_url) {
      if (this.props.openInNewTab) {
        window.open(this.state.document_url, '_blank');
      } else {
        window.location.href = this.state.document_url;
      }
    }
    this.props?.onDocumentDownloaded();
  }

  public componentDidMount() {
    if (this.state.ready_state === ReadyState.LOADING) {
      return;
    }
    if (this.state.ready_state === ReadyState.READY) {
      return this.openDocument();
    }
    this.setState({ ready_state: ReadyState.LOADING });
    this.generateDocument().then(this.checkDocument).catch(this.error);
  }

  public error = () => {
    this.setState({ ready_state: ReadyState.ERROR });
  };

  public render(): JSX.Element {
    return (
      <div className="documents__view-document" style={{ display: this.props.visible === false ? 'none' : 'block' }}>
        <div className="documents__view-document__title">{this.title()}</div>
      </div>
    );
  }

  private buildTitles(): void {
    const { document_type } = this.props;

    this.titles[ReadyState.ERROR] = 'An error has occurred, please try again';
    this.titles[ReadyState.INITIAL] = `Generating ${humanDocumentType(document_type)}...`;
    this.titles[ReadyState.READY] = `${humanDocumentType(document_type)} will be downloaded shortly...`;
    // same title as for initial state to preserve the button size, user never sees it because of the styling
    this.titles[ReadyState.LOADING] = this.titles[ReadyState.INITIAL];
  }
}

export default ViewDocument;
