export function subscribeDownloadChannel(callback, receivedCallback) {
  window.App.download = window.App.cable.subscriptions.create('DownloadChannel', {
    connected() {
      callback();
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      receivedCallback && receivedCallback(data);

      window.App.download.unsubscribe();
      window.App.cable.disconnect();
      delete window.App.download;
    }
  });
}
