/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import DashboardPanelWell from './DashboardPanelWell';
import DashboardPanelTitle from './DashboardPanelTitle';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { stringifyDateLong } from './utils';
import { FigureList, FigureListItem } from './FigureList';
import ProgressMeter from './ProgressMeter';

type IProps = {
  title: string;
  percent: number;
  filledLabel: string;
  unfilledLabel: string;
  updatedDate: Date;
};

const dotCss = css({
  width: 8,
  height: 8,
  borderRadius: 8,
  display: 'inline-block'
});

const greenDotCss = css({
  backgroundColor: '#65e2c1'
});

const grayDotCss = css({
  backgroundColor: PALETTE.neutral4
});

const DashboardPanelPercentageChart: React.FC<IProps> = ({
  title,
  percent,
  filledLabel,
  unfilledLabel,
  updatedDate
}) => (
  <DashboardPanelWell>
    <DashboardPanelTitle>{title}</DashboardPanelTitle>

    <div css={{ marginBottom: 24 }}>
      <ProgressMeter value={percent} />
    </div>

    <FigureList css={{ marginTop: 24 }}>
      <FigureListItem
        label={filledLabel}
        value={`${Math.round(percent * 100)}%`}
        icon={<div css={[dotCss, greenDotCss]} />}
      />
      <FigureListItem
        label={unfilledLabel}
        value={`${Math.round((1.0 - percent) * 100)}%`}
        icon={<div css={[dotCss, grayDotCss]} />}
      />
    </FigureList>

    <div css={[FONTS.label, { paddingTop: 16, color: PALETTE.neutral25, textAlign: 'right' }]}>
      Updated {stringifyDateLong(updatedDate)}
    </div>
  </DashboardPanelWell>
);

export default DashboardPanelPercentageChart;
