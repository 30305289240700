import React from 'react';
import styled from '@emotion/styled';
import { AlertIcon, Button, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { IClaimStatusAction } from '../../interfaces';

interface IProps {
  statusAction: IClaimStatusAction;
  claimStatusChange: (value: string) => void;
}

const Action = styled.div({ background: PALETTE.interaction4, padding: '32px 49px 24px' });
const ActionHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: 20
});
const ActionTitle = styled.p([FONTS.p3Medium]);
const ActionSubtext = styled.p([FONTS.p2Light]);
const ButtonGroup = styled.div({ display: 'flex' });
const PrimaryButton = styled.button([
  FONTS.p3Medium,
  {
    borderRadius: '24px',
    padding: '4px 20px',
    color: PALETTE.neutralLight,
    border: `1px solid ${PALETTE.interaction100}`,
    background: PALETTE.interaction100
  }
]);
const SecondaryButton = styled(PrimaryButton)({
  border: `1px solid ${PALETTE.interaction125}`,
  color: PALETTE.interaction125,
  background: PALETTE.neutralLight,
  marginRight: 24
});

const ClaimAction: React.FC<IProps> = ({ statusAction, claimStatusChange }) => {
  return (
    <Action>
      <ActionHeader>
        <ActionTitle>{statusAction.title}</ActionTitle>
        <AlertIcon height={14} width={14} color={PALETTE.neutralDark} />
      </ActionHeader>
      <ActionSubtext>{statusAction.label}</ActionSubtext>
      <hr />
      <ButtonGroup>
        <SecondaryButton onClick={() => claimStatusChange('deny')}>Deny</SecondaryButton>
        <PrimaryButton onClick={() => claimStatusChange('approve')}>Approve</PrimaryButton>
      </ButtonGroup>
    </Action>
  );
};

export default ClaimAction;
