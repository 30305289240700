import styled from '@emotion/styled';
import { Link, NavLink } from 'react-router-dom';
import { DropdownMenu } from '@sayrhino/rhino-shared-js';
import { FONTS, PALETTE, Button, InlineNotification as Notification, TimeIcon } from '@sayrhino/rhino-shared-js';

export const Banner = styled.div({ margin: 'auto', width: 384 });

export const ButtonGroup = styled.div({
  position: 'sticky',
  bottom: 32,
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 32,
  marginRight: 32,
  paddingTop: 32
});

export const CloseIconButton = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  width: 32,
  backgroundColor: PALETTE.neutral4,
  borderRadius: 75
});

export const CoverageLabel = styled.p([FONTS.p1Light], { paddingTop: 12 });
export const CoverageValue = styled.p([FONTS.p1Medium, { marginTop: -12 }]);

export const DisplayField = styled.div({
  padding: '12px 0',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'default',
  div: [
    FONTS.p1,
    {
      border: 'none',
      backgroundColor: 'transparent',
      width: '75%',
      '&:focus': {
        outline: 'none'
      }
    }
  ],
  label: [
    FONTS.p3,
    {
      color: PALETTE.neutral65,
      textAlign: 'right'
    }
  ]
});

export const EditButton = styled(Link)({
  display: 'flex',
  padding: '0px 96px 32px 96px',
  borderBottom: `1px solid ${PALETTE.neutral4}`
});

export const FormHeaderTitle = styled.h4([FONTS.h5, { color: PALETTE.neutral65 }]);
export const FormTitle = styled.h3([
  FONTS.h3,
  { padding: '32px 0 16px', borderBottom: `1px solid ${PALETTE.neutral4}` }
]);
export const FormSubtitle = styled.h4([
  FONTS.h5,
  { marginBottom: 32, padding: '40px 0 16px', borderBottom: `1px solid ${PALETTE.neutralDark}` }
]);
export const InlineNotification = styled(Notification)({ marginBottom: 16 });
export const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
});
export const NavIconGroup = styled.div({ display: 'flex', alignItems: 'center' });
export const NavBarWrapper = styled.div({
  borderBottom: `1px solid ${PALETTE.neutral4}`,
  backgroundColor: PALETTE.neutralLight,
  height: '3.75rem',
  padding: '20px 32px',
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1
});
export const Name = styled.h4([FONTS.h4, { padding: '24px 32px' }]);

export const NotificationMessage = styled.span({ color: PALETTE.alert100 });
export const NotificationIcon = styled(TimeIcon)({ marginLeft: '8px' });
export const NotificationWrapper = styled.div({
  paddingTop: 32,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});

export const PageHeader = styled.div({
  borderBottom: `1px solid ${PALETTE.neutral4}`,
  backgroundColor: PALETTE.neutralLight,
  height: '3.75rem',
  padding: '20px 32px',
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1
});
export const PageWrapper = styled.div({ padding: '30px 0px', borderRight: `1px solid ${PALETTE.neutral12}` });
export const PageTitle = styled.h3([FONTS.h3, { paddingLeft: '48px', marginBottom: '32px', paddingRight: '16px' }]);
export const Subtitle = styled.h4([
  FONTS.h5,
  { paddingBottom: 8, marginTop: 40, borderBottom: `1px solid ${PALETTE.neutral12}` }
]);
export const NoLineSubtitle = styled.h4([FONTS.h5, { paddingBottom: 8, marginTop: 40 }]);
export const Subtitle2 = styled.h4([FONTS.h5, { paddingBottom: 8, marginTop: 40 }]);
export const DetailsText = styled.p([FONTS.p1Light], { marginTop: '16px' });
export const UpdateButton = styled(Button)({ float: 'right', marginTop: '48px' });
export const Title = styled.h3([FONTS.h3, { padding: '32px 96px' }]);
export const DocumentDropDown = styled(DropdownMenu.Trigger)({
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '10px 0 35px 0'
});
export const DocumentDropDownContent = styled(DropdownMenu.Content)({ minWidth: 384 });
