/** @jsxFrag React.Fragment **/
import React, { useState, useCallback, forwardRef, useImperativeHandle, Ref, useEffect } from 'react';
import DomPurify from 'dompurify';
import styled from '@emotion/styled';
import '@reach/combobox/styles.css';
import { typeaheadFormFieldStyles, PALETTE, FONTS, Typeahead } from '@sayrhino/rhino-shared-js';

import { getProperties, getPropertiesByAssociatedPartners } from 'api/v2/usePropertySearch';
import { InvitationRequestPropertyAccessModal } from '../InvitationRequestPropertyAccessModal';
import useUserRole from '../utils/userRole';
import { useSegmentUser } from '../utils/segmentTracker';
import { isMobileDevice } from 'utils/mobile';
import { v4 as uuid } from 'uuid';

interface PropertyTypeaheadProps {
  onSelect: (id?: number) => void;
  setSelectedProperty: (object) => void;
  setCashDepositEnabled: (cash_deposit_enabled?: boolean) => void;
}

export interface RefObject {
  reset: () => void;
}

const NoPropertiesMessage = styled.span({
  color: PALETTE.alert125
});

const DisabledAddressOption = styled.li([FONTS.p2], {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0'
});

const DisabledAddressText = styled.span({
  color: PALETTE.neutral25,
  i: {
    fontWeight: 'bold'
  },
  '@media(max-width:425px)': {
    maxWidth: '250px'
  }
});

const EnabledAddressOption = styled.li([FONTS.p2], {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0'
});

const EnabledAddressText = styled.span({
  color: PALETTE.neutral88,
  i: {
    fontWeight: 'bold'
  },
  '@media(max-width:425px)': {
    maxWidth: '250px'
  }
});

const EnabledAddressValueText = styled.p([FONTS.p2], {
  color: PALETTE.neutral88,
  margin: '0',
  fontSize: '16px'
});

const NoAccessMsg = styled.span([FONTS.p2], {
  color: PALETTE.neutral55
});

const NoPropertiesMsg = styled.span({
  color: PALETTE.alert125
});

const RequestAccessLink = styled.a({
  color: PALETTE.interaction100,
  textDecoration: 'underline',
  cursor: 'pointer'
});

const Wrapper = styled.div({ ...typeaheadFormFieldStyles });

const sanitize = (html) => DomPurify.sanitize(html);

export const PropertyInviteTypeahead = forwardRef(
  ({ onSelect, setSelectedProperty, setCashDepositEnabled }: PropertyTypeaheadProps, ref: Ref<RefObject>) => {
    const segmentUser = useSegmentUser();
    const { isPropertyManager, isListingAgent } = useUserRole();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [bottomText, setBottomText] = useState<string | object>('');
    const [propertyManagerAdmins, setPropertyManagerAdmins] = useState<any[]>([]);
    const [properties, setProperties] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalAddressString, setModalAddressString] = useState<string>('');
    const [propertyId, setPropertyId] = useState<number | null>(null);
    const only_active = true;
    const isPMorLA = isPropertyManager || isListingAgent;
    const appHeader = document.getElementsByClassName('invitation-header') as HTMLCollectionOf<HTMLElement>;
    const topBar = document.getElementById('topBar');
    const [inviteSessionId, setInviteSessionId] = useState(uuid());

    useImperativeHandle(ref, () => {
      return {
        reset
      };
    });

    const reset = () => {
      setSearchTerm('');
    };

    useEffect(() => {
      const timeoutId = setTimeout(() => searchProperties(), 500);
      return () => clearTimeout(timeoutId);
    }, [searchTerm]);

    const searchProperties = async () => {
      if (searchTerm.length >= 3) {
        setBottomText('Searching for ' + searchTerm);

        const results = isPMorLA
          ? await getPropertiesByAssociatedPartners(searchTerm)
          : await getProperties(searchTerm, only_active, 'invitation');
        setProperties(results);

        if (results.length == 0)
          setBottomText(() => (
            <NoPropertiesMessage>
              'No matches found – Please confirm that you’ve been added to this property by an admin and try again.'
            </NoPropertiesMessage>
          ));
      } else setBottomText('Please enter at least three characters in order to search.');
    };

    const getAddressString = (property) =>
      `${property.building_name}, ${property.address_line_one}, ${property.address_city}, ${property.address_state}, ${property.address_zip}`;

    const getPropertyIdMap = useCallback(() => {
      return (properties as any[])?.reduce((acc, property) => {
        acc[getAddressString(property)] = { id: property.id };
        return acc;
      }, {});
    }, [properties]);

    const propertyIdMap = getPropertyIdMap();

    const onSelectItem = (value) => {
      setSearchTerm(value.label);
      setSelectedProperty(value.property);
      setCashDepositEnabled(value.property?.cash_deposit_enabled);
      if (propertyIdMap) onSelect(propertyIdMap[value.label].id);

      window.analytics.track('Cash Coverage Property Selected', {
        ...segmentUser,
        invite_session_id: inviteSessionId,
        portal_session_id: segmentUser.portal_session_id,
        user_id: segmentUser.user_id,
        property_id: value.property.id,
        cash_deposit_enabled: value.property.cash_deposit_enabled
      });
    };

    const boldSearchTerm = (str, substr) => {
      const term = new RegExp('(' + substr + ')', 'ig');
      return str.replace(term, `<i>$1</i>`);
    };

    const setRequestAccessData = (property) => {
      const { id, property_manager_admins } = property;
      setModalAddressString(getAddressString(property));
      setPropertyManagerAdmins(property_manager_admins);
      setPropertyId(id);
    };

    const hideHeader = () => {
      if (!isMobileDevice()) {
        appHeader[0]!.style.display = 'none';
        topBar!.style.zIndex = '0';
      }
    };

    const displayHeader = () => {
      if (!isMobileDevice()) {
        appHeader[0]!.style.display = 'flex';
        topBar!.style.zIndex = '4';
      }
    };

    const trackSegmentEvent = (propertyId) => {
      window.analytics.track('Property Access Requested', {
        ...segmentUser,
        propertyId
      });
    };

    const handleRequestAccess = (property) => {
      trackSegmentEvent(property.id);
      setIsModalOpen(true);
      setRequestAccessData(property);
      hideHeader();
    };

    const closeModal = () => {
      setIsModalOpen(false);
      displayHeader();
    };

    const formatOptionLabel = ({ value, label, property, user_assigned, pmadmins }, { context }) => (
      <div>
        {context === 'value' && <EnabledAddressValueText>{label}</EnabledAddressValueText>}
        {(!isPMorLA || user_assigned) && context === 'menu' && (
          <EnabledAddressOption>
            <EnabledAddressText dangerouslySetInnerHTML={{ __html: sanitize(boldSearchTerm(label, searchTerm)) }} />
          </EnabledAddressOption>
        )}
        {isPMorLA && !user_assigned && context === 'menu' && (
          <DisabledAddressOption>
            <DisabledAddressText dangerouslySetInnerHTML={{ __html: sanitize(boldSearchTerm(label, searchTerm)) }} />
            {pmadmins.length ? (
              <RequestAccessLink onClick={() => handleRequestAccess(property)}>Request access</RequestAccessLink>
            ) : (
              <NoAccessMsg>No access</NoAccessMsg>
            )}
          </DisabledAddressOption>
        )}
      </div>
    );

    const formatProperties = (properties) =>
      properties?.map((property) => {
        if (isPMorLA && !property.user_assigned) {
          return {
            label: getAddressString(property),
            value: property.id,
            isdisabled: true,
            user_assigned: property.user_assigned,
            pmadmins: property.property_manager_admins,
            property: property
          };
        } else {
          return {
            label: getAddressString(property),
            value: property.id,
            user_assigned: property.user_assigned,
            pmadmins: property.property_manager_admins,
            property: property,
            isdisabled: false
          };
        }
      });

    return (
      <>
        <div>
          {isModalOpen && (
            <InvitationRequestPropertyAccessModal
              closeModal={closeModal}
              isModalOpen={isModalOpen}
              modalAddressString={modalAddressString}
              propertyManagerAdmins={propertyManagerAdmins}
              propertyId={propertyId}
            />
          )}
        </div>

        <Typeahead
          id="PropertyTypeahead"
          label="Lease address"
          options={properties?.length ? formatProperties(properties) : []}
          placeholder=""
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={() => bottomText}
          onInputChange={setSearchTerm}
          isOptionDisabled={(option: any) => option.isdisabled}
          onChange={onSelectItem}
        />
      </>
    );
  }
);
