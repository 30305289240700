import React, { Component } from 'react';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import { ReactSelectProps } from 'react-select';

import { searchUnitsWithinProperty } from 'api/units';
import { getSelectStyles } from 'components/v2/select/styles';

interface IProps extends ReactSelectProps {
  onChange: (property: any) => any;
  name?: string;
  id?: string;
  valueKey?: string;
  labelKey: string;
  value?: string | any;
  clearable?: boolean;
  placeholder?: string;
  propertyId?: string | number | void;
  className?: string;
  refresh?: boolean;
  disabled?: boolean;
  errors?: string | null;
}

interface IState {
  selected?: any;
  isLoading: boolean;
}

class PropertyUnitSelect extends Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    name: 'unit',
    id: 'unit',
    valueKey: 'id',
    clearable: true,
    placeholder: ''
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false,
      selected: props.value
    };
  }

  public onChange = (selected) => {
    this.setState({ selected });
    this.props.onChange(selected);
  };

  public componentDidUpdate(prevProps, prevState, snapshot) {
    const { refresh, value } = this.props;
    if (refresh && value !== prevProps.value) {
      this.setState({ selected: value });
    }
  }

  public render(): JSX.Element {
    const { name, id, valueKey, labelKey, clearable, placeholder, className, disabled, errors } = this.props;
    const { selected, isLoading } = this.state;

    return (
      <div id="unit_select">
        <AsyncSelect
          name={name}
          id={id}
          inputId={id}
          instanceId={id}
          getOptionLabel={(options) => options[labelKey || 'label']}
          getOptionValue={(options) => options[valueKey || 'value']}
          value={selected}
          isClearable={clearable}
          isLoading={isLoading}
          placeholder={placeholder}
          loadOptions={this.loadUnits}
          onChange={this.onChange}
          className={className}
          isDisabled={disabled}
          styles={getSelectStyles({})}
        />
        <div className="error">{errors}</div>
      </div>
    );
  }

  private loadUnits = (input: string, callback: Function) => {
    const { propertyId } = this.props;

    if (!propertyId) {
      return callback([]);
    }

    this.setState({ isLoading: true });

    searchUnitsWithinProperty(
      propertyId,
      input,
      (units) => {
        callback(units);
        this.setState({ isLoading: false });
      },
      (response) => {
        response.json().then((e) => {
          callback([]);
          this.setState({ isLoading: false });
        });
      }
    );
  };
}

export default PropertyUnitSelect;
