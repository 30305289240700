import React from 'react';
import styled from '@emotion/styled';
import { LegacyTag as TagBase, PALETTE } from '@sayrhino/rhino-shared-js';

import { Member } from '../AssignNewTeamMember/TeamSelect';
import { useFormContext } from 'components/v2/App/utils/propertyMemberAssign';

const TagWrapper = styled.div({ display: 'flex', flexWrap: 'wrap', marginBottom: '40px' });

const Tag = styled(TagBase)({
  paddingRight: '12px',
  color: PALETTE.neutralDark,
  margin: '2px',
  backgroundColor: PALETTE.neutral4,
  border: 'none'
});

export interface ITeamProps {
  team: Member[];
}

const TeamTags: React.FC<ITeamProps> = ({ team }: ITeamProps) => {
  const { members } = useFormContext();
  const allTeamSelected = team.length === members?.length;

  return (
    <TagWrapper>
      {allTeamSelected ? (
        <Tag variant="state">All Team</Tag>
      ) : (
        team.map(({ id, full_name, email }) => (
          <Tag variant="state" key={id}>
            {full_name || email}
          </Tag>
        ))
      )}
    </TagWrapper>
  );
};

export default TeamTags;
