/** @jsx jsx */
/** @jsxFrag React.Fragment */

import React, { useEffect, useState } from 'react';
import { jsx, css } from '@emotion/core';
import { useParams, Link, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { camelize } from './utils/camelizeObjectKeys';
import axios from 'axios';
import {
  PALETTE,
  FONTS,
  MinimizeIcon,
  LinkIcon,
  LegacyTag,
  Loading,
  MaskedInput,
  AlertIcon,
  CheckIcon,
  Alert2Icon,
  ActionButton,
  Button
} from '@sayrhino/rhino-shared-js';
import { useClipboard } from 'use-clipboard-copy';
import { stringifyDateRange, initializeDate, useReferrer, urlWithReferrer, stringifyDateWithSlashes } from './utils';
import usePolicy from '../../../api/v2/usePolicy';
import usePolicyCancellation from '../../../api/v2/usePolicyCancellation';
import DownloadBanner from './DownloadBanner';
import InlineStatusBanner from './InlineStatusBanner';
import PendingChangesetBanner from './PendingChangesetBanner';
import { ICancellationRequest, IInsurancePolicy } from 'components/v2/App/interfaces';
import ViewDocument from 'components/documents/ViewDocument';
import { MULTIPLIER } from './utils/constants';
import { useUserRole, useIsAutoApprove } from './utils/userRole';
import { createServiceFeeLabel, createProcessingFeeLabel } from 'utils/us_states';

import '@sayrhino/rhino-shared-js/build/datepicker.css';
import useToast from './toast/use-toast';
import RentersInsurancePolicyDetails from './RentersInsurancePolicyDetails';
import styled from '@emotion/styled';
import { isBefore, isAfter, add } from 'date-fns';
import CancellationRequestBanner from './CancellationRequests/CancellationRequestBanner';
import CancellationRequestModal from './CancellationRequests/CancellationRequestModal';
import FlatCancellationDenialForm from './CancellationRequests/FlatCancellationDenialForm';
import useUpdatePolicyCancellationRequest from 'api/v2/useUpdatePolicyCancellationRequest';
import SuccessCancellationRequestDenial from './SuccessCancellationRequestDenial';
import EarlyTerminationDenialForm from './CancellationRequests/EarlyTerminationDenialForm';
import { event } from 'jquery';
import LeaseStillInEffectBanner from './CancellationRequests/LeaseStillInEffectBanner';
import CancelEarlyTerminationBanner from './CancellationRequests/CancelEarlyTerminationBanner';

const purchasedRentersInsuranceDetails = (window as any).App?.featureFlags?.purchasedRentersInsuranceDetails;
const renewalRequests = (window as any).App?.featureFlags?.renewalRequests;
const useNewCancellationsExperience = (window as any).App?.featureFlags?.useNewCancellationsExperience;

const fieldStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 12,
  borderBottom: '1px solid #F4F4F4',
  input: [
    FONTS.p1Light,
    {
      border: 'none',
      width: '75%'
    }
  ],
  label: [
    FONTS.p3Light,
    {
      color: PALETTE.neutral25,
      textAlign: 'right'
    }
  ],
  span: [
    FONTS.p3Light,
    {
      color: PALETTE.neutral25,
      textAlign: 'left'
    }
  ]
});

const fieldMargin = css({
  marginLeft: 48,
  marginRight: 48
});

const bannerAlignment = css({
  margin: 'auto',
  width: 480
});

const documentLinkStyle = css({
  alignItems: 'center',
  color: '#315BF1',
  borderRadius: '100px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1.75rem',
  overflow: 'hidden',
  padding: '1.5rem 3rem',
  position: 'relative'
});

const ActionButtonWrapper = styled.div({
  borderBottom: '1px solid #F4F4F4',
  marginTop: 16,
  paddingBottom: 40,
  marginBottom: 40,
  display: 'flex',
  gap: 8
});

const SuccessActionButton = styled.button([
  FONTS.p3Medium,
  {
    display: 'flex',
    backgroundColor: PALETTE.success4,
    color: PALETTE.success100,
    alignItems: 'center',
    height: '32px',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
    position: 'relative',
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 2px, 0 0 0 4px ${PALETTE.interaction100}`,
      transition: 'all .1s linear'
    },
    '&:hover .tooltip': {
      visibility: 'visible'
    }
  }
]);

const InputWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column'
});

export const FormField = (props) => <div css={fieldStyles} {...props} />;

const documentType = (data: IInsurancePolicy): string => {
  if (data.market_name === 'Excess Lines') {
    return 'excess_lines_archive';
  } else {
    return 'admitted_archive';
  }
};

const formatCoverageAmount = (
  coverageDetails?: IInsurancePolicy['coverage_details'],
  coverageAmount?: string
): string => {
  if (coverageDetails !== undefined) {
    if (coverageDetails.type === MULTIPLIER) {
      return `${coverageAmount} (${coverageDetails.label})`;
    } else if (coverageAmount !== undefined) {
      return coverageAmount;
    }
  }

  return '';
};

const isPolicyCancelled = (
  cancellationRequest: ICancellationRequest | null | undefined
): cancellationRequest is ICancellationRequest => cancellationRequest !== null && cancellationRequest !== undefined;

// This component should be removed while removing the feature flag use_new_cancellations_experience
const ConfirmCancellation = (props: {
  cancellation: ICancellationRequest;
  isCancellationError: boolean;
  setError: (showError: boolean) => void;
  isTriggered: (trigger: boolean, status: string) => void;
}) => {
  const { mutate, isLoading, isSuccess } = usePolicyCancellation();
  const { cancellation, setError, isTriggered, isCancellationError } = props;
  const [toastStatus, setToastStatus] = useState('');
  const { addToast } = useToast();
  const userRole = useUserRole();
  const { isListingAgent, isThirdPartyAdmin } = userRole;
  const autoAcceptCancellation = useIsAutoApprove();

  const handleConfirm = () => {
    mutate(
      { status: 'accepted', id: cancellation.id },
      {
        onError: () => setError(true),
        onSuccess: () => {
          setToastStatus('Cancellation request approved');
        }
      }
    );
  };

  const handleDecline = () => {
    mutate(
      { status: 'declined', id: cancellation.id },
      {
        onError: () => setError(true),
        onSuccess: () => {
          setToastStatus('Cancellation request denied');
        }
      }
    );
  };

  useEffect(() => {
    if (toastStatus) {
      addToast(toastStatus);
      isTriggered(true, toastStatus);
    }
  }, [toastStatus]);

  useEffect(() => {
    if (autoAcceptCancellation) {
      handleConfirm();
    }
  });

  return (
    <>
      {!isListingAgent && !isThirdPartyAdmin && (
        <AnimatePresence>
          <motion.div
            css={{ backgroundColor: PALETTE.brand4, padding: '32px 0', margin: 'auto' }}
            {...props}
            initial={{ y: '-100%', opacity: 0 }}
            transition={{ stiffness: 100 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: '-100%', opacity: 0 }}
          >
            <div
              css={{
                maxWidth: '384px',
                margin: 'auto'
              }}
            >
              {isSuccess ? (
                <div
                  css={{
                    height: 48,
                    width: 48,
                    backgroundColor: '#32CC65',
                    borderRadius: 60,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: PALETTE.neutralLight,
                    margin: 'auto'
                  }}
                >
                  <CheckIcon height={20} width={20} />
                </div>
              ) : (
                <>
                  <h3
                    css={[
                      FONTS.p3Medium,
                      {
                        color: PALETTE.brand100,
                        marginBottom: 16,
                        paddingBottom: 12,
                        borderBottom: `1px solid ${PALETTE.brand100}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }
                    ]}
                  >
                    Policy cancellation request
                    <AlertIcon height={20} width={20} />
                  </h3>
                  <p css={FONTS.p1Light}>
                    Reason: {cancellation.reason_string}
                    <br />
                    {cancellation.reason === 5 && ` (${cancellation.other_reason})`}
                    {cancellation.reason === 6 &&
                      cancellation.terminated_at &&
                      `Move-out date: ${stringifyDateWithSlashes(new Date(cancellation.terminated_at))}`}
                  </p>
                  {props.cancellation.reason === 2 && (
                    <span>
                      — Roommate's Email:{' '}
                      <a
                        href={`mailto:${cancellation.roommate_email}`}
                        css={[FONTS.p1Light, { textDecoration: 'underline' }]}
                      >
                        {cancellation.roommate_email}
                      </a>
                    </span>
                  )}
                  <div css={{ display: 'flex', marginTop: 16 }}>
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <>
                        <Button variant="secondary" onClick={handleDecline}>
                          Deny
                        </Button>
                        <Button variant="primary" css={{ marginLeft: 24 }} onClick={handleConfirm}>
                          Approve
                        </Button>
                      </>
                    )}
                  </div>
                  {isCancellationError && (
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                      <Alert2Icon style={{ marginRight: 20, color: PALETTE.alert100 }} height="20px" width="20px" />
                      <p css={[FONTS.p2Light, { color: PALETTE.alert100 }]}>Server could not process this request</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

const NavBar = (props) => {
  const { copy, copied } = useClipboard({ copiedTimeout: 1000 });
  const [cancellationStatus, setCancellationStatus] = useState(props.cancellationRequest?.status);

  useEffect(() => {
    if (!props.cancellationRequest) {
      return;
    }

    const fetchCancellationStatus = async () => {
      try {
        const response = await fetch(`/v2/admin/insurance_policy_cancellation_requests/${props.cancellationRequest.id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        setCancellationStatus(data.status);
      } catch (error) {
        console.error("Error fetching cancellation request status:", error);
      }
    };

    const intervalId = setInterval(() => {
      fetchCancellationStatus();
    }, 5000); // Polling every 5000 milliseconds (5 seconds)

    return () => clearInterval(intervalId);
  }, [props.cancellationRequest?.id]);

  const statusToLabel = {
    pending: {
      label: 'Cancellation Requested',
      backgroundColor: '#FDF2F8',
      color: '#BE185D',
    },
    declined: {
      label: 'Cancellation Denied',
      backgroundColor: '#F9FAFB',
      color: '#4B5563',
    },
    accepted: {
      label: 'Cancellation Approved',
      backgroundColor: '#E8FCEF',
      color: '#12873E',
    },
    autocancelled: {
      label: 'Cancellation Approved',
      backgroundColor: '#E8FCEF',
      color: '#12873E',
    },
  };

  return (
    <div
      css={{
        borderBottom: '1px solid #F4F4F4',
        backgroundColor: PALETTE.neutralLight,
        height: '3.75rem',
        padding: '20px 48px',
        position: 'sticky',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 1
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Link
          to={props.referrer}
          css={{ display: 'flex', alignItems: 'center', marginRight: 48 }}
          aria-label="collapse"
        >
          <MinimizeIcon />
        </Link>
        <button
          css={{
            background: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => copy(window.location.href)}
        >
          <LinkIcon />
          {copied && <span css={{ color: PALETTE.success125, marginLeft: 12 }}>copied!</span>}
        </button>
      </div>
      <div css={{display: 'flex'}}>
        {
          useNewCancellationsExperience ? (
            <>
              {props.cancellationRequest && statusToLabel[props.cancellationRequest.status] && (
                <LegacyTag
                  variant="state"
                  css={{
                    backgroundColor: statusToLabel[props.cancellationRequest.status].backgroundColor,
                    color: statusToLabel[props.cancellationRequest.status].color,
                    marginRight: '10px'
                  }}
                >
                  {statusToLabel[props.cancellationRequest.status].label}
                </LegacyTag>
              )}

              <LegacyTag variant="default">{props.status}</LegacyTag>
            </>
          ) : (
            <LegacyTag variant="default">{props.status}</LegacyTag>
          )
        }
      </div>
    </div>
  );
};

type Pagination = {
  current_page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
};

type DocumentRevision = {
  id: number;
  type_name: string;
  valid_from: string;
  valid_to: string | null;
  data: {};
};

type DocumentRevisions = {
  data: DocumentRevision[];
  meta: Pagination;
};

const PolicyDetails = () => {
  const { policyId } = useParams();
  const { pathname } = useLocation();
  const rawReferrer = useReferrer();
  const referrer = rawReferrer || '/admin/renter_policies';
  const { data, isSuccess, refetch } = usePolicy(Number(policyId));
  const [downloadingDocument, startDownload] = useState(false);
  const { status_detail, pending_changeset, lease_start_date, lease_end_date } = data || {};
  const [isCancellationError, setError] = React.useState(false);
  const [triggerCancellation, setTriggerCancellation] = useState<{ trigger: boolean; status: string }>({
    trigger: false,
    status: ''
  });
  const userRole = useUserRole();
  const { isListingAgent, isThirdPartyAdmin } = userRole;
  const [showCancellationRequestModal, setShowCancellationRequestModal] = useState<boolean>(false);
  const [showCancellationApprovedTag, setShowCancellationApprovedTag] = useState<boolean>(false);
  const [showFlatCancellationDenialForm, setShowFlatCancellationDenialForm] = useState<boolean>(false);
  const [showEarlyTerminationDenialForm, setShowEarlyTerminationDenialForm] = useState<boolean>(false);
  const [showEarlyTerminationDenialError, setShowEarlyTerminationDenialError] = useState<boolean>(false);
  const [showCancellationRequestBanner, setShowCancellationRequestBanner] = useState<boolean>(true);
  const [showSuccessCancellationRequestDenial, setShowSuccessCancellationRequestDenial] = useState<boolean>(false);
  const [showLeaseStillInEffectBanner, setShowLeaseStillInEffectBanner] = useState<boolean>(false);
  const [showCancelEarlyTerminationBanner, setShowCancelEarlyTerminationBanner] = useState<boolean>(false);
  const [reasonExplanation, setReasonExplanation] = useState<string>('');
  const [blocksNewRequestsUntil, setBlocksNewRequestsUntil] = useState<string>('');
  const [selectedEarlyTerminationDenialReason, setSelectedEarlyTerminationDenialReason] = useState<number | null>(null);
  const { mutate: updatePolicyCancellationRequest, isLoading: updatingCancellationRequest } = useUpdatePolicyCancellationRequest();

  const [revisions, setRevisions] = useState<DocumentRevisions>({
    data: [],
    meta: { current_page: 1, per_page: 10, total_count: 0, total_pages: 0 }
  });
  const leaseStartDate = initializeDate(lease_start_date);
  const leaseEndDate = initializeDate(lease_end_date);
  const coverageStartDate = initializeDate(data?.coverage_start_date);
  const coverageEndDate = initializeDate(data?.coverage_end_date);
  const notCanceledStatus = data?.status !== 'Canceled';

  const end_date = new Date(coverageEndDate);
  const begin_range = add(new Date(), { days: 45 });
  const end_range = add(new Date(), { days: 91 });
  const tooEarly = isBefore(end_date, begin_range);
  const tooLate = isAfter(end_date, end_range);
  const noNextPolicy = data?.next_policy_id === null;
  const noSentRenewalRequest = data?.has_renewal === false;
  const notMovingOut = data?.renewal?.non_renewal_reason === null;
  const noRenewBlockingReasons = data?.renewal?.renew_blocking_reasons === (null || undefined);
  const noRenewalObject = data?.renewal === null;

  const noRenewalAndBlockingReasons = noRenewalObject || (noRenewBlockingReasons && notMovingOut);
  const canSendRenewalRequest =
    !tooEarly && !tooLate && noNextPolicy && noSentRenewalRequest && noRenewalAndBlockingReasons && renewalRequests;
  const serviceFeesCharged = data?.service_fees_charged;
  const serviceFeesSentence = createServiceFeeLabel(data?.address_state || '', data?.service_fee_cents || '');
  const processingFeesCharged = data?.processing_fees_charged;
  const processingFeesSentence = createProcessingFeeLabel(
    data?.address_state || '',
    data?.processing_fee_cents || '',
    data?.upfront || false
  );

  const checkCancellationRequest = (trigger: boolean, status: string) => {
    refetch();
    setTriggerCancellation({
      trigger,
      status
    });
  };

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/v2/admin/renter_policies/${policyId}/document_revisions`);
        if (!ignore) {
          setRevisions(data);
        }
      } catch {
        console.error('error retrieving document revisions');
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [policyId]);

  const resetCancellationRequest = () => {
    setShowCancellationRequestModal(false);
    setShowFlatCancellationDenialForm(false);
    setShowEarlyTerminationDenialForm(false);
    setShowSuccessCancellationRequestDenial(false);
    setShowLeaseStillInEffectBanner(false);
    setShowCancelEarlyTerminationBanner(false);
    setReasonExplanation('');
    setBlocksNewRequestsUntil('');
    setSelectedEarlyTerminationDenialReason(null);
  }

  const handleConfirmCancellation = (cancellation_request) => {
    updatePolicyCancellationRequest(
      { status: 'accepted', id: cancellation_request.id },
      {
        onError: () => setError(true),
        onSuccess: () => {
          refetch();
          resetCancellationRequest();
          setShowCancellationRequestBanner(false);
          setShowCancellationApprovedTag(true);
        }
      }
    );
  };

  const handleDenyCancellation = (cancellation_request) => {
    setShowCancellationRequestBanner(false);

    if (!data?.cancellation_request?.early_termination) {
      setShowFlatCancellationDenialForm(true);
    } else {
      setShowLeaseStillInEffectBanner(true);
    }
  };

  const handleCancelFlatCancellationDenialForm = () => {
    resetCancellationRequest();
    setShowCancellationRequestBanner(true);
  }

  const handleDenyFlatCancellationRequest = (cancellation_request) => {
    updatePolicyCancellationRequest(
      {
        id: cancellation_request.id,
        status: 'declined',
        cancellation_request_denial_attributes: {
          reason: 'other',
          reason_explanation: reasonExplanation === '' ? null : reasonExplanation
        }
      },
      {
        onError: () => setError(true),
        onSuccess: () => {
          refetch();
          setShowSuccessCancellationRequestDenial(true);
          setShowCancellationRequestBanner(false);
          setShowFlatCancellationDenialForm(false);
        }
      }
    );
  }

  const handleOnChangeEarlyTerminationDenialReason = (value) => {
    setShowEarlyTerminationDenialError(false);
    setSelectedEarlyTerminationDenialReason(value);
    setReasonExplanation('');
    setBlocksNewRequestsUntil('');
  }

  const handleDenyEarlyTerminationCancellationRequest = (cancellation_request) => {
    const invalidOtherReason = Number(selectedEarlyTerminationDenialReason) == 0 && reasonExplanation == '';
    const invalidBlocksNewRequestsUntil = Number(selectedEarlyTerminationDenialReason) == 1 && blocksNewRequestsUntil == '';

    if (invalidOtherReason || invalidBlocksNewRequestsUntil) {
      setShowEarlyTerminationDenialError(true);
      return;
    }

    updatePolicyCancellationRequest(
      {
        id: cancellation_request.id,
        status: 'declined',
        cancellation_request_denial_attributes: {
          reason: Number(selectedEarlyTerminationDenialReason),
          reason_explanation: reasonExplanation === '' ? null : reasonExplanation,
          blocks_new_requests_until: blocksNewRequestsUntil === '' ? null : blocksNewRequestsUntil
        }
      },
      {
        onError: () => setError(true),
        onSuccess: () => {
          refetch();
          setShowSuccessCancellationRequestDenial(true);
          setShowCancellationRequestBanner(false);
          setShowEarlyTerminationDenialForm(false);
        }
      }
    );
  }

  const handleConfirmLeaseStillInEffectBanner = () => {
    setShowEarlyTerminationDenialForm(true);
    setShowLeaseStillInEffectBanner(false);
  }

  const handleCancelLeaseStillInEffectBanner = () => {
    setShowCancelEarlyTerminationBanner(true);
    setShowLeaseStillInEffectBanner(false);
  }

  const handleBackCancelEarlyTerminationBanner = () => {
    resetCancellationRequest();
    setShowCancellationRequestBanner(true);
  }

  const showPolicyDetails = !showSuccessCancellationRequestDenial && !showEarlyTerminationDenialForm

  // update colors once in shared-js
  return isSuccess ? (
    <>
      <NavBar referrer={referrer} status={data?.status} cancellationRequest={data?.cancellation_request} />
      {useNewCancellationsExperience && data?.cancellation_request && showCancelEarlyTerminationBanner && (
        <CancelEarlyTerminationBanner onConfirm={() => setShowCancellationRequestModal(true) } onBack={handleBackCancelEarlyTerminationBanner} />
      )}
      {useNewCancellationsExperience && data?.cancellation_request && showSuccessCancellationRequestDenial && (
        <SuccessCancellationRequestDenial cancellation_request={data!.cancellation_request} renterName={data?.tenant_full_name} referrer={referrer} />
      )}
      {useNewCancellationsExperience && data?.cancellation_request?.early_termination && showEarlyTerminationDenialForm && (
        <EarlyTerminationDenialForm
          cancellation_request={data!.cancellation_request}
          selectedReason={selectedEarlyTerminationDenialReason}
          reasonExplanation={reasonExplanation}
          blocksNewRequestsUntil={blocksNewRequestsUntil}
          onChange={(event) => handleOnChangeEarlyTerminationDenialReason(event.target.value)}
          onBlocksNewRequestsUntilChange={(event) => setBlocksNewRequestsUntil(event.target.value)}
          onReasonExplanationChange={(event) => setReasonExplanation(event.target.value)}
          onDeny={() => handleDenyEarlyTerminationCancellationRequest(data!.cancellation_request)}
          referrer={referrer}
          showError={showEarlyTerminationDenialError} />
      )}
      {showPolicyDetails && (
        <div>
          {data?.cancellation_request?.status === 'pending' && !triggerCancellation.trigger && (
            useNewCancellationsExperience ? (
              <>
                {showLeaseStillInEffectBanner && (
                  <LeaseStillInEffectBanner onConfirm={handleConfirmLeaseStillInEffectBanner} onCancel={handleCancelLeaseStillInEffectBanner} />
                )}
                {!data?.cancellation_request.early_termination && showFlatCancellationDenialForm && (
                  <FlatCancellationDenialForm
                    cancellationRequestReason={data!.cancellation_request?.reason_string}
                    reasonExplanation={reasonExplanation}
                    onReasonExplanationChange={(event) => setReasonExplanation(event.target.value)}
                    onCancel={handleCancelFlatCancellationDenialForm}
                    onDeny={() => handleDenyFlatCancellationRequest(data.cancellation_request)} />
                )}
                {showCancellationRequestModal && (
                  <CancellationRequestModal
                    onCancel={() => setShowCancellationRequestModal(false)}
                    onConfirm={() => handleConfirmCancellation(data.cancellation_request)}
                    isLoading={updatingCancellationRequest} />
                )}
                {showCancellationRequestBanner && !isListingAgent && !isThirdPartyAdmin && (
                  <CancellationRequestBanner
                    cancellation={data!.cancellation_request}
                    isCancellationError={isCancellationError}
                    renterName={data?.tenant_full_name}
                    onAccept={() => setShowCancellationRequestModal(true)}
                    onDeny={() => handleDenyCancellation(data.cancellation_request)}
                    isLoading={updatingCancellationRequest} />
                )}
              </>
            ) : (
              <ConfirmCancellation
                cancellation={data!.cancellation_request}
                setError={setError}
                isCancellationError={isCancellationError}
                isTriggered={checkCancellationRequest}
              />
            )
          )}
          {
            <div
              css={[
                bannerAlignment,
                {
                  paddingTop: '32px'
                }
              ]}
            >
              {status_detail &&
                status_detail.length > 0 &&
                status_detail.map((notif, index) => (
                  <InlineStatusBanner
                    key={`policy-details-inline-status-banner-${index}`}
                    statusDetail={notif}
                    style={{
                      margin: 'auto',
                      marginBottom: '24px',
                      borderRadius: '8px',
                      padding: '10px 24px',
                      minHeight: '48px',
                      display: 'inline-table'
                    }}
                  />
                ))}
              {pending_changeset && lease_start_date && lease_end_date && (
                <PendingChangesetBanner
                  changeset={camelize(pending_changeset)}
                  policyLeaseStartDate={leaseStartDate}
                  policyLeaseEndDate={leaseEndDate}
                />
              )}
            </div>
          }
          <div
            css={[
              bannerAlignment,
              {
                padding: '32px'
              }
            ]}
          >
            <h3 css={[FONTS.h3, { marginBottom: 16 }]}>{data?.tenant_full_name}</h3>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start'
              }}
            >
              <textarea
                css={[FONTS.p1Light, { border: 'none', width: '85%', resize: 'none' }]}
                readOnly
                value={data?.property_address}
              />
              <input
                css={[FONTS.p1Light, { border: 'none', width: '15%', textAlign: 'right' }]}
                readOnly
                value={data?.unit_name}
              />
            </div>
            <ActionButtonWrapper>
              {data?.can_edit && notCanceledStatus && (
                <Link to={urlWithReferrer(`${pathname}/edit`, rawReferrer)}>
                  <ActionButton id="PolicyEdit" variant="noIcon" usage="neutral" disabled={false} tabIndex={-1}>
                    Edit
                  </ActionButton>
                </Link>
              )}
              {data?.can_cancel && notCanceledStatus && (
                <Link to={urlWithReferrer(`${pathname}/cancel`, rawReferrer)}>
                  <ActionButton id="PolicyCancel" variant="noIcon" usage="neutral" disabled={false} tabIndex={-1}>
                    Cancel Policy
                  </ActionButton>
                </Link>
              )}
              {canSendRenewalRequest && (
                <Link to={urlWithReferrer(`/admin/invitations/policy_renewal`, policyId)}>
                  <ActionButton id="SendRenewalRequest" variant="noIcon" usage="neutral" disabled={false} tabIndex={-1}>
                    Send Renewal Request
                  </ActionButton>
                </Link>
              )}
              {data?.has_renewal && renewalRequests && (
                <Link to={urlWithReferrer(`/admin/invitations/policy_renewal`, policyId)}>
                  <ActionButton
                    id="RenewalRequestSent"
                    variant="noIcon"
                    usage="quiet"
                    disabled={true}
                    style={{ color: PALETTE.success125, textDecorationLine: 'underline' }}
                    tabIndex={-1}
                  >
                    Renewal Request Sent!
                  </ActionButton>
                </Link>
              )}
            </ActionButtonWrapper>

            <h4 css={[FONTS.h5, { marginBottom: 8 }, fieldMargin]}>Tenant</h4>
            <FormField css={[{ marginTop: 12 }, fieldMargin]}>
              <input id="email" name="email address" value={data?.tenant_email} readOnly />
              <label htmlFor="email">Email</label>
            </FormField>
            <FormField css={[{ marginTop: 12, marginBottom: 48 }, fieldMargin]}>
              <MaskedInput
                id="phone"
                name="phone number"
                value={data?.tenant_phone_number}
                mask="(999) 999-9999"
                maskChar=""
                readOnly
              />
              <label htmlFor="phone">Phone number</label>
            </FormField>
            <div
              css={[
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 16
                },
                fieldMargin
              ]}
            >
              <h4 css={[FONTS.h5]}>Policy Coverage</h4>
              <p css={[FONTS.p3Light, { textAlign: 'right' }]}>{data?.policy_number}</p>
            </div>
            {data && data.status.toLowerCase() !== 'incomplete' && (
              <>
                <DownloadBanner iconVisible onClick={() => startDownload(true)}>
                  <h5 css={[FONTS.p2Medium]}>Policy Document</h5>
                  <p css={[FONTS.p3Light, { color: PALETTE.neutral25 }]}>Created {data?.policy_created_at}</p>
                </DownloadBanner>

                {downloadingDocument && (
                  <ViewDocument
                    document_owner_id={Number(policyId)}
                    document_owner_type="renter_policies"
                    document_type={documentType(data)}
                    visible={false}
                    onDocumentDownloaded={() => startDownload(false)}
                  />
                )}
              </>
            )}
            <FormField css={[{ marginTop: 24 }, fieldMargin]}>
              <input
                id="leaseDates"
                name="lease dates"
                value={stringifyDateRange([leaseStartDate, leaseEndDate])}
                readOnly
              />
              <label htmlFor="leaseDates">Lease dates</label>
            </FormField>
            <FormField css={[{ marginTop: 12 }, fieldMargin]}>
              <input
                id="coverageDates"
                name="coverage dates"
                value={stringifyDateRange([coverageStartDate, coverageEndDate])}
                readOnly
              />
              <label htmlFor="coverageDates">Coverage dates</label>
            </FormField>
            <FormField css={[{ marginTop: 12 }, fieldMargin]}>
              <input id="monthlyRent" name="monthly rent" value={data?.monthly_rent} readOnly />
              <label htmlFor="monthlyRent">Monthly rent</label>
            </FormField>
            <FormField css={[{ marginTop: 12, flexWrap: 'wrap' }, fieldMargin]}>
              <InputWrapper>
                <input
                  name="rhino plan"
                  id="rhinoPlan"
                  value={
                    data?.upfront
                      ? `${data?.rhino_plan_in_full_rate} / in full`
                      : `${data?.rhino_plan_monthly_rate} / month`
                  }
                  readOnly
                ></input>
                {serviceFeesCharged && <span>{serviceFeesSentence}</span>}
                {processingFeesCharged && <span>{processingFeesSentence}</span>}
              </InputWrapper>
              <label htmlFor="rhinoPlan">Policy Price</label>
            </FormField>
            <FormField css={[{ marginTop: 12, marginBottom: 48 }, fieldMargin]}>
              <input
                id="coverageAmount"
                name="coverage amount"
                value={formatCoverageAmount(data?.coverage_details, data?.coverage_amount)}
                readOnly
              />
              <label htmlFor="coverageAmount">Coverage amount</label>
            </FormField>
            {revisions && revisions?.data?.length !== 0 && (
              <Link to={urlWithReferrer(`${pathname}/document_revisions`, rawReferrer)} css={documentLinkStyle}>
                View Document Revisions
              </Link>
            )}
          </div>
          {purchasedRentersInsuranceDetails && (
            <div>
              <RentersInsurancePolicyDetails
                tenantId={data?.tenant_id}
                stringifyDateRange={stringifyDateRange}
                propertyId={data?.property_id}
              />
            </div>
          )}
        </div>
      )}
    </>
  ) : (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Loading />
    </div>
  );
};

export default PolicyDetails;
