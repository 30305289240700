/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/react';
import FullScreenModal from './Modal';
import {
  StyledModal,
  StyledModalTitle,
  StyledModalSubtitle,
  ButtonGroup,
  StyledHeader
} from '../Claims/ClaimDetails/ClaimStyles';
import { ButtonHabitat } from '@sayrhino/rhino-shared-js';

interface IProps {
  title: string;
  subTitle: string | JSX.Element;
  confirmButtonText: string;
  isLoading?: boolean;
  isOpen: boolean;
  handleConfirm: () => void;
  closeModal: () => void;
}

const WarningModal = ({ title, subTitle, confirmButtonText, isOpen, handleConfirm, closeModal, isLoading }: IProps) => {
  return (
    <FullScreenModal
      isOpen={isOpen}
      closeModal={closeModal}
      children={
        <Fragment>
          <StyledModal>
            <StyledHeader>
              <StyledModalTitle>{title}</StyledModalTitle>
            </StyledHeader>
            <StyledModalSubtitle>{subTitle}</StyledModalSubtitle>
            <ButtonGroup>
              <ButtonHabitat
                id="never-mind-button"
                type="button"
                variant="interaction"
                size="small"
                usage="secondary"
                onClick={() => closeModal()}
                disabled={isLoading}
                aria-label="close"
              >
                Never mind
              </ButtonHabitat>
              <ButtonHabitat
                id="confirm-button"
                type="button"
                variant="interaction"
                size="small"
                onClick={handleConfirm}
                style={{ marginLeft: '8px' }}
                disabled={isLoading}
                aria-label="confirm"
              >
                {confirmButtonText}
              </ButtonHabitat>
            </ButtonGroup>
          </StyledModal>
        </Fragment>
      }
    />
  );
};

export default WarningModal;
