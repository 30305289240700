import axios from 'axios';
import { IBankAccountInfo } from 'components/v2/App/DepositFunds/types';
import { QueryObserverResult, useQuery } from 'react-query';

const getBankAccountDetails = async (id: string, accountType: string) => {
  const { data } = await axios.get<IBankAccountInfo>(`/admin/funds/accounts/${id}`, {
    params: {
      bank_account_uid: id,
      internal_account_type: accountType
    }
  });
  return data;
};

export default function useBankAccountDetails(
  id: string | undefined,
  accountType: string | null
): QueryObserverResult<IBankAccountInfo> | undefined {
  if (id && accountType) return useQuery<IBankAccountInfo>(['bank account details', id], () => getBankAccountDetails(id, accountType));
}
