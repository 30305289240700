import { IData, IPaginatedMeta, ISubrogationPayment, ISubrogationPaymentTable } from 'components/v2/App/interfaces';
import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useMutation, useQuery } from 'react-query';
import { csrfToken } from 'utils/document';
import { stringify } from 'qs';

export const SUBRO_PAYMENTS = 'subrogation_payments';

const getSubrogationPayments = async (id: number, page: number = 1) => {
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });
  const params = { page };
  const { data } = await axios.get<ISubrogationPaymentTable>(`/v2/admin/claims/${id}/subrogation_payments`, {
    params,
    paramsSerializer
  });

  return data;
};

export default function useSubrogationPayments(
  id: number,
  page: number = 1
): QueryObserverResult<ISubrogationPaymentTable> {
  return useQuery<ISubrogationPaymentTable>([SUBRO_PAYMENTS, id, page], () => getSubrogationPayments(id, page));
}

type Form = {
  paid_at: Date | string;
  recovered_amount_cents: number;
  processing_fee_cents: number;
  net_recovery_cents: number;
};

type ISubroData = {
  claimId: number;
  formInfo: Form;
  payoutID?: number;
};

const addSubroPayment = async ({ claimId, formInfo }: ISubroData) => {
  const authenticity_token = csrfToken();
  const { data } = await axios.post(`/v2/admin/claims/${claimId}/subrogation_payments`, formInfo, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });

  return data;
};

const updateSubroPayment = async ({ claimId, formInfo, payoutID }: ISubroData) => {
  const authenticity_token = csrfToken();
  const { data } = await axios.put(`/v2/admin/claims/${claimId}/subrogation_payments/${payoutID}`, formInfo, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });

  return data;
};

const deleteSubroPayment = async ({ claimId, payoutId }) => {
  const authenticity_token = csrfToken();
  const { data } = await axios.delete(`/v2/admin/claims/${claimId}/subrogation_payments/${payoutId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });

  return data;
};

export const useAddSubroPayment = () => {
  const { mutate: addPayment, ...rest } = useMutation(addSubroPayment);
  return {
    addPayment,
    ...rest
  };
};

export const useEditSubroPayment = () => {
  const { mutate: editPayment, ...rest } = useMutation(updateSubroPayment);
  return {
    editPayment,
    ...rest
  };
};

export const useDeleteSubroPayment = () => {
  const { mutate: deletePayment, ...rest } = useMutation(deleteSubroPayment);
  return {
    deletePayment,
    ...rest
  };
};
