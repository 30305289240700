import axios, { AxiosResponse } from 'axios';
import { ACCOUNT_ROUTES } from 'components/v2/App/utils/routeHelpers';
import { csrfToken } from 'utils/document';
import { useMutation, MutationFunction } from 'react-query';

type ITwoFactorEnableResponse = void | ITwoFactorEnableErrorResponse;

interface ITwoFactorEnableErrorResponse {
  status: string;
  errors: string;
}

const submitActivationCode: MutationFunction<AxiosResponse<ITwoFactorEnableResponse>> = async (otp_attempt: {
  otp_attempt: string;
}): Promise<AxiosResponse<ITwoFactorEnableResponse>> => {
  const authenticity_token = csrfToken();
  const response: AxiosResponse<ITwoFactorEnableResponse> = await axios.post(
    '/v2/users/account/two_factor/enable',
    { user: otp_attempt },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useSubmitActivationCodeMutation() {
  return useMutation(submitActivationCode);
}
