/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE, FONT_FAMILY, FONTS } from '@sayrhino/rhino-shared-js';

const DashboardPanelHeader = ({ children }) => (
  <h6
    css={[
      {
        borderBottom: `1px solid ${PALETTE.neutral12}`,
        padding: '16px 24px',
        color: PALETTE.neutral65,
        maxHeight: 60
      },
      FONTS.h5
    ]}
  >
    {children}
  </h6>
);

export default DashboardPanelHeader;
