import React from 'react';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import DownloadBanner from '../DownloadBanner';

interface IProps {
  set: IDocumentSet;
  documentType: string;
  fileViewer?: boolean;
  downloadDocument: (documentId: number, documentType: string) => void;
  handleFileViewerModal: (fileData: IDocumentSet, documentType: string) => void;
}
export interface IDocumentSet {
  document_id: number;
  state: string;
  created_or_expired_at: string;
  expired: boolean;
  carrier: string;
  policy_number: string;
}

const DocumentName = styled.p([FONTS.p2Medium]);
const DocumentDetails = styled.p([FONTS.p3Light], { color: PALETTE.neutral65 });
const DocumentContainer = styled(DownloadBanner)(
  {
    padding: '22px 24px !important',
    margin: '16px 0'
  },
  (props) =>
    props.fileViewer && {
      border: 'none !important',
      borderBottom: `1px solid ${PALETTE.neutral12} !important`,
      padding: '16px 0 20px 0 !important',
      borderRadius: '0 !important'
    }
);

const documentNameMapping = {
  declarations_page: 'Declarations Page (Landlord Policy)',
  policy_form: 'Policy Form (Landlord Policy)',
  schedule_a: 'Schedule A (Landlord Policy)',
  fl_surplus_lines_page: 'Fl Surplus Lines Page',
  sl1_form: 'SL-1 Form',
  sl2_form: 'SL-2 Form',
  complaint_notice: 'Complaint Notice',
  d2_form: 'D-2 Form',
  cbsic_signature_page_url: 'CBSIC Signature Page',
  cb_specialty_standard_privacy_statement: 'CB Specialty Standard Privacy Statement',
  amtrust_signature_page: 'Signature Page',
  amtrust_washington_service_of_suit: 'Washington Service of Suit'
};

export const getDocumentName = (docType: string, state: string | undefined): string => {
  if (state === 'California' && docType === 'complaint_notice') {
    return documentNameMapping['d2_form'];
  } else {
    return documentNameMapping[docType];
  }
};

const PropertyEntityDocuments: React.FC<IProps> = (props) => {
  const { documentType, downloadDocument, handleFileViewerModal, set, fileViewer } = props;
  const { created_or_expired_at, expired, document_id, state, carrier, policy_number } = set;

  const created_title = [carrier, state, `Created ${created_or_expired_at}`].filter((n) => n).join(' — ');

  return (
    <DocumentContainer
      onContainerClick={() => handleFileViewerModal(set, documentType)}
      onIconClick={() => downloadDocument(document_id, documentType)}
      fileViewer={fileViewer}
    >
      <DocumentName>{getDocumentName(documentType, state)}</DocumentName>
      <DocumentDetails>
        {expired ? `Expired ${created_or_expired_at}` : created_title}
        <br />
        {policy_number}
      </DocumentDetails>
    </DocumentContainer>
  );
};

export default PropertyEntityDocuments;
