import styled from '@emotion/styled';
import React from 'react';
import {
  FONTS,
  PALETTE,
  LegacyTag,
  Card,
  FONT_FAMILY,
  IndicatorIcon,
  DropdownMenu,
  CheckIcon,
  SquareButton
} from '@sayrhino/rhino-shared-js';
import { Link } from 'react-router-dom';
import {
  IClaimAmountProps,
  IPaidClaimAmountProps,
  IRemainingCoverageWrapperStyleProps,
  IRemainingCoverageAmountStyleProps,
  IProgressBarStyleProps,
  EditActionButtonProps,
  WithdrawActionButtonProps,
  IClaimAdjustedAmountProps,
  AttachmentListProps,
  AttachmentFileNameProps,
  AttachmentFileItemProps
} from '../../interfaces';

const getMoneyStyle = (amount: number) => (amount === 0 ? [FONTS.p2] : [FONTS.p2Medium]);

export const ClaimsDetailWrapper = styled.div({ marginBottom: 50 });
export const RemainingCoverageWrapper = styled.div<IRemainingCoverageWrapperStyleProps>(({ remainingCoverage }) => [
  ...getMoneyStyle(remainingCoverage),
  { color: PALETTE.neutralDark }
]);

export const StyledRemainingCoverageSpan = styled.span<IRemainingCoverageAmountStyleProps>(({ remainingCoverage }) => [
  ...getMoneyStyle(remainingCoverage),
  { color: PALETTE.interaction100 }
]);

export const StyledInnerProgressSpan = styled.span<IProgressBarStyleProps>(({ remainingPercentOfCoverage }) => ({
  display: 'block',
  height: '24px',
  padding: '5px',
  width: `${remainingPercentOfCoverage}%`,
  backgroundColor: `${remainingPercentOfCoverage > 0 ? PALETTE.brand125 : PALETTE.brand25}`,
  color: PALETTE.brand25
}));

export const StyledClaimAmountDiv = styled.div<IClaimAmountProps>(({ amount_cents }) => [
  ...getMoneyStyle(amount_cents),
  { color: PALETTE.interaction100 }
]);

export const StyledPaidClaimAmountDiv = styled.div<IPaidClaimAmountProps>(({ total_amount_paid_cents }) => [
  ...getMoneyStyle(total_amount_paid_cents),
  { color: PALETTE.interaction100 }
]);

export const StyledClaimDetailsItem = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const ClaimDetailsItem = ({ children }) => <StyledClaimDetailsItem>{children}</StyledClaimDetailsItem>;

export const StyledClaimDescription = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'end'
});

export const ClaimDescription = ({ children }) => <StyledClaimDescription>{children}</StyledClaimDescription>;

export const Splitter = styled.hr({ marginTop: 14, marginBottom: 14 });

export const StyledClaimTag = styled(LegacyTag)([
  FONTS.h6Demi,
  {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto'
  }
]);

export const AttachmentTag = styled.p([
  FONTS.p3Light,
  {
    color: PALETTE.neutral65,
    width: '40px',
    height: '20px',
    marginTop: '8px'
  }
]);

export const StyledNewClaimTag = styled(LegacyTag)([
  FONTS.h6Medium,
  {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '28px',
    height: 'auto',
    padding: '2px 4px 2px 8px'
  }
]);

export const StatusWrapper = styled.div({ marginRight: '.25rem' });

export const ClaimDetailWrapper = styled.div({
  borderBottom: `1px solid ${PALETTE.neutral4}`,
  backgroundColor: PALETTE.neutralLight,
  height: '3.75rem',
  padding: '20px 48px',
  position: 'sticky',
  'z-index': 1,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const StyledIndicatorIcon = styled(IndicatorIcon)({ color: PALETTE.brand88, height: '20px', width: '20px' });

export const StyledLabelDiv = styled.div([FONTS.p3Medium, { color: PALETTE.neutral65 }]);
export const StyledDescriptionDiv = styled.div([FONTS.p2], { wordBreak: 'break-word'});

export const StyledReportedLossLabelDiv = styled.div([FONTS.p3Medium, {
  color: PALETTE.neutral65,
  paddingRight: '8px'
}]);

export const ItemizedLossesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '16px',
  paddingLeft: '16px',
});

export const ItemizedLossesHeader = styled.div([FONTS.p3Medium, {
  color: PALETTE.neutral65,
  paddingBottom: '16px'
}]);

export const LossItemWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export const LossItemLabelWrapper = styled.div([FONTS.p3, {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  cursor: 'default'
}])

export const LossItemValueWrapper = styled.div([FONTS.p3])

export const ApprovedAmountDiv = styled.div([FONTS.p2], {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '0.5rem',
});

export const ApprovedAmountMainLine = styled.div([FONTS.p2], {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0.7rem',
});

export const ApprovedAmountEditor = styled.div([FONTS.p2], {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0.5rem',
});

export const ApprovedAmountWarningLine = styled.div([FONTS.p2], {
  display: 'flex',
  justifyContent: 'center',
  gap: '0.5rem',
});

export const ApprovedAmountWarningMessage = styled.span([FONTS.p3]);

export const P2Font = styled.div([FONTS.p2]);

export const StyledBarSpan = styled.span({ width: '100%', display: 'block', backgroundColor: PALETTE.brand25 });

export const StyledProgressBarDiv = styled.div({
  width: '100%',
  overflow: 'hidden',
  borderRadius: '40px',
  marginTop: '13px',
  marginBottom: '24'
});

export const StyledPolicyNumberDiv = styled.div([FONTS.p2, { color: PALETTE.interaction100 }]);

export const AttachmentButtonContainer = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'row-reverse'
});

export const StyledCaretDiv = styled.div({
  borderRadius: '100%',
  width: '24px',
  height: '24px',
  background: PALETTE.neutralLight,
  border: `1px solid ${PALETTE.neutral25}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const StyledCaretReportedLossDiv = styled.div({
  border: 'none',
  width: '24px',
  height: '24px',
  background: PALETTE.neutralLight,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const ClaimInfoWrapper = styled.p([
  FONTS.p2,
  { color: `${PALETTE.neutral88}`, textTransform: 'capitalize', marginRight: '4px' }
]);

export const AdminEditWrapper = styled.div({
  display: 'flex'
});

export const OtherClaimsWrapper = styled.h6([FONTS.h6Demi, { textTransform: 'capitalize', marginBottom: 14 }]);

export const StyledBadgeDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '28px',
  border: `1px solid ${PALETTE.neutral12}`,
  padding: '0px 3px 0px 8px',
  marginLeft: '12px'
});

export const StyledInnerBadgeDiv = styled.div({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  background: PALETTE.brand88
});

export const StyledOuterBadgeDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  background: '#F0E8FA'
});

export const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
});

export const OtherClaimsBreak = styled.hr({
  marginTop: 13,
  marginBottom: 20,
  marginLeft: -16,
  marginRight: -16
});

export const ClaimTitleWrapper = styled.h4([FONTS.h4, { marginBottom: '1.5rem', textTransform: 'capitalize' }]);

export const StyledCardDetails = styled(Card)({
  padding: '1rem',
  height: 'auto !important',
  overflow: 'hidden',
  cursor: 'auto',
  marginBottom: 24
});

export const StyledCardDetailsHeaderDiv = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.5rem'
});

export const StyledClaimTypeClaimNumber = styled.h5([FONTS.h5, { textTransform: 'capitalize' }]);

export const StyledSeeMoreSeeLessButton = styled.button({
  display: 'flex',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  padding: 0
});

export const StyledSeeMoreSeeLessReportedLossButton = styled.button({
  display: 'flex',
  cursor: 'pointer',
  background: 'none',
  border: 'none !important',
  padding: 0
});

export const StyledSeeMoreDiv = styled.div([FONTS.p2, { marginRight: '.5rem' }]);

export const ClaimDetailViewWrapper = styled.div({ display: 'flex', alignItems: 'center' });

export const StyledEditButtonLink = styled(Link)({
  color: PALETTE.neutralLight,
  backgroundColor: PALETTE.neutralDark,
  border: 'none',
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 50
});

export const StyledUserInitialsP = styled.p([
  FONTS.p3,
  {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: `1px solid ${PALETTE.neutral12}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: PALETTE.neutralLight,
    color: PALETTE.neutralDark
  }
]);

export const StyledAssigneeInitialsP = styled.p([
  FONTS.p3,
  {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    border: `1px solid ${PALETTE.neutral88}`,
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: PALETTE.brand4,
    color: PALETTE.neutralDark
  }
]);

export const ClaimDetailIndividualWrapper = styled.div({ padding: '1.5rem 0', margin: 'auto', width: 480 });

export const StyledReferrerLink = styled(Link)({ display: 'flex', alignItems: 'center', marginRight: 48 });

export const StyledCopyLinkButton = styled.button({
  background: 'none',
  border: 'none',
  display: 'flex',
  alignItems: 'center'
});

export const StyledCopyConfirmationSpan = styled.span({ color: PALETTE.success125, marginLeft: 12 });

export const StyledDateCreatedSpan = styled.span([FONTS.p3, { color: PALETTE.neutral55, marginRight: '.75rem' }]);

export const DateCreatedWrapper = styled.div({ display: 'flex', alignItems: 'center' });

export const StyledPolicyNumberAnchor = styled.a({ color: PALETTE.interaction100, textDecoration: 'underline' });

export const DropdownMenuSplitter = styled.hr({ marginTop: 8, marginBottom: 8 });
export const DropdownItem = styled(DropdownMenu.Item)(
  (props) => ({
    fontWeight: props.disabled ? 'bold' : 'normal'
  }),
  FONT_FAMILY.light
);
export const DropdownContent = styled(DropdownMenu.Content)({ overflow: 'auto', height: '250px' });

export const DrawerModalHeader = styled.h3(FONTS.h5);
export const DrawerModalText = styled.p(FONTS.p2);
export const DrawerModalFooter = styled.div({ display: 'flex', justifyContent: 'flex-end' });
export const SelectedDropdownCheckmark = styled(CheckIcon)({
  paddingLeft: '3px',
  paddingTop: '4px'
});
SelectedDropdownCheckmark.defaultProps = { strokeWidth: '2', height: '19', width: '19' };

// Subro card Styles
export const StyledSubroHeaderDiv = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.5rem'
});
export const StyledSubroText = styled.h5([FONTS.h5, { textTransform: 'capitalize' }]);
export const StyledEditPaymentLink = styled(Link)([
  FONTS.h6,
  { justifyContent: 'space-between', color: PALETTE.interaction100 }
]);
export const StyledSubroSplitter = styled.div({ marginTop: '24px' });
export const StyledEditPaymentDiv = styled.div({ textAlign: 'center' });
interface ITenantNotificationProps {
  isTenantNotified: boolean;
}

export const TenantNotification = styled.div<ITenantNotificationProps>(({ isTenantNotified }) => [
  FONTS.p2,
  {
    color: isTenantNotified ? PALETTE.neutralDark : PALETTE.neutral55,
    margin: '24px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
]);
export const Icon = styled.div<ITenantNotificationProps>(({ isTenantNotified }) => ({
  border: '1px solid',
  borderRadius: '12px',
  marginRight: 8,
  borderColor: isTenantNotified ? PALETTE.neutralDark : PALETTE.neutral55,
  height: 20,
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const SectionDivider = styled.div({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  width: '100%',
  margin: '12px 0'
});

export const SectionHeader = styled.h3(FONTS.h5, { paddingBottom: 32 });
export const NotesFormWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 32,
  marginTop: 5
});
export const AttachmentLabel = styled.label([
  FONTS.h6Medium,
  {
    textDecorationLine: 'underline',
    color: PALETTE.interaction100,
    '&:hover': {
      cursor: 'pointer'
    }
  }
]);

export const NotesSubmitButton = styled(SquareButton)({ width: '110px' });

export const modalAttachmentCSS = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '30px 24px',
  width: '100%',
  borderBottom: '1px solid ' + PALETTE.neutral12,
  alignItems: 'center'
};

export const attachmentCSS = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px',
  marginTop: 12,
  width: '100%',
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: 4,
  backgroundColor: PALETTE.neutralLight
};

export const AttachmentSectionDivider = styled.hr({
  bordeTop: '1px solid' + PALETTE.neutral12,
  margin: '0px'
});

export const FileIconDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer'
  }
});

export const FileIconDivCSS = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer'
  }
};

export const FileName = styled.button<AttachmentFileNameProps>(
  {
    width: 325,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: 0,
    backgroundColor: 'transparent',
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:active': {
      outlineStyle: 'auto'
    },
    '&:focus': {
      outlineStyle: 'auto'
    }
  },
  ({ isSelected }) =>
    isSelected && {
      fontWeight: 'bold'
    }
);

export const FileListItem = styled.div<AttachmentFileItemProps>(
  {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: PALETTE.interaction4
    }
  },
  ({ isModalOpen }) => (isModalOpen ? modalAttachmentCSS : attachmentCSS),
  ({ isSelected }) =>
    isSelected && {
      border: `2px solid ${PALETTE.interaction100}`,
      borderRadius: '8px'
    }
);

export const FileNameLink = styled(Link)({
  width: 325,
  textOverflow: 'ellipsis',
  textDecoration: 'none',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  border: 0,
  backgroundColor: 'transparent',
  textAlign: 'left',
  '&:hover': {
    cursor: 'pointer'
  },
  '&:active': {
    outlineStyle: 'auto'
  },
  '&:focus': {
    outlineStyle: 'auto'
  }
});

export const ImageTag = styled.img({
  maxWidth: '100%',
  height: 'auto'
});

export const FileTag = styled.embed({
  width: '100%',
  height: '100%'
});

export const RemoveButton = styled.button({
  border: 'none',
  background: 'transparent',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center'
});

export const AttachmentNameDivCSS = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '60px',
  '&:hover': {
    cursor: 'pointer'
  }
};

export const AttachmentList = styled.div<AttachmentListProps>(({ isModal }) =>
  isModal ? { overflow: 'auto', maxHeight: '60vh' } : { marginBottom: 20 }
);

export const NotesLabel = styled.label({ display: 'none' });

export const StyledAttachmentCopy = styled.p([FONTS.p2Light]);

//ApprovedClaimAmountStyles

export const StyledSummaryCard = styled(Card)({
  background: PALETTE.neutral4,
  height: '116px',
  alignItems: 'center',
  padding: '24px 16px',
  cursor: 'default',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none'
  },
  marginBottom: '24px'
});

export const ApprovableHeaderWrapper = styled.div({
  alignItems: 'center',
  marginBottom: '12px'
});
export const StyledApprovableHeader = styled.h5([
  FONTS.h5,
  {
    fontSize: '16px',
    fontStyle: 'none'
  }
]);

export const StyledApprovableAmount = styled.p<IClaimAdjustedAmountProps>(({ adjusted_amount_cents }) => [
  ...getMoneyStyle(adjusted_amount_cents),
  FONTS.p2Medium,
  { color: PALETTE.interaction100 }
]);

export const StyledModal = styled.div({ padding: '16px 24px 16px 24px', position: 'relative' });

export const StyledModalTitle = styled.h4([FONTS.h4, { color: PALETTE.neutral88 }]);

export const StyledModalSubtitle = styled.p([FONTS.p2Light, { marginTop: '24px', marginBottom: '48px' }]);

export const ButtonGroup = styled.div({ display: 'flex', justifyContent: 'flex-end' });

export const StyledHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '20px',
  paddingTop: '16px',
  borderBottom: `1px solid ${PALETTE.neutral4}`
});

export const StyledDiv = styled.div({
  marginTop: '1.5rem'
});

export const CloseButtonDiv = styled.div({
  float: 'right'
});

export const CloseIconButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 24,
  width: 24,
  backgroundColor: PALETTE.neutral4,
  borderRadius: 75,
  border: 'none',
  cursor: 'pointer'
});

export const PrimaryButton = styled.button([
  FONTS.p2Medium,
  {
    borderRadius: '24px',
    padding: '4px 20px',
    color: PALETTE.neutralLight,
    border: `1px solid ${PALETTE.interaction100}`,
    background: PALETTE.interaction100
  }
]);

export const SecondaryButton = styled(PrimaryButton)({
  border: `1px solid ${PALETTE.interaction125}`,
  color: PALETTE.interaction125,
  background: PALETTE.neutralLight
});

const rowPadding = { padding: '24px 0px 26px 0px' };
export const StyledTableContainer = styled.div({ padding: '64px 48px 0px 48px' });
export const StyledTableFrame = styled.table({
  width: '486px'
});
export const StyledTableHeader = styled.div({ borderBottom: `solid 2px ${PALETTE.neutralDark}`, width: '486px' });
export const StyledTableTitle = styled.h3([FONTS.h5, { paddingBottom: '12px' }]);
export const StyledRow = styled.tr(rowPadding, {
  borderBottom: `solid 1px ${PALETTE.neutral12}`,

  ':hover': {
    cursor: 'pointer',
    backgroundColor: PALETTE.interaction4
  }
});
export const StyledCell = styled.td([
  FONTS.p2Light,
  { minWidth: '60px', paddingBottom: '26px', paddingTop: '24px', paddingRight: '16px', margin: '20px 0' }
]);
export const StyledColumnHeader = styled.th([
  FONTS.p3Light,
  { minWidth: '60px', paddingTop: '12px', paddingRight: '16px', textAlign: 'left' }
]);
export const StyledHeaderRow = styled.tr(rowPadding);
export const StyledTableFooter = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  padding: '24px 25% 16px'
});

export const StyledAddNewRowLink = styled.a([FONTS.p3Medium, { color: PALETTE.interaction100 }]);

export const StyledEditCell = styled(StyledCell)({
  width: '10%'
});

export const StyledOtherClaimTag = styled(LegacyTag)([
  FONTS.h6Medium,
  {
    color: PALETTE.neutral88,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    height: 'auto',
    fontWeight: '400',
    textTransform: 'capitalize',
    marginLeft: '4px'
  }
]);

export const ActionButtonWrapper = styled.div({
  display: 'flex',
  gap: 8,
  marginBottom: 24
});
