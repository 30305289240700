import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { TabProps } from './Types';

export const StyledTab = styled.li<TabProps>(({ selected }) => [
  selected ? FONTS.h6Demi : FONTS.p2Light,
  {
    borderBottom: selected ? `1px solid ${PALETTE.neutralDark}` : 'none',
    display: 'block',
    margin: '25px 40px -1px 0',
    paddingBottom: '25px',
    cursor: 'pointer'
  }
]);

export const StyledTabList = styled.ul({
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  display: 'flex',
  paddingLeft: '48px'
});
