/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import { Loading, TextInput, DropdownMenu, CaretIcon, FONTS } from '@sayrhino/rhino-shared-js';
import { Link, useNavigate } from 'react-router-dom';
import {
  ButtonGroup,
  SaveButton,
  LoadingWrapper,
  HeaderWrapper,
  StyledCloseButton,
  HeaderText
} from '../../PropertyOwners/Styled';
import { FormMode } from '../../interfaces';
import _ from 'lodash';
import { useWithdrawalForm } from './useWithdrawalForm';
import { DropDownHeader, RoundWrapper } from '../../Claims/CreateClaim/Styled';
import { Splitter } from '../../Claims/ClaimDetails/ClaimStyles';
import { IBankAccountInfo } from '../types';
import useUserAccountBalance from 'api/v2/useUserAccountBalance';
import { formatMoney } from 'utils/money';

const StyledTextInput = styled(TextInput)({ marginTop: '24px' });
export const StyledHeaderText = styled(HeaderText)({ marginBottom: '16px' });

const StyledContent = styled.div({
  padding: '3rem'
});

const StyledDropdownContent = styled(DropdownMenu.Content)({
  minWidth: 480
});

const StyledTitle = styled.h3({
  fontSize: FONTS.h3,
  marginBottom: 20
});

const WithdrawalForm = () => {
  const navigate = useNavigate();
  const {
    withdrawal,
    bankAccount,
    setBankAccount,
    setWithdrawal,
    errors,
    isLoading,
    formMode,
    handleSubmit,
    isLoadingSubmitForm,
    bankAccounts
  } = useWithdrawalForm();
  const { data: accountBalance } = useUserAccountBalance();
  const isEditMode: boolean = formMode === FormMode.EDIT;

  const handleAmountInCentsChanged = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    setWithdrawal((acc) => ({
      ...acc,
      amount_in_cents: parseInt(numericValue)
    }));
  };

  const handleAccountSelect = (account: IBankAccountInfo) => {
    setWithdrawal((withdrawal) => ({
      ...withdrawal,
      user_bank_account_uid: account.bank_account_uid,
      user_bank_account_type: account.internal_account_type,
      account_uid: account.account_uid
    }));
    setBankAccount((bankAccount) => ({
      ...bankAccount,
      ...account
    }));
  };
  const formattedAmount = formatMoney(withdrawal?.amount_in_cents ?? '0').maskedValue;

  const totalAvailableToWithdraw = React.useMemo(() => accountBalance?.free_balance_in_cents ?? 0, [accountBalance]);
  const isWithdrawalAmountInvalid = (withdrawal.amount_in_cents ?? 0) > totalAvailableToWithdraw;

  const isFormValid = React.useCallback(() => {
    if (!withdrawal) return false;
    if (!withdrawal.amount_in_cents) return false;
    if (isWithdrawalAmountInvalid) return false;

    return [withdrawal.user_bank_account_uid, withdrawal.user_bank_account_type].every(
      (value) => value !== null && value !== '' && value !== undefined
    );
  }, [withdrawal]);

  const accounts = bankAccounts.map((account) => (
    <DropdownMenu.Item
      key={account.bank_account_uid}
      onSelect={() => {
        handleAccountSelect(account);
      }}
    >
      {account.bank_name}, {account.account_number}
    </DropdownMenu.Item>
  ));
  return (
    <React.Fragment>
      <HeaderWrapper>
        <StyledCloseButton onClick={() => navigate(-1)} aria-label="leave bank account form" />
        <StyledHeaderText></StyledHeaderText>
      </HeaderWrapper>

      {isLoading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <form css={{ margin: '36px auto', width: '576px' }} onSubmit={handleSubmit}>
          <React.Fragment>
            <StyledContent>
              {isEditMode ? (
                <React.Fragment>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: '1.875rem'
                    }}
                  >
                    <h2 css={[FONTS.h3]}>
                      {bankAccount.bank_name} | {bankAccount?.account_number}
                    </h2>
                    <h2 css={[FONTS.h3]}>{formattedAmount}</h2>
                  </div>
                  <Splitter />
                  <StyledTitle css={[FONTS.h3]}>Edit Withdrawal</StyledTitle>
                </React.Fragment>
              ) : (
                <StyledTitle css={[FONTS.h3]}>Withdraw Funds</StyledTitle>
              )}
              <DropdownMenu.Root>
                <DropDownHeader
                  css={{
                    width: '100% !important'
                  }}
                >
                  {bankAccount.bank_name
                    ? `${bankAccount.bank_name} ${bankAccount.account_number}`
                    : 'Select Bank Account*'}
                  <RoundWrapper>
                    <CaretIcon width={10} height={10} />
                  </RoundWrapper>
                </DropDownHeader>
                <StyledDropdownContent>
                  {accounts}
                  <Link to="/admin/funds/accounts/new">
                    <span
                      css={{
                        padding: 10,
                        display: 'block',
                        margin: '10px 10px',
                        color: '#6B3BF6',
                        textAlign: 'center',
                        border: '1px solid'
                      }}
                    >
                      Add new bank account
                    </span>
                  </Link>
                </StyledDropdownContent>
              </DropdownMenu.Root>
              <StyledTextInput
                id="amount_in_cents"
                label="Amount to withdraw*"
                placeholder="0.00"
                subtext={
                  errors?.amount_in_cents
                    ? errors.amount_in_cents
                    : `You have ${formatMoney(totalAvailableToWithdraw).maskedValue} available to withdraw. ${
                        isWithdrawalAmountInvalid ? `You don't have enough funds to withdraw ${formattedAmount}.` : ''
                      }`
                }
                error={!!errors?.amount_in_cents || isWithdrawalAmountInvalid}
                name="amount_in_cents"
                onChange={handleAmountInCentsChanged}
                value={formattedAmount}
              />
            </StyledContent>

            <ButtonGroup aria-label="submit">
              <SaveButton
                id="submit-property-owner-button"
                variant="interaction"
                type="submit"
                children={`${formMode === FormMode.EDIT ? 'Update' : 'Submit'} Withdrawal`}
                disabled={isLoadingSubmitForm || isLoading || !isFormValid()}
              />
            </ButtonGroup>
          </React.Fragment>
        </form>
      )}
      {isLoadingSubmitForm ? (
        <div
          css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: '8px' }}
        >
          <Loading />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default WithdrawalForm;
