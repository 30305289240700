/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Loading, TextInput, Radio, FONTS } from '@sayrhino/rhino-shared-js';
import { useNavigate } from 'react-router-dom';
import {
  ButtonGroup,
  StyledCloseButton,
  HeaderWrapper,
  HeaderText,
  SaveButton,
  LoadingWrapper,
  FormLabel,
  PhoneInputWrapper
} from '../../PropertyOwners/Styled';
import { useBankAccountForm } from './useBankAccountForm';
import { FormMode } from '../../interfaces';
import { RadioRoot, SelectorWrapper } from '../../PropertyUpload/singleProperty/Styles';
import SwitchSelector from '../../PropertyUpload/singleProperty/coverageSelector';
import _ from 'lodash';
import { PhoneInput } from 'react-international-phone';

const StyledTextInput = styled(TextInput)({ marginTop: "auto" });
const StyledHeaderText = styled(HeaderText)({ marginBottom: '16px' });

const StyledContent = styled.div({
  padding: '3rem'
});

enum BankAccountTypes {
  CHECKING = 'checking',
  SAVINGS = 'savings'
}

const BankAccountForm = () => {
  const navigate = useNavigate();
  const { account, setAccount, errors, isLoading, formMode, handleSubmit } = useBankAccountForm();

  const isEditMode: boolean = formMode === FormMode.EDIT;

  const handleFormChange = (e) => {
    setAccount((acc) => ({
      ...acc,
      [e.target.name]: e.target.value
    }));
  };
  
  const requiredBankAccountFields = [
    'bank_name',
    'account_number',
    'contact_phone_number',
    'first_name_on_account',
    'last_name_on_account',
    'account_type'
  ];

  const requiredUsDomesticFields = ['ach'];

  const requiredInternationalAccountFields = [
    'recipient_address_state',
    'recipient_address_city',
    'recipient_postal_code',
    'bic_swift_code',
    'recipient_address_street'
  ];

  const [phoneNumberCountry, setPhoneNumberCountry] = useState('us');

  // if the phone number is a us phone number, check that it has 17 characters
  // since it will be in format: '+1 (xxx) xxx-xxxx' for the PhoneInput component)
  // otherwise, we won't currently validate whether the phone number has the correct form
  const phoneValid = phoneNumberCountry !== 'us' || account?.contact_phone_number?.length === 17;

  const isFormValid = React.useCallback(() => {
    const bankAccountFields =
      account.internal_account_type === 'us_domestic'
        ? Object.values(_.pick(account, [...requiredUsDomesticFields, ...requiredBankAccountFields]))
        : Object.values(_.pick(account, [...requiredBankAccountFields, ...requiredInternationalAccountFields]));

    return phoneValid && bankAccountFields.every((value) => value !== null && value !== '');
  }, [account, phoneNumberCountry]);

  return (
    <React.Fragment>
      <HeaderWrapper>
        <StyledCloseButton onClick={() => navigate(-1)} aria-label="leave bank account form" />
        <StyledHeaderText>{isEditMode ? 'Edit' : 'Add'} Bank Account</StyledHeaderText>
      </HeaderWrapper>

      {isLoading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <React.Fragment>
          <StyledContent>
            <SelectorWrapper>
              <SwitchSelector
                active={account.internal_account_type}
                list={[
                  { label: 'Domestic Account', value: 'us_domestic' },
                  { label: 'International Account', value: 'international' }
                ]}
                disabled={isEditMode}
                id="internal_account_type"
                onClick={(value) =>
                  setAccount((prev) => ({
                    ...prev,
                    internal_account_type: value
                  }))
                }
              />
            </SelectorWrapper>
            <StyledTextInput
              id="bank_name"
              onChange={handleFormChange}
              label="Bank Name"
              subtext={errors.bank_name}
              error={!!errors.bank_name}
              name="bank_name"
              value={account.bank_name}
              required
            />
            <StyledTextInput
              id="nickname"
              label="Nickname (optional)"
              subtext={errors.nickname}
              error={!!errors.nickname}
              name="nickname"
              onChange={handleFormChange}
              value={account.nickname}
            />
            <StyledTextInput
              id="account_number"
              label="Account Number"
              subtext={errors.account_number}
              error={!!errors.account_number}
              name="account_number"
              onChange={handleFormChange}
              value={account.account_number}
            />
            {account.internal_account_type === 'us_domestic' && (
              <React.Fragment>
                <StyledTextInput
                  id="primary"
                  label="Ach Routing Number"
                  onChange={handleFormChange}
                  name="ach"
                  subtext={errors.ach}
                  error={!!errors.ach}
                  required
                  value={account.ach}
                />
              </React.Fragment>
            )}
            <p css={[FONTS.p1Medium, { marginBottom: 20 }]}>Account type</p>
            <RadioRoot
              name="account_type"
              value={account.account_type}
              onValueChange={(value: BankAccountTypes) => {
                setAccount({
                  ...account,
                  account_type: value
                });
              }}
              label="Account type"
              subtext={errors.account_type}
              error={!!errors.account_type}
              css={{
                marginBottom: 20
              }}
            >
              <Radio.Option value={BankAccountTypes.CHECKING} label="Checking" />
              <Radio.Option value={BankAccountTypes.SAVINGS} label="Savings" />
            </RadioRoot>

            <StyledTextInput
              id="first_name_on_account"
              name="first_name_on_account"
              label="Recipient First Name"
              subtext={errors.first_name_on_account}
              error={!!errors.first_name_on_account}
              onChange={handleFormChange}
              value={account.first_name_on_account}
              required
            />
            <StyledTextInput
              id="last_name"
              label="Recipient Last Name"
              subtext={errors.last_name_on_account}
              error={!!errors.last_name_on_account}
              onChange={handleFormChange}
              value={account.last_name_on_account}
              name="last_name_on_account"
              required
            />

            {account.internal_account_type === 'international' && (
              <React.Fragment>
                <StyledTextInput
                  id="swift_code"
                  label="BIC/Swift/Transfer No./Bank ID"
                  subtext={errors.bic_swift_code}
                  error={!!errors.bic_swift_code}
                  onChange={handleFormChange}
                  name="bic_swift_code"
                  required
                  value={account.bic_swift_code}
                />

                <StyledTextInput
                  id="recipient_country"
                  label="Country"
                  subtext={errors.recipient_country}
                  error={!!errors.recipient_country}
                  onChange={handleFormChange}
                  name="recipient_country"
                  required
                  value={account.recipient_country}
                />
                <StyledTextInput
                  id="recipient_address_state"
                  label="State"
                  subtext={errors.recipient_address_state}
                  error={!!errors.recipient_address_state}
                  onChange={handleFormChange}
                  name="recipient_address_state"
                  required
                  value={account.recipient_address_state}
                />
                <StyledTextInput
                  id="recipient_address_city"
                  label="City"
                  subtext={errors.recipient_address_city}
                  error={!!errors.recipient_address_city}
                  onChange={handleFormChange}
                  name="recipient_address_city"
                  required
                  value={account.recipient_address_city}
                />
                <StyledTextInput
                  id="recipient_address_street"
                  label="Street"
                  subtext={errors.recipient_address_street}
                  error={!!errors.recipient_address_street}
                  onChange={handleFormChange}
                  name="recipient_address_street"
                  required
                  value={account.recipient_address_street}
                />
                <StyledTextInput
                  id="recipient_postal_code"
                  label="Post Code"
                  subtext={errors.recipient_postal_code}
                  error={!!errors.recipient_postal_code}
                  onChange={handleFormChange}
                  name="recipient_postal_code"
                  required
                  value={account.recipient_postal_code}
                />
              </React.Fragment>
            )}
            <FormLabel>Recipient Phone Number</FormLabel>
            <PhoneInputWrapper>
              <PhoneInput
                defaultCountry="us"
                className="phone-input-container"
                inputClassName="phone-number phone-input"
                countrySelectorStyleProps={{ buttonClassName: 'phone-input'}}
                value={account.contact_phone_number}
                onChange={(phone, country) => {
                  setAccount({
                    ...account,
                    contact_phone_number: phone
                  });
                  setPhoneNumberCountry(country);
                }}
              />
            </PhoneInputWrapper>
          </StyledContent>

          <ButtonGroup aria-label="submit">
            <SaveButton
              id="submit-property-owner-button"
              variant="interaction"
              type="submit"
              children={`${formMode === FormMode.EDIT ? 'Update' : 'Add'} bank account`}
              onClick={handleSubmit}
              disabled={isLoading || !isFormValid()}
            />
          </ButtonGroup>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BankAccountForm;
