/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Tag, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { FC, Fragment } from 'react';
import { QueryObserverResult } from 'react-query';
import { IFilters, IStaticFilterOptions } from '../interfaces';
import { useSegmentUser } from '../utils/segmentTracker';
import { newSelections, trackFilters } from './index';
import styled from '@emotion/styled';

const StyledTag = styled(Tag)({
  '&&': {
    lineHeight: 'initial',
    marginRight: '12px'
  }
});

const StateFilterOptions: FC<{
  query: () => QueryObserverResult<IStaticFilterOptions>;
  selectedStates: string[];
  setFilters: (args: Partial<IFilters>) => void;
}> = ({ query, selectedStates, setFilters }) => {
  const { data } = query();
  const segmentUser = useSegmentUser();
  const options = data?.states.map((state, index) => {
    return (
      <StyledTag
        selected={selectedStates.includes(state)}
        usage="emphasis"
        id={`state-filter-option-${index}`}
        key={`state-filter-option-${index}`}
        onChange={() => {
          trackFilters({ state: state }, selectedStates, segmentUser);
          setFilters({ states: newSelections(state, selectedStates) });
        }}
      >
        {state}
      </StyledTag>
    );
  });

  return data?.states ? (
    <Fragment>
      <div css={{ paddingRight: '6rem' }}>
        <h5
          css={[
            FONTS.h5,
            { color: PALETTE.neutral89, marginBottom: '1.5rem', paddingLeft: '48px', paddingRight: '48px' }
          ]}
        >
          State
        </h5>
      </div>
      <div
        css={{
          overflowX: 'auto',
          paddingBottom: '1.5rem',
          paddingLeft: '48px',
          scrollPaddingRight: '6rem'
        }}
      >
        <div css={{ display: 'inline-block', paddingRight: '6rem' }}>
          <div css={{ display: 'flex' }}>{options}</div>
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default StateFilterOptions;
