import { TextInput } from '@sayrhino/rhino-shared-js';
import PasswordInput from 'components/v2/input/passwordInput';
import React, { ChangeEvent, Component, SyntheticEvent } from 'react';
type InputValue = number | string | undefined;

interface IProps {
  placeholder?: string;
  className?: string;
  id?: string;
  label?: string;
  type?: string;
  value?: InputValue;
  onChangeString?: (val: string) => void;
  onChangeNumber?: (val: number | undefined) => void;
  maxLength?: number;
  resource?: string;
  field?: string;
  datePicker?: object;
  maxDate?: string | Date;
  selected?: string | Date;
  toggleVisibility?: boolean;
  onPasswordChange?: (val: string) => void;
  borderColor?: string;
  defaultValue?: string;
}

function inputValueMaybeString(x: InputValue | undefined): x is string | undefined {
  return typeof x === 'string' || typeof x === 'undefined';
}

interface IState {
  value?: number | string;
}

class Input extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { value } = this.props;
    this.state = { value };
  }

  public renderInput = () => {
    const {
      type,
      className,
      placeholder,
      maxLength,
      resource,
      field,
      value,
      maxDate,
      toggleVisibility,
      borderColor,
      datePicker,
      onPasswordChange,
      selected,
      label,
      defaultValue
    } = this.props;

    if (type === 'password' && inputValueMaybeString(value)) {
      return (
        <PasswordInput
          resource={resource ? resource : 'user'}
          toggleVisibility={toggleVisibility}
          onPasswordChange={onPasswordChange}
          onChangeString={this.props.onChangeString}
          field={field ? field : 'password'}
          value={value}
          label={label}
        />
      );
    } else {
      return (
        <TextInput
          className={className}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          type={type ? type : 'text'}
          onChange={this.onChangeString}
          resource={resource}
          name={resource + '[' + field + ']'}
          id={resource + '_' + field}
          defaultValue={defaultValue}
          label={label}
        />
      );
    }
  };

  public render() {
    return <div>{this.renderInput()}</div>;
  }

  private onChangeString = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value });
    if (this.props.onChangeString) {
      this.props.onChangeString(event.target.value);
    }
  };
}

export default Input;
