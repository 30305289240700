import React from 'react';
import qs from 'query-string';

import ClaimCard, { convertClaimUpdateToCardProps } from './ClaimCard';
import InsurancePolicyCard, { convertInsurancePolicyUpdateToCardProps } from './InsurancePolicyCard';
import InvitationCard, { convertInvitationUpdateToCardProps } from './InvitationCard';
import PropertyCard, { convertPropertyUpdateToCardProps } from './PropertyCard';
import useUserRole from './utils/userRole';
import { getClaimPath, getInsurancePolicyPath, getPropertyPath, getV2InvitationPath } from './utils/routeHelpers';
import { Link, useLocation } from 'react-router-dom';
import { Location } from 'history';

import {
  isClaimUpdate,
  isInsurancePolicyUpdate,
  isInvitationUpdate,
  isPropertyUpdate,
  ISearchResult
} from './interfaces';
import { useReferrer } from './utils';
import { ISearchParams, parseSearchParams, searchUrlFromParams } from './Search';
import CardLink from './CardLink';

interface IProps {
  result: ISearchResult;
}

function urlWithReferrer(url: string, searchParams: ISearchParams): string {
  return qs.stringifyUrl({
    url,
    query: {
      referrer: searchUrlFromParams(searchParams)
    }
  });
}

const SearchResultItem: React.FC<IProps> = ({ result }) => {
  const location = useLocation() as Location;
  const referrer = useReferrer();
  const searchParams = parseSearchParams(location, referrer);
  const userRole = useUserRole();

  if (isClaimUpdate(result)) {
    const pathname = urlWithReferrer(getClaimPath(result), searchParams);
    return (
      <CardLink to={`/${pathname}`}>
        <ClaimCard {...convertClaimUpdateToCardProps(result.attributes, userRole)} />
      </CardLink>
    );
  }
  if (isInsurancePolicyUpdate(result)) {
    const pathname = urlWithReferrer(getInsurancePolicyPath(result), searchParams);
    return (
      <CardLink to={`/${pathname}`}>
        <InsurancePolicyCard {...convertInsurancePolicyUpdateToCardProps(result)} />
      </CardLink>
    );
  }
  if (isInvitationUpdate(result)) {
    const pathname = urlWithReferrer(getV2InvitationPath(result), searchParams);
    return (
      <CardLink to={`/${pathname}`}>
        <InvitationCard {...convertInvitationUpdateToCardProps(result)} />
      </CardLink>
    );
  }
  if (isPropertyUpdate(result)) {
    return (
      <a href={getPropertyPath(result)} target="_blank">
        <PropertyCard {...convertPropertyUpdateToCardProps(result)} />
      </a>
    );
  }

  return <div>Error rendering card</div>;
};

export default SearchResultItem;
