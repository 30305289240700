import styled from '@emotion/styled';
import { FONTS, Loading, MinimizeIcon, PALETTE } from '@sayrhino/rhino-shared-js';
import useSubrogationPayments from 'api/v2/useSubrogationPayments';
import Decimal from 'decimal.js';
import React from 'react';
import { useParams } from 'react-router-dom';
import { centsToUSDFormatter, getInitialStr, numberToUSDFormatter, useReferrer } from '../../utils';
import { ClaimsPaymentTable as Table } from './SubrogationPaymentTable';
import useOverlay from '../../utils/overlay';
import { ClaimDetailViewWrapper, ClaimDetailWrapper, LoadingWrapper, StyledReferrerLink } from './ClaimStyles';
import { ToastContextProvider } from '../../toast/toast-context';
import { useUserRole } from '../../utils/userRole';


const ClaimsPaymentTable = () => {
  const { claimsId } = useParams();
  const rawReferrer = useReferrer();
  const referrer = rawReferrer || `/admin/claims/${claimsId}`;
  const userRole = useUserRole();
  const { isThirdPartyAdmin } = userRole;


  return (
    <ToastContextProvider position="bottom-right">
      <ClaimDetailWrapper>
        <ClaimDetailViewWrapper>
          <StyledReferrerLink to={referrer} aria-label="collapse">
            <MinimizeIcon />
          </StyledReferrerLink>
          {isThirdPartyAdmin ? (
            <h3>View Payments</h3>
          ) : (
            <h3>Edit Payments</h3>
          )}
        </ClaimDetailViewWrapper>
      </ClaimDetailWrapper>
      <Table />
    </ToastContextProvider>
  );
};

const SubroPaymentsTable = () => {
  const { setShowOverlay } = useOverlay();
  const { claimsId } = useParams();
  const { isSuccess } = useSubrogationPayments(Number(claimsId)) ?? {};
  React.useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return isSuccess ? (
    <ClaimsPaymentTable />
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default SubroPaymentsTable;
