/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Outlet, useOutlet } from 'react-router-dom';

const Panel = () => {
  const outlet = useOutlet();

  return outlet && <Outlet />;
};

export default Panel;
