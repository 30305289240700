/** @jsx jsx */
import React, { useState } from 'react';
import { CheckIcon, CloseIcon, PALETTE, WithBackgroundIcon } from '@sayrhino/rhino-shared-js';
import { jsx } from '@emotion/core';
import EditableCell, { hasError } from './EditableCell';
import Banner from './Banner';
import { validColumns } from './Columns';
import RowNumberCell from './RowNumberCell';
import ErrorNotification from './ErrorNotification';
import useValidatePropertyUploadMutation from 'api/v2/useValidatePropertyUploadMutation';
import {
  IPropertyCsvUploadErrors,
  IPropertyCsvUploadResponse,
  IPropertyCsvUploadValidationResponse,
  IPropertyCsvUploadValidationStatus,
  isPropertyCsvUploadErrors,
  PropertyCsv,
  PropertyCsvErrors,
  PropertyCsvUploadResponse
} from './interfaces';
import { AxiosError, AxiosResponse } from 'axios';
import usePropertiesBulkUploadMutation from 'api/v2/usePropertiesBulkUploadMutation';
import Table from './Table';
import useCsvValidationStatus from 'api/v2/useCsvValidationStatus';

interface IProps {
  uploadResponse: PropertyCsvUploadResponse;
  onSubmit?: (nextStep: string, options?: PropertyCsvUploadResponse) => void;
}

const PropertyUploadTable = (props: IProps) => {
  const { uploadResponse } = props;
  const initialCsv = isPropertyCsvUploadErrors(uploadResponse) ? uploadResponse.error.parsed_csv : undefined;
  const [csv, setCsv] = useState<PropertyCsv>(initialCsv || []);
  const initialErrors: PropertyCsvErrors | undefined = isPropertyCsvUploadErrors(uploadResponse)
    ? uploadResponse.error.data
    : undefined;
  const [validatedPropertiesErrors, setErrors] = useState<PropertyCsvErrors>(initialErrors);

  if (initialCsv === undefined && initialErrors === undefined) {
    return <React.Fragment></React.Fragment>;
  }

  const [displayErrorNotification, setDisplayErrorNotification] = useState(false);

  const { setValidationUrl } = useCsvValidationStatus({
    onSuccess: (data: IPropertyCsvUploadValidationStatus) => {
      if (data.status === 'complete') {
        if (props.onSubmit) {
          setErrors(undefined);
          props.onSubmit('CsvSuccess', { valid_csv: JSON.parse(data.validated_data) });
        }
        setValidationUrl(undefined);
      }

      if (data.status === 'failed') {
        const parsedCsv = JSON.parse(data.validated_data).parsed_csv;
        const moreErrors = JSON.parse(data.validated_data).data;

        if (parsedCsv && moreErrors) {
          setCsv(parsedCsv);
          setErrors(moreErrors);
          setDisplayErrorNotification(true);
          setTimeout(() => {
            setDisplayErrorNotification(false);
          }, 5000);
        }
        setValidationUrl(undefined);
      }
    },
    onError: (_error) => {
      setValidationUrl(undefined);
    }
  });

  const validateProperties = useValidatePropertyUploadMutation();
  const bulkUpload = usePropertiesBulkUploadMutation();
  const errors: PropertyCsvErrors = validateProperties.error?.response?.data?.error?.data || validatedPropertiesErrors;

  const cellStyle = (cell, attr) => (hasError(cell, attr) ? { background: PALETTE.alert4 } : {});

  const columnValues = () => {
    if (errors) {
      return Object.keys(validColumns).map((column: string) => {
        return {
          Header: validColumns[column].colName,
          id: column,
          style: (cell) => cellStyle(cell, column),
          Cell: (cell) => (
            <EditableCell
              csv={csv}
              cell={cell}
              attr={column}
              width={validColumns[column].width}
              onBlur={updateCsvHandler}
              disabled={validateProperties.isLoading}
            />
          )
        };
      });
    } else {
      return Object.keys(validColumns).map((column: string) => {
        return {
          Header: validColumns[column].colName,
          accessor: column
        };
      });
    }
  };

  const stickyColumns = () => {
    return [
      {
        Header: '',
        id: 'row-icon',
        headerStyle: () => {
          return { ...styles.stickyCell, ...styles.rowIconSticky };
        },
        style: () => {
          return { ...styles.stickyCell, ...styles.rowIconSticky };
        },
        Cell: () => {
          if (errors) {
            return (
              <WithBackgroundIcon style={{ background: PALETTE.alert4 }} icon={true}>
                <CloseIcon color={PALETTE.alert100} height="16px" width="16px" />
              </WithBackgroundIcon>
            );
          } else {
            return (
              <WithBackgroundIcon style={{ background: PALETTE.success4 }} icon={true}>
                <CheckIcon color={PALETTE.success125} height="16px" width="16px" />
              </WithBackgroundIcon>
            );
          }
        }
      },
      {
        Header: () => 'Row',
        accessor: 'row',
        headerStyle: () => {
          return styles.stickyCell;
        },
        style: () => {
          return styles.stickyCell;
        },
        Cell: (cell) => <RowNumberCell cell={cell} />
      }
    ];
  };

  const columns = [...stickyColumns(), ...columnValues()];

  const updateCsvHandler = (value, row, attr) => {
    setCsv((oldCsv) => {
      oldCsv[row.original.row][attr] = value;
      return oldCsv;
    });
  };

  function onSubmit() {
    if (validateProperties.isLoading) {
      return;
    }
    if (errors) {
      const onSuccess = (data: AxiosResponse<IPropertyCsvUploadResponse | IPropertyCsvUploadValidationResponse>) => {
        if ('url' in data.data) {
          setValidationUrl(data.data.url);
        } else if (data.data?.valid_csv && props.onSubmit) {
          setErrors(undefined);
          props.onSubmit('CsvSuccess', data.data);
        }
      };
      const onError = (error: AxiosError<IPropertyCsvUploadErrors>) => {
        const parsedCsv = error.response?.data?.error?.parsed_csv;
        const moreErrors = error.response?.data?.error?.data;

        if (parsedCsv && moreErrors) {
          setCsv(parsedCsv);
          setErrors(moreErrors);
        }

        setDisplayErrorNotification(true);
        setTimeout(() => {
          setDisplayErrorNotification(false);
        }, 5000);
      };
      // There are errors, so submit for validation again
      validateProperties.mutate({ csv }, { onSuccess, onError });
    }
  }

  return (
    <div css={{ overflow: 'scroll', textAlign: 'left' }}>
      <Table columns={columns} data={errors || csv} />
      <div css={{ marginTop: '70px' }}>
        {displayErrorNotification && <ErrorNotification numRows={errors?.length} />}
        <Banner
          onClick={onSubmit}
          disabled={validateProperties.isLoading || bulkUpload.status === 'loading'}
          uploadNewCsvLink={props.onSubmit}
        />
      </div>
    </div>
  );
};

const styles = {
  rowIconSticky: {
    border: 'none',
    left: '0',
    padding: '1rem',
    boxShadow: 'none'
  },
  stickyCell: {
    left: '64px',
    position: 'sticky',
    background: PALETTE.neutralLight,
    zIndex: 998,
    padding: 0,
    boxShadow: '4px 0 15px -8px rgba(0, 0, 0, 0.25)'
  }
};

export default PropertyUploadTable;
