import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { IPMAPropertyEntityEditPost, IPMAPropertyEntityDetails } from 'components/v2/App/interfaces';
import { useMutation, MutationFunction } from 'react-query';

interface IUpdatePropertyEntityResponse {
  errors?: string[];
}

const mapToUpdatePropertyEntity = (
  propertyEntity: Partial<IPMAPropertyEntityDetails>
): Partial<IPMAPropertyEntityEditPost> => {
  const { name, mailing_address, phone, email } = propertyEntity;
  return {
    name,
    mailing_address,
    phone,
    email
  };
};

const updatePropertyEntity: MutationFunction<
  AxiosResponse<IUpdatePropertyEntityResponse>,
  IPMAPropertyEntityEditPost
> = async (propertyEntity: IPMAPropertyEntityDetails): Promise<AxiosResponse<IUpdatePropertyEntityResponse>> => {
  const authenticity_token = csrfToken();
  const policy_holders = mapToUpdatePropertyEntity(propertyEntity);
  const response: AxiosResponse<IUpdatePropertyEntityResponse> = await axios.put(
    `/v2/admin/policy_holders/${propertyEntity.id}`,
    {
      policy_holders
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useUpdatePropertyEntityMutation() {
  return useMutation(updatePropertyEntity);
}
