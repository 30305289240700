import ApplicationTable from './application_table';

class CoverageUnitsTable extends ApplicationTable {
  public selector = '#property-covered-units-table';
  public emptyMessageText = 'You have no covered units';
  public pathPrefix = 'units/';
  public dom = '<"table-head"<"buttons"B><"search"fl><"top">>rt';

  protected columns = [
    {data: 'rental_unit'},
    {data: 'coverage_start_date'},
    {data: 'coverage_end_date'},
    {data: 'coverage_amount'}
  ];

  public defaultOptions() {
    return {
      dom: this.dom,
      retrieve: true,
      serverSide: true
    };
  }
}

export default CoverageUnitsTable;
