/** @jsx jsx */
import React, { useRef } from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { flatMap } from 'lodash';
import qs from 'query-string';
import useUpdates from 'api/v2/useUpdates';
import useTasks from 'api/v2/useTasks';
import ClaimCard, { convertClaimUpdateToCardProps } from './ClaimCard';
import InsurancePolicyCard from './InsurancePolicyCard';
import { ITask, IData } from './interfaces';
import ActivityFeed from './ActivityFeed';
import { Button, PALETTE, FONTS, Loading } from '@sayrhino/rhino-shared-js';
import useJumpLink from './useJumpLink';
import CardLink from './CardLink';
import { useSegmentTrackOnLoad, useSegmentUser } from './utils/segmentTracker';
import { useUserRole } from './utils/userRole';

const StyledWrapperDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '480px',
  margin: 'auto'
});

const StyledHeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '1.875rem'
});

export const base = css`
  padding: 8px;
  margin: 4px;
`;

const totalCountCSS = css({
  background: 'black',
  color: 'white',
  height: '24px',
  borderRadius: '14px',
  padding: '0px 8px',
  fontFamily: 'MaisonNeueExtendedMedium',
  fontSize: '14px',
  lineHeight: '24px',
  alignSelf: 'flex-end'
});

export function urlWithDashboardReferrer(url: string): string {
  return qs.stringifyUrl({
    url,
    query: {
      referrer: '/'
    }
  });
}

export const Dashboard = () => {
  const updatesResponse = useUpdates();
  const tasksResponse = useTasks();
  const { isAdmin, isThirdPartyAdmin } = useUserRole();
  const userRole = useUserRole();
  const segmentUser = useSegmentUser();
  const {
    hasNextPage: canFetchMoreTasks,
    fetchNextPage: fetchMoreTasks,
    isFetchingNextPage: isFetchingMoreTasks
  } = tasksResponse;

  const tasksPages = tasksResponse.data?.pages || [];
  const tasksData: IData<ITask[]> | undefined = tasksPages[0];
  const totalActionItems = tasksData?.meta?.total_count || 0;
  const updatesMeta = updatesResponse?.data !== undefined ? updatesResponse?.data?.pages[0]?.meta : undefined;
  const totalActivityFeed = updatesMeta?.total_count;

  const actionItemsRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const activityFeedRef = useRef() as React.MutableRefObject<HTMLSpanElement>;

  const topStickyCSS = css({
    top: 0,
    borderBottom: `1px solid ${PALETTE.neutral12}`
  });

  const bottomStickyCSS = css({
    bottom: 0,
    borderTop: `1px solid ${PALETTE.neutral12}`
  });

  const linkCSS = css({
    cursor: 'pointer',
    width: 'fit-content'
  });

  const { marginLeft, colWidth, aboveRef, belowRef, isTop, scrollToHeader } = useJumpLink(
    'left-dashboard',
    actionItemsRef,
    'end'
  );

  const {
    aboveRef: aboveUpdates,
    belowRef: belowUpdates,
    scrollToHeader: scrolltoUpdatesHeader
  } = useJumpLink('left-dashboard', activityFeedRef, 'start');

  const stickyCSS = css([
    {
      borderRight: `1px solid ${PALETTE.neutral12}`,
      position: 'fixed',
      left: marginLeft,
      width: colWidth,
      height: '60px',
      background: PALETTE.brand4,
      zIndex: 10,
      padding: '16px 47px',
      cursor: 'default'
    },
    FONTS.h5
  ]);

  useSegmentTrackOnLoad('Dashboard Viewed');

  const successfulResponse = (response) => response.status === 'success' && typeof response.data === 'object';

  const handleClick = (event) => {
    event.preventDefault();
    scrollToHeader();
  };

  const handleUpdatesClick = (event) => {
    event.preventDefault();
    scrolltoUpdatesHeader();
  };

  if (successfulResponse(tasksResponse) || successfulResponse(updatesResponse)) {
    const tasks = flatMap(tasksPages, (page) => page.data || []).map((task) => {
      const attrs = task?.attributes;
      const pathname =
        location.pathname === `admin/renter_policies/${task.id}`
          ? '/'
          : isThirdPartyAdmin
          ? `admin/claims/${task.id}`
          : `admin/renter_policies/${task.id}`;

      return (
        <div key={`task-${task.type}-${task.id}`}>
          {isThirdPartyAdmin ? (
            <CardLink
              to={pathname}
              onClick={() => {
                window.analytics.track('Action Item Selected', {
                  ...segmentUser,
                  action_item_status: attrs.status,
                  action_item_type: 'claim'
                });
              }}
            >
              <ClaimCard {...convertClaimUpdateToCardProps(task.attributes, userRole)} />
            </CardLink>
          ) : (
            <CardLink
              to={urlWithDashboardReferrer(pathname)}
              onClick={() => {
                window.analytics.track('Action Item Selected', {
                  ...segmentUser,
                  action_item_status: attrs.status,
                  action_item_type: 'insurance_policy'
                });
              }}
            >
              <InsurancePolicyCard
                unitName={attrs.unit_name}
                tenantFullName={attrs.tenant_full_name}
                coverageAmount={attrs.coverage_amount}
                property={{ name: attrs.property_name, state: attrs.property_state }}
                coverageDates={attrs.coverage_dates}
                status={attrs.status}
                statusDetail={attrs.status_detail}
              />
            </CardLink>
          )}
        </div>
      );
    });

    const ActionItemSticky = () => {
      return (
        <div>
          <div css={isTop ? { display: 'none' } : [stickyCSS, topStickyCSS]}>
            <a
              onClick={handleClick}
              css={isTop ? { display: 'none' } : [stickyCSS, topStickyCSS]}
              data-testid="tasks-link"
              id="tasks-link"
            >
              <h6 css={linkCSS}>Action items</h6>
              <div css={[totalCountCSS, { zIndex: 10, position: 'fixed', left: colWidth, top: 17 }]}>
                {totalActionItems}
              </div>
            </a>
          </div>
        </div>
      );
    };

    const ActivityFeedSticky = () => {
      return (
        <div>
          <div css={aboveUpdates ? [stickyCSS, bottomStickyCSS] : { display: 'none' }}>
            <a
              onClick={handleUpdatesClick}
              css={aboveUpdates ? [stickyCSS, bottomStickyCSS] : { display: 'none' }}
              data-testid="updates-link"
              id="updates-link"
            >
              <h6 css={linkCSS}>Activity feed</h6>
              <div css={[totalCountCSS, { zIndex: 10, position: 'fixed', left: colWidth, bottom: '15px' }]}>
                {totalActivityFeed || 0}
              </div>
            </a>
          </div>
        </div>
      );
    };

    const ActionItemsAndActivityFeedSticky = () => {
      return (
        <div>
          <div css={belowUpdates ? [stickyCSS, topStickyCSS] : { display: 'none' }}>
            <a onClick={handleClick} css={{ display: 'inline-flex' }} data-testid="tasks-link">
              <h6 css={linkCSS}>Action items</h6>
              <div css={[totalCountCSS, { marginLeft: '30px' }]}>{totalActionItems}</div>
            </a>

            <a
              onClick={handleUpdatesClick}
              css={{ display: 'inline-flex', marginLeft: '50px' }}
              data-testid="updates-link"
            >
              <h6 css={linkCSS}>Activity feed</h6>
              <div css={[totalCountCSS, { marginLeft: '30px' }]}>{totalActivityFeed || 0}</div>
            </a>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        {!isAdmin && (
          <div>
            <StyledWrapperDiv>
              <span ref={actionItemsRef} />
              <StyledHeaderWrapper>
                {belowUpdates && <ActionItemsAndActivityFeedSticky />}
                {!isTop && !belowUpdates && <ActionItemSticky />}
                <h6 className="demi" style={isTop ? { marginTop: '48px' } : { display: 'none' }}>
                  Action items
                </h6>
                <div css={totalCountCSS}>{totalActionItems}</div>
              </StyledHeaderWrapper>
              {tasks}
            </StyledWrapperDiv>
            <Button
              onClick={() => fetchMoreTasks()}
              disabled={!canFetchMoreTasks || Boolean(isFetchingMoreTasks)}
              variant="secondary"
              css={{ margin: 'auto', marginTop: '40px' }}
            >
              {isFetchingMoreTasks ? <Loading /> : canFetchMoreTasks ? 'Load More Tasks' : 'Nothing more to load'}
            </Button>
            <hr style={{ margin: 'auto', width: '480px', marginBottom: '60px', marginTop: '60px' }} />
          </div>
        )}
        {aboveUpdates && <ActivityFeedSticky />}
        <span ref={activityFeedRef} style={{ top: '-120px', position: 'relative' }} />
        <ActivityFeed />
      </React.Fragment>
    );
  } else {
    return (
      <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Loading />
      </div>
    );
  }
};

export default Dashboard;
