import React from 'react';
import { render, screen, within } from '@testing-library/react';
import CsvErrors from '../PropertyUpload/batchUploadSteps/CsvErrors';
import makeServer from '../testUtils/server';
import { errorHash } from '../testUtils/mocks/propertyUpload';
import Wrapper from '../testUtils/Wrapper';

let server;

describe('<CsvErrors />', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders a table with errors from the CSV', async () => {
    render(
      <Wrapper>
        <CsvErrors data={errorHash} />
      </Wrapper>
    );
    const heading = await screen.findByRole('heading', {
      level: 2,
      name: /Your property list is missing some information/i
    });
    const subheading = await screen.findByText(/Add it below now, or reupload a new file./i);
    const table = await screen.findByRole('table');
    const headers = await screen.findAllByRole('columnheader');
    const rows = await screen.findAllByRole('row');
    const rowOneCells = await within(rows[1]).getAllByRole('cell');
    const rowOne = await within(rows[1]).getAllByRole('textbox');

    expect(heading).toBeDefined();
    expect(heading).toHaveTextContent('Your property list is missing some information');

    expect(subheading).toBeDefined();
    expect(subheading).toHaveTextContent('Add it below now, or reupload a new file.');

    expect(table).toBeDefined();
    expect(rows).toHaveLength(2);

    expect(headers).toBeDefined();
    expect(headers[0]).toHaveTextContent('');
    expect(headers[1]).toHaveTextContent('Row');
    expect(headers[2]).toHaveTextContent('Property name');
    expect(headers[3]).toHaveTextContent('Address');
    expect(headers[4]).toHaveTextContent('Type');
    expect(headers[5]).toHaveTextContent('Unit');
    expect(headers[6]).toHaveTextContent('Property owner');
    expect(headers[7]).toHaveTextContent('Legal property entity');
    expect(headers[8]).toHaveTextContent('Property coverage');
    expect(headers[9]).toHaveTextContent('Unit coverage');
    expect(headers[10]).toHaveTextContent('Salesforce opportunity id');
    expect(headers[11]).toHaveTextContent('Student housing');
    expect(headers[12]).toHaveTextContent('Integration type');
    expect(headers[13]).toHaveTextContent('Integration code');

    expect(rowOne).toBeDefined();
    expect(rowOneCells).toBeDefined();
    expect(rowOneCells[1]).toHaveTextContent('1');
    expect(rowOne[0]).toHaveValue('Building 1');
    expect(rowOne[2]).toHaveValue('garden_community');
    expect(rowOne[3]).toHaveValue('2A');
    expect(rowOne[4]).toHaveValue('TestOwner1');
    expect(rowOne[5]).toHaveValue('Policy Holder');
    expect(rowOne[6]).toHaveValue('2x');
    expect(rowOne[7]).toHaveValue('1234');
    expect(rowOne[8]).toHaveValue('sf-123');
    expect(rowOne[9]).toHaveValue('TRUE');
    expect(rowOne[10]).toHaveValue('yardi');
    expect(rowOne[11]).toHaveValue('yardi12345');

    // tool-tips
    expect(within(rowOneCells[3]).getAllByText('Invalid Address')).toBeDefined();
    expect(within(rowOneCells[3]).getAllByText('Please provide a valid address.')).toBeDefined();
    expect(within(rowOneCells[7]).getAllByText('Legal Property Entity does not exist')).toBeDefined();
    expect(within(rowOneCells[7]).getAllByText('Please enter an existing Legal Property Entity')).toBeDefined();
  });
});
