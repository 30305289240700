/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, DownloadIcon, FONTS, Loading } from '@sayrhino/rhino-shared-js';
import useInsurancePolicies from 'api/v2/useInsurancePolicies';
import { Method } from 'axios';
import { flatMap } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useMatch } from 'react-router-dom';
import emptyImage from '../../../../assets/images/empty-hole.svg';
import {
  getInsurancePolicyPropertyFilterOptions,
  insurancePolicyPropertyFilterOptionsKey
} from '../../../api/v2/useInsurancePolicyPropertyFilterOptions';
import {
  getInsurancePolicyStaticFilterOptions,
  insurancePolicyStaticFilterOptionsKey
} from '../../../api/v2/useInsurancePolicyStaticFilterOptions';
import useFileDownloader from '../../../hooks/v2/useFileDownloader';
import useIntersectionObserver from '../../../hooks/v2/useIntersectionObserver';
import CardLink from './CardLink';
import FilterLink from './Filters/FilterLink';
import { useFilterContext } from './utils/filters';
import { InsurancePolicyCard } from './InsurancePolicyCard';
import SortToggle from './SortToggle';
import { urlWithReferrer, useReferrer } from './utils';
import useUserRole from './utils/userRole';
import { useSegmentTrackOnLoad, useSegmentUser } from './utils/segmentTracker';

const styles = {
  filtersWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5rem'
  }
};

const filtersPath = '/admin/renter_policies/filters';

const prefetchFilterOptions = (debouncedFilters) => {
  const cache = useQueryClient();
  const params = { filter: { states: debouncedFilters.states }, page: 1 };

  cache.prefetchQuery([insurancePolicyPropertyFilterOptionsKey, params], () =>
    getInsurancePolicyPropertyFilterOptions(params)
  );
  cache.prefetchQuery([insurancePolicyStaticFilterOptionsKey], getInsurancePolicyStaticFilterOptions);
};

export const InsurancePolicyList = () => {
  const { debouncedFilters, filtersCount } = useFilterContext();
  const [filterField, setFilterField] = useState('latest');
  const queryCache = useQueryClient();
  // callback that removes old query group results that don't match the new filters
  const onSuccess = () => queryCache.removeQueries(['policies'], { exact: true });
  const insurancePoliciesResponse = useInsurancePolicies(debouncedFilters, filterField, onSuccess);
  const { hasNextPage, fetchNextPage, isSuccess, isLoading, isFetchingNextPage, data } = insurancePoliciesResponse;
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const policiesData = flatMap(data?.pages, (d) => d.data || []);
  const hasPolicies = isSuccess && policiesData.length > 0;
  const filtersActive = Boolean(useMatch(filtersPath));
  const location = useLocation();
  const policiesPath = useReferrer() || '/admin/renter_policies';
  const segmentUser = useSegmentUser();
  const policies =
    isSuccess &&
    policiesData.map((policy) => {
      return (
        <CardLink
          key={`${policy.type}-${policy.id}`}
          to={urlWithReferrer(`/admin/renter_policies/${policy.id}`, location.pathname)}
        >
          <InsurancePolicyCard
            unitName={policy.attributes.unit_name}
            tenantFullName={policy.attributes.tenant_full_name}
            coverageAmount={policy.attributes.coverage_amount}
            property={{ name: policy.attributes.property_name, state: policy.attributes.property_state }}
            coverageDates={policy.attributes.coverage_dates}
            status={policy.attributes.status}
            statusDetail={policy.attributes.status_detail}
          />
        </CardLink>
      );
    });
  const empty = isSuccess && (
    <div css={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <img src={emptyImage} css={{ display: 'block', margin: '5.75rem auto 2.5rem' }} alt="Empty" />
      <div css={[FONTS.h3, { width: '23.75rem', textAlign: 'center' }]}>
        Looks like nothing matches your filter result. Try a different filter combination.
      </div>
    </div>
  );
  const loadMoreDiv = isFetchingNextPage && <Loading />;
  const nothingMoreDiv = policies.length > 1 && 'Nothing more to load';
  const loading = (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Loading />
    </div>
  );
  const { isAdmin, isPropertyManager, isPropertyManagerAdmin } = useUserRole();
  const canDownloadCSV = isAdmin || isPropertyManager || isPropertyManagerAdmin;
  let downloadFileConfig = {
    requestConfig: {
      url: '/v2/admin/insurance_policies/generate_csv',
      method: 'post' as Method,
      data: { filters: debouncedFilters }
    },
    payloadKey: 'csv'
  };
  const { isDownloading, startDownload } = useFileDownloader(downloadFileConfig);

  useIntersectionObserver({
    root: null,
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isFetchingNextPage
  });

  useSegmentTrackOnLoad('Policies Viewed');

  prefetchFilterOptions(debouncedFilters);

  useEffect(() => {
    if (isDownloading) {
      window.analytics.track('Click PolicySaveCSV', {
        ...segmentUser,
        filter: debouncedFilters
      });
    }
  }, [isDownloading]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '480px',
        margin: 'auto',
        paddingTop: '1.875rem'
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '1.875rem' }}
      >
        <h2 css={[FONTS.h3]}>Policies</h2>
        {canDownloadCSV && (
          <Button variant="tertiary" onClick={() => startDownload()} disabled={isDownloading}>
            {isDownloading ? 'Saving...' : 'Save .CSV'}
            {isDownloading ? null : <DownloadIcon css={{ paddingLeft: 10 }} />}
          </Button>
        )}
      </div>
      <div css={styles.filtersWrapper}>
        <SortToggle
          value={filterField === 'latest' ? 0 : 1}
          onChange={(value) => setFilterField(value === 0 ? 'latest' : 'modified')}
        />
        <FilterLink
          filtersActive={filtersActive}
          filtersCount={filtersCount}
          id="insurance-policies-filter-button"
          to={urlWithReferrer(filtersActive ? policiesPath : filtersPath, location.pathname)}
        />
      </div>
      {hasPolicies ? policies : empty}
      {isLoading ? loading : null}
      <div
        ref={loadMoreRef}
        css={[
          FONTS.h4,
          {
            display: hasPolicies ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '18px'
          }
        ]}
      >
        {hasNextPage ? loadMoreDiv : nothingMoreDiv}
      </div>
    </div>
  );
};

export default InsurancePolicyList;
