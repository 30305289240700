/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useSegmentUser } from '../utils/segmentTracker';
import { trackFilters } from './index';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Checkbox, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { IPropertyFilter } from '../interfaces';

const CheckboxContainer = styled.div([FONTS.p2], {
  position: 'relative',
  flexBasis: '50%',
  marginBottom: '0.75rem'
});

const Tooltip = styled.div([FONTS.p3Light], {
  position: 'absolute',
  padding: '8px 16px',
  background: PALETTE.neutral88,
  color: PALETTE.neutral4,
  zIndex: 999,
  borderRadius: '4px'
});

export const newSelectedProperties = (selection: IPropertyFilter, selected: IPropertyFilter[]): IPropertyFilter[] => {
  return selected.some(
    (s) => s.building_name === selection.building_name && s.address_state === selection.address_state
  )
    ? selected.filter(
        (s) => !(s.building_name === selection.building_name && s.address_state === selection.address_state)
      )
    : selected.concat([selection]);
};

const PropertyFilterOption = (props) => {
  const { resolvedData, property, index, selectedProperties, setFilters, ...rest } = props;
  const [isMouseInside, setIsMouseInside] = useState(false);
  const segmentUser = useSegmentUser();
  const overFlowingTextLength = 19;

  const sameBuildingNameInMultipleStates =
    resolvedData?.data?.filter((p) => p.building_name === property.building_name).length > 1;

  const buildingName = sameBuildingNameInMultipleStates
    ? `${property.building_name} (${property.address_state})`
    : property.building_name;

  const formatPropertiesForTracking = (properties) => properties.map((property) => property.building_name);

  const showTooltip = buildingName.length > overFlowingTextLength && resolvedData?.meta?.total_count > 5;

  const truncateText = buildingName.slice(0, overFlowingTextLength) + '...';
  return (
    <CheckboxContainer
      key={index}
      onMouseEnter={() => setIsMouseInside(true)}
      onMouseLeave={() => setIsMouseInside(false)}
    >
      <Checkbox
        checked={selectedProperties.some(
          (p) => p.building_name === property.building_name && p.address_state === property.address_state
        )}
        css={{ minWidth: 20 }}
        id={`checkbox-property-filter-${index}`}
        key={`checkbox-property-filter-${index}`}
        name={buildingName}
        onCheckedChange={() => {
          trackFilters(
            { property: property.building_name },
            formatPropertiesForTracking(selectedProperties),
            segmentUser
          );
          setFilters({ properties: newSelectedProperties(property, selectedProperties) });
        }}
        label={showTooltip ? truncateText : buildingName}
      />
      {showTooltip && isMouseInside && <Tooltip className="tooltip">{buildingName}</Tooltip>}
    </CheckboxContainer>
  );
};

export default PropertyFilterOption;
