const LEFT_MOUSE_BUTTON = 1;
const RIGHT_MOUSE_BUTTON = 3;
let lastChecked;

interface ITableSettings extends DataTables.Settings {
  conditionalPaging: boolean;
  select: ISelectSettings;
  buttons: IButton[];
}

interface ISelectSettings {
  style?: string;
  selector?: string;
  multi?: boolean;
}

interface IDataTableButton {
  enable(): void;
  disable(): void;
}

export interface IDataTablesApi extends DataTables.Api {
  buttons(selector: string | string[]): IDataTableButton;
}

export interface IButton {
  text: string;
  className?: string;
  action?(e: object, dt: DataTables.Api, node: JQuery, config: object): void;
}

export type Ordering = [number, string];

class ApplicationTable {
  public selector: string;
  public pathPrefix: string;
  public $table: JQuery;
  public select: ISelectSettings = {};
  public dataTable: IDataTablesApi;
  public emptyMessageText?: string = undefined;
  public pageLength: number;
  public dom: string = '<"table-head"<"buttons"B><"search"fl><"top">>rt<"bottom"ip><"clear">';

  // See https://datatables.net/examples/basic_init/table_sorting.html

  public order: Ordering | Ordering[] = [[0, 'desc']];

  protected columns: DataTables.ColumnSettings[] = [];
  protected columnDefs: DataTables.ColumnDefsSettings[] = [];
  protected buttons: IButton[] = [];

  public getColumns(): DataTables.ColumnSettings[] {
    return this.columns;
  }

  public getColumnDefs(): DataTables.ColumnDefsSettings[] {
    return this.columnDefs;
  }

  public getButtons(): IButton[] {
    return this.buttons;
  }

  public updateFilterLink(): void {
    return;
  }

  public can(action: string): boolean {
    return this.$table.data(`can-${action}`);
  }

  public canBulkUpdate(): boolean {
    return this.can('bulk-update');
  }

  public canDestroy(): boolean {
    return this.can('destroy');
  }

  public canEdit(): boolean {
    return this.can('edit');
  }

  public canFilterCsv(): boolean {
    return false;
  }

  public defaultOptions(): Partial<ITableSettings> {
    return {
      dom: this.dom,
      retrieve: true,
      serverSide: true,
      pageLength: this.pageLength || 10,
      conditionalPaging: true,
      pagingType: this.$table.data('pagingType') || 'full_numbers'
    };
  }

  public onInitComplete() {
    return;
  }

  public settings(): ITableSettings {
    return Object.assign(
      {},
      this.defaultOptions(),
      { order: this.order },
      { ajax: this.$table.data('source') },
      { columns: this.getColumns() },
      { columnDefs: this.getColumnDefs() },
      { select: this.select },
      { buttons: this.getButtons() },
      { language: { emptyTable: this.emptyMessageText } },
      { initComplete: () => this.onInitComplete() }
    );
  }

  public validate(): void {
    const columnLength = this.getColumns().length;
    const headersLength = this.$table.find('th').length;
    if (columnLength !== headersLength) {
      console.error(`${this.selector}: columns size does not match headers!`);
    }
  }

  public onMouseDown = (evt) => {
    const $target = $(evt.target);

    if ($target.closest('td').hasClass('js-control')) {
      return;
    }
    if ($target.closest('td').hasClass('dataTables_empty')) {
      return;
    }

    if (evt.which === LEFT_MOUSE_BUTTON) {
      if (!$target.hasClass('select-checkbox')) {
        window.location.pathname = '/admin/' + this.pathPrefix + $target.parent().attr('id');
      }
    }

    if (!$('.admin_class').length) {
      return;
    }

    if (evt.which === RIGHT_MOUSE_BUTTON) {
      if (!$target.hasClass('select-checkbox')) {
        const url = '/admin/' + this.pathPrefix + $target.parent().attr('id');
        window.open(url, '_blank');
      }
    }

    if (!$target.hasClass('multiselect-checkbox')) {
      return;
    }

    if (evt.shiftKey) {
      const checkboxes = $('.multiselect-checkbox');
      const start = checkboxes.index(evt.target);
      const end = checkboxes.index(lastChecked);
      $.each(checkboxes.slice(Math.min(start, end) + 1, Math.max(start, end)), function (index, value) {
        if (!$(value).parent().hasClass('selected')) {
          $(value).click();
        }
      });
    }
    lastChecked = evt.target;
  };

  public render(): void {
    this.$table = $(this.selector);
    this.$table.on('mousedown', 'tbody tr', this.onMouseDown);

    this.$table.on('xhr.dt', (e, settings, json, xhr) => {
      if (this.canFilterCsv()) {
        this.updateFilterLink();
      }

      if (json === null && xhr.status === 401) {
        location.reload();
      }
    });

    if (!this.$table.length) {
      return;
    }

    if (!this.canBulkUpdate()) {
      this.$table.find('th.action-bulk').remove();
    }

    if (!this.canEdit()) {
      this.$table.find('th.action-edit').remove();
    }

    if (!this.canDestroy()) {
      this.$table.find('th.action-destroy').remove();
    }

    this.validate();
    this.dataTable = this.$table.DataTable(this.settings()) as IDataTablesApi;

    this.toggleBulkButtons(false);
    this.dataTable.on('select', this.onSelectChanged);
    this.dataTable.on('deselect', this.onSelectChanged);
  }

  public selectedRows(): DataTables.RowsMethods {
    return this.dataTable.rows({ selected: true });
  }

  public onSelectChanged = (e: object, dt: DataTables.Api, type: string, indexes: number[]): void => {
    const enableBulkActions = this.selectedRows().count() > 0;
    this.toggleBulkButtons(enableBulkActions);
  };

  public toggleBulkButtons(enable: boolean): void {
    if (enable) {
      this.dataTable.buttons('.bulk').enable();
    } else {
      this.dataTable.buttons('.bulk').disable();
    }
  }
}

export default ApplicationTable;
