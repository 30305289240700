/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import ClaimList from './ClaimList';
import CreateInvitation from './Invitation/CreateInvitation';
import { CreatePartnerIntegration } from './Integrations/CreatePMSIntegrationSetup';
import Dashboard from './Dashboard';
import FullPage from './FullPage';
import InsurancePolicyList from './InsurancePolicyList';
import InvitationList from './InvitationList';
import Layout from './Layout';
import TabLayout from './TabLayout';
import Search from './Search';
import { useStickiedRoutes } from './utils';
import { FilterContext, useFilters } from './utils/filters';
import { ClaimContext, useBatchAssign } from './utils/claimBatchAssign';
import FileClaim from './FileClaim';
import Account from './Account/Account';
import { ROUTES } from './utils/routeHelpers';
import PropertyOwners from './PropertyOwners/PropertyOwners';
import CoverageRulesForm from './Integrations/CoverageRulesForm';
import SitesTable from './Integrations/Sites/SitesTable';
import CreatePolicyRenewalInvitation from './Invitation/CreatePolicyRenewalInvitation';
import PolicyRenewalRequestSent from './Invitation/PolicyRenewalRequestSent';
import DepositFundsList from './DepositFundsList';

export const TestComponent = (props) => {
  return (
    <div>
      <Link to="/">dashboard</Link>
      <h1>Hello {props.name}</h1>
    </div>
  );
};

// Separate component to be able to access the useLocation
// inside of <Router/>'s context
// referrer conditional dynamically shows routes if the left hand panel needs
// to be more specific than the root dashboard panel
const RootRoutes = () => {
  const { debouncedFilters, filtersCount, selectedFilters, setFilters } = useFilters();
  const stickied = useStickiedRoutes();
  const location = useLocation();
  const {
    inBatchAssignMode,
    setBatchAssignMode,
    batchedClaims,
    setBatchSelectedClaims,
    selectedAnalyst,
    setSelectedAnalyst,
    setBatchedClaims
  } = useBatchAssign();

  useEffect(() => {
    if (window.Appcues !== undefined) {
      window.Appcues.page();
    }
    window.analytics.page(location.pathname);
  }, [location]);

  return (
    <FilterContext.Provider value={{ debouncedFilters, filtersCount, selectedFilters, setFilters }}>
      <ClaimContext.Provider
        value={{
          inBatchAssignMode,
          setBatchAssignMode,
          batchedClaims,
          setBatchSelectedClaims,
          selectedAnalyst,
          setSelectedAnalyst,
          setBatchedClaims
        }}
      >
        <Routes>
          <Route path="" element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="search" element={<Search />} />
            <Route path={`${ROUTES.propertyOwner}/*`} element={<PropertyOwners />} />
            <Route path="admin/funds/*" element={<DepositFundsList />} />
            <Route path="admin/funds/accounts/*" element={<DepositFundsList />} />
            {stickied.search && <Route path="/*" element={<Search />} />}
            {stickied.dashboard && <Route path="/*" element={<Dashboard />} />}
            {stickied.any === false && (
              <Fragment>
                <Route path="admin/renter_policies/*" element={<InsurancePolicyList />} />
                <Route path="admin/claims/*" element={<ClaimList />} />
                <Route path="admin/funds/*" element={<DepositFundsList />} />
                <Route path="admin/funds/withdrawals/*" element={<DepositFundsList />} />
                <Route path="admin/invitations/*" element={<InvitationList />} />
              </Fragment>
            )}
          </Route>
          <Route path="" element={<TabLayout />}>
            <Route path={`${ROUTES.account}/*`} element={<Account />} />
          </Route>
          <Route path="" element={<FullPage />}>
            <Route path="admin/invitations/new" element={<CreateInvitation />} />
            <Route path="admin/invitations/policy_renewal" element={<CreatePolicyRenewalInvitation />} />
            <Route path="admin/invitations/policy_renewal_success" element={<PolicyRenewalRequestSent />} />
            <Route path="invite" element={<CreateInvitation />} />
            <Route path="admin/integrations/sites" element={<SitesTable />} />
            <Route path="admin/claims/new" element={<FileClaim />} />
            <Route path="admin/claims/:claimsId/edit" element={<FileClaim />} />
            <Route
              path="admin/property_owners/:propertyOwnerId/integrations/partners/new"
              element={<CreatePartnerIntegration />}
            />
            <Route
              path="admin/property_owners/:propertyOwnerId/integrations/partners/:partnerIntegrationId/edit"
              element={<CreatePartnerIntegration />}
            />
            <Route
              path="admin/property_owners/:propertyOwnerId/integrations/partners/:partnerIntegrationId/coverage"
              element={<CoverageRulesForm />}
            />
          </Route>
        </Routes>
      </ClaimContext.Provider>
    </FilterContext.Provider>
  );
};

export default RootRoutes;
