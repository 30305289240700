import React from 'react';
import { render, screen, act, fireEvent } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import PropertyOwnerOverlay from '../PropertyOwners/PropertyOwnerForm';
import makeServer from '../testUtils/server';
import { waitFor, waitForElementToBeRemoved, within } from '@testing-library/dom';
import Wrapper from '../testUtils/Wrapper';
import PropertyOwnerFormOverlay from '../PropertyOwners/PropertyOwnerForm';
import { UserContext } from '../utils/userRole';
let server;

describe('<PropertyOwnerOverlay />', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  const renderAsRole = (role, propertyOwnerId) =>
    render(
      <UserContext.Provider value={{ id: 1, role: role, sessionId: '', isAutoApprove: false }}>
        <Wrapper>
          <PropertyOwnerOverlay />
        </Wrapper>
      </UserContext.Provider>
    );

  test('It renders the Create Property Owner overlay', async () => {
    render(
      <Wrapper>
        <PropertyOwnerOverlay />
      </Wrapper>
    );
    const heading = await screen.findAllByText('Create Property Owner');

    expect(heading).toBeDefined();
  });

  //name input
  test('It allows for property owner name to be defined', async () => {
    render(
      <Wrapper>
        <PropertyOwnerOverlay />
      </Wrapper>
    );
    const name = await screen.getByLabelText(/Name/i);
    userEvent.type(name, 'Ash');
    const nameInput = await screen.getByRole('textbox', { name: /Name/i });
    expect(nameInput).toHaveValue('Ash');
  });

  //coverage amount/multiplier select
  test('Coverage rules radio groups should show a text input when "Dollar Amount" is selected and a dropdown when "Multiplier" is selected', async () => {
    render(
      <Wrapper>
        <PropertyOwnerOverlay />
      </Wrapper>
    );
    const coverageOptions = await screen.getByLabelText('coverage-rules');
    expect(coverageOptions).toBeDefined();

    // verify dollar amount button is selectable
    const dollarAmountButton = within(coverageOptions).getByRole('tab', { name: 'Dollar amount' });
    expect(dollarAmountButton).toBeDefined();

    userEvent.click(dollarAmountButton);

    // verify clicking dollar amount button shows coverage amount input
    const coverageWithRulesAmountInput = within(coverageOptions).findByLabelText('Dollar amount');
    expect(coverageWithRulesAmountInput).toBeDefined();

    // verify coverage multiplier button is selectable
    const coverageMultiplierButton = within(coverageOptions).getByRole('tab', { name: 'Multiplier' });
    expect(coverageMultiplierButton).toBeDefined();

    // verify clicking coverage multiplier button shows coverage multiplier dropdown
    userEvent.click(coverageMultiplierButton);
    const coverageMultiplierInput = within(coverageOptions).findByLabelText('Select Multiplier(s)');
    expect(coverageMultiplierInput).toBeDefined();
  });

  // checkbox
  test('checkbox displays and checks', async () => {
    render(
      <Wrapper>
        <PropertyOwnerOverlay />
      </Wrapper>
    );
    //make sure the dollar amount is selected
    const coverageOptions = await screen.getByLabelText('coverage-rules');
    expect(coverageOptions).toBeDefined();
    const dollarAmountButton = within(coverageOptions).getByRole('tab', { name: 'Dollar amount' });
    userEvent.click(dollarAmountButton);
    //verify the checkbox is checkable
    const maxCoverageCheckBox = await screen.findByLabelText('Set max coverage');
    await act(async () => {
      userEvent.click(maxCoverageCheckBox);
    });
    expect(maxCoverageCheckBox).toBeChecked();
  });

  test('Homepage Sign-Ups appears properly', async () => {
    renderAsRole('Administrator', 1);
    const requiresInvitationHeader = await screen.getByText('Homepage Sign-Ups');
    expect(requiresInvitationHeader).toBeDefined();
    const requiresInvitationQuestion = await screen.getByText('Block Homepage Sign-Ups');
    expect(requiresInvitationQuestion).toBeDefined();
    const yesAnswers = await screen.findAllByLabelText('Yes');
    const noAnswers = await screen.findAllByLabelText('No');
    await act(async () => {
      userEvent.click(yesAnswers[2]);
    });
    expect(yesAnswers[2]).toBeChecked();
    await act(async () => {
      userEvent.click(noAnswers[2]);
    });
    expect(noAnswers[2]).toBeChecked();
  });

  test('Renters Insurance Requirements section appears correctly', async () => {
    renderAsRole('Administrator', 1);

    const rentersInsuranceReqsHeader = await screen.getByText('Renters Insurance');
    expect(rentersInsuranceReqsHeader).toBeDefined();

    const rentersInsuranceReqQuestion = await screen.getByText(
      'Does the partner require renters insurance for each renter?'
    );
    expect(rentersInsuranceReqQuestion).toBeDefined();

    const exclusiveMarketingQuestion = await screen.getByText(
      'Does the partner have an exclusive marketing agreement with a renters insurance provider?'
    );

    expect(exclusiveMarketingQuestion).toBeDefined();

    const yesAnswers = await screen.findAllByLabelText('Yes');
    const noAnswers = await screen.findAllByLabelText('No');

    await act(async () => {
      userEvent.click(yesAnswers[0]);
    });

    const liabilityDropdown = await screen.findByText('Select from dropdown');

    // Liability Coverage dropdown should appear if the user selects 'Yes'
    expect(liabilityDropdown).toBeInTheDocument();

    await act(async () => {
      userEvent.click(noAnswers[0]);
    });

    // Liability Coverage dropdown should not appear if the user selects 'No'
    expect(liabilityDropdown).not.toBeInTheDocument();
  });

  // submitting form closes the overlay
  // Note: this only tests for the component being removed from the DOM.
  // Testing hook functionality/logic should be done seperately (good examples here: https://tkdodo.eu/blog/testing-react-query)
  test('on submit closes create property owner form', async () => {
    const { queryByText, getByLabelText, getByRole } = render(
      <Wrapper initialEntries={['/admin/property_owners/new']}>
        <div>
          <PropertyOwnerFormOverlay />
        </div>
      </Wrapper>
    );
    const heading = await queryByText('Create Property Owner');
    expect(heading).toBeDefined();
    const name = await getByLabelText(/Name/i);

    //define and locate submit button
    const submitButton = await getByRole('button', { name: /Save/i });
    const closeButton = await getByRole('button', { name: 'Leave create property owner form' });
    expect(submitButton).toBeDefined();
    expect(closeButton).toBeDefined();

    await act(() => {
      userEvent.type(name, 'Ash');
      userEvent.click(submitButton);
    });

    waitForElementToBeRemoved(heading).then(() => {
      expect(name).not.toBeInTheDocument();
      expect(submitButton).not.toBeInTheDocument();
      expect(closeButton).not.toBeInTheDocument();
    });
  });
});
