import http from 'j-fetch';

export function fetchProperties(query: string, onSuccess: Function, onError: Function, limit = 10) {
  http
    .get({ url: '/properties/properties_name?term=' + query + '&limit=' + limit })
    .then((r) => r.json())
    .then(onSuccess)
    .catch(onError);
}

export function fetchPropertiesByAddress(query: string, onSuccess: Function, onError: Function, limit = 10) {
  http
    .get({ url: '/properties/properties_full_address?term=' + query + '&limit=' + limit })
    .then((r) => r.json())
    .then(onSuccess)
    .catch(onError);
}

export function fullTextSearchProperties(query: string, onSuccess: Function, onError: Function) {
  http
    .get({ url: '/properties/search?query=' + query })
    .then((r) => r.json())
    .then(onSuccess)
    .catch(onError);
}

export function fetchPropertyManagers(query: string, onSuccess: Function, onError: Function) {
  http
    .get({ url: '/property_managers/search?query=' + query })
    .then((r) => r.json())
    .then(onSuccess)
    .catch(onError);
}

export function fetchPropertyOwners(query: string, onSuccess: Function, onError: Function, limit = 10) {
  http
    .get({ url: '/property_owners/property_owners_name?term=' + query + '&limit=' + limit })
    .then((r) => r.json())
    .then(onSuccess)
    .catch(onError);
}
