/** @jsx jsx */
import { jsx } from '@emotion/core';
import '@reach/combobox/styles.css';
import React, { useState, useEffect } from 'react';
import { isUndefined } from 'lodash';
import { getPolicies } from 'api/v2/usePolicySearch';
import { PALETTE, FONTS, ROUNDNESS } from '@sayrhino/rhino-shared-js';
import isPresent from '../../../utils/isPresent';
import usePolicy from '../../../api/v2/usePolicy';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import styled from '@emotion/styled';
import { useQueryClient } from 'react-query';
import { PolicySearchResult, PolicyTypeaheadProps, IInsurancePolicy } from 'components/v2/App/interfaces';

type TypeaheadState = {
  isSelected: boolean;
  isFocused: boolean;
};

const Wrapper = styled.div({ marginTop: '16px' });

const Select = styled(AsyncSelect)([
  ROUNDNESS.s,
  {
    border: `1px solid ${PALETTE.neutral12}`,
    padding: '3px'
  }
]);

const Label = styled.label([
  FONTS.p1Light,
  {
    color: PALETTE.neutral55
  }
]);

const setBackgroundStyle = (state: TypeaheadState) => {
  if (state.isSelected) {
    return PALETTE.brand100;
  }
  if (state.isFocused) {
    return PALETTE.neutral12;
  }
  return PALETTE.neutralLight;
};

const setColorStyle = (state: TypeaheadState) => {
  if (state.isSelected) {
    return PALETTE.neutralLight;
  }
  if (state.isFocused) {
    return PALETTE.neutralDark;
  }
  return PALETTE.neutralDark;
};

const customTypeaheadStyles = {
  option: (base: any, state: TypeaheadState) => ({
    ...base,
    color: setColorStyle(state),
    fontWeight: '900',
    backgroundColor: setBackgroundStyle(state)
  })
};

const SubText = styled.p<{ error?: boolean }>(({ error }) => [
  FONTS.p1,
  {
    fontSize: '12px',
    color: !error ? PALETTE.neutral25 : PALETTE.alert100
  }
]);

const buildTenantInfo = (policy) => {
  const coverageStartDate = policy.humanized_coverage_start_date;
  const converageEndDate = policy.humanized_coverage_end_date;
  const tenantFullName = policy.tenant_full_name;
  const unitInfo = `Unit: ${policy.unit_name}`;
  const policyDatesInfo = `Policy dates: ${coverageStartDate} - ${converageEndDate}`;
  return `${tenantFullName}, ${unitInfo}, ${policyDatesInfo}`;
};

export const PolicyTypeahead = ({ propertyId, policyId, onSelect, isInvalid, id }: PolicyTypeaheadProps) => {
  const [policies, setPolicies] = useState<PolicySearchResult[]>();
  const [searchError, setSearchError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: policy } = usePolicy(Number(policyId));
  const [selectedPolicy, setSelectedPolicy] = useState<PolicySearchResult | IInsurancePolicy>();

  useEffect(() => {
    setSelectedPolicy(policy);
  }, [policy]);

  const queryClient = useQueryClient();

  const fetchPoliciesOnSearch = async (input: string): Promise<{}> => {
    return queryClient.fetchQuery(['policySearch', input, propertyId], () => getPolicies(input, propertyId));
  };

  const resetPoliciesAndPolicy = (): void => {
    setSelectedPolicy(undefined);
    setPolicies([]);
  };

  useEffect(() => {
    const fetchInitialPolicies = async () => {
      const policies = (await fetchPoliciesOnSearch('')) as PolicySearchResult[];
      setPolicies(policies);
    };
    if (propertyId) {
      fetchInitialPolicies();
    } else {
      const shouldClearPreviousPolicies = isUndefined(propertyId) && isPresent(policies);
      if (shouldClearPreviousPolicies) {
        resetPoliciesAndPolicy();
      }
    }
  }, [propertyId]);

  const loadOptions = async (input: string) => {
    setIsLoading(() => true);
    try {
      const fetchedPolicies = (await fetchPoliciesOnSearch(input)) as PolicySearchResult[];
      setPolicies(fetchedPolicies);
      setIsLoading(false);
      return fetchedPolicies;
    } catch (error) {
      setIsLoading(() => false);
      setSearchError(`Something went wrong! ${error.toString()}`);
      return [];
    }
  };

  const onChange = (value: PolicySearchResult) => {
    if (value === null) {
      setSelectedPolicy(undefined);
      onSelect(undefined as any);
      return null;
    }
    setSelectedPolicy(value);
    onSelect(value);
  };

  return (
    <Wrapper>
      <Label>Policy associated with your claim</Label>
      <Select
        styles={customTypeaheadStyles}
        id={id}
        isClearable={true}
        isLoading={isLoading}
        value={selectedPolicy}
        defaultOptions={policies}
        loadOptions={loadOptions}
        onChange={onChange}
        inputId="policy-search"
        getOptionValue={(option: PolicySearchResult) => option.id}
        getOptionLabel={(option: PolicySearchResult) => buildTenantInfo(option)}
        placeholder="Select policy..."
      />
      <SubText error={isInvalid}>{isInvalid ? 'Can’t be left blank' : 'Enter the renter’s name, or unit'}</SubText>
      {Boolean(searchError) && <SubText error={isInvalid}>{searchError}</SubText>}
    </Wrapper>
  );
};
