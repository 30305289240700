import CoverageUnitsTable from './coverage_units_table';
import DataSortTable from './data_sort_table';
import InProgressTable from './in_progress_table';
import PolicyHolderTable from './policy_holder_table';
import PropertyDataSortTable from './property_data_sort_table';
import PropertyPolicyTable from './property_policy_table';
import PropertyUnitsTable from './property_units_table';
import RenterPoliciesTable from './renter_policies_table';
import TenantTable from './tenant_table';
import UnitTable from './unit_table';
import UserPropertiesTable from './user_properties_table';
import UserTable from './user_table';

const tables = [
  CoverageUnitsTable,
  DataSortTable,
  InProgressTable,
  PolicyHolderTable,
  RenterPoliciesTable,
  TenantTable,
  UnitTable,
  PropertyDataSortTable,
  PropertyUnitsTable,
  PropertyPolicyTable,
  UserPropertiesTable,
  UserTable
];

export default tables;
