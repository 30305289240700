import axios from 'axios';
import { csrfToken } from 'utils/document';
import { IYardiChargeCode } from 'components/v2/App/interfaces';

export interface IPartnerIntegrationInfo {
  type: string;
  pmcID: string;
  name: string;
  propertyOwnerID?: string;
  base_uri: string;
  username: string;
  password?: string;
  server_name: string;
  deposit_charge_code: string;
  deposit_payment_code: string;
  configuring_ils_gc?: boolean;
  enabled?: boolean;
  auto_invite_enabled?: boolean;
  uuid?: string;
  ils_username?: string;
  ils_entity?: string;
  ils_password?: string;
  allow_writes: boolean;
  single_family_only: boolean;
  enabled_partner_rule_set?: boolean;
  enabled_custom_rule_set?: boolean;
  update_enabled?: boolean;
  invite_resident_statuses: Array<string>;
  invite_resident_credit_statuses: Array<string>;
  invite_immediately?: boolean;
  ledger_sync_enabled?: boolean;
  native_enrollment_enabled?: boolean;
  show_sdi_coverage_amount?: boolean;
  health_check_enabled?: boolean;
  yardi_charge_codes?: Array<IYardiChargeCode>;
  ledger_sync_create_charge?: boolean;
  cash_deposit_push_enabled?: boolean;
  ledger_sync_tagged_transactions?: boolean;
}

const createPartnerIntegration = async (partnerIntegrationInfo: IPartnerIntegrationInfo) => {
  const {
    type,
    pmcID,
    name,
    propertyOwnerID,
    base_uri,
    username,
    password,
    server_name,
    deposit_charge_code,
    deposit_payment_code,
    enabled,
    auto_invite_enabled,
    ils_username,
    ils_entity,
    ils_password,
    allow_writes,
    enabled_partner_rule_set,
    enabled_custom_rule_set,
    update_enabled,
    invite_resident_statuses,
    invite_resident_credit_statuses,
    invite_immediately
  } = partnerIntegrationInfo;

  const token = csrfToken();

  const response = await axios.post<IPartnerIntegrationInfo>(
    '/v2/integrations/partners',
    {
      integration: {
        type: type,
        pmc_id: pmcID,
        name: name,
        property_owner_id: propertyOwnerID,
        base_uri: base_uri,
        username: username,
        password: password,
        server_name: server_name,
        deposit_charge_code: deposit_charge_code,
        deposit_payment_code: deposit_payment_code,
        enabled: enabled,
        auto_invite_enabled: auto_invite_enabled,
        ils_username: ils_username,
        ils_entity: ils_entity,
        ils_password: ils_password,
        allow_writes: allow_writes,
        enabled_partner_rule_set: enabled_partner_rule_set,
        enabled_custom_rule_set: enabled_custom_rule_set,
        update_enabled: update_enabled,
        invite_resident_statuses: invite_resident_statuses,
        invite_resident_credit_statuses: invite_resident_credit_statuses,
        invite_immediately: invite_immediately
      }
    },
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default createPartnerIntegration;
