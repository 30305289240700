/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Button, FONTS, Loading, PALETTE, Radio } from "@sayrhino/rhino-shared-js";
import useCancellationRequestDenialReasons from "api/v2/useCancellationRequestDenialReasons";
import NavBar from "../Account/NavBar";
import React from "react";
import moment from "moment";

export const RadioRoot: any = Radio.Root;

const ContainerDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '15px',
});

const ContentDiv = styled.div({
  backgroundColor: '#F5F6F7',
  border: 'none',
  borderRadius: '10px',
  padding: '15px',
  width: '480px'
});

const ActionButtonsDiv = styled.div({
  display: 'flex',
  justifyContent: 'right',
  gap: '5px',
  paddingTop: '24px'
});

const StyledRadioContainer = styled.div({
  display: 'flex',
  gap: '5px'
});

const StyledRadio = styled.input({
  backgroundColor: PALETTE.neutralLight,
  minWidth: '15px',
  height: 15,
  borderRadius: '50%',
  border: 'none',
  marginTop: '2px'
})

const StyledLabel = styled.label({
  flex: '1'
})

const StyledInput = styled.input({
  border: '1px solid #ADB4B8',
  borderRadius: '8px',
  padding: '0px 12px',
  lineHeight: '18px',
  height: '34px',
  fontSize: '12px'
})

const handleInputDateClick = (event) => {
  event.target.showPicker();
}

const EarlyTerminationDenialForm = ({
  cancellation_request,
  selectedReason,
  reasonExplanation,
  blocksNewRequestsUntil,
  referrer,
  showError,
  onChange,
  onBlocksNewRequestsUntilChange,
  onReasonExplanationChange,
  onDeny
}) => {
  const { data, isSuccess } = useCancellationRequestDenialReasons(cancellation_request.id);
  const otherReasonError = selectedReason == 0 && reasonExplanation == '';
  const blocksNewRequestsUntilError = selectedReason == 1 && blocksNewRequestsUntil == '';

  const errorMessage = otherReasonError ? 'Please provide an explanation.' : blocksNewRequestsUntilError ? 'Please provide a date.' : '';

  return isSuccess ? (
    <>
      <NavBar referrer={referrer} />
      <ContainerDiv>
        <ContentDiv>
          <div>
            <div>
              <h3 css={[FONTS.p1Extended, {color: '#6B3BF5', lineHeight: '25px'}]}>
                Please select the option that best describes the situation.
              </h3>
              <p>
                Please note that this will be shared with your renter.
              </p>
            </div>
            {showError && <p css={{color: '#FF0000', marginTop: '10px'}}>{errorMessage}</p>}
            <hr css={{borderTop: '1px solid #DCDEDF'}} />
            <div css={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
              {
                data?.map((reason: any) => (
                  <StyledRadioContainer key={`reason-reason.value-${reason.value}`}>
                    <StyledRadio type="radio" onChange={onChange} name="early_terminated_reason" id={reason.value} value ={reason.value} />
                    <StyledLabel htmlFor={reason.value}>{reason.label}</StyledLabel>
                    {reason.value === 0 && <StyledInput type="text" disabled={selectedReason != 0} onChange={onReasonExplanationChange} value={reasonExplanation} />}
                    {
                      reason.value === 1 &&
                        <StyledInput
                          type="date"
                          min={moment().add(1, 'days').format('YYYY-MM-DD')}
                          onClick={(event) => handleInputDateClick(event)}
                          disabled={selectedReason != 1}
                          onChange={onBlocksNewRequestsUntilChange}
                          value={blocksNewRequestsUntil} />
                    }
                  </StyledRadioContainer>
                ))
              }
            </div>
          </div>
          <ActionButtonsDiv>
            <Button variant="primary" onClick={onDeny} id="denyRequestBtn">
              Deny Request
            </Button>
          </ActionButtonsDiv>
        </ContentDiv>
      </ContainerDiv>
    </>
  ) : (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Loading />
    </div>
  );
}

export default EarlyTerminationDenialForm;
