import React, { Component } from 'react';

import PropertySelect from 'components/properties/PropertySelect';

interface IProperty {
  id: number | void;
  full_address: string;
}
interface IProps {
  value?: IProperty;
}
interface IState {
  selectedProperty: IProperty | null;
}

class PropertySelectUnitForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const selectedProperty = props.value?.id !== null ? props.value : null;
    this.state = {
      selectedProperty: selectedProperty || null
    };
  }

  public onPropertyChange = (selectedProperty) => this.setState({ selectedProperty });

  public render() {
    const { selectedProperty } = this.state;

    return (
      <div className="form-group">
        <label className="control-label col-sm-12">Property</label>
        <div className="col-sm-10">
          <PropertySelect
            id="unit_property_id"
            name="unit[property_id]"
            labelKey="full_address"
            placeholder="123 Main Street, Brooklyn, NY, United States"
            value={selectedProperty}
            onChange={this.onPropertyChange}
          />
        </div>
      </div>
    );
  }
}
export default PropertySelectUnitForm;
