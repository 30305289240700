import Decimal from 'decimal.js';

export enum PaymentOption {
  DIRECT_DEPOSIT = 'Direct deposit',
  PHYSICAL_CHECK = 'Physical check'
}

export interface LossOfRentData {
  id?: number;
  rentArrearBegin?: number;
  arrearDate: { date: Date; updated: boolean };
  arrearAmountCents: Decimal;
  lateFeeCents: Decimal;
  otherFeeCents: Decimal;
}

export interface DamageData {
  dateOfLoss: { date: Date; updated: boolean };
  amountCents: Decimal;
  damagedRoom: string;
  subCategories: string[];
}

export interface ACHInfo {
  routingNumber: string;
  accountNumber: string;
  nickname?: string;
}

export interface CheckingInfo {
  addressOne: string;
  addressTwo: string;
  googlePlaceId?: string;
  city: string;
  state: string;
  zip: string;
}

export interface Errors {
  propertyId?: string;
  policyId?: string;
  dateOfLoss?: string;
  lossOfRentObj?: LossOfRentObj;
  damagedRoom?: string;
  damageAmount?: string;
  subcategory?: string;
  claimType?: string;
  subcategory_2?: string;
  attachments?: string;
  paymentSelect?: string;
  accountConfirm?: string;
  nickname?: string;
  routingNumber?: string;
  accountNumber?: string;
  addressOne?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface LossOfRentObj {
  loss_of_rent_line_items?: {
    date_of_loss?: string[];
    errors?: {
      index?: number;
      arrears_amount_cents?: string[];
      date_of_loss?: string[];
      loss_of_rent_type?: string[];
    };
    id?: number;
  }[];
}
export interface SavedAccount {
  id: number | null;
  nickname: string;
}
