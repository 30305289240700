import React, { useState } from 'react';
import styled from '@emotion/styled';
import { EditIcon, TextInput as TextInputBase, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { useMutation, QueryClientProvider, QueryClient } from 'react-query';
import slaUpdateApi from './api';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import { ToastContextProvider } from '../toast/toast-context';
import { AxiosError } from 'axios';

const TextInput = styled(TextInputBase)({
  height: '32px!important'
});

const SLA = styled.p([
  FONTS.p1Medium,
  {
    color: PALETTE.neutral55
  }
]);

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center'
});

const client = new QueryClient();
const LandlordPolicySlaNumber: React.FC<{ sla_number: string; id: number }> = ({ sla_number, id }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [slaNumber, setSlaNumber] = useState<string>(sla_number);
  const { isLoading, mutate } = useMutation(slaUpdateApi);
  const { addToast } = useToast();

  const handleChange = (e) => {
    setSlaNumber(e.target.value);
  };

  const onClickOut = (e) => {
    setEditMode(false);
    setSlaNumber(sla_number);
  };

  const submit = (e) => {
    if (e.key === 'Enter') {
      mutate(
        { sla_number: slaNumber, id: id },
        {
          onSuccess: () => {
            setEditMode(false);
            addToast('SLA Number was successfully updated', 'info', TOAST_STATUS.SUCCESS);
          },
          onError: (error: AxiosError) => {
            let message = error?.response?.data.errors;
            addToast(message, 'error', TOAST_STATUS.ERROR);
          }
        }
      );
    }
  };

  return editMode ? (
    <TextInput
      id="sla_number"
      data-cy="slaNumber"
      type="string"
      onChange={(e) => handleChange(e)}
      value={slaNumber}
      onKeyPress={submit}
      onBlur={(e) => onClickOut(e)}
    />
  ) : (
    <Row>
      <SLA>{slaNumber}</SLA>
      <EditIcon onClick={() => setEditMode(true)} height={16} width={16} />
    </Row>
  );
};

const Index: React.FC<any> = (props) => {
  return (
    <QueryClientProvider client={client}>
      <ToastContextProvider>
        <LandlordPolicySlaNumber sla_number={props.landlord_document?.sla_number} id={props.landlord_document.id} />
      </ToastContextProvider>
    </QueryClientProvider>
  );
};

export default Index;
