import React from 'react';
import { render, screen, act, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import PolicyRenewalForm from '../Invitation/PolicyRenewalForm';
import makeServer from '../testUtils/server';
import { MemoryRouter } from 'react-router-dom';
import { ReactQueryWrapper } from '../testUtils/Wrapper';
import { IPolicy } from '../Invitation/CreatePolicyRenewalInvitation';

let server;

const policy: IPolicy = {
  address: '76 Java St',
  first_name: 'Test',
  last_name: 'Test',
  id: 1,
  unit_name: '2',
  coverage_end_date: '2020-03-01',
  next_policy_id: null
};

describe('<CreatePolicyRenewalInvitation />', () => {
  beforeEach(() => {
    server = makeServer();
    jest.useFakeTimers('modern');
    jest.setSystemTime(new Date('2020-01-01'));
  });

  afterEach(() => {
    server.shutdown();
  });

  test('renders the TypeAhead input', async () => {
    render(
      <MemoryRouter>
        <ReactQueryWrapper>
          <PolicyRenewalForm
            onPolicyChange={() => jest.fn()}
            setPolicy={() => jest.fn()}
            invite_id={'1'}
            referredPolicyId={undefined}
          />
        </ReactQueryWrapper>
      </MemoryRouter>
    );

    const input = await screen.getByLabelText('Name');
    expect(input).toBeDefined();
  });

  test('It should not allow for email to be updated', async () => {
    render(
      <MemoryRouter>
        <ReactQueryWrapper>
          <PolicyRenewalForm
            onPolicyChange={() => jest.fn()}
            setPolicy={() => jest.fn()}
            invite_id={'1'}
            policy={policy}
            referredPolicyId={undefined}
          />
        </ReactQueryWrapper>
      </MemoryRouter>
    );

    const emailField = await screen.getByLabelText(/Email/i);
    expect(emailField).toHaveAttribute('disabled');
  });

  test('It should not allow for phone to be updated', async () => {
    render(
      <MemoryRouter>
        <ReactQueryWrapper>
          <PolicyRenewalForm
            onPolicyChange={() => jest.fn()}
            setPolicy={() => jest.fn()}
            invite_id={'1'}
            policy={policy}
            referredPolicyId={undefined}
          />
        </ReactQueryWrapper>
      </MemoryRouter>
    );

    const phoneField = await screen.getByLabelText(/Phone number/i);
    expect(phoneField).toHaveAttribute('disabled');
  });

  test('It should have a non editable lease address', async () => {
    render(
      <MemoryRouter>
        <ReactQueryWrapper>
          <PolicyRenewalForm
            onPolicyChange={() => jest.fn()}
            setPolicy={() => jest.fn()}
            invite_id={'1'}
            policy={policy}
            referredPolicyId={undefined}
          />
        </ReactQueryWrapper>
      </MemoryRouter>
    );

    // update address
    const leaseAddressField = await screen.getByLabelText(/Lease address/i);
    expect(leaseAddressField).toHaveAttribute('disabled');
  });

  test('It should have an editable unit', async () => {
    render(
      <MemoryRouter>
        <ReactQueryWrapper>
          <PolicyRenewalForm
            onPolicyChange={() => jest.fn()}
            setPolicy={() => jest.fn()}
            invite_id={'1'}
            policy={policy}
            referredPolicyId={undefined}
          />
        </ReactQueryWrapper>
      </MemoryRouter>
    );

    // update unit
    const unitField = await screen.getByRole('combobox', { name: '' });
    await userEvent.type(unitField, '');
    userEvent.type(unitField, 'Tr 1');
    const unitInput = await screen.getAllByDisplayValue('Tr 1');
    expect(unitInput).toBeDefined();
  });

  test('Lease start date input should be defined', async () => {
    render(
      <MemoryRouter>
        <ReactQueryWrapper>
          <PolicyRenewalForm
            onPolicyChange={() => jest.fn()}
            setPolicy={() => jest.fn()}
            invite_id={'1'}
            policy={policy}
            referredPolicyId={undefined}
          />
        </ReactQueryWrapper>
      </MemoryRouter>
    );

    const leaseStartDateField = await screen.getByRole('textbox', { name: 'Lease start' });
    userEvent.click(leaseStartDateField);
    expect(leaseStartDateField).toBeDefined();
  });

  test('Lease end date input should be defined', async () => {
    render(
      <MemoryRouter>
        <ReactQueryWrapper>
          <PolicyRenewalForm
            onPolicyChange={() => jest.fn()}
            setPolicy={() => jest.fn()}
            invite_id={'1'}
            policy={policy}
            referredPolicyId={undefined}
          />
        </ReactQueryWrapper>
      </MemoryRouter>
    );

    const leaseEndDateField = await screen.getByRole('textbox', { name: 'Lease end' });
    userEvent.click(leaseEndDateField);
    expect(leaseEndDateField).toBeDefined();
  });

  test('It should allow for rent to be updated', async () => {
    render(
      <MemoryRouter>
        <ReactQueryWrapper>
          <PolicyRenewalForm
            onPolicyChange={() => jest.fn()}
            setPolicy={() => jest.fn()}
            invite_id={'1'}
            policy={policy}
            referredPolicyId={undefined}
          />
        </ReactQueryWrapper>
      </MemoryRouter>
    );

    // monthly rent currency format, string to number
    const rentField = await screen.getByLabelText(/Monthly rent/i);
    userEvent.type(rentField, '1500');
    expect(rentField).toHaveValue(1500);
  });
});
