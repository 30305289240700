import React from 'react';
import styled from '@emotion/styled';
import { FONTS, FormField as FormFieldBase, Checkbox, PALETTE } from '@sayrhino/rhino-shared-js';

interface StyledProps {
  error?: string;
}
const FormLabel = styled.p<StyledProps>(({ error }) => [
  error ? FONTS.p3Light : FONTS.p3,
  { color: error && PALETTE.alert100, marginBottom: 20 }
]);
const FormField = styled(FormFieldBase)<StyledProps>(({ error }) => ({
  borderBottom: error ? `1px solid ${PALETTE.alert100}` : `1px solid ${PALETTE.neutral25}`,
  marginTop: 23
}));

export interface ISavePayoutInfoProps {
  savePayoutInfo: boolean;
  setSavePayoutInfo: (value: boolean) => void;
  accountNickname: string;
  onChangeSaveACHInfo: (value: string) => void;
  nicknameError?: string;
}

const SavePayoutInfo: React.FC<ISavePayoutInfoProps> = ({
  savePayoutInfo,
  setSavePayoutInfo,
  accountNickname,
  onChangeSaveACHInfo,
  nicknameError
}) => {
  return (
    <>
      <Checkbox
        id="ACH-save-for-later-checkbox"
        css={{ minWidth: 20 }}
        label="Save this information for future claims"
        checked={savePayoutInfo}
        onCheckedChange={(value) => setSavePayoutInfo(value)}
      />

      {savePayoutInfo && (
        <>
          <FormField error={nicknameError}>
            <input
              placeholder={'e.g. Home Office'}
              id="ACH-nickname"
              value={accountNickname}
              onChange={(e) => onChangeSaveACHInfo(e.target.value)}
            />
          </FormField>
          <FormLabel error={nicknameError}>{nicknameError ?? 'Create nickname for account'}</FormLabel>
        </>
      )}
    </>
  );
};

export default SavePayoutInfo;
