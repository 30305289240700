import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IPropertySearchResult } from 'components/v2/App/interfaces';

type PropertySearchResults = IPropertySearchResult[];

// Remove the source parameter when removing the feature flag :killing_invites
export const getProperties = async (query: string, only_active: boolean, source?: string) => {
  const { data } = await axios.get<PropertySearchResults>(`/v2/admin/properties/search`, {
    params: { query, only_active, source }
  });
  return data;
};

export const getPropertiesByPropertyOwner = async (
  query: string,
  propertyOwnerId: number,
  limit = 20,
  issUI = true
) => {
  const { data } = await axios.get<PropertySearchResults>(
    `/v2/admin/property_owners/${propertyOwnerId}/properties/search`,
    { params: { query, limit, issUI } }
  );
  return data;
};

export const getPropertiesByAssociatedPartners = async (query?: string) => {
  const { data } = await axios.get<PropertySearchResults>(`/v2/admin/properties/search_related`, { params: { query } });
  return data;
};

export default function usePropertySearch(
  query: string,
  only_active: boolean
): QueryObserverResult<PropertySearchResults> {
  return useQuery<PropertySearchResults>(['propertySearch', query], () => getProperties(query, only_active));
}

export function usePropertiesByAssociatedPartnersSearch(query: string): QueryObserverResult<PropertySearchResults> {
  return useQuery<PropertySearchResults>(['propertySearch', query], () => getPropertiesByAssociatedPartners(query));
}
