import http from 'j-fetch';
import React, { Component } from 'react';
import Dashboard from './Dashboard';

interface IProps {
  states: [IActiveState];
  update_path: string;
  csrf_token: string;
}

interface IState {
  updatedStates: object;
}

interface IActiveState {
  active: boolean;
  full_name: string;
  name: string;
}

class Main extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      updatedStates: {}
    };
  }

  public sendNewActiveStates = (value) => {
    this.setState({updatedStates: value});
  }

  public onSubmit = () => {
    http.post({
      url: this.props.update_path,
      body: this.state.updatedStates,
      headers: { 'X-CSRF-Token': this.props.csrf_token },
      success: setTimeout(function() {
        $( '.page_content' ).prepend( '<p>Your changes have been saved.</p>' );
        location.reload();
      }, 1000)
    });
  }

  public render(): JSX.Element {
    return (
      <div className="page_content">
        <div className="page_content__heading">
          <div className="page_content__heading--bottom-bordered active-states-heading">
            <p className="page_content__title">
              Active Rhino States
            </p>
            <button
              className="button btn rhino_btn request_change_btn active-states-btn"
              onClick={this.onSubmit}
            >
              Save
            </button>
          </div>
        </div>
        <Dashboard states={this.props.states} sendNewActiveStates={this.sendNewActiveStates}/>
      </div>
    );
  }
}

export default Main;
