/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';

interface IProps {
  label: string;
  value: number;
  active: boolean;
  onClick: () => void;
}

const SearchResultsTypeFilterItem: React.FC<IProps> = ({ label, value, active, onClick }) => (
  <div
    css={{
      cursor: 'pointer',
      backgroundColor: active ? PALETTE.neutralDark : PALETTE.neutralLight,
      border: `1px solid ${PALETTE.neutral12}`,
      display: 'inline-block',
      borderRadius: 14,
      marginRight: 12,
      marginBottom: 12,
      padding: 2,
      userSelect: 'none'
    }}
    onClick={onClick}
  >
    <span
      css={{
        color: active ? PALETTE.neutralLight : PALETTE.neutralDark,
        marginLeft: 12
      }}
    >
      {label}
    </span>
    <span
      css={{
        marginLeft: 8,
        marginRight: 2,
        backgroundColor: active ? PALETTE.neutral65 : PALETTE.neutralDark,
        color: PALETTE.neutralLight,
        borderRadius: 20,
        padding: '0px 8px'
      }}
    >
      {value}
    </span>
  </div>
);

export default SearchResultsTypeFilterItem;
