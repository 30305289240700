import React from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { ButtonHabitat, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

type Props = JSX.IntrinsicElements['div'];

const StyledTab = styled.div({ borderBottom: `1px solid ${PALETTE.neutral12}`, display: 'flex', paddingLeft: '48px' });
const TabContainer = (props: Props) => {
  const { children } = props;
  return (
    <div>
      <StyledTab role="tabList">{children}</StyledTab>
    </div>
  );
};

export default TabContainer;
