import ApplicationTable from './application_table';

class TenantTable extends ApplicationTable {
  public selector = '#tenant-table';
  public pathPrefix = 'tenants/';
  public emptyMessageText = 'You have no tenants to view';

  protected columns = [
    {data: 'id'},
    {data: 'first_name'},
    {data: 'last_name'},
    {data: 'email'},
    {data: 'phone_number'},
    {data: 'birthdate'},
    {data: 'yearly_income'},
    {data: 'employer'},
    {data: 'college'},
    {data: 'address_line_one'},
    {data: 'address_line_two'},
    {data: 'address_city'},
    {data: 'address_state'},
    {data: 'address_zip'},
    {data: 'rhino_score'},
    {data: 'lease_start_date'},
    {data: 'lease_end_date'},
    {data: 'subscribed'}
  ];
  protected columnDefs = [
    {
      targets: [ 2, 3, 4, 5],
      data: undefined,
      defaultContent: ''
    }
  ];
}

export default TenantTable;
