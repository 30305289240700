import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IInvitationSummaryMetricsData } from 'components/v2/App/interfaces';

const getInvitationSummaryMetrics = async (): Promise<IInvitationSummaryMetricsData> => {
  const { data }: AxiosResponse<IInvitationSummaryMetricsData> = await axios.get<IInvitationSummaryMetricsData>(
    `/v2/admin/invitations/summary_metrics`
  );
  return data;
};

export function useInvitationSummaryMetrics(): QueryObserverResult<IInvitationSummaryMetricsData> {
  return useQuery<IInvitationSummaryMetricsData>('invitationSummaryMetrics', getInvitationSummaryMetrics);
}
