import React from 'react';
import { render } from '@testing-library/react';
import InvitationSummaryMetrics from '../InvitationSummaryMetrics';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';

describe('<InvitationSummaryMetrics />', () => {
  const renderSummaryMetrics = () =>
    render(
      <Wrapper>
        <InvitationSummaryMetrics />
      </Wrapper>
    );
  let server;

  beforeEach(() => (server = makeServer()));
  afterEach(() => server.shutdown());

  test('renders summary metrics', async () => {
    const { findByText, findAllByRole } = renderSummaryMetrics();

    const heading = await findByText('Invitation Summary');
    expect(heading).toBeDefined();

    const listItems = await findAllByRole('listitem');

    expect(listItems[0]).toHaveTextContent('Sent invitations');
    expect(listItems[0]).toHaveTextContent('1');

    expect(listItems[1]).toHaveTextContent('Subscribed');
    expect(listItems[1]).toHaveTextContent('1');
  });
});
