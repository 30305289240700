/** @jsx jsx */
import { parse } from 'qs';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDebouncedEffect from 'use-debounced-effect-hook';
import { IFilters } from '../interfaces';

export const FilterContext = createContext<{
  debouncedFilters: IFilters;
  filtersCount: number;
  selectedFilters: IFilters;
  setFilters: (args: Partial<IFilters>) => void;
}>({
  debouncedFilters: {
    states: [],
    properties: [],
    statuses: [],
    markets: [],
    administrators: [],
    claim_classifications: []
  },
  filtersCount: 0,
  selectedFilters: {
    states: [],
    properties: [],
    statuses: [],
    markets: [],
    administrators: [],
    claim_classifications: []
  },
  setFilters: ({}) => undefined
});

export const useFilters = () => {
  const location = useLocation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  const preSelectedStatus = queryParams.hasOwnProperty('status') && queryParams.status;
  const [selectedFilters, setSelectedFilters] = useState<IFilters>({
    states: [],
    properties: [],
    statuses: [],
    markets: [],
    administrators: [],
    claim_classifications: []
  });
  const setFilters = (newFilters) => {
    setSelectedFilters(Object.assign({}, selectedFilters, newFilters));
  };
  const [debouncedFilters, setDebouncedFilters] = useState<IFilters>(selectedFilters);
  useDebouncedEffect(() => setDebouncedFilters(selectedFilters), [selectedFilters], 300);
  useEffect(() => {
    if (preSelectedStatus) {
      setFilters({ statuses: [preSelectedStatus] });
    }
  }, [preSelectedStatus]);
  const filtersCount = Object.values(selectedFilters)
    .map((filter): number => Number(filter.length))
    .reduce((acc, value) => acc + value);

  return { debouncedFilters, filtersCount, selectedFilters, setFilters };
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
