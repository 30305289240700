import axios from 'axios';
import { IUpdate } from 'components/v2/App/interfaces';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

interface IUpdatesResult {
  data: IUpdate[];
  meta: {
    total_count: number;
  };
}

const getUpdates = async (page = 1) => {
  const { data } = await axios.get(`/v2/admin/updates?page=${page}`);
  return data;
};

export default function useUpdates(): InfiniteQueryObserverResult<IUpdatesResult> {
  return useInfiniteQuery('updates', ({ pageParam }) => getUpdates(pageParam), {
    getNextPageParam: (lastGroup, _allGroups) => lastGroup.meta.next_page
  });
}
