/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useAdminSummaryMetrics, usePropertyManagerSummaryMetrics } from 'api/v2/useSummaryMetrics';
import React from 'react';
import { QueryObserverResult } from 'react-query';

import AdminDashboardPanelItems from './AdminDashboardPanelItems';
import PropertyManagerDashboardPanelItems from './PropertyManagerDashboardPanelItems';

import DashboardPanelHeader from './DashboardPanelHeader';
import DashboardPanelList from './DashboardPanelList';

import { IAdminSummaryMetricsData, IPropertyManagerSummaryMetricsData } from './interfaces';

import useUserRole from './utils/userRole';

const DashboardPanel = () => {
  const { isAdmin, isPropertyManager, isPropertyManagerAdmin, isListingAgent } = useUserRole();

  const showSummaryMetrics = isAdmin || isPropertyManager || isPropertyManagerAdmin || isListingAgent;

  const renderItems = () => {
    if (isAdmin) {
      const response: QueryObserverResult<IAdminSummaryMetricsData> = useAdminSummaryMetrics();
      if (response.isLoading) {
        return <div>Loading...</div>;
      }
      const data: IAdminSummaryMetricsData | undefined = response.data;
      return data && <AdminDashboardPanelItems data={data} />;
    } else if (isPropertyManager || isPropertyManagerAdmin || isListingAgent) {
      const response: QueryObserverResult<IPropertyManagerSummaryMetricsData> = usePropertyManagerSummaryMetrics();
      if (response.isLoading) {
        return <div>Loading...</div>;
      }
      const data: IPropertyManagerSummaryMetricsData | undefined = response.data;
      return data && <PropertyManagerDashboardPanelItems data={data} />;
    }
  };

  return showSummaryMetrics ? (
    <React.Fragment>
      <DashboardPanelHeader>Portfolio overview</DashboardPanelHeader>
      <DashboardPanelList>{renderItems()}</DashboardPanelList>
    </React.Fragment>
  ) : null;
};

export default DashboardPanel;
