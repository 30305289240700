import styled from '@emotion/styled';
import { PALETTE, FONTS, DropdownMenu, FONT_FAMILY } from '@sayrhino/rhino-shared-js';

export const StyledCoverageRulesBorder = styled.div({
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '8px',
  padding: '24px 48px',
  margin: '0px 0px'
});
export const StyledCheckboxPadding = styled.div({
  paddingLeft: '16px',
  paddingBottom: '16px'
});
export const StyledH5 = styled.h5([FONTS.h5]);
export const StyledH6 = styled.h5([FONTS.h6Medium, { paddingBottom: '8px' }]);
export const StyledDropDownContainer = styled.div({
  marginBottom: '16px'
});
export const StyledDropdownMenuTrigger = styled(DropdownMenu.Trigger)(
  {
    width: '100%',
    textAlign: 'left',
    color: '#000000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    marginTop: '4px'
  },
  (props) => ({
    backgroundColor: `${props.disabled && PALETTE.neutral12}`
  })
);
export const StyledDropdownMenuItem = styled(DropdownMenu.Item)({ minWidth: '650px' });

export const ButtonSubP = styled.p(FONT_FAMILY.extendedLight, {
  lineHeight: '24px',
  color: PALETTE.neutral88
});
export const FormInputSectionTitle = styled.h6([FONTS.h6Demi], { color: PALETTE.neutral88 });
export const FormInputSectionSubtitle = styled.p([FONTS.p2Light, { marginTop: '8px', marginBottom: '24px' }]);
