import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import useWithdrawalDetails from 'api/v2/useWithdrawalDetails';
import createWithdrawal from 'api/v2/createWithdrawal';
import { FormMode } from '../../interfaces';
import useToast from '../../toast/use-toast';
import useFundsBankAccounts from 'api/v2/useFundsBankAccounts';
import useUpdateWithdrawalMutation from 'api/v2/updateWithdrawal';
import { IBankAccountInfo, IWithdrawal, IWithdrawalInfo } from '../types';
import { withdrawalsQueryKey } from 'api/v2/useWithdrawals';

const useWithdrawalForm = () => {
  const { withdrawalId } = useParams();
  const formMode = withdrawalId ? FormMode.EDIT : FormMode.CREATE;
  const { data: withdrawalData, isLoading: isWithdrawalLoading } = useWithdrawalDetails(withdrawalId) ?? {};
  const { data: accountsData, isLoading: isBankAccountsLoading } = useFundsBankAccounts();
  const bankAccountsData = accountsData?.data || [];
  const navigate = useNavigate();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (withdrawalData) {
      setWithdrawal(withdrawalData.withdrawal);
      setBankAccount(withdrawalData.bank_account);
    }
  }, [withdrawalData]);

  const [withdrawal, setWithdrawal] = useState<IWithdrawal>(
    withdrawalData?.withdrawal ?? ({} as unknown as IWithdrawal)
  );
  const [bankAccount, setBankAccount] = useState<IBankAccountInfo>(
    withdrawalData?.bank_account ?? ({} as unknown as IBankAccountInfo)
  );
  const [errors, setErrors] = useState<IWithdrawalInfo>();
  const { mutate: createWithdrawalMutation, isLoading: isLoadingCreate } = useMutation(createWithdrawal, {});
  const { mutate: updateWithdrawalMutation, isLoading: isLoadingUpdate } = useUpdateWithdrawalMutation();

  const isLoadingSubmitForm = isLoadingCreate || isLoadingUpdate;

  const create = (params: IWithdrawalInfo) => {
    createWithdrawalMutation(
      {
        user_bank_account_uid: params.user_bank_account_uid,
        user_bank_account_type: params.user_bank_account_type,
        withdrawal_amount: params.amount_in_cents
      },
      {
        onSuccess: (res) => {
          const path = generatePath('/admin/funds');
          queryClient.invalidateQueries(withdrawalsQueryKey);
          navigate(`${path}`);
          addToast('Withdrawal Submitted');
        },
        onError: (e: AxiosError) => {
          handleError(e);
        }
      }
    );
  };

  const update = (params: IWithdrawalInfo) => {
    updateWithdrawalMutation(
      {
        ...withdrawal,
        user_bank_account_uid: params.user_bank_account_uid,
        user_bank_account_type: params.user_bank_account_type,
        amount_in_cents: params.amount_in_cents
      },
      {
        onSuccess: (res) => {
          const path = generatePath('/admin/funds');
          queryClient.invalidateQueries(withdrawalsQueryKey);
          navigate(`${path}`);
          addToast('Withdrawal updated');
        },
        onError: (e: AxiosError) => {
          handleError(e);
        }
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formMode === FormMode.CREATE) {
      create(withdrawal);
    } else {
      update(withdrawal);
    }
  };

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 422) {
      const data = error.response?.data;
      const responseErrors = JSON.parse(data.errors);
      if (responseErrors.error_info === 'insufficient_free_funds') {
        navigate(-1);
        addToast('Insuffient free funds to withdraw', 'error');
      } else {
        setErrors(JSON.parse(responseErrors));
      }
    } else {
      navigate(-1);
      addToast('Something went wrong', 'error');
    }
  };

  return {
    withdrawal,
    bankAccount,
    setWithdrawal,
    setBankAccount,
    create,
    handleSubmit,
    handleError,
    bankAccounts: bankAccountsData ?? [],
    errors,
    formMode,
    isLoading: isWithdrawalLoading || isBankAccountsLoading,
    isLoadingSubmitForm
  };
};

export { useWithdrawalForm };
