/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { ChangeEvent } from 'react';
import { jsx, css } from '@emotion/core';
import { PALETTE, FONTS, FONT_FAMILY, CurrencyInputUSD } from '@sayrhino/rhino-shared-js';

import { centsToUSDFormatter, sanitizeCurrencyInput } from '../utils';
import { FormField } from '../PolicyDetails';
import Decimal from 'decimal.js';
import { ICoverageOptionSelectProps } from '.';
import { DOLLAR_AMOUNT } from '../utils/constants';

const errorCSS = css([
  {
    color: 'red',
    marginTop: 4,
    marginBottom: 8
  },
  FONTS.p3
]);
const hideCSS = css({ display: 'none' });
const hintCSS = css([FONTS.p3, { color: PALETTE.neutral65, marginTop: 4, marginBottom: 8 }]);

export default function DollarAmountOption(props: ICoverageOptionSelectProps) {
  const { coverageInfo, disabled, errors } = props;
  const { selected, setSelected } = coverageInfo;
  const options = coverageInfo.result.data;
  const coverageAmountCents = selected.value;

  let maxCoverage: string | undefined;
  if (options?.max_coverage_amount_cents !== undefined) {
    maxCoverage = centsToUSDFormatter(new Decimal(options.max_coverage_amount_cents));
  }
  let formattedCoverageAmount: string | undefined;
  if (coverageAmountCents) {
    formattedCoverageAmount = centsToUSDFormatter(coverageAmountCents);
  }

  const onChange = (value) => {
    setSelected({
      type: DOLLAR_AMOUNT,
      value: new Decimal(value)
    });
  };

  return (
    <div
      css={[
        {
          marginTop: 12,
          marginLeft: 46,
          marginBottom: 18,
          paddingBottom: 20,
          borderBottom: `${PALETTE.neutral12} solid 1px`
        }
      ]}
    >
      <CurrencyInputUSD
        name="coverage amount"
        id="coverageAmount"
        value={Number(coverageAmountCents ?? 0)}
        readOnly={disabled}
        onChange={onChange}
        label="Coverage amount"
      />
      <p css={errors ? hideCSS : hintCSS}>Please enter a value up to {maxCoverage}</p>
      {errors && errors?.coverage_amount_cents && <p css={errorCSS}> {errors.coverage_amount_cents} </p>}
      <p css={FONTS.p2}>
        This is set as the maximum coverage amount for this property. If you think this is incorrect, please
        <a
          href="https://support.sayrhino.com/hc/en-us/requests/new"
          css={[FONT_FAMILY.extendedBold, { textDecoration: 'underline', marginLeft: 4 }]}
          target="_blank"
        >
          submit a support ticket
        </a>
        .
      </p>
    </div>
  );
}
