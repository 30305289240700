import styled from '@emotion/styled';
import { ActionButton, AlertIcon, Loading, PALETTE, Switch, Tag } from '@sayrhino/rhino-shared-js';
import useDeletePropertyOwnerStatus from 'api/v2/deletePropertyOwner';
import useUpdatePropertyOwnerStatus from 'api/v2/updatePropertyOwnerStatus';
import usePartnerIntegration from 'api/v2/usePartnerIntegration';
import { PROPERTY_OWNERS_CACHE_KEY } from 'api/v2/usePropertyOwners';
import { AxiosError } from 'axios';
import { Decimal } from 'decimal.js';
import React, { Fragment, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from '../Account/NavBar';
import ErrorModal from '../Integrations/ErrorModal';
import WarningModal from '../Integrations/WarningModal';
import { CoverageTypeEnum, PartnerIntegrationStatusEnum } from '../interfaces';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import { ROUTES } from '../utils/routeHelpers';
import useUserRole from '../utils/userRole';
import ReportForm from './GenerateReportForm';
import IntegrationDetails from './IntegrationDetails';
import ActionButtons from './PropertyOwnerDetails/ActionButtons';
import RentersInsuranceRequirementDetails from './RentersInsuranceRequirementDetails';
import RequiresInvitationDetails from './RequiresInvitationDetails';
import {
  Banner,
  CoverageLabel,
  CoverageValue,
  DisplayField,
  InlineNotification,
  LoadingWrapper,
  NotificationIcon,
  NotificationMessage,
  NotificationWrapper,
  SpacingAbove,
  StyledContent,
  StyledInlineErrorHeader,
  StyledInlineErrorText,
  StyledStatus,
  StyledStatusWrapper,
  StyledValidationErrorBanner,
  Subtitle,
  TitleWrapper,
  TopActionContainer
} from './Styled';
import usePropertyOwner from './usePropertyOwner';
import DepositAccountsSection from './DepositAccountsSection';
import GuarantorCoverageCapable from './GuarantorCoverageCapableSection';
import InterestedParty from '../Lemonade/InterestedParty';

export interface LocationState {
  prevPath?: string;
}

const PropertyOwnerDetails: React.FC = () => {
  const { propertyOwnerId } = useParams();
  const { data, isSuccess } = usePropertyOwner(Number(propertyOwnerId));
  const partnerIntegrationId = data?.integration_partner_id;
  const propertyOwnerIntegrationLogo = data?.logo;
  const canUpdateClaimClassification = data?.actions?.can_update_claim_classification;
  const propertyOwnerClaimClassification = data?.claim_classification;
  const interestedParty = data?.lemonade_interested_party;
  const { data: partnerIntegrationData } = usePartnerIntegration(partnerIntegrationId) ?? {};
  const daysRemainingToEdit = data?.days_remaining_to_edit;
  const rentersInsuranceRequirementAttributes = data?.renters_insurance_requirement_attributes;
  const { isAdmin, isPropertyManagerAdmin } = useUserRole();
  const { mutate: changePropertyOwnerStatus } = useUpdatePropertyOwnerStatus();
  const { mutate: deletePropertyOwner, isLoading } = useDeletePropertyOwnerStatus();
  const [isActive, setIsActive] = useState<boolean>(data?.active ?? false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errors, setErrors] = useState<string[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [customError, setCustomError] = useState<boolean>(false);
  const [showReportForm, setShowReportForm] = useState<boolean>(false);
  const { addToast } = useToast();
  const canSeeClaimTag = propertyOwnerClaimClassification && canUpdateClaimClassification;
  const integrationType = data?.integration_type;

  const queryClient = useQueryClient();
  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const default_coverage_amount = new Decimal(data?.default_coverage_amount_cents || 0).div(100).toString();
  const navigate = useNavigate();

  const propertyOwnerStatusChange = (value) => {
    changePropertyOwnerStatus(
      { id: Number(propertyOwnerId), active: value },
      {
        onSuccess: (response) => {
          setIsActive(response.data.active);
          addToast(`Property Owner status changed successfully.`, '', TOAST_STATUS.SUCCESS);
          queryClient.invalidateQueries([PROPERTY_OWNERS_CACHE_KEY]);
          queryClient.invalidateQueries(['property owner details', propertyOwnerId]);
        },
        onError: (error: AxiosError) => {
          let validationErrors: string[] = [];

          for (var [key, value] of Object.entries(error.response?.data.errors)) {
            validationErrors.push(key + ' - ' + value);
          }

          setErrors(validationErrors);
          setShowErrorModal(true);
        }
      }
    );
  };

  const propertyOwnerDelete = () => {
    deletePropertyOwner(Number(propertyOwnerId), {
      onSuccess: (response) => {
        closeModal();
        queryClient.invalidateQueries([PROPERTY_OWNERS_CACHE_KEY]);
        addToast(`Property Owner successfully deleted.`, '', TOAST_STATUS.SUCCESS);
        navigate(ROUTES.propertyOwner);
      },
      onError: (error: AxiosError) => {
        const customErrorMessage = error.response?.data.errors;
        if (error.response?.status === 422) {
          setShowErrorModal(true);
          setCustomError(true);
          setErrors(customErrorMessage.base);
          closeModal();
        } else {
          closeModal();
          addToast('Something went wrong', 'neutral_warning', TOAST_STATUS.ERROR);
        }
      }
    });
  };

  const integrationValidation = partnerIntegrationData?.integration?.integration_validation;
  const validationErrorBannerText = () => {
    if (integrationValidation) {
      if (integrationValidation.credential_check_status === PartnerIntegrationStatusEnum.FAILED) {
        return { title: 'Credentials Validation failed', subtext: 'Validation method failed in sidekiq.' };
      } else if (integrationValidation.credential_check_status === PartnerIntegrationStatusEnum.ERROR) {
        return { title: 'Credentials are invalid', subtext: 'Please edit integration details.' };
      } else if (integrationValidation.properties_match_status === PartnerIntegrationStatusEnum.FAILED) {
        return { title: 'Properties Validation failed', subtext: 'Validation method failed in sidekiq.' };
      } else if (integrationValidation.properties_match_status === PartnerIntegrationStatusEnum.ERROR) {
        return { title: 'Properties match error', subtext: 'Update properties and try validating again.' };
      } else if (integrationValidation.unit_match_status === PartnerIntegrationStatusEnum.FAILED) {
        return { title: 'Unit Validation failed', subtext: 'Validation method failed in sidekiq.' };
      } else if (integrationValidation.unit_match_status === PartnerIntegrationStatusEnum.ERROR) {
        return { title: 'Unit match error', subtext: 'Update units and try validating again.' };
      }
    }
  };

  const OrangeNotificationBanner = styled.div({
    margin: 'auto',
    marginTop: '32px',
    borderRadius: '8px',
    padding: '0px 0px 0px 48px'
  });

  useEffect(() => {
    setIsActive(data?.active ?? false);
  }, [data?.active]);

  const renderReportsButton = () => {
    if (isAdmin && partnerIntegrationId) {
      return !showReportForm ? (
        <ActionButton
          aria-label="Go to reports form"
          variant="noIcon"
          id="reportButton"
          usage="neutral"
          onClick={() => setShowReportForm(true)}
        >
          Get reports
        </ActionButton>
      ) : (
        <ActionButton
          aria-label="Go back to property owner details"
          icon="arrowLeft"
          variant="default"
          id="reportButton"
          usage="neutral"
          onClick={() => setShowReportForm(false)}
        >
          Go back
        </ActionButton>
      );
    }
  };

  const renderActionButtons = () => {
    return (
      !showReportForm &&
      (data?.actions.edit || data?.actions.delete) && (
        <ActionButtons
          showEdit={data?.actions.edit}
          showDelete={data?.actions.delete}
          deleteAction={openModal}
          propertyOwnerId={Number(propertyOwnerId)}
        />
      )
    );
  };

  return isSuccess ? (
    <>
      <ErrorModal
        isOpen={showErrorModal}
        closeModal={() => setShowErrorModal(false)}
        title={customError ? 'There has been an error' : 'Unable to change property owner status'}
        subTitle={
          customError ? '' : 'You cannot make this status change until the following action has been completed:'
        }
        errors={errors}
      />

      <WarningModal
        title="Are you sure you want to delete the Property Owner?"
        subTitle={
          <Fragment>
            This action will also delete all properties and integrations assigned to this Property Owner.
          </Fragment>
        }
        isOpen={showModal}
        closeModal={closeModal}
        handleConfirm={propertyOwnerDelete}
        isLoading={isLoading}
        confirmButtonText="Yes, delete property owner"
      />
      <NavBar
        referrer={'..'} // will take user to the parent route
        component={
          isAdmin ? (
            <StyledStatusWrapper>
              <StyledStatus isActive={isActive}>Status: {isActive ? 'On' : 'Off'}</StyledStatus>
              <Switch
                aria-label={`Toggle active property owner`}
                id={'property-owner-toggle'}
                checked={isActive}
                onCheckedChange={(value) => propertyOwnerStatusChange(value)}
              />
            </StyledStatusWrapper>
          ) : undefined
        }
      />
      {isPropertyManagerAdmin &&
        (!rentersInsuranceRequirementAttributes ||
          data?.renters_insurance_requirement_attributes?.requires_renters_insurance === null) && (
          <OrangeNotificationBanner>
            <InlineNotification variant="lowPriority">
              Update &mdash; Missing Renters Insurance requirements
              <AlertIcon color={PALETTE.warning100} height="20px" width="20px" />
            </InlineNotification>
          </OrangeNotificationBanner>
        )}
      {!!daysRemainingToEdit && (
        <NotificationWrapper>
          <InlineNotification variant="highPriority" aria-label="editing notification">
            <NotificationMessage>
              You have {daysRemainingToEdit} day{daysRemainingToEdit > 1 ? 's' : ''} remaining to make any changes
            </NotificationMessage>
            <NotificationIcon color={PALETTE.alert100} height="20px" width="20px" />
          </InlineNotification>
        </NotificationWrapper>
      )}

      {validationErrorBannerText() && (
        <Banner>
          <StyledValidationErrorBanner>
            <StyledInlineErrorHeader>{validationErrorBannerText()?.title}</StyledInlineErrorHeader>
            <StyledInlineErrorText>{validationErrorBannerText()?.subtext}</StyledInlineErrorText>
          </StyledValidationErrorBanner>
        </Banner>
      )}
      <TitleWrapper>
        {data?.name}
        {canSeeClaimTag && (
          <Tag id="claim-classification">Claims - {propertyOwnerClaimClassification?.toUpperCase()}</Tag>
        )}
      </TitleWrapper>

      <TopActionContainer>
        {renderReportsButton()}
        {renderActionButtons()}
      </TopActionContainer>
      {!showReportForm ? (
        <>
          <Banner>
            {isAdmin && (
              <IntegrationDetails
                partnerIntegrationId={partnerIntegrationId ?? undefined}
                propertyOwnerIntegrationLogo={propertyOwnerIntegrationLogo}
              />
            )}

            {isAdmin && <DepositAccountsSection depositAccountsEnabled={data?.deposit_accounts_enabled} />}

            {isAdmin && <GuarantorCoverageCapable guarantorCoverageCapable={data?.guarantor_coverage_capable} />}

            <Subtitle>Coverage Type</Subtitle>
            {isAdmin && (
              <StyledContent>
                Standard security deposit amount for this property owner's properties. May be a dollar amount or a rent
                multiplier. This will be set as the default for manual invitations sent to renters.
              </StyledContent>
            )}
            <SpacingAbove>
              <DisplayField>
                {data?.default_coverage_type === CoverageTypeEnum.MULTIPLIER ? (
                  <>
                    <CoverageValue id="coverage-multiplier">
                      {data?.coverage_multiplier_limits?.map((multiplier) => `${Number(multiplier)}x`).join(', ') ?? ''}
                    </CoverageValue>
                    <CoverageLabel htmlFor="coverage-multiplier">Coverage Multiplier</CoverageLabel>{' '}
                  </>
                ) : (
                  <>
                    <CoverageValue id="coverage-amount">$ {default_coverage_amount}</CoverageValue>
                    <CoverageLabel htmlFor="coverage-amount">Dollar amount</CoverageLabel>
                  </>
                )}
              </DisplayField>
            </SpacingAbove>

            <RentersInsuranceRequirementDetails
              rentersInsuranceRequirements={data?.renters_insurance_requirement_attributes}
            />

            <InterestedParty interestedParty={interestedParty} />

            <RequiresInvitationDetails requiresInvitation={data?.requires_invitation} />
          </Banner>
        </>
      ) : (
        <ReportForm
          integrationType={integrationType}
          partnerIntegrationId={partnerIntegrationId ?? undefined}
          flipView={() => setShowReportForm(false)}
        />
      )}
    </>
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default PropertyOwnerDetails;
