import React from 'react';
import { Link } from 'react-router-dom';
import { MinimizeIcon, LinkIcon } from '@sayrhino/rhino-shared-js';
import { useClipboard } from 'use-clipboard-copy';

import { NavBarWrapper, NavIconGroup } from './Styled';

interface IProps {
  referrer: string;
  component?: JSX.Element;
}
const NavBar: React.FC<IProps> = (props) => {
  return (
    <NavBarWrapper>
      <NavIconGroup>
        <Link
          to={props.referrer}
          css={{ display: 'flex', alignItems: 'center', marginRight: 48 }}
          aria-label="collapse"
        >
          <MinimizeIcon />
        </Link>
      </NavIconGroup>
      {props.component}
    </NavBarWrapper>
  );
};

export default NavBar;
