import Input from 'components/v2/input';
// @ts-ignore
import Link from 'components/v2/link/index';
import Validation from 'components/v2/validation';
import React, {Component} from 'react';
import { csrfToken } from 'utils/document';
import { post } from 'utils/request';

interface IProps {
  resource: string;
  token?: string;
  onChange?: (value: string) => void;
  setActiveButton?: (value: boolean) => void;
  value?: string;
}

interface IState {
  validStrength: boolean;
  errors: string;
  passwordString: string;
  passwordConfirmation: string;
  borderColor: string;
}

class CreatePassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      validStrength: false,
      errors: '',
      borderColor: '#E3E3E3',
      passwordString: '',
      passwordConfirmation: ''
    };
  }

  public setActiveStatus = (value: string): void => {
    this.validateStrength(value);

    this.setState({passwordString: value});
  }

  public validateStrength = (value: string) => {
    const token = csrfToken();
    post('/password/validate', { password: value, authenticity_token: token })
      .then((response: { valid: boolean }) => {
        if (response.valid === true) {
          this.setState({ validStrength: true});
        } else {
          this.setState({ validStrength: false});
        }
      }).catch((response: {errors: string}) => {
        const errors = response.errors || '';
        this.setState({ errors });
      });
  }

  public validateMatching = (value: string) => {
    this.setState({borderColor: 'red'});
    if (value === this.state.passwordString) {
      this.setState({borderColor: '#E3E3E3'});
      if (this.props.setActiveButton) {
        this.props.setActiveButton(true);
      }
    }
  }

  public render(): JSX.Element {
    const { resource, token, value } = this.props;
    return (
      <div style={styles.container}>
        <Input
          placeholder="New password"
          type="password"
          toggleVisibility={false}
          onPasswordChange={this.setActiveStatus}
          onChangeString={this.props.onChange}
          resource={resource}
          field={'password'}
          borderColor={this.state.borderColor}
          value={value}
        />
        { token &&
          <input
            resource={resource}
            name={resource + '[reset_password_token]'}
            id={resource + '_reset_password_token'}
            style={styles.hidden}
            value={token}
          />
        }

        <Input
          placeholder="Re-enter password"
          type="password"
          toggleVisibility={false}
          resource={resource}
          onChangeString={this.validateMatching}
          field={'password_confirmation'}
          borderColor={this.state.borderColor}
          value={value}
        />

        <div style={styles.validationSection}>
          <Validation
            label={'At least 8 characters'}
            active={this.state.validStrength}
          />
          <Validation
            label={'At least 1 number'}
            active={this.state.validStrength}
          />
          <Validation
            label={'At least 1 symbol'}
            active={this.state.validStrength}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: '0 40px'
  },
  validationSection: {
    paddingTop: '20px'
  },
  hidden: {
    display: 'none'
  }
};

export default CreatePassword;
