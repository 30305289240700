/** @jsx jsx */
import { jsx } from '@emotion/core';
import useInsurancePolicyPropertyFilterOptions from 'api/v2/useInsurancePolicyPropertyFilterOptions';
import useInsurancePolicyStaticFilterOptions from 'api/v2/useInsurancePolicyStaticFilterOptions';
import { useFilterContext } from '../utils/filters';
import Filters from './index';
import InsurancePolicyStatusFilterOptions from './InsurancePolicyStatusFilterOptions';
import PropertyFilterOptions from './PropertyFilterOptions';

const InsurancePolicyFilters = () => {
  const { selectedFilters, setFilters } = useFilterContext();

  return (
    <Filters
      selectedFilters={selectedFilters}
      setFilters={setFilters}
      staticFilterOptions={useInsurancePolicyStaticFilterOptions}
    >
      <div css={{ paddingLeft: '48px', paddingRight: '48px' }}>
        <PropertyFilterOptions
          css={{ marginBottom: '1.5rem' }}
          query={useInsurancePolicyPropertyFilterOptions}
          selectedProperties={selectedFilters.properties}
          selectedStates={selectedFilters.states}
          setFilters={setFilters}
        />
        <InsurancePolicyStatusFilterOptions
          query={useInsurancePolicyStaticFilterOptions}
          selectedStatuses={selectedFilters.statuses}
          setFilters={setFilters}
          title="Policies"
        />
      </div>
    </Filters>
  );
};

export default InsurancePolicyFilters;
