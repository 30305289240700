import { useEffect, useState } from 'react';
import { subscribeDownloadChannel } from 'utils/cable';
import axios, { AxiosRequestConfig } from 'axios';

interface IDownloadFileConfig {
  requestConfig: AxiosRequestConfig;
  payloadKey: string;
}

/**
 * Returns the isDownloading state and startDownload function to start file download
 *
 * @remarks
 * This function handles requests to download a file and the associated loading state
 *
 * @param requestConfig - Config options for requests: https://github.com/axios/axios#request-config
 * @param payloadKey - The key in the json response object that points to the file url
 * @returns The isDownloading status and the startDownload function
 */
export default function useFileDownloader({ requestConfig, payloadKey }: IDownloadFileConfig) {
  const [isDownloading, setIsDownloading] = useState(false);
  const startDownload = () => {
    setIsDownloading(true);
    subscribeDownloadChannel(
      async () => {
        const { data } = await axios.request(requestConfig);
        return data;
      },
      (data) => {
        window.location.replace(data[payloadKey]);
        setIsDownloading(false);
      }
    );
  }

  return { isDownloading, startDownload };
}
