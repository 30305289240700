import React from 'react';
import { render } from '@testing-library/react';
import InsurancePolicySummaryMetrics from '../InsurancePolicySummaryMetrics';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { IInsurancePolicySummaryMetricsData } from '../interfaces';

describe('<InsurancePolicySummaryMetrics />', () => {
  const renderSummaryMetrics = () =>
    render(
      <Wrapper>
        <InsurancePolicySummaryMetrics />
      </Wrapper>
    );
  let server;

  beforeEach(() => (server = makeServer()));
  afterEach(() => server.shutdown());

  test('renders summary metrics', async () => {
    const { findByText, findAllByRole } = renderSummaryMetrics();

    const heading = await findByText('Policy Summary');
    expect(heading).toBeDefined();

    const listItems = await findAllByRole('listitem');

    expect(listItems[0]).toHaveTextContent('Active policies');
    expect(listItems[0]).toHaveTextContent('2');

    expect(listItems[1]).toHaveTextContent('Covered units');
    expect(listItems[1]).toHaveTextContent('50%');

    expect(listItems[2]).toHaveTextContent('New policies this month');
    expect(listItems[2]).toHaveTextContent('3');

    expect(listItems[3]).toHaveTextContent('Renewals');
    expect(listItems[3]).toHaveTextContent('Renewed with Rhino');
    expect(listItems[3]).toHaveTextContent('Did not renew with Rhino');
  });

  test('does not render the Send An Invitation CTA if there are active policies or invitations', async () => {
    server.get('/v2/admin/insurance_policies/summary_metrics', () => {
      const response: IInsurancePolicySummaryMetricsData = {
        partial_quotes_count: 1,
        active_policies_count: 1,
        total_units_count: 2,
        new_policies_this_month_count: 3,
        renewed_policies_last_30_days_count: 4,
        expired_policies_last_30_days_count: 5,
        active_policies_coverage_begun: 2
      };

      return response;
    });

    const { queryByText } = renderSummaryMetrics();
    expect(queryByText('Send an invitation')).toBeNull();
  });

  test('renders the Send An Invitation CTA if there are no active policies or invitations', async () => {
    server.get('/v2/admin/insurance_policies/summary_metrics', () => {
      const response: IInsurancePolicySummaryMetricsData = {
        partial_quotes_count: 0,
        active_policies_count: 0,
        total_units_count: 2,
        new_policies_this_month_count: 3,
        renewed_policies_last_30_days_count: 4,
        expired_policies_last_30_days_count: 5,
        active_policies_coverage_begun: 2
      };

      return response;
    });

    const { queryByText } = renderSummaryMetrics();
    expect(queryByText('Send an invitation')).toBeDefined();
  });
});
