import React from 'react';
import { Checkbox } from './styles';
import { IOption } from './types';

interface IProps {
  name: string;
  value?: string[];
  options: IOption[];
  onChange: Function;
};

const Checkboxes = ({ options, onChange, name, value }: IProps) => {
  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = value && [...value] || [];
    const val = e.target.name;
    if (e.target.checked) {
      newChecked.push(val);
    } else {
      const index = newChecked.indexOf(val);
      newChecked.splice(index, 1);
    }
    onChange(name, newChecked);
  }

  const isChecked = (val: string) => value && value.indexOf(val) >= 0 || false

  return <ul>
    {options.map((option) => {
      return <li style={{ 'marginBottom': 7 }} key={option.id}>
        <Checkbox>
          <input
            type="checkbox"
            checked={isChecked(option.id)}
            name={option.id}
            style={{ cursor: 'pointer' }}
            onChange={onCheck} />
          <span></span> {option.text}
        </Checkbox>
      </li>
    })}
  </ul>
}

export default Checkboxes;
