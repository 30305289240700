/** @jsx jsx */
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { css, jsx } from '@emotion/core';
import { useState } from 'react';

interface IProps {
  cell: any;
}

const rowNumberCellCSS = css({
  width: '72px',
  alignItems: 'center',
  padding: '1rem 2rem 1rem 0'
});

const RowNumberCell = (props: IProps) => (
  <div css={[FONTS.p3Medium, rowNumberCellCSS]}>
    <span css={{ color: PALETTE.neutral25 }}>{props.cell.value + 1}</span>
  </div>
);

export default RowNumberCell;
