import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from 'utils/document';
import RhinoLogo from '../../../../../assets/images/RhinoLogoBlack.svg';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  validatePasswordLength,
  validatePasswordContainsSymbol,
  validatePasswordContainsNumber
} from '../utils/formValidations';
import {
  FormArea,
  Header,
  Heading,
  Logo,
  Page,
  Container,
  Card,
  TitleCard,
  Text,
  SubText,
  ValidationWrapper,
  Validation,
  Button,
  Password,
  ErrorNotification
} from './components';

import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export type Fields = {
  password_confirmation: string;
  email: string;
  password: string;
};

const Form: React.FC = () => {
  let params = new URL(window.location.href).searchParams;
  let firstName = params.get('first_name');
  let resetToken = params.get('reset_password_token');
  const email = params.get('email') ?? '';
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [fields, setFields] = useState<Fields>({ email } as Fields);
  const [valid, setValid] = useState<boolean>(false);

  function updatePassword(fields: Fields) {
    const token = csrfToken();
    return axios.put(
      `/users/password`,
      { user: { ...fields, reset_password_token: resetToken } },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token
        }
      }
    );
  }
  const { mutate, isLoading } = useMutation(updatePassword);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    mutate(fields, {
      onSuccess: (res) => {
        window.location.href = res.data.url;
      },
      onError: (error: AxiosError) => {
        setError(`Reset password link ${error?.response?.data.errors.reset_password_token[0]}`);
        setTimeout(() => setError(''), 5000);
      }
    });
  };

  function validatePassword() {
    if (
      fields.password == fields.password_confirmation &&
      validatePasswordLength(fields.password) &&
      validatePasswordContainsNumber(fields.password) &&
      validatePasswordContainsSymbol(fields.password)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  useEffect(() => {
    validatePassword();
  }, [fields]);

  return (
    <Page>
      <Header>
        <Logo src={RhinoLogo} alt="Rhino" />
      </Header>
      <FormArea onSubmit={handleSubmit}>
        <Container>
          <TitleCard>Welcome, {firstName}! First, let's create your account.</TitleCard>
          <Text>You're joining with the email </Text>
          <SubText>{email}</SubText>
        </Container>
        <Card>
          <Heading>Create a password</Heading>
          <Password
            id="password"
            placeholder="Enter Password"
            data-cy="password"
            onChange={(e) => setFields((prev) => ({ ...prev, password: e.target.value }))}
            rightIcon={
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={() => setShowPassword((prev) => !prev)}
              />
            }
            type={showPassword ? 'text' : 'password'}
          />
          <Password
            id="password_confirmation"
            data-cy="passwordConfirm"
            placeholder="Re-enter Password"
            onChange={(e) => setFields((prev) => ({ ...prev, password_confirmation: e.target.value }))}
            rightIcon={
              <FontAwesomeIcon
                icon={showConfirm ? faEyeSlash : faEye}
                onClick={() => setShowConfirm((prev) => !prev)}
              />
            }
            type={showConfirm ? 'text' : 'password'}
          />
          <ValidationWrapper>
            <Validation label={'At least 8 characters'} active={validatePasswordLength(fields.password)} />
            <Validation label={'At least 1 number'} active={validatePasswordContainsNumber(fields.password)} />
            <Validation label={'At least 1 symbol'} active={validatePasswordContainsSymbol(fields.password)} />
          </ValidationWrapper>
          {error && <ErrorNotification error={error} />}
          <Button
            children={isLoading ? 'Loading' : 'Create password'}
            variant="primary"
            type="submit"
            disabled={!valid}
          />
        </Card>
      </FormArea>
    </Page>
  );
};

export default Form;
