import styled from '@emotion/styled';
import { PALETTE, RhinoLogo, FONTS } from '@sayrhino/rhino-shared-js';
import React from 'react';
import Spacer from '../Integrations/SpacingTokens';

const LoginContainer = styled.div({
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  margin: '0 auto',
  backgroundColor: PALETTE.neutralLight,
  width: '420px'
});
const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 40px',
  alignItems: 'center',
  marginTop: '80px'
});

const HeaderStyle = styled.h3(FONTS.h3);
const StyledCopy = styled.p(FONTS.p1, {
  textAlign: 'center'
});

export const OktaRedirect = () => {
  return (
    <LoginContainer>
      <ContentWrapper>
        <Spacer amount={6} />
        <RhinoLogo />
        <Spacer amount={10} />
        <HeaderStyle>Single sign-on to Portal</HeaderStyle>
        <Spacer amount={8} />
        <StyledCopy>Authenticate your account by logging into Portal with Okta</StyledCopy>
        <Spacer amount={2} />
      </ContentWrapper>
    </LoginContainer>
  );
};

export default OktaRedirect;
