import axios from 'axios';
import { useQuery, QueryObserverResult } from 'react-query';
import { IClaimsAnalysts } from 'components/v2/App/interfaces';

const getClaimsAnalysts = async () => {
  const { data } = await axios.get<IClaimsAnalysts>('/v2/admin/administrators');

  return data;
};

export default function useClaimsAnalysts(): QueryObserverResult<IClaimsAnalysts> {
  return useQuery<IClaimsAnalysts>(['claimsAnalysts'], () => getClaimsAnalysts());
}
