import { IClaimPayout, IPayment } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useMutation, useQuery } from 'react-query';
import { csrfToken } from 'utils/document';

export const CLAIM_PAYOUTS = 'claim_payouts';

const getClaimPayments = async (id: number) => {
  const { data } = await axios.get<IClaimPayout[]>(`/v2/admin/claims/${id}/claim_payouts`);
  return data;
};

export default function useClaimPayments(id: number): QueryObserverResult<IClaimPayout[]> | undefined {
  return useQuery<IClaimPayout[]>([CLAIM_PAYOUTS, id], () => getClaimPayments(id), {
    enabled: !!id
  });
}

type IData = {
  payoutId?: number;
  claimId: number;
  formInfo: IPayment;
};

const addClaimPayment = async ({ claimId, formInfo }: IData) => {
  const authenticity_token = csrfToken();
  const { data } = await axios.post(`/v2/admin/claims/${claimId}/claim_payouts`, formInfo, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });

  return data;
};

const updateClaimPayment = async ({ claimId, formInfo, payoutId }: IData) => {
  const authenticity_token = csrfToken();
  const { data } = await axios.put(`/v2/admin/claims/${claimId}/claim_payouts/${payoutId}`, formInfo, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });

  return data;
};

const deleteClaimPayment = async ({ claimId, payoutId }) => {
  const authenticity_token = csrfToken();
  const { data } = await axios.delete(`/v2/admin/claims/${claimId}/claim_payouts/${payoutId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });

  return data;
};

export const useAddClaimPayment = () => {
  const { mutate: addPayment, ...rest } = useMutation(addClaimPayment);
  return {
    addPayment,
    ...rest
  };
};

export const useUpdateClaimPayment = () => {
  const { mutate: editPayment, ...rest } = useMutation(updateClaimPayment);
  return {
    editPayment,
    ...rest
  };
};

export const useDeleteClaimPayment = () => {
  const { mutate: deletePayment, ...rest } = useMutation(deleteClaimPayment);
  return {
    deletePayment,
    ...rest
  };
};
