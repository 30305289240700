import React from 'react';
import { render } from '@testing-library/react';
import DashboardPanel from '../DashboardPanel';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { UserContext } from '../utils/userRole';
import { IPropertyManagerSummaryMetricsData, userTypes } from '../interfaces';

describe('<DashboardPanel />', () => {
  const renderAsRole = (role) =>
    render(
      <UserContext.Provider value={{ id: 1, role: role, sessionId: '', isAutoApprove: false }}>
        <Wrapper>
          <DashboardPanel />
        </Wrapper>
      </UserContext.Provider>
    );
  let server;

  beforeEach(() => (server = makeServer()));
  afterEach(() => server.shutdown());

  describe('when signed in as an administrator', () => {
    test('renders summary metrics', async () => {
      const { findByText, findAllByRole } = renderAsRole('Administrator');
      const heading = await findByText('Portfolio overview');
      const listItems = await findAllByRole('listitem');

      expect(heading).toBeDefined();

      expect(listItems[0]).toHaveTextContent('Monthly subs goal');
      expect(listItems[0]).toHaveTextContent('8 / 9');

      expect(listItems[1]).toHaveTextContent('Sign up rate for invitations sent');
      expect(listItems[1]).toHaveTextContent(
        'Renters see the value Rhino brings to them when given the choice between a security deposit or Rhino.'
      );
      expect(listItems[1]).toHaveTextContent('50%');

      expect(listItems[2]).toHaveTextContent('Average time to first renter');
      expect(listItems[2]).toHaveTextContent('2 days');

      expect(listItems[3]).toHaveTextContent('Average time to claim payout');
      expect(listItems[3]).toHaveTextContent('1 day');

      expect(listItems[4]).toHaveTextContent('In-progress invitations');
      expect(listItems[4]).toHaveTextContent('5');

      expect(listItems[5]).toHaveTextContent('Total invitations');
      expect(listItems[5]).toHaveTextContent('8');

      expect(listItems[6]).toHaveTextContent('New claims');
      expect(listItems[6]).toHaveTextContent('7');
    });
  });

  describe('when signed in as a property manager', () => {
    test('renders summary metrics', async () => {
      server.get('/v2/admin/summary_metrics', () => {
        const response: IPropertyManagerSummaryMetricsData = {
          claims_in_review_count: 5,
          covered_units_count: 1402,
          in_progress_invitations_count: 140,
          invitations_completed_count: 98,
          renewed_policies_count: 86,
          total_invitations_count: 100,
          total_invitations_count_this_month: 200,
          total_units_count: 1800,
          units_up_for_renewal_count: 39
        };

        return response;
      });

      const { findByText, findAllByRole } = renderAsRole('PropertyManager');
      const heading = await findByText('Portfolio overview');
      const listItems = await findAllByRole('listitem');

      expect(heading).toBeDefined();

      expect(listItems[0]).toHaveTextContent('Covered units');
      expect(listItems[0]).toHaveTextContent('1402 / 1800');

      expect(listItems[1]).toHaveTextContent('Up for renewal');
      expect(listItems[1]).toHaveTextContent('39');

      expect(listItems[2]).toHaveTextContent('Renewed policies');
      expect(listItems[2]).toHaveTextContent('86');

      expect(listItems[3]).toHaveTextContent('Sign up rate for invitations sent');
      expect(listItems[3]).toHaveTextContent('98.0%');

      expect(listItems[4]).toHaveTextContent('Total invitations');
      expect(listItems[4]).toHaveTextContent('100');

      expect(listItems[5]).toHaveTextContent('In-progress invitations');
      expect(listItems[5]).toHaveTextContent('140');

      expect(listItems[6]).toHaveTextContent('Claims in review');
      expect(listItems[6]).toHaveTextContent('5');
    });
  });

  describe('when signed in as a listing agent', () => {
    test('renders summary metrics', async () => {
      server.get('/v2/admin/summary_metrics', () => {
        const response: IPropertyManagerSummaryMetricsData = {
          covered_units_count: 1402,
          in_progress_invitations_count: 140,
          invitations_completed_count: 98,
          renewed_policies_count: 86,
          total_invitations_count: 100,
          total_invitations_count_this_month: 200,
          units_up_for_renewal_count: 39
        };

        return response;
      });

      const { findByText, findAllByRole } = renderAsRole('ListingAgent');
      const heading = await findByText('Portfolio overview');
      const listItems = await findAllByRole('listitem');

      expect(heading).toBeDefined();

      expect(listItems[0]).toHaveTextContent('Up for renewal');
      expect(listItems[0]).toHaveTextContent('39');

      expect(listItems[1]).toHaveTextContent('Renewed policies');
      expect(listItems[1]).toHaveTextContent('86');

      expect(listItems[2]).toHaveTextContent('Sign up rate for invitations sent');
      expect(listItems[2]).toHaveTextContent('98.0%');

      expect(listItems[3]).toHaveTextContent('Total invitations');
      expect(listItems[3]).toHaveTextContent('100');

      expect(listItems[4]).toHaveTextContent('In-progress invitations');
      expect(listItems[4]).toHaveTextContent('140');
    });
  });
});
