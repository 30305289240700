import React from 'react';
import makeServer from '../testUtils/server';
import { Route, Routes } from 'react-router-dom';
import Wrapper from '../testUtils/Wrapper';
import { ClaimDetailsView } from '../Claims/ClaimDetails/ClaimDetails';
import { render, screen, waitFor } from '@testing-library/react';
import { UserContext } from '../utils/userRole';
import userEvent from '@testing-library/user-event';

let server;

describe('<ClaimDetails />', () => {
  const renderAsRole = (role, claimId) =>
    render(
      <UserContext.Provider value={{ id: 1, role: role, sessionId: '', isAutoApprove: false }}>
        <Wrapper initialEntries={[`/admin/claims/${claimId}`]}>
          <Routes>
            <Route path="/admin/claims/:claimsId" element={<ClaimDetailsView />} />
          </Routes>
        </Wrapper>
      </UserContext.Provider>
    );

  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('shows claims details', async () => {
    renderAsRole('Administrator', 8509);

    const administratorInitials = await screen.findByText('LS');
    expect(administratorInitials).toBeDefined();

    const claimTitle = await screen.findByText('Vicente Nienow | B - Loss of rent');
    expect(claimTitle).toBeDefined();

    const claimStatus = await screen.findByText('Closed - Unpaid');
    expect(claimStatus).toBeDefined();
  });

  test('Shows unassign claim option', async () => {
    renderAsRole('Administrator', 8509);
    const administratorInitialButton = await screen.findByRole('button', { name: 'select admin' });

    expect(administratorInitialButton).toBeDefined();

    userEvent.click(administratorInitialButton);

    const unassignDiv = await screen.findByRole('menuitem', { name: 'unassign claim' });
    expect(unassignDiv).toBeDefined();
  });

  test('Shows Claim Status for Admin', async () => {
    renderAsRole('Administrator', 8509);
    const claimStatusButton = await screen.findByRole('button', { name: 'select claim status' });

    expect(claimStatusButton).toBeDefined();

    userEvent.click(claimStatusButton);

    const label = await screen.findByRole('menuitem', { name: 'new' });
    expect(label).toBeDefined();
  });

  test('Shows Claim Status for TPA', async () => {
    renderAsRole('ThirdPartyAdministrator', 8509);
    const claimStatusButton = await screen.findByRole('button', { name: 'select claim status' });

    expect(claimStatusButton).toBeDefined();

    userEvent.click(claimStatusButton);

    const label = await screen.findByRole('menuitem', { name: 'new' });
    expect(label).toBeDefined();
  });

  test('Updates Claim Status for Admin', async () => {
    renderAsRole('Administrator', 8509);
    const claimStatusButton = await screen.findByRole('button', { name: 'select claim status' });

    expect(claimStatusButton).toBeDefined();

    userEvent.click(claimStatusButton);

    const label = await screen.queryByRole('menuitem', { name: 'processing' });
    expect(label).toContainHTML('processing');
  });

  test('Updates Claim Status for TPA', async () => {
    renderAsRole('ThirdPartyAdministrator', 8509);
    const claimStatusButton = await screen.findByRole('button', { name: 'select claim status' });

    expect(claimStatusButton).toBeDefined();

    userEvent.click(claimStatusButton);

    const label = await screen.queryByRole('menuitem', { name: 'withdrawn' });
    expect(label).toContainHTML('withdrawn');
  });

  test('Hides unassign claim option when no admin is assigned', async () => {
    // render with unassigned claim
    renderAsRole('Administrator', 8510);
    const administratorInitialButton = await screen.findByRole('button', { name: 'select admin' });

    expect(administratorInitialButton).toBeDefined();

    userEvent.click(administratorInitialButton);

    const unassignDiv = screen.queryByRole('menuitem', { name: 'unassign claim' });
    expect(unassignDiv).toBeNull();
  });

  test('Unassign claim', async () => {
    // render with assigned claim
    renderAsRole('Administrator', 8509);

    // verify dropdown trigger, click it
    const administratorInitialButton = await screen.findByRole('button', { name: 'select admin' });
    expect(administratorInitialButton).toBeDefined();
    userEvent.click(administratorInitialButton);

    // verify unassign claim button, click it
    const unassignDiv = await screen.findByRole('menuitem', { name: 'unassign claim' });
    expect(unassignDiv).toBeDefined();
    userEvent.click(unassignDiv);
  });

  test('displays form for notes & attachment claim form', async () => {
    renderAsRole('Administrator', 8509);

    const administratorInitials = await screen.findByText('LS');
    expect(administratorInitials).toBeDefined();

    const notesTextArea = await screen.findByRole('textbox');
    expect(notesTextArea).toBeDefined();
    const attachFilesButton = await screen.findAllByLabelText('Attach files');
    expect(attachFilesButton).toBeDefined();
  });

  test('Can add and remove attachments from claim form', async () => {
    const files = [
      new File(['(⌐□_□)'], 'chucknorris.png', { type: 'image/png' }),
      new File([`¯\_(ツ)_/¯`], 'hmm.png', { type: 'image/png' })
    ];

    renderAsRole('Administrator', 8509);

    const administratorInitials = await screen.findByText('LS');
    expect(administratorInitials).toBeDefined();

    const attachFilesInput = await screen.findByLabelText('Attach files');
    expect(attachFilesInput).toBeDefined();
    userEvent.upload(attachFilesInput, files);

    const uploadedFile = await screen.findByText('chucknorris.png');
    expect(uploadedFile).toBeDefined();
    const removeAttachmentButton = await screen.findByLabelText('remove-chucknorris.png');
    expect(removeAttachmentButton).toBeDefined();
    userEvent.click(removeAttachmentButton);

    //await waitFor(() => expect(screen.queryByLabelText('remove-chucknorris.png')).not.toBeInTheDocument());
  });

  test('Display Edit Payment for Paid Claim Status', async () => {
    // render with assigned claim
    renderAsRole('Administrator', 11021);

    const editPaymentButtons = await screen.findAllByText('Edit Payments');
    expect(editPaymentButtons).toHaveLength(2);
    expect(editPaymentButtons[0].getAttribute('href')).toBe('/admin/claims/11021/claim_payouts');
    expect(editPaymentButtons[1].getAttribute('href')).toBe('/admin/claims/11021/subrogation_payments');
  });
});
