import { ButtonHabitat, Switch } from '@sayrhino/rhino-shared-js';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { FormContainer } from '../Invitation/Styles';
import CoverageCustomRuleSwitchNew from './CoverageCustomRuleSwitchNew';
import CoverageRuleSwitchNew from './CoverageRuleSwitchNew';
import SectionHeader from './SectionHeader';
import Spacer from './SpacingTokens';
import { StyledSectionSubheader } from './Styled';
import {
  CopyWrapper,
  FormHeaderCloseButton,
  FormHeaderDiv,
  FormHeaderSpan,
  InfoRailDiv,
  InfoRailHeader,
  InfoRailStepContainer,
  InfoRailStepNumber,
  InfoRailStepText,
  InfoRailSubHeader,
  IntegrationFormDiv,
  IntegrationSetupPageDiv,
  PMSIntegrationSetupDivider,
  PMSIntegrationSetupHeader,
  StyledCopySubP,
  StyledFormHeaderCloseIcon,
  StyledIntegrationRule,
  StyledSubmitButton,
  StyledVErrorBanner
} from './styles/CoverageRulesFormStyles';
import useCoverageRuleFormNew from './useCoverageRuleFormNew';
import { usePartnerIntegrationForm } from './usePartnerIntegrationForm';

export const CreateCoverageRulesFormNew = () => {
  const navigate = useNavigate();
  const {
    coverageRules,
    addInviteCriteria,
    createCoverageRule,
    partnerRulesets,
    customRulesets,
    setCustomRulesets,
    deletedCustomRulesets,
    setDeletedCustomRulesets,
    showCustomRulesets,
    setShowCustomRulesets,
    showPartnerRulesets,
    setShowPartnerRulesets,
    partnerRulesetError,
    setPartnerRulesetsError
  } = useCoverageRuleFormNew();
  const { integration } = usePartnerIntegrationForm();
  const [showCustomRulesetForm, setShowCustomRulesetForm] = useState<boolean>(false);
  const [expandedComponents, setExpandedComponents] = useState<string[]>([]);

  const toggleExpandStatus = (key: string, status: boolean) => {
    let newExpandednComponents = expandedComponents.slice();

    if(status && !expandedComponents.includes(key)) {
      newExpandednComponents.push(key);
    } else if(!status) {
      newExpandednComponents = newExpandednComponents.filter((component_key) => component_key !== key);
    }

    setExpandedComponents(newExpandednComponents);
  };

  const getCoverageRuleSetUsed = (ruleSet: string | undefined) => {
    if (ruleSet === 'Generic') return `ISS - ${ruleSet}`;
    if (ruleSet === 'ScreeningResult' || ruleSet === 'Baseline') return `Fallback- ${ruleSet}`;
    return ruleSet;
  };

  const toggleCustomRulesetForm = (value: boolean) => {
    setShowCustomRulesetForm(value);
  };

  // collapse all partner/custom related components
  const collapseAllChildrenComponents = (label: string) => {
    let newExpandednComponents = expandedComponents.slice();
    if(label === "partner" && showPartnerRulesets) {
      newExpandednComponents = expandedComponents.filter((item) => !item.includes("partnerRuleSet-"));
    } else if(label == "customer" && showCustomRulesets) {
      setShowCustomRulesetForm(false);
      newExpandednComponents = expandedComponents.filter((item) => item.includes("partnerRuleSet-"));
    }

    setExpandedComponents(newExpandednComponents);
  }

  return (
    <IntegrationSetupPageDiv>
      <InfoRailDiv>
        <InfoRailHeader>PMS Integration Setup</InfoRailHeader>
        <InfoRailSubHeader>Follow these instructions to set up your PMS integration.</InfoRailSubHeader>
        <PMSIntegrationSetupDivider />
        <InfoRailStepContainer>
          <InfoRailStepNumber>1</InfoRailStepNumber>
          <InfoRailStepText>Select your PMS</InfoRailStepText>
        </InfoRailStepContainer>
        <PMSIntegrationSetupDivider />
        <div>
          <InfoRailStepNumber>2</InfoRailStepNumber>
          <InfoRailStepText>Add Integration details</InfoRailStepText>
        </div>
      </InfoRailDiv>
      <IntegrationFormDiv>
        <FormHeaderDiv>
          <FormHeaderSpan>PMS Integration Setup</FormHeaderSpan>
          <FormHeaderCloseButton onClick={() => navigate(-1)} aria-label="Leave integration form">
            <StyledFormHeaderCloseIcon height="16px" width="16px" />
          </FormHeaderCloseButton>
        </FormHeaderDiv>
        <FormContainer>
          <Spacer amount={4} />
          <PMSIntegrationSetupHeader>Coverage rules and auto-invites</PMSIntegrationSetupHeader>
          <Spacer amount={5} />
          <SectionHeader
            action={
              <Switch
                aria-label="Toggle partner coverage rules"
                id={'coverage-toggle-partner'}
                checked={showPartnerRulesets}
                onCheckedChange={() => {
                  collapseAllChildrenComponents("partner");
                  setShowPartnerRulesets(!showPartnerRulesets);
                }}
              ></Switch>
            }
          >
            Partner ruleset
          </SectionHeader>
          <Spacer amount={3} />

          <StyledSectionSubheader>
            Partner rulesets send auto-invites to all properties assigned to this partner, unless custom ruleset has
            been applied to certain properties.
          </StyledSectionSubheader>
          <Spacer amount={4} />

          <AnimatePresence initial={false}>
            {showPartnerRulesets && (
              <motion.section
                initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <CoverageRuleSwitchNew
                  showRulesetNameInput={false}
                  integration={integration?.type}
                  coverageRules={coverageRules}
                  addInviteCriteria={addInviteCriteria}
                  ruleset={partnerRulesets}
                  label="Partner"
                  showApplicableProperties={false}
                  partnerRulesetError={partnerRulesetError}
                  setPartnerRulesetsError={setPartnerRulesetsError}
                  toggleExpandStatus={toggleExpandStatus}
                  id="partnerRuleSet"
                />
              </motion.section>
            )}
          </AnimatePresence>

          <SectionHeader
            action={
              <Switch
                aria-label={`Toggle custom coverage rules`}
                id={'coverage-toggle-custom'}
                checked={showCustomRulesets}
                onCheckedChange={() => {
                  collapseAllChildrenComponents("customer");
                  setShowCustomRulesets(!showCustomRulesets)
                }}
              ></Switch>
            }
          >
            Custom rulesets
          </SectionHeader>
          <Spacer amount={3} />

          <StyledSectionSubheader>
            Custom rulesets allow the partner to select the properties they would like to send auto-invites to. Custom
            rulesets will override partner rulesets.
          </StyledSectionSubheader>
          <Spacer amount={3} />

          <AnimatePresence initial={false}>
            {showCustomRulesets && (
              <motion.section
                initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <CoverageCustomRuleSwitchNew
                  showCustomRulesetForm={showCustomRulesetForm}
                  addInviteCriteria={addInviteCriteria}
                  coverageRules={coverageRules}
                  toggleCustomRulesetForm={toggleCustomRulesetForm}
                  integration={integration}
                  customRulesets={customRulesets}
                  setCustomRulesets={setCustomRulesets}
                  deletedCustomRulesets={deletedCustomRulesets}
                  setDeletedCustomRulesets={setDeletedCustomRulesets}
                  partnerRulesetError={partnerRulesetError}
                  setPartnerRulesetsError={setPartnerRulesetsError}
                  toggleExpandStatus={toggleExpandStatus}
                />
                <Spacer amount={3} />
                <ButtonHabitat
                  id="new-ruleset-button"
                  variant="interaction"
                  size="small"
                  usage="secondary"
                  disabled={showCustomRulesetForm}
                  onClick={() => {
                    setShowCustomRulesetForm(true);
                    toggleExpandStatus("newCustomRuleset", true);
                  }}
                >
                  Add custom ruleset
                </ButtonHabitat>

                <Spacer amount={4} />
              </motion.section>
            )}
          </AnimatePresence>

          <CopyWrapper>
            <StyledCopySubP>
              This integration will be using the the following ruleset to determine resident eligibility:
            </StyledCopySubP>
            {(integration?.rule_set === 'ScreeningResult' || integration?.rule_set === 'Baseline') && (
              <StyledVErrorBanner>This integration is not using ISS or hardcoded coverage rule set.</StyledVErrorBanner>
            )}
            <StyledIntegrationRule>{getCoverageRuleSetUsed(integration?.rule_set)}</StyledIntegrationRule>
          </CopyWrapper>

          <StyledSubmitButton
            id="coverage-form-submit"
            type="submit"
            usage="primary"
            variant="interaction"
            disabled={showCustomRulesetForm || expandedComponents.length > 0}
            onClick={() => createCoverageRule()}
          >
            Submit
          </StyledSubmitButton>
        </FormContainer>
      </IntegrationFormDiv>
    </IntegrationSetupPageDiv>
  );
};
