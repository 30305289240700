/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import React from 'react';

const ProgressMeter = ({ value }) => (
  <div
    css={{
      backgroundColor: PALETTE.neutral4,
      height: '48px',
      borderRadius: '100px',
      overflow: 'hidden'
    }}
  >
    <div
      css={{
        backgroundColor: '#65e2c1',
        width: `${value * 100}%`,
        height: '100%',
        borderTopLeftRadius: '100px',
        borderBottomLeftRadius: '100px'
      }}
    />
  </div>
);

export default ProgressMeter;
