import React, { useState } from 'react';
import { generatePath, Route, Routes } from 'react-router-dom';
import styled from '@emotion/styled';
import { PALETTE, Loading, FONTS, CloseButton } from '@sayrhino/rhino-shared-js';
import MyProfileForm from './MyProfileForm';
import Tab from '../Tab';
import { ACCOUNT_ROUTES } from '../utils/routeHelpers';
import useUserRole, { useIsTfaRequired, useUserId, useUpdateTFAState, useUserContext } from '../utils/userRole';
import usePMAPropertyOwners from '../../../../api/v2/usePMAPropertyOwners';
import usePMAPropertyEntities from '../../../../api/v2/usePMAPropertyEntities';
import usePMAMissingRentersInsuranceRequirements from 'api/v2/usePMAMissingRentersInsuranceRequirements';
import PropertyOwners from './PropertyOwners';
import PropertyOwner from './PropertyOwner';
import PropertyEntities from './PropertyEntities';
import { useEffect } from 'react';

const TabList = styled.div({ borderBottom: `1px solid ${PALETTE.neutral12}`, display: 'flex', paddingLeft: '48px' });
const Content = styled.div({ width: '50%' });
const LoadingWrapper = styled.div({ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' });
const Banner = styled.div({
  width: '100%',
  background: 'black',
  padding: '12px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignSelf: 'center'
});
const BannerText = styled.p([FONTS.p2Medium], { color: 'white' });
const Close = styled(CloseButton)({ color: 'white', background: PALETTE.neutral65 });
const RedDot = styled.div({
  height: 8,
  width: 8,
  background: PALETTE.alert100,
  borderRadius: '4px',
  display: 'inline-flex',
  marginLeft: 5
});

const Account = () => {
  const [isMissingRequirements, setIsMissingRequirements] = useState(false);
  const { isPropertyManagerAdmin, isPropertyManager } = useUserRole();
  const userId = useUserId();
  const propertyOwnersResponse = usePMAPropertyOwners(userId, isPropertyManagerAdmin);
  const { isLoading, isSuccess, data } = propertyOwnersResponse ?? {};
  const totalPropertyOwners = (isSuccess && data?.pages[0].meta.total_count) ?? 0;
  const propertyEntitiesResponse = usePMAPropertyEntities(isPropertyManagerAdmin, isPropertyManager);
  const missingInsuranceRequirementsResponse = usePMAMissingRentersInsuranceRequirements();
  const { data: missingInsuranceRequirementsCount } = missingInsuranceRequirementsResponse ?? 0;

  useEffect(() => {
    if (
      missingInsuranceRequirementsCount !== undefined &&
      missingInsuranceRequirementsCount === 0 &&
      isMissingRequirements
    ) {
      const sidePanelRedDot = document.getElementById('side-panel-red-dot');

      if (sidePanelRedDot) {
        sidePanelRedDot.remove();
      }
    }

    setIsMissingRequirements(!!missingInsuranceRequirementsCount && missingInsuranceRequirementsCount > 0);
  }, [missingInsuranceRequirementsCount]);

  const {
    isLoading: entitiesLoading,
    isSuccess: entitiesSuccess,
    data: propertyEntities
  } = propertyEntitiesResponse ?? {};
  const totalPropertyEntities = (entitiesSuccess && propertyEntities?.pages[0].meta.total_count) ?? 0;
  const showTfaBanner = useIsTfaRequired();

  const loading = (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );

  if (isLoading || entitiesLoading) return loading;
  return (
    <>
      {showTfaBanner && (
        <Banner>
          <BannerText>Please enable Two-Factor Authentication</BannerText>
        </Banner>
      )}
      <TabList role="tabList" aria-label="Account navigation">
        <Tab to={ACCOUNT_ROUTES.edit} selectedRoutes={[ACCOUNT_ROUTES.twoFactorSetup]}>
          Account
        </Tab>
        {totalPropertyOwners > 0 && (
          <Tab
            to={
              totalPropertyOwners === 1 ? `property_owners/${data!.pages[0].data[0].id.toString()}` : 'property_owners'
            }
            selectedRoutes={[`${ACCOUNT_ROUTES.propertyOwners}/*`]}
          >
            Property Owner{totalPropertyOwners === 1 ? '' : 's'}
            {isMissingRequirements && <RedDot />}
          </Tab>
        )}
        {(isPropertyManagerAdmin || isPropertyManager) && totalPropertyEntities > 0 && (
          <Tab to={'legal_property_entities'} selectedRoutes={[`${ACCOUNT_ROUTES.legalPropertyEntities}/*`]}>
            Legal Property Entities
          </Tab>
        )}
      </TabList>

      <Content>
        <Routes>
          <Route
            path={`property_owners/*`}
            element={<PropertyOwners propertyOwnersResponse={propertyOwnersResponse} />}
          />
          <Route
            path={'legal_property_entities/*'}
            element={<PropertyEntities propertyEntitiesResponse={propertyEntitiesResponse} />}
          />
          <Route path={'/*'} element={<MyProfileForm />} />
        </Routes>
      </Content>
    </>
  );
};

export default Account;
