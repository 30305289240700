import React, { useRef, useState, useEffect } from 'react';
import ReactSelect, { ReactSelectProps } from 'react-select';
import { ArrowDown } from '@sayrhino/rhino-shared-js';
import { customStyles, getSelectStyles } from './styles';

const DropdownIndicator = () => <ArrowDown />;

/* React-select v1 allowed to use strings for the value prop,
 * but with v2 and above uses arrays or objects in all the cases.
 * Pass `simpleValue` prop which will convert the value.
 * Send `labelKey` or `ValueKey` if options are different from
 * {label, value}
 */
type IProps = ReactSelectProps & {
  errorStyled?: string;
  labelKey?: string;
  valueKey?: string;
  simpleValue?: string | number;
};

const Select: React.FC<IProps> = ({ labelKey = 'label', valueKey = 'value', simpleValue, components, ...props }) => {
  const [value, setValue] = useState(props.value);
  const valueRef = useRef(props.value || simpleValue);
  valueRef.current = props.value || simpleValue;
  useEffect(
    function getSelectedOption() {
      if (simpleValue !== undefined && props.options.length) {
        const val = Array.isArray(simpleValue)
          ? props.options.filter((x) => simpleValue.includes(x[valueKey]))
          : props.options.find((x) => x[valueKey] === simpleValue);
        setValue(val);
      }
    },
    [simpleValue, props.options]
  );

  const onChange = (option) => {
    setValue(option);
    props.onChange(option);
  };

  return (
    <ReactSelect
      {...props}
      value={value}
      onChange={onChange}
      styles={props.errorStyled ? getSelectStyles({ isError: props.error }) : customStyles}
      getOptionLabel={(options) => options[labelKey]}
      getOptionValue={(options) => options[valueKey]}
      components={{ DropdownIndicator, ...components }}
    />
  );
};

export default Select;
