import axios from 'axios';
import { IData, ITask } from 'components/v2/App/interfaces';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

const getTasks = async (page: number = 1) => {
  const { data } = await axios.get<IData<ITask[]>>(`/v2/admin/tasks?page=${page}`);
  return data;
};

export default function useTasks(): InfiniteQueryObserverResult<IData<ITask[]>, any> {
  return useInfiniteQuery<IData<ITask[]>>('tasks', ({ pageParam = 1 }) => getTasks(pageParam), {
    getNextPageParam: (lastGroup) => lastGroup.meta.next_page
  });
}
