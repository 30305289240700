import axios from 'axios';
import { useQuery } from 'react-query';

export type ISchema = {
  deposit_type?: string;
  policy_url?: string;
  coverage_amount?: string;
  expiration_date?: string;
  renewal_eligibility?: string;
};

export const YARDI_FIELDS_SCHEMA = 'yardi fields schema';

export const getYardiFieldSchema = async (uuid?: string) => {
  const { data } = await axios.get<ISchema>(`/v2/integrations/partners/${uuid}/yardi_fields_schema.json`);
  return data;
};

export default function useYardiFieldSchema(uuid?: string) {
  return useQuery<ISchema>([YARDI_FIELDS_SCHEMA, uuid], () => getYardiFieldSchema(uuid), { enabled: !!uuid });
}
