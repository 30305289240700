/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Card, PALETTE, LegacyTag, FONTS } from '@sayrhino/rhino-shared-js';
import InlineStatusBanner from './InlineStatusBanner';
import { IInsurancePolicyStatus, IStatusDetail, IInsurancePolicyUpdate } from 'components/v2/App/interfaces';
import { getTagVariant } from './utils';

interface IProps {
  unitName: string;
  tenantFullName: string;
  coverageAmount: string;
  property: {
    name: string;
    state: string;
  };
  coverageDates: string;
  status: IInsurancePolicyStatus;
  statusDetail: IStatusDetail;
  styles?: any;
}

export const convertInsurancePolicyUpdateToCardProps = (update: IInsurancePolicyUpdate) => {
  const { attributes } = update;

  return {
    unitName: attributes.unit_name,
    tenantFullName: attributes.tenant_full_name,
    coverageAmount: attributes.coverage_amount,
    property: {
      name: attributes.property_name,
      state: attributes.property_state
    },
    coverageDates: attributes.coverage_dates,
    status: attributes.status,
    statusDetail: Array.isArray(attributes.status_detail) ? attributes.status_detail[0] : attributes.status_detail
  };
};

// we need to refactor away from absolute positioned styles
// adding back in relative position bc it is a requirement for absolute
// positioned elements in styles
const cardCSS = {
  height: 'fit-content',
  padding: '20px 32px',
  position: 'relative',
  width: 'auto'
};

const unitCSS = ({ isMissingUnitCSS }) => css`
  color: black;
  max-width: 15%;
  overflow: hidden;
  position: absolute;
  right: 32px;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 20px;
  ${isMissingUnitCSS && `color: ${PALETTE.neutral12};`}
`;

const sectionCSS = css({
  marginTop: '12px',
  display: 'flex'
});

const coverageHeaderCSS = css({
  borderRight: '1px solid #F4F4F4',
  marginRight: '12px',
  paddingRight: '12px',
  marginTop: '6px',
  color: PALETTE.neutral65
});

const rightInfoCSS = css({
  textTransform: 'capitalize',
  paddingTop: '2px'
});

const propertyCSS = css({
  marginLeft: '12px',
  maxWidth: '70%'
});

const bannerCSS = css({
  margin: '16px -24px -12px -24px',
  display: 'flex'
});

// we should refactor out all of the absolute positioned styles in this card
// the heights of the rows will be impacted here by using design tokens since we
// will always have a line height.
export const InsurancePolicyCard: React.FC<IProps> = (props: IProps) => {
  const { status, property, unitName, tenantFullName, coverageDates, coverageAmount, statusDetail, ...rest } = props;

  const propertyName = property.name.length < 79 ? property.name : property.name.slice(0, 64) + '...';

  return (
    <Card style={cardCSS} {...rest}>
      <h4 css={[FONTS.h4, { maxWidth: '85%' }]}>{tenantFullName}</h4>
      <h4 css={[FONTS.h4, unitCSS({ isMissingUnitCSS: !Boolean(unitName) })]}>{unitName ? unitName : '—'}</h4>
      <div css={[sectionCSS, { justifyContent: 'space-between', paddingBottom: '12px' }]}>
        <div css={{ display: 'flex' }}>
          <p css={[FONTS.label, coverageHeaderCSS]}>Coverage</p>
          <p css={[{ paddingTop: '2px' }, FONTS.p2]}>{coverageDates}</p>
        </div>
        <p css={[FONTS.p2, rightInfoCSS]}>{coverageAmount}</p>
      </div>
      <div css={[sectionCSS, { justifyContent: 'space-between' }]}>
        <div css={{ display: 'flex' }}>
          <LegacyTag variant="disabled">
            <span css={[FONTS.h6Demi, { color: PALETTE.neutral65 }]}>{property.state}</span>
          </LegacyTag>
          <p css={[FONTS.p2, propertyCSS]}>{propertyName}</p>
        </div>
        <LegacyTag variant={getTagVariant(status)} css={rightInfoCSS}>
          {status}
        </LegacyTag>
      </div>
      {statusDetail && (
        <div css={bannerCSS}>
          <InlineStatusBanner statusDetail={statusDetail} />
        </div>
      )}
    </Card>
  );
};

export default InsurancePolicyCard;
