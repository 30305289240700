/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { FONTS, PALETTE, FormField, RadioGroup, Datepicker, Alert2Icon } from '@sayrhino/rhino-shared-js';
import { invalidFormCSS, invalidFieldDescCSS, fieldDescCSS } from './Styled';
import { stringifyDate, centsToUSDFormatter } from '../../utils';
import Decimal from 'decimal.js';
import { LossOfRentData, LossOfRentObj } from './claimInterface';
import { CurrencyInputUSD } from '@sayrhino/rhino-shared-js';
import { format } from 'date-fns';

const rentArrearBegins = [
  { label: 'During the lease term', value: 0 },
  { label: 'After renter move-out', value: 1 }
];

//Styled
const LossOfRentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 0 24px 48px'
});
const LossOfRentDataWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 24
});
const StyledLossOfRentHeader = styled.h5([
  FONTS.h5,
  {
    marginBottom: 16
  }
]);
const StyledSplitter = styled.hr({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  width: '100%',
  margin: '0 0 24px 0'
});
const StyledSplitterMargin = styled(StyledSplitter)({ marginBottom: 16 });
const StyledCopy = styled.h6([FONTS.h6Demi, { marginBottom: 32 }]);
const AlertWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  color: PALETTE.alert100,
  marginBottom: 24
});
const StyledDatePicker = styled.div({
  input: {
    cursor: 'pointer'
  }
});
const StyledFeesCopy = styled.p([
  FONTS.p3,
  {
    marginBottom: 24,
    color: PALETTE.neutral65
  }
]);

const StyledAddAnotherDate = styled.h6([
  FONTS.h6Demi,
  {
    marginBottom: 36,
    color: PALETTE.interaction100,
    cursor: 'pointer'
  }
]);

const LossofRentTotalWrapper = styled.div([
  FONTS.h5,
  {
    display: 'flex',
    justifyContent: 'space-between'
  }
]);

const PolicyAmountWrapper = styled.div({
  marginTop: 8,
  display: 'flex'
});

const StyledPolicyCoverageAmount = styled.p([
  FONTS.p2Light,
  {
    color: PALETTE.neutralDark,
    flex: 1
  }
]);
const StyledSpan = styled.span(FONTS.h4);
const StyledQuestionMark = styled.span({
  minWidth: '20px',
  height: '20px',
  paddingLeft: '1px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '50%',
  color: PALETTE.neutral25,
  marginLeft: '24px'
});
interface IProps {
  lossOfRentData: LossOfRentData[];
  checkLossOfRentErrors: (index: any, type: any) => boolean;
  lossOfRentError: LossOfRentObj;
  handleChangeLossOfRentData: (index: number, value: any, key: string, errType: string) => void;
  onAddLossOfRent: () => void;
  getTotalRentAmount: () => string;
  policyRemainingAmount: number;
  originalDatesOfLoss?: Date[];
}

const LossOfRent: React.FC<IProps> = ({
  lossOfRentData,
  checkLossOfRentErrors,
  lossOfRentError,
  handleChangeLossOfRentData,
  onAddLossOfRent,
  getTotalRentAmount,
  policyRemainingAmount,
  originalDatesOfLoss
}) => {
  return (
    <LossOfRentWrapper>
      {lossOfRentData.map((rentData, index) => (
        <LossOfRentDataWrapper>
          <StyledLossOfRentHeader>{`Loss of Rent - ${index + 1}`}</StyledLossOfRentHeader>
          <StyledSplitter />
          <StyledCopy>When did rent arrears begin?</StyledCopy>
          {checkLossOfRentErrors(index, '') && lossOfRentError[index].errors.loss_of_rent_type && (
            <AlertWrapper>
              <Alert2Icon width={20} height={20} css={{ marginRight: 20 }} />
              {checkLossOfRentErrors(index, 'loss_of_rent_type')}
            </AlertWrapper>
          )}
          <RadioGroup
            name={`rentbegin${index}`}
            options={rentArrearBegins}
            onChange={(selectedVal) =>
              handleChangeLossOfRentData(
                index,
                parseInt(selectedVal.target.value),
                'rentArrearBegin',
                'loss_of_rent_type'
              )
            }
            selected={rentData.rentArrearBegin}
          />
          <StyledDatePicker>
            <Datepicker
              onChange={(date) => {
                const updated = !(originalDatesOfLoss?.[index]?.toString() === format(date, 'yyyy-MM-dd'));
                handleChangeLossOfRentData(index, { date, updated }, 'arrearDate', 'date_of_loss');
              }}
              selected={rentData.arrearDate.date}
              value={stringifyDate(rentData.arrearDate.date)}
              inputLabel=""
              selectsRange={false}
            />
          </StyledDatePicker>
          <p
            css={[
              FONTS.p3,
              checkLossOfRentErrors(index, '') && lossOfRentError[index].errors.date_of_loss
                ? invalidFieldDescCSS
                : fieldDescCSS
            ]}
          >
            {checkLossOfRentErrors(index, '') && lossOfRentError[index].errors.date_of_loss
              ? checkLossOfRentErrors(index, 'date_of_loss')
              : 'Date of loss'}
          </p>
          <CurrencyInputUSD
            id="arrear-amount"
            label={'Arrear amount'}
            value={Number(rentData.arrearAmountCents)}
            onChange={(value) => {
              handleChangeLossOfRentData(index, new Decimal(value), 'arrearAmountCents', 'arrears_amount_cents');
            }}
            disabled={false}
            subtext={
              checkLossOfRentErrors(index, '') && lossOfRentError[index].errors.arrears_amount_cents
                ? checkLossOfRentErrors(index, 'arrears_amount_cents')
                : ''
            }
            css={[
              checkLossOfRentErrors(index, '') && lossOfRentError[index].errors.arrears_amount_cents
                ? invalidFormCSS
                : undefined
            ]}
          />
          <CurrencyInputUSD
            id="late-fees"
            label={'Late fees (Optional)'}
            value={Number(rentData.lateFeeCents)}
            onChange={(value) => {
              handleChangeLossOfRentData(index, new Decimal(value), 'lateFeeCents', '');
            }}
            disabled={false}
          />
          <CurrencyInputUSD
            id="other-fees"
            label={'Other fees (Optional)'}
            value={Number(rentData.otherFeeCents)}
            onChange={(value) => {
              handleChangeLossOfRentData(index, new Decimal(value), 'otherFeeCents', '');
            }}
            disabled={false}
          />
        </LossOfRentDataWrapper>
      ))}
      <StyledAddAnotherDate onClick={onAddLossOfRent}>+ Add another month</StyledAddAnotherDate>
      <StyledSplitterMargin />
      <LossofRentTotalWrapper>
        <StyledSpan>Loss of Rent Total</StyledSpan>
        <StyledSpan>{getTotalRentAmount()}</StyledSpan>
      </LossofRentTotalWrapper>
      <PolicyAmountWrapper>
        <StyledPolicyCoverageAmount>
          The policy will cover up to {centsToUSDFormatter(new Decimal(policyRemainingAmount))} in loss of rent or
          excessive damages. To give us a clearer picture of claim please enter the full amount owed.
        </StyledPolicyCoverageAmount>
        <StyledQuestionMark>?</StyledQuestionMark>
      </PolicyAmountWrapper>
    </LossOfRentWrapper>
  );
};

export default LossOfRent;
