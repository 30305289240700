import axios from 'axios';
import { csrfToken } from 'utils/document';

interface IClaimsAssignInfo {
  analystID?: number;
  claims: IClaim[];
}

interface IClaim {
  administrator: string;
  claim_type: string;
  id?: number;
  property_address_line_one: string;
  renter_full_name: string;
}

interface IClaimAssignApiParams {
  claim_ids: number[];
  claim: IClaimParams;
}

interface IClaimParams {
  administrator_id: number;
}

const batchAssignClaims = async (claimsAssignInfo: IClaimsAssignInfo) => {
  const { analystID, claims } = claimsAssignInfo;
  const batchedClaimIDs = claims.map((claim) => claim.id);

  const token = csrfToken();

  const response = await axios.put<IClaimAssignApiParams>(
    '/v2/admin/claims/bulk_assign_admin',
    { claim_ids: batchedClaimIDs, claim: { administrator_id: analystID } },
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default batchAssignClaims;
