/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import DashboardPanelWell from './DashboardPanelWell';
import DashboardPanelTitle from './DashboardPanelTitle';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { stringifyDateLong } from './utils';
import { FigureList, FigureListItem } from './FigureList';
import ProgressMeter from './ProgressMeter';

type IRange = {
  min: number;
  max: number;
};

type IProps = {
  title: string;
  description: string;
  range: IRange;
  average: number;
  unit: string;
  dateRangeInDays: number;
  updatedDate: Date;
};

const DashboardPanelRangeBarChart: React.FC<IProps> = ({
  title,
  description,
  range,
  average,
  unit,
  dateRangeInDays,
  updatedDate
}) => (
  <DashboardPanelWell>
    <DashboardPanelTitle>{title}</DashboardPanelTitle>
    <p css={[FONTS.p2, { marginBottom: '2rem' }]}>{description}</p>

    <FigureList css={{ marginTop: 24 }}>
      <FigureListItem label="Range" value={`${range.min}—${range.max} ${unit}`} />
      <FigureListItem label="Average" value={`${average} ${unit}`} />
    </FigureList>

    <div css={{ display: 'flex', justifyContent: 'space-between' }}>
      <div css={[FONTS.label, { paddingTop: 16, color: PALETTE.neutral25 }]}>Last {dateRangeInDays} days</div>

      <div css={[FONTS.label, { paddingTop: 16, color: PALETTE.neutral25 }]}>
        Updated {stringifyDateLong(updatedDate)}
      </div>
    </div>
  </DashboardPanelWell>
);

export default DashboardPanelRangeBarChart;
