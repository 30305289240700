/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Checkbox, FONTS, Loading } from '@sayrhino/rhino-shared-js';
import { QueryObserverResult } from 'react-query';
import { LoadingWrapper } from '../Claims/ClaimDetails/ClaimStyles';
import { IFilters, IClaimStaticFilterOptions } from '../interfaces';
import { StyledTitleDiv, StyledOptionsDiv, StyledH5, StyledWrapperDiv } from './ClaimFilterStyles';
import { StyledCheckbox, clearButtonCSS, newSelections } from './index';

const ClaimClassificationFilterOptions: React.FC<{
  css?: any;
  query: () => QueryObserverResult<IClaimStaticFilterOptions>;
  selectedClassifications: string[] | undefined;
  setFilters: (args: Partial<IFilters>) => void;
}> = (props) => {
  const { query, selectedClassifications = [], setFilters, ...rest } = props;
  const { data, isLoading } = query();
  const renderClassifications = () => {
    return data?.claim_classifications?.length ? (
      <div {...rest}>
        <StyledTitleDiv>
          <StyledH5>Classification</StyledH5>
          <button
            type="button"
            css={clearButtonCSS({ canClear: Boolean(selectedClassifications.length) })}
            onClick={() => setFilters({ claim_classifications: [] })}
          >
            Clear
          </button>
        </StyledTitleDiv>
        <StyledWrapperDiv>
          <StyledOptionsDiv>
            {data.claim_classifications.map((option, index) => {
              return (
                <StyledCheckbox key={`checkbox-classification-wrapper-${index}`}>
                  <Checkbox
                    checked={selectedClassifications.includes(option.value)}
                    id={`checkbox-classification-filter-${index}`}
                    key={`checkbox-classification-filter-${index}`}
                    name={option.value}
                    onCheckedChange={() =>
                      setFilters({ claim_classifications: newSelections(option.value, selectedClassifications) })
                    }
                    label={option.label_text}
                  />
                </StyledCheckbox>
              );
            })}
          </StyledOptionsDiv>
        </StyledWrapperDiv>
      </div>
    ) : null;
  };

  return isLoading ? (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  ) : (
    renderClassifications()
  );
};

export default ClaimClassificationFilterOptions;
