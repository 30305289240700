import axios from 'axios';
import { IData, IPaginatedMeta, ITask } from 'components/v2/App/interfaces';
import { stringify } from 'qs';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

const getInsurancePolicies = async (filter, field: string, page: number = 1) => {
  const params = Object.assign({}, { page, filter, field });
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data } = await axios.get<IData<ITask[], IPaginatedMeta>>('/v2/admin/insurance_policies', {
    params,
    paramsSerializer
  });
  return data;
};

export default function useInsurancePolicies(
  filter,
  field,
  onSuccess
): InfiniteQueryObserverResult<IData<ITask[], IPaginatedMeta>> {
  return useInfiniteQuery<IData<ITask[], IPaginatedMeta>>(
    ['policies', filter, field],
    ({ pageParam = 1 }) => getInsurancePolicies(filter, field, pageParam),
    {
      getNextPageParam: (lastGroup) => lastGroup.meta.next_page,
      onSuccess
    }
  );
}
