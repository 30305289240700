import axios from 'axios';
import { csrfToken } from 'utils/document';

const path = '/admin/guided_claims_enablement';

export const guidedClaimsIsEnabled = async () => {
  const authenticity_token = csrfToken();
  const headers = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': authenticity_token
  };

  const { data } = await axios.get(path, {
    headers: headers
  });

  return data.guided_claims_enabled;
}

export const enableGuidedClaims = async () => {
  const authenticity_token = csrfToken();

  return axios.post(path, { authenticity_token });
}

export const disableGuidedClaims = async () => {
  const authenticity_token = csrfToken();
  const headers = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': authenticity_token
  }

  return axios.delete(path, { headers });
}
