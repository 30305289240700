import styled from '@emotion/styled';
import { FONTS, PALETTE, Radio } from '@sayrhino/rhino-shared-js';
import React from 'react';

const FormHeader = styled.h5([FONTS.h5], { marginBottom: '24px', marginTop: '24px' });

const Wrapper = styled.div({
  paddingTop: '20px',
  marginBottom: '30px'
});

const Header = styled.h4([FONTS.h4], {
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  paddingBottom: '8px',
  marginTop: '48px'
});

// temp fix for Radio.Root props issue
const RadioRoot: any = Radio.Root;

const InvitationRequirement = ({ requiresInvitation, setRequiresInvitation }) => {
  return (
    <Wrapper>
      <Header>Homepage Sign-Ups</Header>
      <FormHeader>Block Homepage Sign-Ups</FormHeader>
      <RadioRoot
        name="invitation_required"
        value={requiresInvitation}
        onValueChange={(value) => {
          setRequiresInvitation(value);
        }}
      >
        <Radio.Option data-testid="InvitationIsRequired" data-cy="InvitationIsRequired" value="yes" label="Yes" />
        <Radio.Option data-testid="InvitationIsNotRequired" data-cy="InvitationIsNotRequired" value="no" label="No" />
      </RadioRoot>
    </Wrapper>
  );
};

export default InvitationRequirement;
