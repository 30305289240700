import axios from 'axios';
import { useQuery, QueryObserverResult } from 'react-query';
import { IClaimCategories } from 'components/v2/App/interfaces';

const getCategories = async () => {
  const { data } = await axios.get<IClaimCategories>('/v2/admin/claims/available_categories');

  return data;
};

export default function useClaimCategories(): QueryObserverResult<IClaimCategories> {
  return useQuery<IClaimCategories>(['categories'], () => getCategories());
}
