/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PALETTE, FilterIcon, FONTS, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

const filterCSS = ({ filtersActive, filtersCount }) =>
  css({
    alignSelf: 'flex-end',
    alignItems: 'center',
    backgroundColor: filtersActive ? PALETTE.neutral12 : 'transparent',
    border: `1px solid ${PALETTE.brand4}`,
    borderRadius: '1.75rem',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px 1px 2px 11px',
    '&:active': {
      border: `1px solid ${PALETTE.brand4}`,
      backgroundColor: PALETTE.neutral12
    },
    '&:hover': {
      backgroundColor: filtersActive || filtersCount ? PALETTE.neutralDark : 'inherit',
      border: `1px solid ${PALETTE.neutral25}`,
      color: filtersActive || filtersCount ? PALETTE.neutralLight : 'inherit'
    }
  });

const filterBadgeCSS = css({
  alignItems: 'center',
  backgroundColor: PALETTE.neutralDark,
  border: `1px solid ${PALETTE.neutral12}`,
  borderRadius: '1.75rem',
  color: PALETTE.neutralLight,
  display: 'flex',
  height: '1.5rem',
  justifyContent: 'center',
  lineHeight: '1.5rem',
  marginRight: '1px',
  minWidth: '1.5rem',
  padding: '0 0.375rem',
  textAlign: 'center',
  width: 'fit-content',
  '&:hover': {
    border: `1px solid ${PALETTE.neutralLight}`
  }
});

const FilterLink: FC<{ filtersActive: boolean; filtersCount: number; id: string; to: string }> = ({
  filtersActive,
  filtersCount,
  id,
  to
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Link
      css={[FONTS.p2, filterCSS({ filtersActive, filtersCount })]}
      id={id} // for cypress
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      to={to}
    >
      <span css={{ color: 'inherit', marginRight: '.875rem' }}>Filter</span>
      {filtersCount > 0 ? (
        <span css={[filterBadgeCSS, FONT_FAMILY.medium]}>{filtersCount}</span>
      ) : (
        <span css={{ alignItems: 'center', color: 'inherit', display: 'flex', padding: '0 .625rem 3px 0' }}>
          <FilterIcon height="15px" width="15px" />
        </span>
      )}
    </Link>
  );
};

export default FilterLink;
