import axios, { AxiosResponse } from 'axios';
import { IClaim } from 'components/v2/App/interfaces';
import { csrfToken } from 'utils/document';

const withdrawClaim = async (claim: Partial<IClaim>) => {
  const token = csrfToken();
  const response: AxiosResponse<IClaim> = await axios.put(`/v2/admin/claims/${claim.id}/withdraw`, {
    authenticity_token: token,
    claim: { status: 'withdrawn' }
  });
  return response;
};

export default withdrawClaim;
