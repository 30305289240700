import React from 'react';
import { render, act, fireEvent } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { renderHook } from '@testing-library/react-hooks/dom';
import useCoverageOptions, { CoverageState } from '../../../../api/v2/useCoverageOptions';
import makeServer from '../testUtils/server';
import { ReactQueryWrapper } from '../testUtils/Wrapper';
import { DOLLAR_AMOUNT, MULTIPLIER } from '../utils/constants';
import CoverageOptionsSelect from '../CoverageOptionSelect';
import { ICoverageSelection } from '../interfaces';
import Decimal from 'decimal.js';

let server;
const leftClick = { button: 0 };

const Wrapper = ({ initialType = DOLLAR_AMOUNT, initialValue = new Decimal(30000) }) => {
  const coverageInfo = useCoverageOptions({
    propertyId: 1,
    unitId: 1,
    monthlyRentCents: 100000,
    initialCoverageState: { type: initialType, value: initialValue }
  });

  return <CoverageOptionsSelect coverageInfo={coverageInfo} disabled={false} />;
};

describe('<CoverageOptionSelect />', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('useCoverageOptions calculates multipliers', async () => {
    const initialCoverageSelection: ICoverageSelection = {
      type: DOLLAR_AMOUNT,
      value: new Decimal(0)
    };

    const { result, waitForValueToChange } = renderHook<void, CoverageState>(
      () =>
        useCoverageOptions({
          propertyId: 1,
          unitId: 1,
          monthlyRentCents: 1000_00,
          initialCoverageState: initialCoverageSelection
        }),
      { wrapper: ReactQueryWrapper }
    );

    expect(result.current.result.isLoading).toBe(true);
    await waitForValueToChange(() => result.current.result.status);
    expect(result.current.result.isSuccess).toBe(true);
    expect(result.current.multipliers).toHaveLength(4);
    expect(result.current.multipliers[0].label).toEqual('$1,000.00 (1X monthly rent)');
    expect(result.current.result.data?.default?.type).toEqual(DOLLAR_AMOUNT);
  });

  test('CoverageOptionSelect presents all available options', async () => {
    const { getByRole } = render(
      <ReactQueryWrapper>
        <Wrapper />
      </ReactQueryWrapper>
    );

    const dollarAmount = getByRole('radio', { name: 'Dollar amount' });
    const multiplier = getByRole('radio', { name: 'Multiplier' });
    const defaultCoverage = getByRole('radio', { name: 'Property default' });

    expect(dollarAmount.textContent).not.toBeNull();
    expect(multiplier.textContent).not.toBeNull();
    expect(defaultCoverage.textContent).not.toBeNull();
  });

  test('CoverageOptionSelect updates multiplier options', async () => {
    const { getByRole, getByText, getByLabelText } = render(
      <ReactQueryWrapper>
        <Wrapper />
      </ReactQueryWrapper>
    );

    const dollarRadio = getByRole('radio', { name: 'Dollar amount' });
    const multiplierRadio = getByRole('radio', { name: 'Multiplier' });
    expect(dollarRadio).toBeChecked();

    userEvent.click(multiplierRadio, leftClick);

    expect(multiplierRadio).toBeChecked();

    const multiplierSelect = getByLabelText('Rent multiplier');
    const option1 = getByText('$1,000.00 (1X monthly rent)');
    const option2 = getByText('$2,000.00 (2X monthly rent)');

    expect(option2.getAttribute('aria-selected')).toBe('false');

    fireEvent.change(multiplierSelect, { target: { value: '2' } });
    expect(option1.getAttribute('aria-selected')).toBe('false');
    expect(option2.getAttribute('aria-selected')).toBe('true');
  });

  test('CoverageOptionSelect updates property default', async () => {
    const { getByRole } = render(
      <ReactQueryWrapper>
        <Wrapper initialType={DOLLAR_AMOUNT} initialValue={new Decimal(0)} />
      </ReactQueryWrapper>
    );
    const dollarAmountRadio = getByRole('radio', { name: 'Dollar amount' });
    const propetyDefaultRadio = getByRole('radio', { name: 'Property default' });

    expect(dollarAmountRadio).toBeChecked();

    userEvent.click(propetyDefaultRadio, leftClick);

    expect(propetyDefaultRadio).toBeChecked();
  });

  test('CoverageOptionSelect updates custom amount', async () => {
    const { getByRole } = render(
      <ReactQueryWrapper>
        <Wrapper />
      </ReactQueryWrapper>
    );
    const dollarAmountRadio = getByRole('radio', { name: 'Dollar amount' });

    userEvent.click(dollarAmountRadio, leftClick);

    const coverageAmountInput = getByRole('textbox', { name: 'Coverage amount' });

    // clear input
    userEvent.clear(coverageAmountInput);
    // input value to be formatted
    userEvent.paste(coverageAmountInput, '2222.22');

    expect(coverageAmountInput).toHaveValue('$2,222.22');
  });

  test('CoverageOptionSelect initializes with the multiplier selection', async () => {
    const { findByRole } = render(
      <ReactQueryWrapper>
        <Wrapper initialType={MULTIPLIER} initialValue={new Decimal(1.5)} />
      </ReactQueryWrapper>
    );
    const multiplierRadio = await findByRole('radio', { name: 'Multiplier' });

    expect(multiplierRadio).toBeChecked();
  });

  test('CoverageOptionSelect initializes with the amount selection', async () => {
    const { getByRole } = render(
      <ReactQueryWrapper>
        <Wrapper initialType={DOLLAR_AMOUNT} initialValue={new Decimal(150000)} />
      </ReactQueryWrapper>
    );
    const dollarAmountRadio = getByRole('radio', { name: 'Dollar amount' });

    expect(dollarAmountRadio).toBeChecked();
  });
});
