import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { PartnerIntegrationStatusEnum, PartnerValidationStatus } from '../interfaces';

type IProp = {
  label: string | undefined;
  status: PartnerValidationStatus | undefined;
};

const View = styled.div({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const Label = styled.p([
  FONTS.p3Medium,
  {
    color: PALETTE.neutral65,
    marginLeft: '8px'
  }
]);

const Indicator = styled.div<{ status: string | undefined }>(({ status }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '6px',
  backgroundColor: status === PartnerIntegrationStatusEnum.ERROR ? PALETTE.alert100 : PALETTE.neutral65
}));

const ValidationStatus: React.FC<IProp> = ({ status, label }) => {
  return (
    <View>
      <Indicator status={status} />
      <Label>{label}</Label>
    </View>
  );
};

export default ValidationStatus;
