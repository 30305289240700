import React, { useState } from 'react';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import usePropertySearch from 'api/v2/usePropertySearch';
import styled from '@emotion/styled';
import { ROUNDNESS, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { IPropertySearchResult } from '../../interfaces';

type PropertyProps = {
  id: string;
  subText?: string;
  value?: IPropertySearchResult;
  onChange?: (value?: IPropertySearchResult) => void;
};

const BuildingName = styled.span([FONTS.p1Medium]);

const Select = styled(AsyncSelect)([
  ROUNDNESS.s,
  {
    border: `1px solid ${PALETTE.neutral12}`,
    padding: '3px'
  }
]);

const Label = styled.label([
  FONTS.p1Light,
  {
    color: PALETTE.neutral55
  }
]);

const SubText = styled.p([
  FONTS.p1,
  {
    fontSize: '12px',
    color: PALETTE.neutral25
  }
]);

export const PropertySelect: React.FC<PropertyProps> = ({ id, onChange, value, subText }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>('');
  const only_active = false;
  const { data } = usePropertySearch(searchParam, only_active);

  const loadOptions = async (input: string, callback: any) => {
    setIsLoading(() => true);
    try {
      setSearchParam(input);
      callback(data);
    } catch (error) {
      callback([]);
    } finally {
      setIsLoading(() => false);
    }
  };

  return (
    <>
      <Label>Property name or address</Label>
      <Select
        id={id}
        isLoading={isLoading}
        value={value}
        options={data}
        loadOptions={loadOptions}
        onChange={onChange}
        getOptionValue={(options: IPropertySearchResult) => options.id}
        getOptionLabel={(options: IPropertySearchResult) => options.building_name}
        formatOptionLabel={(option, { context }) => {
          return context === 'menu' ? (
            <>
              <BuildingName>{option.building_name}</BuildingName>
              {option.address ? `: ${option.address}` : ''}
            </>
          ) : (
            option.building_name
          );
        }}
      />
      <SubText>{subText}</SubText>
    </>
  );
};
