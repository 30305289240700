import styled from 'styled-components';

export const Link = styled.a`
  color: #6318ce;
`;

export const Relative = styled.div`
  position: relative;
`;

export const AssigneeButton = styled.button`
  background: none;
  border: none;
  position: relative;
`;

export const Circle = styled.div`
  width: 40px;
  height: 40px;
  cursor: default;
  border-radius: 50%;
  border: 1px solid #000000;
  text-align: center;

  &:hover {
    background: #f4f4f4;
  }
`;

export const Initials = styled.div`
  top: 10px;
  line-height: 1;
  font-size: 20px;
  position: relative;
`;

export const Image = styled.img`
  z-index: -1;
  position: relative;
  vertical-align: middle;
`;

export const Modal = styled.div`
  position: absolute;
  width: 224px;
  margin-left: -194px;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

export const UnassignLink = styled(Link)`
  padding: 12px;
  display: block;
  cursor: pointer;
`;
