/** @jsx jsx */
import React from 'react';
import { ClassNames, jsx } from '@emotion/react';
import Modal from 'react-modal';
import styled from '@emotion/styled';
import { CloseIcon, PALETTE } from '@sayrhino/rhino-shared-js';
import { StyledDiv, CloseButtonDiv, CloseIconButton } from '../Claims/ClaimDetails/ClaimStyles';

const Card = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '489px',
  maxWidth: '550px',
  minHeight: '287px',
  maxHeight: '350px',
  wordWrap: 'break-word',
  backgroundColor: PALETTE.neutralLight,
  border: '1px solid rgba(0, 0, 0, 0.125)',
  borderRadius: '0.25rem'
});

const CardBody = styled.div({
  flex: 'auto',
  padding: '1.25rem',
  minHeight: '1px'
});

interface IProps {
  isOpen: boolean;
  id?: string;
  closeModal: () => void;
  children: JSX.Element | JSX.Element[] | boolean;
  /**
   * Inserts into the aria-label value for the modal
   */
  ariaLabel?: string;
  /**
   * Inserts into the aria-describedby value for the modal
   */
  ariaDescribedBy?: string;
}

const FullScreenModal = ({ isOpen, id, closeModal, children, ariaLabel, ariaDescribedBy }: IProps) => {
  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          isOpen={isOpen}
          id={id}
          closeModal={() => closeModal()}
          // overlayClassName and className need to be strings or else we get a react warning
          overlayClassName={{
            base: 'overlay-base',
            afterOpen: 'overlay-after'
          }}
          className={{
            base: 'content-base'
          }}
          aria={{
            describedby: ariaDescribedBy
          }}
          contentLabel={ariaLabel}
          portalClassName={css`
            .overlay-base {
              padding: 1rem;
              position: fixed;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0);
              opacity: 0;
              outline: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 99999;
              overflow: auto;
            }

            .overlay-after {
              background-color: rgba(0, 0, 0, 0.8);
              opacity: 1;
            }

            .content-base {
              position: relative;
              top: auto;
              left: auto;
              right: auto;
              bottom: auto;
              margin: 0 auto;
              border: 0;
              outline: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 100%;
              width: 100%;
              background-color: transparent;
            }
          `}
        >
          <Card>
            <CardBody>
              <CloseButtonDiv>
                <CloseIconButton onClick={() => closeModal()} aria-label="close">
                  <CloseIcon height={12} width={12} />
                </CloseIconButton>
              </CloseButtonDiv>
              <StyledDiv></StyledDiv>
              {children}
            </CardBody>
          </Card>
        </Modal>
      )}
    </ClassNames>
  );
};

export default FullScreenModal;
