import ApplicationTable from './application_table';
import { DeleteColumn, EditColumn } from './utils';

class PropertyUnitsTable extends ApplicationTable {
  public selector: string = '#property-units-table';
  public pathPrefix: string = 'units/';

  public getColumns(): DataTables.ColumnSettings[] {
    const columns: DataTables.ColumnSettings[] = [];

    columns.push(
      {data: 'property'},
      {data: 'unit'}
    );

    if (this.canEdit()) { columns.push(EditColumn); }
    if (this.canDestroy()) { columns.push(DeleteColumn); }

    return columns;
  }
}

export default PropertyUnitsTable;
