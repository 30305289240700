import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import {
  IPropertyCsvUploadErrors,
  IPropertyCsvUploadResponse,
  IPropertyCsvUploadValidationResponse
} from 'components/tables/property_upload_table/interfaces';
import { useState } from 'react';

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

type UploadRequest<T> = (config: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
type ProgressCallback = (event: ProgressEvent) => void;
export interface IUploadProgress {
  total: number;
  loaded: number;
}
type FileUploadMutationResult = UseMutationResult<
  AxiosResponse<IPropertyCsvUploadResponse | IPropertyCsvUploadValidationResponse>,
  AxiosError<IPropertyCsvUploadErrors>,
  AxiosRequestConfig
>;

export interface IFileUploaderMutation {
  result: FileUploadMutationResult;
  progress?: IUploadProgress;
}

function withProgressCallback<T>(f: UploadRequest<T>, cb: ProgressCallback): UploadRequest<T> {
  return function (config: AxiosRequestConfig) {
    return f({
      ...config,
      onUploadProgress: cb
    });
  };
}

function uploadPropertyCsv(
  uploadFileConfig: AxiosRequestConfig
): Promise<AxiosResponse<IPropertyCsvUploadResponse | IPropertyCsvUploadValidationResponse>> {
  return axios.request({ ...uploadFileConfig, ...config });
}

export default function useFileUploader(): IFileUploaderMutation {
  const [progress, setProgress] = useState<IUploadProgress>();
  function progressCb(event: ProgressEvent) {
    setProgress({
      total: event.total,
      loaded: event.loaded
    });
  }

  const result: FileUploadMutationResult = useMutation(withProgressCallback(uploadPropertyCsv, progressCb));
  return {
    result,
    progress
  };
}
