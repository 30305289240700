import { IClaimSavedAccount } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

const getClaimBankAccounts = async (userId: number) => {
  const { data } = await axios.get<IClaimSavedAccount[]>(`/v2/admin/property_managers/${userId}/bank_accounts`);
  return data;
};

export default function useClaimBankAccounts(
  userId: number,
  makeCall: boolean
): QueryObserverResult<IClaimSavedAccount[]> | undefined {
  if (makeCall)
    return useQuery<IClaimSavedAccount[]>(['saved-bank-accounts', userId], () => getClaimBankAccounts(userId));
}
