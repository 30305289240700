import axios, { AxiosResponse } from 'axios';
import { useMutation, MutationFunction } from 'react-query';

interface ITerminateResponse {
  status?: number;
}

export interface ITerminateVariables {
  token?: string;
  endDate: Date;
  policyId?: string;
  cancellationReason: number | undefined;
  cancellationOtherReason?: string;
  requestedDate: string;
  acceptedDate: string;
}

const terminatePolicy: MutationFunction<AxiosResponse<ITerminateResponse>, ITerminateVariables> = async ({
  token,
  endDate,
  policyId,
  cancellationReason,
  cancellationOtherReason,
  requestedDate,
  acceptedDate
}: ITerminateVariables): Promise<AxiosResponse<ITerminateResponse>> => {
  const response: AxiosResponse<ITerminateResponse> = await axios.put(
    `/admin/admitted_policies/${policyId}/terminated_earlies`,
    {
      authenticity_token: token,
      insurance_policy: {
        lease_end_date: endDate,
        cancellation_reason: cancellationReason,
        cancellation_other_reason: cancellationOtherReason,
        cancellation_request_date: requestedDate,
        cancellation_acceptance_date: acceptedDate
      }
    }
  );
  return response;
};

export default function useEarlyTerminatePolicyMutation() {
  return useMutation(terminatePolicy);
}
