/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import {
  Alert2Icon,
  AlertIcon,
  CheckIcon,
  PALETTE,
  InlineNotification,
  ProgressIcon,
  WithBackgroundIcon
} from '@sayrhino/rhino-shared-js';
import { IStatusDetail } from 'components/v2/App/interfaces';

interface IProps {
  statusDetail: IStatusDetail;
  style?: object;
}

const InlineStatusBanner: React.FC<IProps> = (props) => {
  const { statusDetail: { severity, label, message, indicator_label, days_before_expiration } = {}, style } = props;
  const expIcon = (days) => {
    return days >= 7 || days == 0 ? true : false;
  };
  const cancelPolicy = (label) => {
    return label.includes('Policy Cancellation') || label.includes('Request');
  };
  const nonRenewalBanner = (label) => {
    return label.includes('Coverage expiring');
  };
  const renewalAtRiskBanner = (label) => {
    return label.includes('Renewal at risk');
  };

  const useNewCancellationsExperience = (window as any).App?.featureFlags?.useNewCancellationsExperience;

  const formattedMsg = (label, msg) => {
    if (label.concat(msg).includes('Cancellation Request —') || label.includes('requested')) {
      return msg;
    }
    if (label.includes('Cancellation request approved') || label.includes('Cancellation request denied')) {
      if (msg.contact_us_url != null) {
        return (
          <div>
            <div style={{ fontSize: '14px', color: PALETTE.neutralDark }}>
              Request reason: {msg.cancellation_reason}
            </div>
            {!useNewCancellationsExperience && msg.days_left > 0 && (
              <small style={{ fontSize: '12px', color: PALETTE.neutral65 }}>
                If this was a mistake, please
                <a href={msg.contact_us_url} target="_blank" style={{ color: PALETTE.neutral65 }}>
                  <u> contact us </u>
                </a>
                {msg.days_left === 1 ? `by today ` : `within the next ${msg.days_left} business days.`}
              </small>
            )}
          </div>
        );
      } else if (label.includes(' — Denied') || label.includes(' — Approved')) {
        return (
          <div style={{ fontSize: '14px', color: PALETTE.neutralDark }}> Request reason: {msg.cancellation_reason}</div>
        );
      }
    } else {
      return msg;
    }
  };

  const setIcon = () => {
    switch (severity) {
      case 'highPriority':
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'unset',
              justifyContent: 'flex-end',
              width: '25%',
              paddingTop: '5px'
            }}
          >
            <span style={{ fontSize: '10px', color: 'unset' }}>{indicator_label}</span>
            {expIcon(days_before_expiration) && !nonRenewalBanner(label) && (
              <Alert2Icon color={PALETTE.alert100} style={{ marginLeft: '8px' }} height="20px" width="20px" />
            )}
            {days_before_expiration != null && nonRenewalBanner(label) && (
              <ProgressIcon
                height="20px"
                width="20px"
                daysLeft={days_before_expiration}
                totalDays={90}
                style={{ marginLeft: '8px' }}
              />
            )}
            {days_before_expiration != null && !expIcon(days_before_expiration) && (
              <ProgressIcon
                height="20px"
                width="20px"
                daysLeft={days_before_expiration}
                totalDays={6}
                style={{ marginLeft: '8px' }}
              />
            )}
          </div>
        );
      case 'lowPriority':
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', color: 'unset' }}>
            <span style={{ fontSize: '10px', color: 'unset' }}>{indicator_label}</span>
            {days_before_expiration != null && (renewalAtRiskBanner(label) || nonRenewalBanner(label)) && (
              <ProgressIcon
                height="20px"
                width="20px"
                daysLeft={days_before_expiration}
                totalDays={90}
                style={{ marginLeft: '8px' }}
              />
            )}
            {!nonRenewalBanner(label) && !renewalAtRiskBanner(label) && (
              <AlertIcon color={PALETTE.warning100} height="20px" width="20px" style={{ marginLeft: '8px' }} />
            )}
          </div>
        );
      case 'product':
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', color: 'unset' }}>
            <span style={{ fontSize: '10px', color: 'unset' }}>{indicator_label}</span>
            {days_before_expiration && (
              <ProgressIcon
                height="20px"
                width="20px"
                daysLeft={days_before_expiration}
                totalDays={90}
                style={{ marginLeft: '8px' }}
              />
            )}
          </div>
        );
      case 'success':
        return (
          <WithBackgroundIcon
            style={{ background: PALETTE.success125, color: PALETTE.neutralLight }}
            height="20px"
            width="20px"
          >
            <CheckIcon height="12px" width="12px" />
          </WithBackgroundIcon>
        );
      default:
        return;
    }
  };

  return (
    <InlineNotification variant={severity} style={style}>
      <div style={{ color: 'unset', width: setIcon() ? '75%' : '100%', float: 'left' }}>
        <span style={{ color: 'unset' }}>{label}</span>
        {formattedMsg(label, message)}
      </div>
      {setIcon()}
    </InlineNotification>
  );
};

export default InlineStatusBanner;
