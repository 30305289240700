/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';

const DocumentIcon = (props) => (
  <svg width="45" height="57" viewBox="0 0 45 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M26.9999 1.97656H4.09082V35.1923H26.9999V1.97656Z" fill="#B6AEFF" />
    <path d="M10.6367 12.4551H40.0913" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M10.6367 18.7812H40.0913" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M10.6367 25.1094H40.0913" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M10.6367 31.4355H31.4982" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M44.9999 4.32422H4.09082V56.0042H44.9999V4.32422Z" fill="url(#paint0_linear)" />
    <path
      d="M1 50.68V1H39.9091V50.68H1Z"
      fill="white"
      stroke={PALETTE.neutral65}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M24.3569 23.0665C35.363 23.0665 38.6541 9.92543 38.9238 3.35491V2.02539H1.98633V32.6621C2.62218 29.0204 10.5993 23.0665 24.3569 23.0665Z"
      fill="url(#paint1_linear)"
    />
    <path d="M8.18164 9.43555H32.7271" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M8.18164 17.4355H32.7271" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M8.18164 25.4355H32.7271" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M8.18164 33.4355H27.9478" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="43.4293"
        y1="4.32422"
        x2="3.69589"
        y2="55.8088"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6AEFF" stopOpacity="0.29" />
        <stop offset="0.50277" stopColor="#B6AEFF" stopOpacity="0.62795" />
        <stop offset="1" stopColor="#6318CE" stopOpacity="0.07" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="3.91893"
        y1="-2.67794"
        x2="3.91893"
        y2="24.5683"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D3FFD2" />
        <stop offset="1" stopColor="#AEF0FF" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default DocumentIcon;
