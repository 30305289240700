import React, { useState } from 'react';
import styled from '@emotion/styled';
import Column from 'components/common/grid/Column';
import Toggle from 'components/active_states/Toggle';

const BankAccountDivider = styled.div({
  paddingBottom: '14px',
  borderBottom: '1px solid #E0E0E0'
});

const DisabledInput = styled.input(`
  &.form-control:disabled {
    background-color: #E0E0E0;
  };
`);

export const BankAccountInformation = () => {
  const [removeBankAccountInfo, setRemoveBankAccountInfo] = useState(false);
  const [automaticAccountNumber, setAutomaticAccountNumber] = useState('');
  const [automaticRoutingNumber, setAutomaticRoutingNumber] = useState('');

  return (
    <div>
      <div className="form-group renter_policy__field">
        <label htmlFor="property_automatic_account_number" className="control-label col-sm-12">
          Automatic Account Number
        </label>

        <Column sm={'10'}>
          <DisabledInput
            id="property_automatic_account_number"
            className="form-control form__input"
            name="property[automatic_account_number]"
            value={automaticAccountNumber}
            disabled={removeBankAccountInfo}
            onChange={(e) => setAutomaticAccountNumber(e.target.value)}
          />
        </Column>
      </div>

      <div className="form-group renter_policy__field">
        <label htmlFor="property_automatic_routing_number" className="control-label col-sm-12">
          Automatic Routing Number
        </label>

        <Column sm={'10'}>
          <DisabledInput
            id="property_automatic_routing_number"
            className="form-control form__input"
            name="property[automatic_routing_number]"
            value={automaticRoutingNumber}
            disabled={removeBankAccountInfo}
            onChange={(e) => setAutomaticRoutingNumber(e.target.value)}
          />
        </Column>
      </div>

      <div className="form-group renter_policy__field">
        <Column sm={'10'}>
          <BankAccountDivider>
            <label htmlFor="property_remove_bank_account_info" className="control-label">
              Remove all bank account information
            </label>

            <span className="float-right" style={{ marginTop: '5px' }}>
              <Toggle
                onColor="#F5F4FB"
                name="property[remove_bank_account_info]"
                checked={removeBankAccountInfo}
                handleToggle={(value) => setRemoveBankAccountInfo(value)}
              />
            </span>
          </BankAccountDivider>
        </Column>
      </div>
    </div>
  );
};

export default BankAccountInformation;
