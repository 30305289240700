import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

export const PageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  paddingTop: '1.875rem'
});
export const PageTitleWrapper = styled.div({
  display: 'flex',
  width: 'auto',
  justifyContent: 'space-between',
  alignItems: 'center'
});
export const PageTitle = styled.h3([FONTS.h3, { paddingLeft: '48px', marginBottom: '32px', paddingRight: '16px' }]);
export const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
});

export const StyledTableContainer = styled.div({ padding: '35px 48px 0px 48px' });
export const StyledTableFrame = styled.table({
  width: '100%'
});

export const StyledTableTitle = styled.h3([FONTS.h5, { paddingBottom: '12px' }]);

const rowPadding = { padding: '10px 0' };
export const StyledRow = styled.tr(rowPadding, {
  borderBottom: `solid 1px ${PALETTE.neutral12}`,
  ':hover': {
    cursor: 'pointer',
    backgroundColor: PALETTE.interaction4
  }
});

export const StyledCardRow = styled.tr(rowPadding, {
  borderBottom: `solid 1px ${PALETTE.neutral12}`
});
export const StyledTableFooter = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  paddingRight: '25%',
  paddingLeft: '25%',
  paddingTop: '24px'
});

export const StyledCell = styled.td([
  FONTS.p2Light,
  { minWidth: '60px', paddingBottom: '10px', paddingTop: '10px', paddingRight: '16px', margin: '20px 0' }
]);

export const StyledColumnHeader = styled.th([
  FONTS.p3Light,
  { minWidth: '60px', paddingTop: '12px', paddingRight: '16px', textAlign: 'left' }
]);
export const StyledHeaderRow = styled.tr(rowPadding);

export const StyledAddNewRowLink = styled.a([FONTS.p3Medium, { color: PALETTE.interaction100 }]);

export const StyledEditCell = styled(StyledCell)({
  width: '10%'
});
