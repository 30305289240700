import { debounce } from 'lodash';
import React, { Component } from 'react';
import Table, { QueryParams, IPaginated } from '../Table';
import Toggle from 'components/active_states/Toggle';
import { csrfToken } from 'utils/document';
import { put, request } from 'utils/request';

interface IPropertyOwner {
  id: number;
  name: string;
  integrations: string[];
  deleted_at?: string;
  created_at: string;
  updated_at?: string;
  units_count: number;
  active: boolean;
}

interface IProps {
  propertyOwners: IPaginated<IPropertyOwner>;
}

interface IState {
  propertyOwners: IPaginated<IPropertyOwner>;
}

class PropertyOwnersTable extends Component<IProps, IState> {
  private updateDataDebounced;

  constructor(props) {
    super(props);

    this.state = props;
    this.updateDataDebounced = debounce(this.updateData.bind(this), 500);
  }

  public handleToggle(owner, active) {
    const updatePath = '/admin/property_owners/' + owner.id;
    const token = csrfToken();
    put(updatePath, { active, property_owner_id: owner.id, authenticity_token: token }).catch(
      (response: { error: string }) => {
        // TODO: error reporting on the front end
      }
    );
  }

  public render() {
    const data = this.state.propertyOwners;

    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: (props) => <a href={`/admin/property_owners/${props.row.original.id}`}>{props.row.original.id}</a>
      },
      {
        Header: 'NAME',
        accessor: 'name',
        Cell: (props) => <a href={`/admin/property_owners/${props.row.original.id}`}>{props.row.original.name}</a>
      },
      {
        Header: 'INTEGRATIONS',
        accessor: 'integrations'
      },
      {
        Header: '',
        accessor: 'edit',
        Cell: (props) => (
          <a href={`/admin/property_owners/${props.row.allCells[0].value}/edit`} style={styles.link}>
            Edit
          </a>
        )
      },
      {
        Header: '',
        accessor: 'delete',
        Cell: (props) => {
          if (!props.row.original.associated_policy) {
            return (
              <a
                href={`/admin/property_owners/${props.row.allCells[0].value}`}
                data-method="delete"
                style={styles.link}
              >
                Delete
              </a>
            );
          } else {
            return <div />;
          }
        }
      },
      {
        Header: 'STATUS',
        accessor: 'active',
        Cell: (props) => (
          <Toggle
            onColor="#F5F4FB"
            handleToggle={(active) => this.handleToggle(props.data[props.row.id], active)}
            name={`property-owner-status-${props.row.id}`}
            checked={props.data[props.row.id].active}
          />
        )
      }
    ];

    return <Table paginatedData={data} columns={columns} update={this.updateDataDebounced} />;
  }

  private updateData(params: QueryParams) {
    const [pageIndex, pageSize, sortById, sortByDesc, filterValue, filters] = params;
    const query = {
      page_size: pageSize,
      page_index: pageIndex,
      filters
    };
    if (sortById !== undefined && sortByDesc !== undefined) {
      Object.assign(query, {
        sort_by: sortById,
        sort_desc: sortByDesc
      });
    }
    if (filterValue !== undefined) {
      Object.assign(query, {
        filter_value: filterValue
      });
    }
    request('/admin/property_owners', query).then((propertyOwners) => {
      this.setState({ propertyOwners });
    });
  }
}

const styles = {
  link: {
    color: '#6318CE'
  }
};

export default PropertyOwnersTable;
