import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, Checkbox, ButtonHabitat } from '@sayrhino/rhino-shared-js';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import useGenerateReport from 'api/v2/generateReport';

const View = styled.div({
  padding: '0 50px'
});

const Note = styled.p([
  FONTS.p2Medium,
  {
    color: PALETTE.neutral55
  }
]);

const Options = styled.div({
  margin: '24px 0 64px'
});

const ToLeft = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
});

const GenerateButton = styled(ButtonHabitat)({
  alignSelf: 'flex-end'
});

type IProp = {
  partnerIntegrationId?: string;
  integrationType?: string | null;
  flipView: () => void;
};

const ReportForm: React.FC<IProp> = ({ partnerIntegrationId, flipView, integrationType }) => {
  const [checkedGoLive, setCheckedGoLive] = useState<boolean>(false);
  const [checkedPush, setCheckedPush] = useState<boolean>(false);
  const [checkedUnitDirectory, setCheckedUnitDirectory] = useState<boolean>(false);
  const [checkedValidatePortfolio, setCheckedValidatePortfolio] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const { mutate: generateReportMutation, isLoading } = useGenerateReport();
  const { addToast } = useToast();
  const isRealPage = integrationType === 'realpage';
  const isEntrata = integrationType === 'entrata';

  useEffect(() => {
    if (checkedGoLive || checkedPush || checkedUnitDirectory || checkedValidatePortfolio) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [checkedGoLive, checkedPush, checkedUnitDirectory, checkedValidatePortfolio]);

  const onSubmit = () => {
    let data: string[] = [];
    if (checkedGoLive) {
      data.push('go_live');
    }
    if (checkedPush) {
      data.push('push');
    }
    if (checkedUnitDirectory) {
      data.push('asset_pull');
    }
    if (checkedValidatePortfolio) {
      data.push('validate_portfolio');
    }

    generateReportMutation(
      { uuid: partnerIntegrationId, data },
      {
        onSuccess: () => {
          addToast('Reports will be generated', '', TOAST_STATUS.SUCCESS);
          flipView();
        },
        onError: () => {
          addToast('something went wrong', '', TOAST_STATUS.ERROR);
        }
      }
    );
  };

  return (
    <View>
      <Note>The reports will be emailed once they have been generated</Note>
      <Options>
        <Checkbox
          id="goLive"
          label="Go live report"
          data-cy="goLive"
          checked={checkedGoLive}
          onCheckedChange={(value) => setCheckedGoLive(value)}
        />
        {!isEntrata && (
          <Checkbox
            id="pushReport"
            label="Push report"
            data-cy="pushReport"
            checked={checkedPush}
            onCheckedChange={(value) => setCheckedPush(value)}
          />
        )}
        <Checkbox
          id="unitDirectory"
          label="Unit directory"
          data-cy="unitDirectory"
          checked={checkedUnitDirectory}
          onCheckedChange={(value) => setCheckedUnitDirectory(value)}
        />
        {isRealPage && (
          <Checkbox
            id="validatePortfolio"
            label="Portfolio validation report"
            data-cy="validatePortfolio"
            checked={checkedValidatePortfolio}
            onCheckedChange={(value) => setCheckedValidatePortfolio(value)}
          />
        )}
      </Options>
      <ToLeft>
        <GenerateButton
          id="generateReport"
          children={isLoading ? 'Generating reports' : 'Generate reports'}
          variant="interaction"
          disabled={!isValid || isLoading}
          onClick={onSubmit}
        />
      </ToLeft>
    </View>
  );
};

export default ReportForm;
