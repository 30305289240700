import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { PALETTE, FONTS, FONT_FAMILY } from '@sayrhino/rhino-shared-js';

// uses !important to override default styles in ReactToolTip
const Tooltip = styled(ReactTooltip)({
  padding: '24px 20px 24px 32px !important',
  border: `1px solid ${PALETTE.neutral12} !important`,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16) !important',
  borderRadius: '8px !important',
  width: '350px',
  opacity: '10 !important'
});
const TooltipIcon = styled.div([FONT_FAMILY.medium], {
  border: `1px solid ${PALETTE.neutralDark}`,
  height: '14px',
  width: '14px',
  lineHeight: '14px',
  fontSize: '10px',
  borderRadius: '10px',
  textAlign: 'center',
  marginLeft: '10px',
  '&:hover': {
    cursor: 'pointer',
    background: PALETTE.neutralDark,
    color: PALETTE.neutralLight
  }
});

const ContentWrapper = styled.div({ display: 'flex', justifyContent: 'space-between' });
const MemberType = styled.div({ width: '50%' });
const Title = styled.h6([FONTS.h6Demi, { paddingBottom: '10px' }]);
const Action = styled.div({ display: 'flex', paddingBottom: '8px' });
const Text = styled.p([FONTS.p2]);
type IndicatorIconProps = { color: string };
const IndicatorIcon = styled.div<IndicatorIconProps>(({ color }) => ({
  height: '8px',
  width: '8px',
  borderRadius: '4px',
  margin: '7px 6px 0 0',
  background: color
}));

const memberTypeActions = [
  { action: 'Can invite renters' },
  { action: 'Can view all properties' },
  { action: 'Can file claims', noPermission: 'leasing_agent' },
  { action: 'Can view all tenants' }
];

const MemberTypeTooltip: React.FC = () => {
  const MemberActions = ({ action, noPermission, memberType }) => (
    <Action>
      <IndicatorIcon color={noPermission === memberType ? PALETTE.alert100 : PALETTE.success125} />
      <Text>{action}</Text>
    </Action>
  );
  return (
    <>
      <TooltipIcon data-tip data-for="member-type-info">
        ?
      </TooltipIcon>
      <Tooltip id="member-type-info" type="light" effect="solid" place="bottom">
        <ContentWrapper>
          <MemberType>
            <Title>Property manager admin</Title>
            {memberTypeActions.map(({ action, noPermission }) => (
              <MemberActions
                key={action}
                action={action}
                noPermission={noPermission}
                memberType="property_manager_admin"
              />
            ))}
          </MemberType>
          <MemberType>
            <Title>Property manager</Title>
            {memberTypeActions.map(({ action, noPermission }) => (
              <MemberActions key={action} action={action} noPermission={noPermission} memberType="property_manager" />
            ))}
          </MemberType>
          <MemberType>
            <Title>Leasing Agents</Title>
            {memberTypeActions.map(({ action, noPermission }) => (
              <MemberActions key={action} action={action} noPermission={noPermission} memberType="leasing_agent" />
            ))}
          </MemberType>
        </ContentWrapper>
      </Tooltip>
    </>
  );
};

export default MemberTypeTooltip;
