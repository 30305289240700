/** @jsx jsx */
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { Button } from '@sayrhino/rhino-shared-js';
import { css, jsx } from '@emotion/core';

interface IProps {
  onClick: () => void;
  disabled?: boolean;
  uploadNewCsvLink?: (nextStep: string) => void;
}

const bannerCSS = css({
  position: 'fixed',
  bottom: '12px',
  width: '576px',
  borderRadius: '8px',
  background: PALETTE.neutralDark,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '23px 33px 23px 48px',
  alignItems: 'center',
  left: '35%'
});

const Banner = (props: IProps) => {
  const { uploadNewCsvLink, disabled, onClick } = props;

  return (
    <div css={bannerCSS}>
      <div css={[FONTS.h5, { color: PALETTE.neutralLight }]}>
        <u
          css={{ cursor: 'pointer' }}
          onClick={() => {
            uploadNewCsvLink && uploadNewCsvLink('UploadProperties');
          }}
          role="link"
        >
          Upload new CSV
        </u>
      </div>
      <div>
        <Button
          variant="tertiary"
          disabled={disabled}
          style={{ border: `1px solid ${PALETTE.neutralLight}`, color: PALETTE.neutralLight }}
          onClick={onClick}
        >
          {disabled ? 'Loading...' : 'Continue'}
        </Button>
      </div>
    </div>
  );
};

export default Banner;
