import { ArrowDown, DropdownMenu } from '@sayrhino/rhino-shared-js';
import React, { useState } from 'react';
import FileViewer from '../FileViewer';
import { IFileData, IPMAPropertyEntityDocuments } from '../interfaces';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import PropertyEntityDocuments, { getDocumentName, IDocumentSet } from './PropertyEntityDocuments';
import { Banner, DetailsText, DocumentDropDown, DocumentDropDownContent, NoLineSubtitle, Subtitle } from './Styled';
import { getPropertyEntityDocumentUrl } from './usePropertyEntityDocumentsUrl';

interface IProps {
  documents?: IPMAPropertyEntityDocuments;
}

const PropertyEntityDocumentsSection: React.FC<IProps> = (props) => {
  const policy_documents = props.documents?.policy_documents;
  const [documentStateFilter, updateDocumentStateFilter] = useState<string>('');
  const documentStates = policy_documents?.map((documentSet) => documentSet.state);
  const activeDocuments = policy_documents?.filter((set) => set.expired == false);
  const { addToast } = useToast();
  // for drop down
  const stateSpecificDocumentSet = activeDocuments?.filter((documentSet) => documentSet.state === documentStateFilter);
  const documentSet = documentStateFilter ? stateSpecificDocumentSet : activeDocuments;
  const [selectedDocumentType, updateSelectedDocumentType] = useState<string>('');
  // for FileViewer
  const [selectedFileDoc, updateSelectedFileDoc] = useState<IDocumentSet>();
  const [showFileViewer, setShowFileViewer] = useState<boolean>(false);
  const [selectedFileData, setSelectedFileData] = useState<IFileData>();
  const [loadingFile, updateLoadingFile] = useState<boolean>(false);
  const documentSetByState = policy_documents?.filter((set) => set.state === selectedFileDoc?.state);
  const fileViewerDocName = getDocumentName(selectedDocumentType, selectedFileDoc?.state);

  const handleAttachmentModal = (fileData: IDocumentSet, documentType: string) => {
    updateLoadingFile(true);
    if (!showFileViewer) {
      updateSelectedDocumentType(documentType);
      setShowFileViewer(true);
    }
    updateSelectedFileDoc(fileData);
    getPropertyEntityDocumentUrl(Number(fileData.document_id), documentType)
      .then((res) => {
        setSelectedFileData(res);
        updateLoadingFile(false);
      })
      .catch(() => {
        setSelectedFileData(undefined);
        updateLoadingFile(false);
        addToast('Something went wrong', 'neutral_warning', TOAST_STATUS.ERROR);
      });
  };

  const getAndDownloadDocument = (documentId: number, documentType: string) => {
    getPropertyEntityDocumentUrl(Number(documentId), documentType)
      .then((res) => {
        downloadDocument(res.url, res.filename);
      })
      .catch(() => {
        addToast('Something went wrong', 'neutral_warning', TOAST_STATUS.ERROR);
      });
  };

  const downloadDocument = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {showFileViewer && (
        <FileViewer
          selectedFile={selectedFileData}
          header={fileViewerDocName}
          closeModel={() => setShowFileViewer(false)}
          downloadFile={downloadDocument}
          loadingFile={loadingFile}
          subText={`${selectedFileDoc?.state} — ${selectedFileDoc?.expired ? 'Expired' : 'Created'} ${
            selectedFileDoc?.created_or_expired_at
          }`}
        >
          <Subtitle>Past {fileViewerDocName} Documents</Subtitle>
          {documentSetByState?.map((set) => (
            <PropertyEntityDocuments
              set={set}
              documentType={selectedDocumentType}
              key={`${selectedDocumentType}-${set.document_id}`}
              handleFileViewerModal={handleAttachmentModal}
              downloadDocument={getAndDownloadDocument}
              fileViewer={true}
            />
          ))}
        </FileViewer>
      )}
      <Banner>
        {documentSet && documentSet.length > 0 ? (
          <>
            <NoLineSubtitle>Documents</NoLineSubtitle>
            <DropdownMenu.Root>
              <DocumentDropDown>
                {documentStateFilter || 'Select State (All)'}
                <ArrowDown width={24} height={24} />
              </DocumentDropDown>
              <DocumentDropDownContent>
                <DropdownMenu.Item onSelect={() => updateDocumentStateFilter('')}>All</DropdownMenu.Item>
                {Array.from(new Set(documentStates)).map((state) => (
                  <DropdownMenu.Item key={state} onSelect={() => updateDocumentStateFilter(state)}>
                    {state}
                  </DropdownMenu.Item>
                ))}
              </DocumentDropDownContent>
            </DropdownMenu.Root>

            {documentSet?.map((set) =>
              set.document_types.map((docType) => (
                <PropertyEntityDocuments
                  set={set}
                  documentType={docType}
                  key={`${docType}-${set.document_id}`}
                  handleFileViewerModal={handleAttachmentModal}
                  downloadDocument={getAndDownloadDocument}
                />
              ))
            )}
          </>
        ) : (
          <>
            <Subtitle>Documents</Subtitle>
            <DetailsText>
              This is where you’ll see relevant policy documents when you have your first active policy.
            </DetailsText>
          </>
        )}
      </Banner>
    </>
  );
};

export default PropertyEntityDocumentsSection;
