import axios from 'axios';
import { ICustomCoverageRuleset } from 'components/v2/App/interfaces';
import { QueryObserverResult, useQuery } from 'react-query';

export const PROPERTY_COVERAGE_RULES_KEY = 'property coverage rules';
const getPropertyCoverageRules = async (property_owner_id?: string) => {
  const { data } = await axios.get<ICustomCoverageRuleset[]>(
    `/v2/admin/property_owners/${property_owner_id}/coverage_rule_sets`
  );
  return data;
};

export default function usePropertyCoverageRules(
  id?: string
): QueryObserverResult<ICustomCoverageRuleset[]> | undefined {
  return useQuery<ICustomCoverageRuleset[]>([PROPERTY_COVERAGE_RULES_KEY, id], () => getPropertyCoverageRules(id), {
    enabled: !!id
  });
}
