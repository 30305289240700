import { IDataTablesApi } from './application_table';

export enum DatatableFilterEnum {
  City = 'city',
  State = 'state',
  ZipCode = 'zip_code',
  Status = 'status',
  AddressState = 'address_state'
}

export class DatatableFilter {
  private static ajaxPathMapping: any = {
    city: 'cities',
    state: 'states',
    zip_code: 'zip_codes',
    status: 'renter_policies/statuses',
    address_state: 'renter_policies/states'
  };
  private static selectWrapper: string = '<div class="col-sm-3"></div>';

  constructor(
    protected name: DatatableFilterEnum,
    protected dataTable: IDataTablesApi,
    protected parentNode: JQuery
  ) {
    this.name = name;
    this.dataTable = dataTable;
    this.parentNode = parentNode;
  }

  public call(): JQuery {
    const name = this.name;
    const select: any = this.createSelect();
    const column = this.getColumn();
    const placeholder = `Search by ${name.replace('_', ' ')}`;
    const ajax = {
      url: `/admin/${this.getAjaxPath()}`,
      dataType: 'json',
      processResults: (data) => {
        const results = data.map((item) => ({ id: item, text: item }));

        return { results };
      }
    };

    select
      .appendTo(this.parentNode)
      .wrap(DatatableFilter.selectWrapper)
      .select2({ placeholder, ajax });

    this.addSelectEvents(select, column);

    return select;
  }

  private getColumn(): DataTables.ColumnsMethods {
    const cssClass = `.${this.name.replace('_', '-')}`;

    return this.dataTable.columns(cssClass);
  }

  private getAjaxPath(): string {
    return DatatableFilter.ajaxPathMapping[this.name];
  }

  private createSelect(): JQuery {
    return $(
      `<select class="form-control input-md" id="${this.name}-filter" multiple="true">
        <option value=""></option>
      </select>`
    );
  }

  private addSelectEvents(
    select: JQuery,
    column: DataTables.ColumnsMethods
  ): JQuery {
    const onChange = (event) => {
      const selectedValues: any = $(event.currentTarget).val() || [];

      column.search(`${selectedValues.join('|')}`).draw();
    };

    return select
      .on('select2:select', onChange)
      .on('select2:unselect', onChange);
  }
}
