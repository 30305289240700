import { IUploadedProp } from 'components/v2/App/utils/propertyMemberAssign';

export interface IPropertyCsvRow {
  address: string | null;
  integration_code: string | null;
  integration_type: string | null;
  legal_property_entity: string | null;
  property_default: string | null;
  property_name: string | null;
  property_owner: string | null;
  salesforce_opportunity_id: string | null;
  student_housing: string | null;
  type: string | null;
  unit: string | null;
  unit_default: string | null;
}

export type PropertyCsv = IPropertyCsvRow[];

export interface IPropertyCsvRowErrors {
  row: number;
  property_name?: IPropertyCsvRowErrorValues[];
  address?: IPropertyCsvRowErrorValues[];
  type?: IPropertyCsvRowErrorValues[];
  property?: IPropertyCsvRowErrorValues[];
  unit?: IPropertyCsvRowErrorValues[];
  property_owner?: IPropertyCsvRowErrorValues[];
  legal_property_entity?: IPropertyCsvRowErrorValues[];
  property_default?: IPropertyCsvRowErrorValues[];
  unit_default?: IPropertyCsvRowErrorValues[];
  salesforce_opportunity_id?: IPropertyCsvRowErrorValues[];
  student_housing?: IPropertyCsvRowErrorValues[];
  integration_type?: IPropertyCsvRowErrorValues[];
  integration_code?: IPropertyCsvRowErrorValues[];
}

interface IPropertyCsvRowErrorValues {
  detail: string;
  message: string;
}

export type PropertyCsvErrors = IPropertyCsvRowErrors[] | undefined;

export interface IPropertyCsvUploadValidationResponse {
  url: string;
}

export interface IPropertyCsvUploadValidationStatus {
  status: 'complete' | 'failed' | 'processing';
  validated_data: string;
}

export interface IPropertyCsvUploadResponse {
  valid_csv: {
    parsed_csv: PropertyCsv;
    filename: string;
  };
}

export interface IPropertyCsvUploadErrors {
  error: {
    error_type: string;
    parsed_csv?: PropertyCsv;
    data: PropertyCsvErrors;
    filename: string;
  };
}

export interface IError {
  error: IUploadError;
}
interface IUploadError {
  filename: string;
  message: string;
  property?: IPropertyCsvRow;
}

export type PropertyCsvUploadResponse = IPropertyCsvUploadResponse | IPropertyCsvUploadErrors | IUploadedProp[];

export function isPropertyCsvUploadResponse(x: PropertyCsvUploadResponse): x is IPropertyCsvUploadResponse {
  return (x as IPropertyCsvUploadResponse).valid_csv !== undefined;
}

export function isPropertyCsvUploadErrors(x: PropertyCsvUploadResponse): x is IPropertyCsvUploadErrors {
  return (x as IPropertyCsvUploadErrors).error !== undefined;
}

export function isPropertyCsvUploaded(x: PropertyCsvUploadResponse): x is IUploadedProp[] {
  return (x as IUploadedProp[]) !== undefined;
}
