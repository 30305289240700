import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation, MutationFunction } from 'react-query';

type IPropertyOwnerPostStatus = { id: number; active: boolean };
interface IStatusResponse {
  active: boolean;
}

const updatePropertyOwner: MutationFunction<AxiosResponse<IStatusResponse>, IPropertyOwnerPostStatus> = async (
  property_owner: IPropertyOwnerPostStatus
): Promise<AxiosResponse<IStatusResponse>> => {
  const authenticity_token = csrfToken();
  const response: AxiosResponse<IStatusResponse> = await axios.patch(
    `/v2/admin/property_owners/${property_owner.id}/change_active_status`,
    {
      active: property_owner.active
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useUpdatePropertyOwnerStatus() {
  return useMutation(updatePropertyOwner);
}
