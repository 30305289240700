/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PALETTE, FONTS, Loading, PlusIcon } from '@sayrhino/rhino-shared-js';
import useInvitations from 'api/v2/useInvitations';
import { flatMap } from 'lodash';
import React from 'react';
import { useQueryClient } from 'react-query';
import { NavLink as Link, useLocation, useMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  getInvitationPropertyFilterOptions,
  invitationPropertyFilterOptionsKey
} from '../../../api/v2/useInvitationPropertyFilterOptions';
import {
  getInvitationStaticFilterOptions,
  invitationStaticFilterOptionsKey
} from '../../../api/v2/useInvitationStaticFilterOptions';
import useIntersectionObserver from '../../../hooks/v2/useIntersectionObserver';
import CardLink from './CardLink';
import FilterLink from './Filters/FilterLink';
import { useFilterContext } from './utils/filters';
import InvitationCard, { convertInvitationUpdateToCardProps } from './InvitationCard';
import { urlWithReferrer } from './utils';
import { useCanSendInvite, useKillingInvitesFullyEnabled, useKillingInvitesSemiEnabled, useUserRole } from './utils/userRole';
import { useSegmentTrackOnLoad } from './utils/segmentTracker';

const buttonLinkCSS = css({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  padding: '0 24px',
  borderRadius: '24px',
  fontFamily: 'MaisonNeueExtendedMedium',
  fontSize: '16px',
  lineHeight: '28px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: PALETTE.neutralDark,
  border: '1px solid',
  borderColor: PALETTE.neutralDark,
  '&:hover': {
    backgroundColor: PALETTE.neutralDark,
    color: PALETTE.neutralLight
  },
  '&:disabled': {
    color: PALETTE.neutral25,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'not-allowed'
  }
});

const StyledDiv = styled.div({
  border: '1px solid #6A3BF5',
  borderRadius: '8px',
  paddingLeft: '35px',
  paddingRight: '25px',
  paddingTop: '25px',
  paddingBottom: '25px',
  marginBottom: '15px',
  fontFamily: 'MaisonNeueExtendedMedium'
});

const StyledSpan = styled.label({
  color: PALETTE.neutral90,
  marginBottom: 0
});

const StyledLink = styled.a({
  color: '#6A3BF5',
  textDecoration: 'none',
  cursor: 'pointer'
});

const filtersPath = '/admin/invitations/filters';
const invitationsPath = '/admin/invitations';

const prefetchFilterOptions = (debouncedFilters) => {
  const cache = useQueryClient();
  const params = { filter: { states: debouncedFilters.states }, page: 1 };

  cache.prefetchQuery([invitationPropertyFilterOptionsKey, params], () => getInvitationPropertyFilterOptions(params));
  cache.prefetchQuery([invitationStaticFilterOptionsKey], getInvitationStaticFilterOptions);
};

export const InvitationList = () => {
  const { debouncedFilters, filtersCount } = useFilterContext();
  const queryCache = useQueryClient();
  // callback that removes old query group results that don't match the new filters
  const onSuccess = () => queryCache.removeQueries(['invitations'], { exact: true });
  const invitationsResponse = useInvitations(debouncedFilters, onSuccess);
  const { hasNextPage, fetchNextPage, isSuccess, isLoading, isFetchingNextPage } = invitationsResponse;
  const data = invitationsResponse.data?.pages;
  const loadMoreRef = React.useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    root: null,
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage
  });

  prefetchFilterOptions(debouncedFilters);
  const invitationsData = flatMap(data, (d) => d.data || []);
  const hasInvitations = isSuccess && invitationsData.length > 0;
  const filtersActive = Boolean(useMatch(filtersPath));
  const location = useLocation();
  const invitations =
    isSuccess &&
    invitationsData.map((invitation) => {
      return (
        <CardLink
          key={`${invitation.type}-${invitation.id}`}
          to={urlWithReferrer(`/admin/invitations/${invitation.id}`, location.pathname)}
        >
          <InvitationCard
            key={`${invitation.type}-${invitation.id}`}
            {...convertInvitationUpdateToCardProps(invitation)}
          />
        </CardLink>
      );
    });
  const empty = isSuccess && (
    <div>
      <h6 css={[FONTS.h3]}>No Invitations to Show</h6>
    </div>
  );
  const loadMoreDiv = isFetchingNextPage && <Loading />;
  const nothingMoreDiv = invitations.length > 1 && 'Nothing more to load';
  const loading = (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Loading />
    </div>
  );
  const { isAdmin, isPropertyManager, isPropertyManagerAdmin, isListingAgent } = useUserRole();
  const canSendInvites = useCanSendInvite();
  const killingInvitesFullyEnabled = useKillingInvitesFullyEnabled();
  const killingInvitesSemiEnabled = useKillingInvitesSemiEnabled();
  const canSendInvitation =
    (isAdmin || isPropertyManager || isPropertyManagerAdmin || isListingAgent) && canSendInvites;

  const launchResidentsTab = (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <span>
        <StyledLink href={`/admin/partners/residents`}>Launch Residents Tab</StyledLink>
      </span>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.41249 2.5042C7.18468 2.27639 6.81534 2.27639 6.58753 2.5042C6.35973 2.732 6.35973 3.10135 6.58753 3.32916L9.67505 6.41668H2.91668C2.59451 6.41668 2.33334 6.67784 2.33334 7.00001C2.33334 7.32218 2.59451 7.58334 2.91668 7.58334H9.67505L6.58753 10.6709C6.35973 10.8987 6.35973 11.268 6.58753 11.4958C6.81534 11.7236 7.18468 11.7236 7.41249 11.4958L11.4954 7.41291C11.4968 7.4115 11.4982 7.41009 11.4996 7.40866C11.6026 7.3038 11.6662 7.16021 11.6667 7.00176C11.6667 7.00118 11.6667 7.00059 11.6667 7.00001C11.6667 6.99943 11.6667 6.99884 11.6667 6.99826C11.6664 6.9198 11.6507 6.84499 11.6224 6.77672C11.5946 6.70948 11.5537 6.64639 11.4996 6.59136C11.4982 6.58996 11.4969 6.58856 11.4955 6.58717M7.41249 2.5042L11.4955 6.58717L7.41249 2.5042Z" fill="#6A3BF5"/>
      </svg>
    </div>
  );

  useSegmentTrackOnLoad('Invitations Viewed');

  return (
    <div
      style={{
        maxWidth: '480px',
        margin: 'auto',
        paddingTop: '1.875rem'
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '1.875rem' }}
      >
        <h2 css={[FONTS.h3]}>Invitations</h2>
        {canSendInvitation && (
          <Link to="/admin/invitations/new" css={[buttonLinkCSS]}>
            Send invitation <PlusIcon css={{ paddingLeft: 10 }} />
          </Link>
        )}
      </div>
      <div css={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', marginBottom: '1.5rem' }}>
        <FilterLink
          filtersActive={filtersActive}
          filtersCount={filtersCount}
          id="invitations-filter-button"
          to={urlWithReferrer(filtersActive ? invitationsPath : filtersPath, location.pathname)}
        />
      </div>
      {killingInvitesFullyEnabled && (
        <StyledDiv>
          <StyledSpan>
            Streamline your resident management experience with the new Residents tab. This is where you can start managing new resident activity.
          </StyledSpan>
          <br /><br />
          <StyledSpan>New residents won’t have invites going forward.</StyledSpan>
          <br /><br />
          {launchResidentsTab}
        </StyledDiv>
      )}
      {killingInvitesSemiEnabled && (
        <StyledDiv>
          <StyledSpan>
            Streamline your resident management experience with the new Residents tab. This is where you can find the latest information for all residents living in Leasing Integrated properties.
          </StyledSpan>
          <br /><br />
          {launchResidentsTab}
        </StyledDiv>
      )}
      {hasInvitations ? invitations : empty}
      {isLoading ? loading : null}
      <div
        ref={loadMoreRef}
        css={[
          FONTS.h4,
          {
            display: hasInvitations ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '18px'
          }
        ]}
      >
        {hasNextPage ? loadMoreDiv : nothingMoreDiv}
      </div>
    </div>
  );
};

export default InvitationList;
