import { ChangeEvent, useEffect } from 'react';
import { centsToUSDFormatter, sanitizeCurrencyInput } from '../utils';
import { CoverageTypeEnum, ICoverageRule, ICoverageRuleset } from '../interfaces';
import { useState } from 'react';
import { MAX_COVERAGE_AMOUNT } from './CustomCoverageRulesForm';
import Decimal from 'decimal.js';
import useCoverageRuleForm, { UseCoverageRuleFormReturn } from './useCoverageRuleForm';

const useCoverageRulesForm = (coverageRuleSet?: ICoverageRuleset) => {
  // Have to find coverage rulesets by name since we don't have a flag denoting which ones are which
  const coverageRule = coverageRuleSet
    ? coverageRuleSet.coverage_rules.find((rule) => rule.coverage_option_name === 'Approval')
    : undefined;
  const conditionalCoverageRule = coverageRuleSet
    ? coverageRuleSet.coverage_rules.find((rule) => rule.coverage_option_name === 'Approval with Conditions')
    : undefined;
  const conditionalGTDepositCoverageRule = coverageRuleSet
    ? coverageRuleSet.coverage_rules.find(
        (rule) =>
          rule.coverage_option_name === 'Approval with Conditions' && rule.condition_type === 'deposit_gt_threshold'
      )
    : undefined;
  const conditionalLTEDepositCoverageRule = coverageRuleSet
    ? coverageRuleSet.coverage_rules.find(
        (rule) =>
          rule.coverage_option_name === 'Approval with Conditions' && rule.condition_type === 'deposit_lte_threshold'
      )
    : undefined;
  const allCreditStatusCoverageRule = coverageRuleSet
    ? coverageRuleSet.coverage_rules.find((rule) => rule.coverage_option_name === 'All Yardi Residents')
    : undefined;

  const coverageRuleForm = useCoverageRuleForm(coverageRule);
  const conditionalCoverageRuleForm = useCoverageRuleForm(conditionalCoverageRule);
  const conditionalGTDepositCoverageRuleForm = useCoverageRuleForm(conditionalGTDepositCoverageRule);
  const conditionalLTEDepositCoverageRuleForm = useCoverageRuleForm(conditionalLTEDepositCoverageRule);
  const allCreditStatusCoverageRuleForm = useCoverageRuleForm(allCreditStatusCoverageRule);

  const areCoverageRulesValid = () => {
    return (
      allCreditStatusCoverageRuleForm.isValid() ||
      (coverageRuleForm.isValid() && conditionalCoverageRuleForm.isValid() && isValidConditionalDepositCoverage())
    );
  };

  const isValidConditionalDepositCoverage = () => {
    return (
      coverageRuleSet?.deposit_threshold_cents &&
      coverageRuleSet?.deposit_threshold_cents > 0 &&
      conditionalGTDepositCoverageRuleForm.isValid() &&
      conditionalLTEDepositCoverageRuleForm.isValid()
    );
  };

  const raiseCoverageErrors = () => {
    coverageRuleForm.raiseCoverageErrors();
    conditionalCoverageRuleForm.raiseCoverageErrors();
    if (isValidConditionalDepositCoverage()) {
      conditionalGTDepositCoverageRuleForm.raiseCoverageErrors();
      conditionalLTEDepositCoverageRuleForm.raiseCoverageErrors();
    }
  };

  // Build partner coverage rules from state, returns as ICoverageRule[]
  const getPartnerCoverageRules = (inviteResidentCreditStatuses?: Array<string>): ICoverageRule[] => {
    let coverageRules: ICoverageRule[] = [];
    const allCreditStatusEnabled = inviteResidentCreditStatuses?.length === 0;
    if (coverageRuleForm.isValid() && !allCreditStatusEnabled) {
      coverageRules.push(coverageRuleForm.getPartnerCoverageRule('Approval'));
    }
    if (conditionalCoverageRuleForm.isValid() && !allCreditStatusEnabled) {
      coverageRules.push(conditionalCoverageRuleForm.getPartnerCoverageRule('Approval with Conditions'));
    }
    if (conditionalGTDepositCoverageRuleForm.isValid() && !allCreditStatusEnabled) {
      coverageRules.push(
        conditionalGTDepositCoverageRuleForm.getPartnerCoverageRule('Approval with Conditions', 'deposit_gt_threshold')
      );
    }
    if (conditionalLTEDepositCoverageRuleForm.isValid() && !allCreditStatusEnabled) {
      coverageRules.push(
        conditionalLTEDepositCoverageRuleForm.getPartnerCoverageRule(
          'Approval with Conditions',
          'deposit_lte_threshold'
        )
      );
    }
    if (allCreditStatusCoverageRuleForm.isValid() && allCreditStatusEnabled) {
      coverageRules.push(allCreditStatusCoverageRuleForm.getPartnerCoverageRule('All Yardi Residents'));
    }
    return coverageRules;
  };

  return {
    getPartnerCoverageRules,
    areCoverageRulesValid,
    raiseCoverageErrors,
    coverageRuleSet,
    coverageRule: coverageRuleForm,
    conditionalCoverageRule: conditionalCoverageRuleForm,
    conditionalGTDepositCoverageRule: conditionalGTDepositCoverageRuleForm,
    conditionalLTEDepositCoverageRule: conditionalLTEDepositCoverageRuleForm,
    allCreditStatusRule: allCreditStatusCoverageRuleForm,
    handleSwitchSelect: coverageRuleForm.handleSwitchSelect,
    handleCashSwitchSelect: coverageRuleForm.handleCashSwitchSelect,
    handleConditionalSwitchSelect: conditionalCoverageRuleForm.handleSwitchSelect,
    handleConditionalCashSwitchSelect: conditionalCoverageRuleForm.handleCashSwitchSelect,
    handleAllCreditStatusSwitchSelect: allCreditStatusCoverageRuleForm.handleSwitchSelect,
    hasCoverageValueChanged: coverageRuleForm.hasCoverageValueChanged
  };
};

export type UseCoverageRulesFormReturn = ReturnType<typeof useCoverageRulesForm>;
export default useCoverageRulesForm;
