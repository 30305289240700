import React from 'react';
import styled from '@emotion/styled';
import { ButtonHabitat, PALETTE, FONTS, FONT_FAMILY, Loading } from '@sayrhino/rhino-shared-js';
import { flatMap } from 'lodash';
import useUserRole from './utils/userRole';
import useUpdates from 'api/v2/useUpdates';
import ClaimCard, { convertClaimUpdateToCardProps } from './ClaimCard';
import InsurancePolicyCard, { convertInsurancePolicyUpdateToCardProps } from './InsurancePolicyCard';
import { isClaimUpdate, isInsurancePolicyUpdate } from './interfaces';
import { getClaimPath, getInsurancePolicyPath } from './utils/routeHelpers';
import { useLocation } from 'react-router-dom';
import { urlWithDashboardReferrer } from './Dashboard';
import CardLink from './CardLink';

const StyledWrapperDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '480px',
  margin: 'auto',
  paddingTop: '1.875rem'
});

const StyledHeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '1.875rem'
});

const StyledHeader = styled.h3([FONTS.h3]);
const StyledTotalCount = styled.p([
  FONTS.p2,
  FONT_FAMILY.extendedMedium,
  {
    color: PALETTE.neutralLight,
    backgroundColor: PALETTE.neutralDark,
    padding: '0 8px',
    alignSelf: 'center',
    borderRadius: 50
  }
]);
const StyledButton = styled(ButtonHabitat)({ margin: 'auto', marginBottom: '60px' });

const ActivityFeed = () => {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useUpdates();
  const location = useLocation();
  const updatesMeta = data?.pages[0]?.meta;
  const userRole = useUserRole();

  const activityFeedItems = flatMap(data?.pages, (data) => data.data || []).map((update) => {
    if (isClaimUpdate(update)) {
      return (
        <CardLink to={getClaimPath(update)} key={`update-${update.type}-${update.id}`}>
          <ClaimCard {...convertClaimUpdateToCardProps(update.attributes, userRole)} />
        </CardLink>
      );
    } else if (isInsurancePolicyUpdate(update)) {
      const pathname = location.pathname === getInsurancePolicyPath(update) ? '/' : getInsurancePolicyPath(update);
      return (
        <CardLink to={urlWithDashboardReferrer(pathname)} key={`update-${update.type}-${update.id}`}>
          <InsurancePolicyCard {...convertInsurancePolicyUpdateToCardProps(update)} />
        </CardLink>
      );
    }
  });

  return (
    <>
      <StyledWrapperDiv>
        <StyledHeaderWrapper>
          <StyledHeader data-testid="updates-header">Activity feed</StyledHeader>
          <StyledTotalCount>{updatesMeta?.total_count || 0}</StyledTotalCount>
        </StyledHeaderWrapper>
        {activityFeedItems}
      </StyledWrapperDiv>

      <StyledButton
        id="load-more-activity-feed-button"
        onClick={() => fetchNextPage()}
        usage="secondary"
        disabled={!hasNextPage || Boolean(isFetchingNextPage)}
      >
        {isFetchingNextPage ? <Loading /> : hasNextPage ? 'Load More' : 'Nothing more to load'}
      </StyledButton>
    </>
  );
};

export default ActivityFeed;
