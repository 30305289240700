import React from 'react';
import {
  ICoverageRule,
  ICoverageRulesetPostNew,
  IRealPageIntegration,
  IRulesetError,
  IYardiIntegration
} from '../interfaces';
import useToast from '../toast/use-toast';
import CoverageRuleSwitchNew from './CoverageRuleSwitchNew';
import { StyledCoverageRulesBorder } from './styles/CoverageRuleSwitchStyles';
import { IInviteCriteria, ISetCustomRuleset } from './interface';
import RulesetCard from './RulesetCard';
import { Row, StyledEditLink } from './styles/CoverageRuleCardStyles';

export interface ICoverageCustomRuleSwitchNew {
  integration?: IRealPageIntegration | IYardiIntegration;
  showCustomRulesetForm: boolean;
  toggleCustomRulesetForm: (value: boolean) => void;
  coverageRules: (value: ICoverageRule[]) => void;
  addInviteCriteria: ({ screening, creditCheckStatus, userRenter }: IInviteCriteria) => void;
  customRulesets: ICoverageRulesetPostNew[];
  setCustomRulesets: (value: ICoverageRulesetPostNew[]) => void;
  deletedCustomRulesets: ICoverageRulesetPostNew[];
  setDeletedCustomRulesets: (value: ICoverageRulesetPostNew[]) => void;
  partnerRulesetError: IRulesetError;
  setPartnerRulesetsError: (value: IRulesetError) => void;
  toggleExpandStatus: (key: string, status: boolean) => void;
}

const CoverageCustomRuleSwitchNew = ({
  integration,
  showCustomRulesetForm,
  toggleCustomRulesetForm,
  coverageRules,
  addInviteCriteria,
  customRulesets,
  setCustomRulesets,
  deletedCustomRulesets,
  setDeletedCustomRulesets,
  partnerRulesetError,
  setPartnerRulesetsError,
  toggleExpandStatus
}: ICoverageCustomRuleSwitchNew) => {
  const { addToast } = useToast();

  const updateCustomRulesets = async ({ ruleset, index, closeEditRuleset }: ISetCustomRuleset) => {
    if (customRulesets && customRulesets.length > 0) {
      const propertyIds = ruleset.properties?.map((item) => item.id);
      const propertyExist = customRulesets.some((rule, i) => {
        if(i == index) return false;
        const customRulePropertiesIds = rule.properties?.map((p) => p.id);
        return propertyIds?.some(id => customRulePropertiesIds?.includes(id));
      });

      if (propertyExist) {
        addToast('You already have a custom ruleset with one of the provided properties', 'error');
        return;
      }
    }
    if (index !== undefined) {
      const newCustomRuleset = customRulesets.map((rule, i) =>
        i === index
          ? {
              ...rule,
              coverage_rules: ruleset.coverage_rules,
              properties: ruleset.properties,
              coverageable_ids: ruleset.properties?.map((item) => item.id.toString()) || [],
              name: ruleset.name,
              invite_criteria: {
                id: rule.invite_criteria?.id,
                allowed_credit_check_statuses: ruleset.invite_criteria?.allowed_credit_check_statuses ?? [],
                allowed_screening_results: ruleset.invite_criteria?.allowed_screening_results ?? [],
                use_renter_approval_date: ruleset.invite_criteria?.use_renter_approval_date ?? false
              }
            }
          : rule
      );
      setCustomRulesets(newCustomRuleset);
    } else {
      setCustomRulesets([...customRulesets, ruleset]);
      toggleCustomRulesetForm(false);
    }
    closeEditRuleset && closeEditRuleset();
  };

  const removeRuleset = (ruleset: ICoverageRulesetPostNew) => {
    const filter = customRulesets.filter((item) => item !== ruleset);
    ruleset.coverageable_ids = []
    setDeletedCustomRulesets([...deletedCustomRulesets, ruleset])
    setCustomRulesets(filter);
  };

  return (
    <>
      {customRulesets.length > 0 &&
        customRulesets.map((ruleset, index) => (
          <RulesetCard
            key={`ruleset-${index}`}
            index={index}
            ruleset={ruleset}
            newRulesets={customRulesets}
            coverageRules={coverageRules}
            addInviteCriteria={addInviteCriteria}
            integration={integration}
            removeRuleset={removeRuleset}
            updateCustomRulesets={updateCustomRulesets}
            partnerRulesetError={partnerRulesetError}
            setPartnerRulesetsError={setPartnerRulesetsError}
            toggleExpandStatus={toggleExpandStatus}
          />
        ))}

      {showCustomRulesetForm && (
        <StyledCoverageRulesBorder>
          <Row>
            <StyledEditLink onClick={() => {
              toggleCustomRulesetForm(false);
              toggleExpandStatus(`newCustomRuleset`, false);
            }}>Close</StyledEditLink>
          </Row>
          <CoverageRuleSwitchNew
            integration={integration?.type}
            label="Custom"
            addInviteCriteria={addInviteCriteria}
            coverageRules={coverageRules}
            customRulesets={customRulesets}
            setCustomRulesets={updateCustomRulesets}
            partnerRulesetError={partnerRulesetError}
            setPartnerRulesetsError={setPartnerRulesetsError}
            toggleExpandStatus={toggleExpandStatus}
            id="newCustomRuleset"
          />
        </StyledCoverageRulesBorder>
      )}
    </>
  );
};

export default CoverageCustomRuleSwitchNew;
