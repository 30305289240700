import { fetchPropertyOwners } from 'api/properties';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import React, { Component } from 'react';

interface IProps {
  prefix?: string;
  value?: any;
}

interface IState {
  selected?: any;
  isLoading: boolean;
}

class PropertyOwnerSelect extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false,
      selected: props.value
    };
  }

  public onChange = (selected) => {
    this.setState({ selected });
  };

  public render() {
    const { selected, isLoading } = this.state;
    let prefix = 'property';

    if (this.props.prefix) {
      prefix = this.props.prefix;
    }

    return (
      <div id={`${prefix}_owner_select`}>
        <AsyncSelect
          name={`${prefix}[property_owner_id]`}
          id={`${prefix}_property_owner_id`}
          getOptionLabel={(options) => options.name}
          getOptionValue={(options) => options.id}
          value={selected}
          isClearable={true}
          isLoading={isLoading}
          placeholder=""
          loadOptions={this.loadPropertyOwners}
          onChange={this.onChange}
        />
      </div>
    );
  }

  private loadPropertyOwners = (input: string, callback: Function) => {
    const complete = false;
    this.setState({ isLoading: true });

    fetchPropertyOwners(
      input,
      (response) => {
        callback(response.property_owners);
        this.setState({ isLoading: false });
      },
      (response) => {
        response.json().then((e) => {
          callback([]);
          this.setState({ isLoading: false });
        });
      }
    );
  };
}

export default PropertyOwnerSelect;
