import { IAccountDetails } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

const getAccountDetails = async (id: number) => {
  const { data } = await axios.get<IAccountDetails>(`/v2/users/accounts/${id}`);
  return data;
};

export default function useAccountDetails(id: number): QueryObserverResult<IAccountDetails> {
  return useQuery<IAccountDetails>(['account details', id], () => getAccountDetails(id));
}
