import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, PALETTE, MaskedInput } from '@sayrhino/rhino-shared-js';
import FormFieldBase from '../../../FormField';
import SwitchSelector from '../../../SwitchSelector';
import { parseIntDecimal } from '../../../utils';
import { TeamMemberType, NewTeamMemberFormValues } from './NewTeamMember';
import PropertiesMultiSelect from './PropertiesMultiSelect';
import MemberTypeTooltip from './MemberTypeTooltip';
import { validateEmail, validatePhone, validateRequiredField } from 'components/v2/App/utils/formValidations';

const SecondaryButton = styled(Button)({
  border: 'none',
  textDecoration: 'underline',
  '&:hover': {
    background: 'none',
    color: PALETTE.neutralDark
  }
});
const Form = styled.div({ paddingTop: '30px' });
const FormField = styled(FormFieldBase)({ marginBottom: '10px' });
const NameFormField = styled(FormField)({ marginTop: '30px' });
const ButtonGroup = styled.div({
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse' as const
});
const SwitchWrapper = styled.div({ display: 'flex', alignItems: 'center' });
export interface IProps {
  handleClick: (newTeamMember: NewTeamMemberFormValues) => void;
  handleDelete?: () => void;
  initialValues: NewTeamMemberFormValues;
  buttonText?: string;
}

const NewTeamMemberForm: React.FC<IProps> = ({ handleClick, handleDelete, initialValues, buttonText }) => {
  const [newTeamMember, updateNewTeamMember] = useState<NewTeamMemberFormValues>(initialValues);
  const [nameInputError, setNameInputError] = useState<string>();
  const [emailInputError, setEmailInputError] = useState<string>();
  const [phoneInputError, setPhoneInputError] = useState<string>();
  const [propertiesInputError, setPropertiesInputError] = useState<string>();
  const resetErrors = () => {
    setNameInputError(undefined);
    setEmailInputError(undefined);
    setPhoneInputError(undefined);
    setPropertiesInputError(undefined);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updateNewTeamMember({ ...newTeamMember, [event.target.name]: event.target.value });

  const handleTeamMemberType = (value: string) => {
    updateNewTeamMember({
      ...newTeamMember,
      type: value as TeamMemberType
    });
  };
  const handlePhoneNumberChange = (event) => {
    const phone = parseIntDecimal(event.target.value.replace(/\D/g, ''));
    updateNewTeamMember({ ...newTeamMember, phone });
  };

  const handlePropertyChange = (selectedValues) => {
    updateNewTeamMember({ ...newTeamMember, properties: selectedValues });
  };

  const validateForm = () => {
    let isValid = true;
    const nameValidation = validateRequiredField(newTeamMember.name, 'Name');
    if (nameValidation) {
      setNameInputError(nameValidation);
      isValid = false;
    }
    const propertiesValidation = validateRequiredField(newTeamMember.properties, 'Properties');
    if (propertiesValidation) {
      setPropertiesInputError(propertiesValidation);
      isValid = false;
    }

    const emailValidation = validateEmail(newTeamMember.email);
    if (emailValidation) {
      setEmailInputError(emailValidation);
      isValid = false;
    }

    const phoneValidation = validatePhone(newTeamMember.phone);
    if (phoneValidation) {
      setPhoneInputError(phoneValidation);
      isValid = false;
    }
    return isValid;
  };

  const handleAddTeamMember = () => {
    resetErrors();

    if (validateForm()) {
      handleClick(newTeamMember);
    }
  };

  return (
    <Form>
      <SwitchWrapper>
        <SwitchSelector
          active={newTeamMember.type}
          list={[
            { label: 'Property manager admin', value: TeamMemberType.PROPERTY_MANAGER_ADMIN },
            { label: 'Property manager', value: TeamMemberType.PROPERTY_MANAGER },
            { label: 'Leasing agent', value: TeamMemberType.LISTING_AGENT }
          ]}
          id="partner_self_serve"
          onClick={handleTeamMemberType}
        />
        <MemberTypeTooltip />
      </SwitchWrapper>
      <NameFormField
        error={nameInputError}
        input={<input name="name" placeholder="Name" id="name" value={newTeamMember.name} onChange={handleChange} />}
        label={<label htmlFor="name">Name</label>}
      />
      <FormField
        error={emailInputError}
        input={
          <input name="email" placeholder="Email" id="email" value={newTeamMember.email} onChange={handleChange} />
        }
        label={<label htmlFor="email">Email</label>}
      />
      <FormField
        error={phoneInputError}
        input={
          <MaskedInput
            mask="(999) 999-9999"
            onChange={handlePhoneNumberChange}
            placeholder="Phone number"
            value={newTeamMember.phone ?? ''}
            maskChar=""
            id="phone"
          />
        }
        label={<label htmlFor="phone">Phone Number</label>}
      />

      <PropertiesMultiSelect
        error={propertiesInputError}
        properties={newTeamMember.properties}
        handleChange={handlePropertyChange}
      />

      <ButtonGroup>
        <Button variant="tertiary" onClick={handleAddTeamMember}>
          {buttonText ?? 'Add'}
        </Button>
        {handleDelete && (
          <SecondaryButton variant="tertiary" onClick={handleDelete}>
            Delete
          </SecondaryButton>
        )}
      </ButtonGroup>
    </Form>
  );
};

export default NewTeamMemberForm;
