import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { FONT_FAMILY, PALETTE, Switch } from "@sayrhino/rhino-shared-js";
import styled from '@emotion/styled';
import Spacer from "./SpacingTokens";
import Label from 'components/common/formsy/Label';
import { CoverageTypeEnum, ICoverageRule } from '../interfaces';
import { CoverageRuleSwitch } from './CoverageRuleSwitch';

const StyledLabel = styled(Label)(FONT_FAMILY.extendedMedium, { color: PALETTE.neutral88, lineHeight: '24px' });
const SameCashToggleContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
});

interface ICoverageRuleProps {
  coverageType: CoverageTypeEnum;
  coverageCashType: CoverageTypeEnum;
  coverageValue: number;
  coverageCashValue: number;
  coverageError: boolean,
  coverageCashError: boolean,
  coverageDollarError: string;
  coverageCashDollarError: string;
  coverageSameCashDeposit: boolean;
  setCoverageCashType: Function,
  setCoverageValue: any;
  setCoverageCashValue: any;
  setCoverageSameCashDeposit: any;
  convertCoverageToCents: (e: ChangeEvent<HTMLInputElement>) => void;
  convertCoverageCashToCents: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface IProps {
  coverageRule: ICoverageRuleProps;
  coverageTitle?: string
  coverageLabel: string;
  coverageCashLabel: string;
  coverageId: string;
  index?: number;
  handleSwitchSelect:  (value: CoverageTypeEnum) => void;
  handleCashSwitchSelect: (value: CoverageTypeEnum) => void;
}

const CoverageRuleForm: React.FC<IProps> = ({
  coverageRule,
  coverageTitle,
  coverageLabel,
  coverageCashLabel,
  coverageId,
  index,
  handleSwitchSelect,
  handleCashSwitchSelect,
}) => {
  const ruleId = index ? `${index}-default-coverage-${coverageId}` : `${coverageId}-default-coverage`;
  const cashRuleId = index ? `${index}-default-cash-${coverageId}` : `${coverageId}-default-cash`;
  const sameAmountLabelId = index ? `${index}-same-cash-amount-${coverageId}` : `same-cash-amount-${coverageId}`;
  const sameAmountSwitchId = index ?
    `${index}-toggle-same-cash-amount-${coverageId}` : `toggle-same-cash-amount-${coverageId}`;

  return (
    <div>
      <Spacer amount={2} />
      { coverageTitle && (
        <>
          <StyledLabel>{coverageTitle}</StyledLabel>
          <Spacer amount={1} />
        </>
      )}
      <CoverageRuleSwitch
        coverageType={coverageRule.coverageType}
        value={coverageRule.coverageValue}
        handleCoverageDollarValue={coverageRule.convertCoverageToCents}
        onSelect={handleSwitchSelect}
        setCoverageValue={(value) => coverageRule.setCoverageValue(value)}
        coverageDollarError={coverageRule.coverageDollarError}
        label={coverageLabel}
        id={ruleId}
      />

      {coverageRule.coverageType !== 'no_coverage' &&
        <>
          <SameCashToggleContainer>
            <label id={sameAmountLabelId}>Same cash amount?</label>
            <Switch
              id={sameAmountSwitchId}
              aria-labelledby={sameAmountLabelId}
              checked={coverageRule.coverageSameCashDeposit}
              onCheckedChange={() =>
                coverageRule.setCoverageSameCashDeposit((prev: boolean) => !prev)
              }
            ></Switch>
          </SameCashToggleContainer>

          <Spacer amount={3} />

          {!coverageRule.coverageSameCashDeposit &&
            <>
              <CoverageRuleSwitch
                coverageType={coverageRule.coverageCashType}
                value={coverageRule.coverageCashValue}
                handleCoverageDollarValue={
                  (e) => coverageRule.convertCoverageCashToCents(e)
                }
                onSelect={handleCashSwitchSelect}
                setCoverageValue={coverageRule.setCoverageCashValue}
                coverageDollarError={coverageRule.coverageCashDollarError}
                label={coverageCashLabel}
                includeNoCoverage={false}
                id={cashRuleId}
              />
              <Spacer amount={3} />
            </>
          }
        </>
      }
    </div>
  );
}

export default CoverageRuleForm;