import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

const getMissingRentersInsuranceRequirements = async () => {
  const { data } = await axios.get<number>(`/v2/admin/missing_renters_insurance_requirements.json`);
  return data;
};

export default function usePMAMissingRentersInsuranceRequirements(): QueryObserverResult<number> {
  return useQuery<number>(['missing renterts insurance requirements'], () => getMissingRentersInsuranceRequirements());
}
