import React, { createContext, useContext, useState } from 'react';
import { CloseIcon } from '@sayrhino/rhino-shared-js';

import Form from './propertyForm';
import { SegmentUser } from '../../utils/segmentTracker';
import CompleteNotification from '../inviteProperties/completeNotification';
import InviteNotification from '../inviteProperties/inviteNotification';
import { Close, Header, LargeHeader, Page } from './components';
import { Response } from './queryHooks';

export const UserContext = createContext<{ user: SegmentUser }>({
  user: {} as SegmentUser
});

export const getUserDetails = () => {
  return useContext(UserContext);
};

function SinglePropertyPage({ user }: { user: SegmentUser }) {
  const [response, setResponse] = useState<Response>();
  const [completeDone, setCompleteDone] = useState<boolean>();
  if (response) {
    return completeDone ? (
      <InviteNotification property={response} />
    ) : (
      <CompleteNotification setComplete={(complete) => setCompleteDone(complete)} />
    );
  }
  return (
    <UserContext.Provider value={{ user }}>
      <Page>
        <Header>
          <LargeHeader>Add New Property</LargeHeader>
          <Close href="/admin/properties?table=true">
            <CloseIcon width="15px" height="15px" />
          </Close>
        </Header>
        <Form setResponse={(res) => setResponse(res)} />
      </Page>
    </UserContext.Provider>
  );
}

export default SinglePropertyPage;
