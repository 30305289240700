/** @jsx jsx */
import Pluralize from 'react-pluralize';
import { css, jsx } from '@emotion/core';
import { LegacyTag, Card, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { IPropertyUpdate } from './interfaces';

export const convertPropertyUpdateToCardProps = (update: IPropertyUpdate) => {
  const { attributes } = update;
  return {
    propertyId: attributes.id,
    buildingName: attributes.building_name,
    ownerName: attributes.owner_name,
    unitCount: attributes.unit_count,
    addressState: attributes.address_state,
    addressCity: attributes.address_city,
    active: attributes.active
  };
};

interface IProps {
  propertyId: number;
  buildingName: string;
  ownerName: string;
  unitCount: number;
  addressState: string;
  addressCity: string;
  active: boolean;
}

// we need to refactor away from absolute positioned styles
// adding back in relative position bc it is a requirement for absolute
// positioned elements in styles
const cardCSS = {
  height: 'fit-content',
  padding: '20px 32px',
  position: 'relative'
};

const sectionCSS = css({
  margin: '0 16px',
  marginTop: '12px',
  display: 'flex'
});

const addressCityCSS = css({
  marginLeft: '12px',
  maxWidth: '70%'
});

export const PropertyCard: React.FC<IProps> = ({
  propertyId,
  buildingName,
  ownerName,
  unitCount,
  addressState,
  addressCity,
  active
}) => {
  return (
    <Card style={cardCSS}>
      <div css={{ display: 'flex', justifyContent: 'space-between', margin: '0 16px' }}>
        <h4 css={FONTS.h4}>{buildingName}</h4>
        <h4 css={[FONTS.h4]}>{propertyId}</h4>
      </div>
      <div css={[{ paddingBottom: '12px', justifyContent: 'space-between' }, sectionCSS]}>
        <p css={[FONTS.p2, PALETTE.neutral25]}>{ownerName}</p>
        <p css={[FONTS.p2, PALETTE.neutral25]}>
          <Pluralize singular="Unit" count={unitCount} />
        </p>
      </div>
      <div css={[sectionCSS, { justifyContent: 'space-between' }]}>
        <div css={{ display: 'flex' }}>
          <LegacyTag variant="disabled">
            <span css={[FONTS.h6Demi, { color: PALETTE.neutral65 }]}>{addressState}</span>
          </LegacyTag>
          <p css={[FONTS.p2, addressCityCSS]}>{addressCity}</p>
        </div>
        <LegacyTag>{active ? 'Active' : 'Inactive'}</LegacyTag>
      </div>
    </Card>
  );
};

export default PropertyCard;
