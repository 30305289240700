import axios from 'axios';
import { csrfToken } from 'utils/document';
import { IAccountDetailsEditPOST } from 'components/v2/App/interfaces';

const mapToUpdateAccountDetails = (user: IAccountDetailsEditPOST) => {
  const { id, first_name, last_name, email, current_password, password, password_confirmation } = user;
  return {
    id,
    first_name,
    last_name,
    email,
    ...(current_password !== '' && { current_password }),
    ...(password !== '' && { password }),
    ...(password_confirmation !== '' && { password_confirmation })
  };
};

const updateAccountDetails = async (user: IAccountDetailsEditPOST) => {
  const authenticity_token = csrfToken();
  const renter_user = mapToUpdateAccountDetails(user);

  const { data } = await axios.put<IAccountDetailsEditPOST>(
    `/v2/users/account`,
    { user: renter_user, authenticity_token },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return data;
};

export default updateAccountDetails;
