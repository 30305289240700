/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { LegacyTag, Card, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { getTagVariant } from './utils';
import InvitationCoverageType from './InvitationCoverageType';
import { IInvitationUpdate, IInvitationStatus } from 'components/v2/App/interfaces';

interface IProps {
  addressState: string;
  addressUnit: string;
  buildingName: string;
  coverageAmount: string;
  coverageDates: string;
  coverageMonths: string;
  guarantorCoverageEnabled: boolean;
  monthlyRent: string;
  requiredCoverage: number;
  status: IInvitationStatus;
  tenantEmail: string;
  tenantFullName: string;
  styles?: any;
  cashDepositAmount: string;
  cashDepositOnly: boolean;
}

export const convertInvitationUpdateToCardProps = (update: IInvitationUpdate) => {
  const { attributes } = update;

  return {
    addressState: attributes.address_state,
    addressUnit: attributes.address_unit,
    buildingName: attributes.building_name,
    cashDepositAmount: attributes.cash_deposit_amount,
    cashDepositOnly: attributes.cash_deposit_only,
    coverageAmount: attributes.coverage_amount,
    coverageDates: attributes.coverage_dates,
    coverageMonths: attributes.coverage_months,
    guarantorCoverageEnabled: attributes.guarantor_coverage,
    monthlyRent: attributes.monthly_rent,
    requiredCoverage: attributes.required_coverage,
    status: attributes.status,
    tenantEmail: attributes.tenant_email,
    tenantFullName: attributes.tenant_full_name
  };
};

const cardStyles = {
  padding: '20px 16px',
  height: 'auto',
  width: 'auto'
};

const sectionCSS = css({
  margin: '0 16px',
  marginTop: '12px',
  display: 'flex'
});

const propertyCSS = css({
  maxWidth: '245px'
});

export const InvitationCard = (props: IProps) => {
  const {
    addressState,
    addressUnit,
    buildingName,
    cashDepositAmount,
    cashDepositOnly,
    coverageAmount,
    coverageDates,
    coverageMonths,
    guarantorCoverageEnabled,
    monthlyRent,
    requiredCoverage,
    status,
    tenantEmail,
    tenantFullName,
    ...rest
  } = props;

  const unit = addressUnit ? (
    <h4 css={FONTS.h4}>{addressUnit}</h4>
  ) : (
    <h4 css={[FONTS.h4, { color: PALETTE.neutral12 }]}>-</h4>
  );

  const building = buildingName.length < 70 ? buildingName : buildingName.slice(0, 64) + '...';

  return (
    <Card style={cardStyles} {...rest}>
      <div css={{ display: 'flex', justifyContent: 'space-between', margin: '0 16px' }}>
        <h4 css={FONTS.h4}>{tenantFullName === ' ' ? tenantEmail : tenantFullName}</h4>
        {unit}
      </div>
      <div css={[sectionCSS]}>
        <InvitationCoverageType
          coverageAmount={coverageAmount}
          coverageDates={coverageDates}
          coverageMonths={coverageMonths}
          monthlyRent={monthlyRent}
          requiredCoverage={requiredCoverage}
          status={status}
          cashDepositAmount={cashDepositAmount}
          cashDepositOnly={cashDepositOnly}
        />
      </div>
      <div css={[sectionCSS, { justifyContent: 'space-between' }]}>
        <div css={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <LegacyTag variant="disabled">
            <span css={[FONTS.h6Demi, { color: PALETTE.neutral65 }]}>{addressState}</span>
          </LegacyTag>
          <p css={[FONTS.p2, propertyCSS]}>{building}</p>
        </div>
        <div css={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {guarantorCoverageEnabled ? <LegacyTag>Guarantor</LegacyTag> : null}
          <LegacyTag variant={getTagVariant(status)}>{status}</LegacyTag>
        </div>
      </div>
    </Card>
  );
};

export default InvitationCard;
