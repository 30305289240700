export const tasks = {
  data: [
    {
      type: 'InsurancePolicy',
      id: 17434,
      attributes: {
        tenant_full_name: 'John_42611 Doe_42611',
        coverage_amount: '$25,770',
        coverage_dates: 'May 02, 2020 - Sep 30, 2020',
        property_name: 'Enclave at the Cathedral',
        property_state: 'NY',
        property_street_address: '400 W 113th St, New York, NY, 10025',
        status: 'pending',
        unit_name: null
      }
    },
    {
      type: 'InsurancePolicy',
      id: 17435,
      attributes: {
        tenant_full_name: 'John_42611 Doe_42611',
        coverage_amount: '$25,770',
        coverage_dates: 'May 02, 2020 - Sep 30, 2020',
        property_name: 'Enclave at the Cathedral',
        property_state: 'NY',
        property_street_address: '400 W 113th St, New York, NY, 10025',
        status: 'pending',
        unit_name: null
      }
    },
    {
      type: 'InsurancePolicy',
      id: 17436,
      attributes: {
        tenant_full_name: 'John_42611 Doe_42611',
        coverage_amount: '$25,770',
        coverage_dates: 'May 02, 2020 - Sep 30, 2020',
        property_name: 'Enclave at the Cathedral',
        property_state: 'NY',
        property_street_address: '400 W 113th St, New York, NY, 10025',
        status: 'pending',
        unit_name: null
      }
    },
    {
      type: 'InsurancePolicy',
      id: 17437,
      attributes: {
        tenant_full_name: 'John_42611 Doe_42611',
        coverage_amount: '$25,770',
        coverage_dates: 'May 02, 2020 - Sep 30, 2020',
        property_name: 'Enclave at the Cathedral',
        property_state: 'NY',
        property_street_address: '400 W 113th St, New York, NY, 10025',
        status: 'pending',
        unit_name: null
      }
    },
    {
      type: 'InsurancePolicy',
      id: 17438,
      attributes: {
        tenant_full_name: 'John_42611 Doe_42611',
        coverage_amount: '$25,770',
        coverage_dates: 'May 02, 2020 - Sep 30, 2020',
        property_name: 'Enclave at the Cathedral',
        property_state: 'NY',
        property_street_address: '400 W 113th St, New York, NY, 10025',
        status: 'pending',
        unit_name: null
      }
    }
  ],
  meta: { total_count: 21, next_page: 2, page: '1', per: 20 }
};

