import { ArrowDown, ButtonHabitat, DropdownMenu, Switch, TextInput } from '@sayrhino/rhino-shared-js';
import React, { ChangeEvent, useState } from 'react';
import { ErrorText } from '../Invitation/Styles';
import useToast from '../toast/use-toast';
import { sanitizeCurrencyInput, centsToUSDFormatter } from '../utils';
import { COVERAGE_MULTIPLIER_OPTIONS, MULTIPLIER_OPTIONS_FOR_UNIT_PROPERTY } from './CoverageOptions';
import { StyledDropdownMenuItem, StyledDropdownMenuTrigger } from './CreatePMSIntegrationSetup';
import { IAddCoverageRule } from './interface';
import { StyledLabel, StyledSaveButtonWrapper, ToggleContainer } from './styles/AddCoverageRuleStyles';
import { Row, StyledEditLink } from './styles/CoverageRuleCardStyles';
import { StyledDropDownContainer } from './styles/CoverageRuleSwitchStyles';
import {
  AddCoverageTypeEnum,
  CoverageInputEnum,
  CashCoverageInputEnum,
  realpageCoverageTypes,
  yardiCoverageTypes,
  yardiRenterTypes,
  realpageRenterTypes
} from './util/rulesetConstant';
import { Decimal } from 'decimal.js';

interface IInput {
  label: string;
  value: string;
}

const AddCoverageRule = ({
  id,
  getUnusedRenterTypes,
  integration,
  saveCoverageRuleList,
  setShowCoverageRule,
  showEditCoverage,
  editCoverage,
  coverageRuleList,
  toggleExpandStatus
}: IAddCoverageRule) => {
  const coverageTypes = integration === 'realpage' ? realpageCoverageTypes : yardiCoverageTypes;
  const renterTypes = integration === 'yardi' ? yardiRenterTypes : realpageRenterTypes;

  const coverageMultiplier = (value: number | undefined) => COVERAGE_MULTIPLIER_OPTIONS.find(
    (multipler) => Number(multipler.value) === Number(value)
  );

  const unitPropertyMultiplier = (value: number | undefined) => MULTIPLIER_OPTIONS_FOR_UNIT_PROPERTY.find(
    (multipler) => Number(multipler.value) === Number(value)
  );

  const isDollarAmount = (coverageTypeVariable: string | undefined) =>
    coverageTypeVariable === AddCoverageTypeEnum.DOLLAR_AMOUNT;

  const isMultiplier = (coverageTypeVariable: string | undefined) =>
    coverageTypeVariable === AddCoverageTypeEnum.MONTHLY_RENT;

  const isUnitPropertyMultiplier = (coverageTypeVariable: string | undefined) =>
    coverageTypeVariable === AddCoverageTypeEnum.UNIT_PROPERTY;

  const initialSameCashDeposit = editCoverage?.same_cash_deposit === false ? false : true;

  const initialDollarAmount = (coverageTypeVariable?: string, coverageValueVariable?: number) =>
    isDollarAmount(coverageTypeVariable) ? coverageValueVariable : undefined;

  const initialMultiplierCoverage = (coverageTypeVariable?: string, coverageValueVariable?: number) =>
    isMultiplier(coverageTypeVariable) ? coverageMultiplier(coverageValueVariable) : undefined;

  const initialUnitPropertyMultiplierCoverage = (coverageTypeVariable?: string, coverageValueVariable?: number) =>
    isUnitPropertyMultiplier(coverageTypeVariable) ? unitPropertyMultiplier(coverageValueVariable) : undefined;

  const renterTypeOptions = editCoverage?.coverage_option_name ? renterTypes : getUnusedRenterTypes();
  const [renterType, setRenterType] = useState<string | undefined>(editCoverage?.coverage_option_name);
  const [coverageType, setCoverageType] = useState<string | undefined>(editCoverage?.coverage_type);
  const [cashCoverageType, setCashCoverageType] = useState<string | undefined>(
    !initialSameCashDeposit ? editCoverage?.cash_deposit_type : undefined
  );
  const [multiplierCoverage, setMultiplierCoverage] = useState<IInput | undefined>(
    initialMultiplierCoverage(editCoverage?.coverage_type, editCoverage?.coverage_value)
  );
  const [unitPropertyMultiplierCoverage, setUnitPropertyMultiplierCoverage] = useState<IInput | undefined>(
    initialUnitPropertyMultiplierCoverage(editCoverage?.coverage_type, editCoverage?.coverage_value)
  );
  const [dollarAmountInCents, setDollarAmountInCents] = useState<number | undefined>(
    initialDollarAmount(editCoverage?.coverage_type, editCoverage?.coverage_value)
  );

  const [sameCashAmount, setSameCashAmount] = useState<boolean>(initialSameCashDeposit);
  const [cashMultiplierCoverage, setCashMultiplierCoverage] = useState<IInput | undefined>(
    // if same cash deposit starts out true while editing, we want these values to be empty upon toggle press
    !initialSameCashDeposit ? initialMultiplierCoverage(
      editCoverage?.cash_deposit_type,
      editCoverage?.cash_deposit_coverage_value
    ) : undefined
  );
  const [cashUnitPropertyMultiplierCoverage, setCashUnitPropertyMultiplierCoverage] = useState<IInput | undefined>(
    !initialSameCashDeposit ? initialUnitPropertyMultiplierCoverage(
      editCoverage?.cash_deposit_type,
      editCoverage?.cash_deposit_coverage_value
    ) : undefined
  );
  const [cashDollarAmountInCents, setCashDollarAmountInCents] = useState<number | undefined>(
    !initialSameCashDeposit ?
      initialDollarAmount(editCoverage?.cash_deposit_type, editCoverage?.cash_deposit_coverage_value) :
      undefined
  );

  const [errors, setErrors] = useState<IInput[]>([]);
  const { addToast } = useToast();

  const addCoverage = () => {
    const checkError = inputErrorHandler();
    if (checkError.length > 0) {
      setErrors(checkError);
      return;
    }

    if (checkRenterType()) {
      setErrors([
        ...errors,
        { label: CoverageInputEnum.RENTER_TYPE, value: 'Renter type already exist in coverage amount list' }
      ]);
    }

    checkCoverageRuleList();

    setCoverageType(undefined);
    setCashCoverageType(undefined);
    setRenterType(undefined);
    setDollarAmountInCents(undefined);
    setCashDollarAmountInCents(undefined);
    showEditCoverage && showEditCoverage(false);
    toggleExpandStatus(id, false);
  };

  const checkCoverageRuleList = () => {
    const coverageValue = convertCoverageValue(
      coverageType,
      multiplierCoverage,
      unitPropertyMultiplierCoverage,
      dollarAmountInCents
    );
    const cashDepositCoverageValue = convertCoverageValue(
      cashCoverageType,
      cashMultiplierCoverage,
      cashUnitPropertyMultiplierCoverage,
      cashDollarAmountInCents
    );

    const cashAttributes = () => sameCashAmount ? {
      cash_deposit_type: coverageType!,
      cash_deposit_coverage_value: coverageValue
    } : {
      cash_deposit_type: cashCoverageType!,
      cash_deposit_coverage_value: cashDepositCoverageValue
    }

    if (editCoverage !== undefined) {
      let index = coverageRuleList.indexOf(editCoverage);

      const renterTypeExists = coverageRuleList.some(
        (rule, i) => rule.coverage_option_name === renterType && i !== index
      );

      if (renterTypeExists) {
        addToast(`${renterType} already exists in coverage amount`, 'error');
        return;
      }

      saveCoverageRuleList(
        coverageRuleList.map((obj, i) => {
          if (i === index) {
            return {
              ...obj,
              coverage_option_name: renterType!,
              coverage_type: coverageType!,
              coverage_value: coverageValue,
              same_cash_deposit: sameCashAmount,
              ...cashAttributes()
            };
          }
          return obj;
        })
      );
      return;
    }
    saveCoverageRuleList([
      ...coverageRuleList,
      {
        coverage_option_name: renterType!,
        coverage_type: coverageType!,
        coverage_value: coverageValue,
        same_cash_deposit: sameCashAmount,
        ...cashAttributes()
      }
    ]);
  };

  const convertCoverageValue = (
    coverageTypeVariable: string | undefined,
    multiplierVariable: IInput | undefined,
    unitPropertyVariable: IInput | undefined,
    dollarAmountVariable: number | undefined
  ) => {
    if (isMultiplier(coverageTypeVariable)) {
      return Number(multiplierVariable?.value!);
    } else if (isUnitPropertyMultiplier(coverageTypeVariable)) {
      return Number(unitPropertyVariable?.value!);
    } else if (isDollarAmount(coverageTypeVariable)) {
      return Number(dollarAmountVariable!);
    } else {
      return 0;
    }
  };

  const checkRenterType = () => {
    if (coverageRuleList.length > 0) {
      const renterTypeCoverageRules = coverageRuleList.filter((rule) => rule.coverage_option_name === renterType);
      return renterTypeCoverageRules.length > 1;
    }
  };

  const inputError = (label: string) => {
    const hasError = errors.find((error) => error.label === label);
    return hasError;
  };

  const removeError = (label: string) => {
    const removeErr = errors.filter((error) => error.label !== label);
    setErrors(removeErr);
  };

  const inputErrorHandler = () => {
    let inputError: IInput[] = [];
    if (!renterType) {
      inputError = [...inputError, { label: CoverageInputEnum.RENTER_TYPE, value: 'Renter type is required' }];
    }

    if (!coverageType) {
      inputError = [...inputError, { label: CoverageInputEnum.COVERAGE_TYPE, value: 'Coverage type is required' }];
    }

    if (!multiplierCoverage && isMultiplier(coverageType)) {
      inputError = [...inputError, { label: CoverageInputEnum.MULTIPLIER, value: 'Monthly multiplier is required' }];
    }

    if (!unitPropertyMultiplierCoverage && isUnitPropertyMultiplier(coverageType)) {
      inputError = [...inputError, { label: CoverageInputEnum.UNIT_PROPERTY, value: 'Multiplier is required' }];
    }

    if (!dollarAmountInCents && isDollarAmount(coverageType)) {
      inputError = [...inputError, { label: CoverageInputEnum.DOLLAR_AMOUNT, value: 'Dollar amount is required' }];
    }

    if (!sameCashAmount) {
      if(!cashCoverageType) {
        inputError =
          [...inputError, { label: CashCoverageInputEnum.COVERAGE_TYPE, value: 'Coverage type is required' }];
      }

      if(!cashMultiplierCoverage && isMultiplier(cashCoverageType)) {
        inputError =
          [...inputError, { label: CashCoverageInputEnum.MULTIPLIER, value: 'Monthly multiplier is required' }];
      }

      if(!cashUnitPropertyMultiplierCoverage && isUnitPropertyMultiplier(cashCoverageType)) {
        inputError = [...inputError, { label: CashCoverageInputEnum.UNIT_PROPERTY, value: 'Multiplier is required' }];
      }

      if(!cashDollarAmountInCents && isDollarAmount(cashCoverageType)) {
        inputError =
          [...inputError, { label: CashCoverageInputEnum.DOLLAR_AMOUNT, value: 'Dollar amount is required' }];
      }
    }

    return inputError;
  };

  const handleRenterType = (type: string) => {
    removeError(CoverageInputEnum.RENTER_TYPE);
    setRenterType(type);
  };

  const handleCoverageType = (item: string) => {
    removeError(CoverageInputEnum.COVERAGE_TYPE);
    setCoverageType(item);
    if (item === AddCoverageTypeEnum.UNIT_PROPERTY) {
      setUnitPropertyMultiplierCoverage({ label: '1X', value: '1.0' });
    }
  };

  const handleCashCoverageType = (item: string) => {
    removeError(CashCoverageInputEnum.COVERAGE_TYPE);
    setCashCoverageType(item); 
    if (item === AddCoverageTypeEnum.UNIT_PROPERTY) {
      setCashUnitPropertyMultiplierCoverage({ label: '1X', value: '1.0' });
    }
  };

  const handleMultiplier = (multiplier: IInput) => {
    removeError(CoverageInputEnum.MULTIPLIER);
    setMultiplierCoverage(multiplier);
  };

  const handleCashMultiplier = (multiplier: IInput) => {
    removeError(CashCoverageInputEnum.MULTIPLIER);
    setCashMultiplierCoverage(multiplier);
  };

  const handleUnitPropertyMultiplier = (multiplier: IInput) => {
    removeError(CoverageInputEnum.UNIT_PROPERTY);
    setUnitPropertyMultiplierCoverage(multiplier);
  };

  const handleCashUnitPropertyMultiplier = (multiplier: IInput) => {
    removeError(CashCoverageInputEnum.UNIT_PROPERTY);
    setCashUnitPropertyMultiplierCoverage(multiplier);
  };

  const handleDollarAmount = (e: ChangeEvent<HTMLInputElement>) => {
    removeError(CoverageInputEnum.DOLLAR_AMOUNT);
    let allCreditStatusAmountCents = sanitizeCurrencyInput(e);
    setDollarAmountInCents(allCreditStatusAmountCents);
  };

  const handleCashDollarAmount = (e: ChangeEvent<HTMLInputElement>) => {
    removeError(CashCoverageInputEnum.DOLLAR_AMOUNT);
    let sanitizedCentsInput = sanitizeCurrencyInput(e);
    setCashDollarAmountInCents(sanitizedCentsInput);
  };

  const closeCoverageRuleModal = () => {
    toggleExpandStatus(id, false);
    setShowCoverageRule && setShowCoverageRule(false);
  };

  return (
    <>
      {!showEditCoverage && (
        <Row>
          <StyledEditLink onClick={closeCoverageRuleModal}>Close</StyledEditLink>
        </Row>
      )}

      <StyledDropDownContainer>
        <StyledLabel>Renter Type</StyledLabel>
        <DropdownMenu.Root>
          <StyledDropdownMenuTrigger>
            {renterType ?? 'Select a renter type'}
            <ArrowDown width="24px" height="24px" />
          </StyledDropdownMenuTrigger>
          <DropdownMenu.Content>
            {renterTypeOptions.map((type: string, index: number) => (
              <StyledDropdownMenuItem onSelect={() => handleRenterType(type)} key={`renter-type-${index}`}>
                {type}
              </StyledDropdownMenuItem>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        {inputError(CoverageInputEnum.RENTER_TYPE)?.label && (
          <ErrorText>{inputError(CoverageInputEnum.RENTER_TYPE)!.value}</ErrorText>
        )}
      </StyledDropDownContainer>

      <StyledDropDownContainer>
        <StyledLabel>Coverage Type</StyledLabel>
        <DropdownMenu.Root>
          <StyledDropdownMenuTrigger aria-label="Select coverage type">
            {coverageType ?? 'Select a coverage type'}
            <ArrowDown width="24px" height="24px" />
          </StyledDropdownMenuTrigger>
          <DropdownMenu.Content>
            {coverageTypes.map((item: string, index: number) => (
              <StyledDropdownMenuItem onSelect={() => handleCoverageType(item)} key={`coverage-type-${index}`}>
                {item}
              </StyledDropdownMenuItem>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        {inputError(CoverageInputEnum.COVERAGE_TYPE) && (
          <ErrorText>{inputError(CoverageInputEnum.COVERAGE_TYPE)!.value}</ErrorText>
        )}
      </StyledDropDownContainer>

      {isMultiplier(coverageType) && (
        <>
          <StyledLabel>Monthly rent multiplier</StyledLabel>
          <StyledDropDownContainer>
            <DropdownMenu.Root>
              <StyledDropdownMenuTrigger aria-label="Multiplier">
                {multiplierCoverage ? multiplierCoverage.value + "X" : 'Select a coverage type'}
                <ArrowDown width="24px" height="24px" />
              </StyledDropdownMenuTrigger>
              <DropdownMenu.Content>
                {COVERAGE_MULTIPLIER_OPTIONS.map((multiplier) => (
                  <StyledDropdownMenuItem key={multiplier.value} onSelect={() => handleMultiplier(multiplier)}>
                    {multiplier.label}
                  </StyledDropdownMenuItem>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            {inputError(CoverageInputEnum.MULTIPLIER) && (
              <ErrorText>{inputError(CoverageInputEnum.MULTIPLIER)!.value}</ErrorText>
            )}
          </StyledDropDownContainer>
        </>
      )}

      {isUnitPropertyMultiplier(coverageType) && (
        <>
          <StyledLabel>Unit/Property Multiplier</StyledLabel>
          <StyledDropDownContainer>
            <DropdownMenu.Root>
              <StyledDropdownMenuTrigger aria-label="Multiplier">
                {unitPropertyMultiplierCoverage ?
                  unitPropertyMultiplierCoverage.value + "X" :
                  'Select a coverage type'
                }
                <ArrowDown width="24px" height="24px" />
              </StyledDropdownMenuTrigger>
              <DropdownMenu.Content>
                {MULTIPLIER_OPTIONS_FOR_UNIT_PROPERTY.map((multiplier) => (
                  <StyledDropdownMenuItem
                    key={`${multiplier.value}-unit-property`}
                    onSelect={() => handleUnitPropertyMultiplier(multiplier)}
                  >
                    {multiplier.label}
                  </StyledDropdownMenuItem>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            {inputError(CoverageInputEnum.UNIT_PROPERTY) && (
              <ErrorText>{inputError(CoverageInputEnum.UNIT_PROPERTY)!.value}</ErrorText>
            )}
          </StyledDropDownContainer>
        </>
      )}

      {coverageType === AddCoverageTypeEnum.DOLLAR_AMOUNT && (
        <TextInput
          id={coverageType.replace(/ /g, '-')}
          label="Dollar amount"
          value={dollarAmountInCents ? centsToUSDFormatter(new Decimal(dollarAmountInCents)) : ''}
          onChange={handleDollarAmount}
          placeholder={'e.g. $1,500'}
          error={errors.length > 0 && inputError(CoverageInputEnum.DOLLAR_AMOUNT) ? true : false}
          subtext={
            errors.length > 0 &&
            inputError(CoverageInputEnum.DOLLAR_AMOUNT) && (
              <ErrorText> {inputError(CoverageInputEnum.DOLLAR_AMOUNT)!.value}</ErrorText>
            )
          }
          maxLength={11}
        />
      )}

      <ToggleContainer>
        <label id="same-cash-amount">Same cash amount?</label>
        <Switch
          id="toggle-same-cash-amount"
          aria-labelledby="same-cash-amount"
          checked={sameCashAmount}
          onCheckedChange={() => setSameCashAmount((prev) => !prev)}
        ></Switch>
      </ToggleContainer>

      {!sameCashAmount && (
        <>
          <StyledDropDownContainer>
            <StyledLabel>Cash Type</StyledLabel>
            <DropdownMenu.Root>
              <StyledDropdownMenuTrigger aria-label="Select cash coverage type">
                {cashCoverageType ?? 'Select a coverage type'}
                <ArrowDown width="24px" height="24px" />
              </StyledDropdownMenuTrigger>
              <DropdownMenu.Content>
                {coverageTypes.map((type: string, index: number) => (
                  <StyledDropdownMenuItem
                    onSelect={() => handleCashCoverageType(type)}
                    key={`cash-coverage-type-${index}`}
                  >
                    {type}
                  </StyledDropdownMenuItem>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            {inputError(CashCoverageInputEnum.COVERAGE_TYPE) && (
              <ErrorText>{inputError(CashCoverageInputEnum.COVERAGE_TYPE)!.value}</ErrorText>
            )}
          </StyledDropDownContainer>

          {isMultiplier(cashCoverageType) && (
            <>
              <StyledLabel>Monthly rent multiplier</StyledLabel>
              <StyledDropDownContainer>
                <DropdownMenu.Root>
                  <StyledDropdownMenuTrigger aria-label="Multiplier for cash deposit">
                    {cashMultiplierCoverage ? cashMultiplierCoverage.value + "X" : 'Select a coverage type'}
                    <ArrowDown width="24px" height="24px" />
                  </StyledDropdownMenuTrigger>
                  <DropdownMenu.Content>
                    {COVERAGE_MULTIPLIER_OPTIONS.map((multiplier) => (
                      <StyledDropdownMenuItem
                        key={`${multiplier.value}-cash`}
                        onSelect={() => handleCashMultiplier(multiplier)}
                      >
                        {multiplier.label}
                      </StyledDropdownMenuItem>
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
                {inputError(CashCoverageInputEnum.MULTIPLIER) && (
                  <ErrorText>{inputError(CashCoverageInputEnum.MULTIPLIER)!.value}</ErrorText>
                )}
              </StyledDropDownContainer>
            </>
          )}

          {isUnitPropertyMultiplier(cashCoverageType) && (
            <>
              <StyledLabel>Unit/Property Multiplier</StyledLabel>
              <StyledDropDownContainer>
                <DropdownMenu.Root>
                  <StyledDropdownMenuTrigger aria-label="Multiplier for cash deposit">
                    {cashUnitPropertyMultiplierCoverage ?
                      cashUnitPropertyMultiplierCoverage.value + "X" :
                      'Select a coverage type'
                    }
                    <ArrowDown width="24px" height="24px" />
                  </StyledDropdownMenuTrigger>
                  <DropdownMenu.Content>
                    {MULTIPLIER_OPTIONS_FOR_UNIT_PROPERTY.map((multiplier) => (
                      <StyledDropdownMenuItem
                        key={`${multiplier.value}-cash-unit-property`}
                        onSelect={() => handleCashUnitPropertyMultiplier(multiplier)}
                      >
                        {multiplier.label}
                      </StyledDropdownMenuItem>
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
                {inputError(CashCoverageInputEnum.UNIT_PROPERTY) && (
                  <ErrorText>{inputError(CashCoverageInputEnum.UNIT_PROPERTY)!.value}</ErrorText>
                )}
              </StyledDropDownContainer>
            </>
          )}

          {cashCoverageType === AddCoverageTypeEnum.DOLLAR_AMOUNT && (
            <TextInput
              id={`${cashCoverageType.replace(/ /g, '-')}-cash`}
              label="Dollar amount"
              value={cashDollarAmountInCents ? centsToUSDFormatter(new Decimal(cashDollarAmountInCents)) : ''}
              onChange={handleCashDollarAmount}
              placeholder={'e.g. $1,500'}
              error={errors.length > 0 && inputError(CashCoverageInputEnum.DOLLAR_AMOUNT) ? true : false}
              subtext={
                errors.length > 0 &&
                inputError(CashCoverageInputEnum.DOLLAR_AMOUNT) && (
                  <ErrorText> {inputError(CashCoverageInputEnum.DOLLAR_AMOUNT)!.value}</ErrorText>
                )
              }
              maxLength={11}
            />
          )}
        </>
      )}

      <StyledSaveButtonWrapper>
        <ButtonHabitat
          id="save-coverage-rule"
          variant="interaction"
          size="small"
          usage="tertiary"
          onClick={() => addCoverage()}
        >
          Save
        </ButtonHabitat>
      </StyledSaveButtonWrapper>
    </>
  );
};

export default AddCoverageRule;
