import React, { useRef, useState } from 'react';
import { Button, DownloadIcon } from '@sayrhino/rhino-shared-js';
import uploadPercentageFile from './uploadPercentageFile';
import { useMutation, QueryClient, QueryClientProvider } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import { ToastContextProvider } from '../toast/toast-context';
import ValidationErrors, { CsvErrors } from './ValidationErrors';
import DeleteCsv, { IInsuranceCarrierPercentages } from './DeleteCsv';

const queryClient = new QueryClient();

const manageCarrierStatus = (window as any).App?.featureFlags?.manageCarrierStatus;

const AddCsv: React.FC = () => {
  const [warning, setWaring] = useState<boolean>(true);
  const [csvFile, setCsvFile] = useState({});
  const [fileName, setFileName] = useState<string>('csv-file');
  const [error, setError] = useState<[CsvErrors] | undefined>();
  const [success, setSuccess] = useState<boolean>(false);
  const [buttonStatus, setButtonStatus] = useState<boolean>(true);
  const { addToast } = useToast();

  const { mutate } = useMutation(uploadPercentageFile);

  const handleUpload = () => {
    if (warning) {
      alert(
        "Caution updating your allocations will change the distribution of your insurance carrier's percentages to renters in the states named in your CSV "
      );
    }
    setWaring(false);
  };

  const handleError = (message) => {
    setError(message);
  };

  const downloadSampleSheet = () => {
    window.location.assign(
      'https://insurance-carrier-percentages-sheets.s3.amazonaws.com/sample_insurance_carrier_percentages_sheet.csv'
    );
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    mutate(csvFile, {
      onSuccess: (res: AxiosResponse) => {
        setError(undefined);
        addToast(res?.data?.message, 'info', TOAST_STATUS.SUCCESS);
        setSuccess(true);
        resetCsvState();
      },
      onError: (error: AxiosError) => {
        resetCsvState();
        const message = error?.response?.data.error.message;
        if (!Array.isArray(message)) {
          setError(undefined);
          addToast(message, 'error', TOAST_STATUS.ERROR);
        } else handleError(message);
      }
    });
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFileName(uploadedFile.name);
    setCsvFile(getFormData(uploadedFile));
    setButtonStatus(false);
  };

  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const getFormData = (droppedFile) => {
    const formData = new FormData();

    formData.append('percentages[file]', droppedFile);

    return formData;
  };

  const resetCsvState = () => {
    if (fileRef !== null) {
      fileRef.current.value = '';
    }
    setFileName('');
    setCsvFile({});
    setButtonStatus(true);
  };

  return (
    <div style={styles.container}>
      <p>
        <a href={`/v2/admin/insurance_carrier_markets`}>Back</a>
      </p>
      <div style={styles.textContainer}>
        <p style={styles.text}>
          First click on the Download sample CSV sheet button to get a template that will help you set the insurace
          carrier, insurance carrier percentage, state name, and the effective date for an updated carrier allocation.
          Once you have your CSV ready, use the Upload File to submit your allocation.
        </p>
      </div>
      <div style={styles.buttonContainer}>
        <span style={styles.downloadButton}>
          <Button
            variant="tertiary"
            id="download-precentage-csv-button"
            style={styles.button}
            onClick={downloadSampleSheet}
          >
            Download sample CSV sheet <DownloadIcon />
          </Button>
        </span>

        {warning && !success ? (
          <Button
            id="upload-percentage-csv"
            variant="primary"
            style={styles.button}
            onClick={handleUpload}
            disabled={!manageCarrierStatus}
          >
            Upload File
          </Button>
        ) : (
          <form onSubmit={handleSubmit} style={styles.formContainer}>
            <label>
              <input
                style={styles.text}
                type="file"
                id="csv-file"
                name={fileName}
                onChange={handleFileChange}
                accept=".csv"
                ref={fileRef}
              />
            </label>
            <Button
              id="upload-percentage-csv"
              variant="primary"
              style={styles.button}
              type="submit"
              disabled={buttonStatus}
            >
              Upload
            </Button>
          </form>
        )}
      </div>
      {!!error?.length ? <ValidationErrors rows={error} /> : null}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex-column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContainer: {
    display: 'flex-column',
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: '2em'
  },
  text: {
    fontSize: '16px',
    fontWeight: 'bold' as 'bold'
  },
  button: {
    textAlign: 'center' as 'center',
    marginTop: '1em'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '5em'
  },
  downloadButton: {
    margin: '0 5px',
    borderRadius: '8px'
  },
  formContainer: {
    display: 'flex-column',
    alignItems: 'center'
  }
};

const InsuranceCarrierPercentagesWrapper: React.FC<IInsuranceCarrierPercentages> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContextProvider position="bottom-right">
        <AddCsv />
        <hr />
        <DeleteCsv insurance_carrier_percentages={props.insurance_carrier_percentages} />
      </ToastContextProvider>
    </QueryClientProvider>
  );
};

export default InsuranceCarrierPercentagesWrapper;
