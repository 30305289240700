import { csrfToken } from 'utils/document';
import { Fields } from './InsuranceCarrierMarket';
import axios from 'axios';

const updateCarrierMarket = async (carrierMarket: Fields) => {
  const token = csrfToken();

  const { id, ...rest } = carrierMarket;

  const res = axios.put(`/v2/admin/insurance_carrier_markets/${id}.json`, {
    authenticity_token: token,
    ...rest
  });
  return res;
};

export default updateCarrierMarket;
