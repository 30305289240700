import React from 'react';
import styled from '@emotion/styled';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { PALETTE } from '@sayrhino/rhino-shared-js';

const StyledCardLink = styled(NavLink)({
  margin: '0 auto 12px auto',
  width: '480px',
  display: 'block'
});

const ActiveCardLinkStyle = {
  border: `2px solid ${PALETTE.interaction100}`,
  borderRadius: 8
};

const CardLink: React.FC<NavLinkProps> = ({ children, ...props }) => (
  <StyledCardLink {...props} style={({ isActive }) => (isActive ? ActiveCardLinkStyle : {})}>
    {children}
  </StyledCardLink>
);
export default CardLink;
