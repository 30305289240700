/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
// @ts-ignore
import searchImage from '../../../../assets/images/empty-hole.svg';
import { CloseIcon } from '@sayrhino/rhino-shared-js';


const EmptySearchResults: React.FC = ({children}) => {
  return (
    <div>
      <img src={searchImage} css={{display: 'block', margin: '93px auto 73px'}} alt="Search" />
      <div css={{textAlign: 'center'}}>
        { children }
      </div>
    </div>
  )
};

export default EmptySearchResults;
