import http from 'j-fetch';

export function searchUnits(query: string, onSuccess: Function, onError: Function) {
  http
    .get({ url: '/units/search?query=' + query })
    .then((r) => r.json())
    .then(onSuccess)
    .catch(onError);
}

export function searchUnitsWithinProperty(
  propertyId: string | number,
  query: string,
  onSuccess: Function,
  onError: Function
) {
  http
    .get({ url: `/properties/${propertyId}/units/search?query=${query}` })
    .then((r) => r.json())
    .then(onSuccess)
    .catch(onError);
}
