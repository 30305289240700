import styled from '@emotion/styled';
import { ButtonHabitat, ButtonLink, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import React, { FC } from 'react';

const EmptyStateCard = styled.div([FONTS.p2Light], {
  backgroundColor: PALETTE.brand4,
  color: PALETTE.brand125,
  borderRadius: '8px',
  padding: '10px 16px'
});

const EmptyStateSpan = styled.span([FONTS.p2Medium], {
  color: PALETTE.brand125
});

const ButtonGroup = styled.div({
  display: 'flex',
  marginTop: '10px',
  marginBottom: '10px'
});

interface IEmptyStateBanner {
  title: string;
  content: string;
  callToAction?: boolean;
  propertyOwnerID?: string;
}

const EmptyStateBanner: FC<IEmptyStateBanner> = ({ title, content, callToAction = false, propertyOwnerID }) => {
  return (
    <EmptyStateCard>
      <EmptyStateSpan>{title} &mdash; </EmptyStateSpan> {content}
      {callToAction && (
        <ButtonGroup>
          <ButtonLink
            id="get-started"
            type="button"
            size="small"
            usage="primary"
            target="_blank"
            to={`/v2/users/account/property_owners/${propertyOwnerID}`}
          >
            Get Started
          </ButtonLink>
          <ButtonLink
            id="learn-more"
            type="button"
            size="small"
            usage="tertiary"
            target="_blank"
            to="https://support.sayrhino.com/hc/en-us/articles/4404682463124-All-About-Renters-Insurance"
          >
            Learn more
          </ButtonLink>
        </ButtonGroup>
      )}
    </EmptyStateCard>
  );
};

export default EmptyStateBanner;
