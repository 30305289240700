/**
 * Checks if `value` is Present.
 *
 * A value is considered present if it's not nil, empty, or undefined.
 * @category Logic
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is present  , else `false`.
 * @example
 *
 * isPresent(1)
 * // => true
 *
 * isPresent([1])
 * // => true
 *
 * isPresent({a: 'a'})
 * // => true
 *
 * isPresent(null)
 * // => false
 *
 * isPresent(undefined)
 * // => false
 *
 * isPresent([])
 * // => false
 *
 * isPresent({})
 * // => false
 */
import { isNull, isUndefined } from 'lodash';
import isEmptyExtended from './isEmptyExtended';

const isPresent = (data: any): boolean => {
  return !isNull(data) && !isUndefined(data) && !isEmptyExtended(data);
};

export default isPresent;
