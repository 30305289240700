import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

const View = styled.aside({
  backgroundColor: PALETTE.brand4,
  width: '384px',
  maxWidth: '384px',
  height: '100%',
  position: 'fixed',
  zIndex: 1,
  top: 0,
  overflowX: 'hidden'
});

const Header = styled.h2([
  FONTS.h6Demi,
  {
    fontSize: '24px',
    lineHeight: '36px',
    color: PALETTE.brand100,
    padding: '48px 48px 0px'
  }
]);

const SubHeading = styled.p([
  FONTS.p1Light,
  {
    fontSize: '14px',
    lineHeight: '24px',
    color: PALETTE.brand100,
    padding: '0px 48px'
  }
]);

const StepBody = styled(SubHeading)({
  padding: '0'
});

const Step = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '40px 48px 40px',
  borderBottom: `1px solid ${PALETTE.neutral12}`
});

const WithoutBorder = styled(Step)({
  borderBottom: `0px`
});

const StepNumber = styled.div([
  FONTS.p1Medium,
  {
    textAlign: 'center',
    color: PALETTE.brand100,
    border: `1px solid ${PALETTE.brand100}`,
    borderRadius: '20px',
    marginRight: '12px',
    padding: '0px 10px'
  }
]);

const StepHeader = styled.h6([
  FONTS.p2Medium,
  {
    fontSize: '16px',
    lineHeight: '28px',
    color: PALETTE.brand100
  }
]);

const SideBar: React.FC = () => {
  return (
    <View>
      <Header>Getting started</Header>
      <SubHeading>Help residents enroll with Rhino and waive their security deposit requirement.</SubHeading>
      <Step>
        <StepNumber>1</StepNumber>
        <div>
          <StepHeader>Send out an invitation</StepHeader>
          <StepBody>
            Submit the invite applicant form. You’ll have 14 days to make updates after the renter signs up for a Rhino
            policy.
          </StepBody>
        </div>
      </Step>
      <Step>
        <StepNumber>2</StepNumber>
        <div>
          <StepHeader>Applicants apply</StepHeader>
          <StepBody>We'll email the applicant with instructions to sign up for Rhino.</StepBody>
        </div>
      </Step>
      <WithoutBorder>
        <StepNumber>3</StepNumber>
        <div>
          <StepHeader>You’ll be notified</StepHeader>
          <StepBody>Leasing teams will receive confirmation when the enrollment is complete.</StepBody>
        </div>
      </WithoutBorder>
    </View>
  );
};

export default SideBar;
