/** @jsx jsx */
import React, { ChangeEvent, FC, useState } from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Loading, Radio, FONTS, PALETTE, TextInput, Select } from '@sayrhino/rhino-shared-js';

import { CoverageState } from 'api/v2/useCoverageOptions';
import { CoverageType, ICoverageMultiplier, isCoverageType } from './interfaces';
import { DOLLAR_AMOUNT, MULTIPLIER, PROPERTY_DEFAULT } from './utils/constants';
import { IErrors } from './InvitationDetailsEdit';
import Decimal from 'decimal.js';
import { centsToUSDFormatter, sanitizeCurrencyInput } from './utils';
import { errorCSS } from './Invitation/Styles';

export interface ICoverageOptionSelectProps {
  coverageInfo: CoverageState;
  disabled: boolean;
  errors?: IErrors;
}

const contactUsURL = 'https://support.sayrhino.com/hc/en-us/requests/new';
const RadioRoot: any = Radio.Root;

const DefaultDiv = styled.div({
  marginLeft: '50px',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  padding: '20px 0'
});

const MultiplierDiv = styled.div({
  marginLeft: '50px',
  padding: '20px 0'
});

const DefaultPrice = styled.p([
  FONTS.p1Medium,
  {
    fontSize: '16px',
    lineHeight: '28px'
  }
]);

const Link = styled.a([
  FONTS.p1Medium,
  {
    fontSize: '14px',
    lineHeight: '24px',
    textDecoration: 'underline'
  }
]);

const DefaultText = styled.p([
  FONTS.p1Light,
  {
    fontSize: '14px',
    lineHeight: '24px'
  }
]);

const Wrap = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const ErrorText = styled.p([FONTS.p3], {
  color: PALETTE.alert100
});

export const CoverageOptionsRadioButtons = (props: ICoverageOptionSelectProps) => {
  const { coverageInfo, errors } = props;
  const { result, selected, setSelected, setType } = coverageInfo;
  const [multiplier, setMultiplier] = useState<ICoverageMultiplier>();

  if (result.isLoading) {
    return <Loading />;
  } else if (result.isError || result.data === undefined) {
    return <p>Error loading coverage options</p>;
  }

  const changeCoverageTypeIfValid = (type: CoverageType) => {
    if (isCoverageType(type)) {
      setType(type);
    }
  };

  function onChangeMultiplier(e: ICoverageMultiplier) {
    setSelected({
      type: MULTIPLIER,
      value: e.value
    });
  }

  function onChangeDollarAmount(e: ChangeEvent<HTMLInputElement>) {
    setSelected({
      type: DOLLAR_AMOUNT,
      value: new Decimal(sanitizeCurrencyInput(e))
    });
  }

  const options = result.data;
  const optionProps = (type: CoverageType) => ({
    ...props,
    key: `coverage-option-detail-${type}`
  });

  const defaultCoverage = options.default;

  return (
    <div>
      <RadioRoot
        name="Coverage"
        value={selected.type}
        onValueChange={(value: CoverageType) => {
          changeCoverageTypeIfValid(value);
        }}
      >
        <Radio.Option value="PROPERTY_DEFAULT" label="Property default" />
        {selected.type === 'PROPERTY_DEFAULT' && (
          <DefaultDiv>
            <DefaultPrice>
              {defaultCoverage?.type === DOLLAR_AMOUNT
                ? centsToUSDFormatter(defaultCoverage.value)
                : defaultCoverage?.label}
            </DefaultPrice>
            <Wrap>
              <DefaultText>
                This is set as the default coverage amount for this property. If this seems incorrect, please{' '}
                <Link href={contactUsURL}>submit a support ticket</Link>.
              </DefaultText>
            </Wrap>
          </DefaultDiv>
        )}
        <Radio.Option value="DOLLAR_AMOUNT" label="Dollar amount" />
        {selected.type === 'DOLLAR_AMOUNT' && (
          <DefaultDiv>
            <TextInput
              label="Coverage amount"
              id="aptSuiteFloor"
              onChange={onChangeDollarAmount}
              value={selected.value ? centsToUSDFormatter(selected.value) : centsToUSDFormatter(new Decimal(0))}
              data-cy="coverageAmount"
              style={{ padding: '0px 0px 0px 0px' }}
              subtext={
                errors?.coverage_amount_cents && (
                  <p className="infoError" css={errorCSS}>
                    Coverage amount {errors.coverage_amount_cents.join()}
                  </p>
                )
              }
            />
            {options.max_coverage_amount_cents != undefined && (
              <DefaultText>
                Please enter a value up to {centsToUSDFormatter(new Decimal(options.max_coverage_amount_cents))}
              </DefaultText>
            )}
            <Wrap>
              <DefaultText>
                This is set as the maximum coverage amount for this property. If this seems incorrect, please{' '}
                <Link href={contactUsURL}>submit a support ticket</Link>.
              </DefaultText>
            </Wrap>
          </DefaultDiv>
        )}
        <Radio.Option data-cy="multiplierOption" value="MULTIPLIER" label="Multiplier" />
        {selected.type === 'MULTIPLIER' && (
          <MultiplierDiv>
            <Select
              id="rentMultiplier"
              data-cy="coverageMultiplier"
              value={multiplier?.label}
              onChange={(e: ICoverageMultiplier) => onChangeMultiplier(e)}
              options={options.multipliers}
            />
          </MultiplierDiv>
        )}
      </RadioRoot>
    </div>
  );
};

export default CoverageOptionsRadioButtons;
