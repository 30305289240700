import { TextInput } from '@sayrhino/rhino-shared-js';
import React from 'react';

type MaskedPasswordInputProps = {
  /** Function to set password value */
  setPassword: (val: string) => void;
  /** Function to track password state change */
  setHasPasswordChanged: (val: boolean) => void;
  /** Boolean representing whether password has been changed (should initially be false) */
  hasPasswordChanged: boolean;
  /** String for password value */
  password: string;
  /** String for error value */
  error: string;
  /** Boolean representing whether to show mask or not */
  showMask: boolean;
  /** id, required to pair label and input */
  id: string;
  /** String to be used as label */
  label: string;
};

/**
 * Text input component that will show an arbitrary password mask. Editing the input will empty it's masked value and allow unmasked input.
 * A "Not changed" message appears in the subtext to denote whether the password has been modified or not.
 * This component is useful when editing a form that has a password input. If we don't want to return the password to display, we can mask a value
 * and dynamically include/exclude it from a payload based on whether it was changed.
 * @param props
 * @returns Masked password text input component
 */
const MaskedPasswordInput = (props: MaskedPasswordInputProps) => {
  const { id, label, setPassword, setHasPasswordChanged, hasPasswordChanged, password, showMask, error } = props;
  return (
    <TextInput
      id={id}
      label={label}
      value={password}
      onChange={(e) => {
        setPassword(e.target.value);
        if (showMask) {
          // mark password as updated
          setHasPasswordChanged(true);
        }
      }}
      onFocus={(e) => {
        if (showMask) {
          // mask is showing, empty password field
          setPassword('');
        }
      }}
      onBlur={(e) => {
        if (!hasPasswordChanged || e.target.value === '') {
          // mask password if they click out/empty input
          setPassword('0000000000');
          setHasPasswordChanged(false);
        }
      }}
      subtext={error.length > 0 ? error : hasPasswordChanged ? '' : 'Not changed'}
      type={showMask ? 'password' : ''}
    ></TextInput>
  );
};

export default MaskedPasswordInput;
