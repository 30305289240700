/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';

type RuleProps = JSX.IntrinsicElements['hr'];

const DashboardPanelRule = (props: RuleProps) => (
  <hr
    css={{
      borderBottom: `1px solid ${PALETTE.neutralDark}`,
      marginBottom: '2rem'
    }}
    {...props}
  />
);

export default DashboardPanelRule;
