/** @jsx jsx */
import { ChangeEvent } from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { TextInput, PALETTE, FONTS, DropdownMenu, ArrowDown } from '@sayrhino/rhino-shared-js';
import { centsToUSDFormatter } from '../utils';
import { CoverageTypeEnum } from '../interfaces';
import Decimal from 'decimal.js';
import SwitchSelector from '../SwitchSelector';
import { COVERAGE_MULTIPLIER_OPTIONS } from './CoverageOptions';
import { StyledDropdownMenuTrigger, StyledDropdownMenuItem } from './CreatePMSIntegrationSetup';
import Spacer from './SpacingTokens';

const StyledLabel = styled.label([FONTS.p3Medium, { color: PALETTE.neutral65 }]);

const StyledMultiplierInputDiv = styled.div({ marginBottom: 25 });
const SwitchWrapper = styled.div({ display: 'flex', alignItems: 'center' });

interface IProps {
  coverageType: CoverageTypeEnum;
  value: number;
  onSelect: (value: CoverageTypeEnum) => void;
  handleCoverageDollarValue: (e: ChangeEvent<HTMLInputElement>) => void;
  setCoverageValue: (value: number) => void;
  coverageDollarError: string;
  label: string;
  includeNoCoverage?: boolean;
  id?: string;
}

export const CoverageRuleSwitch: React.FC<IProps> = ({
  label,
  coverageType,
  value,
  handleCoverageDollarValue,
  onSelect,
  setCoverageValue,
  coverageDollarError,
  id,
  includeNoCoverage = true
}) => {
  const coverageList = [
    { label: 'Multiplier', value: CoverageTypeEnum.MULTIPLIER },
    { label: 'Dollar amount', value: CoverageTypeEnum.DOLLAR_AMOUNT },
    { label: 'RealPage Deposits', value: CoverageTypeEnum.RP_DEPOSIT_CHARGES },
    { label: 'No Coverage', value: CoverageTypeEnum.NO_COVERAGE }
  ];
  if (!includeNoCoverage) {
    coverageList.pop();
  }

  const handleApprovalSelect = (value: string) => {
    const covgType: CoverageTypeEnum = value as CoverageTypeEnum;
    onSelect(covgType);
  };
  return (
    <div aria-label={id}>
      <SwitchWrapper>
        <SwitchSelector
          active={coverageType}
          list={coverageList}
          onClick={handleApprovalSelect}
          width={includeNoCoverage ? 520 : 390}
          id={id}
        />
      </SwitchWrapper>
      <Spacer amount={3} />

      {coverageType === CoverageTypeEnum.DOLLAR_AMOUNT && (
        <div>
          <TextInput
            id={`${label}-approval-amount`}
            label="Dollar amount"
            onChange={(e) => handleCoverageDollarValue(e)}
            value={value === 0 ? '' : centsToUSDFormatter(new Decimal(value))}
            subtext={coverageDollarError}
            error={!!coverageDollarError}
            placeholder={'e.g. $1,500'}
            maxLength={11}
          />
        </div>
      )}
      {coverageType === CoverageTypeEnum.MULTIPLIER && (
        <StyledMultiplierInputDiv>
          <StyledLabel>Monthly rent multiplier</StyledLabel>
          <DropdownMenu.Root>
            <StyledDropdownMenuTrigger aria-label="Rent multiplier" value={+value}>
              <span>
                {COVERAGE_MULTIPLIER_OPTIONS.find((multiplier) => Number(multiplier.value) === +value)?.label}
              </span>
              <ArrowDown width="24px" height="24px" />
            </StyledDropdownMenuTrigger>
            <DropdownMenu.Content>
              {COVERAGE_MULTIPLIER_OPTIONS.map((multiplier) => (
                <StyledDropdownMenuItem
                  key={multiplier.value}
                  onSelect={() => setCoverageValue(Number(multiplier.value))}
                >
                  {multiplier.label}
                </StyledDropdownMenuItem>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </StyledMultiplierInputDiv>
      )}
    </div>
  );
};
