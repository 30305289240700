import React, { useState } from 'react';
import styled from '@emotion/styled';
import ToggleButton from './ToggleButton';
import { useMutation, QueryClient, QueryClientProvider } from 'react-query';
import { PALETTE, Loading, Button as ButtonBase } from '@sayrhino/rhino-shared-js';
import updateCarrierMarket from './updateCarrierMarket';
import { AxiosError } from 'axios';
import useCarrierMarketDetails from './useCarrierMarketDetails';

const queryClient = new QueryClient();

export const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid black',
  borderRadius: '30px',
  padding: '25px 30px',
  margin: '10px',
  width: '60em',
  height: 'auto'
});

const FormArea = styled.form({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center'
});

const ButtonsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '240px',
  alignItems: 'center'
});

const Button = styled(ButtonBase)(
  {
    width: '10em',
    margin: '32px 0 0',
    alignItems: 'center !important',
    display: 'flex',
    justifyContent: 'center'
  },
  (props) => ({
    backgroundColor: `${props.disabled ? PALETTE.neutral12 : PALETTE.brand100} !important`,
    color: `${props.disabled ? PALETTE.neutralDark : PALETTE.neutralLight} !important`
  })
);

const Link = styled.a({
  fontSize: 14
});

export type Fields = {
  id: number;
  enabled: boolean;
};

const InsuranceCarrierMarket: React.FC = () => {
  let params = window.location.pathname.split('/');
  const { data } = useCarrierMarketDetails(+params[4]);
  const { insurance_carrier_market } = data || {};
  const { id, carrier_name, enabled, market_type, actions } = insurance_carrier_market || {};
  const [fields, setFields] = useState<Fields>({ id, enabled } as Fields);
  const { mutate, isLoading } = useMutation(updateCarrierMarket);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    mutate(fields, {
      onSuccess: () => {
        queryClient.invalidateQueries('carrier market details');
        setSuccess(true);
      },
      onError: (error: AxiosError) => {
        setError(error?.response?.data.error);
        setTimeout(() => setError(''), 5000);
      }
    });
  };

  const toggleInsuranceCarrierMarket = (id: number, status: boolean) => {
    setFields((prev) => ({ ...prev, enabled: status }));
    if (success) setSuccess(false);
  };

  const isValidState = (): boolean => {
    return fields !== undefined && fields.enabled !== enabled;
  };

  return (
    <>
      <p>
        <a href={`/v2/admin/insurance_carrier_markets`}>Back</a>
      </p>
      <FormArea onSubmit={handleSubmit}>
        <h1>{`${carrier_name}_${market_type}`}</h1>
        <Card key={id}>
          <ButtonsWrapper>
            <ToggleButton
              id={0}
              name={`${carrier_name}_${market_type}`}
              handleToggle={toggleInsuranceCarrierMarket}
              enabled={enabled || false}
              label="Enabled?"
              disabled={!actions?.editable}
            />
          </ButtonsWrapper>
          <Button
            children={isLoading ? 'Loading' : 'Submit'}
            variant="primary"
            disabled={!actions?.editable || !isValidState() || isLoading || success}
            type="submit"
          />
        </Card>
      </FormArea>
    </>
  );
};

const InsuranceCarrierMarketWrapper: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <InsuranceCarrierMarketView />
    </QueryClientProvider>
  );
};

const InsuranceCarrierMarketView: React.FC = () => {
  let params = window.location.pathname.split('/');
  const { isSuccess } = useCarrierMarketDetails(+params[4]);
  return isSuccess ? <InsuranceCarrierMarket /> : <Loading />;
};

export default InsuranceCarrierMarketWrapper;
