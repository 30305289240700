/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { CloseIcon, FONTS, PALETTE, RhinoLogo } from '@sayrhino/rhino-shared-js';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobileDevice } from 'utils/mobile';
import Tab from '../Tab';
import CreateInvitationForm from './CreateInvitationForm';
import GettingStartedSteps from './GettingStartedSteps';
import { closeButtonCSS, errorCSS, errorLinkCSS } from './Styles';
import useCreateInvitationHook from './useCreateInvitationHook';
import { mediaQueryWidth } from '../utils';
import { BREAKPOINTS } from '../utils/constants';

const IS_MOBILE = isMobileDevice();

export const CreateInvitation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const TabList = styled.div({ borderBottom: `1px solid ${PALETTE.neutral12}`, display: 'flex', paddingLeft: '48px' });

  const renewalRequests = (window as any).App?.featureFlags?.renewalRequests;

  const { trackErrorLinkClick, errors, formRef } = useCreateInvitationHook();

  const createErrorMsg = (error) => {
    if (error.includes('invitation')) {
      return (
        <p className="infoError" css={errorCSS}>
          This property already has an active
          <a
            css={errorLinkCSS}
            target="_blank"
            href={`/admin/invitations/${errors?.existing_invitation_id}`}
            onClick={() =>
              trackErrorLinkClick(
                'Property already has an active invitation',
                `/admin/invitations/${errors?.existing_invitation_id}`
              )
            }
          >
            invitation
          </a>
          for this email
        </p>
      );
    } else if (error.includes('unit')) {
      return (
        <p className="infoError" css={errorCSS}>
          User already has a
          <a
            css={errorLinkCSS}
            target="_blank"
            href={`/admin/renter_policies/${errors?.unit_existing_policy_id}`}
            onClick={() =>
              trackErrorLinkClick(
                'User already has a policy at this unit',
                `/admin/renter_policies/${errors?.unit_existing_policy_id}`
              )
            }
          >
            policy
          </a>
          at this unit
        </p>
      );
    } else {
      return (
        <p className="infoError" css={errorCSS}>
          User already has a
          <a
            css={errorLinkCSS}
            target="_blank"
            href={`/admin/renter_policies/${errors?.property_existing_policy_id}`}
            onClick={() =>
              trackErrorLinkClick(
                'User already has a policy at this property',
                `/admin/renter_policies/${errors?.property_existing_policy_id}`
              )
            }
          >
            policy
          </a>
          at this property
        </p>
      );
    }
  };

  return (
    <div>
      <GettingStartedSteps type="invitation" />
      <div
        id="form-container"
        css={{
          flex: 1,
          marginLeft: 384,
          [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
            marginLeft: 0
          }
        }}
      >
        <div
          id="header"
          className="invitation-header"
          css={{
            borderBottom: `1px solid ${PALETTE.neutral4}`,
            backgroundColor: PALETTE.neutralLight,
            padding: '20px 48px',
            position: 'sticky',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 1,
            [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
              display: 'none'
            }
          }}
        >
          <span css={[FONTS.h5, { color: `${PALETTE.neutral25}` }]}>Send an invitation</span>
          <button css={closeButtonCSS} onClick={() => navigate(-1)} aria-label="Leave invitation form">
            <CloseIcon height="16px" width="16px" color={PALETTE.neutral25} css={{ marginTop: '7px' }} />
          </button>
        </div>
        <form>
          <div
            id="form"
            ref={formRef}
            css={{
              padding: '40px 96px',
              marginBottom: 100,
              [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
                padding: '40px 16px'
              }
            }}
          >
            {IS_MOBILE && (
              <div
                css={{
                  marginBottom: '40px'
                }}
              >
                <RhinoLogo />
              </div>
            )}

            {renewalRequests && (
              <TabList role="tabList" aria-label="Send invitations">
                <Tab to={'/admin/invitations/new'}>New policy</Tab>
                <Tab to={'/admin/invitations/policy_renewal'}>Policy renewal</Tab>
              </TabList>
            )}

            <CreateInvitationForm createErrorMsg={createErrorMsg} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateInvitation;
