import axios from 'axios';
import { QueryObserverResult, useMutation, useQuery } from 'react-query';

type CancelationRequestDenialReason = {
  label: string,
  value: number
};

const getCancellationRequestDenialReasons = async (id: number) => {
  const { data } = await axios.get<CancelationRequestDenialReason[]>(
    `/v2/admin/insurance_policy_cancellation_requests/${id}/cancellation_request_denials/options`
  );
  return data;
};

export default function useCancellationRequestDenialReasons(id: number): QueryObserverResult<CancelationRequestDenialReason[]> {
  return useQuery<any>(['cancellation_request', id], () => getCancellationRequestDenialReasons(id));
}
