import React, { useState } from 'react';
import TextAreaInput from './TextAreaInput';
import {
  SectionHeader,
  AttachmentLabel,
  NotesFormWrapper,
  NotesSubmitButton,
  AttachmentList,
  NotesLabel
} from './ClaimStyles';
import { useDropzone } from 'react-dropzone';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import AttachmentFileItem from '../AttachmentFileItem';
import useToast from '../../toast/use-toast';
import useCreateClaimNoteMutation from 'api/v2/createClaimNote';
import { useQueryClient } from 'react-query';
import { CLAIM_NOTES_KEY } from 'api/v2/useClaimNotes';
import { CLAIM_ATTACHMENTS_KEY } from 'api/v2/useClaimAttachments';

type RequestError = {
  errors: { description: string[] };
};

const ATTACHMENTS_URL = '/uploads/attachments';

const NoteAndAttachmentsForm = () => {
  const { claimsId } = useParams();
  const queryCache = useQueryClient();
  const [noteDescription, setNoteDescription] = useState<string>('');
  const [noteError, setNoteError] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [attachmentFiles, setAttachmentFiles] = useState<any[]>([]);
  const { addToast } = useToast();

  // dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,application/pdf',
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((f, index) => {
        const data = new FormData();
        data.append('file', f);
        axios
          .post(ATTACHMENTS_URL, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: () => {
              setLoading(true);
            }
          })
          .then((res) => {
            // @ts-ignore
            f.id = res.data.id;
            setLoading(false);
          });
      });
      setAttachmentFiles((currentFiles) => [...currentFiles, ...acceptedFiles]);
    }
  });
  const removeAttachment = (e, file) => {
    e.preventDefault();
    axios
      .delete(`${ATTACHMENTS_URL}/${file.id}`)
      .then((res) => {
        setAttachmentFiles((currentFiles) => currentFiles.filter((f) => f.name !== file.name));
      })
      .catch((err) => {
        addToast('Could not remove attachment', 'error');
      });
  };

  const resetForm = () => {
    setNoteDescription('');
    setAttachmentFiles([]);
  };

  const visibleCheckbox = document.getElementById("set-visibility") as HTMLInputElement;

  const { mutate: createClaimNoteMutation } = useCreateClaimNoteMutation();
  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setNoteError('');
    createClaimNoteMutation(
      {
        claimsId: Number(claimsId),
        description: noteDescription,
        attachments: attachmentFiles.map((item) => item?.id),
        public: visibleCheckbox?.checked
      },
      {
        onSuccess: () => {
          queryCache.invalidateQueries([CLAIM_NOTES_KEY]);
          queryCache.invalidateQueries([CLAIM_ATTACHMENTS_KEY]);
          resetForm();
        },
        onError: (error: AxiosError<RequestError>) => {
          if (error.response?.status === 422) {
            const data = error.response.data;
            const errors = data.errors;
            if (errors.description?.length) {
              setNoteError(errors.description.toString());
            }
          } else {
            addToast('Internal Server Error', 'error');
          }
        }
      }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <SectionHeader>Notes</SectionHeader>
      <TextAreaInput
        id="claim-note"
        name="claim note"
        onChange={(e) => {
          if (noteError) setNoteError(undefined);
          setNoteDescription(e.target.value);
        }}
        value={noteDescription}
        subtext={noteError}
        error={!!noteError}
      />
      <NotesLabel htmlFor="claim-note">Notes Description</NotesLabel>
      {attachmentFiles.length > 0 && (
        <AttachmentList>
          {attachmentFiles.map((attachment) => (
            <AttachmentFileItem key={attachment.name} attachment={attachment} removeAttachment={removeAttachment} />
          ))}
        </AttachmentList>
      )}
      <div {...getRootProps()}>
        <input
          type="file"
          id="notes-attachment-claim"
          name="notes-attachment"
          {...getInputProps({ id: 'attachment-claim-dz' })}
        />
      </div>
      <NotesFormWrapper>
        <AttachmentLabel htmlFor="attachment-claim-dz">Attach files</AttachmentLabel>

        <input type="checkbox" id="set-visibility" name="note-visibility" />
        <label htmlFor="set-visibility">Make note visible to partner</label>

        <NotesSubmitButton variant="primary" type="submit" disabled={isLoading}>
          submit
        </NotesSubmitButton>
      </NotesFormWrapper>
    </form>
  );
};
export default NoteAndAttachmentsForm;
