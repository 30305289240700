import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

type PolicySearchResult = {
  first_name: string;
  last_name: string;
  address: string;
  id: number;
  coverage_end_date: string;
  next_policy_id: boolean;
  renewal: boolean;
  unit_name: string;
};

type PolicySearchConfig = {
  host?: string;
};

type PolicySearchResults = PolicySearchResult[];

export const POLICY_SEARCH_QUERY_KEY = 'policySearch';

export const getPolicies = async (query: string, config?: PolicySearchConfig) => {
  const { data } = await axios.get<PolicySearchResults>(`/v2/admin/tenant_active_policies`, {
    params: { query: query }
  });
  return data;
};

export function usePolicySearch(query: string, config?: PolicySearchConfig): QueryObserverResult<PolicySearchResults> {
  return useQuery<PolicySearchResults>([POLICY_SEARCH_QUERY_KEY, query], () => {
    return getPolicies(query, config);
  });
}
