/** @jsx jsx */
import { jsx } from '@emotion/core';
import '@reach/combobox/styles.css';
import React, { useCallback, useState, useEffect } from 'react';
import DomPurify from 'dompurify';
import { getPolicies, POLICY_SEARCH_QUERY_KEY } from 'api/v2/useTenantActivePolicies';
import { PALETTE, FONTS, ArrowDown } from '@sayrhino/rhino-shared-js';
import { useQueryClient } from 'react-query';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import styled from '@emotion/styled';
import { useSegmentUser } from '../../App/utils/segmentTracker';
import { getSelectStyles } from 'components/v2/select/styles';

interface PolicyTypeaheadProps {
  onSelect: (policy: PolicySearchResult) => void;
  isInvalid?: boolean;
  id: string;
  policy?: PolicySearchResult;
  invite_id?: string;
  setPolicy: any;
  error: any;
}

type PolicySearchResult = {
  first_name: string;
  last_name: string;
  address: string;
  id: number;
};

const sanitize = (html) => DomPurify.sanitize(html);

const Wrapper = styled.div({ marginTop: '16px' });

const StyledLabel = styled.label<{ error?: boolean }>(
  [FONTS.p3Medium, { color: PALETTE.neutral65 }],
  (props) => props.error && { color: PALETTE.alert125 }
);

const SubText = styled.p<{ error?: boolean }>(({ error }) => [
  FONTS.p1,
  {
    fontSize: '12px',
    color: !error ? PALETTE.neutral25 : PALETTE.alert100
  }
]);

const PolicyValueText = styled.p([FONTS.p2Light], {
  color: PALETTE.neutral88,
  margin: '0',
  fontSize: '16px'
});

const PolicyOption = styled.li([FONTS.p2], {
  display: 'flex',
  margin: '0'
});

const NameText = styled.span({
  color: PALETTE.neutral88,
  fontFamily: 'MaisonNeueLight',
  fontSize: '16px',
  lineHeight: '24px',
  i: {
    fontWeight: 'bold'
  },
  '@media(max-width:425px)': {
    maxWidth: '250px'
  }
});

const AddressText = styled.p([FONTS.p2Light], {
  color: PALETTE.neutral55,
  fontSize: '16px'
});

export const PolicyInviteTypeahead = ({
  policy,
  onSelect,
  isInvalid,
  id,
  invite_id,
  setPolicy,
  error
}: PolicyTypeaheadProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPolicy, setSelectedPolicy] = useState<PolicySearchResult | undefined>(policy);
  const queryClient = useQueryClient();
  const segmentUser = useSegmentUser();
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const renewalRequests = (window as any).App?.featureFlags?.renewalRequests;

  const isPolicyForm = renewalRequests && invite_id;

  const getTenantInfoString = (policy) => {
    return `${policy.first_name} ${policy.last_name} • ${policy.address}`;
  };

  const boldSearchTerm = (str, substr) => {
    const term = new RegExp('(' + substr + ')', 'ig');
    return str.replace(term, `<i>$1</i>`);
  };

  useEffect(() => {
    setPolicy(policy as PolicySearchResult);
  }, [policy]);

  const loadOptions = async (input: string) => {
    setIsLoading(() => true);
    try {
      const policies = await queryClient.fetchQuery([POLICY_SEARCH_QUERY_KEY, input], () => getPolicies(input));
      setIsLoading(() => false);
      const newPropertiesArray = policies?.map((policy) => {
        return {
          label: getTenantInfoString(policy),
          value: policy.id,
          id: policy.id,
          first_name: policy.first_name,
          last_name: policy.last_name,
          full_name: policy.first_name + ' ' + policy.last_name,
          address: policy.address,
          coverage_end_date: policy.coverage_end_date,
          next_policy_id: policy.next_policy_id,
          renewal: policy.renewal,
          unit_name: policy.unit_name
        };
      });
      return newPropertiesArray;
    } catch (error) {
      setIsLoading(() => false);
      return [];
    }
  };

  const selectChange = (value: PolicySearchResult) => {
    setSelectedPolicy(value);
    onSelect(value);
    setPolicy(value);
  };

  const trackSegmentEvents = (eventName: string, { meta }) => {
    window.analytics.track(eventName, {
      invite_session_id: invite_id,
      userId: segmentUser.user_id,
      userType: segmentUser.user_type,
      sessionId: segmentUser.portal_session_id,
      ...meta
    });
  };

  const trackSelectInteraction = (e) => {
    if (!hasInteracted) {
      if (isPolicyForm) {
        trackSegmentEvents('Renewal Tenant Search Started', {
          meta: {
            cta_referrer: document.referrer.split('/').slice(3).join('/')
          }
        });
      }
      setHasInteracted(true);
    }
    setSearchTerm(e);
  };

  const formatOptionLabel = ({ value, label, full_name, address }, { context }) => (
    <div>
      {context === 'value' && <PolicyValueText>{label}</PolicyValueText>}
      {context === 'menu' && (
        <PolicyOption>
          <NameText dangerouslySetInnerHTML={{ __html: sanitize(boldSearchTerm(full_name, searchTerm)) }} />
          <AddressText>&nbsp;{'• ' + address}</AddressText>
        </PolicyOption>
      )}
    </div>
  );

  return (
    <Wrapper>
      <StyledLabel error={error} id="policy-name-header">
        Name
      </StyledLabel>
      <AsyncSelect
        aria-labelledby="policy-name-header"
        id={id}
        isLoading={isLoading}
        value={selectedPolicy}
        cacheOptions
        loadOptions={loadOptions}
        onChange={(e) => {
          selectChange(e);
        }}
        onInputChange={(e) => {
          trackSelectInteraction(e);
        }}
        inputId="policy-search"
        getOptionValue={(option: PolicySearchResult) => option.id}
        getOptionLabel={(option: PolicySearchResult) => getTenantInfoString(option)}
        placeholder=""
        styles={getSelectStyles({ isError: error })}
        formatOptionLabel={formatOptionLabel}
        components={{ DropdownIndicator: () => <ArrowDown style={{ marginRight: 4 }} /> }}
      />

      {isInvalid && <SubText error={isInvalid}>Can't be left blank</SubText>}
    </Wrapper>
  );
};
