import { Ordering } from './application_table';
import ApplicationTable from './application_table';
import {DatatableFilter, DatatableFilterEnum} from './filter_select';

const adminStartDateColumnNumber = 8;
const propertyManagerStartDateColumnNumber = 7;

class RenterPoliciesTable extends ApplicationTable {
  public selector = '#renter-policy-table';
  public pathPrefix = 'renter_policies/';
  public emptyMessageText = 'You have no subscribed tenants';
  public order: Ordering = [this.getOrderColumnNumber(), 'desc'];
  public dom = '<"table-head"<"buttons"B><"search"fl><"custom-filters-wrapper"><"top">>rt<"bottom"ip><"clear">';
  private customFiltersNode: JQuery;
  public getColumns() {
    const columns: DataTables.ColumnSettings[] = [];

    columns.push(
      {data: 'policy_number'},
      {data: 'renter_name'},
      {visible: false, data: 'renter_email'},
      {data: 'address_line_one'},
      {visible: false, data: 'address_city'},
      {data: 'unit'}
    );

    if (this.can('view-advanced-properties')) {
      columns.push(
        {data: 'policy_holder'},
        {data: 'property_owner_name'}
      );
    }

    columns.push(
      {data: 'start_date'},
      {data: 'end_date'}
    );

    if (this.can('view-policy-status')) {
      columns.push({data: 'status'});
    }

    if (this.can('view-advanced-properties')) {
      columns.push({data: 'upfront'});
    }

    columns.push(
      {data: 'remaining_coverage'},
      {data: 'coverage_multiplier'}
    );

    if (this.can('view-advanced-properties')) {
      columns.push({data: 'market_name'});
    }

    return columns;
  }

  public onInitComplete() {
    this.dataTable = this.dataTable || this.$table.DataTable();

    this.customFiltersNode = $('.custom-filters-wrapper').addClass('clearfix row');

    [
      DatatableFilterEnum.AddressState,
      DatatableFilterEnum.Status
    ].forEach((name) => {
      const filter: DatatableFilter = new DatatableFilter(
        name,
        this.dataTable,
        this.customFiltersNode
      );

      filter.call();
    });
  }

  public getOrderColumnNumber() {
    if ($(this.selector).data('can-view-advanced-properties')) {
      return adminStartDateColumnNumber;
    }

    return propertyManagerStartDateColumnNumber;
  }
}

export default RenterPoliciesTable;
