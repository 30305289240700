import React, { useState } from 'react';
import { IFileData } from '../interfaces';
import { DownloadIcon, Loading, PALETTE } from '@sayrhino/rhino-shared-js';
import FileViewerModal from './FileViewerModal';
import {
  DrawerModalHeader,
  FileViewerDownloadButtonContainer,
  FileTag,
  ImageTag,
  FileViewerDownloadButton,
  FileViewerRightSection,
  FileViewerCloseButton,
  FileViewerLeftSectionForImage,
  FileViewerLeftSectionForPDF,
  FileViewerContainer,
  FileViewerRightUpperSection,
  FileViewerRightLowerSection,
  FileViewerSubText,
  FileViewerLeftSectionForLoading
} from './FileViewerStyles';
import { Resizable } from 're-resizable';

interface IProps {
  subText?: string;
  header?: string;
  loadingFile?: boolean;
  selectedFile?: IFileData;
  closeModel: () => void;
  downloadFile: (url: string, name: string) => void;
}

const FileViewer: React.FC<IProps> = ({
  selectedFile,
  downloadFile,
  closeModel,
  subText,
  header,
  children,
  loadingFile
}) => {
  const isAttachmentPdf = selectedFile?.url?.toLowerCase().includes('.pdf') || false;

  const [resizeStyle, setResizeStyle] = useState<any>({});

  const updateResizeStyle = () => {
    setResizeStyle({
      borderLeft: `3px solid ${PALETTE.interaction100}`,
      borderRight: `3px outset ${PALETTE.interaction100}`
    });
  };
  return (
    <FileViewerModal>
      <FileViewerContainer>
        <Resizable
          defaultSize={{
            width: 840,
            height: `100vh`
          }}
          style={resizeStyle}
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          minWidth={840}
          onResizeStart={updateResizeStyle}
          onResizeStop={() => {
            setResizeStyle({});
          }}
        >
          {loadingFile ? (
            <FileViewerLeftSectionForLoading>
              <Loading />
            </FileViewerLeftSectionForLoading>
          ) : isAttachmentPdf ? (
            <FileViewerLeftSectionForPDF>
              <FileTag src={selectedFile?.url} key={selectedFile?.url} type="application/pdf" />
            </FileViewerLeftSectionForPDF>
          ) : (
            <FileViewerLeftSectionForImage>
              <ImageTag src={selectedFile?.url} key={selectedFile?.url} />
            </FileViewerLeftSectionForImage>
          )}
        </Resizable>
        <FileViewerRightSection>
          <FileViewerRightUpperSection>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FileViewerCloseButton onClick={closeModel} />
            </div>
            <DrawerModalHeader>{header || selectedFile?.filename}</DrawerModalHeader>
            <FileViewerSubText>{subText}</FileViewerSubText>
            <FileViewerDownloadButton
              onClick={() => {
                const { download_url, filename } = selectedFile ?? {};
                if (download_url && filename) {
                  downloadFile(download_url, filename);
                }
              }}
              variant="tertiary"
            >
              <FileViewerDownloadButtonContainer>
                <span>Download</span> <DownloadIcon height={16} width={16} />
              </FileViewerDownloadButtonContainer>
            </FileViewerDownloadButton>
          </FileViewerRightUpperSection>
          <FileViewerRightLowerSection>{children}</FileViewerRightLowerSection>
        </FileViewerRightSection>
      </FileViewerContainer>
    </FileViewerModal>
  );
};

export default FileViewer;
