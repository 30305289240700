import cancelWithdrawal from "api/v2/cancelWithdrawal";
import { useMutation, useQueryClient } from "react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import React from "react";
import useToast from "components/v2/App/toast/use-toast";
import deleteBankAccount from "./deleteBankAccount";

const useDeleteBankAccountView = () => {
    const [error, setError] = React.useState(null)

    const { mutate: deleteBankAccountMutation } = useMutation(deleteBankAccount, {});

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { addToast } = useToast();

    const handleDeleteBankAccount = (id: string, accountType: string) => {
        deleteBankAccountMutation(
          {id, accountType},
          {
            onSuccess: (res) => {
              const path = generatePath(`/admin/funds`);
              queryClient.invalidateQueries('adminBankAccounts');
              navigate(`${path}`);
              addToast('Bank Account Deleted');
            },
            onError: (e: AxiosError) => {
              handleError(e);
            }
          }
        );
      };

      const handleError = (error: AxiosError) => {
        
        if (error.response?.status === 422) {
          const { errors } = error.response?.data;
          setError(errors);
        } else {
          navigate(-1);
          addToast('Something went wrong', 'error');
        }
      };

      return {
        error,
        handleSubmit: handleDeleteBankAccount,
      }
}

export { useDeleteBankAccountView }
