/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useBankAccountDetails from 'api/v2/useBankAccountDetails';
import { ActionButtonWrapper } from '../../Claims/ClaimDetails/ClaimStyles';
import { ButtonHabitat, FONTS, Loading } from '@sayrhino/rhino-shared-js';
import { LoadingWrapper, Splitter } from '../../PropertyOwners/Styled';
import { useDeleteBankAccountView } from 'api/v2/useDeleteBankAccount';

const StyledCancelSection = styled.div({
  margin: '20px auto'
});

const StyledContent = styled.div({
  padding: '3rem'
});

const StyledHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '1.875rem'
})

const DeleteBankAccountConfirmation: React.FC = () => {
  const { accountId } = useParams();
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const accountType = searchParams.get("accountType")
  const { data: bankAccount, isLoading: isBankAccountLoading } =
    useBankAccountDetails(accountId, accountType) ?? {};

  const { handleSubmit, error } = useDeleteBankAccountView()

  if (isBankAccountLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <React.Fragment>
      <StyledContent>
        <StyledHeader>
          <h2 css={[FONTS.h3]}>
            {bankAccount?.bank_name}, {bankAccount?.account_number}
          </h2>
        </StyledHeader>
        <Splitter />
        <StyledCancelSection>
          <h2 css={[FONTS.h3, { margin: '10px auto' }]}>Delete Bank Account?</h2>

          <p css={[FONTS.p, { margin: '10px auto' }]}>
            Are you sure you want to delete this account? This action cannot be reversed.
          </p>
        </StyledCancelSection>
        <ActionButtonWrapper
          css={{
            borderBottom: 'none'
          }}
        >
          <ButtonHabitat id="neverMind" usage="secondary" variant="black" onClick={() => navigate(-1)}>
            Never Mind
          </ButtonHabitat>
          <ButtonHabitat id="cancelWithdrawal" variant="destructive" onClick={() => bankAccount && handleSubmit(bankAccount?.bank_account_uid, String(accountType))}>
            Yes, Delete Account
          </ButtonHabitat>
        </ActionButtonWrapper>
        {error && <small>{error}</small>}
      </StyledContent>
    </React.Fragment>
  );
};

export { DeleteBankAccountConfirmation };
