import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const Wrapper = (props) => (
  <QueryClientProvider client={queryClient}>
    <MemoryRouter {...props} />
  </QueryClientProvider>
);

export const ReactQueryWrapper = (props) => <QueryClientProvider client={queryClient} {...props} />;

export default Wrapper;
