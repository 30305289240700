/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { FC } from 'react';
import { QueryObserverResult } from 'react-query';
import {
  IClaimStaticFilterOptions,
  IFilterOption,
  IFilters,
  IFilterStatusTitle,
  IStaticFilterOptions
} from '../interfaces';
import { clearButtonCSS } from './index';
import StatusFilterCheckbox from './StatusFilterCheckbox';

const StatusFilterOptions: FC<{
  css?: any;
  query: () => QueryObserverResult<IClaimStaticFilterOptions | IStaticFilterOptions>;
  selectedStatuses: string[];
  setFilters: (args: Partial<IFilters>) => void;
  title: IFilterStatusTitle;
  topStatuses?: IFilterOption[];
}> = (props) => {
  const { query, selectedStatuses, setFilters, title, topStatuses = [], ...rest } = props;
  const { data } = query();

  const checkboxes = (opts) => {
    return (
      <div css={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1rem' }}>
        <StatusFilterCheckbox options={opts} selectedStatuses={selectedStatuses} setFilters={setFilters} />
      </div>
    );
  };

  const topOptions = topStatuses.length > 0 && data?.statuses && (
    <div css={{ marginBottom: '1.5rem', borderBottom: `1px solid ${PALETTE.neutral4}` }}>
      {checkboxes(
        topStatuses.filter((topStatus) => data.statuses.map((status) => status.value).includes(topStatus.value))
      )}
    </div>
  );
  const options = data?.statuses && (
    <div css={{ marginBottom: '1.5rem' }}>
      {checkboxes(
        data.statuses.filter((status) => !topStatuses.map((topStatus) => topStatus.value).includes(status.value))
      )}
    </div>
  );

  return data?.statuses ? (
    <div {...rest}>
      <div css={{ alignItems: 'baseline', display: 'flex', justifyContent: 'space-between' }}>
        <h5 css={[FONTS.h5, { color: PALETTE.neutral88 }]}>{title}</h5>
      </div>
      {topOptions}
      {options}
    </div>
  ) : null;
};

export default StatusFilterOptions;
