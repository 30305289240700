import React from 'react';
import { Radio, Checkbox, Typeahead, Switch } from '@sayrhino/rhino-shared-js';
import SwitchSelector from './coverageSelector';
import CreatableSelect from 'react-select/creatable';
import { Response } from './queryHooks';
import { PropertyOwnerSelect, PolicyHolderSelect, MultiplierPropertySelect, IntegrationSelect } from './select';
import ErrorNotification from './ErrorNotification';
import { PropertyManagerTypeahead } from './PropertyManagerTypeahead';
import { ListingAgentTypeahead } from './ListingAgentTypeahead';
import GooglePlaceIdTypeahead from './GooglePlaceIdTypeahead';
import { IStateOption } from '../../interfaces';
import useSinglePropertyUploadLogic from './SPULogic';
import { Coverage, property_types } from './types';
import {
  Button,
  CreateableComponents,
  FormView,
  RadioRoot,
  SelectorWrapper,
  StyledLine,
  Text,
  TextInput,
  Title
} from './Styles';
import { sanitizeCurrencyInput } from '../../utils';

type IProps = {
  setResponse: (res: Response) => void;
};

const Form: React.FC<IProps> = ({ setResponse }) => {
  const {
    property,
    handleChange,
    formErrors,
    validationError,
    tracker,
    setGooglePlaceId,
    setListingAgents,
    setPropertyManagers,
    setProperty,
    setValidationError,
    fullAddressEntered,
    googlePlaceId,
    propertyManagers,
    listingAgents,
    user,
    statesData,
    errors,
    isLoading,
    onSubmit,
    state,
    handleKeyDown,
    handleUnitChange,
    handleUnitInputChange,
    getError,
    clearMultiPropertyInput,
    convertToDecimalAndFormatUSD,
    cashDeposit,
    setCashDeposit
  } = useSinglePropertyUploadLogic(setResponse);

  return (
    <FormView>
      <Title>Property information</Title>
      <StyledLine />
      <TextInput
        id="name"
        label="Property Name"
        data-cy="singlePropertyName"
        value={property.building_name ?? ''}
        onChange={(event) => handleChange(event, 'building_name')}
        error={formErrors('building_name')}
        subtext={validationError['building_name'] ?? ''}
        onBlur={() => {
          if (Boolean(property.building_name)) {
            tracker('New Property - Property Name Entered');
          }
        }}
      />
      <PropertyOwnerSelect
        label="Property Management Company"
        data-cy="singlePropertyOwner"
        value={property.property_owner}
        onChange={(property_owner) => {
          setProperty((prev) => ({
            ...prev,
            property_owner
          }));
          setValidationError((prev) => ({ ...prev, property_owner_name: undefined }));
          tracker('New Property - Owner Name Entered');
        }}
        error={validationError['property_owner_name']}
      />
      <PolicyHolderSelect
        label="Property Legal Entity"
        data-cy="singlePropertyLegalEntity"
        value={property.policy_holder}
        onChange={(policy_holder) => {
          setProperty((prev) => ({
            ...prev,
            policy_holder
          }));
          setValidationError((prev) => ({ ...prev, policy_holder_name: undefined }));
          tracker('Legal Property Entity Entered');
        }}
        error={validationError['policy_holder_name']}
      />
      <TextInput
        id="street"
        label="Street address"
        data-cy="singlePropertyStreet"
        value={property.address_line_one ?? undefined}
        onChange={(event) => handleChange(event, 'address_line_one')}
        error={formErrors('address_line_one')}
        subtext={validationError['address_line_one'] ?? ''}
        onBlur={fullAddressEntered}
      />
      <TextInput
        id="city"
        label="City"
        data-cy="singlePropertyCity"
        value={property.address_city ?? ''}
        onChange={(event) => handleChange(event, 'address_city')}
        error={formErrors('address_city')}
        subtext={validationError['address_city'] ?? ''}
        onBlur={fullAddressEntered}
      />
      <Typeahead
        id="state"
        label="State"
        isSearchable={true}
        data-cy="singlePropertyState"
        placeholder={property.address_state === '' ? '-' : property.address_state}
        defaultValue={property.address_state ?? '-'}
        onChange={(selection: IStateOption) => handleChange(selection, 'address_state')}
        options={statesData?.states}
        subtext={validationError['address_state'] ?? ''}
        onBlur={fullAddressEntered}
        error={formErrors('address_state')}
      />
      <TextInput
        id="zip"
        label="ZIP Code"
        data-cy="singlePropertyZip"
        value={property.address_zip ?? ''}
        onChange={(event) => handleChange(event, 'address_zip')}
        error={formErrors('address_zip')}
        subtext={validationError['address_zip'] ?? ''}
        onBlur={fullAddressEntered}
      />
      {user.isAdmin && (
        <>
          <GooglePlaceIdTypeahead
            id="check mailing address line one"
            label="Google Place"
            googlePlaceId={googlePlaceId ?? ''}
            setGooglePlaceId={setGooglePlaceId}
          />
        </>
      )}
      <PropertyManagerTypeahead
        onChange={setPropertyManagers}
        selectedPM={propertyManagers}
        id="property-manager-typeahead"
      />
      <ListingAgentTypeahead onChange={setListingAgents} selectedPM={listingAgents} id="listing-agents-typeahead" />
      <Title>Property Type</Title>
      <StyledLine />
      <RadioRoot
        name="property_type"
        value={property.property_type}
        onValueChange={(value: property_types) => {
          setProperty((prev) => {
            if (value === property_types.MULTI && prev.property_type === property_types.SINGLE) {
              // sets input back to initial values, prevents "N/A" from getting added
              clearMultiPropertyInput();
            }
            return {
              ...prev,
              property_type: value
            };
          });
          tracker('Property Type Selected');
        }}
      >
        <Radio.Option data-cy="singlePropertyTypeSingle" value={property_types.SINGLE} label="Single-family home" />
        <Radio.Option data-cy="singlePropertyTypeMulti" value={property_types.MULTI} label="Multi-family home" />
        <Radio.Option data-cy="singlePropertyTypeGarden" value={property_types.GARDEN} label="Garden community" />
        <Radio.Option data-cy="singlePropertyTypeSAGC" value={property_types.SAGC} label="Single-address garden community" />
        <Radio.Option data-cy="singlePropertyTypeStudent" value={property_types.STUDENT} label="Student Housing" />
      </RadioRoot>
      {property?.property_type === property_types.MULTI || property?.property_type === property_types.SAGC ? (
        <>
          <Title>Property Units</Title>
          <StyledLine />
          <CreatableSelect
            inputValue={state.inputValue}
            components={CreateableComponents}
            isMulti
            onKeyDown={handleKeyDown}
            menuIsOpen={false}
            onChange={handleUnitChange}
            onInputChange={handleUnitInputChange}
            placeholder="Type the unit name and press enter..."
            value={state.value}
            error={validationError['units']}
          />
          {formErrors('units') && <ErrorNotification errors={[getError('units')]} />}
        </>
      ) : (
        <>
          <Title>Property Units (only for multi-family home or single-address garden community)</Title>
          <StyledLine />
          <TextInput id="unit input" disabled={true} placeholder={'N/A'} />
        </>
      )}
      {user.isAdmin && (
        <>
          <Title>Additional Details</Title>
          <StyledLine />
          <Checkbox
            id="Multi_Policy_Holder"
            label="Multi Policy Holder"
            checked={Boolean(property?.multi_policy_holder)}
            onCheckedChange={(value) =>
              setProperty((prev) => ({
                ...prev,
                multi_policy_holder: value
              }))
            }
          />
        </>
      )}
      <Title>Coverage Type</Title>
      <StyledLine />
      <Text>
        Please provide the standard security deposit amount for your property, either as a multiplier or a dollar
        amount.
      </Text>
      <SelectorWrapper>
        <SwitchSelector
          active={property.coverage_details?.type ?? Coverage.MULTIPLIER}
          list={[
            { label: 'Multiplier', value: Coverage.MULTIPLIER },
            { label: 'Dollar amount', value: Coverage.AMOUNT }
          ]}
          id="coverage_type"
          onClick={(value) =>
            setProperty((prev) => ({
              ...prev,
              coverage_details: {
                value: 0,
                type: value
              }
            }))
          }
        />
      </SelectorWrapper>
      {property.coverage_details.type === Coverage.AMOUNT && (
        <>
          <TextInput
            id="Dollar amount"
            label="Dollar amount"
            data-cy="singlePropertyCoverageDollarAmount"
            placeholder="e.g. $1,500"
            value={convertToDecimalAndFormatUSD(property.coverage_details?.value)}
            onChange={(e) =>
              setProperty((prev) => ({
                ...prev,
                coverage_details: {
                  ...prev?.coverage_details,
                  value: sanitizeCurrencyInput(e)
                }
              }))
            }
            min="0"
            onInput={(event) => handleChange(event, 'coverage_details_dollar_amount')}
            error={validationError['coverage_details_value']}
            subtext={validationError['coverage_details_value']}
            onBlur={() => {
              if (Boolean(property.coverage_details?.value)) {
                tracker('New property - Coverage Amount Entered');
              }
            }}
          />
        </>
      )}
      {property.coverage_details?.type === Coverage.MULTIPLIER && (
        <MultiplierPropertySelect
          id={property.property_owner?.id ?? null}
          data-cy="singlePropertyCoverageMultiplier"
          errorPresent={formErrors('coverage_details_value')}
          subtext={
            validationError['coverage_details_value'] &&
            validationError['coverage_details_value'] !== 'must be greater than $100'
              ? validationError['coverage_details_value']
              : ''
          }
          value={property.coverage_details}
          label="Multiplier"
          onChange={(mulp) => {
            setProperty((prev) => ({
              ...prev,
              coverage_details: {
                ...prev?.coverage_details,
                ...mulp
              }
            }));
            setValidationError((prev) => ({ ...prev, coverage_details_value: undefined }));
            if (Boolean(property.coverage_details?.value)) {
              tracker('New property - Coverage Amount Entered');
            }
          }}
        />
      )}
      {user.isAdmin && (
        <>
          <IntegrationSelect
            label="Integration Type"
            value={property?.integration_type}
            onChange={(intType) =>
              setProperty((prev) => ({
                ...prev,
                integration_type: intType?.value ?? 'no_integration'
              }))
            }
            error={validationError['integration_type']}
          />
          <TextInput
            id="integration_code"
            label="Integration Code"
            data-cy="integrationCode"
            value={property?.integration_code}
            onChange={(event) => handleChange(event, 'integration_code')}
            error={formErrors('integration_code')}
            subtext={validationError['integration_code'] ?? ''}
          />
        </>
      )}
      {user.isAdmin && (
        <div>
          <label htmlFor="site-enabled" style={{ paddingRight: 15 }}>
            Cash Deposits Enabled
          </label>
          <Switch
            id="site-enabled"
            checked={cashDeposit}
            onCheckedChange={() => {
              setCashDeposit(!cashDeposit);
            }}
          />
        </div>
      )}
      {(errors as string[]).length > 0 && <ErrorNotification errors={errors as string[]} />}
      <Button
        children={isLoading ? 'Loading' : 'Add property'}
        data-cy="singlePropertySubmit"
        variant="tertiary"
        type="submit"
        disabled={isLoading}
        onClick={onSubmit}
      />
    </FormView>
  );
};

export default Form;
