/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

const PaperPlane = (props) => (
  <svg width="109" height="49" viewBox="0 0 112 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M1.33203 24.7057L110.714 0.586914L59.7246 38.1258L1.33203 24.7057Z"
        fill="white"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M48.6286 35.7211C48.6286 35.7211 27.6531 49.0474 27.583 48.8362C27.5129 48.6251 31.3749 31.6094 31.3749 31.6094L48.6286 35.7211Z"
        fill="#B6AEFF"
      />
      <path d="M23.6456 30.0026L1.33203 24.7061" stroke="black" strokeMiterlimit="10" />
      <path d="M59.7251 38.1259L31.3701 31.6094" stroke="black" strokeMiterlimit="10" />
      <path d="M31.3695 31.6093L27.5483 49.0004L22.9033 29.662L110.714 0.586914L31.3695 31.6093Z" fill="#6318CE" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="112" height="49" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PaperPlane;
