import axios from 'axios';
import { IRealPageIntegration, IYardiIntegration } from 'components/v2/App/interfaces';
import { QueryObserverResult, useQuery } from 'react-query';

interface Response {
  integration: IRealPageIntegration | IYardiIntegration;
}

export const PARTNER_INTEGRATION_KEY = 'partner integration';

const getPartnerIntegration = async (id: string | null | undefined) => {
  const { data } = await axios.get<Response>(`/v2/integrations/partners/${id}`);
  return data;
};

export default function usePartnerIntegration(
  id: string | null | undefined
): QueryObserverResult<Response> | undefined {
  return useQuery<Response>([PARTNER_INTEGRATION_KEY, id], () => getPartnerIntegration(id), { enabled: !!id });
}
