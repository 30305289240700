import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IClaimSummaryMetricsData } from 'components/v2/App/interfaces';

const getClaimSummaryMetrics = async (): Promise<IClaimSummaryMetricsData> => {
  const { data }: AxiosResponse<IClaimSummaryMetricsData> = await axios.get<IClaimSummaryMetricsData>(
    `/v2/admin/claims/summary_metrics`
  );
  return data;
};

export function useClaimSummaryMetrics(): QueryObserverResult<IClaimSummaryMetricsData> {
  return useQuery<IClaimSummaryMetricsData>('insurancePolicySummaryMetrics', getClaimSummaryMetrics);
}
