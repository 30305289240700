import axios from 'axios';
import { csrfToken } from 'utils/document';
import { IInsurancePolicy, InsurancePolicyEditPOST } from 'components/v2/App/interfaces';

const mapToUpdatePolicy = (policy: Partial<IInsurancePolicy>): Partial<InsurancePolicyEditPOST> => {
  const { lease_end_date, lease_start_date, unit_id, coverage_amount_cents, property_id } = policy;
  return {
    unit_id,
    property_id,
    lease_end_date,
    lease_start_date,
    coverage_amount_cents
  };
};

const updatePolicy = async (policy: Partial<IInsurancePolicy>) => {
  const authenticity_token = csrfToken();
  const renter_policy = mapToUpdatePolicy(policy);

  const { data } = await axios.put<InsurancePolicyEditPOST>(
    `/admin/renter_policies/${policy.id}`,
    { renter_policy, authenticity_token },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return data;
};

export default updatePolicy;
