/** @jsx jsx */
import React from 'react';
import { SearchIcon, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

type Props = JSX.IntrinsicElements['input'];

const StyledInput = styled.input([
  FONTS.p1,
  {
    position: 'relative',
    padding: '16px 0 15px 48px',
    border: 'none',
    background: PALETTE.brand4,
    minWidth: '100%',
    '&:focus': {
      background: PALETTE.neutralLight,
      zIndex: 2
    }
  }
]);
const InputWrapper = styled.div({
  width: '100%',
  height: '60px',
  display: 'block',
  borderBottom: `1px solid ${PALETTE.neutral12}`
});

const StyledSearchIcon = styled(SearchIcon)({
  height: '20px',
  width: '20px',
  color: PALETTE.neutral65,
  position: 'relative',
  bottom: 40,
  right: '7%',
  float: 'right'
});
const HiddenLabel = styled.label({
  border: '0',
  clip: ' rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
  width: '1px'
});

const SearchInput = React.forwardRef((props: Props, ref: React.RefObject<HTMLInputElement>) => (
  <InputWrapper>
    <HiddenLabel htmlFor="global_search">{props.id || 'Global Search Bar'}</HiddenLabel>
    <StyledInput ref={ref} id="global_search" placeholder="Search" {...props} />
    <StyledSearchIcon />
  </InputWrapper>
));

export default SearchInput;
