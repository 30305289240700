/** @jsx jsx */
import React, { Fragment } from 'react';

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import { Loading, PALETTE } from '@sayrhino/rhino-shared-js';
import makeSummaryMetricsContainer from './makeSummaryMetricsContainer';
import DashboardPanelPill from './DashboardPanelPill';
import { useUserRole } from './utils/userRole';

import { OPEN_WITHDRAWALS_TITLE } from './utils/constants';
import DashboardPanelWell from './DashboardPanelWell';
import DashboardPanelTitle from './DashboardPanelTitle';
import ProgressMeter from './ProgressMeter';
import { FigureList, FigureListItem } from './FigureList';
import { formatMoney } from 'utils/money';
import { Splitter } from './PropertyOwners/Styled';

import { LoadingWrapper, StyledEditPaymentDiv, StyledEditPaymentLink } from './Claims/ClaimDetails/ClaimStyles';
import useFundsBankAccounts from 'api/v2/useFundsBankAccounts';
import { IBankAccountInfo, IWithdrawalAttributes } from './DepositFunds/types';
import useWithdrawals from 'api/v2/useWithdrawals';
import useUserAccountBalance from 'api/v2/useUserAccountBalance';
import useDepositFundsListWrapper from '../../../api/v2/useDepositFundsListWrapper';

interface EmptyProps {
  text: string;
}

const dotCss = css({
  width: 8,
  height: 8,
  borderRadius: 8,
  display: 'inline-block'
});

const greenDotCss = css({
  backgroundColor: '#65e2c1'
});

const grayDotCss = css({
  backgroundColor: PALETTE.neutral4
});

export const StyledListItem = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const StyledListItemActionDelete = styled(Link)({
  color: PALETTE.alert125,
  cursor: 'pointer',
  paddingLeft: '0.2rem',
  fontWeight: 600
});

const StyledListItemActions = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
});

export const ListItem = ({ children }) => <StyledListItem>{children}</StyledListItem>;

const BankAccountListItem: React.FC<
  Pick<IBankAccountInfo, 'bank_account_uid' | 'nickname' | 'account_number' | 'internal_account_type'>
> = ({ nickname, account_number, bank_account_uid, internal_account_type: account_type }) => {
  return (
    <React.Fragment>
      <StyledListItem>
        <span>
          {nickname}, {account_number}
        </span>
        <StyledListItemActions>
          <Link
            css={{ color: PALETTE.interaction100, cursor: 'pointer', paddingRight: '0.2rem', fontWeight: 600 }}
            to={`accounts/${bank_account_uid}/edit?accountType=${account_type}`}
          >
            Edit
          </Link>
          <StyledListItemActionDelete to={`accounts/${bank_account_uid}/delete?accountType=${account_type}`}>
            Delete
          </StyledListItemActionDelete>
        </StyledListItemActions>
      </StyledListItem>

      <Splitter />
    </React.Fragment>
  );
};
const DepositFundsSummaryMetrics = makeSummaryMetricsContainer('Funds overview', () => {
  const {
    data,
    isLoading: isLoadingDepositFundsListWrapper,
    isFetching: isFetchingDepositFundsListWrapper
  } = useDepositFundsListWrapper();
  const { propertyOwnerId } = data || { propertyOwnerId: null };
  const { isThirdPartyAdmin, isPropertyManagerAdmin } = useUserRole();
  const {
    data: accountBalance,
    isLoading: isLoadingAccountBalance,
    isFetching: isFetchingUserAccountBalance
  } = useUserAccountBalance({ enabled: !!propertyOwnerId });
  const {
    data: accountsData,
    isLoading: isBankAccountsLoading,
    isSuccess: isAccountsSuccess,
    isFetching: isFetchingFundsBankAccounts
  } = useFundsBankAccounts({ enabled: !!propertyOwnerId });
  const bankAccountsData = accountsData?.data || [];
  const hasBankAccounts = isAccountsSuccess && bankAccountsData.length > 0;
  const { data: withdrawalsData, isFetching: isFetchingWithdrawals } = useWithdrawals({ enabled: !!propertyOwnerId });
  const withdrawals = withdrawalsData?.data || [];

  const pendingWithdrawals = React.useMemo(
    () => withdrawals.filter((withdrawal: IWithdrawalAttributes) => withdrawal.attributes.state === 'pending'),
    [withdrawals]
  );
  const totalAvailableToWithdraw = React.useMemo(
    () => accountBalance?.free_balance_in_cents ?? 0,
    [accountBalance, withdrawalsData]
  );
  const totalWithheldFunds = React.useMemo(
    () => accountBalance?.total_balance_in_cents ?? 0,
    [accountBalance, withdrawalsData]
  );

  if (isLoadingDepositFundsListWrapper || isThirdPartyAdmin || !propertyOwnerId) return null;

  const Empty: React.FC<EmptyProps> = ({ text }) => (
    <React.Fragment>
      <Splitter />
      <StyledListItem>{text}</StyledListItem>
      <Splitter />
    </React.Fragment>
  );

  if (isBankAccountsLoading || isLoadingAccountBalance)
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );

  const isFetchingAny =
    isFetchingDepositFundsListWrapper ||
    isFetchingFundsBankAccounts ||
    isFetchingWithdrawals ||
    isFetchingUserAccountBalance;

  return (
    <React.Fragment>
      <DashboardPanelPill title={OPEN_WITHDRAWALS_TITLE} value={pendingWithdrawals.length} />
      {isPropertyManagerAdmin && (
        <DashboardPanelWell>
          <DashboardPanelTitle>Funds available to withdraw</DashboardPanelTitle>

          <div css={{ marginBottom: 24 }}>
            <ProgressMeter value={totalAvailableToWithdraw / totalWithheldFunds} />
          </div>

          <FigureList css={{ marginTop: 24 }}>
            {accountBalance ? (
              <React.Fragment>
                <FigureListItem
                  label="Total available to withdraw"
                  value={formatMoney(totalAvailableToWithdraw).maskedValue}
                  icon={<div css={[dotCss, greenDotCss]} />}
                />
                <FigureListItem
                  label="Total deposit amounts"
                  value={formatMoney(totalWithheldFunds).maskedValue}
                  icon={<div css={[dotCss, grayDotCss]} />}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Splitter />
                <StyledListItem>There are no deposit funds in your acount</StyledListItem>
                <Splitter />
              </React.Fragment>
            )}
          </FigureList>
        </DashboardPanelWell>
      )}
      {isPropertyManagerAdmin && (
        <DashboardPanelWell>
          <DashboardPanelTitle>Bank Accounts</DashboardPanelTitle>
          <Fragment>
            {hasBankAccounts ? (
              <Fragment>
                {bankAccountsData &&
                  bankAccountsData?.map((account) => (
                    <BankAccountListItem
                      key={account.bank_account_uid}
                      bank_account_uid={account.bank_account_uid}
                      nickname={account.nickname}
                      account_number={account.account_number}
                      internal_account_type={account.internal_account_type}
                    />
                  ))}
              </Fragment>
            ) : (
              <Empty text="You don't have any bank accounts yet" />
            )}
          </Fragment>

          <StyledEditPaymentDiv>
            <StyledEditPaymentLink to="/admin/funds/accounts/new">Add a new bank account</StyledEditPaymentLink>
          </StyledEditPaymentDiv>
        </DashboardPanelWell>
      )}
      {isFetchingAny && (
        <div
          css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: '8px' }}
        >
          <Loading />
        </div>
      )}
    </React.Fragment>
  );
});

export default DepositFundsSummaryMetrics;
