/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import { PALETTE, FONTS, RightIcon } from '@sayrhino/rhino-shared-js';
import { Link } from 'react-router-dom';
import DashboardPanelRule from './DashboardPanelRule';
import DashboardPanelValue from './DashboardPanelValue';

interface IProps {
  description: string;
  forceRefresh?: boolean;
  to: string;
  value: number;
  valueLabel: string;
}

const DashboardPanelLinkFigure: FC<IProps> = ({ value, valueLabel, description, to, forceRefresh }: IProps) => {
  const children = (
    <span>
      <DashboardPanelValue>{value}</DashboardPanelValue>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '2rem'
        }}
      >
        <span
          css={[
            {
              display: 'block',
              paddingRight: '1rem'
            },
            FONTS.h4
          ]}
        >
          {valueLabel}
        </span>
        <RightIcon style={{ color: PALETTE.neutralDark }} />
      </div>
      <DashboardPanelRule />
      <p css={FONTS.p2}>{description}</p>
    </span>
  );

  return (
    <li
      css={{
        border: `1px solid ${PALETTE.neutral12}`,
        borderRadius: '24px',
        marginBottom: '1.75rem',
        padding: '2rem 3rem',
        '&:hover': {
          boxShadow: `0px 4px 16px 0px ${PALETTE.neutral12}`,
          border: `1px solid ${PALETTE.neutralLight}`
        }
      }}
    >
      {forceRefresh ? (
        <a href={to} children={children} aria-label={valueLabel} />
      ) : (
        <Link to={to} children={children} aria-label={valueLabel} />
      )}
    </li>
  );
};

export default DashboardPanelLinkFigure;
