import ApplicationTable from './application_table';
import { DeleteColumn, EditColumn } from './utils';

class PolicyHolderTable extends ApplicationTable {
  public pageLength = 100;
  public selector = '#policy-holder-table';
  public emptyMessageText = 'You have no policy holders to view';
  public pathPrefix = 'policy_holders/';

  protected columns = [
    {data: 'id'},
    {data: 'name'},
    EditColumn,
    DeleteColumn
  ];
  protected columnDefs = [
    {
      targets: [ 2, 3],
      data: undefined,
      defaultContent: ''
    }
  ];
}

export default PolicyHolderTable;
