import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation, MutationFunction } from 'react-query';
import { IUpdateWithdrawalResponse, IWithdrawal, IWithdrawalInfo } from 'components/v2/App/DepositFunds/types';


const updateWithdrawal: MutationFunction<AxiosResponse<IUpdateWithdrawalResponse>, IWithdrawalInfo> = async (
  withdrawal: Partial<IWithdrawal>
): Promise<AxiosResponse<IUpdateWithdrawalResponse>> => {
  const authenticity_token = csrfToken();

  const response: AxiosResponse<IUpdateWithdrawalResponse> = await axios.patch(
    `/admin/funds/withdrawals/${withdrawal.withdraw_uid}`,
    {
      withdrawal: {
        withdraw_uid: withdrawal.withdraw_uid,
        user_bank_account_uid: withdrawal.user_bank_account_uid,
        user_bank_account_type: withdrawal.user_bank_account_type,
        amount_in_cents: withdrawal.amount_in_cents
      }
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useUpdateWithdrawalMutation() {
  return useMutation(updateWithdrawal);
}
