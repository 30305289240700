// Util methods to interact with localStorage. Feel free to add on any extra helper methods.

/**
 * Writes key/value pair to client localStorage.
 * @param key
 * @param value
 */
export const setLocal = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

/**
 * Reads localStorage and returns the value associated to the given key
 * @param key
 * @returns value associated to key, null if no key exists
 */
export const getLocal = (key: string): string | null => {
  return localStorage.getItem(key);
};

/**
 * Removes key/value pair from client localStorage
 * @param key key to remove
 */
export const removeLocal = (key: string) => {
  localStorage.removeItem(key);
};
