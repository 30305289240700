type EnvVarName = 'APP_ENV' | 'GOOGLE_API_KEY';

interface IApp {
  env: {
    readonly [P in EnvVarName]?: string;
  };
  featureFlags?: Record<any, any>;
  download: any;
  cable: any;
}

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    App: IApp;
  }
}

const env = (name: EnvVarName): string => window.App.env[name] || '';

export default env;
