import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Loading, MinimizeIcon } from '@sayrhino/rhino-shared-js';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  StyledCell,
  StyledColumnHeader,
  StyledHeaderRow,
  StyledRow,
  StyledTableContainer,
  StyledTableFrame,
  StyledTableHeader,
  StyledTableTitle,
  StyledAddNewRowLink,
  StyledReferrerLink,
  ClaimDetailViewWrapper,
  ClaimDetailWrapper
} from './Claims/ClaimDetails/ClaimStyles';
import { Pagination } from './Filters/Pagination';
import useOverlay from './utils/overlay';
type Pagination = {
  current_page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
}

const StyledRegenButton = styled(Button)({
  float: 'right'
});

type DocumentRevision = {
  id: number;
  type_name: string;
  valid_from: string;
  valid_to: string | null;
  data: {};
  document_url: string;
};

type DocumentRevisions = {
  data: DocumentRevision [];
  meta: Pagination;
};

const tableColumnTitles = ["Document Type", "Valid From", "Valid To"];
const itemsPerPage = 10;

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
  return formattedDate;
};

const DocumentRevisionsList: React.FC = () => {
  const { policyId } = useParams();
  const [revisions, setRevisions] = useState<DocumentRevisions>({data: [], meta: {current_page: 1, per_page: 10, total_count: 0, total_pages: 0}});
  const [currentPage, setCurrentPage] = useState(1);
  const errorMessage = "Something went wrong attempting to load the policy document history";
  const noContentMessage = "There is no document history for this policy";
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [regenQueued, setRegenQueued] = useState(false);
  const { setShowOverlay } = useOverlay();
  const rp_url = "/v2/admin/renter_policies/";

  const handleRegenClick = async () => {
    try {
      const response = await axios.get(`${rp_url}${policyId}/document_revisions/regenerate`);
      setRegenQueued(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      setIsLoading(true);
      setHasError(false);
      setShowOverlay(true);

      try {
      const { data } = await axios.get(`${rp_url}${policyId}/document_revisions?page=${currentPage}&per_page=${itemsPerPage}`);
      if (!ignore) {
        setRevisions(data);
      }
      } catch {
        setHasError(true);
      }
      setIsLoading(false);
    };
    fetchData();

    return () => {
      ignore = true;
      setShowOverlay(false);
    };

  }, [setRevisions, policyId, currentPage]);


  return (
    <>
      <ClaimDetailWrapper>
          <ClaimDetailViewWrapper>
            <StyledReferrerLink to={`/admin/renter_policies/${policyId}`} aria-label="collapse">
              <MinimizeIcon />
            </StyledReferrerLink>
            <h3>Policy Document History</h3>
          </ClaimDetailViewWrapper>
      </ClaimDetailWrapper>
      <StyledTableContainer>
      { hasError ? <div><h3>{errorMessage}</h3></div>
      : isLoading ? <div><Loading /></div> :
          <>
            <StyledTableHeader>
                { regenQueued ? 
                  <p>Document regeneration started. If there are any changes, new documents will be created in a few minutes.</p> :
                  <StyledRegenButton
                    onClick={handleRegenClick}
                    type="submit"
                  >
                    Regenerate Documents
                  </StyledRegenButton>
                }
                <StyledTableTitle>Document Revisions for Policy {policyId}</StyledTableTitle>
              </StyledTableHeader>
              <StyledTableFrame>
                {revisions?.data?.length !== 0 ? (
                  <>
                    <tbody>
                      <StyledHeaderRow>
                        {tableColumnTitles.map((columnTitle) => (
                          <StyledColumnHeader key={columnTitle}>{columnTitle}</StyledColumnHeader>
                        ))}
                      </StyledHeaderRow>
                      {revisions?.data?.map((revision) => (
                          <StyledRow key={revision.id}>
                            <StyledAddNewRowLink href={revision.document_url} target="_blank" >
                              {revision.type_name}
                            </StyledAddNewRowLink>
                            <StyledCell>{formatDate(revision.valid_from)}</StyledCell>
                            <StyledCell>{revision.valid_to ? formatDate(revision.valid_to) : 'Current'}</StyledCell>
                          </StyledRow>
                        ))}
                    </tbody>
                    <div>
                </div>
                <Pagination
                  currentPageCount={revisions?.meta?.current_page}
                  nextPage={currentPage}
                  page={currentPage}
                  per={revisions?.meta?.per_page}
                  prevPage={currentPage}
                  setPage={setCurrentPage}
                  totalCount={revisions?.meta?.total_count}
                  totalPages={revisions?.meta?.total_pages}
                />
                  </>
                ) : (
                  <div>
                    <p>{noContentMessage}</p>
                  </div>
                )}
              </StyledTableFrame>
          </>
          }
      </StyledTableContainer>
    </>
  );
};

export default DocumentRevisionsList;
