import { IVariant, CoverageType, ICoverageRule } from '../interfaces';
import { format, isValid, sub, isAfter, parseISO } from 'date-fns';
import Decimal from 'decimal.js';
import { ParsedQuery } from 'query-string';
import { matchPath } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { AddCoverageTypeEnum } from '../Integrations/util/rulesetConstant';
import type { BreakpointValue } from './constants';

export type DateTuple = [Date, Date];

export const getTagVariant = (status: string): IVariant => {
  switch (status) {
    case 'active':
    case 'upcoming':
    case 'Subscribed':
      return 'default';
    case 'expired':
    case 'canceled':
    case 'withdrawn':
    case 'paid':
    case 'denied':
    case 'Collections - Paid':
    case 'Collections - Unpaid':
    case 'Closed - Unpaid':
    case 'Subrogation - Paid':
      return 'disabled';
    case 'incomplete':
    case 'Sent':
    case 'In Progress':
    case 'accepted':
    case 'processing':
      return 'pending';
    case 'declined':
      return 'default';
    default:
      break;
  }
};

/**
 * Formats phone number as user inputs
 * @param value
 * @returns formatted phone number
 */
export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

/**
 * To be used with dates returned from our endpoint
 * and will create a default ISO date or the current day
 * instead of an InvalidDate object
 * @param dateISO - date as ISO 8601
 */
export const initializeDate = (dateISO?: string): Date => {
  return dateISO === undefined ? new Date() : parseISO(dateISO);
};

export const stringifyDateRange = (dates: DateTuple): string => {
  const [startDate, endDate] = dates;
  return `${format(startDate, 'LLL d, u')} ${isValid(endDate) ? '— ' + format(endDate, 'LLL d, u') : ''}`;
};

/**
 * Converts cents to dollars
 * @param cents - US currency converter - Converts cents (¢) to USD ($)
 *
 * @returns Dollar amount as a number
 */
export const centsToUSD = (cents: number): number => cents / 100;

/**
 *
 * @param coverageAmountCents - coverage amount in cents
 * @param monthlyRentCents - monthly rent in cents
 *
 * @return A whole number multiplier for the coverage amount
 */
export const calculateCoverageMultiplier = (coverageAmountCents: number, monthlyRentCents): number => {
  return Math.round(coverageAmountCents / monthlyRentCents);
};

/**
 *
 * @param coverageOption - Coverage options of pilicy. One of PROPERTY_DEFAULT, DOLLAR_AMOUNT, or MULTIPLIER
 * @param propertyDefaultCoverageCents - Default coverage amount of property - is always the monthly rent as of 11/2020
 * @param customCoverageCents - Custom coverage amount entered in form
 * @param multiplier - The option to increase coverage in multiples of the monthly rent. 1 - 6 in a majority of uses
 */
export const calculateCoverageAmount = (
  coverageOption: CoverageType,
  propertyDefaultCoverageCents: number,
  customCoverageCents: number,
  multiplier: number
): number => {
  switch (coverageOption) {
    case 'PROPERTY_DEFAULT':
      return propertyDefaultCoverageCents;
    case 'DOLLAR_AMOUNT':
      return customCoverageCents;
    case 'MULTIPLIER':
      return multiplier * propertyDefaultCoverageCents;
    case 'RP_DEPOSIT_CHARGES':
      return 0;
    case 'NO_COVERAGE':
      return 0;
  }
};

/**
 * Handles pluralization of the string 'day'
 * @param value - number of days
 *
 * @returns string 'day' in singular or plural form
 */
export const formatDays = (value: number | null): string => `${value} ${value === 1 ? 'day' : 'days'}`;

/**
 * Formats Date to a string with short month, short day, and year
 * @param date - Date object
 *
 * @returns formatted date string, e.g. "Jan 1, 2020"
 */
export const stringifyDate = (date: Date): string => format(date, 'LLL d, u');

/**
 * Formats Date to a string with short month, short day, and year
 * @param date - Date object
 *
 * @returns formatted date string, e.g. "01/01/2020"
 */
export const stringifyDateWithSlashes = (date: Date): string => format(date, 'MM/dd/yyyy');

/**
 * Formats Date to a string with short month and year
 * @param date - Date object
 *
 * @returns formatted month string, e.g. "Jan 2020"
 */
export const stringifyMonth = (date: Date): string => format(date, 'LLL u');

/**
 * Formats Date to a string with long month, short day, and year
 * @param date - Date object
 *
 * @returns formatted date string, e.g. "January 1, 2020"
 */
export const stringifyDateLong = (date: Date): string => format(date, 'LLLL d, u');

export const numberToUSDFormatter = (n: number | string): string => {
  const godForgiveMe: any = n;
  return new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(godForgiveMe);
};

export function centsToUSDFormatter(n: Decimal): string {
  return numberToUSDFormatter(n.div(100).toString());
}

export const coverageValue = (coverageType: string, coverageValue: number) => {
  const dollarAmount = coverageType === AddCoverageTypeEnum.DOLLAR_AMOUNT;
  const value = coverageValue;
  if (!dollarAmount) return value + 'X'; // when it's multiplier
  return value ? centsToUSDFormatter(new Decimal(value)) : '';
};

export function ratioToPercentageFormatter(ratio: number): string {
  const percentage = Math.floor(ratio * 100);
  return `${percentage}%`;
}

/**
 * Converts a Decimal number to a USD Formatted string
 * @param dollarValue - dollar amount as Decimal
 *
 * @returns USD Formatted String ex: $x.xx
 */
export function toUSDFormatter(dollarValue: Decimal): string {
  return numberToUSDFormatter(dollarValue.toString());
}

export const sanitizeCurrencyInput = (e: React.ChangeEvent<HTMLInputElement>): number =>
  Number(e?.target?.value.replace(/\D/g, ''));

export const sanitizeCurrencyString = (value: string): number => Number(value.replace(/\D/g, ''));

/**
 * Converts user input into a dollar amount decimal
 * ex: $3.21 will return a Decimal initialized with '3.21'
 * @param e - The event object from a currency input
 *
 * @returns The parsed dollar value as a a decimal
 */
export const parseDollars = (e: React.ChangeEvent<HTMLInputElement>): Decimal =>
  new Decimal(e?.target?.value.replace(/[D$]/g, ''));

export const parseIntDecimal = (rawInput: string): number => parseInt(rawInput, 10);

export const inputEventToCents = (e: React.ChangeEvent<HTMLInputElement>) => sanitizeCurrencyInput(e) / 100;

export const isWithin14days = (date: Date): boolean => {
  const today = new Date();
  return isAfter(date, sub(today, { days: 14 }));
};

export const stripTrailingZeroMultiplier = (num: number): string => {
  return num.toString().replace(/\.?0+$/, '') + 'x';
};

type CurrencyFormats = {
  value: string;
  formatted: string;
  rawNumber: Decimal;
  decimal: Decimal;
};

/**
 * A catch all function for handling currency formatting
 * built on other utils (sanitizeCurrencyInput, parseDollars
 * & centsToUSDFormatter)
 * @param e - input event from currency inputs
 * @returns {
 *   value: direct input target value passed through
 *   formatted: currency formatted to USD
 *   rawNumber: only numerical characters, as Decimal
 *   decimal: parsed dollar value, as Decimal
 * }
 */
export const handleCurrencyInput = (e: React.ChangeEvent<HTMLInputElement>): CurrencyFormats => {
  const rawNumber = new Decimal(sanitizeCurrencyInput(e));
  const dollarValue = new Decimal(parseDollars(e));

  return {
    value: e.target.value,
    formatted: centsToUSDFormatter(rawNumber),
    rawNumber,
    decimal: dollarValue
  };
};

interface IParsedQueryDefaults<T> {
  [key: string]: T | T[];
}

export function fromParsedQuery<T = string>(
  query: ParsedQuery<T>,
  defaults: IParsedQueryDefaults<T>
): IParsedQueryDefaults<T> {
  const result = {};

  for (let key in query) {
    const qval = query[key];
    const dval = defaults[key];
    if (qval !== undefined && query[key] !== null) {
      result[key] = qval;
    } else {
      result[key] = dval;
    }
  }

  return result;
}

export function useReferrer(): string | undefined {
  const location = useLocation();
  const queryString = qs.parse(location.search);
  if (typeof queryString.referrer === 'string') {
    return queryString.referrer;
  }
}

export function useStickiedRoutes() {
  const referrerUrl = useReferrer();
  const referrer = referrerUrl ? qs.parseUrl(referrerUrl).url : undefined;
  const search = referrer !== undefined && matchPath('/search', referrer) !== null;
  const dashboard = referrer !== undefined && search === false && matchPath('/', referrer) !== null;
  const any = search || dashboard;

  return {
    search,
    dashboard,
    any
  };
}

export function urlWithReferrer(url: string, referrer?: string): string {
  const query = referrer !== undefined ? { referrer } : {};
  return qs.stringifyUrl({ url, query });
}

export function redirectTo(path: string) {
  window.location.href = path;
}

export function toCurrencyFormat(str: string | number) {
  return str.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export function getInitialStr(str: string) {
  if (!str) {
    return '';
  }
  let splitNames = str.split(' ');
  if (splitNames.length === 1) {
    return str.toUpperCase().slice(0, 2);
  }
  splitNames = splitNames.slice(0, 2);
  return splitNames.reduce((total, cur) => {
    cur = cur.slice(0, 1);
    cur = cur.toUpperCase();
    return `${total}${cur}`;
  }, '');
}

export function addYears(date: Date, years: number) {
  date.setFullYear(date.getFullYear() + years);
  return date;
}

export function firstOfMonth() {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
}

export function mediaQueryWidth({
  value,
  max,
  min,
  exact
}: {
  value: BreakpointValue;
  max?: boolean;
  min?: boolean;
  exact?: boolean;
}) {
  if ([min, max, exact].filter((x) => x).length > 1) throw new Error('Only one of min, max, or exact can be true');
  if (exact) `@media (width: ${value}px)`;
  if (min) return `@media (min-width: ${value}px)`;
  return `@media (max-width: ${value}px)`;
}
