import { IData, ISearchResult } from 'components/v2/App/interfaces';

export const mockSearchResults: IData<ISearchResult[]> = {
  meta: {
    count: 20,
    total_count: 20,
    total_pages: 1,
    next_page: null,
    prev_page: null,
    page: 1,
    per: 20
  },
  data: [
    {
      id: 1,
      type: 'Claim',
      attributes: {
        amount_cents: 100,
        claim_number: '000000104',
        claim_type: 'Basic',
        property_name: 'The Steve',
        property_address: 'Florida',
        remaining_amount_cents: 100,
        status: 'lol',
        renter_full_name: 'Steve',
        id: 104,
        created_at: '2021-02-17T00:04:37.177Z'
      }
    },
    {
      id: 2,
      type: 'InsurancePolicy',
      attributes: {
        coverage_amount: '1.00',
        coverage_dates: '1/1/2020',
        policy_number: '123ABC',
        property_name: 'Stevehaus',
        property_state: 'FL',
        remaining_coverage_amount_cents: 1000,
        status: 'active',
        tenant_full_name: 'Steve Stevenson',
        unit_name: '123',
        pending_changeset: {
          property_id: null,
          unit_id: null,
          building_name: null,
          unit_name: null,
          monthly_rent_cents: null,
          monthly_rent: null,
          lease_start_date: null,
          lease_end_date: null,
          coverage_option_selected: null,
          coverage_option_value: null,
          custom_coverage_amount_cents: null,
          custom_flat_coverage: null,
          status: 'test',
          needs_tenant_approval: true,
          full_address: null
        },
        lease_start_date: '11/20/2020',
        lease_end_date: '11/20/2021',
        status_detail: {
          severity: 'success',
          label: 'lol'
        }
      }
    },
    {
      type: 'Invitation',
      id: 7835,
      attributes: {
        address_state: 'WA',
        address_unit: 'A',
        building_name: 'Fir Vista',
        coverage_amount: '$1,000',
        coverage_dates: 'Oct 02, 2019 - May 01, 2020',
        coverage_months: '0x',
        guarantor_coverage: false,
        monthly_rent: '$1,500',
        cash_deposit_only: false,
        cash_deposit_amount: '$1,500',
        required_coverage: 1000,
        status: 'Sent',
        tenant_email: 'no_user+7835@example.com',
        tenant_full_name: 'Test Test'
      }
    },
    {
      type: 'Property',
      id: 21206,
      attributes: {
        id: 21206,
        building_name: '1000 Test Drive',
        address_line_one: '1000 Test Drive',
        address_city: 'Fort Worth',
        address_state: 'TX',
        address_zip: '76116',
        active: true,
        owner_name: 'Owner_681',
        unit_count: 1
      }
    }
  ]
};
