import axios, { AxiosResponse } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { IFilterQueryParams, IPropertyFilterOptions } from '../../components/v2/App/interfaces';
import { stringify } from 'qs';

export const getInvitationPropertyFilterOptions = async (params = {}): Promise<IPropertyFilterOptions> => {
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data }: AxiosResponse<IPropertyFilterOptions> = await axios.get<IPropertyFilterOptions>(
    '/v2/admin/invitations/property_filter_options',
    { params, paramsSerializer }
  );

  return data;
};

export const invitationPropertyFilterOptionsKey = 'invitationPropertyFilterOptions';

export default function useInvitationPropertyFilterOptions(
  queryParams: IFilterQueryParams
): QueryObserverResult<IPropertyFilterOptions> {
  return useQuery(
    [invitationPropertyFilterOptionsKey, queryParams],
    () => getInvitationPropertyFilterOptions(queryParams),
    {
      keepPreviousData: true
    }
  );
}
