import { TextInput as TextInputBase, Button as ButtonBase, Radio, FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { BREAKPOINTS } from '../../utils/constants';

export const Wrapper = styled.div({ marginBottom: 24 });

export const Label = styled.label([
  FONTS.labelMedium,
  {
    fontSize: '12px',
    lineHeight: '20px',
    color: PALETTE.neutral65
  }
]);

export const ErrorLabel = styled.label([
  FONTS.p3Medium,
  {
    color: PALETTE.alert125
  }
]);

export const FormView = styled.div({
  width: '100%',
  maxWidth: `${BREAKPOINTS.md}px`
});

export const TextInput = styled(TextInputBase)({
  width: '100%'
});

export const Button = styled(ButtonBase)({
  justifyContent: 'center',
  margin: '8px 0',
  textAlign: 'center',
  width: '100%',
  maxWidth: '330px'
});

export const StyledLine = styled.hr({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`
});

export const Title = styled.p([
  FONTS.p1Medium,
  {
    fontSize: '18px',
    lineHeight: '32px',
    margin: '12px 0'
  }
]);

export const Text = styled.p([
  FONTS.p1Light,
  {
    fontSize: '16px'
  }
]);

export const SelectorWrapper = styled.div({
  margin: '24px 0'
});

export const Tooltip = styled(ReactTooltip)({
  padding: '12px !important',
  border: `1px solid ${PALETTE.neutral12} !important`,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16) !important',
  borderRadius: '8px !important',
  opacity: '10 !important',
  width: '238px !important',
  '&:hover': {
    visibility: 'visible'
  }
});

export const TooltipText = styled.span([[FONTS.p3]]);

export const TooltipIconContainer = styled.div({
  display: 'flex',
  marginLeft: '5px',
  'svg:hover': {
    color: PALETTE.interaction100
  }
});

export const TooltipContainer = styled.div({ display: 'flex', flexDirection: 'row-reverse', marginBottom: '-18px' });

export const CheckboxTooltipContainer = styled.div({ display: 'flex', marginTop: '18px' });

export const CreateableComponents = {
  DropdownIndicator: null
};

// temp fix for Radio.Root props issue
export const RadioRoot: any = Radio.Root;
