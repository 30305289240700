import http from 'j-fetch';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  user_id: string;
  reset_password_url_base: string;
  input_placeholder_id: string;
  classes: string;
}

interface IState {
  reset_password_token: string | null;
}

class UsersResetPasswordUrl extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { reset_password_token: null };
  }

  public onGenerateResetPasswordUrl = (): void => {
    this.fetchResetPasswordToken();
  }

  public fetchResetPasswordToken() {
    const url = `/admin/users/${this.props.user_id}/reset_password_token`;

    return http
      .post({ url })
      .then((response) => response.text())
      .then((response) => this.setState({ reset_password_token: response }));
  }

  public componentDidUpdate() {
    this.renderInput();
  }

  public componentWillMount() {
    this.renderInput();
  }

  public input(reset_password_url) {
    return (<input name="reset-password-url" readOnly value={reset_password_url}></input>);
  }

  public renderInput() {
    const reset_password_token = this.state.reset_password_token;
    const reset_password_url = this.props.reset_password_url_base + '?reset_password_token=' + reset_password_token;
    const input_el = document.getElementById(this.props.input_placeholder_id);
    if (reset_password_token) {
      input_el!.parentElement!.style.display = 'table-row';
      ReactDOM.render(this.input(reset_password_url), input_el);
    } else {
      input_el!.parentElement!.style.display = 'none';
    }
  }

  public render(): JSX.Element {
    const handleBtnClick = this.onGenerateResetPasswordUrl;
    const buttonClasses = this.props.classes;
    return (
      <a className={`button ${buttonClasses}`} href="#" onClick={handleBtnClick}>Generate Reset Password URL</a>
    );
  }
}

export default UsersResetPasswordUrl;
