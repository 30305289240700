/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import {
  PALETTE,
  FONTS,
  Loading,
  Button,
  Alert2Icon,
} from '@sayrhino/rhino-shared-js';
import { ICancellationRequest } from '../interfaces';
import { useUserRole, useIsAutoApprove } from '../utils/userRole';
import styled from '@emotion/styled';
import React from 'react';
import { stringifyDateWithSlashes } from '../utils';
import moment from 'moment';

const StyledListItem = styled.li({
  listStyle: 'initial',
  listStylePosition: 'inside',
  textAlign: 'left'
});

const CancellationRequestBanner = (props: {
  cancellation: ICancellationRequest;
  isCancellationError: boolean;
  renterName: string;
  isLoading: boolean;
  onAccept: () => void;
  onDeny: () => void;
}) => {
  const { cancellation, renterName, isCancellationError, isLoading, onAccept, onDeny } = props;
  const userRole = useUserRole();
  const { isListingAgent, isThirdPartyAdmin } = userRole;
  const reasonComplement = cancellation.reason_complement ? ` (${cancellation.reason_complement})` : '';

  return (
    <>
      <div css={{ backgroundColor: PALETTE.brand4, padding: '32px 0', margin: 'auto', width: '480px' }}>
        <div css={{ maxWidth: '384px', margin: 'auto' }}>
          <>
              {renterName} has submitted a cancellation request with the following reason: {cancellation.reason_string + reasonComplement}.
              <ul css={{padding: '20px 0'}}>
                {cancellation.response_deadline && (
                  <StyledListItem>
                    You have until {moment(cancellation.response_deadline).format("MM/DD/YYYY")} to approve or deny this request, after which the request will be auto-approved.
                  </StyledListItem>
                )}
                {cancellation.info && (<StyledListItem>{cancellation.info}</StyledListItem>)}
              </ul>
              <div css={{ display: 'flex', marginTop: 16 }}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <Button variant="secondary" onClick={onDeny} id="denyCancellationRequestBtn">
                      Deny
                    </Button>
                    <Button variant="primary" css={{ marginLeft: 24 }} onClick={onAccept} id="approveCancellationRequestBtn">
                      Approve
                    </Button>
                  </>
                )}
              </div>
              {isCancellationError && (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                  <Alert2Icon style={{ marginRight: 20, color: PALETTE.alert100 }} height="20px" width="20px" />
                  <p css={[FONTS.p2Light, { color: PALETTE.alert100 }]}>Server could not process this request</p>
                </div>
              )}
            </>
        </div>
      </div>
    </>
  );
};

export default CancellationRequestBanner;
