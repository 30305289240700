export const properties = [
  {
    building_name: 'Cool Haus 1',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 2',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 3',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 4',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 5',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 6',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 7',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 8',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 9',
    address_state: 'NY'
  },
  {
    building_name: 'Cool Haus 10',
    address_state: 'NY'
  },
  {
    building_name: 'Same Building Name',
    address_state: 'NY'
  },
  {
    building_name: 'Same Building Name',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 1',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 2',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 3',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 4',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 5',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 6',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 7',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 8',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 9',
    address_state: 'CA'
  },
  {
    building_name: 'Sweet Spot 10',
    address_state: 'CA'
  },
  {
    building_name: 'Tom\'s Place',
    address_state: 'VT'
  }
]