import styled from '@emotion/styled';
import React from 'react';
/**
 * Testing use of spacing tokens in forms. Please only use in integrations/ISS for now.
 */
type SpacerProps = {
  /**
   * Sets height of spacer * 8. Defaults to 1
   * ex: (amount = 4) * 8 = 32px of height
   */
  amount?: number;
};

const StyledDiv = styled.div<SpacerProps>(({ amount = 1 }) => [{ height: amount * 8 }]);

const Spacer = (props: SpacerProps) => {
  const { amount } = props;
  return <StyledDiv amount={amount}></StyledDiv>;
};

export default Spacer;
