import { IData, IPaginatedMeta, IPropertyOwnerSearchResult } from 'components/v2/App/interfaces';
import axios from 'axios';
import { stringify } from 'qs';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

const getPropertyOwners = async (query: string, page: number, per: number) => {
  const getQueryType = Number.isNaN(+query) ? 'name' : 'id';
  const params = { page, per, [getQueryType]: query };
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data } = await axios.get<IData<IPropertyOwnerSearchResult[], IPaginatedMeta>>(`/v2/admin/property_owners`, {
    params,
    paramsSerializer
  });
  return data;
};

export const PROPERTY_OWNERS_CACHE_KEY = 'adminPropertyOwners';

export default function usePropertyOwners(
  query: string,
  isAdmin: boolean,
  per: number = 20
): InfiniteQueryObserverResult<IData<IPropertyOwnerSearchResult[], IPaginatedMeta>> | undefined {
  if (isAdmin) {
    return useInfiniteQuery<IData<IPropertyOwnerSearchResult[], IPaginatedMeta>>(
      [PROPERTY_OWNERS_CACHE_KEY, query],
      ({ pageParam = 1 }) => getPropertyOwners(query, pageParam, per),
      {
        getNextPageParam: (lastGroup) => lastGroup.meta.next_page
      }
    );
  }
}
