import '@reach/combobox/styles.css';
import React, { useState } from 'react';
import { PALETTE, FONTS, ROUNDNESS } from '@sayrhino/rhino-shared-js';
import { getProperties } from 'api/v2/usePropertySearch';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import styled from '@emotion/styled';
import { useQueryClient } from 'react-query';

interface Property {
  id: number;
  full_address: string;
  address_line_one: string;
  address_zip: string;
  name: string;
  has_bank_account?: boolean;
}
interface PropertyTypeaheadProps {
  onSelect: (id?: number, hasBankAccount?: boolean) => void;
  isInvalid?: boolean;
  propertyAddress?: string;
  property?: Property;
  id: string;
}

type TypeaheadState = {
  isSelected: boolean;
  isFocused: boolean;
};

const Wrapper = styled.div({});

const Select = styled(AsyncSelect)([
  ROUNDNESS.s,
  {
    border: `1px solid ${PALETTE.neutral12}`,
    padding: '3px'
  }
]);

const Label = styled.label([
  FONTS.p1Light,
  {
    color: PALETTE.neutral55
  }
]);

const SubText = styled.p<{ error?: boolean }>(({ error }) => [
  FONTS.p1,
  {
    fontSize: '12px',
    color: !error ? PALETTE.neutral25 : PALETTE.alert100
  }
]);

const setBackgroundStyle = (state: TypeaheadState) => {
  if (state.isSelected) {
    return PALETTE.brand100;
  }
  if (state.isFocused) {
    return PALETTE.neutral12;
  }
  return PALETTE.neutralLight;
};

const setColorStyle = (state: TypeaheadState) => {
  if (state.isSelected) {
    return PALETTE.neutralLight;
  }
  if (state.isFocused) {
    return PALETTE.neutralDark;
  }
  return PALETTE.neutralDark;
};

const customTypeaheadStyles = {
  option: (base: any, state: TypeaheadState) => ({
    ...base,
    color: setColorStyle(state),
    fontWeight: '900',
    backgroundColor: setBackgroundStyle(state)
  })
};

const getAddressString = (property) => {
  return `${property.building_name || property.name}, ${property.address_line_one}, ${property.address_zip}`;
};

export const PropertyTypeahead = ({ onSelect, isInvalid, id, property }: PropertyTypeaheadProps) => {
  const [searchError, setSearchError] = useState<string>('');
  const [value, setValue] = useState<Property | undefined>(property);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const only_active = false;
  const queryClient = useQueryClient();

  const loadOptions = async (input: string) => {
    setIsLoading(() => true);
    try {
      const properties = await queryClient.fetchQuery(['propertySearch', input], () =>
        getProperties(input, only_active)
      );
      setIsLoading(() => false);
      return properties;
    } catch (error) {
      setSearchError('Something went wrong!');
      setIsLoading(() => false);
      return [];
    }
  };

  const onChange = (value: Property | null) => {
    if (value === null) {
      setValue(undefined);
      onSelect(undefined);
      return null;
    }
    setValue(value);
    onSelect(value.id, value.has_bank_account);
  };

  return (
    <Wrapper>
      <Label htmlFor={id}>Property name or address</Label>
      <Select
        styles={customTypeaheadStyles}
        id={id}
        isClearable={true}
        isLoading={isLoading}
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        inputId="property-search"
        aria-label="property-search"
        getOptionValue={(options: Property) => options.id}
        getOptionLabel={(options: Property) => getAddressString(options)}
        placeholder="Select property..."
      />
      {isInvalid && <SubText error={isInvalid}>Can’t be left blank</SubText>}
      {Boolean(searchError) && <SubText error={isInvalid}>{searchError}</SubText>}
    </Wrapper>
  );
};
