/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Button, FONTS, PALETTE, TextInput } from "@sayrhino/rhino-shared-js";
import { useState } from "react";

const ContainerDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '15px'
});

const ContentDiv = styled.div({
  backgroundColor: '#F5F6F7',
  border: '1px solid #ADB4B8',
  borderRadius: '10px',
  padding: '15px',
  width: '480px'
});

const ActionButtonsDiv = styled.div({
  display: 'flex',
  justifyContent: 'right',
  gap: '5px'
});

const CancelButton = styled.button([
  {
    border: 'none',
    background: 'transparent',
    color: PALETTE.brand100
  },
  FONTS.h6Demi
]);

const FlatCancellationDenialForm = ({
  cancellationRequestReason,
  reasonExplanation,
  onReasonExplanationChange,
  onCancel,
  onDeny
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const denyRequest = () => {
    if (!reasonExplanation || reasonExplanation === '') {
      setShowErrorMessage(true);
      return;
    }

    setShowErrorMessage(false);
    onDeny();
  }

  return (
    <ContainerDiv>
      <ContentDiv>
        {showErrorMessage && (
          <div css={{paddingBottom: '20px', color: '#E05E02'}}>
            Please provide a reason for denying this cancellation request.
          </div>
        )}
        <div>
          By denying this cancellation, you are indicating that the cancellation reason provided ("{cancellationRequestReason}") is inaccurate. Please add an explanation below to be shared with the renter:
        </div>
        <TextInput id='denial_reason_explanation' onChange={onReasonExplanationChange}></TextInput>
        <ActionButtonsDiv>
          <CancelButton onClick={onCancel}>Back</CancelButton>
          <Button variant="primary" onClick={denyRequest} id="denyRequestBtn">
            Deny Request
          </Button>
        </ActionButtonsDiv>
      </ContentDiv>
    </ContainerDiv>
  );
}

export default FlatCancellationDenialForm;
