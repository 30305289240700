import React from 'react';

const Label = (props) => {
  const { target, children, className = '' } = props;

  return (
    <label htmlFor={target} className={`control-label ${className}`}>
      {children}
    </label>
  );
};

export default Label;
