import React, { Component } from 'react';
import StateRow from './StateRow';

interface IProps {
  item: IStateSection;
  onStatesChange: Function;
}

interface IActiveState {
  active: boolean;
  full_name: string;
  name: string;
}

interface IStateSection {
  title: string;
  data: IActiveState[];
}

class StateSection extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public updateStateActiveStatus = (value)  => {
    this.props.onStatesChange(value);
  }

  public render(): JSX.Element {
    return (
      <div>
        <h1>
          {this.props.item.title}
         </h1>
         <div className="statesSection">
            { this.props.item.data.map((activeState) =>
              <StateRow state={activeState} updateStateActiveStatus={this.updateStateActiveStatus}/>
            )}
         </div>
      </div>
    );
  }
}

export default StateSection;
