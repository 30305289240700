/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  PALETTE,
  Datepicker,
  FONTS,
  CloseIcon,
  ArrowRight,
  Button,
  Loading,
  InlineNotification,
  TextInput
} from '@sayrhino/rhino-shared-js';
import {
  sanitizeCurrencyInput,
  isWithin14days,
  initializeDate,
  centsToUSDFormatter,
  useReferrer,
  urlWithReferrer
} from './utils';
import { stringifyDate } from './utils';
import { isEqual } from 'lodash';

import usePolicy from '../../../api/v2/usePolicy';
import { useUserId } from './utils/userRole';
import updatePolicy from '../../../api/v2/updatePolicy';
import { FormField } from './PolicyDetails';
import { format } from 'date-fns';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import { useQueryClient, useMutation } from 'react-query';
import { ChangeRequestError, ICoverageSelection, UnitRequestError } from './interfaces';
// @TODO - Remove and deprecate PropertySelect & PropertyUnitSelect
// paddingBottom: 0 is added to compensate for height of these on inputs
import PropertySelect from 'components/properties/PropertySelect';
import PropertyUnitSelect from 'components/units/PropertyUnitSelect';
import changePolicy from 'api/v2/changePolicy';
import { AxiosError } from 'axios';
import CoverageOptionsSelect, { ICoverageOptionSelectProps } from './CoverageOptionSelect/index';
import useOverlay from './utils/overlay';
import Decimal from 'decimal.js';
import useCoverageOptions, { CoverageState } from 'api/v2/useCoverageOptions';
import { DOLLAR_AMOUNT } from './utils/constants';

type PropertyTypeahead = {
  id?: number;
  full_address?: string;
};

type UnitTypeahead = {
  id?: number;
  unit?: string;
};

// @TODO: remove start and end dates from datepicker. Github actions is down so we can't update to latest types yet
export const PolicyDetailsEditView = () => {
  const { policyId } = useParams();
  const navigate = useNavigate();
  const referrer = useReferrer();
  const { data } = usePolicy(Number(policyId));
  const userId = useUserId();
  const initialPropertyId = data?.property_id;
  const initialUnitId = data?.unit_id;

  // policy details fields
  const [unit, setUnit] = React.useState<UnitTypeahead>({ id: data?.unit_id, unit: data?.unit_name || '' });
  const [property, setProperty] = React.useState<PropertyTypeahead>({ id: data?.property_id, full_address: '' });
  const [leaseStartDate, setLeaseStartDate] = React.useState(initializeDate(data?.lease_start_date));
  const [leaseEndDate, setLeaseEndDate] = React.useState(initializeDate(data?.lease_end_date));
  const [monthlyRent, setMonthlyRent] = React.useState<number>(data?.monthly_rent_cents || 0);
  const [changeErrorMessage, setChangeErrorMessage] = React.useState<string | undefined>('');
  const [unitErrorMessage, setUnitErrorMessage] = React.useState<string | undefined>('');
  const [needsTenantApproval, setNeedsTenantApproval] = React.useState<boolean | undefined>(false);
  let coverageInfo: CoverageState | undefined;
  let initialCoverageSelection: ICoverageSelection | undefined;

  const queryCache = useQueryClient();

  if (property.id !== undefined) {
    initialCoverageSelection = {
      type: DOLLAR_AMOUNT,
      value: new Decimal(data?.coverage_amount_cents || 0)
    };

    if (data?.coverage_details) {
      initialCoverageSelection = {
        type: data.coverage_details.type,
        value: new Decimal(data.coverage_details.value)
      };
    }

    coverageInfo = useCoverageOptions({
      propertyId: property.id,
      unitId: unit.id,
      monthlyRentCents: monthlyRent,
      initialCoverageState: initialCoverageSelection
    });
  }

  const bindingDate = initializeDate(data?.binding_date);
  const policyWithin14Days = isWithin14days(bindingDate);

  // mutation for react-query to update cache on put request
  const { mutate: changePolicyMutation, isLoading } = useMutation(changePolicy, {});
  const { mutate: updatePolicyMutation } = useMutation(updatePolicy, {});

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (coverageInfo?.selected === undefined) {
      return;
    }

    const checkTenantApprovalRequired =
      needsTenantApproval || isCoverageUpdated(initialCoverageSelection, coverageInfo.selected);

    if (policyWithin14Days || data?.actions.update_address) {
      changePolicyMutation(
        {
          ...data,
          monthly_rent_cents: monthlyRent,
          insurance_policy_id: data?.id,
          requester_user_id: userId,
          unit_id: unit.id,
          property_id: property.id,
          lease_start_date: format(leaseStartDate, 'yyyy-MM-dd'),
          lease_end_date: format(leaseEndDate, 'yyyy-MM-dd'),
          coverage: coverageInfo.selected,
          previous_unit_id: initialUnitId,
          needs_tenant_approval: checkTenantApprovalRequired
        },
        {
          onSuccess: () => {
            // only update unit directly once the change request is created and
            // if the property hasn't changed, but the unit has
            if (property.id === initialPropertyId && unit.id !== initialUnitId) {
              updatePolicyWithUnit();
            } else {
              queryCache.invalidateQueries('policies');
              navigate(-1);
            }
          },
          onError: (error: AxiosError<ChangeRequestError>) => {
            const errorMessages = error?.response?.data.message;

            if (errorMessages) {
              setChangeErrorMessage(errorMessages);
            } else {
              // An error with no message indicates that a change request wasn't created
              // because the request didn't result in any actual changes to the policy
              queryCache.invalidateQueries('policies');
              navigate(-1);
            }
          }
        }
      );
    } else {
      if (property.id === initialPropertyId && unit.id !== initialUnitId) {
        updatePolicyWithUnit();
      } else {
        queryCache.invalidateQueries('policies');
        navigate(-1);
      }
    }
  };

  const updatePolicyWithUnit = () => {
    updatePolicyMutation(
      { id: Number(policyId), unit_id: unit.id },
      {
        onSuccess: (responseData) => {
          queryCache.setQueryData(['policy', Number(policyId)], responseData);
          queryCache.invalidateQueries('policies');
          navigate(-1);
        },
        onError: (error: AxiosError<UnitRequestError>) => {
          const unitErrorResponse = error?.response?.data?.revision?.unit;
          if (unitErrorResponse && unitErrorResponse.length) {
            setUnitErrorMessage(unitErrorResponse.join(' '));
          }
        }
      }
    );
  };

  const isCoverageUpdated = (initial, selected) => !isEqual(initial, selected);

  const handleLeaseStartDateChange = (date: Date) => {
    setLeaseStartDate(date);
  };

  const handleLeaseEndDateChange = (date: Date) => {
    setLeaseEndDate(date);
  };

  const handleMonthlyRentChange = (event, key: string) => {
    setMonthlyRent(sanitizeCurrencyInput(event));
    setNeedsTenantApproval(true);
  };

  const formattedMonthlyRent = centsToUSDFormatter(new Decimal(monthlyRent));

  let coverageOptionsSelectProps: ICoverageOptionSelectProps | undefined;
  if (coverageInfo) {
    coverageOptionsSelectProps = {
      coverageInfo,
      disabled: !policyWithin14Days
    };
  }

  return isLoading ? (
    <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Loading />
    </div>
  ) : (
    <div>
      <div
        css={{
          borderBottom: `1px solid ${PALETTE.neutral4}`,
          backgroundColor: PALETTE.neutralLight,
          height: '3.75rem',
          padding: '20px 32px',
          position: 'sticky',
          top: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 1
        }}
      >
        <h4 css={[FONTS.h5, { color: PALETTE.neutral25 }]}>Edit policy</h4>
        <div
          css={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Link
            to={urlWithReferrer(`/admin/renter_policies/${policyId}`, referrer)}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 32,
              width: 32,
              backgroundColor: PALETTE.neutral4,
              borderRadius: 75
            }}
            aria-label="view policy details"
          >
            <CloseIcon height={14} width={14} />
          </Link>
        </div>
      </div>
      {(changeErrorMessage || unitErrorMessage) && (
        <div
          css={{
            padding: '32px 96px 12px 96px'
          }}
        >
          <InlineNotification variant="highPriority" aria-label="form error message">
            {`${changeErrorMessage} ${unitErrorMessage}`.trim()}
          </InlineNotification>
        </div>
      )}
      <div
        css={{
          padding: '32px 0',
          width: 480,
          margin: 'auto'
        }}
      >
        <h3 css={[FONTS.h3, { paddingBottom: 16, marginBottom: 48, borderBottom: `${PALETTE.neutral12} solid 1px` }]}>
          {data?.tenant_full_name}
        </h3>
        <h4 css={[FONTS.h5, { marginBottom: 32, paddingBottom: 16, borderBottom: `${PALETTE.neutralDark} solid 1px` }]}>
          Apartment
        </h4>
        <form onSubmit={handleSubmit} method="POST">
          <FormField css={[{ marginTop: 12, marginBottom: 28, paddingBottom: 0 }]}>
            <PropertySelect
              labelKey="full_address"
              id="address"
              placeholder="123 Main Street, Brooklyn, NY, United States"
              value={{ id: property.id, full_address: data?.property_address }}
              onChange={(p) => {
                setProperty({ id: p?.id || null });
                setNeedsTenantApproval(true);
                // null unit when property is changed
                setUnit({});
              }}
              disabled={!data?.actions.update_address}
              errors={null}
              clearable={false}
            />
            <label htmlFor="address" css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Lease address <ArrowRight style={{ transform: 'rotate(90deg)', color: PALETTE.neutral25, zIndex: 0 }} />
            </label>
          </FormField>
          <FormField css={[{ marginTop: 12, paddingBottom: 0 }]}>
            <PropertyUnitSelect
              id="unit"
              labelKey="address_line_two"
              propertyId={property.id}
              value={{ id: unit.id, address_line_two: unit.unit }}
              onChange={(u) => {
                setUnit({ id: u?.id, unit: u.address_line_two });
              }}
              placeholder="Lease unit"
              clearable={false}
              key={property.id}
              refresh
            />
            <label htmlFor="unit" css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Lease unit <ArrowRight style={{ transform: 'rotate(90deg)', color: PALETTE.neutral25, zIndex: 0 }} />
            </label>
          </FormField>
          <div css={[{ marginTop: 48 }]}>
            <div css={{ marginTop: 48 }}>
              <Datepicker
                onChange={(date) => {
                  handleLeaseStartDateChange(date);
                  setNeedsTenantApproval(true);
                }}
                selected={leaseStartDate}
                value={leaseStartDate ? stringifyDate(leaseStartDate) : 'Select lease start date'}
                inputLabel="Lease start date"
                readOnly={!policyWithin14Days}
              />
            </div>
            <div css={{ marginTop: 48 }}>
              <Datepicker
                onChange={(date) => {
                  handleLeaseEndDateChange(date);
                  setNeedsTenantApproval(true);
                }}
                selected={leaseEndDate}
                value={leaseEndDate ? stringifyDate(leaseEndDate) : 'Select lease end date'}
                inputLabel="Lease end date"
                readOnly={!policyWithin14Days}
              />
            </div>
          </div>
          
          <div css={[{ marginTop: 48 }]}>
            <TextInput
              name="monthly rent"
              id="monthlyRent"
              label="Monthly Rent"
              value={formattedMonthlyRent}
              onChange={(event) => handleMonthlyRentChange(event, 'monthly_rent')}
              disabled={!policyWithin14Days}
            />
          </div>
          
          <h4
            css={[FONTS.h5, { marginBottom: 32, paddingBottom: 16, borderBottom: `${PALETTE.neutralDark} solid 1px` }]}
          >
            Coverage
          </h4>
          <fieldset disabled={!policyWithin14Days}>
            {coverageOptionsSelectProps && <CoverageOptionsSelect {...coverageOptionsSelectProps} />}
          </fieldset>
          <Button variant="primary" type="submit">
            Update Policy
          </Button>
        </form>
      </div>
    </div>
  );
};

const PolicyDetailsEdit = () => {
  const { policyId } = useParams();
  const { isSuccess } = usePolicy(Number(policyId));
  const { setShowOverlay } = useOverlay();

  React.useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return isSuccess ? <PolicyDetailsEditView /> : <Loading />;
};

export default PolicyDetailsEdit;
