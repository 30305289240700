import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { centsToUSDFormatter, initializeDate } from '../../utils';
import Decimal from 'decimal.js';
import createClaim from 'api/v2/createClaim';
import editClaim from 'api/v2/editClaim';
import useToast, { TOAST_STATUS } from '../../toast/use-toast';
import { useQueryClient, useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useUserId } from '../../utils/userRole';
import useClaim from 'api/v2/useClaim';
import {
  LossOfRentData,
  DamageData,
  ACHInfo,
  CheckingInfo,
  PaymentOption,
  Errors,
  SavedAccount,
  LossOfRentObj
} from './claimInterface';
import { ICreateClaimParams, FormMode } from '../../interfaces';
import useClaimAttachments from 'api/v2/useClaimAttachments';
import { format } from 'date-fns';

// update Errors claimInterface to match
const claimDetailsErrors = {
  claim_type: 'claimType',
  amount_cents: 'damageAmount',
  subcategory_2: 'subcategory',
  date_of_loss: 'dateOfLoss',
  damaged_room: 'damagedRoom'
};
const claimDocumentationError = { attachments: 'attachments' };
const claimPaymentErrors = {
  payment_option_selected: 'paymentSelect',
  bank_account: 'accountConfirm',
  'bank_account.routing_number': 'routingNumber',
  'bank_account.account_number': 'accountNumber',
  'bank_account.nickname': 'nickname',
  'mailing_address.street': 'addressOne',
  'mailing_address.city': 'city',
  'mailing_address.state': 'state',
  'mailing_address.zipcode': 'zip'
};

const claimErrorMapping = {
  property_id: 'propertyId',
  insurance_policy: 'policyId',
  ...claimDetailsErrors,
  ...claimDocumentationError,
  ...claimPaymentErrors
};

const ATTACHMENTS_URL = '/uploads/attachments';
const useClaimForm = () => {
  const { claimsId } = useParams();
  const userId = useUserId();
  const mode = claimsId ? FormMode.EDIT : FormMode.CREATE;
  const { data: claim } = useClaim(Number(claimsId)) ?? {};
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [accountConfirmed, setAccountConfirmed] = useState<boolean>(false);
  const [savePayoutInfo, setSavePayoutInfo] = useState<boolean>(false);
  const [accountNickname, setAccountNickname] = useState<string>(claim?.bank_account?.nickname ?? '');
  const [savedAccount, setSavedAccount] = useState<SavedAccount>({ id: null, nickname: '' });
  const [hidePayoutMethod, setHidePayoutMethods] = useState<boolean>(false);
  const [isAttachmentLoading, setAttachmentLoading] = useState<boolean>(false);
  const [tenantNotified, setTenantNotified] = useState<boolean>(claim?.tenant_notified_of_rent_arrears ?? false);
  const [selectedProperty, setSelectedProperty] = useState<number | undefined>(claim?.property.id);
  const [selectedPolicy, setSelectedPolicy] = useState<number | undefined>(claim?.insurance_policy.id);
  const [policyRemainingAmount, setPolicyRemainingAmount] = useState<number>(
    claim?.insurance_policy.remaining_coverage_amount_cents || 0
  );
  const [note, setNote] = useState<string | undefined>();
  const { data: attachments } = useClaimAttachments(Number(claimsId), { per: 100 }) ?? {};

  let gettingStartedRef = useRef<HTMLDivElement>(null);
  let claimDetailsRef = useRef<HTMLDivElement>(null);
  let claimDocumentationRef = useRef<HTMLDivElement>(null);

  const getInitialClaimType = () => {
    if (claim?.claim_type === 'Loss of rent') return 'Loss of Rent';
    if (claim?.claim_type === 'Excessive wear and tear') return 'Damages';
    return '';
  };
  const [claimType, setClaimType] = useState<string>(getInitialClaimType());

  const getRentArrearBegin = (loss_of_rent_type) => {
    if (loss_of_rent_type === 'rent arrears during tenancy') return 0;
    if (loss_of_rent_type === 'rent arrears after move-out') return 1;
    return undefined;
  };

  const getLossOfRents = (claim) => {
    return claim.loss_of_rents.map((item) => {
      return {
        id: item.id,
        rentArrearBegin: getRentArrearBegin(item.loss_of_rent_type),
        arrearDate: { date: initializeDate(item.date_of_loss), updated: false },
        arrearAmountCents: new Decimal(item.arrears_amount_cents),
        lateFeeCents: new Decimal(item.late_fees_cents),
        otherFeeCents: new Decimal(item.other_fees_cents)
      };
    });
  };

  const [lossOfRentData, setLossOfRentData] = useState<LossOfRentData[]>(
    claim?.loss_of_rents
      ? getLossOfRents(claim)
      : [
          {
            arrearDate: { date: new Date(), updated: false },
            arrearAmountCents: new Decimal(0),
            lateFeeCents: new Decimal(0),
            otherFeeCents: new Decimal(0)
          }
        ]
  );
  const [damageData, setDamageData] = useState<DamageData>(
    claim?.claim_type === 'Excessive wear and tear'
      ? {
          dateOfLoss: {
            date: claim.date_of_loss ? initializeDate(claim.date_of_loss.toString()) : new Date(),
            updated: false
          },
          amountCents: new Decimal(claim.amount_cents),
          damagedRoom: claim.damaged_room ? claim.damaged_room : '',
          subCategories: claim.subcategory_2 ? JSON.parse(claim.subcategory_2) : []
        }
      : {
          dateOfLoss: { date: new Date(), updated: false },
          amountCents: new Decimal(0),
          damagedRoom: '',
          subCategories: []
        }
  );

  const [filesList, setFilesList] = useState<any[]>(
    attachments?.data
      ? attachments.data.map((item) => {
          return {
            id: item.blob_id,
            name: item.filename
          };
        })
      : []
  );

  const [paymentOption, setPaymentOption] = useState<string>(
    claim?.payment_option_selected === 'ACH'
      ? PaymentOption.DIRECT_DEPOSIT
      : claim?.payment_option_selected === 'check'
      ? PaymentOption.PHYSICAL_CHECK
      : ''
  );
  const [paymentACHInfo, setPaymentACHInfo] = useState<ACHInfo>(
    claim && claim.bank_account && claim.bank_account.routing_number && claim.bank_account.account_number
      ? {
          routingNumber: claim.bank_account.routing_number,
          accountNumber: claim.bank_account.account_number
        }
      : {
          routingNumber: '',
          accountNumber: ''
        }
  );
  const [checkMailingAddress, setCheckMailingAddress] = useState<CheckingInfo>(
    claim?.mailing_address
      ? {
          addressOne: claim.mailing_address.address_line_one,
          googlePlaceId: claim.mailing_address.google_place_id,
          addressTwo: claim.mailing_address.address_line_two,
          city: claim.mailing_address.address_city,
          state: claim.mailing_address.address_state,
          zip: claim.mailing_address.address_zip
        }
      : {
          addressOne: '',
          googlePlaceId: '',
          addressTwo: '',
          city: '',
          state: '',
          zip: ''
        }
  );
  // validation
  const [errors, setErrors] = useState<Errors>({});
  const [lossOfRentError, setLossOfRentError] = useState<LossOfRentObj>({});
  const resetError = (key: string) => {
    setErrors({ ...errors, [key]: undefined });
  };
  // dropzone
  const validateForDuplicates = (file) => {
    return filesList.find((e) => e.name === file.name && e.size === file.size && e.type === file.type);
  };
  const [fileSize, setCurrentFilesSize] = useState<number>(0);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,application/pdf',
    onDropRejected: () => {
      addToast('No duplicate files allowed', 'error', TOAST_STATUS.ERROR);
    },
    validator: validateForDuplicates,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((f, index) => {
        const data = new FormData();
        setCurrentFilesSize(fileSize + f.size);
        data.append('file', f);
        axios
          .post(ATTACHMENTS_URL, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: () => {
              setAttachmentLoading(true);
            }
          })
          .then((res) => {
            // @ts-ignore
            f.id = res.data.id;
            setAttachmentLoading(false);
          });
      });

      if (errors.attachments) {
        resetError('attachments');
      }
      setFilesList((currentFiles) => [...currentFiles, ...acceptedFiles]);
    }
  });

  // mutation for react-query to update cache on put request
  const { mutate: createClaimMutation, isLoading: createClaimIsLoading } = useMutation(createClaim, {});
  const { mutate: editClaimMutation, isLoading: editClaimIsLoading } = useMutation(editClaim, {});
  const queryCache = useQueryClient();

  const onSelectProperty = (id: number, hasBankAccount: boolean) => {
    setSelectedProperty(id);
    if (hasBankAccount) setHidePayoutMethods(hasBankAccount);
    if (id >= 0 && errors.propertyId) {
      resetError('propertyId');
    }
  };
  const onSelectPolicy = (policy) => {
    if (!policy) {
      setSelectedPolicy(undefined);
      return;
    }
    setSelectedPolicy(policy.id);
    setPolicyRemainingAmount(policy.remaining_coverage_amount_cents);
    if (policy.id >= 0 && errors.policyId) {
      resetError('policyId');
    }
  };
  const onSelectClaimType = (item) => {
    setClaimType(item);
    if (item) {
      resetError('claimType');
    }
  };

  const checkLossOfRentErrors = (index, type) => {
    if (!type && !!lossOfRentError && !!lossOfRentError[index] && !!lossOfRentError[index].errors) {
      return true;
    } else if (!!type && !!lossOfRentError && !!lossOfRentError[index] && !!lossOfRentError[index].errors) {
      return lossOfRentError[index]['errors'][type];
    }
  };

  const handleChangeLossOfRentData = (index, value, key, errType) => {
    const newLoseOfRentDatas = lossOfRentData.map((item, i) => {
      if (i !== index) {
        return item;
      }
      return {
        ...item,
        [key]: value
      };
    });
    setLossOfRentData([...newLoseOfRentDatas]);
    const len = lossOfRentData.length;
    if (
      errType === 'loss_of_rent_type' &&
      checkLossOfRentErrors(index, '') &&
      value >= 0 &&
      !!lossOfRentError[index].errors.loss_of_rent_type
    ) {
      delete lossOfRentError[index].errors[errType];
      setLossOfRentError(lossOfRentError);
    }
    if (
      errType === 'arrears_amount_cents' &&
      checkLossOfRentErrors(index, '') &&
      value >= 0 &&
      !!lossOfRentError[index].errors.arrears_amount_cents
    ) {
      delete lossOfRentError[index].errors[errType];
      setLossOfRentError(lossOfRentError);
    }
    if (
      errType === 'date_of_loss' &&
      checkLossOfRentErrors(index, '') &&
      !!lossOfRentError[index].errors.date_of_loss
    ) {
      delete lossOfRentError[index].errors[errType];
      setLossOfRentError(lossOfRentError);
    }
  };

  const onAddLossOfRent = () => {
    setLossOfRentData([
      ...lossOfRentData,
      {
        arrearDate: { date: new Date(), updated: false },
        arrearAmountCents: new Decimal(0),
        lateFeeCents: new Decimal(0),
        otherFeeCents: new Decimal(0)
      }
    ]);
  };
  const onChangeDamageData = (value: Decimal | string | string[], key: string) => {
    setDamageData({
      ...damageData,
      [key]: value
    });
    if (key === 'amountCents') {
      if ((value as Decimal).toNumber() > 0 && errors.damageAmount) {
        resetError('damageAmount');
      }
    }
  };

  const onChangeDateOfLoss = (value: { date: Date; updated: boolean }) => {
    setDamageData({ ...damageData, dateOfLoss: value });
  };
  const onToggleCheckSubCategory = (subCategory) => {
    let newSubCategories: string[] = damageData.subCategories;
    if (newSubCategories.includes(subCategory)) {
      newSubCategories = newSubCategories.filter((st) => st !== subCategory);
    } else {
      newSubCategories = [...newSubCategories, subCategory];
    }
    if (errors.subcategory && newSubCategories.length) {
      resetError('subcategory');
    }
    setDamageData({
      ...damageData,
      subCategories: [...newSubCategories]
    });
  };
  const getTotalRentAmount = () => {
    const totalAmount = lossOfRentData.reduce((total, cur) => {
      return total + cur.arrearAmountCents.toNumber() + cur.lateFeeCents.toNumber() + cur.otherFeeCents.toNumber();
    }, 0);
    return centsToUSDFormatter(new Decimal(totalAmount));
  };

  const onChangeACHInfo = (e, key) => {
    const value = e.target.value;
    setPaymentACHInfo({
      ...paymentACHInfo,
      [key]: value
    });
    if (value && errors[key]) {
      resetError(key);
    }
  };
  const onChangeSaveACHInfo = (value: string) => {
    setAccountNickname(value);
    if (errors.nickname) {
      resetError('nickname');
    }
  };
  const onChangeCheckInfo = (value, key) => {
    setCheckMailingAddress({
      ...checkMailingAddress,
      [key]: value
    });
    if (key !== 'addressTwo' && value && errors[key]) {
      resetError(key);
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    let params: ICreateClaimParams = {
      attachments: filesList.map((item) => item?.id).join(','),
      claim: {
        insurance_policy_id: selectedPolicy ? selectedPolicy : '',
        claim_type:
          claimType === 'Damages' ? 'Excessive wear and tear' : claimType === 'Loss of Rent' ? 'Loss of rent' : '',
        payment_option_selected:
          paymentOption === PaymentOption.DIRECT_DEPOSIT
            ? 'ACH'
            : paymentOption === PaymentOption.PHYSICAL_CHECK
            ? 'check'
            : paymentOption,
        tenant_notified_of_rent_arrears: tenantNotified,
        ...(paymentOption === PaymentOption.DIRECT_DEPOSIT && { account_confirmed: accountConfirmed }),
        ...(mode === FormMode.CREATE && note && { notes_attributes: [{ user_id: userId, description: note }] })
      }
    };
    if (claimType === 'Loss of Rent') {
      params.claim.loss_of_rent_line_items_attributes = lossOfRentData.map((data, index) => {
        return {
          id: data.id,
          arrears_amount_cents: data.arrearAmountCents.toNumber(),
          date_of_loss:
            mode === FormMode.EDIT && !data.arrearDate.updated && claim?.loss_of_rents[index].date_of_loss
              ? claim?.loss_of_rents[index].date_of_loss.toString()
              : format(data.arrearDate.date, 'yyyy-MM-dd'),
          late_fees_cents: data.lateFeeCents.toNumber(),
          loss_of_rent_type: data.rentArrearBegin !== undefined ? data.rentArrearBegin : '',
          other_fees_cents: data.otherFeeCents.toNumber()
        };
      });
    } else if (claimType === 'Damages') {
      params.claim.date_of_loss =
        mode === FormMode.EDIT && !damageData.dateOfLoss.updated && claim?.date_of_loss
          ? claim?.date_of_loss.toString()
          : format(damageData.dateOfLoss.date, 'yyyy-MM-dd');
      params.claim.amount_cents = damageData.amountCents.toNumber();
      params.claim.damaged_room = damageData.damagedRoom;
      if (damageData.damagedRoom && damageData.damagedRoom !== 'Heating, HVAC, Electrical') {
        params.claim.subcategory_2 = damageData.subCategories;
      }
    }
    if (paymentOption === PaymentOption.PHYSICAL_CHECK) {
      params.claim.mailing_address_attributes = {
        google_place_id: checkMailingAddress.googlePlaceId,
        address_line_one: checkMailingAddress.addressOne,
        address_line_two: checkMailingAddress.addressTwo,
        address_city: checkMailingAddress.city,
        address_state: checkMailingAddress.state,
        address_zip: checkMailingAddress.zip
      };
    } else if (paymentOption === PaymentOption.DIRECT_DEPOSIT) {
      if (savedAccount.id) {
        params.claim.bank_account_attributes = {
          saved_bank_account_id: savedAccount.id
        };
      } else {
        params.claim.bank_account_attributes = {
          account_number: paymentACHInfo.accountNumber,
          routing_number: paymentACHInfo.routingNumber,
          save_for_future_use: savePayoutInfo,
          ...(savePayoutInfo && { nickname: accountNickname })
        };
      }
    }

    const callback = {
      onSuccess: (response) => {
        queryCache.invalidateQueries('claims');
        const claimId = response?.data?.id;
        navigate('/admin/claims/' + claimId);
      },
      onError: (e: AxiosError) => {
        if (e.response?.status === 422) {
          const data = e.response.data;
          const errors = data.claim.errors;
          const errorKeys = Object.keys(errors);
          errorKeys.forEach((key) => {
            const mappedKey = claimErrorMapping[key];
            if (mappedKey) return setErrors((prevState) => ({ ...prevState, [mappedKey]: errors[key].toString() }));
          });
          if (!!errors.loss_of_rent_line_items) {
            setLossOfRentError(errors.loss_of_rent_line_items);
          }
          if (errors.base && errors.base.length) {
            addToast(errors.base.toString(), 'error', TOAST_STATUS.ERROR);
          }

          const claimDetailError =
            Object.keys(claimDetailsErrors).some((type) => errors[type]) || !!errors.loss_of_rent_line_items;

          if (!!errors.insurance_policy) {
            gettingStartedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else if (!!claimDetailError) {
            claimDetailsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else if (!!errors.attachments) {
            claimDocumentationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        } else {
          addToast('Something went wrong', 'error', TOAST_STATUS.ERROR);
        }
      }
    };
    if (claim?.id) {
      params.id = claim.id;
      editClaimMutation(params, callback);
    } else {
      createClaimMutation(params, callback);
    }
  };

  const handleSelectPaymentOption = (value) => {
    if (value) {
      resetError('paymentSelect');
    }
    setPaymentOption(value);
  };

  const removeAttachment = (e, file) => {
    e.preventDefault();
    if (claimsId) {
      setFilesList((currentFiles) => currentFiles.filter((f) => f.name !== file.name));
    } else {
      axios
        .delete(`${ATTACHMENTS_URL}/${file.id}`)
        .then((res) => {
          setFilesList((currentFiles) => currentFiles.filter((f) => f.name !== file.name));
          setCurrentFilesSize(fileSize - file.size);
        })
        .catch((err) => {
          addToast('Remove attachment is failed.', 'error', TOAST_STATUS.ERROR);
        });
    }
  };

  const handleAccountConfirmChange = (value) => {
    if (errors.accountConfirm) {
      resetError('accountConfirm');
    }
    setAccountConfirmed(value);
  };

  return {
    submitForm,
    claim,
    errors,
    lossOfRentError,
    checkLossOfRentErrors,
    isLoading: createClaimIsLoading || editClaimIsLoading || isAttachmentLoading,
    tenantInformation: {
      onSelectProperty,
      selectedProperty,
      onSelectPolicy,
      selectedPolicy,
      tenantNotified,
      setTenantNotified
    },
    claimDetails: {
      claimType,
      onSelectClaimType,
      lossOfRentData,
      onAddLossOfRent,
      getTotalRentAmount,
      handleChangeLossOfRentData,
      damageData,
      onChangeDamageData,
      onChangeDateOfLoss,
      policyRemainingAmount,
      onToggleCheckSubCategory,
      note,
      setNote,
      displayNoteTextInput: mode === FormMode.CREATE
    },
    fileSize,
    claimDocumentation: { getRootProps, getInputProps, filesList, removeAttachment },
    payoutMethod: {
      hidePayoutMethod,
      onChangeACHInfo,
      onChangeCheckInfo,
      handleSelectPaymentOption,
      setCheckMailingAddress,
      paymentOption,
      setPaymentOption,
      paymentACHInfo,
      accountConfirmed,
      handleAccountConfirmChange,
      checkMailingAddress,
      savedAccount,
      setSavedAccount,
      savePayout: { savePayoutInfo, setSavePayoutInfo, accountNickname, onChangeSaveACHInfo },
      mode,
      initialPaymentACHInfo: {
        routingNumber: claim?.bank_account?.routing_number ?? '',
        accountNumber: claim?.bank_account?.account_number ?? ''
      }
    },
    sectionRef: {
      gettingStartedRef,
      claimDetailsRef,
      claimDocumentationRef
    }
  };
};

export default useClaimForm;
