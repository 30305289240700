import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Card, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import NewTeamMemberForm from './NewTeamMemberForm';
import TeamMember from './TeamMember';

const cardStyle = {
  height: 'auto',
  border: `1px solid ${PALETTE.neutral12}`,
  padding: '40px 48px',
  cursor: 'auto',
  margin: '15px auto',
  width: '600px'
};

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const Title = styled.h4([FONTS.h4, { color: PALETTE.brand100 }]);

export enum TeamMemberType {
  PROPERTY_MANAGER = 'PropertyManager',
  LISTING_AGENT = 'ListingAgent',
  PROPERTY_MANAGER_ADMIN = 'PropertyManagerAdmin'
}

export interface Property {
  property_id: number;
  building_name: string;
  address: string;
}

export interface NewTeamMemberFormValues {
  type: TeamMemberType;
  name: string;
  email: string;
  phone: number | null;
  properties: Property[];
}

const INITIAL_VALUES: NewTeamMemberFormValues = {
  type: TeamMemberType.PROPERTY_MANAGER_ADMIN,
  name: '',
  email: '',
  phone: null,
  properties: []
};

interface IProps {
  showForm: boolean;
  newTeamMembers: NewTeamMemberFormValues[];
  setTeamMembers(values: NewTeamMemberFormValues[]): void;
  flipForm(val: boolean): void;
}

const NewTeamMember: React.FC<IProps> = ({ showForm, flipForm, newTeamMembers, setTeamMembers }) => {
  const handleAdd = (newTeamMember) => {
    flipForm(false);
    setTeamMembers([...newTeamMembers, newTeamMember]);
  };

  const handleUpdate = (newTeamMember, idx) => {
    setTeamMembers(
      [...newTeamMembers].map((teamMember, index) => {
        if (index === idx) return newTeamMember;
        return teamMember;
      })
    );
  };

  const handleDelete = (idx) => {
    setTeamMembers([...newTeamMembers].filter((_, index) => index !== idx));
  };

  return (
    <>
      {newTeamMembers.map((teamMember, idx) => (
        <TeamMember
          key={`${teamMember.name}-${idx}`}
          idx={idx}
          teamMember={teamMember}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      ))}
      <Card variant="border" style={cardStyle}>
        <Header>
          <Title>Add new team member</Title>
          {!showForm && (
            <Button variant="tertiary" onClick={() => flipForm(true)}>
              Add
            </Button>
          )}
        </Header>
        {showForm && <NewTeamMemberForm handleClick={handleAdd} initialValues={INITIAL_VALUES} />}
      </Card>
    </>
  );
};

export default NewTeamMember;
