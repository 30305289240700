/** @jsx jsx */
import { FONTS } from '@sayrhino/rhino-shared-js';
import React from 'react';
import PropertyUploadTable from 'components/tables/property_upload_table';
import { css, jsx } from '@emotion/core';
import { PropertyCsvUploadResponse } from 'components/tables/property_upload_table/interfaces';

interface IProps {
  data: PropertyCsvUploadResponse;
  onSubmit?: (nextStep: string, options?: PropertyCsvUploadResponse) => void;
}

const headingCSS = css({
  margin: '92px 0 60px 0',
  textAlign: 'center',
  width: '528px',
  alignSelf: 'center'
});

const pageCSS = css({
  display: 'flex',
  flexDirection: 'column'
});

const CsvErrors: React.FC<IProps> = (props: IProps) => {
  return (
    <div css={pageCSS}>
      <div css={headingCSS}>
        <h2 css={[FONTS.brandH1Bold]}>Your property list is missing some information</h2>
        <p css={[FONTS.p1, { marginTop: '28px' }]}>Add it below now, or reupload a new file.</p>
      </div>
      <PropertyUploadTable uploadResponse={props.data} onSubmit={props.onSubmit} />
    </div>
  );
};

export default CsvErrors;
