import React from 'react';
import makeServer from '../testUtils/server';
import { Route, Routes } from 'react-router-dom';
import Wrapper from '../testUtils/Wrapper';
import PropertyOwnerDetails from '../PropertyOwners/PropertyOwnerDetails';
import { getRoles, logRoles, render, screen, waitFor } from '@testing-library/react';
import { UserContext } from '../utils/userRole';
import userEvent from '@testing-library/user-event';

const mockUseLocationValue = {
  pathname: '/admin/property_owners/1',
  search: '',
  hash: '',
  state: { prevPath: '/admin/property_owners' }
};
jest.mock('react-router-dom', () => ({
  ...(jest.requireActual('react-router-dom') as {}),
  useLocation: jest.fn().mockImplementation(() => {
    return mockUseLocationValue;
  })
}));
let server;

/**
 * Test ID's
 * 1 - Integration with no validation
 * 2 - No integration, no validation
 * 3 - Validated integration
 */

const renderAsRole = (role, propertyOwnerId) =>
  render(
    <UserContext.Provider value={{ id: 1, role: role, sessionId: '', isAutoApprove: false }}>
      <Wrapper initialEntries={[`/admin/property_owners/${propertyOwnerId}`]}>
        <Routes>
          <Route path="/admin/property_owners/:propertyOwnerId" element={<PropertyOwnerDetails />} />
        </Routes>
      </Wrapper>
    </UserContext.Provider>
  );

describe('<PropertyOwnerDetails /> and <ReportForm /> with unvalidated integration', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('shows property owner details', async () => {
    renderAsRole('Administrator', 1);

    const propertyOwnerName = await screen.findByText('Owner_1');
    expect(propertyOwnerName).toBeDefined();

    const integrationButton = await screen.findByRole('button', { name: 'Edit Property Owner' });
    expect(integrationButton).toBeDefined();

    const coverageMultiplier = await screen.findByText('Coverage Multiplier');
    expect(coverageMultiplier).toBeDefined();

    const rentersInsurance = await screen.findByText('Renters Insurance');
    expect(rentersInsurance).toBeDefined();
  });

  test('shows coverage type section', async () => {
    renderAsRole('Administrator', 1);

    const coverageTypeHeader = screen.getByRole('heading', { level: 4, name: 'Coverage Type' });
    expect(coverageTypeHeader).toBeDefined();

    const coverageMultiplier = screen.getByText('Coverage Multiplier');
    expect(coverageMultiplier).toBeDefined();
  });

  test('shows renters insurance section', async () => {
    renderAsRole('Administrator', 1);

    const rentersHeader = screen.getByRole('heading', { level: 4, name: 'Renters Insurance' });
    expect(rentersHeader).toBeDefined();

    const insuranceLabel = screen.getByText('Renters insurance');
    expect(insuranceLabel).toBeDefined();

    const agreementLabel = screen.getByText('Exclusive agreement');
    expect(agreementLabel).toBeDefined();
  });

  test('shows Get Reports button when integration exists', async () => {
    renderAsRole('Administrator', 1);

    const integrationButton = await screen.findByText('Get reports');
    expect(integrationButton).toBeDefined();
  });

  test('Reports form: shows reports form when Get Reports is clicked', async () => {
    renderAsRole('Administrator', 1);

    const reportsButton = screen.getByText('Get reports');
    expect(reportsButton).toBeDefined();
    userEvent.click(reportsButton);

    const backButton = screen.getByText('Go back');
    expect(backButton).toBeDefined();

    const goLiveCheckbox = screen.getByRole('checkbox', { name: 'Go live report' });
    expect(goLiveCheckbox).toBeDefined();

    const pushCheckbox = screen.getByRole('checkbox', { name: 'Push report' });
    expect(pushCheckbox).toBeDefined();

    const generateReportsButton = screen.getByRole('button', { name: 'Generate reports' });
    expect(generateReportsButton).toBeDefined();
    expect(generateReportsButton).toBeDisabled();
  });

  test('Reports form: Enables Generate Reports button when checkbox is checked', async () => {
    renderAsRole('Administrator', 1);

    const reportsButton = screen.getByText('Get reports');
    expect(reportsButton).toBeDefined();
    userEvent.click(reportsButton);

    const goLiveCheckbox = screen.getByRole('checkbox', { name: 'Go live report' });
    expect(goLiveCheckbox).toBeDefined();

    const pushCheckbox = screen.getByRole('checkbox', { name: 'Push report' });
    expect(pushCheckbox).toBeDefined();

    const generateReportsButton = screen.getByRole('button', { name: 'Generate reports' });
    expect(generateReportsButton).toBeDefined();
    expect(generateReportsButton).toBeDisabled();

    userEvent.click(goLiveCheckbox);
    expect(generateReportsButton).not.toBeDisabled();
  });

  test('Shows edit and delete controls', async () => {
    renderAsRole('Administrator', 1);
    const integrationButton = await screen.findByRole('button', { name: 'Edit Property Owner' });
    expect(integrationButton).toBeDefined();
    const deleteButton = await screen.findByRole('button', { name: 'Delete Property Owner' });
    expect(deleteButton).toBeDefined();
  });
});

describe('<PropertyOwnerDetails /> with no integration', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('shows property owner details', async () => {
    renderAsRole('Administrator', 2);

    const propertyOwnerName = await screen.findByText('Jane Smith 7');
    expect(propertyOwnerName).toBeDefined();

    const integrationButton = await screen.findByRole('button', { name: 'Edit Property Owner' });
    expect(integrationButton).toBeDefined();

    const coverageMultiplier = await screen.findByText('Coverage Multiplier');
    expect(coverageMultiplier).toBeDefined();

    const rentersInsurance = await screen.findByText('Renters Insurance');
    expect(rentersInsurance).toBeDefined();
  });

  test("does not show Get Reports button when integration doesn't exist", async () => {
    renderAsRole('Administrator', 2);

    const integrationButton = await screen.queryByText('Get reports');
    expect(integrationButton).not.toBeInTheDocument();
  });

  test('shows integration details', async () => {
    renderAsRole('Administrator', 2);

    const coverageTypeHeader = screen.getByRole('heading', { level: 4, name: 'Integration' });
    expect(coverageTypeHeader).toBeDefined();

    const integrationButton = await screen.findByRole('button', { name: 'Set up Integration' });
    expect(integrationButton).toBeDefined();

    // things that should not be in the document
    const integrationEditButton = await screen.queryByText('Edit Integration Details');
    expect(integrationEditButton).not.toBeInTheDocument();

    const editCoverageButton = await screen.queryByText('Edit Coverage Rules');
    expect(editCoverageButton).not.toBeInTheDocument();

    const editPolicyPushButton = await screen.queryByText('Edit policy push');
    expect(editPolicyPushButton).not.toBeInTheDocument();
  });

  test('shows coverage type section', async () => {
    renderAsRole('Administrator', 2);

    const coverageTypeHeader = screen.getByRole('heading', { level: 4, name: 'Coverage Type' });
    expect(coverageTypeHeader).toBeDefined();

    const coverageMultiplier = screen.getByText('Coverage Multiplier');
    expect(coverageMultiplier).toBeDefined();
  });

  test('shows renters insurance section', async () => {
    renderAsRole('Administrator', 2);

    const rentersHeader = screen.getByRole('heading', { level: 4, name: 'Renters Insurance' });
    expect(rentersHeader).toBeDefined();

    const insuranceLabel = screen.getByText('Renters insurance');
    expect(insuranceLabel).toBeDefined();

    const agreementLabel = screen.getByText('Exclusive agreement');
    expect(agreementLabel).toBeDefined();
  });
});

describe('<PropertyOwnerDetails /> with validated integration', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('shows property owner details', async () => {
    renderAsRole('Administrator', 3);

    const propertyOwnerName = await screen.findByText('Jane Smith 5');
    expect(propertyOwnerName).toBeDefined();

    const integrationButton = await screen.findByRole('button', { name: 'Edit Property Owner' });
    expect(integrationButton).toBeDefined();

    const coverageMultiplier = await screen.findByText('Coverage Multiplier');
    expect(coverageMultiplier).toBeDefined();

    const rentersInsurance = await screen.findByText('Renters Insurance');
    expect(rentersInsurance).toBeDefined();
  });

  test('shows integration details', async () => {
    renderAsRole('Administrator', 3);

    const coverageTypeHeader = screen.getByRole('heading', { level: 4, name: 'Realpage Integration' });
    expect(coverageTypeHeader).toBeDefined();

    const integrationButton = await screen.findByText('Edit Integration Details');
    expect(integrationButton).toBeDefined();

    const editCoverageButton = await screen.findByText('Edit Coverage Rules');
    expect(editCoverageButton).toBeDefined();
  });

  test('shows coverage type section', async () => {
    renderAsRole('Administrator', 3);

    const coverageTypeHeader = screen.getByRole('heading', { level: 4, name: 'Coverage Type' });
    expect(coverageTypeHeader).toBeDefined();

    const coverageMultiplier = screen.getByText('Coverage Multiplier');
    expect(coverageMultiplier).toBeDefined();
  });

  test('shows renters insurance section', async () => {
    renderAsRole('Administrator', 3);

    const rentersHeader = screen.getByRole('heading', { level: 4, name: 'Renters Insurance' });
    expect(rentersHeader).toBeDefined();

    const insuranceLabel = screen.getByText('Renters insurance');
    expect(insuranceLabel).toBeDefined();

    const agreementLabel = screen.getByText('Exclusive agreement');
    expect(agreementLabel).toBeDefined();
  });

  test('shows Homepage Sign-Ups section', async () => {
    renderAsRole('Administrator', 3);

    const requiresInvitationHeader = screen.getByRole('heading', { level: 4, name: 'Homepage Sign-Ups' });
    expect(requiresInvitationHeader).toBeDefined();
  });
});
