import React from 'react';
import useClaimPayments from 'api/v2/useClaimPayments';
import { useParams } from 'react-router-dom';
import {
  StyledAddNewRowLink,
  StyledCell,
  StyledColumnHeader,
  StyledHeaderRow,
  StyledRow,
  StyledTableContainer,
  StyledTableFooter,
  StyledTableFrame,
  StyledTableHeader,
  StyledTableTitle,
  StyledEditCell
} from './ClaimStyles';
import { ValueCell } from './SubrogationPaymentTable';
import { centsToUSDFormatter } from '../../utils';
import { formatDate } from '../../utils/formatDate';
import Decimal from 'decimal.js';
import ClaimPaymentCard, { Status } from './ClaimPaymentCard';
import { ActionButton, Loading } from '@sayrhino/rhino-shared-js';

const ClaimsPayoutTable: React.FC = () => {
  const [showCard, setShowCard] = React.useState<boolean>();
  const { claimsId } = useParams();
  const { data: payment_info, isSuccess } = useClaimPayments(Number(claimsId)) ?? {};
  const [editId, setEditId] = React.useState<number>();

  return (
    <StyledTableContainer>
      <StyledTableHeader>
        <StyledTableTitle>Payment History</StyledTableTitle>
      </StyledTableHeader>
      <StyledTableFrame>
        {isSuccess ? (
          <tbody>
            <StyledHeaderRow>
              <StyledColumnHeader>Date</StyledColumnHeader>
              <StyledColumnHeader>Amount Paid</StyledColumnHeader>
              <StyledColumnHeader />
            </StyledHeaderRow>
            {payment_info &&
              payment_info.map((payment, index) => {
                return [
                  <StyledRow>
                    <StyledCell>{formatDate(payment.paid_at as string)}</StyledCell>
                    <ValueCell value={payment.amount_paid_cents}>
                      {centsToUSDFormatter(new Decimal(payment.amount_paid_cents))}
                    </ValueCell>
                    <StyledEditCell>
                      <ActionButton
                        usage="quiet"
                        id={`edit-${index}`}
                        variant="noLabel"
                        icon="edit"
                        size="small"
                        onClick={() => setEditId(payment.id)}
                      />
                    </StyledEditCell>
                  </StyledRow>,
                  editId === payment.id && (
                    <StyledRow>
                      <StyledCell colSpan={100}>
                        <ClaimPaymentCard
                          status={Status.Edit}
                          flipView={() => setEditId(undefined)}
                          payoutInfo={payment}
                        />
                      </StyledCell>
                    </StyledRow>
                  )
                ];
              })}
          </tbody>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </StyledTableFrame>
      <StyledTableFooter>
        {!showCard ? (
          <StyledAddNewRowLink href="#" onClick={() => setShowCard(true)}>
            + Add another payment
          </StyledAddNewRowLink>
        ) : (
          <ClaimPaymentCard status={Status.Add} flipView={() => setShowCard(false)} />
        )}
      </StyledTableFooter>
    </StyledTableContainer>
  );
};

export default ClaimsPayoutTable;
