/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import DashboardPanelWell from './DashboardPanelWell';
import DashboardPanelTitle from './DashboardPanelTitle';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { stringifyDateLong } from './utils';
import { FigureList, FigureListItem } from './FigureList';
import PercentageBar from './PercentageBar';

type IData = {
  id: string;
  label: string;
  value: number;
  color: string;
};

type IProps = {
  title: string;
  data: Array<IData>;
  updatedDate?: Date;
};

const dotCss = css({
  width: 8,
  height: 8,
  borderRadius: 8,
  display: 'inline-block'
});

const DashboardPanelPercentagesChart: React.FC<IProps> = ({ title, data, updatedDate }) => (
  <DashboardPanelWell>
    <DashboardPanelTitle>{title}</DashboardPanelTitle>

    <div css={{ marginBottom: 24 }}>
      <PercentageBar data={data} />
    </div>

    <FigureList css={{ marginTop: 24 }}>
      {data.map((item, index) => (
        <FigureListItem
          label={item.label}
          value={item.value.toString()}
          icon={<div css={[dotCss, { backgroundColor: item.color }]} />}
          key={index}
        />
      ))}
    </FigureList>

    {updatedDate && (
      <div css={[FONTS.label, { paddingTop: 16, color: PALETTE.neutral25, textAlign: 'right' }]}>
        Updated {stringifyDateLong(updatedDate)}
      </div>
    )}
  </DashboardPanelWell>
);

export default DashboardPanelPercentagesChart;
