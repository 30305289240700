import React from 'react';
import styled from '@emotion/styled';
import {
  Card as CardBase,
  PALETTE,
  FONTS,
  TextInput,
  Button as ButtonBase,
  Alert2Icon,
  InlineNotification
} from '@sayrhino/rhino-shared-js';
import ValidationBase from 'components/v2/validation';
import { keyframes } from '@emotion/react';

export const ValidationWrapper = styled.div({ paddingTop: '12px', marginBottom: '32px' });
export const Validation = styled(ValidationBase)({ marginBottom: '16px' });

export const Page = styled.div({
  background: PALETTE.brand4,
  width: '100%',
  minHeight: '100%',
  height: '100vh'
});

export const Logo = styled.img({
  width: '98px',
  height: '28px'
});

export const Header = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '45px 100px',
  marginBottom: '20px'
});

export const Heading = styled.h5([
  FONTS.h5,
  {
    marginBottom: '20px',
    color: PALETTE.brand100
  }
]);

export const TitleCard = styled.h1([
  FONTS.brandH1Bold,
  {
    fontSize: '48px',
    lineHeight: '45px',
    marginBottom: '30px'
  }
]);

export const SubText = styled.span([FONTS.p1Medium]);

export const Text = styled.span({});

export const FormArea = styled.form({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '0px 100px',
  height: 'auto!important'
});

export const Container = styled.div({
  width: '470px !important',
  height: 'auto!important'
});

export const Card = styled(CardBase)({
  padding: '35px 48px',
  height: 'auto !important',
  width: '432px!important',
  ':hover': {
    boxShadow: 'none !important',
    cursor: 'default'
  }
});

export const Button = styled(ButtonBase)(
  {
    width: '200px',
    margin: '32px 0 0',
    alignItems: 'center !important',
    display: 'flex',
    justifyContent: 'center'
  },
  (props) => ({
    backgroundColor: `${props.disabled ? PALETTE.neutral12 : PALETTE.brand100} !important`,
    color: `${props.disabled ? PALETTE.neutralDark : PALETTE.neutralLight} !important`
  })
);

export const Password = styled(TextInput)({
  height: '65px!important'
});

export const Errors = styled.div({
  margin: '10px auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const fadeOut = keyframes` 
    0% {opacity: 1}
    25% {opacity: .6}
    50% {opacity: .4}
    100% {opacity: 0}
  `;

export const ErrorItem = styled(InlineNotification)({
  width: '360px',
  maxWidth: '360px',
  borderRadius: '8px',
  animation: `${fadeOut} 1s ease-out 4s`
});

export const ErrorNotification = ({ error }: { error: string }) => {
  return (
    <Errors>
      <ErrorItem variant="highPriority">
        <div style={{ color: 'unset' }}>
          <span style={{ color: 'unset' }}>{error}</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', color: 'unset' }}>
          <Alert2Icon color={PALETTE.alert100} style={{ marginLeft: '8px' }} height="20px" width="20px" />
        </div>
      </ErrorItem>
    </Errors>
  );
};
