import { IRentersInsurancePolicyDetails } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

const getRentersInsurancePolicyDetails = async (userId: number) => {
  const { data } = await axios.get<IRentersInsurancePolicyDetails[]>(
    `/v2/admin/renters_insurance_policies/by_user_id/${userId}.json`
  );
  return data;
};

export default function useRentersInsurancePolicyDetails(
  userId: number
): QueryObserverResult<IRentersInsurancePolicyDetails[]> {
  return useQuery<IRentersInsurancePolicyDetails[]>(['renters insurance policy details', userId], () =>
    getRentersInsurancePolicyDetails(userId)
  );
}
