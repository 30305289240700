/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { ChangeEvent, FocusEvent } from 'react';
import { jsx, css } from '@emotion/core';
import { ArrowRight, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

import Decimal from 'decimal.js';
import { ICoverageOptionSelectProps } from '.';
import { MULTIPLIER } from '../utils/constants';

const errorCSS = css([
  {
    color: 'red',
    marginTop: 4,
    marginBottom: 8
  },
  FONTS.p3
]);

export default function MultiplierOption(props: ICoverageOptionSelectProps) {
  const { coverageInfo, errors } = props;
  const { setType, setSelected, selected, multipliers } = coverageInfo;

  const multiplierOptions = multipliers.map((opt, i) => (
    <option
      value={opt.value.toString()}
      key={`multiplier-${i}`}
      id={`multiplier-${opt.value}`}
      aria-selected={selected?.value?.toNumber() === opt.value.toNumber()}
    >
      {opt.label}
    </option>
  ));

  function onChange(e: ChangeEvent<HTMLSelectElement> | FocusEvent<HTMLSelectElement>) {
    setSelected({
      type: MULTIPLIER,
      value: new Decimal(e.target.value)
    });
  }

  return (
    <div
      css={{
        paddingBottom: 4,
        marginBottom: 84
      }}
    >
      <div
        css={{
          width: '100%',
          border: 'none',
          borderBottom: `${PALETTE.neutral12} solid 1px`,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <select
          aria-label="Rent multiplier"
          onBlur={(e) => onChange(e)}
          onChange={(e) => onChange(e)}
          css={{ border: 'none', appearance: 'none' }}
          value={selected?.value?.toString()}
        >
          {multiplierOptions}
        </select>
        <ArrowRight style={{ transform: 'rotate(90deg)', color: PALETTE.neutral25 }} />
      </div>
      {errors && errors?.coverage_months && <p css={errorCSS}> {errors.coverage_months} </p>}
    </div>
  );
}
