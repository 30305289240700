import { render } from '@testing-library/react';
import App from '../index';
import makeServer from '../testUtils/server';
import React from 'react';

describe('<App />', () => {
  let server;
  beforeEach(() => (server = makeServer()));
  afterEach(() => server.shutdown());

  test('renders the dashboard panel summary metrics view when the current user is an administrator', async () => {
    const { findByText } = render(<App user={{ id: 1, session_uuid: '', role: 'Administrator' }} />);
    const panelHeading = await findByText('Portfolio overview');

    expect(panelHeading).toBeDefined();
  });

  test('renders the dashboard panel summary metrics view when the current user is a property manager', async () => {
    const { findByText } = render(<App user={{ id: 2, session_uuid: '', role: 'PropertyManager' }} />);
    const panelHeading = await findByText('Portfolio overview');

    expect(panelHeading).toBeDefined();
  });
});
