import { IClaim } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

const getClaim = async (id: number) => {
  const { data } = await axios.get<IClaim>(`/v2/admin/claims/${id}`);
  return data;
};

export default function useClaim(id: number): QueryObserverResult<IClaim> | undefined {
  if (id) return useQuery<IClaim>(['claim', id], () => getClaim(id));
}
