import styled from '@emotion/styled';
import { Loading, Table } from '@sayrhino/rhino-shared-js';
import { ColumnProp } from '@sayrhino/rhino-shared-js/build/components/Table/types';
import useChangelog from 'api/v2/useClaimChangelog';
import React from 'react';
import { IClaimChangelog } from '../../interfaces';
import { LoadingWrapper } from './ClaimStyles';

const StyledTableContainer = styled.div({ padding: '8px', overflow: 'auto' });
const StyledContainer = styled.div({ minHeight: '200px' });

const ClaimChangelog = ({ claimId }) => {
  const { data: rawChangelog, isSuccess, isLoading } = useChangelog(Number(claimId)) ?? {};
  const chartData: object[] = isSuccess && rawChangelog ? rawChangelog.audits : [];
  const columns: ColumnProp[] = [
    {
      id: 'createdAt',
      title: 'Date',
      rowIndex: 'createdAt'
    },
    {
      id: 'user',
      title: 'By',
      rowIndex: 'user'
    },
    {
      id: 'status',
      title: 'Status',
      rowIndex: 'status'
    }
  ];

  const Loader = () => {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  };

  return (
    <StyledContainer>
      {isLoading ? (
        Loader()
      ) : (
        <StyledTableContainer>
          {chartData.length > 0 && <Table columns={columns} dataSource={chartData} />}
          {chartData.length === 0 && <p>No records exist.</p>}
        </StyledTableContainer>
      )}
    </StyledContainer>
  );
};

export default ClaimChangelog;
