import Input from 'components/v2/input';
import React from 'react';
import { FONTS } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import RhinoLogo from '../../../../../assets/images/RhinoLogoRaptor.svg';

interface IProps {
  resource: string;
}

export const Login = (props: IProps) => {
  const { resource } = props;
  const currentUrl = new URL(window.location.href);
  const userEmail = currentUrl.searchParams.get('email');

  const ContentContainer = styled.div({
    padding: '76px 48px 0px 48px'
  });

  const ErrorMessage = styled.div({
    display: 'flex-start',
    backgroundColor: '#fdf7f7',
    borderRadius: '8px',
    padding: '4px 24px 0px 24px'
  });

  const ErrorContainer = styled.div({
    padding: '0px 0px 24px 0px'
  });

  const ErrorText = styled.p([FONTS.p2], {
    color: '#A72131'
  });

  const LogoContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 0px 24px 0px'
  });

  const EmailContainer = styled.div({
    padding: '0px 0px 8px 0px'
  });

  const Header = styled.h3([FONTS.h3], {
    textAlign: 'center',
    padding: '0px 0px 24px 0px'
  });

  const Logo = styled.img({
    height: '36px',
    width: '114px'
  });

  return (
    <ContentContainer>
      <LogoContainer>
        <Logo src={RhinoLogo} alt="Rhino" />
      </LogoContainer>
      <Header>Partner Portal</Header>
      {window.location.href.includes('email') && (
        <ErrorContainer>
          <ErrorMessage>
            <ErrorText>
              <strong>Oops!</strong> You tried to sign in as a Renter with a Partner login - try again here.
            </ErrorText>
          </ErrorMessage>
        </ErrorContainer>
      )}
      <EmailContainer>
        <Input
          label="Email address"
          type="email"
          resource={resource}
          field="email"
          defaultValue={userEmail ? userEmail : ''}
        />
      </EmailContainer>
      <Input label="Password" type="password" toggleVisibility={true} />
    </ContentContainer>
  );
};

export default Login;
