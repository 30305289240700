import { render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import { subscribeDownloadChannel } from '../../../../utils/cable';
import { FilterContext } from '../utils/filters';
import InsurancePolicyList from '../InsurancePolicyList';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { UserContext } from '../utils/userRole';
import { userTypes } from '../interfaces';

jest.mock('../../../../utils/cable');

let server;

const filters = {
  debouncedFilters: {
    states: [],
    properties: [],
    statuses: []
  },
  filtersCount: 0,
  selectedFilters: {
    states: [],
    properties: [],
    statuses: []
  },
  setFilters: ({}) => undefined
};

describe('<InsurancePolicyList />', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('renders policies list', async () => {
    const { findByText } = render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InsurancePolicyList />
        </FilterContext.Provider>
      </Wrapper>
    );
    const heading = await findByText('Policies');
    const policy = await findByText('Josh Conel');
    const statusBanner = await findByText('Review and update information by Oct 7, 2020');

    expect(heading).toBeDefined();
    expect(heading).toHaveTextContent('Policies');

    expect(policy).toBeDefined();
    expect(policy).toHaveTextContent('Josh Conel');

    expect(statusBanner).toBeDefined();
    expect(statusBanner).toHaveTextContent('Review and update information by Oct 7, 2020');
  });

  test('renders filter button', async () => {
    const { findByRole } = render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InsurancePolicyList />
        </FilterContext.Provider>
      </Wrapper>
    );

    const filterLink = await findByRole('link', { name: 'Filter' });

    expect(filterLink).toBeDefined();
  });

  describe.each([['Administrator'], ['PropertyManager'], ['PropertyManagerAdmin']])(
    'can download CSV',
    (role: userTypes) => {
      test('channel subscribed and download button disabled', async () => {
        const { findByRole } = render(
          <UserContext.Provider value={{ role, id: 1, sessionId: '', isAutoApprove: false }}>
            <Wrapper>
              <FilterContext.Provider value={filters}>
                <InsurancePolicyList />
              </FilterContext.Provider>
            </Wrapper>
          </UserContext.Provider>
        );
        const downloadCSVButton = await findByRole('button', { name: 'Save .CSV' });

        userEvent.click(downloadCSVButton);

        expect(subscribeDownloadChannel).toHaveBeenCalled();
        expect(downloadCSVButton).toBeDisabled();
        expect(downloadCSVButton).toHaveTextContent('Saving...');
      });
    }
  );

  describe.each([['ListingAgent'], ['ThirdPartyAdministrator']])('cannot download CSV', (role: userTypes) => {
    test('download button not visible', async () => {
      const { queryByRole } = render(
        <UserContext.Provider value={{ role: role, id: 1, sessionId: '', isAutoApprove: false }}>
          <Wrapper>
            <FilterContext.Provider value={filters}>
              <InsurancePolicyList />
            </FilterContext.Provider>
          </Wrapper>
        </UserContext.Provider>
      );
      const downloadCSVButton = queryByRole('button', { name: 'Save .CSV' });

      expect(downloadCSVButton).not.toBeInTheDocument();
    });
  });
});
