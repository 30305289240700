import { RefObject, useContext, useEffect } from 'react';
import { userTypes } from '../interfaces';
import { IUserContext, UserContext } from './userRole';

export type SegmentUser = {
  user_id: number;
  portal_session_id: string;
  user_type: userTypes;
  isAdmin?: boolean;
  showUploadErrorBanner?: boolean;
};

// helper to convert the user object in context to the specific segment compatible shape
const mapToSegmentUser = (user: IUserContext): SegmentUser => ({
  user_id: user.id,
  portal_session_id: user.sessionId,
  user_type: user.role
});

export const useSegmentUser = () => {
  const user = useContext(UserContext);
  const segmentUser = mapToSegmentUser(user);
  return segmentUser;
};

export const useSegmentTrackOnLoad = (eventName: string, params: {} = {}) => {
  const user = mapToSegmentUser(useContext(UserContext));

  useEffect(() => {
    window.analytics.track(eventName, { ...user, ...params });
  }, []);
};

export const useSegmentTrackProgress = (eventName: string, formRef: RefObject<HTMLDivElement>, params: {} = {}) => {
  const user = useSegmentUser();

  const trackFilledFields = () => {
    const node = formRef.current;
    if (node) {
      const inputFields = Array.from(node.getElementsByTagName('input'));
      const filledCount = inputFields.reduce((acc, currentInput) => {
        return currentInput.value.length !== 0 ? acc + 1 : acc;
      }, 0);

      window.analytics.track(eventName, { ...user, ...params, filled_field_count: filledCount });
    }
  };

  window.addEventListener('beforeunload', trackFilledFields);

  return () => {
    window.removeEventListener('beforeunload', trackFilledFields);
  };
};
