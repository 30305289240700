/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';
import useClaimPropertyFilterOptions from 'api/v2/useClaimPropertyFilterOptions';
import useClaimStaticFilterOptions from 'api/v2/useClaimStaticFilterOptions';
import useAdministrators from 'api/v2/useAdministrators';
import { useFilterContext } from '../utils/filters';
import { CLAIM_TOP_STATUS_FILTER_OPTIONS } from '../utils/constants';
import Filters, { ClearButton, Divider, SectionTitle, TitleContainer, TitleWrapper } from './index';
import MarketFilterOptions from './MarketFilterOptions';
import PropertyFilterOptions from './PropertyFilterOptions';
import ClaimStatusFilterOptions from './ClaimStatusFilterOptions';
import useUserRole, { useIsClaimAdmin } from '../utils/userRole';
import ClaimAdminFilterOptions from './ClaimAdminFilterOptions';
import { StyledClaimClassificationFilterOptions } from './ClaimFilterStyles';

const InsurancePolicyFilters = () => {
  const { selectedFilters, setFilters } = useFilterContext();
  const isClaimAdmin = useIsClaimAdmin();
  const { isPropertyManager, isPropertyManagerAdmin, isListingAgent } = useUserRole();
  const hideMarketFilters = isPropertyManager || isPropertyManagerAdmin || isListingAgent;

  return (
    <Filters
      selectedFilters={selectedFilters}
      setFilters={setFilters}
      staticFilterOptions={useClaimStaticFilterOptions}
    >
      <PropertyFilterOptions
        css={{ paddingLeft: '48px', paddingRight: '48px' }}
        query={useClaimPropertyFilterOptions}
        selectedProperties={selectedFilters.properties}
        selectedStates={selectedFilters.states}
        setFilters={setFilters}
      />
      {!hideMarketFilters && (
        <MarketFilterOptions
          css={{ paddingLeft: '48px', paddingRight: '48px' }}
          query={useClaimStaticFilterOptions}
          selectedMarkets={selectedFilters?.markets}
          setFilters={setFilters}
        />
      )}
      <TitleContainer>
        <TitleWrapper>
          <SectionTitle>Claims</SectionTitle>
          {selectedFilters.statuses.length ? (
            <ClearButton onClick={() => setFilters({ statuses: [] })}>Clear</ClearButton>
          ) : null}
        </TitleWrapper>
        <Divider />
      </TitleContainer>
      {isClaimAdmin && (
        <ClaimAdminFilterOptions
          css={{ paddingLeft: '48px', paddingRight: '48px' }}
          query={useAdministrators}
          selectedAdmins={selectedFilters?.administrators}
          setFilters={setFilters}
        />
      )}
      <StyledClaimClassificationFilterOptions
        query={useClaimStaticFilterOptions}
        selectedClassifications={selectedFilters?.claim_classifications}
        setFilters={setFilters}
      />
      <ClaimStatusFilterOptions
        css={{
          borderBottom: `1px solid ${PALETTE.neutral4}`,
          marginBottom: '1.5rem',
          paddingBottom: '1.5rem',
          paddingLeft: '48px',
          paddingRight: '48px'
        }}
        query={useClaimStaticFilterOptions}
        selectedStatuses={selectedFilters.statuses}
        setFilters={setFilters}
        title="Claim Status"
        topStatuses={CLAIM_TOP_STATUS_FILTER_OPTIONS}
      />
    </Filters>
  );
};

export default InsurancePolicyFilters;
