import React from 'react';

interface OverlayState {
  showOverlay: boolean;
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

const OverlayContext = React.createContext<OverlayState>({ showOverlay: false, setShowOverlay: () => false });

export const OverlayProvider = (props) => {
  const [showOverlay, setShowOverlay] = React.useState<boolean>(false);
  return <OverlayContext.Provider value={{ setShowOverlay, showOverlay }} {...props} />;
};

export const useOverlay = () => {
  const { showOverlay, setShowOverlay } = React.useContext(OverlayContext);
  return {
    showOverlay,
    setShowOverlay
  };
};

export default useOverlay;
