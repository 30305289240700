import { act, render, screen } from '@testing-library/react';
import React from 'react';
import { FilterContext } from '../utils/filters';
import { InvitationList } from '../InvitationList';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import { UserContext } from '../utils/userRole';
import { userTypes } from '../interfaces';

let server;
const filters = {
  debouncedFilters: {
    states: [],
    properties: [],
    statuses: []
  },
  filtersCount: 0,
  selectedFilters: {
    states: [],
    properties: [],
    statuses: []
  },
  setFilters: ({}) => undefined
};

describe('<InvitationList />', () => {
  beforeEach(() => {
    const mockIntersectionObserver = jest.fn();
    mockIntersectionObserver.mockReturnValue({
      observe: () => null,
      unobserve: () => null
    });
    window.IntersectionObserver = mockIntersectionObserver;
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the invitations header', async () => {
    render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InvitationList />
        </FilterContext.Provider>
      </Wrapper>
    );
    const heading = await screen.findByText('Invitations');

    expect(heading).toBeDefined();
    expect(heading).toHaveTextContent('Invitations');
  });

  test('It only renders invitations list page one', async () => {
    render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InvitationList />
        </FilterContext.Provider>
      </Wrapper>
    );
    const invitation1Name = await screen.findByText('Emmerson Neubelt');
    const invitation1CoverageType = await screen.findByText('$500');
    const invitation2Name = await screen.findByText('Lexi Bell');
    const invitation2CoverageType = await screen.findByText('2.5x Monthly Rent');
    const invitation3Name = screen.queryByText('Archie Schramm');

    expect(invitation1Name).toBeDefined();
    expect(invitation1Name).toHaveTextContent('Emmerson Neubelt');

    expect(invitation1CoverageType).toBeDefined();
    expect(invitation1CoverageType).toHaveTextContent('$500');

    expect(invitation2Name).toBeDefined();
    expect(invitation2Name).toHaveTextContent('Lexi Bell');

    expect(invitation2CoverageType).toBeDefined();
    expect(invitation2CoverageType).toHaveTextContent('2.5x Monthly Rent');

    expect(invitation3Name).not.toBeInTheDocument();
  });

  test('It should display the proper coverage Type', async () => {
    render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InvitationList />
        </FilterContext.Provider>
      </Wrapper>
    );
    const invitation1Name = await screen.queryByText('Lexi Bell');
    const invitation1RentMultiplier = await screen.queryByText('2.5x Monthly Rent');
    const invitation1RentValue = await screen.queryByText('$3,800');
    const invitation2Name = await screen.queryByText('Cora Feenan');
    const invitation2CoverageAmount = await screen.queryByText('$150');
    const invitation3Name = await screen.queryByText('Ellie Nelson');
    const invitation3RentMultiplier = await screen.queryByText('1x Monthly Rent');
    const invitation4Name = await screen.queryByText('Matthew Crawley');
    const invitation4Subscribed = await screen.queryByText('Oct 13, 2020 - Oct 12, 2021');

    // coverage with rent multiplier & rent value entered
    expect(invitation1Name).toBeDefined();
    expect(invitation1Name).toHaveTextContent('Lexi Bell');
    expect(invitation1RentMultiplier).toBeDefined();
    expect(invitation1RentMultiplier).toHaveTextContent('2.5x Monthly Rent');
    expect(invitation1RentValue).toBeDefined();
    expect(invitation1RentValue).toHaveTextContent('$3,800');

    // coverage with specific dollar amount
    expect(invitation2Name).toBeDefined();
    expect(invitation2Name).toHaveTextContent('Cora Feenan');
    expect(invitation2CoverageAmount).toBeDefined();
    expect(invitation2CoverageAmount).toHaveTextContent('$150');

    // coverage with rent multiplier, no rent value entered
    expect(invitation3Name).toBeDefined();
    expect(invitation3Name).toHaveTextContent('Ellie Nelson');
    expect(invitation3RentMultiplier).toBeDefined();
    expect(invitation3RentMultiplier).toHaveTextContent('1x Monthly Rent');

    // coverage when subscribed shows dates
    expect(invitation4Name).toBeDefined();
    expect(invitation4Name).toHaveTextContent('Matthew Crawley');
    expect(invitation4Subscribed).toBeDefined();
    expect(invitation4Subscribed).toHaveTextContent('Oct 13, 2020 - Oct 12, 2021');
  });

  test('It should show email if name is null', async () => {
    render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InvitationList />
        </FilterContext.Provider>
      </Wrapper>
    );
    const invitationEmail = await screen.getByRole('heading', { level: 4, name: /sybil@downton.org/ });

    expect(invitationEmail).toBeDefined();
    expect(invitationEmail).toHaveTextContent('sybil@downton.org');
  });

  test('It should show - if unit is null', async () => {
    render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InvitationList />
        </FilterContext.Provider>
      </Wrapper>
    );
    const invitationUnit = await screen.getByRole('heading', { level: 4, name: /-/ });

    expect(invitationUnit).toBeDefined();
    expect(invitationUnit).toHaveTextContent('-');
  });

  test('It fetches more invitations when the scrolling to the bottom of page', async () => {
    render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InvitationList />
        </FilterContext.Provider>
      </Wrapper>
    );
    await act(async () => {
      // mocking the intersection ovserver
      await new Promise((resolve) => requestAnimationFrame(resolve));
    });
    const invitation3Name = await screen.findByText('Archie Schramm');
    const invitation3CoverageType = await screen.findByText('Nov 13, 2020 - Nov 12, 2021');
    const invitation4Name = await screen.findByText('Ellis Adams');
    const invitation4CoverageType1 = await screen.findByText('2x Monthly Rent');
    const invitation4CoverageType2 = screen.queryAllByText('∙')[0];
    const invitation4CoverageType3 = await screen.findByText('$4,800');
    const invitation5CoverageType1 = await screen.findByText('4x Monthly Rent');
    const invitation5CoverageType3 = await screen.findByText('$5,666');

    expect(invitation3Name).toBeDefined();
    expect(invitation3Name).toHaveTextContent('Archie Schramm');

    expect(invitation3CoverageType).toBeDefined();
    expect(invitation3CoverageType).toHaveTextContent('Nov 13, 2020 - Nov 12, 2021');

    expect(invitation4Name).toBeDefined();
    expect(invitation4Name).toHaveTextContent('Ellis Adams');

    expect(invitation4CoverageType1).toBeDefined();
    expect(invitation4CoverageType1).toHaveTextContent('2x Monthly Rent');
    expect(invitation4CoverageType2).toBeDefined();
    expect(invitation4CoverageType2).toHaveTextContent('∙');
    expect(invitation4CoverageType3).toBeDefined();
    expect(invitation4CoverageType3).toHaveTextContent('$4,800');

    expect(invitation5CoverageType1).toBeDefined();
    expect(invitation5CoverageType1).toHaveTextContent('4x Monthly Rent');
    expect(invitation5CoverageType3).toBeDefined();
    expect(invitation5CoverageType3).toHaveTextContent('$5,666');
  });

  test('renders filter button', async () => {
    const { findByRole } = render(
      <Wrapper>
        <FilterContext.Provider value={filters}>
          <InvitationList />
        </FilterContext.Provider>
      </Wrapper>
    );

    const filterLink = await findByRole('link', { name: 'Filter' });

    expect(filterLink).toBeDefined();
  });

  describe.each([['Administrator'], ['PropertyManager'], ['PropertyManagerAdmin'], ['ListingAgent']])(
    'can send invitation',
    (role: userTypes) => {
      test('send invitation button visible', async () => {
        render(
          <Wrapper>
            <UserContext.Provider value={{ role, canSendInvites: true, id: 1, sessionId: '', isAutoApprove: false }}>
              <FilterContext.Provider value={filters}>
                <InvitationList />
              </FilterContext.Provider>
            </UserContext.Provider>
          </Wrapper>
        );
        const sendInvitationButton = screen.getByRole('link', { name: /send invitation/i });

        expect(sendInvitationButton).toBeInTheDocument();
      });
    }
  );

  describe.each([['PropertyManager'], ['ListingAgent']])('UDR and Highmark employees', (role: userTypes) => {
    test('send invitation button not visible', async () => {
      render(
        <Wrapper>
          <UserContext.Provider value={{ role, canSendInvites: false, id: 1, sessionId: '', isAutoApprove: false }}>
            <FilterContext.Provider value={filters}>
              <InvitationList />
            </FilterContext.Provider>
          </UserContext.Provider>
        </Wrapper>
      );
      const sendInvitationButton = screen.queryByText('Send Invitation');

      expect(sendInvitationButton).toBeNull();
    });
  });

  describe('ThirdPartyAdministrator cannot send invitation', () => {
    test('send invitation button not visible', async () => {
      render(
        <UserContext.Provider value={{ role: 'ThirdPartyAdministrator', id: 1, sessionId: '', isAutoApprove: false }}>
          <Wrapper>
            <FilterContext.Provider value={filters}>
              <InvitationList />
            </FilterContext.Provider>
          </Wrapper>
        </UserContext.Provider>
      );
      const sendInvitationButton = screen.queryByText('Send Invitation');

      expect(sendInvitationButton).toBeNull();
    });
  });
});
