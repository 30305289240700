import { PALETTE } from '@sayrhino/rhino-shared-js';
import React, { useState } from 'react';
import {
  ICoverageRule,
  ICoverageRulesetPostNew,
  IRealPageIntegration,
  IRulesetError,
  IYardiIntegration
} from '../interfaces';
import CoverageRuleSwitchNew from './CoverageRuleSwitchNew';
import { IInviteCriteria } from './interface';
import {
  Card,
  Header,
  Row,
  StyledEditLink,
  StyledRowLi,
  StyledTag,
  StyledTagWrapper,
  StyledColLi,
  StyledColLiTitle
} from './styles/CoverageRuleCardStyles';
import { realpageScreening, yardiCreditCheckStatus, yardiScreening } from './util/rulesetConstant';
import { coverageValue } from '../utils';

interface IRulesetCard {
  index?: number;
  ruleset: ICoverageRulesetPostNew;
  integration?: IRealPageIntegration | IYardiIntegration;
  newRulesets: ICoverageRulesetPostNew[];
  updateCustomRulesets: ({ ruleset, index, closeEditRuleset }) => void;
  removeRuleset: (ruleset: ICoverageRulesetPostNew) => void;
  coverageRules: (value: ICoverageRule[]) => void;
  addInviteCriteria: ({ screening, creditCheckStatus, userRenter }: IInviteCriteria) => void;
  partnerRulesetError: IRulesetError;
  setPartnerRulesetsError: (value: IRulesetError) => void;
  toggleExpandStatus: (key: string, status: boolean) => void;
}

const RulesetCard = ({
  index,
  ruleset,
  integration,
  newRulesets,
  removeRuleset,
  updateCustomRulesets,
  coverageRules,
  addInviteCriteria,
  partnerRulesetError,
  setPartnerRulesetsError,
  toggleExpandStatus
}: IRulesetCard) => {
  const [showEditRuleset, setShowEditRuleset] = useState(false);

  const closeEditRuleset = () => {
    toggleExpandStatus(`customRuleSetCard-${index}`, false);
    setShowEditRuleset(false);
  };

  const screening = (allowed_screening_results) => {
    const screening = integration?.type === 'yardi' ? yardiScreening : realpageScreening;
    const filter = screening.filter((item) => allowed_screening_results?.includes(item.value));
    return filter.length > 0 ? filter.map((item) => item.name).join(', ') : 'N/A';
  };

  const creditCheckStatus = (credit_check_statuses) => {
    const filter = yardiCreditCheckStatus.filter((item) => credit_check_statuses?.includes(item.value));
    return filter.length > 0 ? filter.map((item) => item.name).join(', ') : 'N/A';
  };

  const coverageCopy = (coverageRule: ICoverageRule) => {
    const sameCashAmount = coverageRule.same_cash_deposit === false ? false : true;
    const coverageOptionName = coverageRule.coverage_option_name;
    const formattedCoverageValue = coverageValue(coverageRule.coverage_type, coverageRule.coverage_value);
    const formattedCashValue = sameCashAmount ?
      formattedCoverageValue :
      coverageValue(coverageRule.cash_deposit_type, coverageRule.cash_deposit_coverage_value);
      
    return {
      deposit_insurance: `${coverageOptionName} = ${formattedCoverageValue}`,
      cash_deposit: `${coverageOptionName} = ${formattedCashValue}`
    };
  };

  return (
    <Card variant="border" style={{ border: `1px solid ${PALETTE.neutral12} ` }}>
      {showEditRuleset ? (
        <>
          <Row>
            <StyledEditLink onClick={closeEditRuleset}>close</StyledEditLink>
          </Row>
          <CoverageRuleSwitchNew
            index={index}
            id={`customRuleSetCard-${index}`}
            integration={integration?.type}
            label="Custom"
            coverageRules={coverageRules}
            ruleset={ruleset}
            closeEditRuleset={closeEditRuleset}
            addInviteCriteria={addInviteCriteria}
            customRulesets={newRulesets}
            setCustomRulesets={updateCustomRulesets}
            partnerRulesetError={partnerRulesetError}
            setPartnerRulesetsError={setPartnerRulesetsError}
            toggleExpandStatus={toggleExpandStatus}
          />
        </>
      ) : (
        <>
          <Header>
            <StyledEditLink onClick={() => {
              setShowEditRuleset(true)
              toggleExpandStatus(`customRuleSetCard-${index}`, true);
              }}>Edit</StyledEditLink>
            <StyledEditLink onClick={() => removeRuleset(ruleset)}>Remove</StyledEditLink>
          </Header>
          <ul>
            <StyledRowLi>
              Name
              <span>{ruleset.name}</span>
            </StyledRowLi>
            <StyledRowLi>
              Screening Results
              <span>{screening(ruleset.invite_criteria?.allowed_screening_results)}</span>
            </StyledRowLi>
            <StyledRowLi>
              Credit Check Statuses
              <span>{creditCheckStatus(ruleset.invite_criteria?.allowed_credit_check_statuses)}</span>
            </StyledRowLi>
            <StyledRowLi>
              Resident has been approved by partner user
              <span>{ruleset.invite_criteria?.use_renter_approval_date ? 'Yes' : 'No'}</span>
            </StyledRowLi>
            <StyledRowLi>
              {ruleset.coverage_rules.length > 0 ? (
                <>
                  <StyledColLi>
                    <StyledColLiTitle>Deposit Insurance</StyledColLiTitle>
                    <div>
                      {ruleset.coverage_rules.map((coverageRule) =>
                        <div>{coverageCopy(coverageRule)['deposit_insurance']}</div>
                      )}
                    </div>
                  </StyledColLi>
                  <StyledColLi>
                    <StyledColLiTitle>Cash Deposit</StyledColLiTitle>
                    <div>
                      {ruleset.coverage_rules.map((coverageRule) =>
                        <div>{coverageCopy(coverageRule)['cash_deposit']}</div>
                      )}
                    </div>
                  </StyledColLi>
                </>
              ) : "No Coverage Rules"}
            </StyledRowLi>
          </ul>
          {ruleset.properties && (
            <StyledTagWrapper>
              {ruleset.properties[0]?.id && <StyledTag>{ruleset.properties[0].address_line_one}</StyledTag>}
              {ruleset.properties[1]?.id && <StyledTag>{ruleset.properties[1].address_line_one}</StyledTag>}
              {ruleset.properties.length > 2 && <StyledTag>+ {ruleset.properties.length - 2}</StyledTag>}
            </StyledTagWrapper>
          )}
        </>
      )}
    </Card>
  );
};

export default RulesetCard;
