import React from 'react';
import styled from '@emotion/styled';
import { PALETTE, Alert2Icon, InlineNotification } from '@sayrhino/rhino-shared-js';
import { IClaimStatusNotification } from '../../interfaces';

interface IProps {
  statusNotification: IClaimStatusNotification;
}

const Notification = styled(InlineNotification)({
  margin: '24px 0'
});
const NotificationText = styled.div({ width: '368px', color: 'inherit' });
const NotificationTitle = styled.span({ color: 'inherit' });

const ClaimNotification: React.FC<IProps> = ({ statusNotification }) => {
  return (
    <Notification
      variant={statusNotification.severity === 'high_priority' ? 'highPriority' : 'passive'}
      style={{ height: 'auto', width: 'auto', padding: '12px 24px', borderRadius: '8px' }}
    >
      <NotificationText>
        <NotificationTitle>{statusNotification.title}</NotificationTitle> {statusNotification.title && '-'}{' '}
        {statusNotification.label}
      </NotificationText>

      <Alert2Icon color={statusNotification.severity === 'high_priority' ? PALETTE.alert100 : PALETTE.neutral55} />
    </Notification>
  );
};

export default ClaimNotification;
