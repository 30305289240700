import React from 'react';
import CardSelection from './../CardSelection';
import styled from '@emotion/styled';
import { Button, DownloadIcon, Card, PALETTE, FONTS, Alert2Icon } from '@sayrhino/rhino-shared-js';
import { redirectTo } from '../../utils';
import { SegmentUser } from '../../utils/segmentTracker';
import { useNavigate } from 'react-router-dom';

interface IProps {
  user: SegmentUser;
}

export const downloadCsv = () => {
  window.location.assign('/admin/properties/generate_template_csv');
};

const Page = styled.div({
  height: 'auto',
  minHeight: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  background: PALETTE.brand4
});

const Title = styled.h2([
  FONTS.h2,
  {
    fontSize: '32px',
    lineHeight: '44px',
    textAlign: 'center',
    marginBottom: '28px'
  }
]);

const SubTitle = styled.p([
  FONTS.p1Light,
  {
    fontSize: '16px',
    lineHeight: '28px',
    textAlign: 'center'
  }
]);

const Header = styled.div({
  width: '528px',
  margin: '0 auto 44px'
});

const CardStyle = styled.div({
  display: 'flex',
  justifyContent: 'center',
  margin: '0px 25px',
  width: '350px'
});

const MainCard = styled(Card)({
  margin: '0px 25px',
  border: '1px solid #E3E3E3'
});

const ButtonContainer = styled.div({
  marginTop: '48px',
  display: 'flex',
  justifyContent: 'center'
});

const DownloadButton = styled.span({
  margin: '0 5px',
  borderRadius: '8px'
});

const ErrorBanner = styled.div({
  padding: '12px 24px',
  marginTop: '40px',
  borderRadius: '8px',
  background: '#FFECE6',
  display: 'flex',
  alignItems: 'center',
  p: [
    {
      color: '#000000',
      fontSize: '16px',
      lineHeight: '28px',
      fontFamily: 'MaisonNeueExtendedMedium'
    }
  ]
});

const AddPropertiesOption: React.FC<IProps> = (props) => {
  const { user } = props;
  const { user_id, portal_session_id, user_type, showUploadErrorBanner } = user;
  const track = (value) => {
    window.analytics.track(value, {
      user_id: user_id,
      portal_session_id: portal_session_id,
      user_type: user_type
    });
  };

  const navigate = useNavigate();

  return (
    <Page>
      <Header>
        <Title>Add your first property</Title>
        <SubTitle>
          Manually add properties one at a time, or add several at once by uploading a list — whichever is easiest for
          you.
        </SubTitle>
      </Header>
      <div>
        <CardStyle>
          <MainCard>
            <CardSelection
              heading="Create new property"
              bodyText="Manually add new properties to Portal. Don’t worry, we’ll guide you through it."
              id="new-property-card"
              onClick={() => {
                track('ADD SINGLE PROPERTY Button Clicked');
                navigate('../single');
              }}
            />
          </MainCard>
          <MainCard>
            <CardSelection
              heading="Bulk import properties"
              bodyText="Add your properties to our CSV template and upload it to import all your properties at once."
              id="batch-property-card"
              onClick={() => {
                track('ADD MULTIPLE PROPERTIES Button Clicked');
                redirectTo('/admin/properties/add/bulk');
              }}
            />
          </MainCard>
        </CardStyle>
      </div>

      {showUploadErrorBanner &&
        <ErrorBanner>
          <Alert2Icon color={PALETTE.alert100} style={{ margin: '8px', marginTop: '-14px' }} height="26px" width="26px" />
          <div>
            <p>Please reach out to your Account Manager or Rhino Support to upload properties that support Rhino Cash Deposit collection.</p>
            <p>Self-upload will result in delays in fund transfers.</p>
          </div>
        </ErrorBanner>
      }

      < ButtonContainer >
        <DownloadButton>
          <Button
            variant="tertiary"
            onClick={() => {
              track('DOWNLOAD CSV TEMPLATE Button Clicked');
              downloadCsv();
            }}
            id="download-csv-button"
          >
            Download CSV template <DownloadIcon />
          </Button>
        </DownloadButton>
      </ButtonContainer>
    </Page>
  );
};

export default AddPropertiesOption;
