import axios from 'axios';
import { ICoverageRulesetPost } from 'components/v2/App/interfaces';
import { csrfToken } from 'utils/document';

const createBulkCoverageRules = async (coverageRulesets: ICoverageRulesetPost[]) => {
  const token = csrfToken();

  const response = await axios.put<ICoverageRulesetPost[]>(
    `/v2/integrations/coverage_rule_sets/bulk_update`,
    {
      integrations: {
        coverage_rule_sets: coverageRulesets
      }
    },
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default createBulkCoverageRules;
