/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PALETTE, FONTS, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import { CreateClaim } from './Claims/CreateClaim/CreateClaim';

const contextParaCSS = css({
  color: PALETTE.brand100,
  padding: '8px 0 32px 0'
});
const listItemCSS = css({
  display: 'flex',
  alignItems: 'center',
  color: PALETTE.brand100,
  listStyleType: 'none',
  '&:before': {
    content: '"•"',
    marginRight: '8px',
    fontSize: '20px'
  }
});

const contextHeadingCSS = css({
  color: PALETTE.brand100
});

const numberCircleCSS = css({
  border: `1px solid ${PALETTE.brand100}`,
  borderRadius: '50%',
  height: '28px',
  width: '28px',
  display: 'inline-block',
  textAlign: 'center',
  color: PALETTE.brand100,
  marginRight: 12,
  padding: 1
});

const contextLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  margin: '0 0 0 -48px',
  paddingBottom: 32,
  width: '384px'
});

export const FileClaim = () => {
  return (
    <div css={{ display: 'flex' }}>
      <div
        id="context"
        css={{
          height: '100vh',
          display: 'flex',
          position: 'fixed',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'flex-start',
          background: PALETTE.interaction4,
          padding: 48,
          borderRight: `1px solid ${PALETTE.neutral12}`,
          width: '384px',
          overflow: 'auto'
        }}
      >
        <h3 css={[FONTS.h3, { color: `${PALETTE.brand100}` }]}>Filing a claim</h3>
        <span css={[FONTS.p2, contextParaCSS, { paddingBottom: 32 }]}>
          Follow these instructions to help us process your claim quickly and accurately.
        </span>
        <hr css={[contextLineCSS]} />
        <div>
          <div css={[FONTS.p2, numberCircleCSS]}>1</div>
          <span css={[FONTS.h5, contextHeadingCSS]}>Enter claim details</span>
        </div>
        <span css={[FONTS.p2, contextParaCSS, { marginLeft: 40 }]}>
          Enter all renter, unit, and claim information to the best of your knowledge
        </span>
        <hr css={[contextLineCSS]} />
        <div>
          <div css={[FONTS.p2, numberCircleCSS]}>2</div>
          <span css={[FONTS.h5, contextHeadingCSS]}>Add claim documentation</span>
        </div>
        <div css={{ marginLeft: 40, padding: '8px 0 32px 0' }}>
          <ul>
            <li css={[FONTS.p2, listItemCSS]}>Complete rent ledger</li>
            <li css={[FONTS.p2, listItemCSS]}>Lease documentation</li>
            <li css={[FONTS.p2, listItemCSS]}>Photos of damage</li>
            <li css={[FONTS.p2, listItemCSS]}>Any additional supporting evidence</li>
          </ul>
        </div>
        <hr css={[contextLineCSS]} />
        <div>
          <div css={[FONTS.p2, numberCircleCSS]}>3</div>
          <span css={[FONTS.h5, contextHeadingCSS]}>Select payout method</span>
        </div>
        <span css={[FONTS.p2, contextParaCSS, { marginLeft: 40 }]}>
          Choose between receiving a physical check or a direct deposit sent to a bank account of your choice.
        </span>
      </div>
      <div id="form-container" css={{ flex: 1, marginLeft: 384 }}>
        <div
          id="header"
          css={{
            borderBottom: `1px solid ${PALETTE.neutral4}`,
            backgroundColor: PALETTE.neutralLight,
            padding: '16px 48px',
            position: 'sticky',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 1
          }}
        >
          <span css={[FONTS.h5, { color: `${PALETTE.neutral25}` }]}>File a Claim</span>
        </div>
        <CreateClaim />
      </div>
    </div>
  );
};

export default FileClaim;
