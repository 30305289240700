/** @jsx jsx */
/** @jsxFrag React.Fragment */

import React from "react";
import DrawerModal from "../DrawerModal";
import { Button, FONTS, Loading, PALETTE } from "@sayrhino/rhino-shared-js";
import styled from "@emotion/styled";
import { jsx } from "@emotion/core";

const CancelButton = styled.button([
  {
    border: 'none',
    background: 'transparent',
    color: PALETTE.brand100
  },
  FONTS.h6Demi
]);

const ActionButtonsDiv = styled.div({
  display: 'flex',
  justifyContent: 'center'
});

const ModalTitle = styled.h3({
  ...FONTS.h5,
  textAlign: 'center'
});

const ModalBody = styled.div({
  ...FONTS.p1Light,
  textAlign: 'center'
});

const CancellationRequestModal = ({
  onCancel,
  onConfirm,
  isLoading
}) => {
  return (
    <DrawerModal>
      <ModalTitle>Are you sure you want to approve the cancellation?</ModalTitle>
      <ModalBody>
        Cancellations cannot be reversed, so make sure that you are covered before approving a cancellation request.
      </ModalBody>
      {isLoading ? (<Loading />) : (
        <ActionButtonsDiv>
          <CancelButton onClick={onCancel} id="cancelCancellationRequestApprovalBtn">No, take me back</CancelButton>
          <Button onClick={onConfirm} variant="primary" id="confirmCancellationRequestApprovalBtn">
            Yes, approve
          </Button>
        </ActionButtonsDiv>
      )}
    </DrawerModal>
  );
}

export default CancellationRequestModal;
