import { IProperty } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

export const getPropertyDetails = async (propertyId: number) => {
  const { data } = await axios.get<IProperty>(`/v2/admin/properties/${propertyId}.json`);

  return data;
};
export default function usePropertyDetails(id: number): QueryObserverResult<IProperty> {
  return useQuery<IProperty>(['property details', id], () => getPropertyDetails(id));
}
