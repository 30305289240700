import axios from 'axios';
import { IAdministrators } from 'components/v2/App/interfaces';
import { QueryObserverResult, useQuery } from 'react-query';

const getAdministrators = async (query: string) => {
  const { data } = await axios.get<IAdministrators>('/v2/admin/administrators', {
    params: { query: query, limit: 50 }
  });

  return data;
};

export default function useAdministrators(): QueryObserverResult<IAdministrators> {
  return useQuery<IAdministrators>('administrators', () => getAdministrators(''));
}
