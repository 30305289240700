import axios from 'axios';
import { IData, IPaginatedMeta, IClaimsNote } from 'components/v2/App/interfaces';
import { stringify } from 'qs';
import { InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

export const CLAIM_NOTES_KEY = 'claim notes';

const getClaimNotes = async (claimsId: number, page: number = 1) => {
  const params = Object.assign({}, { page });
  const paramsSerializer = (p) => stringify(p, { arrayFormat: 'brackets', indices: false, skipNulls: true });

  const { data } = await axios.get<IData<IClaimsNote[], IPaginatedMeta>>(`/v2/admin/claims/${claimsId}/notes.json`, {
    params,
    paramsSerializer
  });

  return data;
};

export default function useClaimNotes(
  claimsId: number
): InfiniteQueryObserverResult<IData<IClaimsNote[], IPaginatedMeta>> {
  return useInfiniteQuery<IData<IClaimsNote[], IPaginatedMeta>>(
    [CLAIM_NOTES_KEY],
    ({ pageParam }) => getClaimNotes(claimsId, pageParam),
    {
      getNextPageParam: (lastGroup) => lastGroup.meta.next_page
    }
  );
}
