import React, { useState, useEffect, useRef } from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

const useJumpLink = (containerId: string, headerRef, scrollPosition) => {
  const [isTop, setTop] = useState(true);
  const [aboveRef, setAboveRef] = useState(true);
  const [belowRef, setBelowRef] = useState(false);
  const [colWidth, setColWidth] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);

  const headerVisible = () => {
    const el = headerRef.current;
    const top = el.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    return top < windowHeight;
  };

  const belowHeader = () => {
    const el = headerRef.current;
    const top = el.getBoundingClientRect().top;
    return top < -120;
  };

  const scrollToHeader = () => {
    const updatesHeader = headerRef.current;
    elementScrollIntoView(updatesHeader, { behavior: 'smooth', block: scrollPosition });
  };

  const resizeWindow = () => {
    const element = window.document.getElementById(containerId);
    if (element !== null) {
      setColWidth(element.offsetWidth);
      setMarginLeft(element.offsetLeft);
    }
  };

  useEffect(() => {
    const element = window.document.getElementById(containerId);

    if (element !== null) {
      const elementWidth = element.offsetWidth;
      setColWidth(elementWidth);
      setMarginLeft(element.offsetLeft);
      const scrollListener = () => {
        const topOfPage = element.scrollTop === 0;
        if (topOfPage !== isTop) {
          setTop(false);
        } else {
          setTop(true);
        }

        if (headerVisible()) {
          setAboveRef(false);
        } else {
          setAboveRef(true);
        }

        if (belowHeader()) {
          setBelowRef(true);
        } else {
          setBelowRef(false);
        }
      };
      resizeWindow();
      element.addEventListener('scroll', scrollListener);
      window.addEventListener('resize', resizeWindow);

      return () => {
        element.removeEventListener('scroll', scrollListener);
        window.removeEventListener('resize', resizeWindow);
      };
    }
  }, []);
  return { isTop, marginLeft, colWidth, aboveRef, belowRef, scrollToHeader };
};

export default useJumpLink;
