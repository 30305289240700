export const updates = {
  data: [
    {
      type: 'Claim',
      id: 1457,
      attributes: {
        administrator: null,
        amount_cents: 31000,
        claim_type: 'Loss of Rent',
        coverage_end_date: '2021-07-01T00:00:00Z',
        created_at: '2021-02-17T00:04:37Z',
        id: 5942,
        property_address: '10 West 122nd Street, New York, NY, 10027, United States',
        property_address_line_one: '10 West 122nd Street',
        property_name: 'New York',
        remaining_amount_cents: 464000,
        renter_full_name: 'John_1330 Doe_1330',
        status: 'new',
        status_detail: []
      }
    },
    {
      type: 'InsurancePolicy',
      id: 17431,
      attributes: {
        tenant_full_name: 'Jacob DeGrom',
        coverage_amount: '$25,770',
        coverage_dates: 'May 02, 2020 - Sep 30, 2020',
        property_name: 'Enclave at the Cathedral',
        property_state: 'NY',
        property_street_address: '400 W 113th St, New York, NY, 10025',
        status: 'expired',
        unit_name: '215'
      }
    }
  ],
  meta: { total_count: 21, next_page: 2, page: '1', per: 20 }
};

export const updates2 = {
  data: [
    {
      type: 'Claim',
      id: 1458,
      attributes: {
        administrator: null,
        amount_cents: 77700,
        claim_type: 'Wear & Tear',
        coverage_end_date: '2021-07-01T00:00:00Z',
        created_at: '2021-02-17T00:04:37Z',
        id: 5941,
        property_address: '10 West 122nd Street, New York, NY, 10027, United States',
        property_address_line_one: '10 West 122nd Street',
        remaining_amount_cents: 464000,
        renter_full_name: 'John_1330 Doe_1330',
        status: 'new',
        status_detail: []
      }
    },
    {
      type: 'InsurancePolicy',
      id: 17432,
      attributes: {
        tenant_full_name: 'Jacob DeGrom',
        coverage_amount: '$25,770',
        coverage_dates: 'May 02, 2020 - Sep 30, 2020',
        property_name: 'Enclave at the Cathedral',
        property_state: 'NY',
        property_street_address: '400 W 113th St, New York, NY, 10025',
        status: 'active',
        unit_name: '215'
      }
    },
    {
      type: 'InsurancePolicy',
      id: 17433,
      attributes: {
        tenant_full_name: 'John Smith',
        coverage_amount: '$25,770',
        coverage_dates: 'May 02, 2020 - Sep 30, 2020',
        property_name: 'Enclave at the Cathedral',
        property_state: 'NY',
        property_street_address: '400 W 113th St, New York, NY, 10025',
        status: 'expired',
        unit_name: '215'
      }
    }
  ],
  meta: { total_count: 21, next_page: 2, page: '2', per: 20 }
};
