import axios from 'axios';
import { IBankAccountInfo } from 'components/v2/App/DepositFunds/types';
import { csrfToken } from 'utils/document';

const createBankAccount = async (bankAccountInfo: Omit<IBankAccountInfo, "bank_account_uid" | "account_uid">) => {
  const {
    nickname,
    bank_name,
    account_number,
    last_name_on_account,
    recipient_country,
    bic_swift_code,
    first_name_on_account,
    account_type,
    internal_account_type,
    contact_phone_number,
    ach,
    recipient_address_city,
    recipient_address_state,
    recipient_postal_code,
    recipient_address_street
  } = bankAccountInfo;

  const token = csrfToken();

  const response = await axios.post<IBankAccountInfo>(
    '/admin/funds/accounts',
    {
      bank_account: {
        bank_name,
        account_number,
        account_type,
        contact_phone_number,
        first_name_on_account,
        last_name_on_account,
        nickname,
        internal_account_type,
        ach,
        ...(internal_account_type === 'international' && {
          bic_swift_code: bic_swift_code,
          recipient_country: recipient_country,
          recipient_address_street,
          recipient_address_city,
          recipient_address_state,
          recipient_postal_code
        })
      }
    },
    {
      headers: {
        'X-CSRF-Token': token
      }
    }
  );
  return response;
};

export default createBankAccount;
