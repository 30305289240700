import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { PALETTE, FONTS, FONT_FAMILY, CloseIcon, ButtonHabitat } from '@sayrhino/rhino-shared-js';

import { StyledValidationErrorBanner } from '../../PropertyOwners/Styled';

export const contextParaCSS = css({
  color: PALETTE.brand100,
  padding: '12px 0'
});

export const contextHeadingCSS = css({
  color: PALETTE.brand100
});
export const contextLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  margin: '1em 0 1em -48px',
  paddingBottom: 32,
  width: '384px'
});
export const numberCircleCSS = css({
  border: `1px solid ${PALETTE.brand100}`,
  borderRadius: '50%',
  height: '28px',
  width: '28px',
  display: 'inline-block',
  textAlign: 'center',
  color: PALETTE.brand100,
  marginRight: 14,
  padding: 1
});

export const InfoRailDiv = styled.div({
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'flex-start',
  background: PALETTE.brand4,
  padding: 48,
  borderRight: `1px solid ${PALETTE.neutral12}`,
  width: '384px'
});
export const IntegrationSetupPageDiv = styled.div({ display: 'flex' });

export const InfoRailHeader = styled.h3(FONTS.h3, { color: PALETTE.brand100 });
export const InfoRailSubHeader = styled.span(FONTS.p2, contextParaCSS, {
  paddingBottom: 40
});
export const closeButtonCSS = css({
  background: PALETTE.neutral4,
  borderRadius: '20px',
  height: '32px',
  width: '32px',
  textAlign: 'center',
  border: 'none'
});
export const InfoRailStepContainer = styled.div({ marginBottom: 50 });
export const InfoRailStepNumber = styled.div(FONTS.p2, numberCircleCSS);
export const InfoRailStepText = styled.span(FONTS.h5, contextHeadingCSS);
export const PMSIntegrationSetupDivider = styled.hr({ contextLineCSS });
export const IntegrationFormDiv = styled.div({ flex: 1, marginLeft: 384 });
export const FormContainer = styled.div({ padding: '40px 96px', marginBottom: 100, maxWidth: '900px' });
export const FormHeaderSpan = styled.span(FONTS.p1, FONT_FAMILY.extendedMedium, {
  color: PALETTE.neutral65
});
export const FormHeaderCloseButton = styled.button(closeButtonCSS);
export const StyledFormHeaderCloseIcon = styled(CloseIcon)({ marginTop: '7px', color: PALETTE.neutral55 });
export const FormHeaderDiv = styled.div({
  backgroundColor: PALETTE.neutralLight,
  padding: '20px 48px',
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 1
});
export const PMSIntegrationSetupHeader = styled.h3(FONTS.h3);
export const StyledSubmitButton = styled(ButtonHabitat)({
  float: 'right'
});

export const CopyWrapper = styled.div({ paddingTop: '8px', paddingBottom: '12px' });
export const StyledCopySubP = styled.p(FONT_FAMILY.extendedLight, {
  paddingTop: '16px',
  paddingBottom: '8px',
  lineHeight: '24px',
  color: PALETTE.neutral88
});
export const StyledVErrorBanner = styled(StyledValidationErrorBanner)({ marginTop: 0 });
export const StyledIntegrationRule = styled.h4(FONTS.h4);
