/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
  FONTS,
  PALETTE,
  Checkbox,
  Datepicker,
  DropdownMenu,
  Alert2Icon,
  CaretIcon,
  CurrencyInputUSD
} from '@sayrhino/rhino-shared-js';
import { invalidFormCSS, DamageRoomContent, DropDownHeader, invalidFieldDescCSS, fieldDescCSS } from './Styled';
import { find } from 'lodash';
import { stringifyDate, centsToUSDFormatter } from '../../utils';
import Decimal from 'decimal.js';
import { DamageData, Errors } from './claimInterface';
import useClaimCategories from 'api/v2/useClaimCategories';
import { format } from 'date-fns';

//Styles
const DamagesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 0 24px 48px'
});
const DatePickerWrapper = styled.div({
  input: {
    cursor: 'pointer'
  }
});
const StyledErrorText = styled.p([
  FONTS.p3,
  {
    marginBottom: 24,
    color: PALETTE.alert100
  }
]);

const CaretWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  border: `1px solid ${PALETTE.neutral88}`,
  cursor: 'pointer'
});
const SubCategoryWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr'
});
const CoverageAmountCopyWrapper = styled.div({
  marginTop: 8,
  marginBottom: 32,
  display: 'flex'
});
const StyledCoverageCopy = styled.p([
  FONTS.p2Light,
  {
    flex: '1',
    color: PALETTE.neutralDark
  }
]);
const StyledDamageCopy = styled.h6([FONTS.h6, { marginBottom: 16 }]);
const StyledQuestionMark = styled.span({
  minWidth: '20px',
  height: '20px',
  paddingLeft: '1px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '50%',
  color: PALETTE.neutral25,
  marginLeft: '24px'
});
const StyledDamageSelection = styled.div({
  marginTop: 32,
  display: 'flex',
  flexDirection: 'column'
});
const AlertRowWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  color: PALETTE.alert100,
  marginBottom: 24
});
const CheckBoxWrapper = styled.div({ marginBottom: 16 });
const StyledAlert2Icon = styled(Alert2Icon)({ marginRight: 20 });

interface IProps {
  onChangeDateOfLoss: (value: { date: Date; updated: boolean }) => void;
  damageData: DamageData;
  onChangeDamageData: (value: string | Decimal | string[], key: string) => void;
  errors: Errors;
  policyRemainingAmount: number;
  onToggleCheckSubCategory: (subCategory: string) => void;
  originalDateOfLoss?: Date | null;
}

const Damages: React.FC<IProps> = ({
  onChangeDateOfLoss,
  damageData,
  onChangeDamageData,
  errors,
  policyRemainingAmount,
  onToggleCheckSubCategory,
  originalDateOfLoss
}) => {
  const { data } = useClaimCategories();
  const subcategories = find(data?.categories, { category: damageData.damagedRoom })?.subcategories;

  useEffect(() => {
    if (damageData.damagedRoom === 'Heating, HVAC, or Electrical') {
      onChangeDamageData([], 'subCategories');
    }
  }, [damageData.damagedRoom]);

  return (
    <DamagesContainer>
      <DatePickerWrapper>
        <Datepicker
          onChange={(date) => {
            const updated = !(originalDateOfLoss?.toString() === format(date, 'yyyy-MM-dd'));
            onChangeDateOfLoss({ date, updated });
          }}
          selected={damageData.dateOfLoss.date}
          value={stringifyDate(damageData.dateOfLoss.date)}
          inputLabel="date of loss"
          selectsRange={false}
        />
      </DatePickerWrapper>
      <p css={[FONTS.p3, errors.dateOfLoss ? invalidFieldDescCSS : fieldDescCSS]}>
        {errors.dateOfLoss ?? 'Please enter the date of loss for damages'}
      </p>
      <div css={errors.damageAmount ? invalidFormCSS : undefined}>
        <CurrencyInputUSD
          id="create-claim-damage-amount"
          label={'damages amount'}
          value={Number(damageData.amountCents)}
          onChange={(value) => {
            onChangeDamageData(new Decimal(value), 'amountCents');
          }}
          disabled={false}
          subtext={errors.damageAmount ?? 'Please enter the full amount owed in damages'}
        />
      </div>
      <CoverageAmountCopyWrapper>
        <StyledCoverageCopy>
          The policy will cover up to {centsToUSDFormatter(new Decimal(policyRemainingAmount))} in loss of rent or
          excessive damages. To give us a clearer picture of claim please enter the full amount owed.
        </StyledCoverageCopy>
        <StyledQuestionMark>?</StyledQuestionMark>
      </CoverageAmountCopyWrapper>
      <StyledDamageCopy>Where did the damage occur?</StyledDamageCopy>
      <DropdownMenu.Root>
        <DropDownHeader>
          {damageData.damagedRoom ? damageData.damagedRoom : 'select an area...'}
          <CaretWrapper>
            <CaretIcon width={10} height={10} />
          </CaretWrapper>
        </DropDownHeader>
        <DamageRoomContent>
          {data?.categories.map((categoryInfo) => (
            <DropdownMenu.Item
              onSelect={() => onChangeDamageData(categoryInfo.category, 'damagedRoom')}
              key={categoryInfo.category}
            >
              {categoryInfo.category}
            </DropdownMenu.Item>
          ))}
        </DamageRoomContent>
      </DropdownMenu.Root>
      {errors.damagedRoom && <StyledErrorText>{errors.damagedRoom}</StyledErrorText>}
      {subcategories && subcategories.length > 0 && (
        <StyledDamageSelection>
          <StyledDamageCopy>What was damaged?</StyledDamageCopy>
          {errors.subcategory && (
            <AlertRowWrapper>
              <StyledAlert2Icon width={20} height={20} />
              {errors.subcategory}
            </AlertRowWrapper>
          )}
          <SubCategoryWrapper>
            {subcategories.map((subcategory: string) => (
              <CheckBoxWrapper key={subcategory}>
                <Checkbox
                  id={`create-claim-sub-category-${subcategory}`}
                  name={`create-claim-sub-category-${subcategory}`}
                  label={subcategory}
                  onCheckedChange={() => onToggleCheckSubCategory(subcategory)}
                  checked={damageData.subCategories.includes(subcategory)}
                />
              </CheckBoxWrapper>
            ))}
          </SubCategoryWrapper>
        </StyledDamageSelection>
      )}
    </DamagesContainer>
  );
};

export default Damages;
