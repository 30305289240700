import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS, CheckIcon } from '@sayrhino/rhino-shared-js';

const Page = styled.div({
  height: 'auto',
  minHeight: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  background: PALETTE.brand4
});

const Title = styled.h2([
  FONTS.h3,
  {
    fontSize: '25px',
    lineHeight: '35px',
    textAlign: 'center',
    marginBottom: '28px',
    width: '395px'
  }
]);

const CircleBackground = styled.div({
  backgroundColor: PALETTE.success100,
  width: '65px',
  height: '65px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  marginBottom: '20px'
});

type IProps = {
  setComplete: (complete: boolean) => void;
};

const CompleteNotification: React.FC<IProps> = ({ setComplete }) => {
  useEffect(() => {
    setTimeout(() => setComplete(true), 1200);
  }, []);
  return (
    <Page>
      <CircleBackground>
        <CheckIcon height="40px" width="40px" color={PALETTE.neutralLight} />
      </CircleBackground>
      <Title>Congratulations, you've added your first property!</Title>
    </Page>
  );
};

export default CompleteNotification;
