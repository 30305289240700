import { css, jsx } from '@emotion/core';
import { FONTS, FONT_FAMILY, PALETTE, DropdownMenu, ROUNDNESS } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import TextArea from '../ClaimDetails/TextAreaInput';

export const subFormLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  width: '100%',
  margin: '0 0 24px 0'
});

export const subContainer = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 0 24px 48px'
});

export const questionCSS = css({
  minWidth: '20px',
  height: '20px',
  paddingLeft: '1px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${PALETTE.neutral25}`,
  borderRadius: '50%',
  color: PALETTE.neutral25,
  marginLeft: '24px'
});

export const partCheckContainer = css({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr'
});

export const formLineCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutralDark}`,
  width: '100%',
  margin: '0 0 32px 0'
});
export const StyledFormLine = styled.hr({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutralDark}`,
  width: '100%',
  margin: '0 0 32px 0'
});
export const sectionHeaderCss = [FONTS.h5, FONT_FAMILY.extendedDemi, css({ marginBottom: 16 })];
export const StyledSectionHeader = styled.h5([FONTS.h5Demi, { marginBottom: 16 }]);

export const submitButtonCSS = css({
  marginLeft: 'auto',
  marginBottom: '50px',
  marginTop: '20px'
});

export const inputCSS = css({
  border: 'none',
  borderBottom: `1px solid ${PALETTE.neutral65}`,
  paddingBottom: 12,
  width: '100%'
});

export const invalidInputCSS = css({
  border: 'none',
  borderBottom: `1px solid ${PALETTE.alert100}`,
  paddingBottom: 12,
  width: '100%'
});

export const MediumCSS = css({
  label: {
    fontWeight: 'bold'
  }
});

export const LightCSS = css({
  label: {
    fontWeight: 'normal'
  }
});

export const datePickerCSS = css({
  input: {
    cursor: 'pointer'
  }
});

export const sectionCSS = css({
  display: 'flex',
  flexDirection: 'column'
});
export const SectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column'
});
export const sectionDividerCSS = css({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  width: '100%',
  margin: '60px 0'
});
export const StyledSectionDivider = styled.hr({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${PALETTE.neutral12}`,
  width: '100%',
  margin: '60px 0'
});
export const alertRowCSS = css({
  display: 'flex',
  alignItems: 'center',
  color: PALETTE.alert100,
  marginBottom: 24
});

export const invalidFormCSS = css({
  '> div': {
    borderBottom: `1px solid ${PALETTE.alert100}`,
    label: {
      color: `${PALETTE.alert100}`
    }
  }
});

export const RoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${PALETTE.neutral88};
  cursor: pointer;
`;

export const DropDownHeader = styled(DropdownMenu.Trigger)({
  display: 'flex',
  justifyContent: 'space-between'
});

export const ClaimTypeContent = styled(DropdownMenu.Content)({
  minWidth: 572
});

export const DamageRoomContent = styled(DropdownMenu.Content)({
  minWidth: 524
});

export const fieldDescCSS = css({
  marginBottom: 24,
  color: PALETTE.neutral65
});

export const invalidFieldDescCSS = css({
  marginBottom: 24,
  color: PALETTE.alert100
});

export const TextAreaInput = styled(TextArea)({ marginTop: 20 });

export const StyledDisclaimer = styled.p[FONTS.p3];
