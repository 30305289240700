import ApplicationTable from './application_table';
import { DeleteColumn, EditColumn } from './utils';

class UserTable extends ApplicationTable {
  public selector = '#user-table';
  public pathPrefix = 'users/';
  public dom = '<"search"fl><"top">rt<"bottom"ip><"clear">';

  protected columns = [
    {data: 'id'},
    {data: 'email'},
    {data: 'last_sign_in_at'},
    {data: 'last_sign_in_ip'},
    EditColumn,
    DeleteColumn
  ];
  protected columnDefs = [
    {
      targets: [2, 3, 4, 5],
      data: undefined,
      defaultContent: ''
    }
  ];
}

export default UserTable;
