import React from 'react';
import { Link, LinkProps, useMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';

type TabProps = LinkProps & {
  selected: boolean;
};

const StyledTab = styled(Link)<TabProps>(({ selected }) => [
  selected ? FONTS.h6Demi : FONTS.p2Light,
  {
    borderBottom: selected ? `1px solid ${PALETTE.neutralDark}` : 'none',
    display: 'block',
    margin: '25px 40px -1px 0',
    paddingBottom: '25px'
  }
]);

interface IProps {
  children: React.ReactNode;
  to: string;
  selectedRoutes?: Array<string>;
}

const Tab: React.FC<IProps> = ({ children, to, selectedRoutes = [] }) => {
  const routeMatchList = [to, ...selectedRoutes];
  const selected = routeMatchList.map((route) => useMatch(route)).some((route) => !!route);

  return (
    <StyledTab to={to} role="tab" selected={selected} aria-selected={selected}>
      {children}
    </StyledTab>
  );
};

export default Tab;
