import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { IPropertyCsvUploadValidationStatus } from '../../../javascript/components/tables/property_upload_table/interfaces';

type ValidationUrl = string | undefined;

const getCsvValidationStatus = async (url: ValidationUrl): Promise<IPropertyCsvUploadValidationStatus> => {
  if (url) {
    const { data }: AxiosResponse<IPropertyCsvUploadValidationStatus> =
      await axios.get<IPropertyCsvUploadValidationStatus>(url);
    return data;
  } else {
    return { status: 'processing', validated_data: '' };
  }
};

export default function useCsvValidationStatus({ onSuccess, onError }) {
  const [validationUrl, setValidationUrl] = useState<ValidationUrl>();

  useQuery(['useCsvValidationStatus', validationUrl], () => getCsvValidationStatus(validationUrl), {
    onSuccess,
    onError,
    refetchInterval: !!validationUrl ? 500 : false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    enabled: !!validationUrl
  });

  return { setValidationUrl };
}
