import React, { useMemo } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  TableToggleCommonProps
} from 'react-table';

import { BulkActions } from './Styles';

interface IFilter {
  id: string;
  value: string;
}
export type QueryParams = [
  number, // pageIndex
  number, // pageSize
  string | undefined, // sortBy.id
  boolean | undefined, // sortBy.desc
  string | undefined, // filterValue
  IFilter[] | undefined // filters
];

export type UpdateFn = (params: QueryParams) => void;

type IBulkActionsProps = {
  selectedIds: number[];
};
export type BulkActionsFn = (props: IBulkActionsProps) => JSX.Element;

export interface IPaginated<Data> {
  data: Data;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
}

export interface IProps<Data, Columns> {
  paginatedData: IPaginated<Data>;
  noPagination?: boolean;
  columns: Columns;
  update?: UpdateFn;
  bulkActions?: BulkActionsFn;
  displayGlobalFilter?: boolean;
  tooltips?: boolean;
  tableStyles?: object;
  toggleExtendedFilter?(): void;
}

function useUpdate(update: UpdateFn, params: QueryParams): void {
  const updateThunk = () => update(params);
  return useMemo(updateThunk, params);
}

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, toggleExtendedFilter }) {
  return (
    <div style={{ float: 'right' }}>
      <span style={{ position: 'relative' }}>
        <span
          className="fa fa-search form-control-feedback"
          style={{
            position: 'absolute',
            width: '55px',
            height: '60px',
            lineHeight: '60px',
            fontSize: '23px',
            color: '#B1A89F',
            textAlign: 'center',
            pointerEvents: 'none'
          }}
        ></span>
        <input
          value={globalFilter || ''}
          onChange={(e) => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder="Search"
          name="table-filter-input"
          className="table-filter-input"
          style={{
            fontSize: '1.1rem',
            border: '2px solid #E3E3E3',
            boxSizing: 'border-box',
            borderRadius: '4px',
            width: '466.37px',
            height: '60px',
            paddingLeft: '46px',
            marginBottom: '20px'
          }}
        />
      </span>
      {toggleExtendedFilter && (
        <a href="#" onClick={toggleExtendedFilter} style={{ color: '#9B9B9B', fontSize: '18px', marginLeft: '27px' }}>
          Filter by
        </a>
      )}
    </div>
  );
}

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      name="column-filter"
      placeholder={`Search records...`}
    />
  );
}

const IndeterminateCheckbox = React.forwardRef(
  (props: TableToggleCommonProps, ref: React.RefObject<HTMLInputElement>) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const { indeterminate, ...rest } = props;

    React.useEffect(() => {
      if (resolvedRef && resolvedRef.current) {
        resolvedRef.current.indeterminate = !!indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function Table<Data, Columns>(props: IProps<Data, Columns>) {
  const {
    paginatedData,
    noPagination,
    columns,
    update,
    toggleExtendedFilter,
    bulkActions,
    displayGlobalFilter = true,
    tooltips = false,
    tableStyles = {}
  } = props;

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy, globalFilter, filters }
  } = useTable(
    {
      columns,
      data: paginatedData.data,
      defaultColumn,
      filterTypes,
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      manualFilters: true,
      autoResetGlobalFilter: false,
      pageCount: paginatedData.pageCount,
      initialState: {
        pageIndex: paginatedData.pageIndex,
        pageSize: paginatedData.pageSize
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      bulkActions &&
        hooks.visibleColumns.push((cols) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps, toggleAllRowsSelected }) => {
              const myProps = getToggleAllRowsSelectedProps();
              myProps.onChange = () => toggleAllRowsSelected();
              return (
                <div>
                  <IndeterminateCheckbox {...myProps} className="select-all-checkbox" />
                </div>
              );
            },
            Cell: ({ row }) => {
              return (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              );
            }
          },
          ...cols
        ]);
    }
  );

  if (update) {
    let sortById;
    let sortByDesc;
    if (sortBy[0]) {
      sortById = sortBy[0].id;
      sortByDesc = sortBy[0].desc;
    }
    useUpdate(update, [pageIndex, pageSize, sortById, sortByDesc, globalFilter, filters]);
  }

  // Render Data Table UI
  return (
    <section style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {displayGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            toggleExtendedFilter={toggleExtendedFilter}
          />
        )}
        {bulkActions && selectedFlatRows.length > 0 && (
          <BulkActions>{bulkActions({ selectedIds: selectedFlatRows.map((row) => row.original.id) })}</BulkActions>
        )}
      </div>
      <div style={{ maxWidth: '100%', overflow: 'auto' }}>
        <table {...getTableProps()} style={Object.assign({}, styles.table, tableStyles)}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''}
                    style={Object.assign({}, styles.cell, styles.header, column.headerStyle && column.headerStyle())}
                  >
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                      </span>
                    </div>
                    <div>{column.displayFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        data-tip={tooltips}
                        data-for={tooltips ? `${cell.column.id}-${cell.row.id}` : undefined}
                        {...cell.getCellProps()}
                        style={Object.assign({}, styles.cell, cell.column.style && cell.column.style(cell))}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {!noPagination && (
        <div style={styles.pagination}>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => {
                const selectedPage = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(selectedPage);
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            style={styles.pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50, 100].map((perPage) => (
              <option key={perPage} value={perPage}>
                Show {perPage}
              </option>
            ))}
          </select>
        </div>
      )}
    </section>
  );
}

const styles = {
  table: {
    width: '100%',
    borderSpacing: '0',
    border: '1px solid #E0E0E0',
    maxHeight: '90vh'
  },
  cell: {
    margin: '0',
    padding: '1rem',
    borderBottom: '1px solid #E0E0E0'
  },
  header: {
    fontFamily: 'MaisonNeueMedium',
    fontSize: '13px',
    lineHeight: '17px',
    color: '#5D5D5D'
  },
  pagination: {
    textAlign: 'left' as 'left',
    display: 'inline-block',
    paddingLeft: '0',
    margin: '20px 0',
    alignSelf: 'center' as 'center',
    position: 'relative' as 'relative',
    borderRadius: '4px',
    width: '100%'
  },
  pageSize: {
    position: 'absolute' as 'absolute',
    right: '0'
  },
  link: {
    color: '#6318CE',
    cursor: 'pointer'
  }
};

export default Table;
