import React from 'react';

import { QueryObserverResult } from 'react-query';
import { Loading } from '@sayrhino/rhino-shared-js';

import { useInsurancePolicySummaryMetrics } from 'api/v2/useInsurancePolicySummaryMetrics';
import { IInsurancePolicySummaryMetricsData } from './interfaces';

import makeSummaryMetricsContainer from './makeSummaryMetricsContainer';
import DashboardPanelPill from './DashboardPanelPill';
import DashboardPanelPillLink from './DashboardPanelPillLink';
import DashboardPanelPercentageChart from './DashboardPanelPercentageChart';

import { ACTIVE_POLICIES_TITLE, COVERED_UNITS_TITLE, NEW_POLICIES_THIS_MONTH_TITLE } from './utils/constants';

const InsurancePolicySummaryMetrics = makeSummaryMetricsContainer('Policy Summary', () => {
  const response: QueryObserverResult<IInsurancePolicySummaryMetricsData> = useInsurancePolicySummaryMetrics();
  if (response.isLoading) {
    return <Loading />;
  }

  const data: IInsurancePolicySummaryMetricsData | undefined = response.data;

  if (!data) {
    return;
  }

  const renewedAndExpiredPoliciesCount =
    data.renewed_policies_last_30_days_count + data.expired_policies_last_30_days_count;

  const renewalPercentage =
    renewedAndExpiredPoliciesCount > 0 ? data.renewed_policies_last_30_days_count / renewedAndExpiredPoliciesCount : 0;

  const showCoveredUnitsCount = () =>
    (data.active_policies_count > 0 && data.total_units_count > 0) ||
    (data.active_policies_count === 0 && data.partial_quotes_count > 0);

  const showSendInvitationCTA = () => data.active_policies_count === 0 && data.partial_quotes_count === 0;

  const getCoveredUnitsPercentage = () =>
    Math.round((data.active_policies_coverage_begun / data.total_units_count) * 100);

  const showRenewalCard = () => data.renewed_policies_last_30_days_count > 0;

  return (
    <React.Fragment>
      <DashboardPanelPill title={ACTIVE_POLICIES_TITLE} value={data.active_policies_count} dark />
      {showCoveredUnitsCount() && (
        <DashboardPanelPill title={COVERED_UNITS_TITLE} value={`${getCoveredUnitsPercentage()}%`} />
      )}
      <DashboardPanelPill title={NEW_POLICIES_THIS_MONTH_TITLE} value={data.new_policies_this_month_count} />
      {showRenewalCard() && (
        <DashboardPanelPercentageChart
          title="Renewals"
          percent={renewalPercentage}
          filledLabel="Renewed with Rhino"
          unfilledLabel="Did not renew with Rhino"
          updatedDate={new Date()}
        />
      )}
      {showSendInvitationCTA() && <DashboardPanelPillLink title="Send an invitation" to="/admin/invitations/new" />}
    </React.Fragment>
  );
});

export default InsurancePolicySummaryMetrics;
