import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUpdatePropertyEntityMutation from '../../../../api/v2/updatePropertyEntity';
import usePropertyEntityDetails from '../../../../api/v2/usePropertyEntityDetails';
import { useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { PMAPropertyEntitiesKey } from 'api/v2/usePMAPropertyEntities';

type RequestError = {
  errors: string[];
};
export const usePropertyEntity = (propertyEntityId: number) => {
  const { data, isSuccess } = usePropertyEntityDetails(Number(propertyEntityId));
  const navigate = useNavigate();

  // property entity details fields
  const [name, setName] = React.useState<string>(data?.name || '');
  const [address, setAddress] = React.useState<string>(data?.mailing_address || '');
  const [phone, setPhone] = React.useState<string>(data?.phone || '');
  const [email, setEmail] = React.useState<string>(data?.email || '');
  const [errorMessage, setErrorMessage] = React.useState<string[] | undefined>();

  const setRawPhone = (value) => {
    return setPhone(value.replace(/\D+/g, ''));
  };
  const queryCache = useQueryClient();

  // mutation for react-query to update cache on put request
  const { mutate: updatePropertyEntityMutation, isLoading } = useUpdatePropertyEntityMutation();

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    updatePropertyEntityMutation(
      {
        id: propertyEntityId,
        name,
        mailing_address: address,
        phone,
        email
      },
      {
        onSuccess: () => {
          queryCache.removeQueries(['property entity details', Number(propertyEntityId)], { exact: true });
          queryCache.invalidateQueries([PMAPropertyEntitiesKey]);
          navigate(-1);
        },
        onError: (error: AxiosError<RequestError>) => {
          const errorResponse = error?.response?.data?.errors;
          if (errorResponse && errorResponse.length) {
            setErrorMessage(errorResponse);
          } else {
            setErrorMessage(['Sorry, something went wrong']);
          }
        }
      }
    );
  };

  return {
    isLoading,
    handleSubmit,
    data,
    isSuccess,
    formField: {
      name,
      setName,
      address,
      setAddress,
      phone,
      setPhone: setRawPhone,
      email,
      setEmail
    },
    errorMessage
  };
};

export default usePropertyEntity;
