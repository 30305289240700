/** @jsx jsx */
import { Fragment, useRef } from 'react';
import styled from '@emotion/styled';
import { ActionButton, Button, ButtonHabitat, FONTS, Loading } from '@sayrhino/rhino-shared-js';
import useWithdrawalDetails from 'api/v2/useWithdrawalDetails';
import React from 'react';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { initializeDate, numberToUSDFormatter, stringifyDate } from '../../utils';
import { Splitter } from '../../Claims/ClaimDetails/ClaimStyles';
import { jsx } from '@emotion/core';
import { useCancelWithdrawalView } from './useCancelWithdrawalView';
import { WithdrawalProgress } from './WithdrawalProgress';
import { HeaderWrapper, StyledCloseButton } from '../../PropertyOwners/Styled';
import { StyledHeaderText } from './WithdrawalForm';

export const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
});

export const StyledListItem = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const StyledContent = styled.div({
  padding: '3rem'
});

const ActionButtonWrapper = styled.div({
  borderBottom: '1px solid #F4F4F4',
  marginTop: 16,
  paddingBottom: 40,
  marginBottom: 40,
  display: 'flex',
  gap: 8
});

const StyledSectionWrapper = styled.section({
  marginBottom: 50
});

const StyledCancelSection = styled.div({
  margin: '20px auto'
});

const canBeCancelledStates = ["pending", "user_info_needed"]

const WithdrawalDetails: React.FC = () => {
  const navigate = useNavigate();
  const [isCancelling, setIsCancelling] = React.useState<boolean>(false);
  const { withdrawalId } = useParams();

  const { data: withdrawalData, isLoading } = useWithdrawalDetails(String(withdrawalId)) ?? {};
  const { withdrawal, bank_account } = withdrawalData ?? {};
  const { handleSubmit: handleCancelWithdrawal } = useCancelWithdrawalView() ?? {};
  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  const handleIsCancelling = () => setIsCancelling((state) => !state);

  return (
    <React.Fragment>
      <HeaderWrapper>
        <StyledCloseButton onClick={() => navigate(-1)} aria-label="leave bank account form" />
        <StyledHeaderText></StyledHeaderText>
      </HeaderWrapper>
      <StyledContent>
        {isCancelling ? (
          <React.Fragment>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: '16px'
              }}
            >
              <h2 css={[FONTS.h3]}>
                {bank_account?.bank_name} | {bank_account?.account_number}
              </h2>
              <h2 css={[FONTS.h3]}>
                {withdrawal?.amount_in_cents && numberToUSDFormatter(withdrawal?.amount_in_cents / 100)}
              </h2>
            </div>
            <Splitter />
            <StyledCancelSection>
              <h2 css={[FONTS.h3, { margin: '10px auto' }]}>Cancel Withdrawal</h2>

              <p css={[FONTS.p, { margin: '10px auto' }]}>
                Are you sure you want to cancel this withdrawal? This action cannot be reversed.
              </p>
            </StyledCancelSection>

            <ActionButtonWrapper
              css={{
                borderBottom: 'none'
              }}
            >
              <ButtonHabitat id="neverMind" usage="secondary" variant="black" onClick={handleIsCancelling}>
                Never Mind
              </ButtonHabitat>
              <ButtonHabitat
                id="cancelWithdrawal"
                variant="destructive"
                onClick={() => {
                  withdrawal && handleCancelWithdrawal(withdrawal?.withdraw_uid);
                }}
              >
                Yes, Cancel Withdrawal
              </ButtonHabitat>
            </ActionButtonWrapper>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: '16px'
              }}
            >
              <h2 css={[FONTS.h3]}>
                {bank_account?.bank_name} | {bank_account?.account_number}
              </h2>
            </div>
            <ActionButtonWrapper>
              <Link to={`/admin/funds/withdrawals/${withdrawal?.withdraw_uid}/edit`}>
                <ActionButton
                  id="PolicyEdit"
                  variant="noIcon"
                  usage="neutral"
                  disabled={!!withdrawal?.state && !canBeCancelledStates.includes(withdrawal?.state)}
                >
                  Edit Withdrawal
                </ActionButton>
              </Link>

              <ActionButton
                id="PolicyEdit"
                variant="noIcon"
                usage="neutral"
                disabled={!!withdrawal?.state && !canBeCancelledStates.includes(withdrawal?.state)}
                onClick={handleIsCancelling}
              >
                Cancel
              </ActionButton>
            </ActionButtonWrapper>

            <div css={{ borderBottom: '1px solid #F4F4F4', marginBottom: 40 }}>
              <WithdrawalProgress withdrawal={withdrawal} />
            </div>

            <StyledSectionWrapper>
              <React.Fragment>
                <h4 css={[FONTS.h5]}>Withdrawal Details</h4>
                <Splitter />
                <StyledListItem>
                  <p>Withdrawal Amount: </p>
                  <p>{withdrawal?.amount_in_cents && numberToUSDFormatter(withdrawal?.amount_in_cents / 100)}</p>
                </StyledListItem>
                <Splitter />
                <StyledListItem>
                  <p>Status: </p>
                  <p>{withdrawal?.state === "payment_pending" ? "paid" : withdrawal?.state}</p>
                </StyledListItem>
                <Splitter />
                <StyledListItem>
                  <p>Date Requested: </p>
                  <p>{stringifyDate(initializeDate(withdrawal?.created_timestamp))}</p>
                </StyledListItem>
              </React.Fragment>
            </StyledSectionWrapper>

            <StyledSectionWrapper>
              <React.Fragment>
                <h4 css={[FONTS.h5]}>Bank Details</h4>
                <Splitter />
                <StyledListItem>
                  <p>Bank Name: </p>
                  <p>{bank_account?.bank_name}</p>
                </StyledListItem>
                <Splitter />
                <StyledListItem>
                  <p>Account Number: </p>
                  <p>{bank_account?.account_number}</p>
                </StyledListItem>
                <Splitter />
                <StyledListItem>
                  <p>Routing Number: </p>
                  <p>{bank_account?.ach ? bank_account.ach : bank_account?.bic_swift_code}</p>
                </StyledListItem>
                <Splitter />
                <StyledListItem>
                  <p>Account Type: </p>
                  <p>{bank_account?.account_type}</p>
                </StyledListItem>
                <Splitter />
                <StyledListItem>
                  <p>Recipient Name: </p>
                  <p>
                    {bank_account?.first_name_on_account} {bank_account?.last_name_on_account}
                  </p>
                </StyledListItem>
              </React.Fragment>
            </StyledSectionWrapper>
          </React.Fragment>
        )}
      </StyledContent>
    </React.Fragment>
  );
};

export { WithdrawalDetails };
