/** @jsxFrag React.Fragment **/
import React, { useState, useCallback, forwardRef, useImperativeHandle, Ref } from 'react';
import DomPurify from 'dompurify';
import styled from '@emotion/styled';
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from '@reach/combobox';
import '@reach/combobox/styles.css';
import { typeaheadPopoverStyles, typeaheadFormFieldStyles, FormField, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

import usePropertySearch, { usePropertiesByAssociatedPartnersSearch } from 'api/v2/usePropertySearch';
import { InvitationRequestPropertyAccessModal } from '../InvitationRequestPropertyAccessModal';
import useUserRole from '../utils/userRole';
import { useSegmentUser } from '../utils/segmentTracker';
import { isMobileDevice } from 'utils/mobile';
interface PropertyTypeaheadProps {
  onSelect: (id?: number) => void;
}

const sanitize = (html) => DomPurify.sanitize(html);

export interface RefObject {
  reset: () => void;
}

const NoPropertiesMessage = styled.span({
  color: PALETTE.alert125
});

const DisabledAddressOption = styled.li([FONTS.p2], {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0',
  padding: '0.25rem 0.5rem'
});

const DisabledAddressText = styled.span({
  color: PALETTE.neutral25,
  i: {
    fontWeight: 'bold'
  },
  '@media(max-width:425px)': {
    maxWidth: '250px'
  }
});

const NoAccessMsg = styled.span([FONTS.p2], {
  color: PALETTE.neutral55
});

const NoPropertiesMsg = styled.span({
  color: PALETTE.alert125
});

const RequestAccessLink = styled.a({
  color: PALETTE.interaction100,
  textDecoration: 'underline',
  cursor: 'pointer'
});

const Wrapper = styled.div({ ...typeaheadFormFieldStyles });

export const PropertyTypeahead = forwardRef(({ onSelect }: PropertyTypeaheadProps, ref: Ref<RefObject>) => {
  const segmentUser = useSegmentUser();
  const { isPropertyManager, isListingAgent } = useUserRole();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [propertyManagerAdmins, setPropertyManagerAdmins] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalAddressString, setModalAddressString] = useState<string>('');
  const [propertyId, setPropertyId] = useState<number | null>(null);
  const only_active = true;
  const isPMorLA = isPropertyManager || isListingAgent;
  const isLoading = !isPMorLA
    ? usePropertySearch(searchTerm, only_active).isLoading
    : usePropertiesByAssociatedPartnersSearch(searchTerm).isLoading;
  const appHeader = document.getElementsByClassName('invitation-header') as HTMLCollectionOf<HTMLElement>;
  const topBar = document.getElementById('topBar');
  let properties = isPMorLA
    ? usePropertiesByAssociatedPartnersSearch(searchTerm).data
    : usePropertySearch(searchTerm, only_active).data;

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useImperativeHandle(ref, () => {
    return {
      reset
    };
  });

  const reset = () => {
    setSearchTerm('');
  };

  const getAddressString = (property) =>
    `${property.building_name}, ${property.address_line_one}, ${property.address_city}, ${property.address_state}, ${property.address_zip}`;

  const getPropertyIdMap = useCallback(() => {
    return (properties as any[])?.reduce((acc, property) => {
      acc[getAddressString(property)] = { id: property.id };
      return acc;
    }, {});
  }, [properties]);

  const propertyIdMap = getPropertyIdMap();

  const onSelectItem = (value) => {
    setSearchTerm(value);
    if (propertyIdMap) onSelect(propertyIdMap[value].id);
  };

  const boldSearchTerm = (str, substr) => {
    const term = new RegExp('(' + substr + ')', 'ig');
    return str.replace(term, `<i>$1</i>`);
  };

  const setRequestAccessData = (property) => {
    const { id, property_manager_admins } = property;
    setModalAddressString(getAddressString(property));
    setPropertyManagerAdmins(property_manager_admins);
    setPropertyId(id);
  };

  const hideHeader = () => {
    if (!isMobileDevice()) {
      appHeader[0]!.style.display = 'none';
      topBar!.style.zIndex = '0';
    }
  };

  const displayHeader = () => {
    if (!isMobileDevice()) {
      appHeader[0]!.style.display = 'flex';
      topBar!.style.zIndex = '4';
    }
  };

  const trackSegmentEvent = (propertyId) => {
    window.analytics.track('Property Access Requested', {
      ...segmentUser,
      propertyId
    });
  };

  const handleRequestAccess = (property) => {
    trackSegmentEvent(property.id);
    setIsModalOpen(true);
    setRequestAccessData(property);
    hideHeader();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    displayHeader();
  };

  return (
    <>
      <div>
        {isModalOpen && (
          <InvitationRequestPropertyAccessModal
            closeModal={closeModal}
            isModalOpen={isModalOpen}
            modalAddressString={modalAddressString}
            propertyManagerAdmins={propertyManagerAdmins}
            propertyId={propertyId}
          />
        )}
      </div>
      <Wrapper>
        <FormField>
          <Combobox aria-label="Select a property" style={{ flexGrow: 1 }} onSelect={onSelectItem}>
            <ComboboxInput
              placeholder="123 Main Street, Brooklyn, NY, United States"
              autoComplete="off"
              onChange={handleChange}
              id="PropertyTypeahead"
              value={searchTerm}
            />
            <ComboboxPopover
              style={{
                ...(typeaheadPopoverStyles as any),
                zIndex: 1,
                backgroundColor: properties?.length || isLoading ? PALETTE.neutralLight : PALETTE.alert4
              }}
              portal={false}
            >
              <ComboboxList>
                {isLoading ? (
                  <span>Loading...</span>
                ) : properties?.length ? (
                  properties?.map((property) => {
                    return property.user_assigned || !isPMorLA ? (
                      <ComboboxOption key={property.id} value={getAddressString(property)} />
                    ) : (
                      <DisabledAddressOption key={property.id}>
                        <DisabledAddressText
                          dangerouslySetInnerHTML={{ __html: sanitize(boldSearchTerm(getAddressString(property), searchTerm)) }}
                        />
                        {property.property_manager_admins?.length ? (
                          <RequestAccessLink onClick={() => handleRequestAccess(property)}>
                            Request access
                          </RequestAccessLink>
                        ) : (
                          <NoAccessMsg>No access</NoAccessMsg>
                        )}
                      </DisabledAddressOption>
                    );
                  })
                ) : (
                  <NoPropertiesMsg>
                    No matches found – Please confirm that you’ve been added to this property by an admin and try again.
                  </NoPropertiesMsg>
                )}
              </ComboboxList>
            </ComboboxPopover>
          </Combobox>
          <label htmlFor="PropertyTypeahead">Lease address</label>
        </FormField>
      </Wrapper>
    </>
  );
});
