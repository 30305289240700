import React from 'react';
import { Clear as ClearLink } from './styles';

const Clear = ({ filtersAmount, onClick }) => {
  if (filtersAmount === 0) {
    return null;
  }

  let label = 'Filter';
  if (filtersAmount > 1) {
    label = 'Filters';
  }

  return <ClearLink onClick={onClick}>Clear {filtersAmount} {label}</ClearLink>
}

export default Clear;
