/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, { ChangeEvent, FC } from 'react';
import { jsx } from '@emotion/core';
import { Loading, RadioOption } from '@sayrhino/rhino-shared-js';
import { flatMap } from 'lodash';

import { CoverageState } from 'api/v2/useCoverageOptions';
import { CoverageType, isCoverageType } from '../interfaces';
import PropertyDefaultOption from './PropertyDefaultOption';
import DollarAmountOption from './DollarAmountOption';
import MultiplierOption from './MultiplierOption';
import { DOLLAR_AMOUNT, MULTIPLIER, PROPERTY_DEFAULT } from '../utils/constants';
import { IErrors } from '../InvitationDetailsEdit';

export interface ICoverageOptionSelectProps {
  coverageInfo: CoverageState;
  disabled: boolean;
  errors?: IErrors;
}

const labels = {
  [DOLLAR_AMOUNT]: 'Dollar amount',
  [PROPERTY_DEFAULT]: 'Property default',
  [MULTIPLIER]: 'Multiplier'
};

function optionComponent(type: CoverageType): FC<ICoverageOptionSelectProps> {
  switch (type) {
    case DOLLAR_AMOUNT:
      return DollarAmountOption;
    case PROPERTY_DEFAULT:
      return PropertyDefaultOption;
    case MULTIPLIER:
      return MultiplierOption;
    default:
      throw new Error('Invalid coverage type');
  }
}

export default function CoverageOptionsSelect(props: ICoverageOptionSelectProps) {
  const { coverageInfo } = props;
  const { result, selected, setSelected, setType } = coverageInfo;

  if (result.isLoading) {
    return <Loading />;
  } else if (result.isError || result.data === undefined) {
    return <p>Error loading coverage options</p>;
  }

  const changeCoverageTypeIfValid = (type: string) => {
    if (isCoverageType(type)) {
      setType(type);
    }
  };

  const options = result.data;
  const optionProps = (type: CoverageType) => ({
    ...props,
    key: `coverage-option-detail-${type}`
  });

  const opts = flatMap(options.types, (type: CoverageType) => {
    const ret = [
      <RadioOption
        selected={selected.type}
        value={type}
        label={labels[type]}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          changeCoverageTypeIfValid(e.target.value);
        }}
        id={type}
        key={`coverage-option-radio-${type}`}
      />
    ];
    if (selected.type === type) {
      ret.push(React.createElement(optionComponent(type), optionProps(type)));
    }
    return ret;
  });

  return opts;
}
