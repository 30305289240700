/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  Button,
  CaretIcon,
  CloseIcon,
  DropdownMenu,
  SquareButton,
  PersonCircleIcon,
  PALETTE,
  FONTS,
  FONT_FAMILY
} from '@sayrhino/rhino-shared-js';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useReferrer, getInitialStr } from './utils';
import DashboardPanelRule from './DashboardPanelRule';
import DrawerModal from './DrawerModal';
import { useClaimContext } from './utils/claimBatchAssign';
import useClaimsAnalysts from 'api/v2/useClaimsAnalysts';
import batchAssignClaims from 'api/v2/batchAssignClaims';
import { every } from 'lodash';
import { useFilterContext } from './utils/filters';
import { flatMap, some } from 'lodash';
import styled from '@emotion/styled';

interface IClaim {
  administrator: string;
  claim_type: string;
  id?: number;
  property_address_line_one: string;
  renter_full_name: string;
}

interface IProps {
  isAdmin: boolean;
  showModal?: boolean;
}

interface IClaimsCache {
  pageParams: any[];
  pages: IClaimCachePage[];
}

interface IClaimCachePage {
  data: any[];
  meta: any;
}

const CloseLink = styled(Link)({
  alignItems: 'center',
  backgroundColor: PALETTE.neutral4,
  border: 'none',
  borderRadius: '20px',
  display: 'flex',
  height: '2rem',
  justifyContent: 'center',
  width: '2rem'
});

const BatchedClaimRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  '&:hover': {
    background: '#F3F3F3',
    'button:first-child': { visibility: 'visible' }
  }
});

const BatchedClaimRowInfo = styled.div({
  lineHeight: '28px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  width: '480px',
  flexBasis: '0 0 auto'
});

const BatchClaimsInfo = styled.div([
  FONTS.p3,
  {
    margin: '0px 5px',
    lineHeight: '28px',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px'
  }
]);

const RemoveClaimIcon = styled.button({
  background: PALETTE.neutral25,
  borderRadius: '12px',
  height: '20px',
  width: '20px',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '8px',
  visibility: 'hidden'
});

const Header = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  display: 'flex',
  height: '3.75rem',
  justifyContent: 'space-between',
  marginBottom: '2.5rem',
  padding: '1rem 2rem',
  width: '100%'
});

const AnalystIcon = styled.p([
  FONTS.p3,
  FONT_FAMILY.medium,
  {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F3F3F3',
    color: `${PALETTE.neutralDark}`
  }
]);

const DropdownArrowContainer = styled.span({
  borderRadius: '100%',
  width: '24px',
  height: '24px',
  background: PALETTE.neutralLight,
  border: `1px solid ${PALETTE.neutral88}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

let ClaimsBody = styled.div({
  margin: 'auto',
  width: 480,
  minHeight: '780px',
  display: 'flex',
  flexDirection: 'column',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
});

const SelectAllClaimsButton = styled.button([
  FONTS.p2,
  {
    marginLeft: 'auto',
    textDecoration: 'underline',
    cursor: 'pointer',
    border: 'none',
    background: 'none'
  }
]);

const ClearAllClaimsButton = styled.button([
  FONTS.p2,
  {
    textDecoration: 'underline',
    cursor: 'pointer',
    border: 'none',
    background: 'none'
  }
]);

const ClaimBatchAssignPanel = () => {
  const referrer = useReferrer() || '..';
  const {
    setBatchAssignMode,
    batchedClaims,
    setBatchSelectedClaims,
    selectedAnalyst,
    setSelectedAnalyst,
    setBatchedClaims
  } = useClaimContext();
  const { filtersCount, debouncedFilters } = useFilterContext();
  const { data } = useClaimsAnalysts();
  const { mutate: updateClaimsMutation, isLoading } = useMutation(batchAssignClaims, {});
  const unassignable: boolean = batchedClaims.length == 0 || typeof selectedAnalyst.analystID == 'undefined';
  const batchedClaimsAreUnassigned: boolean = every(
    batchedClaims,
    (claim: IClaim) => claim.administrator == '' || claim.administrator == null
  );
  const canSelectAllClaims: boolean = filtersCount > 0 && batchedClaims.length == 0;
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState<boolean>(false);

  function closeModal() {
    setShowModal(false);
  }

  const updateClaimAnalyst = () => {
    updateClaimsMutation(
      { analystID: selectedAnalyst.analystID, claims: batchedClaims },
      {
        onSuccess: () => {
          if (showModal == true) {
            closeModal();
          }
          setBatchSelectedClaims(undefined);
          setSelectedAnalyst({ analystID: undefined, analystName: '' });
          queryClient.invalidateQueries('claims');
        }
      }
    );
  };

  const closePanel = () => {
    setBatchAssignMode();
    setSelectedAnalyst({ analystID: undefined, analystName: '' });
    if (batchedClaims.length > 0) {
      setBatchSelectedClaims(undefined);
    }
  };

  const selectAll = () => {
    // Get data from cache using filters
    const cacheData: IClaimsCache = queryClient.getQueryData(['claims', debouncedFilters]) || {
      pageParams: [],
      pages: [{ data: [], meta: undefined }]
    };

    // Map claims into correct shape for each page
    const claimsList = flatMap(cacheData?.pages, (d) => d.data || []).map((claim) => ({
      administrator: claim.administrator,
      claim_type: claim.claim_type,
      id: claim.id,
      property_address_line_one: claim.property_address_line_one,
      renter_full_name: claim.renter_full_name
    }));

    // Set claims as selected
    setBatchedClaims(claimsList);
  };

  const analystAvatar = (administratorName: string) => {
    if (administratorName && administratorName.length > 0) {
      return <AnalystIcon>{getInitialStr(administratorName)}</AnalystIcon>;
    } else {
      return (
        <PersonCircleIcon css={{ height: '28px', width: '28px', color: `${PALETTE.alert100}`, textAlign: 'right' }} />
      );
    }
  };

  return (
    <div>
      {showModal && (
        <DrawerModal>
          <h3 css={FONTS.h5}>Reassign claims?</h3>
          <p css={FONTS.p2}>
            You're about to reassign some claims that have already been assigned. Are you sure you want to continue?
          </p>
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SquareButton onClick={() => closeModal()} variant="secondary" disabled={isLoading}>
              Never mind
            </SquareButton>
            <SquareButton
              onClick={() => updateClaimAnalyst()}
              variant="primary"
              style={{ marginLeft: '8px' }}
              disabled={isLoading}
            >
              Reassign claims
            </SquareButton>
          </div>
        </DrawerModal>
      )}
      <Header>
        <h5 role="heading" css={[FONTS.h5, FONT_FAMILY.extendedMedium, { color: PALETTE.neutral25 }]}>
          Assign claims
        </h5>
        <CloseLink to={referrer} onClick={() => closePanel()} aria-label="collapse">
          <CloseIcon color={PALETTE.neutral25} height="16px" width="16px" />
        </CloseLink>
      </Header>

      <ClaimsBody>
        <h5 css={[FONTS.h5, FONT_FAMILY.extendedMedium, { color: PALETTE.neutral25, marginBottom: 16 }]}>
          Select claims analyst
        </h5>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger
            style={{
              width: '100%',
              textAlign: 'left',
              color: PALETTE.neutralDark,
              display: 'flex',
              justifyContent: 'space-between'
            }}
            aria-label="select analyst"
          >
            <span>{typeof selectedAnalyst.analystID == 'undefined' ? '-' : selectedAnalyst.analystName}</span>
            <DropdownArrowContainer>
              <CaretIcon width={10} height={4} />
            </DropdownArrowContainer>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {data?.administrators.map((analyst) => (
              <DropdownMenu.Item
                onSelect={() => setSelectedAnalyst({ analystID: analyst.id, analystName: analyst.name_or_email })}
                key={analyst.id}
                style={{ minWidth: '480px' }}
              >
                {analyst.name_or_email}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>

        <div css={{ display: 'flex', marginTop: 48, marginBottom: 16 }}>
          <h5 css={[FONTS.h5, FONT_FAMILY.extendedMedium, { color: PALETTE.neutral65 }]}>Selected claims</h5>
          <SelectAllClaimsButton onClick={() => selectAll()}>Select all from list</SelectAllClaimsButton>
          {batchedClaims.length > 0 && (
            <ClearAllClaimsButton onClick={() => setBatchSelectedClaims(undefined)} aria-label="clear all">
              Clear all
            </ClearAllClaimsButton>
          )}
        </div>
        <DashboardPanelRule css={{ lineHeight: '24px', margin: '0 0 2rem 0' }} />

        <div css={{ minHeight: '200px' }}>
          {batchedClaims.length > 0 &&
            batchedClaims.map((claim) => {
              return (
                <BatchedClaimRow key={claim.id}>
                  <RemoveClaimIcon onClick={() => setBatchSelectedClaims(claim)}>
                    <CloseIcon color={PALETTE.neutralLight} height="12px" width="12px" />
                  </RemoveClaimIcon>
                  <BatchedClaimRowInfo>
                    <BatchClaimsInfo>{claim.renter_full_name}</BatchClaimsInfo>
                    <BatchClaimsInfo>{claim.claim_type}</BatchClaimsInfo>
                    <BatchClaimsInfo>{claim.property_address_line_one}</BatchClaimsInfo>
                    {analystAvatar(claim.administrator)}
                  </BatchedClaimRowInfo>
                </BatchedClaimRow>
              );
            })}
        </div>

        <div css={{ display: 'flex', justifyContent: 'flex-end', margin: 'auto', width: '480px' }}>
          <Button
            disabled={unassignable || isLoading}
            onClick={() => (batchedClaimsAreUnassigned ? updateClaimAnalyst() : setShowModal(true))}
            variant="tertiary"
            style={{ border: `1px solid ${unassignable ? PALETTE.neutral25 : 'black'}`, margin: '24px 0px 48px 0px' }}
          >
            Assign Claims
          </Button>
        </div>
      </ClaimsBody>
    </div>
  );
};

export default ClaimBatchAssignPanel;
