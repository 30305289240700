/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import { GooglePrediction } from '../../../utils/google';
import styled from '@emotion/styled';
import { isPresent } from '../../../utils';
import { getSelectStyles } from 'components/v2/select/styles';

const Wrapper = styled.div({ marginBottom: '24px' });

const Label = styled.label([
  FONTS.labelMedium,
  {
    fontSize: '12px',
    lineHeight: '20px',
    color: PALETTE.neutral65
  }
]);

const SubText = styled.p<{ error?: boolean }>(({ error }) => [
  FONTS.p1,
  {
    fontSize: '12px',
    color: !error ? PALETTE.neutral25 : PALETTE.alert100
  }
]);

interface GoogleAddressInputProps {
  addressOneError: string;
  subtext: string;
  id: string | number;
  label: string;
  placeholder?: string;
  searchError: string;
  isLoading: boolean;
  value: GooglePrediction | null;
  defaultOptions: GooglePrediction[] | [];
  loadOptions: (query: string) => void;
  onChange: (option: GooglePrediction | null) => void;
}

export const GoogleAddressInput = ({
  addressOneError,
  id,
  label,
  searchError,
  isLoading,
  value,
  defaultOptions,
  loadOptions,
  onChange
}: GoogleAddressInputProps) => {
  return (
    <Wrapper>
      <Label>{`${label}`}</Label>
      <AsyncSelect
        id={id}
        isClearable={true}
        styles={getSelectStyles({ isError: addressOneError || searchError })}
        isLoading={isLoading}
        value={value}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onChange={onChange}
        inputId="physical-check-address-search"
        getOptionValue={(option: any) => option && option.place_id}
        getOptionLabel={(option: any) => option && option.description}
        noOptionsMessage={({ inputValue }) => (!inputValue ? 'Type to search' : 'No addresses found')}
        placeholder={'Enter address'}
      />
      {isPresent(searchError) || isPresent(addressOneError) ? (
        <SubText error={true}>{searchError || addressOneError}</SubText>
      ) : null}
    </Wrapper>
  );
};

export default GoogleAddressInput;
