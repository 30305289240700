import React, { useState } from 'react';
import AsyncSelect from 'components/v2/select/AsyncSelect';
import { useQueryClient } from 'react-query';
import { getSelectStyles } from 'components/v2/select/styles';
import axios from 'axios';
import { Wrapper, Label, ErrorLabel } from './Styles';

export type PropertyManagerResult = {
  id: number;
  email: string;
};
type PropertyManagerTypeaheadProps = {
  onChange: (propertyManagers: PropertyManagerResult[]) => void;
  selectedPM: PropertyManagerResult[];
  id: string;
};

const getPropertyManagers = async (query: string) => {
  const { data } = await axios.get<{ managers: PropertyManagerResult[] }>(`/property_managers/search`, {
    params: { query: query }
  });
  return data;
};

export const PropertyManagerTypeahead = ({ onChange, selectedPM, id }: PropertyManagerTypeaheadProps) => {
  const [propertyManagers, setPropertyManagers] = useState<PropertyManagerResult[]>();
  const [searchError, setSearchError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const fetchPMOnSearch = async (input: string): Promise<{}> => {
    return queryClient.fetchQuery(['policySearch', input], () => getPropertyManagers(input));
  };

  const loadOptions = async (input: string) => {
    setIsLoading(() => true);
    try {
      const { managers } = (await fetchPMOnSearch(input)) as { managers: PropertyManagerResult[] };
      setPropertyManagers(managers);
      setIsLoading(false);
      return managers;
    } catch (error) {
      setIsLoading(() => false);
      setSearchError(`Something went wrong! ${error.toString()}`);
      return [];
    }
  };

  return (
    <Wrapper>
      {searchError ? (
        <ErrorLabel htmlFor={id}>{searchError}</ErrorLabel>
      ) : (
        <Label htmlFor={id}>Property Manager Email Address</Label>
      )}
      <AsyncSelect
        styles={getSelectStyles({})}
        id={id}
        isClearable={true}
        isLoading={isLoading}
        value={selectedPM}
        defaultOptions={propertyManagers}
        components={{ DropdownIndicator: () => null }}
        loadOptions={loadOptions}
        onChange={(value) => onChange(value)}
        inputId="property-manager-search"
        getOptionValue={(option: PropertyManagerResult) => option.id}
        getOptionLabel={(option: PropertyManagerResult) => option.email}
        placeholder=""
        isMulti
      />
    </Wrapper>
  );
};
