/** @jsx jsx */
import React, { useRef, useState } from 'react';
import { format, parse } from 'date-fns';
import { csrfToken } from 'utils/document';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  TextInput,
  FONTS,
  Button,
  CloseIcon,
  Datepicker,
  Loading,
  RadioGroup,
  RadioOption,
  PALETTE
} from '@sayrhino/rhino-shared-js';
import { stringifyDate, parseIntDecimal, urlWithReferrer, useReferrer, initializeDate } from './utils';
import useOverlay from './utils/overlay';
import usePolicy from '../../../api/v2/usePolicy';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import useCancelPolicyMutation from '../../../api/v2/useCancelPolicyMutation';
import useEarlyTerminatePolicyMutation from '../../../api/v2/useEarlyTerminatePolicyMutation';
import { startOfToday, differenceInDays, parseISO } from 'date-fns';


enum CancellationReasons {
  DID_NOT_SIGN_LEASE = 0,
  DID_NOT_RENEW = 1,
  ROOMMATE_SIGNED_UP = 2,
  DUPLICATE_POLICY = 3,
  PAID_CASH_DEPOSIT = 4,
  OTHER = 5,
  MOVED_OUT = 6
}

const flatCancellationReasons = [
  { label: 'Did not sign lease', value: CancellationReasons.DID_NOT_SIGN_LEASE },
  { label: 'Did not renew', value: CancellationReasons.DID_NOT_RENEW },
  { label: 'Roommate signed up', value: CancellationReasons.ROOMMATE_SIGNED_UP },
  { label: 'Duplicate policy', value: CancellationReasons.DUPLICATE_POLICY },
  { label: 'Paid cash deposit', value: CancellationReasons.PAID_CASH_DEPOSIT }
];

const ViewPolicyDetailButton = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  width: 32,
  backgroundColor: PALETTE.neutral4,
  borderRadius: 75,
  float: 'right',
  marginTop: '14px',
  marginRight: '32px'
});

const CancelPolicyContainer = styled.div({
  backgroundColor: PALETTE.neutralLight,
  padding: '96px',
  alignItems: 'center'
});

const TenantFullName = styled.h4([FONTS.h4, { color: `${PALETTE.neutral55}` }]);

const TenantDetailsDivider = styled.div({
  width: '100%',
  border: 'none',
  borderBottom: `${PALETTE.neutral12} solid 1px`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 0 16px 0'
});

const CancelPolicyDisclaimer = styled.div({
  padding: '48px 0 22px 0'
});

const CancelPolicyHeader = styled.h3(FONTS.h3);

const P1Font = styled.p(FONTS.p1);

const P5Font = styled.p(FONTS.h5);

const MovedOutDate = styled.div({
  marginTop: 12,
  marginBottom: 12
});

const RequestedAndAcceptedDate = styled.div({
  marginTop: 12
});

const ConfirmInfoAgreementContainer = styled.div({
  padding: '12px 20px',
  marginTop: 25,
  backgroundColor: PALETTE.brand4,
  borderRadius: 8
});

const ConfirmInfoAgreementText = styled.p([FONTS.p2, { color: `${PALETTE.brand125}` }]);

const ConfirmOrNvmButtonContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '65px 0'
});

const NeverMindButton = styled.div({
  borderColor: PALETTE.neutral55 + '!important'
});

const YesCancelButton = styled.div({
  '&:disabled': {
    border: '1px solid !important'
  },
  float: 'right'
});

export const CancelPolicyView = () => {
  const { policyId } = useParams();
  const { data } = usePolicy(Number(policyId));
  const navigate = useNavigate();
  const referrer = useReferrer();

  const [cancellationReason, setCancellationReason] = useState<number | undefined>();
  const [cancellationOtherReason, setCancellationOtherReason] = useState<string | undefined>();
  const [requestedDate, setRequestedDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'));
  const [acceptedDate, setAcceptedDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'));
  const requiredFields = [cancellationReason, requestedDate, acceptedDate];
  const isFormValid = requiredFields.every((item) => typeof item !== 'undefined');
  const token = csrfToken();
  const { mutate: cancelPolicy } = useCancelPolicyMutation();
  const acceptedToDate = parse(acceptedDate, 'MM/dd/yyyy', new Date());
  const requestedToDate = parse(requestedDate, 'MM/dd/yyyy', new Date());
  var defaultEarlyTermDate = new Date();
  if (data) {
    defaultEarlyTermDate = new Date() < new Date(data.lease_start_date) ? new Date(data.lease_start_date) : new Date();
  }
  const [endDate, setEndDate] = useState<Date>(defaultEarlyTermDate);
  const { mutate: earlyTermPolicy } = useEarlyTerminatePolicyMutation();

  const formatDate = (date: Date) => {
    return format(date, 'MM/dd/yyyy');
  };

  const earlyTermLeaseEndDate = () => {
    if (cancellationReason === CancellationReasons.MOVED_OUT) {
      return endDate
    } else {
      return requestedToDate
    }
  }
    
  const onConfirm = () => {
    if (earlyTermReasonSelected()) {
      earlyTermPolicy(
        {
          token,
          endDate: earlyTermLeaseEndDate(),
          policyId,
          cancellationReason,
          cancellationOtherReason,
          requestedDate: format(requestedToDate, 'MM-dd-yyyy'),
          acceptedDate: format(acceptedToDate, 'MM-dd-yyyy'),
        },
        {
          onSuccess: () => navigate(-1)
        }
      );
    } else {
      cancelPolicy(
        {
          token,
          cancellationReason,
          requestedDate: format(requestedToDate, 'MM-dd-yyyy'),
          acceptedDate: format(acceptedToDate, 'MM-dd-yyyy'),
          policyId
        },
        {
          onSuccess: () => navigate(-1)
        }
      );
    }
  };

  const thirtyDaysOut = () => {
    if (data) {
      return differenceInDays(startOfToday(), parseISO(data.coverage_start_date)) >= 30;
    }
  };

  const earlyTermReasonSelected = () => {
    return cancellationReason === CancellationReasons.MOVED_OUT || cancellationReason === CancellationReasons.OTHER
  }

  return (
    <div>
      <ViewPolicyDetailButton
        to={urlWithReferrer(`/admin/renter_policies/${policyId}`, referrer)}
        aria-label="view policy details"
      >
        <CloseIcon height={14} width={14} />
      </ViewPolicyDetailButton>

      <CancelPolicyContainer>
        <TenantFullName>{data?.tenant_full_name}</TenantFullName>
        <TenantDetailsDivider>
          <P1Font>{data?.property_address}</P1Font>
          <P5Font>{data?.unit_name}</P5Font>
        </TenantDetailsDivider>

        <CancelPolicyDisclaimer>
          <CancelPolicyHeader>Cancel Policy?</CancelPolicyHeader>
          <P1Font>
            If you cancel this policy, this resident and their unit will no longer be covered by Rhino. This action
            cannot be reversed.
          </P1Font>
        </CancelPolicyDisclaimer>

        {data?.can_terminate_early && thirtyDaysOut() && (
          <div>
            <div>
              <RadioOption
                label="Moved out"
                id="early-termination-moved-out"
                value={CancellationReasons.MOVED_OUT}
                selected={cancellationReason}
                onChange={(selectedVal) => setCancellationReason(parseIntDecimal(selectedVal.target.value))}
              />
              {cancellationReason === CancellationReasons.MOVED_OUT && (
                <MovedOutDate>
                  <Datepicker
                    onChange={(date) => setEndDate(date)}
                    maxDate={new Date()}
                    minDate={initializeDate(data.coverage_start_date)}
                    selected={endDate ? endDate : defaultEarlyTermDate}
                    value={endDate ? stringifyDate(endDate) : stringifyDate(new Date())}
                    inputLabel="Lease End Date"
                    selectsRange={false}
                  />
                </MovedOutDate>
              )}
            </div>
            <div>
              <RadioOption
                label="Other"
                id="early-termination-other"
                value={CancellationReasons.OTHER}
                selected={cancellationReason}
                onChange={(selectedVal) => setCancellationReason(parseIntDecimal(selectedVal.target.value))}
              />
              {cancellationReason === CancellationReasons.OTHER && (
                <div>
                  <TextInput
                    style={{ marginLeft: '40px', marginTop: '-25px' }}
                    label={'Reason for cancellation'}
                    name={'other'}
                    id={'other'}
                    onChange={(event) => setCancellationOtherReason(event.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
          
        )}
        {!thirtyDaysOut() && (<RadioGroup
          options={flatCancellationReasons}
          onChange={(selectedVal) => setCancellationReason(parseIntDecimal(selectedVal.target.value))}
          name="radio-group"
          selected={cancellationReason}
        />)}

        {cancellationReason !== undefined && (
          <div>
            <RequestedAndAcceptedDate>
              <Datepicker
                onChange={(date) => setRequestedDate(formatDate(date))}
                startDate={requestedToDate}
                endDate={requestedToDate}
                selected={requestedToDate}
                value={requestedDate ? requestedDate : 'Select requested date'}
                inputLabel="Requested date"
                id="requestedDate"
                selectsRange={false}
                maxDate={new Date()}
                minDate={initializeDate(data?.coverage_start_date)}
              />
            </RequestedAndAcceptedDate>

            <RequestedAndAcceptedDate>
              <Datepicker
                onChange={(date) => setAcceptedDate(formatDate(date))}
                startDate={acceptedToDate}
                endDate={acceptedToDate}
                selected={acceptedToDate}
                value={acceptedDate ? acceptedDate : 'Select accepted date'}
                inputLabel="Accepted date"
                selectsRange={false}
                maxDate={new Date()}
                minDate={initializeDate(data?.coverage_start_date)}
              />
            </RequestedAndAcceptedDate>
          </div>
        )}

        {earlyTermReasonSelected()  && (
          <ConfirmInfoAgreementContainer>
            <ConfirmInfoAgreementText>
              By confirming this information, you are agreeing to cancel this Rhino policy early and that no future
              claims will be submitted on the policy after this move-out date.
            </ConfirmInfoAgreementText>
          </ConfirmInfoAgreementContainer>
        )}
        <ConfirmOrNvmButtonContainer>
          <NeverMindButton>
            <Button variant="tertiary" onClick={() => navigate(-1)}>
              Never mind
            </Button>
          </NeverMindButton>
          <YesCancelButton>
            <Button variant="tertiary" onClick={onConfirm} disabled={!isFormValid}>
              Yes, cancel policy
            </Button>
          </YesCancelButton>
        </ConfirmOrNvmButtonContainer>
      </CancelPolicyContainer>
    </div>
  );
};

const CancelPolicy = () => {
  const { policyId } = useParams();
  const { isSuccess } = usePolicy(Number(policyId));
  const { setShowOverlay } = useOverlay();

  React.useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return isSuccess ? <CancelPolicyView /> : <Loading />;
};

export default CancelPolicy;
