import styled from '@emotion/styled';
import { FONTS } from '@sayrhino/rhino-shared-js';
import ClaimClassificationFilterOptions from './ClaimClassificationFilterOptions';

export const StyledTitleDiv = styled.div({
  alignItems: 'baseline',
  display: 'flex',
  justifyContent: 'space-between'
});

export const StyledOptionsDiv = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '1rem'
});

export const StyledWrapperDiv = styled.div({ marginBottom: '1.5rem' });

export const StyledH5 = styled.h5([FONTS.h5, { marginBottom: '1.5rem' }]);

export const StyledClaimClassificationFilterOptions = styled(ClaimClassificationFilterOptions)({
  paddingLeft: '48px',
  paddingRight: '48px'
});
