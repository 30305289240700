import React from 'react';
import { Count, Li } from './styles';
import { IOption } from './types';

interface IProps {
  name:     string;
  value:    string
  options:  IOption[];
  onChange: Function;
}

const Radios = ({ options, onChange, name, value }: IProps) => {
  const onClick = (val: string) => {
    /* ( multiple choices )
    const index = checked.indexOf(val);
    let newChecked = [...checked];
    if (index >= 0) {
      newChecked.splice(index, 1);
    } else {
      newChecked.push(val);
    }
     */

    onChange(name, val);
  }

  /* ( multiple choices )
  const isChecked = (val: string) => value.indexOf(val) >= 0
   */
  const isChecked = (val: string) => val === value

  return <ul style={{display: 'inline-block', marginLeft: '-5px'}}>
    {
      options.map((option) =>
        <Li
          className={isChecked(option.id) ? 'checked' : null}
          key={option.id}
          onClick={() => onClick(option.id)}
        >
          {option.text}
          {/* <Count>28</Count> */}
        </Li>)
    }
  </ul>
}

export default Radios;
