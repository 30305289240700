/** @jsx jsx */
import { PALETTE, FONTS, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import ReactTooltip from 'react-tooltip';
import { flatMap } from 'lodash';
import { css, jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { PropertyCsv } from './interfaces';
interface IProps {
  cell: any;
  attr: string;
  width: number;
  csv: PropertyCsv;
  disabled?: boolean;
  onBlur: (e, row, attr) => void;
}
// uses !important to override default styles in ReactToolTip
const toolTipCSS = css({
  padding: '24px 32px !important',
  border: `1px solid ${PALETTE.neutral12} !important`,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16) !important',
  borderRadius: '8px !important',
  width: '336px',
  opacity: '10 !important'
});
const cellCSS = css([FONT_FAMILY.light], { fontSize: '1.125rem', border: 'none', background: 'transparent' });

// Maps error fields to the columns they should be displayed on.
// Key is the column, value is a list of error fields that should be displayed
// on that column.
const ERROR_MAPPINGS = {
  address: ['property'],
  unit_name: ['unit']
};

function expandAttrs(attr: string): string[] {
  const extraAttrs: string[] | undefined = ERROR_MAPPINGS[attr];
  const attrs = extraAttrs !== undefined ? [attr, ...extraAttrs] : [attr];
  return attrs;
}

export function hasError(cell, attr: string) {
  const attrs = expandAttrs(attr);
  return attrs.reduce((m, a) => m || cell.row.original.hasOwnProperty(a), false);
}

function formatError(rowValues, rowId, attr) {
  const attrs = expandAttrs(attr);
  return (
    <div>
      {flatMap(attrs, (currentAttr) => {
        return flatMap(rowValues[currentAttr], (dataTip, index) => [
          <p key={`${rowId}-${currentAttr}-error-message-${index}`} css={[FONTS.p1Medium, { margin: '8px 0' }]}>
            {dataTip.message}
          </p>,
          <p key={`${rowId}-${currentAttr}-error-detail-${index}`} css={FONTS.p2}>
            {dataTip.detail}
          </p>
        ]);
      })}
    </div>
  );
}

const EditableCell = (props: IProps) => {
  const { cell, attr, width, csv, disabled } = props;
  const row = cell.row;
  const rowValues = row.original;
  const csvColumnValue = csv[rowValues.row][attr];
  const [value, setValue] = useState(csvColumnValue);

  useEffect(() => {
    setValue(csvColumnValue);
  }, [csvColumnValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = (e) => {
    props.onBlur(e.target.value, row, attr);
  };

  return (
    <div>
      <input
        css={[cellCSS, { width }]}
        value={value || ''}
        role="textbox"
        readOnly={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hasError(cell, attr) && (
        <ReactTooltip
          type="light"
          effect="solid"
          place="bottom"
          css={toolTipCSS}
          id={`${cell.column.id}-${row.id}`}
          getContent={() => formatError(rowValues, row.id, attr)}
        />
      )}
    </div>
  );
};

export default EditableCell;
