import { CoverageType, IInvitationType } from '../interfaces';

// Filters
export const CLAIM_TOP_STATUS_FILTER_OPTIONS = [
  { label_text: 'Open', value: 'open' },
  { label_text: 'Closed', value: 'closed' },
  { label_text: 'Withdrawn', value: 'withdrawn' },
  { label_text: 'Denied', value: 'denied' }
];

export const CLAIM_STATUSES_ON_PAID = [
  { label_text: 'Closed - Without payment', value: 'closed_without_payment' },
  { label_text: 'Subrogation', value: 'subrogation' },
  { label_text: 'Subrogation - Paid in full', value: 'subrogation_paid_in_full' },
  { label_text: 'Collections', value: 'collections' },
  { label_text: 'Collections - Paid', value: 'collections_paid' },
  { label_text: 'Collections - Unpaid', value: 'collections_unpaid' },
  { label_text: 'Payment plan', value: 'payment_plan' },
  { label_text: 'Payment plan - In progress', value: 'payment_plan_in_progress' }
];

export const CLAIM_STATUS_HIDDEN_PARTNERS = [
  { label_text: 'Open', value: 'open' },
  { label_text: 'Closed', value: 'closed' },
  { label_text: 'New', value: 'new' },
  ...CLAIM_STATUSES_ON_PAID
];

// Coverage types
export const MULTIPLIER: CoverageType = 'MULTIPLIER';
export const DOLLAR_AMOUNT: CoverageType = 'DOLLAR_AMOUNT';
export const NO_COVERAGE: CoverageType = 'NO_COVERAGE';
export const PROPERTY_DEFAULT: CoverageType = 'PROPERTY_DEFAULT';

// Coverage option language
export const CUSTOM_DOLLAR_AMT_COVERAGE = 'Enter dollar amount';
export const PROPERTY_DEFAULT_AMT_COVERAGE = 'Use property default';

// Summary metrics copy
export const IN_PROGRESS_INVITATIONS_TITLE = 'In-progress invitations';
export const SIGN_UP_RATE_DESCRIPTION =
  'Renters see the value Rhino brings to them when given the choice between a security deposit or Rhino.';
export const SIGN_UP_RATE_LINK_TEXT = 'Invite renters to unlock this stat';
export const SIGN_UP_RATE_TITLE = 'Sign up rate for invitations sent';
export const TOTAL_INVITATIONS_TITLE = 'Total invitations';
export const ACTIVE_POLICIES_TITLE = 'Active policies';
export const COVERED_UNITS_TITLE = 'Covered units';
export const NEW_POLICIES_THIS_MONTH_TITLE = 'New policies this month';
export const SENT_INVITATIONS_TITLE = 'Sent invitations';
export const SUBSCRIBED_TITLE = 'Subscribed';

// Claim Summary metric
export const OPEN_CLAIMS_TITLE = 'Open Claims';
export const RESOLVED_CLAIMS_TITLE = 'Resolved claims this month';
export const LOSS_OF_RENT_TITLE = 'Loss of rent';
export const DAMAGES_TITLE = 'Damages';
export const TOTAL_CLAIM_PAYOUT_TITLE = 'Total claims payout';
export const TOTAL_RECLAIMED_TITLE = 'Total reclaimed';
export const AVERAGE_DAY_APPROVE_TITLE = 'Average days to payout approved claims';
export const AVERAGE_DAY_APPROVE_DESC = 'Filed claims usually have a 4 day settle rate.';

// Deposits Summary Metric
export const OPEN_WITHDRAWALS_TITLE = 'Pending Withdrawals';
export const FUNDS_AVAILABLE_TO_WITHDRAW_TITLE = 'Funds available to withdraw';
export const BANK_ACCOUNTS_TITLE = 'Bank accounts';

export const INVITATION_TYPE = {
  manual: 'Manual' as IInvitationType,
  auto: 'Automated' as IInvitationType
};

// Corresponding to `app/assets/stylesheets/breakpoint_variables.scss`
export type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type BreakpointValue = 0 | 576 | 768 | 992 | 1200 | 1400;
export const BREAKPOINTS = {
  xs: 0 as const,
  sm: 576 as const,
  md: 768 as const,
  lg: 992 as const,
  xl: 1200 as const,
  xxl: 1400 as const
};

// Corresponding to $top-bar--width-expanded and $top-bar--width-collapsed in app/assets/stylesheets/claims_portal/layout/v2/top_bar.scss
export const NAV_WIDTH = {
  expanded: '214px' as const,
  collapsed: '64px' as const
};
