import React, { useEffect, useState } from 'react';
import Modal, { UserTrackDetails } from './Modal';
import PropertyUnitCSVUpdateModal from './PropertyUnitCSVUpdateModal';
import { Button } from '@sayrhino/rhino-shared-js';
import { redirectTo } from '../utils';

interface IProps {
  isAdmin: boolean;
  showModal?: boolean;
  showPropertyUnitModal?: boolean;
  user: UserTrackDetails;
}

const OPEN_MODAL_CLASS = 'fullscreen-modal-visible';

export default function PropertyUploadButton(props: IProps) {
  const [showModal, setShowModal] = useState(props.showModal || false);
  const [showPropertyUnitModal, setshowPropertyUnitModal] = useState(props.showPropertyUnitModal || false);
  const singlePropertyUpload = (window as any).App?.featureFlags?.singlePropertyUpload;
  const { isAdmin, user } = props;
  const { user_id, portal_session_id, user_type } = user;

  const onClick = () => {
    if (isAdmin || !singlePropertyUpload) {
      setShowModal(true);
    } else if (singlePropertyUpload) {
      window.location.href = '/admin/properties/add/new';
      window.analytics.track('ADD NEW PROPERTY Button Clicked', {
        user_id: user_id,
        portal_session_id: portal_session_id,
        user_type: user_type
      });
    }
  };

  const onClickPropertyUnitCSVUpdate = () => {
    if (isAdmin) setshowPropertyUnitModal(true);
  };

  useEffect(() => {
    if (showModal) {
      document.body.classList.add(OPEN_MODAL_CLASS);
    } else {
      document.body.classList.remove(OPEN_MODAL_CLASS);
    }
  }, [showModal]);

  useEffect(() => {
    if (showPropertyUnitModal) {
      document.body.classList.add(OPEN_MODAL_CLASS);
    } else {
      document.body.classList.remove(OPEN_MODAL_CLASS);
    }
  }, [showPropertyUnitModal]);

  function closeModal(currentView: string) {
    const msg =
      currentView === 'UploadComplete' || currentView === 'AssignTeam'
        ? 'Are you sure you want to exit?'
        : "Are you sure you want to exit? Your properties will not be uploaded, and you'll have to start over.";
    if (confirm(msg)) {
      //setShowModal(false);
      redirectTo('/admin/properties?table=true');
    }
  }

  function closePropertyUnitCSVUpdateModal(currentView: string) {
    const msg =
      currentView === 'UploadComplete' || currentView === 'AssignTeam'
        ? 'Are you sure you want to exit?'
        : "Are you sure you want to exit? Your properties will not be uploaded, and you'll have to start over.";
    if (confirm(msg)) {
      redirectTo('/admin/properties?table=true');
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px', flexWrap: 'wrap' }}>
      {showModal && (
        <Modal isAdmin={isAdmin} closeModal={(currentView: string) => closeModal(currentView)} user={user} />
      )}

      {showPropertyUnitModal && (
        <PropertyUnitCSVUpdateModal
          isAdmin={isAdmin}
          closeModal={(currentView: string) => closePropertyUnitCSVUpdateModal(currentView)}
          user={user}
        />
      )}

      <Button onClick={onClick} variant="secondary" id="property-upload-button">
        Add new property
      </Button>

      <Button onClick={onClickPropertyUnitCSVUpdate} variant="secondary" id="property-unit-csv-update-button">
        Property / Unit CSV Update
      </Button>
    </div>
  );
}
