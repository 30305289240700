/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Checkbox, FONTS } from '@sayrhino/rhino-shared-js';
import { FC } from 'react';
import { QueryObserverResult } from 'react-query';
import {
  ClaimStatus,
  IClaimStaticFilterOptions,
  IFilterOption,
  IFilters,
  IFilterStatusTitle,
  IStaticFilterOptions
} from '../interfaces';
import { StyledCheckbox, clearButtonCSS, newSelections } from './index';
import { useUserRole } from '../utils/userRole';

import { CLAIM_STATUS_HIDDEN_PARTNERS } from '../utils/constants';
import { StyledTitleDiv, StyledOptionsDiv, StyledH5, StyledWrapperDiv } from './ClaimFilterStyles';

const ClaimStatusFilterOptions: FC<{
  css?: any;
  query: () => QueryObserverResult<IClaimStaticFilterOptions | IStaticFilterOptions>;
  selectedStatuses: string[];
  setFilters: (args: Partial<IFilters>) => void;
  title: IFilterStatusTitle;
  topStatuses?: IFilterOption[];
}> = (props) => {
  const { query, selectedStatuses, setFilters, title, topStatuses = [], ...rest } = props;
  const { data } = query();
  const userRole = useUserRole();
  const { isPartner } = userRole;

  const isSelectedFiltered = (selected) => {
    return selected === ClaimStatus.PROCESSING && isPartner;
  };

  const updateStatus = () => {
    return selectedStatuses.includes(ClaimStatus.PROCESSING)
      ? setFilters({
          statuses: [...selectedStatuses].filter(
            (status) => status !== ClaimStatus.NEW && status !== ClaimStatus.PROCESSING
          )
        })
      : setFilters({ statuses: [...selectedStatuses, ClaimStatus.PROCESSING, ClaimStatus.NEW] });
  };

  const showAllOptions = (statuses, topStatuses) => {
    return statuses.filter((status) => !topStatuses.map((topStatus) => topStatus.value).includes(status.value));
  };

  const hideSomeOptions = (statuses) => {
    return statuses.filter(
      (status) => !CLAIM_STATUS_HIDDEN_PARTNERS.map((statusToHide) => statusToHide.value).includes(status.value)
    );
  };

  const checkboxes = (opts) => {
    return (
      <StyledOptionsDiv>
        {opts.map((option, index) => {
          return (
            <StyledCheckbox key={`${option}-${index}`}>
              <Checkbox
                checked={selectedStatuses.includes(option.value)}
                id={`checkbox-status-filter-${index}-${option.value}`}
                css={{ minWidth: 20 }}
                key={`checkbox-status-filter-${index}-${option.value}`}
                name={option.value}
                onCheckedChange={() =>
                  isSelectedFiltered(option.value)
                    ? updateStatus()
                    : setFilters({ statuses: newSelections(option.value, selectedStatuses) })
                }
                label={option.label_text}
              />
            </StyledCheckbox>
          );
        })}
      </StyledOptionsDiv>
    );
  };

  const topOptions = topStatuses.length > 0 && data?.statuses && (
    <StyledWrapperDiv>
      {checkboxes(
        topStatuses.filter((topStatus) => data.statuses.map((status) => status.value).includes(topStatus.value))
      )}
    </StyledWrapperDiv>
  );
  const options = data?.statuses && (
    <StyledWrapperDiv>
      {checkboxes(isPartner ? hideSomeOptions(data.statuses) : showAllOptions(data.statuses, topStatuses))}
    </StyledWrapperDiv>
  );

  return data?.statuses ? (
    <div {...rest}>
      <StyledTitleDiv>
        <StyledH5>{title}</StyledH5>
      </StyledTitleDiv>
      {!isPartner && topOptions}
      {options}
    </div>
  ) : null;
};

export default ClaimStatusFilterOptions;
