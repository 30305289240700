/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FONTS, PALETTE } from '@sayrhino/rhino-shared-js';
import { FC } from 'react';
import { QueryObserverResult } from 'react-query';
import {
  IClaimStaticFilterOptions,
  IFilterOption,
  IFilters,
  IFilterStatusTitle,
  IStaticFilterOptions
} from '../interfaces';
import { ClearButton, Divider } from './index';
import StatusFilterCheckbox from './StatusFilterCheckbox';
import styled from '@emotion/styled';

const CheckboxSectionContainer = styled.div({
  marginBottom: '24px'
});

const OptionsContainer = styled.div({
  marginBottom: '1.5rem'
});

const StatusesWrapper = styled.div({ alignItems: 'baseline', display: 'flex', justifyContent: 'space-between' });

const Title = styled.h4([FONTS.h4], {
  marginBottom: '15px'
});

const InsurancePolicyStatusFilterOptions: FC<{
  css?: any;
  query: () => QueryObserverResult<IClaimStaticFilterOptions | IStaticFilterOptions>;
  selectedStatuses: string[];
  setFilters: (args: Partial<IFilters>) => void;
  title: IFilterStatusTitle;
  topStatuses?: IFilterOption[];
}> = (props) => {
  const { query, selectedStatuses, setFilters, title, topStatuses = [], ...rest } = props;
  const { data } = query();

  const filterCheckboxProps = {
    selectedStatuses,
    setFilters
  };

  const checkboxes = (opts) => {
    return (
      <div>
        <Divider />
        <CheckboxSectionContainer>
          <StatusFilterCheckbox title="Policy Details" options={opts.policy_details} {...filterCheckboxProps} />
          <StatusFilterCheckbox title="Cancellations" options={opts.cancellations} {...filterCheckboxProps} />
          <StatusFilterCheckbox title="Renewals" options={opts.renewals} {...filterCheckboxProps} />
        </CheckboxSectionContainer>
      </div>
    );
  };
  const options = data?.statuses && <OptionsContainer>{checkboxes(data.statuses)}</OptionsContainer>;

  return data?.statuses ? (
    <div {...rest}>
      <StatusesWrapper>
        <Title>{title}</Title>
        {selectedStatuses.length ? <ClearButton onClick={() => setFilters({ statuses: [] })}>Clear</ClearButton> : null}
      </StatusesWrapper>
      {options}
    </div>
  ) : null;
};

export default InsurancePolicyStatusFilterOptions;
