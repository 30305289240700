import 'whatwg-fetch';
import http from 'j-fetch';

// see docs on available fetch options at https://github.com/github/fetch#usage
export function request(url: string, query: any = {}): Promise<any> {
  return http.get({ url, query }).then((response): Promise<any> => response.json());
}

export function post(url: string, body: {}): Promise<any> {
  return http.post({ url, body })
    .then((response): Promise<any> => response.json())
    .catch((response): Promise<any> => {
      return response.json().then((r): Promise<any> => Promise.reject(r))
    });
}

export function put(url: string, body: {}): Promise<any> {
  return http.put({ url, body })
    .then((response): Promise<any> => response.json())
    .catch((response): Promise<any> => {
      return response.json().then((r): Promise<any> => Promise.reject(r))
    });
}

export function putRaw(url: string, body: {}): Promise<any> {
  return http.put({ url, body });
}
