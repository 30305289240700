import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation, MutationFunction } from 'react-query';
import { IClaimStatusOption, ClaimStatus } from 'components/v2/App/interfaces';

type IClaimStatus = { id: number; selectedStatus?: IClaimStatusOption };
interface IStatusResponse {
  status: ClaimStatus;
}

const updateClaimStatus: MutationFunction<AxiosResponse<IStatusResponse>, IClaimStatus> = async (
  claim: IClaimStatus
): Promise<AxiosResponse<IStatusResponse>> => {
  const authenticity_token = csrfToken();
  const response: AxiosResponse<IStatusResponse> = await axios.put(
    `/v2/admin/claims/${claim.id}/set_status`,
    {
      status: claim.selectedStatus?.value
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return response;
};

export default function useUpdateClaimStatus() {
  return useMutation(updateClaimStatus);
}
