import React from 'react';
import { render } from '@testing-library/react';
import Dashboard from '../Dashboard';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';

describe('<Dashboard />', () => {
  let server;
  beforeEach(() => (server = makeServer()));
  afterEach(() => server.shutdown());

  test('jump links', async () => {
    const { findByText, getByTestId } = render(
      <Wrapper>
        <Dashboard />
      </Wrapper>
    );
    const tasksHeading = await findByText('Action items');

    expect(tasksHeading).toBeDefined();
    expect(tasksHeading).toHaveTextContent('Action items');

    const updatesHeader = getByTestId('updates-header');
    const updatesLink = getByTestId('updates-link');

    expect(updatesHeader).toBeDefined();
    expect(updatesHeader).toHaveTextContent('Activity feed');
    expect(updatesLink).toBeDefined();
  });
});
