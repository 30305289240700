/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PhoneInput, Button, PALETTE, Alert2Icon, TextInput, DateInput, CalendarIcon } from '@sayrhino/rhino-shared-js';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import { PolicyInviteTypeahead } from '../CreateInvitationTypeahead/PolicyInviteTypeahead';
import { IPolicy } from './CreatePolicyRenewalInvitation';
import {
  newErrorCSS,
  newErrorLinkCSS,
  errorCSS,
  StyledLine,
  StyledH4,
  SectionContainer,
  StyledDescription,
  TypeaheadContainer
} from './Styles';
import { add, isBefore, isAfter, format, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { ICoverageSelection } from '../interfaces';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import usePolicy from 'api/v2/usePolicy';
import { PropertyUnitInviteTypeahead } from '../CreateInvitationTypeahead/PropertyUnitInviteTypeahead';
import { DatesContainer, StartDateInputContainer, EndDateInputContainer } from './Styles';
import CoverageOptionsRadioButtons from '../CoverageOptionsRadioButtons';
import useCoverageOptions from 'api/v2/useCoverageOptions';
import { ICoverageWrapperProps } from './useCreateInvitationHook';
import { useCoverageState, CoverageState } from 'api/v2/useCoverageOptions';
import { PROPERTY_DEFAULT } from '../utils/constants';
import createRenewalRequest from 'api/v2/createRenewalRequest';
import { isMobileDevice } from 'utils/mobile';
import { useNavigate } from 'react-router-dom';
import { useSegmentUser } from '../utils/segmentTracker';
import { v4 as uuid } from 'uuid';

export interface IRenewalFormProps {
  onPolicyChange: () => void;
  policy?: IPolicy;
  invite_id: string;
  setPolicy: any;
  referredPolicyId?: number;
}

export interface IRenewalRequest {
  insurance_policy_id?: number;
  lease_start_date?: Date;
  lease_end_date?: Date;
  coverage_type?: any;
  coverage_amount_cents?: any;
  monthly_rent_cents?: number;
  unit_id?: number;
  coverage?: any;
  coverage_months?: any;
}
export interface IErrors {
  insurance_policy_id?: [string];
  lease_start_date?: [string];
  lease_end_date?: [string];
  coverage_type?: [string];
  coverage_amount_cents?: [string];
  monthly_rent_cents?: [string];
  unit_id?: [string];
}

let coverageInfo: CoverageState;
export const CoverageOptionsRadioButtonsWrapper = (props: ICoverageWrapperProps) => {
  coverageInfo = useCoverageOptions({
    propertyId: props.propertyId,
    unitId: props.unitId,
    monthlyRentCents: props?.monthlyRentCents ? props.monthlyRentCents : undefined,
    initialCoverageState: props.coverageSelectionState
  });
  return <CoverageOptionsRadioButtons coverageInfo={coverageInfo} disabled={false} errors={props.errors} />;
};

const createErrorMsg = (policy) => {
  const end_date = new Date(policy?.coverage_end_date);
  const begin_range = add(new Date(), { days: 45 });
  const end_range = add(new Date(), { days: 91 });
  const tooEarly = isBefore(end_date, begin_range);
  const tooLate = isAfter(end_date, end_range);

  if (tooEarly || tooLate) {
    return (
      <span style={{ display: 'flex' }}>
        <Alert2Icon color={PALETTE.alert125} style={{ marginTop: '6px' }} height="13px" width="13px" />
        <p className="infoError" css={newErrorCSS}>
          You can only send a Policy Renewal Request between 90 and 46 days of policy expiration.
        </p>
      </span>
    );
  } else if (
    policy?.renewal?.renew_blocking_reasons?.includes(0) ||
    policy?.renewal?.renew_blocking_reasons?.includes(1)
  ) {
    return (
      <span style={{ display: 'flex' }}>
        <Alert2Icon color={PALETTE.alert125} style={{ marginTop: '6px' }} height="13px" width="13px" />
        <p className="infoError" css={newErrorCSS}>
          Cannot send Policy Renewal to this tenant due to
          <a
            css={newErrorLinkCSS}
            target="_blank"
            href={``}
            // TO DO: Currently a placeholder, In a future ticket, implement a route for this error state.
            // onClick={() =>
            //   ()
            // }
          >
            delinquent
          </a>
          policy and/or claims payments.
        </p>
      </span>
    );
  } else if (policy?.has_renewal === true) {
    return (
      <span style={{ display: 'flex' }}>
        <Alert2Icon color={PALETTE.alert125} style={{ marginTop: '6px' }} height="13px" width="13px" />
        <p className="infoError" css={newErrorCSS}>
          A policy renewal request has already been sent. You can make changes to the
          <a
            css={newErrorLinkCSS}
            target="_blank"
            href={``}
            // TO DO: Currently a placeholder, In a future ticket, implement a route for this error state.
            // onClick={() =>
            //   ()
            // }
          >
            original invitation
          </a>
          .
        </p>
      </span>
    );
  } else if (policy?.next_policy_id !== null) {
    return (
      <span style={{ display: 'flex' }}>
        <Alert2Icon color={PALETTE.alert125} style={{ marginTop: '6px' }} height="13px" width="13px" />
        <p className="infoError" css={newErrorCSS}>
          The policy associated with this renter has already been
          <a
            css={newErrorLinkCSS}
            target="_blank"
            href={``}
            // TO DO: Currently a placeholder, In a future ticket, implement a route for this error state.
            // onClick={() =>
            //   ()
            // }
          >
            renewed
          </a>
          .
        </p>
      </span>
    );
  } else if (
    policy?.renewal?.non_renewal_reason === 'I’m not moving out, but I’d prefer to pay a cash deposit' ||
    policy?.renewal?.non_renewal_reason === 'I’m not moving out, but Rhino’s too expensive' ||
    policy?.renewal?.non_renewal_reason === 'I’m not moving out, but I had a bad experience with Rhino'
  ) {
    return (
      <span style={{ display: 'flex' }}>
        <Alert2Icon color={PALETTE.alert125} style={{ marginTop: '6px' }} height="13px" width="13px" />
        <p className="infoError" css={newErrorCSS}>
          This renter indicated they will not renew their
          <a
            css={newErrorLinkCSS}
            target="_blank"
            href={``}
            // TO DO: Currently a placeholder, In a future ticket, implement a route for this error state.
            // onClick={() =>
            //   ()
            // }
          >
            policy
          </a>
          .
        </p>
      </span>
    );
  } else {
    return false;
  }
};

export const PolicyRenewalForm = ({
  policy,
  onPolicyChange,
  setPolicy,
  invite_id,
  referredPolicyId
}: IRenewalFormProps) => {
  const navigate = useNavigate();
  const { data } = usePolicy(Number(policy?.id));
  const [renewalRequest, updateRenewalRequest] = useState<IRenewalRequest | undefined>();
  const [isPolicyValid, setIsPolicyValid] = useState(false);
  const [errors, setErrors] = useState<IErrors | undefined>();
  const today = new Date();
  const sixMonthsFromToday = add(today, { months: 6 });
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(sixMonthsFromToday);
  const initialCoverageSelection: ICoverageSelection = {
    type: PROPERTY_DEFAULT
  };
  const coverageSelectionState = useCoverageState(initialCoverageSelection);
  const { addToast } = useToast();
  const IS_MOBILE = isMobileDevice();
  const [isFormValid, setIsFormValid] = useState(false);
  const requiredFields = ['insurance_policy_id', 'monthly_rent_cents', 'unit_id'];
  const segmentUser = useSegmentUser();
  const [renewalRequestSessionId, setRenewalRequestSessionId] = useState(uuid());

  const monthDiff = (d1: Date, d2: Date) => {
    let diff = (d2.getTime() - d1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff)) - 1;
  };

  useEffect(() => {
    if (renewalRequest) {
      const hasRequiredFields = requiredFields.every(
        (item) => renewalRequest.hasOwnProperty(item) && renewalRequest[item] !== ''
      );
      hasRequiredFields && Number(renewalRequest.monthly_rent_cents) > 10000
        ? setIsFormValid(true)
        : setIsFormValid(false);
    }
  }, [renewalRequest]);

  const handleLeaseStartDateChange = (date: Date) => {
    setStartDate(date);
    if (policy) {
      const coverageEndDateString = policy?.coverage_end_date;
      const coverageEndDate = new Date(coverageEndDateString);
      if (date <= coverageEndDate) {
        setErrors({
          ...errors,
          lease_start_date: ['must be after coverage end date']
        });
      } else if (date < startOfMonth(new Date())) {
        setErrors({
          ...errors,
          lease_start_date: ['is earlier than the first of the current month']
        });
      } else {
        setErrors({ ...errors, lease_start_date: undefined });
      }
      updateRenewalRequest({
        ...renewalRequest,
        lease_start_date: date
      });
    }
  };

  const handleLeaseEndDateChange = (date: Date) => {
    setEndDate(date);
    const start = new Date(startDate.toISOString());
    const end = new Date(date.toISOString());
    const diff = monthDiff(start, end);

    if (end < start) {
      setErrors({ ...errors, lease_end_date: ['must be on or after the lease start date'] });
    } else if (diff > 28) {
      setErrors({ ...errors, lease_end_date: ['cannot be more than 28 months later than the start date'] });
    } else {
      setErrors({ ...errors, lease_end_date: undefined });
    }
    updateRenewalRequest({
      ...renewalRequest,
      lease_end_date: date
    });
  };

  useEffect(() => {
    if (policy) {
      setIsPolicyValid(false);

      const oldPolicyCoverageEndDate = new Date(policy.coverage_end_date);
      const newLeaseStartDate = add(oldPolicyCoverageEndDate, { days: 1 });
      const sixMonthsFromLeaseStartDate = add(newLeaseStartDate, { months: 12 });

      setStartDate(newLeaseStartDate);
      setEndDate(sixMonthsFromLeaseStartDate);

      if (createErrorMsg(policy) === false) {
        setIsPolicyValid(true);
        updateRenewalRequest({
          ...renewalRequest,
          insurance_policy_id: policy.id
        });
      }
    }
  }, [policy]);

  const onUnitChange = (unitId) => {
    updateRenewalRequest({
      ...renewalRequest,
      unit_id: unitId
    });
  };

  const handleMonthlyRentChange = (value) => {
    setErrors({
      ...errors,
      monthly_rent_cents: value <= 100 ? ['must be greater than $100'] : undefined
    });
    const monthlyRentInputCents = value * 100;
    updateRenewalRequest({ ...renewalRequest, monthly_rent_cents: monthlyRentInputCents });
  };

  const submitForm = async () => {
    try {
      const response = await createRenewalRequest({
        ...renewalRequest,
        lease_start_date: startDate ? startDate : today,
        lease_end_date: endDate ? endDate : sixMonthsFromToday,
        coverage: coverageInfo.selected
      });
      if (response.status === 200) {
        window.analytics.track('Renewal Request Sent', {
          ...segmentUser,
          invite_session_id: renewalRequestSessionId,
          invitation_id: response.data.id
        });
        addToast('Renewal Request sent', 'info', TOAST_STATUS.SUCCESS);
        if (IS_MOBILE) {
          window.document.querySelector('form')?.reset();
          addToast('Renewal Request sent', 'info', TOAST_STATUS.SUCCESS);
          return;
        }

        if (referredPolicyId === undefined) navigate('/admin/invitations/policy_renewal_success');
        else navigate(-1);
      }
    } catch (_e) {
      const e = _e;

      window.analytics.track('Renewal Request Failed', {
        ...segmentUser,
        invite_session_id: renewalRequestSessionId,
        errors: e.response?.data
      });
      if (e.response.status === 500) {
        addToast('Internal error — Try again', 'failed', TOAST_STATUS.ERROR);
      }
      setErrors(e.response?.data);
    }
  };

  const getTenantInfoString = (policy) => {
    return `${policy.tenant_full_name} • ${policy.property_address}`;
  };

  return (
    <div>
      <StyledDescription>
        Select a renter with an active policy and send a request to renew their security deposit insurance policy.
        Please review all policy details before sending.
      </StyledDescription>
      <StyledH4>Tenant</StyledH4>
      <StyledLine />
      <TypeaheadContainer>
        {referredPolicyId === undefined && (
          <PolicyInviteTypeahead
            data-cy="apartmentAddress"
            onSelect={onPolicyChange}
            id="policyTypeahead"
            setPolicy={setPolicy}
            invite_id={invite_id}
            error={policy && createErrorMsg(policy)}
          />
        )}
        {policy && createErrorMsg(policy)}
      </TypeaheadContainer>
      {referredPolicyId && policy && (
        <TextInput name="name" label="Name" id="name" value={getTenantInfoString(data)} disabled />
      )}
      {policy && isPolicyValid && (
        <div>
          <SectionContainer>
            <TextInput name="email address" label="Email" id="email" value={data?.tenant_email} disabled />
            <PhoneInput
              name="phone number"
              label="Phone number"
              id="phone number"
              type="tel"
              value={data?.tenant_phone_number}
              disabled
            />
          </SectionContainer>
          <SectionContainer>
            <StyledH4>Property</StyledH4>
            <StyledLine />
            <TextInput
              name="lease address"
              label="Lease address"
              id="lease address"
              value={data?.property_address}
              disabled
            />
            <PropertyUnitInviteTypeahead
              data-cy="apartmentLeaseUnit"
              propertyId={data?.property_id}
              onSelect={onUnitChange}
            />
            <DatesContainer>
              <StartDateInputContainer>
                <DateInput
                  id="startDate"
                  inputLabel="Lease start"
                  selected={startDate}
                  onChange={{
                    onChange: (newDate: Date) => handleLeaseStartDateChange(newDate),
                    onChangeRaw: (e) => console.log(e)
                  }}
                  value={format(startDate, 'MMM d, YYY')}
                  data-cy="leaseStateDate"
                  customInput={
                    <TextInput
                      id="startDate"
                      label="Lease start"
                      rightIcon={<CalendarIcon />}
                      subtext={
                        errors?.lease_start_date && (
                          <p className="infoError" css={errorCSS}>
                            Start date {errors?.lease_start_date.join()}
                          </p>
                        )
                      }
                    />
                  }
                />
              </StartDateInputContainer>
              <EndDateInputContainer>
                <DateInput
                  id="endDate"
                  inputLabel="Lease end"
                  selected={endDate}
                  onChange={{
                    onChange: (newDate: Date) => handleLeaseEndDateChange(newDate),
                    onChangeRaw: (e) => console.log(e)
                  }}
                  onChangeRaw={(e) => {}}
                  value={format(endDate, 'MMM d, YYY')}
                  data-cy="leaseEndDate"
                  customInput={
                    <TextInput
                      id="endDate"
                      label="Lease end"
                      rightIcon={<CalendarIcon />}
                      subtext={
                        errors?.lease_end_date && (
                          <p className="infoError" css={errorCSS}>
                            End date {errors?.lease_end_date.join()}
                          </p>
                        )
                      }
                    />
                  }
                />
              </EndDateInputContainer>
            </DatesContainer>
            <TextInput
              id="monthlyRent"
              label="Monthly rent"
              data-cy="apartmentMonthlyRent"
              leftIcon="$"
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(event) => handleMonthlyRentChange(event.target.value)}
              subtext={
                errors?.monthly_rent_cents && (
                  <p className="infoError" css={errorCSS}>
                    Monthly rent {errors?.monthly_rent_cents.join()}
                  </p>
                )
              }
            />
          </SectionContainer>
          <SectionContainer>
            <StyledH4>Coverage</StyledH4>
            <StyledLine />
            {data && (
              <CoverageOptionsRadioButtonsWrapper
                coverageSelectionState={coverageSelectionState}
                propertyId={data?.property_id}
                unitId={renewalRequest?.unit_id}
                monthlyRentCents={renewalRequest?.monthly_rent_cents}
                errors={errors}
              />
            )}
          </SectionContainer>
          <Button
            data-cy="sendRenewalRequest"
            variant="primary"
            onClick={submitForm}
            disabled={!isFormValid}
            css={{
              '&:disabled': {
                border: '1px solid'
              },
              float: 'right'
            }}
          >
            Send invitation
          </Button>
        </div>
      )}
    </div>
  );
};
export default PolicyRenewalForm;
