import axios, { AxiosResponse } from 'axios';
import { csrfToken } from 'utils/document';

const validatePartnerIntegration = async (uuid?: string) => {
  const token = csrfToken();
  const response: AxiosResponse = await axios.patch(`/v2/integrations/partners/${uuid}/validate`, {
    authenticity_token: token
  });
  return response;
};

export default validatePartnerIntegration;
