import { IPMAPropertyEntityDocuments } from 'components/v2/App/interfaces';
import axios from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';

const getPropertyEntityDocuments = async (id: number) => {
  const { data } = await axios.get<IPMAPropertyEntityDocuments>(`/v2/admin/policy_holders/${id}/policy_documents`);
  return data;
};

export default function usePropertyEntityDocuments(id: number): QueryObserverResult<IPMAPropertyEntityDocuments> {
  return useQuery<IPMAPropertyEntityDocuments>(['property entity documents', id], () => getPropertyEntityDocuments(id));
}
