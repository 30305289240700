import React from 'react';

interface IProps {
  heading: string;
  bodyText: string;
  className?: string;
  id?: string;
  onClick: () => void;
}

const CardSelection: React.FC<IProps> = (props: IProps) => {
  return (
    <div style={styles.container} className="card-selection" id={props.id} onClick={props.onClick} role="button">
      <p className="p1 demi purple" style={styles.heading}>
        {props.heading}
      </p>
      <p className="p3 light">{props.bodyText}</p>
    </div>
  );
};

const styles = {
  container: {
    borderRadius: '8px',
    padding: '32px 42px',
    width: '295px',
    height: '208px',
    cursor: 'pointer',
    margin: '0 19px'
  },
  heading: {
    marginBottom: '16px'
  }
};

export default CardSelection;
