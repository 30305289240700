/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PALETTE } from '@sayrhino/rhino-shared-js';

const DashboardPanelWell = ({ children }) => (
  <li
    css={{
      border: `1px solid ${PALETTE.neutralDark}`,
      borderRadius: '24px',
      marginBottom: '1.75rem',
      padding: '2rem 3rem'
    }}
  >
    {children}
  </li>
);

export default DashboardPanelWell;
