import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Form from './form';
import { useSegmentTrackOnLoad } from '../utils/segmentTracker';

const queryClient = new QueryClient();

function SignUp() {
  useSegmentTrackOnLoad('Create Account Page Viewed');
  return (
    <QueryClientProvider client={queryClient}>
      <Form />
    </QueryClientProvider>
  );
}

export default SignUp;
