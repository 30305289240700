import React, { useState } from 'react';
import styled from '@emotion/styled';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import { Button, DropdownMenu, FONTS } from '@sayrhino/rhino-shared-js';
import useDeletePercentageFile from './useDeletePercentageFile';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

const SubheadingText = styled.p({
  marginTop: '15px',
  fontSize: '16px',
  fontWeight: 'bold'
});
const InnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px'
});
const StyledDropdownMenuTrigger = styled(DropdownMenu.Trigger)({
  width: '50%',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between'
});
const StyledDropdownMenuItem = styled(DropdownMenu.Item)({ minWidth: '534px' });
const Heading = styled.h3([FONTS.h3]);

export interface IInsuranceCarrierPercentages {
  insurance_carrier_percentages: string[];
}

const DeleteCsv: React.FC<IInsuranceCarrierPercentages> = (props) => {
  const [fileToDelete, setFileToDelete] = useState<string | undefined>();
  const [filenames, setFileNames] = useState<Array<string> | undefined>(props.insurance_carrier_percentages);
  const { addToast } = useToast();
  const { mutate } = useMutation(useDeletePercentageFile);

  const handleSubmit = () => {
    mutate(fileToDelete, {
      onSuccess: (res: AxiosResponse) => {
        addToast(res?.data?.message, 'info', TOAST_STATUS.SUCCESS);
        setFileToDelete(undefined);
        filenames && setFileNames(filenames.filter((e) => e !== fileToDelete));
      },
      onError: (error: AxiosError) => {
        const message = error?.response?.data.error.message;
        addToast(message, 'error', TOAST_STATUS.ERROR);
      }
    });
  };

  return (
    <div>
      <Heading>Delete CSV</Heading>
      <SubheadingText>
        Please only use this feature to delete a recent CSV Percentage file that was uploaded in error or if a new CSV
        needs to be uploaded before the previous file goes into effect.
      </SubheadingText>
      <InnerContainer>
        <DropdownMenu.Root>
          <StyledDropdownMenuTrigger aria-label="select csv file name" value={fileToDelete}>
            <span>{fileToDelete ? fileToDelete : 'Select File to Delete'}</span>
          </StyledDropdownMenuTrigger>
          <DropdownMenu.Content>
            {filenames?.map((filename) => (
              <StyledDropdownMenuItem aria-label={filename} onSelect={() => setFileToDelete(filename)} key={filename}>
                {filename}
              </StyledDropdownMenuItem>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        <Button id="delete-percentage-csv" variant="primary" onClick={handleSubmit} disabled={!fileToDelete}>
          Delete File
        </Button>
      </InnerContainer>
    </div>
  );
};

export default DeleteCsv;
