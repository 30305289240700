/** @jsx jsx */
import { jsx } from '@emotion/core';

import React from 'react';
import { StyledLine, fieldMargin } from './Styles';

import { FONTS } from '@sayrhino/rhino-shared-js';

export const CoverageOptions = ({
  sectionHeader,
  invitation,
  CoverageOptionsRadioButtonsWrapper,
  coverageSelectionState,
  errors
}) => {
  return (
    <div>
      <h4 css={[FONTS.h5, { marginBottom: 12 }, fieldMargin]}>{sectionHeader}</h4>
      {invitation?.property_id ? (
        <div>
          <CoverageOptionsRadioButtonsWrapper
            coverageSelectionState={coverageSelectionState}
            propertyId={invitation.property_id}
            unitId={invitation.unit_id}
            monthlyRentCents={invitation.monthly_rent}
            errors={errors}
          />
        </div>
      ) : (
        <div css={{ marginBottom: 48 }}>
          <p>You will be able to set the coverage amount once you have selected a property.</p>
        </div>
      )}
    </div>
  );
};
