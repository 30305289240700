import React from 'react';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
import ClaimChangelog from '../Claims/ClaimDetails/ClaimChangelog';
import { render, screen } from '@testing-library/react';
import { UserContext } from '../utils/userRole';

let server;

const renderAsRole = (role) =>
  render(
    <UserContext.Provider value={{ id: 1, role: role, sessionId: '', isAutoApprove: false }}>
      <Wrapper>
        <ClaimChangelog claimId={11021} />
      </Wrapper>
    </UserContext.Provider>
  );

describe('<ClaimChangelog /> as an admin', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('shows table column names', async () => {
    renderAsRole('Administrator');

    const columnDate = await screen.findByRole('columnheader', { name: 'Date' });
    expect(columnDate).toBeDefined();

    const columnBy = await screen.findByRole('columnheader', { name: 'By' });
    expect(columnBy).toBeDefined();

    const columnStatus = await screen.findByRole('columnheader', { name: 'Status' });
    expect(columnStatus).toBeDefined();
  });

  // skipping this test until tables are updated to specify aria-labels
  test.skip('shows table contents', async () => {
    renderAsRole('Administrator');

    const columnDate = await screen.findByRole('cell', { name: 'Date' });
    expect(columnDate).toBeDefined();

    const columnBy = await screen.findByRole('cell', { name: 'By' });
    expect(columnBy).toBeDefined();

    const columnStatus = await screen.findByRole('cell', { name: 'Status' });
    expect(columnStatus).toBeDefined();
  });
});
