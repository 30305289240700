import React from 'react';
import { SearchResultType, ISearchResultCountsByType } from 'components/v2/App/interfaces';
import { getHumanizedSearchResultType } from './Search';
import SearchResultsTypeFilterItem from './SearchResultsTypeFilterItem';
import { useUserContext, IUserContext } from './utils/userRole';

interface IProps {
  value: SearchResultType | null;
  counts: ISearchResultCountsByType;
  onFilterResultsByType: (ISearchResultType) => void;
}

const ALL_FILTER_TYPES = ['InsurancePolicy', 'Claim', 'Invitation', 'Property'];
const LISTING_AGENT_FILTER_TYPES = ['InsurancePolicy', 'Invitation'];
const THIRD_PARTY_ADMIN_FILTER_TYPES = ['InsurancePolicy', 'Claim', 'Property'];
const RESTRICTED_TYPES = {
  ListingAgent: LISTING_AGENT_FILTER_TYPES,
  ThirdPartyAdministrator: THIRD_PARTY_ADMIN_FILTER_TYPES
};

const SearchResultsTypeFilter: React.FC<IProps> = ({ value, counts, onFilterResultsByType }) => {
  const userContext: IUserContext = useUserContext();
  const types = RESTRICTED_TYPES[userContext.role] || ALL_FILTER_TYPES;
  return (
    <div>
      {types.map((type) => (
        <SearchResultsTypeFilterItem
          key={type}
          label={getHumanizedSearchResultType(type)}
          value={counts[type]}
          active={value === type}
          onClick={() => onFilterResultsByType(type)}
        />
      ))}
    </div>
  );
};

export default SearchResultsTypeFilter;
