import React from 'react';
import styled from '@emotion/styled';
import { FONTS, PALETTE, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import CheckSVG from '../../../../../../assets/images/complete-check.svg';
import { initializeDate, stringifyDate } from '../../utils';

const ProgressCard = styled.div({
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  marginTop: '8px',
  marginBottom: '16px',
  // desktop
  '@media (max-width: 992px)': {
    maxWidth: 'none',
    width: '100%'
  }
});

const Header = styled.h6([FONTS.h6Demi, { margin: '20px 16px 0px 16px' }]);
const Text = styled.p([FONTS.p3Medium, { margin: '0px 16px 4px 16px', color: PALETTE.neutral55 }]);

const ProgressBar = styled.div({
  width: '65%',
  height: '16px',
  backgroundColor: '#eee',
  borderRadius: '10px',
  margin: 'auto',
  textAlign: 'center',
  position: 'relative'
});

const Progress = styled.div({
  height: '100%',
  borderRadius: '10px',
});

const StyleHeader = styled.div({
  display: 'flex',
  width: '95%',
  justifyContent: 'center',
  margin: 'auto',
  padding: '10px 15px',
  flexDirection: 'column',
  alignItems: 'center'
});

interface Props {
  withdrawal?: any;
}

export const WithdrawalProgress = ({ withdrawal }: Props) => {
  const { state, amount_in_cents, created_timestamp } = withdrawal;

  const cardsContext = {
    cancelled: {
      bottomRichText: 'Your withdrawal request was canceled',
      bottomRichTextSmall: '',
      progress: '4%',
      progressBgColor: PALETTE.neutral12
    },
    pending: {
      bottomRichText: `We received your withdrawal request on ${stringifyDate(initializeDate(created_timestamp))}`,
      bottomRichTextSmall: 'Please allow 5-10 business days for the transfer to be processed',
      progress: '33%',
      progressBgColor: PALETTE.raptor
    },
    user_info_needed: {
      bottomRichText: 'Your transfer requires input',
      bottomRichTextSmall: 'We encountered an issue sending your deposit funds.',
      progress: '66%',
      cancel_transfer_button: false,
      progressBgColor: '#A72131'
    },
    payment_pending: {
      bottomRichText: `We received your withdrawal request on ${stringifyDate(initializeDate(created_timestamp))}`,
      bottomRichTextSmall: 'Please allow 5-10 business days for the transfer to be processed',
      progress: '100%',
      progressBgColor: PALETTE.raptor
    },
    payment_complete: {
      bottomRichText: 'Your withdrawal is complete',
      bottomRichTextSmall: `Your withdrawal of $${amount_in_cents} was successfully transferred`,
      progress: '100%',
      progressBgColor: PALETTE.raptor
    }
  }
  return (
    <>
      <ProgressCard>
        <ProgressBar>
          <Progress style={{ width: cardsContext[state].progress, backgroundColor: cardsContext[state].progressBgColor }} />

          {
            state === 'payment_complete' &&
            <img src={CheckSVG} style={{ position: 'absolute', top: '-6px', right: '-10px' }} />
          }

        </ProgressBar>
        <StyleHeader>
          <Header css={[FONTS.h6Medium, FONT_FAMILY.extendedBold]} style={state === 'cancelled' ? { color: PALETTE.neutral55 } : {}}>
            {cardsContext[state].bottomRichText}
          </Header>
          <Text>
            {cardsContext[state].bottomRichTextSmall}
          </Text>
        </StyleHeader>
      </ProgressCard>
    </>
  );
};