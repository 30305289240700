/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Checkbox, FONTS } from '@sayrhino/rhino-shared-js';
import { QueryObserverResult } from 'react-query';
import { IFilters, IClaimStaticFilterOptions } from '../interfaces';
import { StyledTitleDiv, StyledOptionsDiv, StyledH5, StyledWrapperDiv } from './ClaimFilterStyles';
import { StyledCheckbox, clearButtonCSS, newSelections } from './index';


const MarketFilterOptions: React.FC<{
  css?: any;
  query: () => QueryObserverResult<IClaimStaticFilterOptions>;
  selectedMarkets: string[] | undefined;
  setFilters: (args: Partial<IFilters>) => void;
}> = (props) => {
  const { query, selectedMarkets = [], setFilters, ...rest } = props;
  const { data } = query();

  return data?.markets ? (
    <div {...rest}>
      <StyledTitleDiv>
        <StyledH5>Insurance Market</StyledH5>
        <button
          type="button"
          css={clearButtonCSS({ canClear: Boolean(selectedMarkets.length) })}
          onClick={() => setFilters({ markets: [] })}
        >
          Clear
        </button>
      </StyledTitleDiv>
      <StyledWrapperDiv>
        <StyledOptionsDiv>
          {data.markets.map((option, index) => {
            return (
              <StyledCheckbox key={`${option}-${index}`}>
                <Checkbox
                  checked={selectedMarkets.includes(option.value)}
                  id={`checkbox-market-filter-${index}`}
                  key={`checkbox-market-filter-${index}`}
                  name={option.value}
                  onCheckedChange={() => setFilters({ markets: newSelections(option.value, selectedMarkets) })}
                  label={option.label_text}
                />
              </StyledCheckbox>
            );
          })}
        </StyledOptionsDiv>
      </StyledWrapperDiv>
    </div>
  ) : null;
};

export default MarketFilterOptions;
