import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  FONTS,
  PALETTE,
  InlineNotification as Notification,
  TextInput,
  TimeIcon,
  ButtonHabitat,
  CloseButton,
  ButtonLink,
  FONT_FAMILY
} from '@sayrhino/rhino-shared-js';
import { AttachmentListProps } from '../interfaces';

const containerPaddingX = '32px';

export const Title = styled.h3([FONTS.h3, { padding: `32px ${containerPaddingX}` }]);

export const TitleWrapper = styled.h3([
  FONTS.h3,
  {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `16px ${containerPaddingX}`
  }
]);

export const Subtitle = styled.h4([
  FONTS.h5,
  {
    paddingBottom: 4,
    marginTop: 16,
    borderBottom: `1px solid ${PALETTE.neutral12}`
  }
]);

export const PropertyOwnerFormSubtitle = styled.h4([
  FONTS.h5,
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 8,
    marginTop: 40,
    borderBottom: `1px solid ${PALETTE.neutral12}`,
    alignItems: 'flex-end'
  }
]);

export const PropertyOwnerFormLabel = styled.label([
  FONTS.h5,
  {
    lineHeight: 'normal',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 8,
    marginTop: 32,
    borderBottom: `1px solid ${PALETTE.neutral12}`,
    alignItems: 'flex-end'
  }
]);

export const Banner = styled.div({ padding: `0 ${containerPaddingX} 24px`, width: 'auto' });

export const ButtonGroup = styled.div({
  position: 'sticky',
  bottom: 32,
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 32,
  marginRight: 32,
  marginTop: 32
});

export const CoverageLabel = styled.label([FONTS.p1Light]);
export const CoverageValue = styled.p([
  FONTS.p2Medium,
  {
    maringBlock: 4,
    color: PALETTE.neutral75,
    ':first-letter': {
      textTransform: 'uppercase'
    }
  }
]);

export const RenterLink = styled.p([FONTS.p2Light]);

export const IndentedCoverageValue = styled(CoverageValue)({ 'text-indent': '50px' });

export const EditButton = styled(Link)({
  color: PALETTE.neutralLight,
  backgroundColor: PALETTE.neutralDark,
  border: 'none',
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 16px',
  borderRadius: 50
});

export const SaveButton = styled(ButtonHabitat)({
  '&&': { padding: `12px ${containerPaddingX}` }
});

export const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '32px'
});

export const DisplayField = styled.div({
  padding: '12px 0',
  borderBottom: `1px solid ${PALETTE.neutral12}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'default',
  div: [FONTS.p1Medium],
  label: [
    FONTS.p3Medium,
    {
      color: PALETTE.neutral55,
      textAlign: 'right'
    }
  ]
});

export const DisplayFieldControls = styled.span({
  display: 'flex',
  alignItems: 'center',
  gap: '16px'
});

export const InlineNotification = styled(Notification)({ marginBottom: 16 });
export const NotificationMessage = styled.span({ color: PALETTE.alert100 });
export const NotificationIcon = styled(TimeIcon)({ marginLeft: '8px' });
export const NotificationWrapper = styled.div({
  paddingTop: 32,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
});

export const StyledContent = styled.p([FONTS.p2Medium, { marginBlock: 4, color: PALETTE.neutral55, textWrap: "pretty" }]);
export const SpacingAbove = styled.div({ paddingTop: 16 });
export const StyledCloseButton = styled(CloseButton)({
  float: 'right'
});
export const HeaderWrapper = styled.div({ padding: '16px 32px 0px 32px', alignItems: 'center' });
export const Splitter = styled.hr({ marginTop: 14, marginBottom: 14 });
export const HeaderText = styled.h4([
  FONTS.h4,
  {
    color: PALETTE.neutral55,
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '28px'
  }
]);
export const StyledEmptySearchP = styled.p([FONTS.h3]);

export const FlexButtonContainer = styled.div({ display: 'flex', gap: '10px' });
export const IntegrationButton = styled(ButtonHabitat)();
export const IntegrationButtonLink = styled(ButtonLink)({ marginTop: 16 });

type styledProps = { isActive: boolean };
export const StyledStatus = styled.p<styledProps>(({ isActive }) => [
  FONTS.p3Medium,
  {
    color: isActive ? PALETTE.interaction100 : PALETTE.neutral55,
    paddingRight: 16
  }
]);
export const StyledStatusWrapper = styled.div({ display: 'flex', alignItems: 'center' });

export const StyledValidationErrorBanner = styled.div({
  padding: '12px 24px',
  marginTop: '40px',
  borderRadius: '8px',
  color: PALETTE.alert4,
  background: PALETTE.alert125,
  p: [
    FONTS.p2,
    {
      color: PALETTE.alert4
    }
  ]
});

export const StyledInlineErrorHeader = styled.p([
  FONTS.p2,
  {
    color: PALETTE.neutralLight,
    fontWeight: 'bold'
  }
]);

export const StyledInlineErrorText = styled.p([
  FONTS.p2,
  {
    color: PALETTE.neutralLight
  }
]);
export const StyledInlineProgressText = styled.p([
  FONTS.p2,
  {
    color: PALETTE.neutral55,
    marginRight: '72px'
  }
]);
export const StyledInlineProcessingNotification = styled.div({
  display: 'flex',
  padding: '12px 24px',
  width: 'inherit',
  height: '48px',
  background: PALETTE.neutral4,
  borderRadius: '8px',
  margin: '16px 0px 40px 0px'
});
export const StyledLittleInProgress = styled.p([
  FONTS.p3,
  {
    alignSelf: 'center',
    color: PALETTE.neutral55,
    fontSize: '10px',
    marginLeft: '12px'
  }
]);
export const Platform = styled.h4([FONTS.h4]);

export const TopActionContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  padding: `8px ${containerPaddingX}`
});

export const BoldText = styled.span([FONTS.p2Medium]);

export const AttachmentLabel = styled.label([
  FONTS.h6Medium,
  {
    textDecorationLine: 'underline',
    fontSize: '14px !important',
    color: `${PALETTE.interaction100} !important`,
    '&:hover': {
      cursor: 'pointer'
    }
  }
]);

export const AttachmentList = styled.div<AttachmentListProps>(({ isModal }) =>
  isModal ? { overflow: 'auto', maxHeight: '60vh' } : { marginBottom: 20 }
);

export const FieldError = styled.p({
  color: 'red',
  fontWeight: 'bold'
});

export const FormLabel = styled.p([
  FONT_FAMILY.medium,
  {
    margin: '16px 0px 2px 0px',
    fontSize: '12px',
    fontWeight: 500,
    color: PALETTE.neutral65
  }
]);

export const NumericTextInput = styled(TextInput)({
  flex: 1,
  '& > div > input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  }
});

export const PhoneInputWrapper = styled.div({
  marginBottom: '16px',
  'input.phone-input, button.phone-input': {
    border: 'none',
    boxShadow: 'inset 0 0 0 1px #bfbfbf',
    height: '100%'
  },
  'input.phone-number': {
    boxSizing: 'border-box',
    flexGrow: 1,
    fontSize: '16px',
    padding: '8px',
    width: '100%'
  },
  'div.phone-input-container': {
    display: 'flex',
    fontFamily: 'MaisonNeueLight',
    fontSize: '16px',
    height: '44px',
    width: '100%',
    borderRadius: '4px',
    '&:hover .phone-input': {
      boxShadow: 'inset 0 0 0 1px #1f1f1f'
    },
    '&:focus-within .phone-input': {
      boxShadow: 'inset 0 0 0 1px #315bf1, 0 0 0 1px #315bf1'
    }
  }
});
