import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { FONT_FAMILY, CheckIcon, PALETTE } from '@sayrhino/rhino-shared-js';
import { PropertyCsvUploadResponse } from 'components/tables/property_upload_table/interfaces';

interface IProps {
  data: PropertyCsvUploadResponse;
  onSubmit?: (nextStep: string, options?: PropertyCsvUploadResponse) => void;
}

const Header = styled.div({
  margin: '90px 0 60px 0',
  textAlign: 'center'
});

const Page = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100% - 150px)'
});

const CircleBackground = styled.div({
  backgroundColor: PALETTE.success100,
  width: '84px',
  height: '84px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%'
});

const UploadComplete: React.FC<IProps> = (props: IProps) => {
  useEffect(() => {
    setTimeout(() => props.onSubmit && props.onSubmit('AssignTeam', props.data), 1000);
  });

  return (
    <Page>
      <Header>
        <h2 css={[FONT_FAMILY.sectraBold, { fontSize: '44px', lineHeight: '56px' }]}>
          Your properties were successfully imported!
        </h2>
      </Header>
      <CircleBackground>
        <CheckIcon height={29.4} width={42} color={PALETTE.neutralLight} />
      </CircleBackground>
    </Page>
  );
};

export default UploadComplete;
