import React, { createRef, Ref } from 'react';
import { PropertyUnitInviteTypeahead } from '../../CreateInvitationTypeahead/PropertyUnitInviteTypeahead';
import makeServer from '../../testUtils/server';
import { render, screen, act, waitFor, fireEvent } from '@testing-library/react';
import Wrapper from '../../testUtils/Wrapper';
import { RefObject } from '../../CreateInvitationTypeahead/PropertyTypeahead';
import userEvent from '@testing-library/user-event';

let server;

beforeEach(() => {
  server = makeServer();
});

afterEach(() => {
  server.shutdown();
});

describe('<PropertyUnitInviteTypeahead />', () => {
  const doRender = (propertyId?: number) => {
    const mockRef: Ref<RefObject> = createRef();
    act(() => {
      render(
        <Wrapper>
          <PropertyUnitInviteTypeahead onSelect={() => {}} ref={mockRef} propertyId={propertyId}/>
        </Wrapper>
      );
    });
  };

  describe('When no property is selected', () => {
    test('It should display no matches', async () => {
      // render with no propertyId
      doRender();
      const unitInput = await screen.findByRole('combobox');
      act(() => userEvent.click(unitInput));
      await waitFor(() => screen.getByText('No matches found'));
      expect(screen.getByText('No matches found')).toBeDefined();
    });
  });

  // the mock server endpoint will return whichever units named 1 to 10
  // match the query (and all 10 if there is no query)
  describe('When a property is selected', () => {
    test('It should display units on click', async () => {
      // render with a propertyId
      doRender(1);
      const unitInput = await screen.findByRole('combobox');
      act(() => userEvent.click(unitInput));

      // ensure options pop up
      await waitFor(() => screen.getByText('8'));
      expect(screen.getAllByRole('listitem').length).toBe(10);

      // select option 8
      const unitOption = screen.getByText('8');
      fireEvent.click(unitOption);
      const unitField = (await screen.findByTitle(/Lease unit container/i));
      // check that the listitems are gone after clicking
      expect(screen.queryAllByRole('listitem').length).toBe(0);
      expect(unitField).toHaveTextContent('8');
    });

    test('It should query for and display matching units when input entered', async () => {
      doRender(1);
      const unitInput = await screen.findByRole('combobox');
      act(() => userEvent.click(unitInput));
      userEvent.type(unitInput, '1');
      // the 1 has extra formatting since it matches the query, so we'll just search for 0 instead
      await waitFor(() => screen.getByText('0', { exact: false }));
      // 1 and 10 should be the only items if our mock endpoint was called correctly
      // and results were displayed correctly
      expect(screen.getAllByRole('listitem').length).toBe(2);

      // select option 10
      const unitOption = screen.getByText('0', { exact: false});
      fireEvent.click(unitOption);
      const unitField = (await screen.findByTitle(/Lease unit container/i));
      // check that the listitems are gone after clicking
      expect(screen.queryAllByRole('listitem').length).toBe(0);
      // unitField doesn't keep the extra <i></i> formatting, so the 10 shouldn't be broken up
      expect(unitField).toHaveTextContent('10');
    });

    test('It should display no matches when input does not result in units', async () => {
      doRender(1);
      const unitInput = await screen.findByRole('combobox');
      act(() => userEvent.click(unitInput));
      userEvent.type(unitInput, 'A');
      // make sure it tries to search and doesn't just result in 'No matches found'
      await waitFor(() => screen.getByText('Searching...'));
      expect(await screen.findByText('Searching...')).toBeDefined();
      await waitFor(() => screen.getByText('No matches found'));
      expect(screen.getByText('No matches found')).toBeDefined();
    });
  });
});
