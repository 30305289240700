import { ClaimStatus } from '../interfaces';
import { IUserRole } from './userRole';

export const generateMaskedStatus = (status: string, userRole: IUserRole) => {
  const { isPartner } = userRole;
  if (isPartner && status === ClaimStatus.NEW) return ClaimStatus.PROCESSING;

  const maskedForPM = [
    'paid',
    'closed_without_payment',
    'subrogation',
    'subrogation_paid_in_full',
    'collections',
    'collections_paid',
    'collections_unpaid',
    'payment_plan',
    'payment_plan_in_progress'
  ];

  if (isPartner) {
    if (maskedForPM.includes(status)) {
      return ClaimStatus.PAID;
    }
    if (status === ClaimStatus.NEW) return ClaimStatus.PROCESSING;
  }

  const statusStrConversion = {
    closed_without_payment: 'Closed - Unpaid',
    subrogation_paid_in_full: 'Subrogation - Paid',
    collections_paid: 'Collections - Paid',
    collections_unpaid: 'Collections - Unpaid',
    payment_plan: 'Payment Plan',
    payment_plan_in_progress: 'Payment Plan - Active'
  };
  const conversionStatues = Object.keys(statusStrConversion);
  if (conversionStatues.includes(status)) {
    return statusStrConversion[status];
  }
  return status;
};

export const claimsFilterCount = (selectedFilters) =>
  Object.keys(selectedFilters)
    .map((section): number => {
      const values = selectedFilters[section];
      if (section === 'statuses') {
        const filteredList = values.filter((status) => status !== 'new');
        return filteredList.length;
      }
      return Number(values.length);
    })
    .reduce((acc, value) => acc + value);
