import ApplicationTable, { IButton } from './application_table';
import { DeleteColumn, EditColumn } from './utils';

class UnitTable extends ApplicationTable {
  public select = {
    style: 'os',
    selector: 'td:first-child',
    multi: true
  };
  public emptyMessageText = 'You have no units to view';
  public selector = '#unit-table';
  public pathPrefix = 'units/';

  public getColumns() {
    const columns: DataTables.ColumnSettings[] = [];

    if (this.canBulkUpdate()) {
      columns.push({ data: 'unit_id', render: () => '' });
    }

    columns.push(
      {data: 'unit_id'},
      {data: 'property'},
      {data: 'name'},
      {data: 'coverage_amount'}
    );

    if (this.can('view-advanced-properties')) {
      columns.push({ data: 'policy_holder' });
    }

    if (this.canEdit()) { columns.push(EditColumn); }
    if (this.canDestroy()) { columns.push(DeleteColumn); }

    return columns;
  }

  public getColumnDefs() {
    const columnDefs: DataTables.ColumnDefsSettings[] = [
      {
        targets: this.canBulkUpdate() ? [2, 3, 4] : [0, 1, 2] ,
        data: undefined,
        defaultContent: ''
      }
    ];

    if (this.canBulkUpdate()) {
      columnDefs.push({
        targets: 0,
        searchable: false,
        orderable: false,
        className: 'select-checkbox',
        data: ''
      });
    }

    return columnDefs;
  }

  public getButtons() {
    const buttons: IButton[] = [];
    if (this.canBulkUpdate()) {
        buttons.push({
        text: 'Bulk Update',
        className: 'button bulk turquoise__link',
        action: this.bulkUpdate.bind(this)
      });
    }

    return buttons;
  }

  public bulkUpdate(): void {
    const row_ids = this.selectedRows().data().pluck('DT_RowId');
    const params = row_ids.map((id) => `unit_ids[]=${id}`).join('&');
    window.location.assign(`/admin/units/bulk_edit?${params}`);
  }
}

export default UnitTable;
