import { csrfToken } from 'utils/document';
import axios from 'axios';
import { Fields } from './form';

type Account = {
  partner: Fields;
};

const createAccount = async (user: Fields) => {
  const authenticity_token = csrfToken();
  const userData: Account = { partner: user };
  const res = await axios.post(`v2/users/accounts`, userData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': authenticity_token
    }
  });
  return res;
};

export default createAccount;
