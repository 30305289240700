import axios from 'axios';
import { csrfToken } from 'utils/document';

export default function createPropertyInvitationAccessRequest(params) {
  const { property_id, user_id } = params;
  const token = csrfToken();
  return axios.post(`/admin/properties/${property_id}/property_access_requests.json`, {
    authenticity_token: token,
    user_id
  });
}
