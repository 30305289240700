import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PALETTE, FONTS } from '@sayrhino/rhino-shared-js';
import { ErrorDisplay } from './select';
import { getUserDetails } from '.';

const Wrapper = styled.div({
  margin: '24px 0'
});

const TextInput = styled.input([
  FONTS.p2Medium,
  {
    border: 'none',
    flex: 9,
    fontSize: 16,
    lineHeight: '24px',
    outline: 'none'
  }
]);

const Text = styled.p([
  FONTS.p2Medium,
  {
    flex: 9,
    fontSize: 16,
    lineHeight: '24px'
  }
]);

const SideButton = styled.button([
  FONTS.p2Medium,
  {
    outline: 'none',
    border: 'none',
    background: 'transparent',
    flex: 1,
    textAlign: 'right',
    fontSize: 14,
    lineHeight: '28px'
  }
]);

const TextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: `.6px solid ${PALETTE.neutral12}`,
  padding: '10px 0'
});

interface Props {
  list?: Array<string>;
  error?: string;
  handleChange: (list: Array<string>) => void;
}

const Units: React.FC<Props> = ({ list = [], handleChange, error }) => {
  const [newValue, setNewValue] = useState<string>('');
  const { user } = getUserDetails();
  const tracker = (value: string) => {
    window.analytics.track(value, user);
  };

  const onAdd = (value: string) => {
    if (value) {
      handleChange([...list, value]);
      setNewValue('');
    }
  };

  const onRemove = (index: number) => {
    let filtered = list?.filter((_, id) => index !== id);
    handleChange(filtered);
  };

  return (
    <Wrapper>
      {list?.map((unit: string, index: number) => (
        <TextWrapper key={index}>
          <Text>{unit}</Text>
          <SideButton onClick={() => onRemove(index)}>Remove</SideButton>
        </TextWrapper>
      ))}
      <TextWrapper>
        <TextInput
          id={`unit-${list?.length + 1}`}
          data-cy={`singlePropertyUnit-${list?.length + 1}`}
          value={newValue ?? ''}
          onChange={(e) => setNewValue(e.target.value)}
        />
        <SideButton
          onClick={() => {
            onAdd(newValue);
            tracker('Add Property Units Clicked');
          }}
        >
          Add
        </SideButton>
      </TextWrapper>
      {error && <ErrorDisplay error={error} />}
    </Wrapper>
  );
};

export default Units;
