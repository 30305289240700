import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { FONTS, FONT_FAMILY, Loading } from '@sayrhino/rhino-shared-js';
import useIntersectionObserver from '../../../hooks/v2/useIntersectionObserver';

interface StyledProps {
  hasData: boolean;
}

const Wrapper = styled.div<StyledProps>(({ hasData }) => [
  FONTS.p1Medium,
  {
    display: hasData ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '18px',
    marginBottom: '1px'
  }
]);

export const PagingList = ({ hasNextPage, fetchNextPage, isFetchingNextPage, hasData }) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const loadMoreDiv = isFetchingNextPage && <Loading />;
  const nothingMoreDiv = hasData && 'Nothing more to load';

  useIntersectionObserver({
    root: null,
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isFetchingNextPage
  });
  return (
    <Wrapper ref={loadMoreRef} hasData={hasData}>
      {hasNextPage ? loadMoreDiv : nothingMoreDiv}
    </Wrapper>
  );
};

export default PagingList;
