import styled from '@emotion/styled';
import { ActionButton, FONTS, Loading, PALETTE } from '@sayrhino/rhino-shared-js';
import useSubrogationPayments from 'api/v2/useSubrogationPayments';
import Decimal from 'decimal.js';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from '../../Filters/Pagination';
import { centsToUSDFormatter, getInitialStr, numberToUSDFormatter } from '../../utils';
import { formatDate } from '../../utils/formatDate';
import { Status } from './ClaimPaymentCard';
import { useUserRole } from '../../utils/userRole';

import {
  StyledTableContainer,
  StyledUserInitialsP,
  StyledTableHeader,
  StyledTableTitle,
  StyledHeaderRow,
  StyledColumnHeader,
  StyledTableFrame,
  StyledRow,
  StyledCell,
  StyledTableFooter,
  StyledAddNewRowLink,
  StyledEditCell
} from './ClaimStyles';
import SubrogationPaymentCard from './SubrogationPaymentCard';

export const ClaimsPaymentTable: React.FC = () => {
  const { claimsId } = useParams();
  const [page, setPage] = useState(1);
  const { data: table_data, isSuccess } = useSubrogationPayments(Number(claimsId), page) ?? {};
  const { data: payment_info, meta } = table_data ?? { data: [] };
  const per = meta?.per ?? 20;
  const totalCount = meta?.total_count ?? 0;
  const userRole = useUserRole();
  const { isThirdPartyAdmin } = userRole;


  const [showCard, setShowCard] = useState<boolean>(false);
  const [editId, setEditId] = React.useState<number>();
  return (
    <>
      <StyledTableContainer>
        <StyledTableHeader>
          <StyledTableTitle>Payment History</StyledTableTitle>
        </StyledTableHeader>
        <StyledTableFrame>
          {isSuccess ? (
            <tbody>
              <StyledHeaderRow>
                <StyledColumnHeader>Date</StyledColumnHeader>
                <StyledColumnHeader>Payment Amount</StyledColumnHeader>
                <StyledColumnHeader>Processing Fees</StyledColumnHeader>
                <StyledColumnHeader>Net Recovery</StyledColumnHeader>
                <StyledColumnHeader>User</StyledColumnHeader>
                <StyledColumnHeader />
              </StyledHeaderRow>

              {payment_info &&
                payment_info.map((payment, index) => {
                  return [
                    <StyledRow>
                      <StyledCell>{formatDate(payment.paid_at)}</StyledCell>
                      <StyledCell>{centsToUSDFormatter(new Decimal(payment.recovered_amount_cents))}</StyledCell>
                      <ValueCell value={payment.processing_fee_cents}>
                        {centsToUSDFormatter(new Decimal(payment.processing_fee_cents))}
                      </ValueCell>
                      <ValueCell value={payment.net_recovery_cents}>
                        {centsToUSDFormatter(new Decimal(payment.net_recovery_cents))}
                      </ValueCell>
                      <StyledCell>
                        <StyledUserInitialsP>{getInitialStr(payment.creator)}</StyledUserInitialsP>
                      </StyledCell>
                      {!isThirdPartyAdmin && (
                        <StyledEditCell>
                          <ActionButton
                            usage="quiet"
                            id={`edit-${index}`}
                            variant="noLabel"
                            icon="edit"
                            size="small"
                            onClick={() => setEditId(payment.id)}
                          />
                        </StyledEditCell>
                      )}
                    </StyledRow>,
                    editId === payment.id && (
                      <StyledRow>
                        <StyledCell colSpan={100}>
                          <SubrogationPaymentCard
                            flipView={() => setEditId(undefined)}
                            status={Status.Edit}
                            payoutInfo={payment}
                          />
                        </StyledCell>
                      </StyledRow>
                    )
                  ];
                })}
            </tbody>
          ) : (
            <div>
              <Loading />
            </div>
          )}
        </StyledTableFrame>
        <StyledTableFooter>
          {!isThirdPartyAdmin && (
            !showCard ? (
              <StyledAddNewRowLink href="#" onClick={() => setShowCard(true)}>
                + Add payment
              </StyledAddNewRowLink>
            ) : (
              <SubrogationPaymentCard flipView={() => setShowCard(false)} status={Status.Add} />
            )
          )}
        </StyledTableFooter>
        <div>
          {isSuccess && (
            <Pagination
              currentPageCount={payment_info?.length ?? 0}
              nextPage={meta?.next_page}
              page={page}
              per={per}
              prevPage={meta?.prev_page}
              setPage={setPage}
              totalCount={totalCount}
              totalPages={Math.ceil(totalCount / per)}
            />
          )}
        </div>
      </StyledTableContainer>
    </>
  );
};

export interface ValueCellProps {
  value: number;
  children: React.ReactNode;
}
export const StyledValueCell = styled.td<{ isValuePositive?: boolean }>(
  [FONTS.p2Light, { minWidth: '60px', paddingBottom: '26px', paddingTop: '24px', paddingRight: '16px' }],
  (isValuePositive) => ({
    color: isValuePositive ?? isValuePositive ? PALETTE.interaction100 : PALETTE.alert100
  })
);
/**
 * A <td> node that renders with red text if the value is negative and blue if positive
 * @param props
 * @returns
 */
export const ValueCell = (props: ValueCellProps) => {
  const { value, children } = props;
  const isPositive = value > 0;
  return <StyledValueCell isValuePositive={isPositive}>{children}</StyledValueCell>;
};
