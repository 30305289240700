/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, { useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import { PALETTE, FONTS, FONT_FAMILY } from '@sayrhino/rhino-shared-js';

import { centsToUSDFormatter } from '../utils';
import { ICoverageOptionSelectProps } from '.';
import { DOLLAR_AMOUNT, PROPERTY_DEFAULT } from '../utils/constants';

export default function PropertyDefaultOption(props: ICoverageOptionSelectProps) {
  const { coverageInfo } = props;
  const {
    selected,
    setSelected,
    result: { data: options }
  } = coverageInfo;

  if (options === undefined || options?.default === undefined) {
    return <></>;
  }
  const { label, type, value } = options.default;

  let coverageString: string;
  if (type === DOLLAR_AMOUNT) {
    coverageString = centsToUSDFormatter(value);
  } else {
    coverageString = label;
  }

  return (
    <div
      css={[
        {
          marginTop: 12,
          marginLeft: 46,
          marginBottom: 18,
          paddingBottom: 20,
          borderBottom: `${PALETTE.neutral12} solid 1px`
        }
      ]}
    >
      <p css={[FONTS.h5, { marginTop: 4, marginBottom: 8 }]} role="figure" aria-label="Default coverage amount">
        {coverageString}
      </p>
      <p css={FONTS.p2}>
        This is set as the default coverage amount for this property. If you think this is incorrect, please
        <a
          href="https://support.sayrhino.com/hc/en-us/requests/new"
          css={[FONT_FAMILY.extendedBold, { textDecoration: 'underline', marginLeft: 4 }]}
          target="_blank"
        >
          submit a support ticket
        </a>
        .
      </p>
    </div>
  );
}
