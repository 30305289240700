/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import bytesFormatter from 'utils/fileSizeFormat';
import styled from '@emotion/styled';
import {
  FONTS,
  PALETTE,
  Checkbox,
  DropdownMenu,
  Alert2Icon,
  FileUploader,
  CaretIcon,
  Loading,
  Button,
  Card
} from '@sayrhino/rhino-shared-js';
import {
  submitButtonCSS,
  sectionCSS,
  sectionHeaderCss,
  sectionDividerCSS,
  alertRowCSS,
  DropDownHeader,
  RoundWrapper,
  ClaimTypeContent,
  TextAreaInput,
  StyledFormLine
} from './Styled';

import { NotesLabel } from '../ClaimDetails/ClaimStyles';
import { PropertyTypeahead } from '../../PropertyTypeahead';
import { PolicyTypeahead } from '../../PolicyTypeahead';
import { useParams } from 'react-router-dom';
import useClaim from 'api/v2/useClaim';
import useOverlay from '../../utils/overlay';
import PayoutMethod from './PayoutMethod';
import TenantResideCheckbox from './TenantResideCheckbox';
import useClaimForm from './useClaimForm';
import AttachmentFileItem from '../AttachmentFileItem';
import useClaimAttachments from 'api/v2/useClaimAttachments';
import LossOfRent from './LossOfRent';
import Damages from './Damages';
import usePolicy from 'api/v2/usePolicy';
import { isBefore, isAfter } from 'date-fns';

const StyledUploader = styled(FileUploader)({
  cursor: 'default',
  span: {
    cursor: 'pointer'
  }
});
const StyledCard = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '48.26px',
  marginBottom: '48px',
  backgroundColor: PALETTE.warning4,
  borderRadius: '8px',
  width: 'auto',
  height: '96px',
  '&:hover': {
    boxShadow: 'none',
    cursor: 'default'
  }
});
const StyledWarningTextBody = styled.p([
  FONTS.p3Light,
  {
    color: PALETTE.warning125,
    lineHeight: '24px',
    fontSize: '14px',
    lineWeight: '400'
  }
]);
const StyledWarningTextHeader = styled.p([
  FONTS.p3Medium,
  {
    color: PALETTE.warning125,
    lineHeight: '24px',
    fontSize: '14px',
    lineWeight: '400'
  }
]);

const WarningWrapper = styled.div({
  margin: '12px 24px 12px 24px'
});
const StyledSizeWarning = styled.p({
  marginLeft: '4px'
});
const CreateClaimView: React.FC = () => {
  const {
    submitForm,
    claim,
    isLoading,
    tenantInformation,
    claimDetails,
    claimDocumentation,
    payoutMethod,
    errors,
    sectionRef,
    lossOfRentError,
    checkLossOfRentErrors,
    fileSize
  } = useClaimForm();
  const {
    claimType,
    onSelectClaimType,
    lossOfRentData,
    onAddLossOfRent,
    getTotalRentAmount,
    handleChangeLossOfRentData,
    damageData,
    onChangeDamageData,
    policyRemainingAmount,
    onToggleCheckSubCategory,
    note,
    setNote,
    displayNoteTextInput,
    onChangeDateOfLoss
  } = claimDetails;

  const { data: policy } = usePolicy(Number(tenantInformation.selectedPolicy));
  const NEW_BOND_VERSION = '2021-01-01-coverage-after-bind';
  const isNewBondVersion = policy?.bond_version === NEW_BOND_VERSION;
  let coverageStart = policy?.coverage_start_date ?? '';
  let coverageEnd = policy?.coverage_end_date ?? '';
  let dateOfTheLoss =
    claimType === 'Damages' ? [damageData.dateOfLoss.date] : lossOfRentData.map(({ arrearDate }) => arrearDate.date);
  const isOutsideLeaseDates = dateOfTheLoss.some(
    (date) => isBefore(date, new Date(coverageStart)) || isAfter(date, new Date(coverageEnd))
  );
  const newBondFormWarning: boolean = isNewBondVersion && isOutsideLeaseDates;

  return (
    <form css={{ margin: '36px auto', width: '576px' }} onSubmit={submitForm}>
      <div ref={sectionRef.gettingStartedRef} css={sectionCSS}>
        <h3 css={[FONTS.h3, { marginBottom: 60 }]}>Claim submission form</h3>
        <h5 css={[FONTS.h5, { marginBottom: 16 }]}>Getting started</h5>
        <StyledFormLine />
        <PropertyTypeahead
          propertyAddress={claim?.property?.full_address}
          id="propertyTypeahead"
          property={claim?.property}
          onSelect={tenantInformation.onSelectProperty}
          isInvalid={!!errors.propertyId}
        />
        <PolicyTypeahead
          onSelect={tenantInformation.onSelectPolicy}
          propertyId={tenantInformation.selectedProperty}
          policyId={tenantInformation.selectedPolicy}
          id="policyTypeahead"
          isInvalid={!!errors.policyId}
        />
      </div>
      <TenantResideCheckbox />
      <Checkbox
        id="tenant-notified-checkbox"
        css={{ minWidth: 20 }}
        label="Renter was notified of claim"
        checked={tenantInformation.tenantNotified}
        onCheckedChange={(value) => tenantInformation.setTenantNotified(value)}
      />
      <hr css={sectionDividerCSS} />
      <div ref={sectionRef.claimDetailsRef} css={sectionCSS}>
        <h5 css={[FONTS.h5, { marginBottom: 16 }]}>Claim details</h5>
        <StyledFormLine />
        <h6 css={[FONTS.h6Demi, { marginBottom: 16 }]}>What kind of claim are you filing?</h6>
        {errors.claimType && (
          <div css={alertRowCSS}>
            <Alert2Icon width={20} height={20} css={{ marginRight: 20 }} />
            {errors.claimType}
          </div>
        )}
        <DropdownMenu.Root>
          <DropDownHeader>
            {claimType ? claimType : '-'}
            <RoundWrapper>
              <CaretIcon width={10} height={10} />
            </RoundWrapper>
          </DropDownHeader>
          <ClaimTypeContent>
            <DropdownMenu.Item onSelect={() => onSelectClaimType('Loss of Rent')}>Loss of Rent</DropdownMenu.Item>
            <DropdownMenu.Item onSelect={() => onSelectClaimType('Damages')}>Damages</DropdownMenu.Item>
          </ClaimTypeContent>
        </DropdownMenu.Root>
        {claimType === 'Loss of Rent' && (
          <LossOfRent
            lossOfRentData={lossOfRentData}
            checkLossOfRentErrors={checkLossOfRentErrors}
            lossOfRentError={lossOfRentError}
            handleChangeLossOfRentData={handleChangeLossOfRentData}
            onAddLossOfRent={onAddLossOfRent}
            policyRemainingAmount={policyRemainingAmount}
            getTotalRentAmount={getTotalRentAmount}
            originalDatesOfLoss={claim?.loss_of_rents?.map(({ date_of_loss }) => date_of_loss)}
          />
        )}
        {claimType === 'Damages' && (
          <Damages
            onChangeDateOfLoss={onChangeDateOfLoss}
            damageData={damageData}
            onChangeDamageData={onChangeDamageData}
            errors={errors}
            policyRemainingAmount={policyRemainingAmount}
            onToggleCheckSubCategory={onToggleCheckSubCategory}
            originalDateOfLoss={claim?.date_of_loss}
          />
        )}
        {displayNoteTextInput && (
          <React.Fragment>
            <TextAreaInput
              id="create-claim-note"
              name="create-claim-note"
              onChange={(e) => setNote(e.target.value)}
              value={note}
              subtext="Notes (optional)"
              placeholder="Additional notes for this claim"
            />
            <NotesLabel htmlFor="create-claim-note">Notes Description</NotesLabel>
          </React.Fragment>
        )}
      </div>
      <hr css={sectionDividerCSS} />
      <div ref={sectionRef.claimDocumentationRef} css={sectionCSS}>
        <h5 css={sectionHeaderCss}>Claim documentation</h5>
        <StyledFormLine />
        <p css={[FONTS.p1, { marginBottom: 24 }]}>
          Upload all relevant documentation or photo evidence to support your claim. If the unit becomes vacant, you are
          required to submit screenshots or links to rental listings showing that you’re actively trying to find a
          renter for the unit.
        </p>
        {errors.attachments && (
          <div css={alertRowCSS}>
            <Alert2Icon width={20} height={20} css={{ marginRight: 20 }} />
            {errors.attachments}
          </div>
        )}
        <div {...claimDocumentation.getRootProps({ className: 'dz-clickable attachments' })}>
          <StyledUploader>
            <input {...claimDocumentation.getInputProps({ id: 'dz-input' })} />
          </StyledUploader>
          <StyledSizeWarning>Current file size: {bytesFormatter(fileSize)} </StyledSizeWarning>
        </div>
        <div>
          {claimDocumentation.filesList.length > 0 && (
            <h3 css={[FONTS.h6, { color: PALETTE.neutral65, marginTop: 24, marginBottom: 12 }]}>Attachments</h3>
          )}
          {claimDocumentation.filesList.map((file) => (
            <AttachmentFileItem
              key={file.name}
              attachment={file}
              removeAttachment={claimDocumentation.removeAttachment}
            />
          ))}
        </div>
      </div>
      {!payoutMethod.hidePayoutMethod && <PayoutMethod {...payoutMethod} errors={errors} />}
      {newBondFormWarning && (
        <StyledCard variant="borderless">
          <WarningWrapper>
            <StyledWarningTextHeader>One or more dates of loss do not fall within lease term</StyledWarningTextHeader>
            <StyledWarningTextBody>
              Your claim reimbursement may differ from the amount you claimed because one or more of your dates of loss
              do not fall within the lease term.
            </StyledWarningTextBody>
          </WarningWrapper>
        </StyledCard>
      )}
      <Button variant="primary" css={[FONTS.h5, submitButtonCSS]} type="submit" disabled={isLoading}>
        Submit claim
      </Button>
    </form>
  );
};

export const CreateClaim = () => {
  const { claimsId } = useParams();
  const { isSuccess } = useClaim(Number(claimsId)) ?? {};
  const { setShowOverlay } = useOverlay();
  const { isSuccess: attachmentsSuccess } = useClaimAttachments(Number(claimsId), { per: 100 }) ?? {};

  useEffect(() => {
    setShowOverlay(true);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  return !claimsId || (isSuccess && attachmentsSuccess) ? <CreateClaimView /> : <Loading />;
};

export default CreateClaim;
