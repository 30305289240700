import React, { useState } from 'react';
import { PALETTE, TextInput, Alert2Icon } from '@sayrhino/rhino-shared-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import RhinoLogo from '../../../../../assets/images/RhinoLogoBlack.svg';
import { useSegmentUser } from '../utils/segmentTracker';
import {
  validatePasswordLength,
  validatePasswordContainsSymbol,
  validatePasswordContainsNumber,
  validateEmail,
  isValidEmail
} from '../utils/formValidations';

import createAccount from './create';
import {
  FormArea,
  Card,
  Header,
  Logo,
  Page,
  Heading,
  Row,
  FirstName,
  LastName,
  Terms,
  TermsLink,
  Button,
  BottomText,
  SubtextErrorMessage,
  SubtextErrorWrapper,
  ErrorNotification,
  ValidationWrapper,
  Validation,
  Link
} from './components';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

export type Fields = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  external_system_id?: string;
  external_system_type?: string;
};

const Form: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const segmentUser = useSegmentUser();
  const initialFields = {
    email: params.get('email') ?? '',
    external_system_id: params.get('external_system_id') ?? '',
    external_system_type: params.get('external_system_type') ?? ''
  };
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [fields, setFields] = useState<Fields>(initialFields as Fields);
  const [emailInputError, setEmailInputError] = useState<string>();
  const [error, setError] = useState<string>();
  const { mutate, isLoading } = useMutation(createAccount);
  const singlePropertyUpload = (window as any).App?.featureFlags?.singlePropertyUpload;

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    mutate(fields, {
      onSuccess: () => {
        if (singlePropertyUpload) {
          window.location.href = '/admin/properties/add/new';
        } else {
          window.location.href = '/admin/properties?modal=true&table=true';
        }
      },
      onError: (error: AxiosError) => {
        setError(error?.response?.data.error.PropertyManagerAdmin.split(':')[1]);
        setTimeout(() => setError(''), 5000);
      }
    });
  };

  const validateEmailInput = () => {
    const emailValidation = validateEmail(fields?.email);
    if (emailValidation) {
      setEmailInputError(emailValidation);
    } else {
      setEmailInputError(undefined);
    }
  };

  const isValidPassword = (password) => {
    return (
      validatePasswordLength(password) &&
      validatePasswordContainsNumber(password) &&
      validatePasswordContainsSymbol(password)
    );
  };

  const isValidState = (): boolean =>
    fields !== undefined &&
    isValidEmail(fields.email) &&
    fields.first_name?.length > 0 &&
    fields.last_name?.length > 0 &&
    isValidPassword(fields.password);

  const subtextError = (error) => {
    return (
      <SubtextErrorWrapper>
        <Alert2Icon color={PALETTE.alert125} height="16px" width="16px" />
        <SubtextErrorMessage>{error}</SubtextErrorMessage>
      </SubtextErrorWrapper>
    );
  };

  return (
    <Page>
      <Header>
        <Logo src={RhinoLogo} alt="Rhino" />
      </Header>
      <FormArea onSubmit={handleSubmit}>
        <Card>
          <Heading>Create your Rhino Partner account</Heading>
          <Row>
            <FirstName
              label="First name"
              id="firstName"
              data-cy="firstNameLSS"
              css
              value={fields.first_name}
              onChange={(e) => setFields((prev) => ({ ...prev, first_name: e.target.value }))}
            />
            <LastName
              label="Last name"
              id="lastName"
              data-cy="lastNameLSS"
              css
              value={fields.last_name}
              onChange={(e) => setFields((prev) => ({ ...prev, last_name: e.target.value }))}
            />
          </Row>
          <TextInput
            id="email"
            label="Email address"
            css
            data-cy="emailLSS"
            type="email"
            value={fields.email}
            subtext={emailInputError ? subtextError(emailInputError) : ''}
            error={emailInputError !== undefined}
            onBlur={() => validateEmailInput()}
            onChange={(e) => setFields((prev) => ({ ...prev, email: e.target.value }))}
          />
          <TextInput
            id="password"
            label="Password"
            css
            data-cy="passwordLSS"
            value={fields.password}
            onChange={(e) => setFields((prev) => ({ ...prev, password: e.target.value }))}
            rightIcon={
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={() => setShowPassword((prev) => !prev)}
              />
            }
            type={showPassword ? 'text' : 'password'}
          />
          <ValidationWrapper>
            <Validation label={'At least 8 characters'} active={validatePasswordLength(fields.password)} />
            <Validation label={'At least 1 number'} active={validatePasswordContainsNumber(fields.password)} />
            <Validation label={'At least 1 symbol'} active={validatePasswordContainsSymbol(fields.password)} />
          </ValidationWrapper>
          <Terms>
            By creating an account, you are agreeing to comply with our{' '}
            <TermsLink href="https://guidelines.sayrhino.com/">program guidelines</TermsLink> and{' '}
            <TermsLink href="http://terms.sayrhino.com/">terms of use</TermsLink>. Failure to comply may result in
            deactivation of your access to Rhino.
          </Terms>
          {error && <ErrorNotification error={error} />}
          <Button
            children={isLoading ? 'Loading' : 'Create your account'}
            variant="primary"
            disabled={!isValidState() || isLoading}
            type="submit"
            data-cy="createAccountLSS"
          />
        </Card>
        <BottomText>
          Already have an account? <Link href="/">Sign in</Link>
        </BottomText>
      </FormArea>
    </Page>
  );
};

export default Form;
