import React from 'react';
import styled from '@emotion/styled';
import { FileIcon, FONTS, Loading, PALETTE, WithBackgroundIcon } from '@sayrhino/rhino-shared-js';
import { useParams } from 'react-router-dom';
import { initializeDate, stringifyDate } from '../../utils';
import { flatMap } from 'lodash';
import useClaimNotes from 'api/v2/useClaimNotes';
import PagingList from '../../PagingList';
import { SectionHeader, StyledCardDetails, LoadingWrapper } from './ClaimStyles';
import useUserRole from '../../utils/userRole';

const getInitials = (author: string) => {
  return author
    .split(' ')
    .map((x) => x.charAt(0))
    .join('')
    .substr(0, 2)
    .toUpperCase();
};

const Header = styled.div({ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' });
const AuthorWrapper = styled.div({ display: 'flex', alignItems: 'center', marginBottom: 13 });
const Author = styled.div([FONTS.p1Medium]);
const IconText = styled.p([FONTS.p1Medium, { color: PALETTE.neutralLight }]);
const CreatedAt = styled.p([FONTS.p3Medium, { color: PALETTE.neutral55 }]);
const PrivateVisibility = styled.p([FONTS.p3Medium, { color: PALETTE.poppy, marginLeft: 10 }]);
const Description = styled.p([FONTS.p1Light], { whiteSpace: 'pre-line' });
const AttachmentsWrapper = styled.div({ marginTop: 12 });
const Attachment = styled.div({ display: 'flex', alignItems: 'center', marginBottom: 10 });
const AttachmentLink = styled.a([
  FONTS.p1Light,
  {
    color: PALETTE.interaction100,
    textDecoration: 'underline',
    pointer: 'cursor',
    marginRight: '15px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer'
    }
  }
]);

const AttachmentDownloadText = styled.p([
  FONTS.p2Light,
  {
    color: PALETTE.neutral65,
    height: '20px',
    marginTop: '4px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
]);

const ClaimNotes = ({ handleModal, handleDownload }) => {
  const { claimsId } = useParams();
  const { isAdmin, isThirdPartyAdmin } = useUserRole();
  const { hasNextPage, fetchNextPage, isSuccess, isLoading, isFetchingNextPage, data } = useClaimNotes(
    Number(claimsId)
  );
  const notesData = flatMap(data?.pages, (d) => d.data || []);
  const totalCount = (isSuccess && data?.pages[0].meta.total_count) ?? 0;
  const displayTitle = !(isAdmin || isThirdPartyAdmin) && totalCount > 0;
  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <>
      {displayTitle && <SectionHeader>Notes</SectionHeader>}
      {isSuccess &&
        notesData.map(({ id, created_by, created_at, description, publicVisibility, attachments }) => {
          const author = created_by.full_name.trim() || created_by.email;
          return (
            <StyledCardDetails key={id} variant="border">
              <Header>
                <AuthorWrapper>
                  <WithBackgroundIcon style={{ background: PALETTE.brand100, marginRight: 12 }}>
                    <IconText>{getInitials(author)}</IconText>
                  </WithBackgroundIcon>
                  <Author>{author}</Author>
                </AuthorWrapper>
                <CreatedAt>{stringifyDate(initializeDate(created_at))}</CreatedAt>
                {!publicVisibility && <PrivateVisibility>INTERNAL</PrivateVisibility>}
              </Header>
              <Description>{description}</Description>
              <AttachmentsWrapper>
                {!!attachments?.length &&
                  attachments.map((attachment) => (
                    <Attachment key={attachment.blob_id}>
                      <FileIcon style={{ marginRight: 12, color: PALETTE.interaction100 }} />
                      <AttachmentLink onClick={() => handleModal(attachment)}>{attachment.filename}</AttachmentLink>
                      <AttachmentDownloadText onClick={() => handleDownload(attachment.url, attachment.filename)}>
                        <span style={{ fontFamily: 'monospace' }}>∙</span> Download
                      </AttachmentDownloadText>
                    </Attachment>
                  ))}
              </AttachmentsWrapper>
            </StyledCardDetails>
          );
        })}
      {totalCount > 20 && (
        <PagingList
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasData //We are already checking that there is data to display the component
        />
      )}
    </>
  );
};

export default ClaimNotes;
