import React, { useState } from 'react';
import { put } from 'utils/request';
import { IAssignee } from './types';
import { BulkActionLink, BulkActionExpand, BulkActionArrowUp, BulkActionArrowDown } from '../Styles';

interface IProps {
  selectedIds: number[];
  assignees: IAssignee[];
  onSuccess?(): void;
}

const BulkAssign = ({ selectedIds, assignees, onSuccess }: IProps) => {
  const [expanded, setExpanded] = useState(false);

  return <>
    <BulkActionLink onClick={() => setExpanded(!expanded)}>
      Assign to {expanded ? <BulkActionArrowUp /> : <BulkActionArrowDown />}
    </BulkActionLink>
    { expanded &&
      <BulkActionExpand>
        {
          assignees.map((a) =>
            <li key={a.id} onClick={() => assignTo(a.id, selectedIds, onSuccess)}>
              <a>{a.name_or_email}</a>
            </li>)
        }
      </BulkActionExpand>
    }
  </>
}

const assignTo = (id: number, selectedIds: number[], onSuccess?: Function) => {
  const message= `You are about to update ${selectedIds.length} claims. ` +
    `This action is irreversable`;

  if (confirm(message)) {
    const updatePath = '/admin/claims/bulk_assign_admin';

    put(updatePath, {administrator_id: id, claim_ids: selectedIds}).then((_response) => {
      onSuccess && onSuccess()
    });
  }
}

export default BulkAssign;
