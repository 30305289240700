import React from 'react';
import { render, fireEvent, waitFor } from '@testing-library/react';
import Search from '../Search';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';

let server;
beforeEach(() => server = makeServer());
afterEach(() => server.shutdown());

describe('<Search />', () => {
  const doRender = () =>
    render(
      <Wrapper>
        <Search />
      </Wrapper>
    );

  test('initial search page', async () => {
    const { findByText } = doRender();
    const heading = await findByText('Search');
    expect(heading).toBeDefined();
    expect('Start typing to search').toBeDefined();
  });

  test('does not render the result when no query has been entered', async () => {
    const { queryByText } = doRender();
    const result = queryByText('Steve');
    expect(result).toBeNull();
  });

  test('renders search results after entering query', async () => {
    const { findByPlaceholderText, queryByText } = doRender();
    const input = await findByPlaceholderText('Search');
    fireEvent.change(input, { target: { value: 'my query' } });

    await waitFor(() => expect(queryByText('Steve')).toBeDefined());
  });

  test('renders no results if there are none', async () => {
    const { queryByText, findByPlaceholderText } = doRender();
    const input = await findByPlaceholderText('Search');
    fireEvent.change(input, { target: { value: 'thisshouldnothaveanyresults' } });

    await waitFor(() => expect(queryByText('Looks like nothing matches your search.')).toBeDefined());
  });
});
