import axios from 'axios';
import { useMutation } from 'react-query';
import { csrfToken } from 'utils/document';

export const pushAllPolicies = async (id: string) => {
  const authenticity_token = csrfToken();
  const { data } = await axios.post(
    `/v2/admin/property_owners/${id}/push_all_policies`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return data;
};
