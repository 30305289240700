/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ArrowLeft, ArrowRight, PALETTE, FONTS, FONT_FAMILY } from '@sayrhino/rhino-shared-js';
import { range } from 'lodash';
import React from 'react';

interface IPaginationProps {
  currentPageCount: number;
  nextPage?: number | null;
  page: number;
  per: number;
  prevPage?: number | null;
  setPage: (arg: number) => void;
  totalCount: number;
  totalPages: number;
}

export const Pagination: React.FC<IPaginationProps & JSX.IntrinsicElements['div']> = ({
  currentPageCount,
  nextPage,
  page,
  per,
  prevPage,
  setPage,
  totalCount,
  totalPages,
  ...rest
}) => {
  let paginationWindow;

  if (page <= 6 && totalPages <= 10) {
    paginationWindow = [...range(1, totalPages + 1)];
  } else if (page <= 6) {
    paginationWindow = [...range(1, 11), '...', totalPages];
  } else if (page >= totalPages - 5) {
    paginationWindow = [1, '...', ...range(totalPages - 5, totalPages + 1)];
  } else {
    paginationWindow = [1, '...', ...range(page - 3, page + 4), '...', totalPages];
  }

  const windowStart = (page <= 1 ? 1 : (page - 1) * per + 1).toLocaleString();
  const windowEnd = (page === totalPages ? (page - 1) * per + currentPageCount : page * per).toLocaleString();
  const paginationWindowElements = paginationWindow.map((element, index) => {
    if (element === '...') {
      return (
        <div
          key={`pagination-window-element-${element}-${index}`}
          css={{ color: PALETTE.neutral25, lineHeight: 'inherit', paddingRight: '.75rem' }}
        >
          {element}
        </div>
      );
    } else {
      return (
        <button
          key={`pagination-window-element-${element}-${index}`}
          type="button"
          css={[
            {
              backgroundColor: 'transparent',
              border: 'none',
              color: element === page ? PALETTE.neutralDark : PALETTE.neutral55,
              padding: '0 .75rem 0 0'
            },
            element === page && FONT_FAMILY.extendedMedium
          ]}
          onClick={() => {
            setPage(element);
          }}
        >
          {element}
        </button>
      );
    }
  });

  return (
    <div css={{ marginBottom: '1.5rem', display: 'flex', flexDirection: 'column' }} {...rest}>
      <div css={[{ display: 'flex', justifyContent: 'center', marginBottom: '.5rem' }, FONTS.p1Light]}>
      <button
          type="button"
          disabled={page <= 1 ? true : false}
          css={{
            padding: '0 1.25rem 0 0',
            border: '0',
            backgroundColor: 'transparent',
            color: page <= 1 ? PALETTE.neutral12: PALETTE.neutralDark,
            lineHeight: '0'
          }}
          onClick={() => page <= 1 ? false : setPage(page - 1)}
        >
          <ArrowLeft />
        </button>
        {paginationWindowElements}
        <button
          type="button"
          disabled={page >= totalPages ? true : false}
          css={[
            page >= totalPages ? FONTS.p2Medium : FONTS.p2Light,
            {
              padding: '0 0 0 .25rem',
              border: '0',
              backgroundColor: 'transparent',
              color: page >= totalPages ? PALETTE.neutral12: PALETTE.neutralDark,
              lineHeight: '0'
            }
          ]}
          onClick={() => page >= totalPages ? false : setPage(page + 1)}
        >
          <ArrowRight />
        </button>
      </div>
      <div css={[{ color: PALETTE.neutral65, display: 'flex', justifyContent: 'center' }, FONTS.p3Light]}>
        Showing {windowStart}-{windowEnd} of {totalCount.toLocaleString()}
      </div>
    </div>
  );
};

export default Pagination;
