/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import { CloseIcon, FONTS, PALETTE, RhinoLogo, Alert2Icon } from '@sayrhino/rhino-shared-js';
import '@sayrhino/rhino-shared-js/build/datepicker.css';
import { ChangeEvent, createRef, Ref, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobileDevice } from 'utils/mobile';
import { v4 as uuid } from 'uuid';
import useToast, { TOAST_STATUS } from '../toast/use-toast';
import { useSegmentTrackOnLoad, useSegmentUser } from '../utils/segmentTracker';
import useUserRole from '../utils/userRole';
import { useLocation } from 'react-router-dom';
import Tab from '../Tab';
import GettingStartedSteps from './GettingStartedSteps';
import useCreateInvitationHook from './useCreateInvitationHook';
import PolicyRenewalForm from './PolicyRenewalForm';
import { closeButtonCSS } from './Styles';
import { mediaQueryWidth, useReferrer } from '../utils';
import usePolicy from 'api/v2/usePolicy';
import { BREAKPOINTS } from '../utils/constants';

export interface IRenewal {
  insurance_policy_id?: number;
  renewal_type?: string;
  non_renewal_reason?: number;
  renew_blocking_reasons?: [number];
}

export interface IPolicy {
  address?: string;
  first_name?: string;
  last_name?: string;
  has_renewal?: boolean;
  id?: number;
  renewal?: IRenewal;
  unit_name?: string;
  coverage_end_date: string;
  next_policy_id?: any;
}

const IS_MOBILE = isMobileDevice();

export const CreatePolicyRenewalInvitation = () => {
  const referrer = useReferrer();
  const referredPolicyNumber = referrer ? parseInt(referrer) : undefined;
  const navigate = useNavigate();
  const { isAdmin } = useUserRole();
  const [policy, setPolicy] = useState<IPolicy | undefined>();
  const [isFormValid, setIsFormValid] = useState(false);
  const requiredFields = ['first_name', 'last_name', 'email', 'property_id', 'rental_status', 'monthly_rent'];
  const [inviteSessionId, setInviteSessionId] = useState(uuid());
  const segmentUser = useSegmentUser();
  const formRef = createRef<HTMLDivElement>();
  const { addToast } = useToast();
  let element = window.document.getElementById('topBar');
  const location = useLocation();
  const TabList = styled.div({ borderBottom: `1px solid ${PALETTE.neutral12}`, display: 'flex', paddingLeft: '48px' });
  const renewalRequests = (window as any).App?.featureFlags?.renewalRequests;
  const data = referredPolicyNumber ? usePolicy(referredPolicyNumber) : undefined;
  const { invitation } = useCreateInvitationHook();

  if (isAdmin) {
    requiredFields.pop();
  }

  if (IS_MOBILE) {
    element!.style.visibility = 'hidden';
  }

  useEffect(() => {
    window.addEventListener('resize', screenSize);
    return () => window.removeEventListener('resize', screenSize);
  }, []);

  const screenSize = () => {
    if (IS_MOBILE) {
      element!.style.visibility = 'hidden';
    } else {
      element!.style.visibility = 'visible';
    }
  };

  useEffect(() => {
    if (invitation) {
      const hasRequiredFields = requiredFields.every(
        (item) => invitation.hasOwnProperty(item) && invitation[item] !== ''
      );
      hasRequiredFields && Number(invitation.monthly_rent) > 100 ? setIsFormValid(true) : setIsFormValid(false);
    }
  }, [invitation]);

  useEffect(() => {
    if (data?.data) {
      const referredPolicyValue = {
        address: data.data.property_address,
        id: data.data.id,
        unit_name: data.data.unit_name,
        coverage_end_date: data.data.coverage_end_date,
        next_policy_id: data.data.next_policy_id
      };
      setPolicy(referredPolicyValue);
    }
  }, [data?.data]);

  const onPolicyChange = (policy) => {
    trackSegmentEvents('Renewal Tenant Search Entered', {
      meta: {
        cta_referrer: document.referrer.split('/').slice(3).join('/')
      }
    });
    setPolicy(policy);
  };

  const trackSegmentEvents = (eventName: string, { meta }) => {
    window.analytics.track(eventName, {
      invite_session_id: inviteSessionId,
      userId: segmentUser.user_id,
      userType: segmentUser.user_type,
      sessionId: segmentUser.portal_session_id,
      ...meta
    });
  };

  useSegmentTrackOnLoad('Renewal Request Started', {
    invite_session_id: inviteSessionId,
    user_type: segmentUser.user_type,
    user_id: segmentUser.user_id,
    portal_session_id: segmentUser.portal_session_id,
    cta_referrer: document.referrer.split('/').slice(2).join('/')
  });

  return (
    <div>
      <GettingStartedSteps type="renewal" />
      <div
        id="form-container"
        css={{
          flex: 1,
          marginLeft: 384,
          [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
            marginLeft: 0
          }
        }}
      >
        <div
          id="header"
          className="invitation-header"
          css={{
            borderBottom: `1px solid ${PALETTE.neutral4}`,
            backgroundColor: PALETTE.neutralLight,
            padding: '20px 48px',
            position: 'sticky',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 1,
            [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
              display: 'none'
            }
          }}
        >
          <span css={[FONTS.h5, { color: `${PALETTE.neutral25}` }]}>Send an invitation</span>
          <button css={closeButtonCSS} onClick={() => navigate(-1)} aria-label="Leave invitation form">
            <CloseIcon height="16px" width="16px" color={PALETTE.neutral25} css={{ marginTop: '7px' }} />
          </button>
        </div>
        <form>
          <div
            id="form"
            ref={formRef}
            css={{
              padding: '40px 96px',
              marginBottom: 100,
              [mediaQueryWidth({ value: BREAKPOINTS.md })]: {
                padding: '40px 16px'
              }
            }}
          >
            {IS_MOBILE && (
              <div
                css={{
                  marginBottom: '40px'
                }}
              >
                <RhinoLogo />
              </div>
            )}

            {referredPolicyNumber == undefined && (
              <TabList role="tabList" aria-label="Send invitations">
                <Tab to={'/admin/invitations/new'}>New policy</Tab>
                <Tab to={'/admin/invitations/policy_renewal'}>Policy renewal</Tab>
              </TabList>
            )}

            {renewalRequests && (
              <PolicyRenewalForm
                policy={policy}
                onPolicyChange={() => {
                  onPolicyChange(policy);
                }}
                setPolicy={setPolicy}
                invite_id={inviteSessionId}
                referredPolicyId={referredPolicyNumber}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePolicyRenewalInvitation;
