import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { Button, FONTS, PALETTE, CloseIcon } from '@sayrhino/rhino-shared-js';
import FormFieldBase from '../FormField';
import useOverlay from '../utils/overlay';
import { ACCOUNT_ROUTES } from '../utils/routeHelpers';
import { AxiosError } from 'axios';
import useSubmitActivationCodeMutation from 'api/v2/useSubmitActivationCodeMutation';
import { useQueryClient } from 'react-query';
import { useUpdateTFAState } from '../utils/userRole';

const PageWrapper = styled.div({ padding: '80px 48px' });
const Title = styled.h4([
  FONTS.h4,
  { marginBottom: '36px', paddingBottom: '28px', borderBottom: `1px solid ${PALETTE.neutral12}` }
]);
const SubTitle = styled.h5([FONTS.h5, { marginBottom: '8px' }]);
const Text = styled.p([FONTS.p1Light, { marginBottom: '32px' }]);
const FormField = styled(FormFieldBase)({ width: '192px', marginBottom: '24px' });
const Step2Wrapper = styled.div({ display: 'flex', justifyContent: 'space-between' });
const qrCodeImagePath = '/v2/users/account/two_factor/qr_code.png';
const QrCode = styled.img({ width: '144px', height: '144px', marginBottom: '32px' });
const CloseButton = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  width: 32,
  backgroundColor: PALETTE.neutral4,
  borderRadius: 75,
  float: 'right',
  marginTop: '14px',
  marginRight: '32px'
});

interface IProps {}

const TwoFactorSetup: React.FC<IProps> = () => {
  const { setShowOverlay } = useOverlay();
  const [activationCode, setActivationCode] = useState<string | undefined>('');
  const [activationCodeError, setActivationCodeError] = useState<string | undefined>();
  const [qrCodeSrc, setQRCodeSrc] = useState<string | undefined>();
  const queryCache = useQueryClient();
  const navigate = useNavigate();
  const updateTFA = useUpdateTFAState();

  useEffect(() => {
    setShowOverlay(true);
    setQRCodeSrc(`${qrCodeImagePath}?${Date.now()}`);

    return () => {
      setShowOverlay(false);
    };
  }, []);

  const { mutate: submitActivationCode } = useSubmitActivationCodeMutation();

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setActivationCodeError(undefined);

    if (activationCode?.length) {
      submitActivationCode(
        { otp_attempt: activationCode.replace(/\s+/g, '') },
        {
          onSuccess: () => {
            queryCache.invalidateQueries('account details');
            if (updateTFA) updateTFA(false);
            navigate(`/${ACCOUNT_ROUTES.twoFactorCodes}`);
          },
          onError: (error: AxiosError) => {
            const activationErrorResponse = error?.response?.data?.errors;
            if (activationErrorResponse && activationErrorResponse.length) {
              setActivationCodeError(activationErrorResponse);
            }
          }
        }
      );
    } else {
      setActivationCodeError('Cannot be blank');
    }
  };

  return (
    <>
      <CloseButton to={`/${ACCOUNT_ROUTES.edit}`} aria-label="return to edit My Profile">
        <CloseIcon height={14} width={14} />
      </CloseButton>
      <PageWrapper>
        <Title>Two-Factor Authentication Configuration</Title>
        <SubTitle>Step 1 — Get the App</SubTitle>
        <Text>
          Download and install the Google Authenticator, Duo Mobile, Authy, or Windows Phone Authenticator app for your
          phone or tablet.
        </Text>
        <Step2Wrapper>
          <div>
            <SubTitle>Step 2 — Scan this Barcode</SubTitle>
            <Text>
              1. Open the authentication app <br />
              2. Tap the "+" icon in the top-right of the app
              <br />
              3. Scan this code, using your phone's camera
            </Text>
          </div>
          <QrCode src={qrCodeSrc} alt="qr code" />
        </Step2Wrapper>
        <SubTitle>Step 3 — Enter Verification Code</SubTitle>
        <Text>Once the barcode above is scanned, enter the 6-digit verification code generated by the app.</Text>
        <form onSubmit={handleSubmit}>
          <FormField
            error={activationCodeError}
            input={
              <input
                name="verificationCode"
                id="verificationCode"
                title="Verification Code"
                type="verificationCode"
                value={activationCode}
                autoComplete="off"
                onChange={(e) => setActivationCode(e.target.value)}
              />
            }
            label={<label htmlFor="verificationCode">Verification Code</label>}
          />
          <Button type="submit" variant="primary">
            Verify &amp; Activate
          </Button>
        </form>
      </PageWrapper>
    </>
  );
};

export default TwoFactorSetup;
