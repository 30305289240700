import axios from 'axios';
import { csrfToken } from 'utils/document';
import { useMutation } from 'react-query';

type PolicyCancellation = {
  status: 'accepted' | 'declined',
  cancellation_request_denial_attributes?: CancelationRequestDenial
};
type CancellationId = { id: number };
type CancelationRequestDenial = {
  reason: string | number | null,
  reason_explanation?: string | null,
  blocks_new_requests_until?: string | null
}

const updateCancellationRequest = async (cancellation: PolicyCancellation & CancellationId) => {
  const authenticity_token = csrfToken();
  const urlParams = new URLSearchParams(window.location.search);
  const auth_token = urlParams.get('auth_token');
  const { status, cancellation_request_denial_attributes, id } = cancellation;
  const { data } = await axios.put<PolicyCancellation>(
    `/v2/admin/insurance_policy_cancellation_requests/${id}`,
    {
      cancellation_request: {
        status,
        cancellation_request_denial_attributes
      },
      auth_token
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return data;
};

const useUpdatePolicyCancellationRequest = () => useMutation(updateCancellationRequest);

export default useUpdatePolicyCancellationRequest;
