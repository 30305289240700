import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import ActivityFeed from '../ActivityFeed';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';

let server;

describe('<ActivityFeed />', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });

  test('It renders the activity feed header', async () => {
    render(
      <Wrapper>
        <ActivityFeed />
      </Wrapper>
    );
    const heading = await screen.findByText('Activity feed');

    expect(heading).toBeDefined();
  });

  test('It shows the total amount of updates', async () => {
    render(
      <Wrapper>
        <ActivityFeed />
      </Wrapper>
    );
    const count = await screen.findByText('21');

    expect(count).toBeDefined();
  });

  test('It claims cards mixed in with insurance policy cards', async () => {
    render(
      <Wrapper>
        <ActivityFeed />
      </Wrapper>
    );
    const status = await screen.findByText('$310.00', { exact: false });

    expect(status).toBeDefined();
  });

  test('It fetches more updates when the load more button is clicked', async () => {
    render(
      <Wrapper>
        <ActivityFeed />
      </Wrapper>
    );

    const button = await screen.findByRole(/button/);
    userEvent.click(button);

    await waitFor(() => screen.getByText('John_1330 Doe_1330'));

    expect(screen.getByText('John_1330 Doe_1330')).toBeTruthy();
  });
});
