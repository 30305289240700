import axios from 'axios';
import { csrfToken } from 'utils/document';
import { InsurancePolicyChangeRequest } from 'components/v2/App/interfaces';

type PolicyChangeOptions = Partial<InsurancePolicyChangeRequest>;

const mapToChangePolicy = (policy: PolicyChangeOptions): Partial<InsurancePolicyChangeRequest> => {
  const {
    lease_end_date,
    lease_start_date,
    unit_id,
    coverage_amount_cents,
    property_id,
    monthly_rent_cents,
    requester_user_id,
    coverage,
    insurance_policy_id,
    needs_tenant_approval,
    previous_unit_id
  } = policy;
  return {
    unit_id,
    property_id,
    lease_end_date,
    lease_start_date,
    coverage_amount_cents,
    monthly_rent_cents,
    insurance_policy_id,
    needs_tenant_approval,
    requester_user_id,
    coverage,
    previous_unit_id
  };
};

const changePolicy = async (policy: Partial<PolicyChangeOptions>) => {
  const authenticity_token = csrfToken();

  const { data } = await axios.post<InsurancePolicyChangeRequest>(
    '/admin/insurance_policy_change_requests',
    mapToChangePolicy(policy),
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticity_token
      }
    }
  );
  return data;
};

export default changePolicy;
