import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Card, PALETTE, FONTS } from '@sayrhino/rhino-shared-js';

const ErrorWrapper = styled.div({
  marginTop: '4em',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const ErrorDetails = styled.ul({
  listStyle: 'inside'
});

const ErrorTitle = styled.h3([FONTS.h3], { marginBottom: '1em' });

interface ErrorType {
  message: String;
  detail: String;
}

export interface CsvErrors {
  row_index: number;
  type: Array<ErrorType>;
}

interface IProps {
  rows: [CsvErrors];
}

const ValidationErrors = ({ rows }: IProps) => {
  if (rows instanceof String) {
    return <></>;
  }

  return (
    <ErrorWrapper>
      <ErrorTitle>CSV is invalid. Please correct errors below:</ErrorTitle>
      {rows.map(({ row_index, type }) => (
        <Card key={row_index} style={{ height: 'auto', marginBottom: '2em' }}>
          {row_index == -1 ? <div>State Errors: </div> : <div>Row: {row_index + 2}</div>}
          <ErrorDetails>
            {type.map(({ message, detail }, i) => (
              <div key={i + row_index}>
                <li style={{ color: PALETTE.alert100 }}>{`${message}: ${detail}`}</li>
              </div>
            ))}
          </ErrorDetails>
        </Card>
      ))}
    </ErrorWrapper>
  );
};

export default ValidationErrors;
