import React from 'react';
import { DisplayFieldControls, PropertyOwnerFormLabel, StyledContent } from './Styled';
import { Switch } from '@sayrhino/rhino-shared-js';
import useUpdatePropertyOwnerMutation from 'api/v2/updatePropertyOwner';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useToast from '../toast/use-toast';
import { AxiosError } from 'axios';

interface IProps {
  guarantorCoverageCapable?: boolean;
}

const GuarantorCoverageCapable = ({ guarantorCoverageCapable }: IProps) => {
  const { propertyOwnerId } = useParams();
  const { mutate: updatePropertyOwnerMutation } = useUpdatePropertyOwnerMutation();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const update = (propertyOwnerId: number, guarantor_coverage_capable: boolean) => {
    updatePropertyOwnerMutation(
      { id: propertyOwnerId, guarantor_coverage_capable },
      {
        onSuccess: (_e) => {
          queryClient.invalidateQueries(['property owner details', propertyOwnerId]);
          addToast('Property Owner Information was successfully updated');
        },
        onError: (_e: AxiosError) => {
          addToast('Something went wrong', 'error');
        }
      }
    );
  };

  return (
    <>
      <PropertyOwnerFormLabel>
        Allow Guarantor Coverage
        <DisplayFieldControls>
          <Switch
            id="toggle-guarantor-coverage-capable"
            aria-labelledby="toggle-guarantor-coverage-capable"
            aria-selected={!!guarantorCoverageCapable}
            checked={!!guarantorCoverageCapable}
            onCheckedChange={() => update(Number(propertyOwnerId), !guarantorCoverageCapable)}
          />
        </DisplayFieldControls>
      </PropertyOwnerFormLabel>
      <StyledContent>
        Allow ability to toggle Guarantor Coverage on invitations for this property owner's properties.
      </StyledContent>
    </>
  );
};

export default GuarantorCoverageCapable;
