import React from 'react';
import { fireEvent, render, screen } from '@testing-library/react';
import UploadProperties from '../PropertyUpload/batchUploadSteps/UploadProperties';
import makeServer from '../testUtils/server';
import Wrapper from '../testUtils/Wrapper';
let server;

describe('<UploadProperties />', () => {
  beforeEach(() => {
    server = makeServer();
  });

  afterEach(() => {
    server.shutdown();
  });
  const mockSubmit = jest.fn();

  test('It renders a modal to upload a file', async () => {
    render(
      <Wrapper>
        <UploadProperties onSubmit={mockSubmit} />
      </Wrapper>
    );
    const heading = await screen.findByRole('heading', { level: 2, name: /Import properties/i });
    const subheading = await screen.findByText(/Import all of your properties by uploading a CSV file below./i);
    expect(heading).toBeDefined();
    expect(subheading).toBeDefined();
  });

  test('Shows an error if file format is not csv', async () => {
    render(
      <Wrapper>
        <UploadProperties onSubmit={mockSubmit} />
      </Wrapper>
    );

    const inputEl = screen.getByTestId('file');
    const file = new File(['imagefile'], 'image.png', { type: 'incorrect_format' });

    Object.defineProperty(inputEl, 'files', { value: [file] });

    fireEvent.change(inputEl);

    const error = await screen.findByText(/Your file is incorrectly formatted/);
    expect(error).toBeDefined();
  });

  test('Shows an error if the csv has the wrong headers', async () => {
    render(
      <Wrapper>
        <UploadProperties onSubmit={mockSubmit} />
      </Wrapper>
    );

    const inputEl = screen.getByTestId('file');
    const file = new File(['csv'], 'props.csv', { type: 'template_guideline_error' });

    Object.defineProperty(inputEl, 'files', { value: [file] });

    fireEvent.change(inputEl);

    const error = await screen.findByText(/does not match upload template guidelines/);
    expect(error).toBeDefined();
  });

  test('Shows an error if the csv is empty', async () => {
    render(
      <Wrapper>
        <UploadProperties onSubmit={mockSubmit} />
      </Wrapper>
    );

    const inputEl = screen.getByTestId('file');
    const file = new File(['csv'], 'props.csv', { type: 'no_rows' });

    Object.defineProperty(inputEl, 'files', { value: [file] });

    fireEvent.change(inputEl);

    const error = await screen.findByText(/Please include at least one property in the csv and try again/);
    expect(error).toBeDefined();
  });

  test('Shows an error if an unexpected issue occurs', async () => {
    render(
      <Wrapper>
        <UploadProperties onSubmit={mockSubmit} />
      </Wrapper>
    );

    const inputEl = screen.getByTestId('file');
    const file = new File(['csv'], 'props.csv', { type: 'error' });

    Object.defineProperty(inputEl, 'files', { value: [file] });

    fireEvent.change(inputEl);

    const error = await screen.findByText(/Please try again/);
    expect(error).toBeDefined();
  });

  test('When the csv has validation errors, it shows that the csv was uploaded', async () => {
    render(
      <Wrapper>
        <UploadProperties onSubmit={mockSubmit} />
      </Wrapper>
    );

    const inputEl = screen.getByTestId('file');
    const file = new File(['csv'], 'props.csv', { type: 'validation_error' });

    Object.defineProperty(inputEl, 'files', { value: [file] });

    fireEvent.change(inputEl);

    const uploadNotice = await screen.findByText(/Uploaded/);
    expect(uploadNotice).toBeDefined();
  });

  test('When the csv has no errors, it shows that the csv was uploaded', async () => {
    render(
      <Wrapper>
        <UploadProperties onSubmit={mockSubmit} />
      </Wrapper>
    );

    const inputEl = screen.getByTestId('file');
    const file = new File(['imagefile'], 'image.png', { type: 'success' });

    Object.defineProperty(inputEl, 'files', { value: [file] });

    fireEvent.change(inputEl);

    const uploadNotice = await screen.findByText(/Uploaded/);
    expect(uploadNotice).toBeDefined();
  });
});
